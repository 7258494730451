import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Subtitle2 } from "components/Text/Text";
import type { ReactNode } from "react";
import { Menu } from "react-feather";
import { twJoin } from "tailwind-merge";

interface Props {
  id: string;
  title?: string;
  disabled: boolean;
  children: ReactNode;
}

export function DynamicRepeaterItem({ id, title, disabled, children }: Props): React.ReactNode {
  const { attributes, listeners, active, isDragging, setNodeRef, transform, transition } = useSortable({
    id: id,
    disabled: disabled,
  });

  const style = { transform: CSS.Transform.toString(transform), transition };

  return (
    <div
      className={twJoin(
        "relative flex cursor-default flex-col gap-6 rounded-lg bg-white px-4 pb-8 pt-4",
        active && !isDragging ? "shadow-none ring-1 ring-grey-lightest" : "shadow-sm",
      )}
      ref={setNodeRef}
      style={style}
    >
      {isDragging && <div className="absolute left-0 top-0 z-10 size-full rounded-lg bg-blue-lightest"></div>}
      <div className="flex flex-row-reverse justify-between">
        <Menu
          {...attributes}
          {...listeners}
          className={twJoin(
            "text-grey-light",
            !disabled ? (isDragging ? "cursor-grabbing" : "cursor-grab") : undefined,
          )}
        />
        {title && <Subtitle2>{title}</Subtitle2>}
      </div>
      {children}
    </div>
  );
}
