import { useMutation, useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type {
  CopilotPromptConfigurationsDto,
  HomeDnaProjectConfigurationDto,
  IqbiProjectConfigurationDto,
  MyPupProjectConfigurationDto,
  RemsProjectConfigurationRequest,
  ZiezodanProjectConfigurationDto,
} from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Headline3 } from "components/Text/Text";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";

import { CopilotConfigurationsForm } from "./components/CopilotConfigurationsForm";
import { HomeDnaForm } from "./components/HomeDnaForm";
import { IqbiForm } from "./components/IqbiForm";
import { MyPupForm } from "./components/MyPupForm";
import { RemsForm } from "./components/RemsForm";
import { ZiezodanForm } from "./components/ZiezodanForm";

export function IntegrationsPage(): React.ReactNode {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const api = useApi();
  const showFlashToast = useFlashToast();
  const {
    data: remsConfiguration,
    isFetching: isLoadingRemsConfiguration,
    error: remsConfigurationError,
  } = useQuery({
    queryKey: QUERY_KEYS.INTEGRATION_REMS_CONFIGURATION(projectId),
    queryFn: () => api.getIntegrationsRemsV1(),
    select: commonAPIDataSelector,
  });
  const {
    data: ziezodanConfiguration,
    isFetching: isLoadingZiezodanConfiguration,
    error: ziezodanConfigurationError,
  } = useQuery({
    queryKey: QUERY_KEYS.INTEGRATION_ZIEZODAN_CONFIGURATION(projectId),
    queryFn: () => api.getIntegrationsZiezodanV1(),
    select: commonAPIDataSelector,
  });
  const {
    data: homeDnaConfiguration,
    isFetching: isLoadingHomeDnaConfiguration,
    error: homeDnaConfigurationError,
  } = useQuery({
    queryKey: QUERY_KEYS.INTEGRATION_HOMEDNA_CONFIGURATION(projectId),
    queryFn: () => api.getIntegrationsHomednaV1(),
    select: commonAPIDataSelector,
  });
  const {
    data: iqbiConfiguration,
    isFetching: isLoadingIqbiConfiguration,
    error: iqbiConfigurationError,
  } = useQuery({
    queryKey: QUERY_KEYS.INTEGRATION_IQBI_CONFIGURATION(projectId),
    queryFn: () => api.getIntegrationsIqbiV1(),
    select: commonAPIDataSelector,
  });
  const {
    data: myPupConfiguration,
    isFetching: isLoadingMyPupConfiguration,
    error: myPupConfigurationError,
  } = useQuery({
    queryKey: QUERY_KEYS.INTEGRATION_MY_PUP_CONFIGURATION(projectId),
    queryFn: () => api.getIntegrationsMypupV1(),
    select: commonAPIDataSelector,
  });
  const {
    data: copilotConfigurations,
    isFetching: isLoadingCopilotConfigurations,
    error: copilotConfigurationsError,
  } = useQuery({
    queryKey: QUERY_KEYS.INTEGRATION_COPILOT_CONFIGURATION(projectId),
    queryFn: () => api.getIntegrationsCopilotPromptConfigurationsV1(),
    select: commonAPIDataSelector,
  });

  const editRemsConfiguration = useMutation({
    mutationFn: (data: RemsProjectConfigurationRequest) => api.putIntegrationsRemsV1(data),
    onSuccess() {
      showFlashToast({ title: t("page.integration.rems.edit.notification.success"), type: "success" });
    },
    onError() {
      showFlashToast({ title: t("page.integration.rems.edit.notification.failed"), type: "error" });
    },
  });
  const editZiezodanConfiguration = useMutation({
    mutationFn: (data: ZiezodanProjectConfigurationDto) => api.putIntegrationsZiezodanV1(data),
    onSuccess() {
      showFlashToast({ title: t("page.integration.ziezodan.edit.notification.success"), type: "success" });
    },
    onError() {
      showFlashToast({ title: t("page.integration.ziezodan.edit.notification.failed"), type: "error" });
    },
  });
  const editHomeDnaConfiguration = useMutation({
    mutationFn: (data: HomeDnaProjectConfigurationDto) => api.putIntegrationsHomednaV1(data),
    onSuccess() {
      showFlashToast({ title: t("page.integration.homedna.edit.notification.success"), type: "success" });
    },
    onError() {
      showFlashToast({ title: t("page.integration.homedna.edit.notification.failed"), type: "error" });
    },
  });
  const editIqbiConfiguration = useMutation({
    mutationFn: (data: IqbiProjectConfigurationDto) => api.putIntegrationsIqbiV1(data),
    onSuccess() {
      showFlashToast({ title: t("page.integration.iqbi.edit.notification.success"), type: "success" });
    },
    onError() {
      showFlashToast({ title: t("page.integration.iqbi.edit.notification.failed"), type: "error" });
    },
  });
  const editMyPupConfiguration = useMutation({
    mutationFn: (data: MyPupProjectConfigurationDto) => api.putIntegrationsMypupV1(data),
    onSuccess() {
      showFlashToast({ title: t("page.integration.mypup.edit.notification.success"), type: "success" });
    },
    onError() {
      showFlashToast({ title: t("page.integration.mypup.edit.notification.failed"), type: "error" });
    },
  });
  const editCopilotConfigurations = useMutation({
    mutationFn: (data: CopilotPromptConfigurationsDto) => api.putIntegrationsCopilotPromptConfigurationsV1(data),
    onSuccess() {
      showFlashToast({
        title: t("page.integration.copilot-configurations.edit.notification.success"),
        type: "success",
      });
    },
    onError() {
      showFlashToast({ title: t("page.integration.copilot-configurations.edit.notification.failed"), type: "error" });
    },
  });

  const error =
    remsConfigurationError ||
    ziezodanConfigurationError ||
    iqbiConfigurationError ||
    copilotConfigurationsError ||
    homeDnaConfigurationError ||
    myPupConfigurationError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const isLoading =
    isLoadingRemsConfiguration ||
    isLoadingZiezodanConfiguration ||
    isLoadingIqbiConfiguration ||
    isLoadingCopilotConfigurations ||
    isLoadingHomeDnaConfiguration ||
    isLoadingMyPupConfiguration;
  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  const defaultValuesRems = {
    enabled: remsConfiguration?.enabled || false,
    communityUserContactId: remsConfiguration?.communityUserContactId || "",
    communityUserRelationId: remsConfiguration?.communityUserRelationId || "",
    defectCode: remsConfiguration?.defectCode || "",
    complexCode: remsConfiguration?.complexCode || "",
    tenantId: remsConfiguration?.tenantId || "",
  };

  const defaultValuesZiezodan = {
    enabled: ziezodanConfiguration?.enabled || false,
  };

  const defaultValuesHomeDna = {
    enabled: homeDnaConfiguration?.enabled || false,
    clientTenant: homeDnaConfiguration?.clientTenant || "",
    clientSecret: homeDnaConfiguration?.clientSecret || "",
    buyerGln: homeDnaConfiguration?.buyerGln || "",
    buyerName: homeDnaConfiguration?.buyerName || "",
    contractorGln: homeDnaConfiguration?.contractorGln || "",
    contractorName: homeDnaConfiguration?.contractorName || "",
  };

  const defaultValuesIqbi = {
    enabled: iqbiConfiguration?.enabled || false,
    credentials: iqbiConfiguration?.credentials || "",
    locationUuid: iqbiConfiguration?.locationUuid || "",
  };

  const defaultValuesMyPup = {
    enabled: myPupConfiguration?.enabled || false,
    authKey: myPupConfiguration?.authKey || "",
  };

  const defaultValuesCopilotConfigurations = {
    configurations: copilotConfigurations?.configurations || [],
  };

  return (
    <DocumentPaper theme="minimal-constrained" title={t("page.integration.title")}>
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-4 rounded-lg bg-white p-5">
          <Headline3 as="h2">{t("page.integration.ziezodan.title")}</Headline3>
          <ZiezodanForm
            defaultValues={defaultValuesZiezodan}
            isSubmitting={editZiezodanConfiguration.isPending}
            onSubmit={editZiezodanConfiguration.mutate}
          />
        </div>

        <div className="flex flex-col gap-4 rounded-lg bg-white p-5">
          <Headline3 as="h2">{t("page.integration.rems.title")}</Headline3>
          <RemsForm
            defaultValues={defaultValuesRems}
            isSubmitting={editRemsConfiguration.isPending}
            onSubmit={editRemsConfiguration.mutate}
          />
        </div>

        <div className="flex flex-col gap-4 rounded-lg bg-white p-5">
          <Headline3 as="h2">{t("page.integration.homedna.title")}</Headline3>
          <HomeDnaForm
            defaultValues={defaultValuesHomeDna}
            isSubmitting={editHomeDnaConfiguration.isPending}
            onSubmit={editHomeDnaConfiguration.mutate}
          />
        </div>

        <div className="flex flex-col gap-4 rounded-lg bg-white p-5">
          <Headline3 as="h2">{t("page.integration.iqbi.title")}</Headline3>
          <IqbiForm
            defaultValues={defaultValuesIqbi}
            isSubmitting={editIqbiConfiguration.isPending}
            onSubmit={editIqbiConfiguration.mutate}
          />
        </div>

        <div className="flex flex-col gap-4 rounded-lg bg-white p-5">
          <Headline3 as="h2">{t("page.integration.mypup.title")}</Headline3>
          <MyPupForm
            defaultValues={defaultValuesMyPup}
            isSubmitting={editMyPupConfiguration.isPending}
            onSubmit={editMyPupConfiguration.mutate}
          />
        </div>

        <div className="flex flex-col gap-4 rounded-lg bg-white p-5">
          <Headline3 as="h2">{t("page.integration.copilot-configurations.title")}</Headline3>
          <CopilotConfigurationsForm
            defaultValues={defaultValuesCopilotConfigurations}
            isSubmitting={editCopilotConfigurations.isPending}
            onSubmit={editCopilotConfigurations.mutate}
          />
        </div>
      </div>
    </DocumentPaper>
  );
}
