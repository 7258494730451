import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useApi } from "api/hooks/useApi";
import { useMsalConfigContext } from "authentication/MsalConfigContext";
import { getLocalStorageValue, updateLocalStorage } from "hooks/useLocalStorage";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import type { LayoutProps } from "./Layout";

const LAST_USED_EMAIL_KEY = "last_used_email";

export function Loader({ children }: { children: (props: LayoutProps) => React.ReactNode }): React.ReactNode {
  const { i18n } = useTranslation();
  const api = useApi();
  const { instance, accounts, inProgress } = useMsal();
  const msalContext = useMsalConfigContext();
  const location = useLocation();
  const navigate = useNavigate();

  const [lastUsedEmail, setLastUsedEmail] = useState<string>();

  useEffect(() => {
    const email = getLocalStorageValue<string | undefined>(LAST_USED_EMAIL_KEY, undefined);
    if (email) {
      setLastUsedEmail(email);
    }
  }, []);

  const onLogin = useCallback(
    async (email: string) => {
      updateLocalStorage(LAST_USED_EMAIL_KEY, email);

      const result = await api.postUserSignInSignUpVerifyEmailV1({ email });

      return instance.loginRedirect({
        ...msalContext.authRequest,
        extraQueryParameters: {
          ui_locales: i18n.language,
          auto_focus: "true",
        },
        loginHint: email,
        authority: result.data.exists ? undefined : msalContext.b2cPolicies.authorities.signUp.authority,
      });
    },
    [api, instance, msalContext.b2cPolicies.authorities.signUp.authority, msalContext.authRequest, i18n.language],
  );

  const isAuthenticated = accounts.length > 0;

  useEffect(() => {
    if (inProgress !== InteractionStatus.None) {
      return;
    }

    if (isAuthenticated) {
      const redirectPath = new URLSearchParams(location.search).get("redirect") || "/";
      navigate(redirectPath);
    }
  }, [inProgress, isAuthenticated, location.search, navigate]);

  if (inProgress !== InteractionStatus.None || isAuthenticated) {
    // Waiting for MSAL or waiting for redirect
    return null;
  }

  return children({ lastUsedEmail, onLogin });
}
