import { ErrorPage } from "components/Error/ErrorPage";
import { RequireAuth } from "components/Routing/RequireAuth";
import { HelpCategoryCreateOrEditPage } from "modules/community-groups/pages/HelpCategory/CreateOrEdit";
import { HelpCategoryListPage } from "modules/community-groups/pages/HelpCategory/List";
import { InterestGroupCreateOrEditPage } from "modules/community-groups/pages/InterestGroup/CreateOrEdit";
import { InterestGroupListPage } from "modules/community-groups/pages/InterestGroup/List";
import type { RouteObject } from "react-router-dom";

import { CreateOrEditResidentGroupPage } from "./components/CreateOrEditResidentGroup/Page";
import { HelpCategoryDetailsPage } from "./pages/HelpCategory/Details";
import { InterestGroupDetailsPage } from "./pages/InterestGroup/Details";
import { helpCategoriesRoutes, interestGroupsRoutes } from "./routes";

export const helpCategoriesRoute = [
  {
    path: helpCategoriesRoutes.list,
    element: <HelpCategoryListPage />,
  },
  {
    path: helpCategoriesRoutes.create,
    element: (
      <RequireAuth permission={(x) => x.isAdmin && x.community.canCreateGroup}>
        <HelpCategoryCreateOrEditPage />
      </RequireAuth>
    ),
  },
  {
    path: helpCategoriesRoutes.details,
    element: <HelpCategoryDetailsPage />,
  },
  {
    path: helpCategoriesRoutes.edit,
    element: <HelpCategoryCreateOrEditPage />,
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];

export const interestGroupsRoute = [
  {
    path: interestGroupsRoutes.list,
    element: <InterestGroupListPage />,
  },
  {
    path: interestGroupsRoutes.create,
    element: (
      <RequireAuth permission={(x) => x.community.canCreateGroup}>
        <InterestGroupCreateOrEditPage />
      </RequireAuth>
    ),
  },
  {
    path: helpCategoriesRoutes.details,
    element: <InterestGroupDetailsPage />,
  },
  {
    path: interestGroupsRoutes.edit,
    element: <InterestGroupCreateOrEditPage />,
  },
  {
    path: interestGroupsRoutes.createResidentGroup,
    element: (
      <RequireAuth permission={(x) => x.community.canCreateGroup}>
        <CreateOrEditResidentGroupPage />
      </RequireAuth>
    ),
  },
  {
    path: interestGroupsRoutes.editResidentGroup,
    element: <CreateOrEditResidentGroupPage />,
  },
  { path: "*", element: <ErrorPage status={404} /> },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
