import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "routes";

import type { LayoutProps } from "./Layout";

export interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader({ children }: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const api = useApi();
  const { id: serviceId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const slug = useSlug();

  const {
    data: serviceDetails,
    isLoading: isLoadingServiceDetails,
    error: serviceDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.SERVICE_PARTNER(projectId, serviceId!),
    queryFn: () => api.getServicepartnersDetailsV1(serviceId!),
    select: commonAPIDataSelector,
  });
  const { mutateAsync: deleteService } = useMutation({
    mutationFn: () => api.deleteServicepartnersByIdV1(serviceDetails!.id),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SERVICE_PARTNERS_ALL(projectId) });
      navigate(routes.servicePartners.list({ slug }));
      showFlashToast({
        type: "success",
        title: t("model.service.action.delete.notification.success"),
      });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.service.action.delete.notification.error"),
      });
    },
  });

  const error = !serviceId || serviceDetailsError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const loading = isLoadingServiceDetails || !serviceDetails;
  if (loading) {
    return <FullSizeLoader withPadding />;
  }

  return children({
    serviceDetails,
    onDelete: deleteService,
  });
}
