import type { SettingTypeOf } from "configcat-js";
import type { Config, ConfigCatUser } from "helpers/config";
import { getFromConfig, getFromConfigSnapshot } from "helpers/config";
import { useEffect, useState } from "react";

interface ConfigValue<T extends keyof Config> {
  setting: SettingTypeOf<Config[T]> | undefined;
  loading: boolean;
}

export function useConfigNoUser<T extends keyof Config>(key: T, defaultValue?: Config[T]): ConfigValue<T> {
  return useConfigInternal(key, defaultValue, undefined);
}

/**
 * Internal implementation of getting configuration values. Do not use in app, only use useConfigNoUser or useConfig
 */
export function useConfigInternal<T extends keyof Config>(
  key: T,
  defaultValue?: Config[T],
  configCatUser?: ConfigCatUser,
): ConfigValue<T> {
  const [state, setState] = useState<ConfigValue<T>>(() => ({
    loading: true,
    // Get from config snapshot if we already fetched it, so if we have a value we can already populate it
    setting: getFromConfigSnapshot(key, undefined, configCatUser),
  }));

  useEffect(() => {
    let cancelled = false;

    async function get() {
      setState((x) => ({ loading: true, setting: x.setting }));

      const setting = await getFromConfig<T>(key, defaultValue, configCatUser);

      if (!cancelled) {
        setState({ loading: false, setting });
      }
    }

    void get();

    return () => {
      cancelled = true;
    };
  }, [key, defaultValue, configCatUser]);

  return state;
}
