import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { routes } from "routes";

import type { LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader(props: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const api = useApi();
  const sessionUser = useSessionUser();
  const navigate = useNavigate();

  const {
    data: onboardingScreens,
    isPending: isLoadingOnboardingScreens,
    error: onboardingScreensError,
  } = useQuery({
    queryKey: QUERY_KEYS.ONBOARDING_SCREENS_LIST(projectId),
    queryFn: () => api.getOnboardingScreensV1(),
    select: commonAPIDataSelector,
  });

  useEffect(() => {
    if (onboardingScreens && onboardingScreens.total === 0) {
      navigate(routes.onboardingFlow.userProfile({ slug }), { replace: true });
    }
  }, [navigate, onboardingScreens, slug]);

  if (sessionUser.onboardedAt) {
    return <Navigate to="/" />;
  }

  if (onboardingScreensError) {
    return <ErrorPage error={onboardingScreens} />;
  }

  if (isLoadingOnboardingScreens) {
    return <FullSizeLoader withPadding />;
  }

  if (!onboardingScreens || onboardingScreens.items.length == 0) {
    return null;
  }

  return props.children({
    slides: onboardingScreens.items,
  });
}
