import type { AdminTicketDetailsDto } from "api/types";
import { Button } from "components/Button/Button";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { useBool } from "hooks/useBool";
import { TicketReminderModal } from "modules/tickets/components/TicketReminderModal";
import { Clock } from "react-feather";
import { useTranslation } from "react-i18next";

export function TicketReminderButton({ ticket }: { ticket: AdminTicketDetailsDto }): React.ReactNode {
  const [isReminderModalOpen, reminderModalOpenHandlers] = useBool(false);
  const { i18n, t } = useTranslation();

  return (
    <>
      <TicketReminderModal
        ticketId={ticket.id}
        isOpen={isReminderModalOpen}
        onClose={reminderModalOpenHandlers.setFalse}
        reminderDate={ticket.remindAt}
        daysToAutoClose={ticket.category.closeAfterInactiveDayAmount}
      />
      {ticket.canSetReminder ? (
        !ticket.remindAt ? (
          <Button
            styling="primaryFaded"
            onClick={reminderModalOpenHandlers.setTrue}
            title={t("page.tickets.details.remind-me-later.tooltip")}
          >
            <span className="flex items-center gap-2">
              <Clock size={16} />
              {t("page.tickets.details.remind-me-later.create")}
            </span>
          </Button>
        ) : (
          <Button
            styling="primary"
            onClick={reminderModalOpenHandlers.setTrue}
            title={t("page.tickets.details.remind-me-later.tooltip")}
          >
            <span className="flex items-center gap-2">
              <Clock size={16} />
              {t("page.tickets.details.remind-me-later.edit", {
                when: formatDate(i18n, "datetimeShort", ticket.remindAt),
              })}
            </span>
          </Button>
        )
      ) : null}
    </>
  );
}
