import type { ChatReplyDto, ChatReplyRequest } from "api/types";
import { CommentFieldWithAvatar } from "components/CommentField/CommentField";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { LoadingIcon } from "components/Icons/Icons";
import type { FormImage } from "components/ImageInput/useImageInput";
import { useImageInput } from "components/ImageInput/useImageInput";
import { Capture1, Capture2 } from "components/Text/Text";
import { parseISO } from "date-fns";
import { stripTime } from "helpers/date";
import { DEFAULT_MAXIMUM_FILE_SIZE_IN_MEGA_BYTES, megaBytesToBytes } from "helpers/file-size";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useOnIntersection } from "hooks/useOnIntersection";
import { groupBy, sortBy } from "lodash-es";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ChatMessage } from "./ChatMessage";

interface ChatWindowProps {
  canChat: boolean;
  chatReplies: ChatReplyDto[];
  hasMoreReplies: boolean | undefined;
  fetchMoreReplies: () => void;
  isLoadingMoreReplies: boolean;
  sendChat: ({ message, files }: { message: string; files: FormImage[] }) => Promise<ChatReplyRequest>;
  chatName?: string;
}

function groupChatRepliesByDate<T extends { sentAt: string }>(chatMessages: T[]): [string, T[]][] {
  const sortedChatReplies = sortBy(chatMessages, (x) => parseISO(x.sentAt).valueOf());
  const groupedByDay = Object.entries(groupBy(sortedChatReplies, (x) => stripTime(x.sentAt).valueOf()));

  return sortBy(groupedByDay, ([date]) => Number(date));
}

export function ChatWindow({
  canChat,
  chatReplies,
  hasMoreReplies,
  fetchMoreReplies,
  isLoadingMoreReplies,
  sendChat,
  chatName,
}: ChatWindowProps): React.ReactNode {
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const showFlashToast = useFlashToast();
  const [images, setImages] = useState<FormImage[]>([]);
  const { addImages, removeImage, removeImages } = useImageInput({ onChange: setImages, maximumFiles: 1 });
  const { getValues, setValue, watch, register } = useForm<{ messageValue: string }>();
  const scrollRef = useRef<HTMLDivElement>(null);
  const loaderRef = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isLoadingMoreReplies && hasMoreReplies) {
        fetchMoreReplies();
      }
    }, [fetchMoreReplies, hasMoreReplies, isLoadingMoreReplies]),
  });

  const chatMessagesByDate = useMemo(() => groupChatRepliesByDate(chatReplies), [chatReplies]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({ top: scrollRef.current.scrollHeight, behavior: "smooth" });
    }
  }, [chatReplies]);

  useEffect(() => {
    register("messageValue");
  }, [register]);

  const messageValue = watch("messageValue");

  const onChangeContent = useCallback((value: string) => setValue("messageValue", value), [setValue]);

  async function onSendChat() {
    const { messageValue } = getValues();
    await sendChat({
      message: messageValue,
      files: images,
    });

    removeImages();
    setValue("messageValue", "");
  }

  const onRemoveImage = useCallback(
    (imageToBeRemoved: FormImage) => {
      removeImage(imageToBeRemoved);
    },
    [removeImage],
  );

  return (
    <div className="h-[calc(100vh-300px)]">
      <div className="flex h-full flex-col justify-between rounded-lg bg-white">
        <div className="flex grow flex-col gap-4 overflow-y-auto p-5 pb-4" ref={scrollRef}>
          {hasMoreReplies && (
            <div className="p-4" ref={loaderRef}>
              {isLoadingMoreReplies && <LoadingIcon className="inset-0 mx-auto my-4 w-6" />}
            </div>
          )}
          {chatMessagesByDate.map(([date, chatMessages]) => (
            <div key={date.toString()} data-testid="chat-message-date-group">
              <div className="mb-4 flex items-center justify-center whitespace-nowrap">
                <Capture1 className="rounded-lg bg-grey-lightest p-2">
                  <FormattedDate format="date" date={new Date(Number(date)).toISOString()} />
                </Capture1>
              </div>
              <div className="flex flex-col gap-1">
                {chatMessages.map((chatMessage) =>
                  chatMessage.replyType === "creation" ? (
                    <div key={chatMessage.id} className="flex items-center justify-center whitespace-nowrap">
                      <Capture2 className="w-fit rounded-lg bg-grey-lightest p-2">
                        {chatMessage.author?.id === sessionUser.id
                          ? t("page.chat-detail.chat-creation.self", { group: chatName })
                          : t("page.chat-detail.chat-creation.other", {
                              user: chatMessage.author?.fullName,
                              group: chatName,
                            })}
                      </Capture2>
                    </div>
                  ) : (
                    <ChatMessage
                      key={chatMessage.id}
                      isOwnMessage={chatMessage.author?.id === sessionUser.id}
                      hasPrevMessage={
                        chatMessages[chatMessages.indexOf(chatMessage) + 1]?.author?.id === sessionUser.id
                      }
                      isRead={chatMessage.hasBeenSeenByAll}
                      user={chatMessage.author!}
                      canSeeUserDetails={sessionUser.id !== chatMessage.author?.id}
                      sentAt={chatMessage.sentAt}
                      content={chatMessage.text}
                      image={chatMessage.image}
                    />
                  ),
                )}
              </div>
            </div>
          ))}
        </div>
        <form className="rounded-b-lg bg-grey-lightest px-5 py-4">
          <CommentFieldWithAvatar
            user={sessionUser}
            value={messageValue}
            withoutTopRow
            placeholder={t("page.chat-detail.chat.input.placeholder")}
            onChange={onChangeContent}
            images={images}
            onSubmit={onSendChat}
            allowsImages
            onRemoveImage={onRemoveImage}
            onAddImages={(images) => {
              const maxMegaBytes = DEFAULT_MAXIMUM_FILE_SIZE_IN_MEGA_BYTES;
              const maxBytes = megaBytesToBytes(maxMegaBytes);
              if (Array.from(images).some((x) => x.size > maxBytes)) {
                showFlashToast({
                  title: t("components.form.error.file-too-big", {
                    sizeInMegaBytes: maxMegaBytes,
                  }),
                  type: "error",
                });

                return;
              }

              addImages(images);
            }}
            imageUploadTooltip={
              !sessionUser.chatEnabled ? t("page.chat-detail.chat.disabled-input-tooltip") : undefined
            }
            sendTooltip={
              !sessionUser.chatEnabled
                ? t("page.chat-detail.chat.disabled-input-tooltip")
                : t("page.chat-detail.chat.input-tooltip")
            }
            disabled={!sessionUser.chatEnabled || !canChat}
          />
        </form>
      </div>
    </div>
  );
}
