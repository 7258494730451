import type { EventMessage } from "@azure/msal-browser";
import { AuthError, EventType, InteractionType, NavigationClient, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider as AzureMsalProvider } from "@azure/msal-react";
import * as Sentry from "@sentry/react";
import type { MsalConfigContextValue } from "authentication/MsalConfigContext";
import {
  buildMsalConfigContext,
  MsalConfigContextProvider,
  useMsalConfigContext,
} from "authentication/MsalConfigContext";
import { ErrorPage } from "components/Error/ErrorPage";
import { addHours, parseISO } from "date-fns";
import { useEffect, useMemo } from "react";
import { Async } from "react-async";
import type { NavigateFunction } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export function MsalProvider({ children }: { children: React.ReactNode }): React.ReactNode {
  return (
    <Async promiseFn={buildMsalConfigContext} onReject={console.error}>
      <Async.Fulfilled>
        {(context: MsalConfigContextValue) => (
          <MsalConfigContextProvider value={context}>
            <InternalMsalProvider>{children}</InternalMsalProvider>
          </MsalConfigContextProvider>
        )}
      </Async.Fulfilled>
      <Async.Rejected>{(error) => <ErrorPage error={error} />}</Async.Rejected>
    </Async>
  );
}

function InternalMsalProvider({ children }: { children: React.ReactNode }): React.ReactNode {
  const msalContextConfig = useMsalConfigContext();
  const navigate = useNavigate();
  const msalInstance = useMemo(() => new PublicClientApplication(msalContextConfig.config), [msalContextConfig.config]);

  useEffect(() => {
    msalInstance.setNavigationClient(new CustomNavigationClient(navigate));
  }, [msalInstance, navigate]);

  useEffect(() => {
    if (!msalInstance.getActiveAccount()) {
      const accounts = msalInstance.getAllAccounts();
      if (accounts[0]) {
        msalInstance.setActiveAccount(accounts[0]);
      }
    }
  }, [msalInstance]);

  /*
   * Using the event API, you can register an event callback that will do something when an event is emitted.
   * When registering an event callback in a react component you will need to make sure you do 2 things.
   * 1) The callback is registered only once
   * 2) The callback is unregistered before the component unmounts.
   * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
   */
  useEffect(() => {
    function getLoginHint() {
      return msalInstance.getActiveAccount()?.username || msalInstance.getAllAccounts()[0]?.username;
    }

    const callbackId = msalInstance.addEventCallback((event: EventMessage) => {
      console.debug("MSAL event:", JSON.stringify(event, null, 4));

      if (event.eventType === EventType.LOGIN_SUCCESS) {
        if (event.payload && "account" in event.payload && event.payload.account) {
          const account = event.payload.account;
          msalInstance.setActiveAccount(account);
        }
      } else if (event.eventType === EventType.LOGIN_FAILURE && event.error && event.error instanceof AuthError) {
        // https://docs.microsoft.com/en-us/azure/active-directory-b2c/error-codes
        Sentry.captureException(event.error);

        // User cancellation. This can occur from password reset flow when user presses the cancel button.
        if (event.error.errorMessage.includes("AADB2C90091")) {
          return msalInstance.loginRedirect({
            ...msalContextConfig.authRequest,
            loginHint: getLoginHint(),
            extraQueryParameters: {
              auto_focus: "true",
            },
          });
        }

        // Password reset (this flow shouldn't occur as the forgot password policy no longer exists)
        if (event.error.errorMessage.includes("AADB2C90118")) {
          return msalInstance.logoutRedirect();
        }

        // Grant expired
        if (event.error.errorMessage.includes("AADB2C90080")) {
          return msalInstance.logoutRedirect();
        }
      }

      if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE && event.interactionType === InteractionType.Silent) {
        if (acquireTokenFailedTooOften()) {
          return msalInstance.logoutRedirect();
        } else {
          return msalInstance.acquireTokenRedirect({
            ...msalContextConfig.authRequest,
            loginHint: getLoginHint(),
            extraQueryParameters: {
              auto_focus: "true",
            },
          });
        }
      }
    });

    return () => {
      if (callbackId) {
        msalInstance.removeEventCallback(callbackId);
      }
    };
  }, [msalInstance, msalContextConfig]);

  return <AzureMsalProvider instance={msalInstance}>{children}</AzureMsalProvider>;
}

interface AcquireTokenFailure {
  expiresAt: string;
  timesFailed: number;
}

function acquireTokenFailedTooOften() {
  try {
    let timesFailed = 0;

    const storageKey = "AOP." + EventType.ACQUIRE_TOKEN_FAILURE;
    const storedItem = sessionStorage.getItem(storageKey);
    if (storedItem) {
      const data = JSON.parse(storedItem) as AcquireTokenFailure;
      const expiresAt = parseISO(data.expiresAt);
      if (new Date() > expiresAt) {
        sessionStorage.removeItem(storageKey);
      } else {
        timesFailed = data.timesFailed;
      }
    }

    timesFailed++;

    const failedTooOften = timesFailed > 2;

    if (failedTooOften) {
      sessionStorage.removeItem(storageKey);
    } else {
      sessionStorage.setItem(
        storageKey,
        JSON.stringify({
          timesFailed,
          expiresAt: addHours(new Date(), 3).toISOString(),
        } as AcquireTokenFailure),
      );
    }

    return failedTooOften;
  } catch (error) {
    Sentry.captureException(error);

    return true;
  }
}

/**
 * Custom navigation client to improve performance for internal redirects
 */
class CustomNavigationClient extends NavigationClient {
  private navigate: NavigateFunction;

  constructor(navigate: NavigateFunction) {
    super();
    this.navigate = navigate;
  }

  navigateInternal(url: string, options: { noHistory?: boolean } = {}) {
    const relativePath = url.replace(window.location.origin, "");
    this.navigate(relativePath, { replace: options.noHistory });

    return Promise.resolve(false);
  }
}
