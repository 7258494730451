import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { AutomatedSurveyEditRequest, AutomatedSurveyRequest } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { QUERY_KEYS } from "query-keys";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "routes";

import { automatedSurveyQuestionCategories, automatedSurveyTypes } from "../../constants";
import type { FormValues, LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader(props: LoaderProps): React.ReactNode {
  const { t } = useTranslation();
  const api = useApi();
  const query = useQueryClient();
  const showFlashToast = useFlashToast();
  const navigate = useNavigate();
  const { id: surveyId } = useParams<{ id: string }>();

  const {
    data: automatedSurveyDetails,
    isPending: isLoadingAutomatedSurveyDetails,
    error: automatedSurveyDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.AUTOMATED_SURVEY_DETAILS(surveyId!),
    queryFn: () => api.getAutomatedSurveysDetailsV1(surveyId!),
    select: commonAPIDataSelector,
    enabled: !!surveyId,
  });

  const createAutomatedSurvey = useMutation({
    mutationFn: (payload: AutomatedSurveyRequest) => api.postAutomatedSurveysV1(payload).then((x) => x.data),
    onSuccess: () => {
      showFlashToast({ type: "success", title: t("page.automated-surveys.new.notification.success") });
      navigate(routes.automatedSurveys.list());
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.automated-surveys.new.notification.error") });
    },
  });

  const editAutomatedSurvey = useMutation({
    mutationFn: (payload: AutomatedSurveyEditRequest) =>
      api.putAutomatedSurveysV1(surveyId!, payload).then((x) => x.data),
    onSuccess: () => {
      showFlashToast({ type: "success", title: t("page.automated-surveys.edit.notification.success") });
      void query.invalidateQueries({ queryKey: QUERY_KEYS.AUTOMATED_SURVEY_DETAILS(surveyId!) });
      navigate(routes.automatedSurveys.list());
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.automated-surveys.edit.notification.error") });
    },
  });

  const defaultFormValues = useMemo(() => {
    return {
      name: automatedSurveyDetails ? automatedSurveyDetails.title : "",
      description: automatedSurveyDetails ? automatedSurveyDetails.description : "",
      thanksMessage: automatedSurveyDetails ? automatedSurveyDetails.thankYouMessage : "",
      interval: automatedSurveyDetails ? automatedSurveyDetails.weeklyInterval.toString() : "0",
      minSampleSize: automatedSurveyDetails ? automatedSurveyDetails.usersSampleSize.toString() : "0",
      type: automatedSurveyDetails ? automatedSurveyDetails.category : automatedSurveyTypes[0],
      projects: automatedSurveyDetails ? automatedSurveyDetails.enabledProjects : [],
      active: automatedSurveyDetails ? automatedSurveyDetails.isLocked : false,
      questions: automatedSurveyDetails
        ? automatedSurveyDetails.questionGroups.map((question) => ({
            qId: question.id,
            category: automatedSurveyQuestionCategories.find((category) => category.id === question.type)!,
            ratingQuestion: question.ratingQuestionTemplate,
            openQuestion: question.openQuestionTemplate,
            explanation: question.explanation,
          }))
        : automatedSurveyQuestionCategories
            .filter((category) => category.mandatory)
            .map((category) => ({
              category: category,
              ratingQuestion: "",
              openQuestion: "",
              explanation: "",
            })),
    } satisfies FormValues;
  }, [automatedSurveyDetails]);

  if (automatedSurveyDetailsError) {
    return <ErrorPage error={automatedSurveyDetailsError} />;
  }

  if (isLoadingAutomatedSurveyDetails && !!surveyId) {
    return <FullSizeLoader withPadding />;
  }

  return props.children({
    defaultFormValues: defaultFormValues,
    onCreate: createAutomatedSurvey.mutateAsync,
    onEdit: editAutomatedSurvey.mutateAsync,
    isSubmitting: createAutomatedSurvey.isPending,
  });
}
