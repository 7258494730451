import type { LanguageDto, ProjectConnectionGroupDetailDto, UserRoleDto, UserRoleTypePermissionsDto } from "api/types";
import { createFormPermissions } from "helpers/system-groups/permissions";
import { toFormTranslations } from "helpers/system-groups/translations";

import type { FormValues } from "./Layout";

export function getDefaultFormValues(
  roles: UserRoleDto[],
  languages: LanguageDto[],
  defaultsGroupsPermissions: UserRoleTypePermissionsDto[],
  group?: ProjectConnectionGroupDetailDto,
): FormValues {
  return {
    type: group?.type || "interest",
    nameTranslations: toFormTranslations(languages, group?.translations, "name"),
    descriptionTranslations: toFormTranslations(languages, group?.translations, "description"),
    icon: group?.icon,
    image: group?.image ? [group.image] : [],
    postingLevel: group?.postingLevel || "projectAndProjectConnection",
    audience: group?.defaultPostingAudience || "projectConnection",
    permissions: createFormPermissions(roles, defaultsGroupsPermissions, group?.permissions),
  };
}
