import type { ImageDto } from "api/types";
import { useSlug } from "hooks/useSlug";
import { useForbiddenPopupContext } from "providers/ForbiddenPopupProvider";
import { Link } from "react-router-dom";
import { routes } from "routes";

import { UserAvatar } from "../UserAvatar/UserAvatar";

export interface UserAvatarLinkProps {
  user: { id: string; projectId: string; canViewProfile: boolean; avatar?: ImageDto; deletedAt?: string };
  hideDeletedIcon?: boolean;
}

export function UserAvatarLink({ user, ...props }: UserAvatarLinkProps): React.ReactNode {
  const slug = useSlug();
  const forbiddenContext = useForbiddenPopupContext();

  const avatar = (
    <UserAvatar hideDeletedIcon={props.hideDeletedIcon} isUserDeleted={!!user.deletedAt} img={user.avatar} />
  );

  if (user.canViewProfile) {
    return <Link to={routes.users.details({ slug, id: user.id })}>{avatar}</Link>;
  }

  return <button onClick={forbiddenContext.open}>{avatar}</button>;
}
