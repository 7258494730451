import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { useImageResolver } from "api/hooks/useImageResolver";
import messageChatSquareIcon from "assets/icons/message-chat-square.svg";
import award from "assets/images/award.svg";
import bgPlaceholder from "assets/images/image-placeholder.png";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { BorderlessButton, Button, IconButton, LinkButton, StaticButton } from "components/Button/Button";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { ContextMenu } from "components/ContextMenu/ContextMenu";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { formatDistance } from "components/FormattedDistance/FormattedDistance";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { Label } from "components/Label/Label";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { Modal } from "components/Modal/Modal";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { TagList } from "components/Tag/TagList";
import { Capture2, Headline4 } from "components/Text/Text";
import { UserAvatarList } from "components/UserAvatarList/UserAvatarList";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { isDefined } from "helpers/util";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useOnIntersection } from "hooks/useOnIntersection";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { orderBy, round } from "lodash-es";
import { canClone, canViewGroupAudience } from "modules/community-groups/permissions";
import { CommunityPoll } from "modules/messages/pages/List/components/CommunityPoll";
import { CommunityPost } from "modules/messages/pages/List/components/CommunityPost";
import { CommunitySurvey } from "modules/messages/pages/List/components/CommunitySurvey";
import { ResidentCreatePost } from "modules/messages/pages/List/components/ResidentCreatePost";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useMemo, useState } from "react";
import { Copy, Edit, PenTool, Plus, Power, Trash2, Users } from "react-feather";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";
import type { ApiQueryParams, ApiResponseType } from "types/api-types";

import { GroupMembersModal } from "../GroupMembersModal";

const COMMUNITY_FEED_ITEM_AMOUNT = 20;

export function GroupDetailsPage(): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const { id: groupId } = useParams<{ id: string }>();
  const { i18n, t } = useTranslation();
  const showFlashToast = useFlashToast();
  const queryClient = useQueryClient();
  const sessionUser = useSessionUser();
  const resolveImage = useImageResolver();
  const slug = useSlug();
  const hasPermission = usePermission();
  const navigate = useNavigate();
  const [isGroupMembersModalOpen, groupMembersModalOpenHandlers] = useBool();
  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<string>("group-delete-modal");
  const [disableGroupId, setDisableGroupId] = useState<string | null>(null);
  const [residentCreatePostType, setResidentCreatePostType] = useState<
    "askHelp" | "inform" | "sellItem" | "promote" | null
  >(null);

  const {
    data: groupDetails,
    isLoading: groupDetailsIsFetching,
    error: groupDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.COMMUNITY_GROUP_DETAILS(projectId, groupId!),
    queryFn: () => api.getGroupsDetailsV2(groupId!),
    select: commonAPIDataSelector,
  });

  const enableGroup = useMutation({
    mutationFn: api.postGroupsEnableV1,
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMMUNITY_GROUPS(projectId) });
    },
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title:
          groupDetails?.type === "helpCategory"
            ? t("model.platform.help-category.action.enable.notification.success")
            : t("model.platform.interest-group.action.enable.notification.success"),
        "data-testid": "toast-group-is-enabled",
      });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title:
          groupDetails?.type === "helpCategory"
            ? t("model.platform.help-category.action.enable.notification.error")
            : t("model.platform.interest-group.action.enable.notification.error"),
      });
    },
  });

  const disableGroup = useMutation({
    mutationFn: api.deleteGroupsByIdV1,
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMMUNITY_GROUPS(projectId) });
    },
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title:
          groupDetails?.type === "helpCategory"
            ? t("model.platform.help-category.action.disable.notification.success")
            : t("model.platform.interest-group.action.disable.notification.success"),
        "data-testid": "toast-group-is-disabled",
      });
      navigate(
        groupDetails?.type === "helpCategory"
          ? routes.helpCategories.list({ slug })
          : routes.interestGroups.list({ slug }),
      );
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title:
          groupDetails?.type === "helpCategory"
            ? t("model.platform.help-category.action.disable.notification.error")
            : t("model.platform.interest-group.action.disable.notification.error"),
      });
    },
  });

  const skipPromote = useMutation({
    mutationFn: () => api.postGroupsSkipPromotionV1(groupId!),
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMMUNITY_GROUP_DETAILS(projectId, groupId!) });
    },
  });

  const { mutate: follow } = useMutation({
    mutationFn: (id: string) => api.postGroupsFollowV1(id),
    onMutate: (id) => {
      queryClient.setQueryData<ApiResponseType<"getGroupsDetailsV2"> | undefined>(
        QUERY_KEYS.COMMUNITY_GROUP_DETAILS(projectId, id),
        (data) => {
          if (data) {
            return {
              ...data,
              data: {
                ...data.data,
                isMember: true,
              },
            };
          }
        },
      );
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMMUNITY_GROUPS(projectId) });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title:
          groupDetails?.type === "helpCategory"
            ? t("model.help-category.action.follow.notification-error")
            : t("model.interest-group.action.follow.notification-error"),
      });
    },
  });

  const { mutate: unfollow } = useMutation({
    mutationFn: (id: string) => api.postGroupsUnfollowV1(id),
    onMutate: (id) => {
      queryClient.setQueryData<ApiResponseType<"getGroupsDetailsV2"> | undefined>(
        QUERY_KEYS.COMMUNITY_GROUP_DETAILS(projectId, id),
        (data) => {
          if (data) {
            return {
              ...data,
              data: {
                ...data.data,
                isMember: false,
              },
            };
          }
        },
      );
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMMUNITY_GROUPS(projectId) });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title:
          groupDetails?.type === "helpCategory"
            ? t("model.help-category.action.unfollow.notification-error")
            : t("model.interest-group.action.unfollow.notification-error"),
      });
    },
  });

  const deleteGroupMutationResult = useMutation({
    mutationFn: api.deleteGroupsByIdV1,
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMMUNITY_GROUPS(projectId) });
    },
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title:
          groupDetails?.type === "helpCategory"
            ? t("model.help-category.action.delete.notification.success")
            : t("model.interest-group.action.delete.notification.success"),
        "data-testid": "toast-group-is-deleted",
      });
      navigate(
        groupDetails?.type === "helpCategory"
          ? routes.helpCategories.list({ slug })
          : routes.interestGroups.list({ slug }),
      );
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title:
          groupDetails?.type === "helpCategory"
            ? t("model.help-category.action.delete.notification.error")
            : t("model.interest-group.action.delete.notification.error"),
      });
    },
  });

  const messageFeedQuery = {
    Tab: "all",
    Connection: "included",
    GroupIds: [groupId!],
    FeedTypes: ["messages", "polls", "surveys"],
  } satisfies ApiQueryParams<"getCommunityFeedV2">;
  const {
    data: messageData,
    hasNextPage: hasMoreMessages,
    fetchNextPage: fetchMoreMessages,
    isPending: isLoadingMessages,
    isFetchingNextPage: isLoadingMoreMessages,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.MESSAGES_FEED(projectId, messageFeedQuery),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getCommunityFeedV2({
          ...messageFeedQuery,
          Limit: COMMUNITY_FEED_ITEM_AMOUNT,
          Offset: pageParam * COMMUNITY_FEED_ITEM_AMOUNT,
        })
        .then((items) => commonAPIDataSelector(items)),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
  });
  const messages = useMemo(() => messageData?.pages.flatMap((x) => x.items) ?? [], [messageData]);
  const ref = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isLoadingMoreMessages && hasMoreMessages) {
        void fetchMoreMessages();
      }
    }, [fetchMoreMessages, hasMoreMessages, isLoadingMoreMessages]),
  });

  const residentPostCreationOptions = useMemo(() => {
    const actions: ContextMenuAction[] = [];
    if (!groupDetails?.id || (!groupDetails.canCreatePost && !groupDetails.canCreateAnnouncement)) {
      return actions;
    }

    if (groupDetails.id === sessionUser.marketPlaceGroupId) {
      actions.push({
        text: t("page.group-details.resident-create-message-options.marketplace"),
        callback: () => setResidentCreatePostType("sellItem"),
      });
    } else {
      if (groupDetails.type !== "helpCategory") {
        actions.push({
          text: t("page.group-details.resident-create-message-options.inform"),
          callback: () => setResidentCreatePostType("inform"),
        });
      }

      actions.push({
        text: t("page.group-details.resident-create-message-options.help"),
        callback: () => setResidentCreatePostType("askHelp"),
      });
    }

    return actions;
  }, [
    groupDetails?.canCreateAnnouncement,
    groupDetails?.canCreatePost,
    groupDetails?.id,
    groupDetails?.type,
    sessionUser.marketPlaceGroupId,
    t,
  ]);

  const error = groupDetailsError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const isPageLoading = groupDetailsIsFetching;
  if (isPageLoading || !groupDetails) {
    return <FullSizeLoader withPadding />;
  }

  const image = groupDetails.image ? groupDetails.image : sessionUser.project.backgroundImage;

  const listRoute = groupDetails.type === "helpCategory" ? routes.helpCategories.list : routes.interestGroups.list;
  const editRoute =
    groupDetails.type === "helpCategory"
      ? routes.helpCategories.edit
      : groupDetails.isResidentGroup
        ? routes.interestGroups.editResidentGroup
        : routes.interestGroups.edit;
  const duplicateRoute =
    groupDetails.type === "helpCategory" ? routes.helpCategories.create : routes.interestGroups.create;

  const tag = !sessionUser.isAdmin
    ? undefined
    : groupDetails.type === "realEstate"
      ? t("page.discovery.group-type.real-estate")
      : groupDetails.projectConnectionGroupId
        ? t("page.discovery.group-type.project-connection")
        : groupDetails.systemGroupId
          ? t("page.discovery.group-type.platform")
          : undefined;

  return (
    <DocumentPaper
      theme="minimal-constrained"
      title={t("page.group-details.title")}
      subTitle={
        <Breadcrumbs
          pages={[
            {
              name: t("page.group-details.breadcrumbs.discovery"),
            },
            {
              name:
                groupDetails.type === "helpCategory"
                  ? t("page.help-category.list.title")
                  : t("page.interest-group.list.title"),

              to: listRoute({ slug }),
            },
            { name: groupDetails.name },
          ]}
        />
      }
      actions={
        groupDetails.canCreatePost || groupDetails.canCreateAnnouncement ? (
          sessionUser.isAdmin ? (
            <>
              <LinkButton
                to={routes.messageFeed.adminCreatePost({ slug })}
                state={{ groupId: groupDetails.id }}
                className="hidden lg:block"
                styling="primary"
                data-testid="group-action-create-message"
              >
                {t("page.group-details.action.create-message")}
              </LinkButton>
              <IconButton
                className="inline rounded-full bg-aop-basic-blue p-2 text-white hover:bg-aop-basic-blue-dark active:text-white lg:hidden"
                title={t("page.group-details.action.create-message")}
                onClick={() =>
                  navigate(routes.messageFeed.adminCreatePost({ slug }), { state: { groupId: groupDetails.id } })
                }
              >
                <Plus size={24} />
              </IconButton>
            </>
          ) : residentPostCreationOptions.length === 1 ? (
            <>
              <Button
                className="hidden lg:block"
                data-testid="create-message"
                onClick={residentPostCreationOptions[0].callback}
              >
                {t("page.group-details.action.create-message")}
              </Button>
              <IconButton
                className="inline rounded-full bg-aop-basic-blue p-2 text-white hover:bg-aop-basic-blue-dark active:text-white lg:hidden"
                title={t("page.group-details.action.create-message")}
                onClick={residentPostCreationOptions[0].callback}
              >
                <Plus size={24} />
              </IconButton>
            </>
          ) : (
            <ContextMenu actions={residentPostCreationOptions}>
              {(props) => (
                <>
                  <Button
                    className="hidden lg:block"
                    data-testid="create-message"
                    onClick={props.openHandlers.toggle}
                    pressed={props.isOpen}
                  >
                    {t("page.group-details.action.create-message")}
                  </Button>
                  <IconButton
                    className="inline rounded-full bg-aop-basic-blue p-2 text-white hover:bg-aop-basic-blue-dark active:text-white lg:hidden"
                    title={t("page.group-details.action.create-message")}
                    onClick={props.openHandlers.toggle}
                    pressed={props.isOpen}
                  >
                    <Plus size={24} />
                  </IconButton>
                </>
              )}
            </ContextMenu>
          )
        ) : null
      }
    >
      <div className="flex flex-col gap-6">
        <div
          className={twJoin(
            "flex flex-col rounded-lg bg-white shadow-md",
            groupDetails.disabled ? "text-grey" : "text-black",
          )}
        >
          <div className="relative">
            <div
              className="h-64 rounded-t-lg bg-grey-light bg-cover bg-center p-2"
              style={{
                backgroundImage: `url(${image ? resolveImage(image, "large") : bgPlaceholder})`,
              }}
            />
          </div>
          <div className="flex flex-col gap-4 p-4">
            <div className="flex justify-between">
              <div className="flex flex-col gap-2">
                {tag ? <Label theme="gray">{tag}</Label> : null}
                <Headline4>{groupDetails.name}</Headline4>
              </div>
              <div className="-mr-2">
                <ContextMenu
                  actions={[
                    groupDetails.canEdit
                      ? {
                          dataTestId: "context-menu-edit-btn",
                          text: t("common.action.edit"),
                          icon: <Edit size={16} />,
                          callback: () => navigate(editRoute({ slug, id: groupDetails.id })),
                        }
                      : undefined,
                    hasPermission(canClone) &&
                    !groupDetails.systemGroupId &&
                    !groupDetails.projectConnectionGroupId &&
                    !groupDetails.isResidentGroup
                      ? {
                          dataTestId: "duplicate-group-btn",
                          text: t("common.action.duplicate"),
                          icon: <Copy size={16} />,
                          callback: () => {
                            navigate(duplicateRoute({ slug }), { state: { groupId: groupDetails.id } });
                          },
                        }
                      : undefined,
                    groupDetails.canPromote
                      ? {
                          dataTestId: "context-menu-promote",
                          text: t("page.group-details.promote.button"),
                          icon: <PenTool size={16} />,
                          callback: () => setResidentCreatePostType("promote"),
                        }
                      : undefined,
                    ...(groupDetails.canDelete
                      ? [
                          (groupDetails.systemGroupId || groupDetails.projectConnectionGroupId) &&
                          !!groupDetails.disabled
                            ? {
                                text: t("common.action.enable"),
                                icon: <Power size={16} />,
                                callback: () => {
                                  enableGroup.mutate(groupDetails.id);
                                },
                              }
                            : undefined,
                          (groupDetails.systemGroupId || groupDetails.projectConnectionGroupId) &&
                          !groupDetails.disabled
                            ? {
                                text: t("common.action.disable"),
                                icon: <Power size={16} />,
                                callback: () => {
                                  setDisableGroupId(groupDetails.id);
                                },
                              }
                            : undefined,
                          !groupDetails.systemGroupId && !groupDetails.projectConnectionGroupId
                            ? {
                                dataTestId: "context-menu-delete-btn",
                                text: t("common.action.delete"),
                                icon: <Trash2 size={16} />,
                                callback: () => {
                                  openDeleteModal(groupDetails.id);
                                },
                              }
                            : undefined,
                        ]
                      : []),
                  ].filter(isDefined)}
                />
              </div>
            </div>

            {!groupDetails.disabled && (
              <div className="flex flex-wrap items-center justify-between gap-1">
                <UserAvatarList
                  images={orderBy(groupDetails.members.items, (x) => !!x.avatar).map((x) => x.avatar)}
                  limit={3}
                  totalElements={groupDetails.members.total}
                  onClick={groupMembersModalOpenHandlers.toggle}
                />
                {groupDetails.isOwner && groupDetails.isResidentGroup ? (
                  <StaticButton>
                    <div data-testid="group-admin-btn" className="flex items-center gap-1">
                      <img className="size-4" src={award} alt="" />
                      <span>{t("model.interest-group.owner")}</span>
                    </div>
                  </StaticButton>
                ) : (
                  <Button
                    className="w-full sm:w-auto sm:max-w-full"
                    data-testid="group-action-follow-unfollow"
                    styling={groupDetails.isMember ? "secondaryGreen" : "secondary"}
                    onClick={() => {
                      if (groupDetails.isMember) {
                        unfollow(groupDetails.id);
                      } else {
                        follow(groupDetails.id);
                      }
                    }}
                  >
                    {groupDetails.isMember
                      ? groupDetails.type === "helpCategory"
                        ? t("model.help-category.action.unfollow")
                        : t("model.interest-group.action.unfollow")
                      : groupDetails.type === "helpCategory"
                        ? t("model.help-category.action.follow")
                        : t("model.interest-group.action.follow")}
                  </Button>
                )}
              </div>
            )}

            <p>
              <LinkFormatter>{groupDetails.description}</LinkFormatter>
            </p>
            {groupDetails.lastActivityAt ? (
              <span className="text-sm text-grey">
                {t("component.community-post.last-activity", {
                  time: formatDistance(i18n, { start: new Date(groupDetails.lastActivityAt) }),
                })}
              </span>
            ) : null}
          </div>
        </div>
        {groupDetails.isOwner && groupDetails.canPromote && groupDetails.hasPromotionBanner ? (
          <div className="flex flex-col items-center gap-4 rounded-lg bg-white p-4 shadow-sm">
            <Icon name={messageChatSquareIcon} size={20} />
            <Headline4>{t("page.group-details.promote.label")}</Headline4>
            <div className="flex items-center">
              <Button data-testid="promote-group-button" onClick={() => setResidentCreatePostType("promote")}>
                {t("page.group-details.promote.button")}
              </Button>
            </div>
            <BorderlessButton className="underline" onClick={() => skipPromote.mutateAsync()}>
              {t("common.action.skip")}
            </BorderlessButton>
          </div>
        ) : null}

        {isLoadingMessages ? (
          <FullSizeLoader withPadding size="small" />
        ) : messages.length === 0 ? (
          <p>{t("page.group-details.no-messages")}</p>
        ) : (
          <>
            <ul className="flex flex-col gap-5">
              {messages.map((message) =>
                message.type === "message" && message.message ? (
                  <li key={message.message.id} data-testid="community-post">
                    <CommunityPost message={message.message} isExpanded={false} />
                  </li>
                ) : message.type === "poll" && message.poll ? (
                  <li key={message.poll.id} data-testid="poll">
                    <CommunityPoll poll={message.poll} />
                  </li>
                ) : message.type === "survey" && message.survey ? (
                  <li key={message.survey.id} data-testid="survey">
                    <CommunitySurvey survey={message.survey} updatedAt={message.updatedAt} />
                  </li>
                ) : null,
              )}
            </ul>
            <div className="p-4" ref={ref}>
              <LoadingIcon className={twJoin("inset-0 mx-auto my-4 w-6", !hasMoreMessages ? "opacity-0" : undefined)} />
            </div>
          </>
        )}
      </div>
      <DeleteModal<string>
        title={
          groupDetails.type === "helpCategory"
            ? t("model.help-category.action.delete.confirmation")
            : t("model.interest-group.action.delete.confirmation")
        }
        description={
          groupDetails.type === "helpCategory"
            ? t("model.help-category.action.delete.description")
            : t("model.interest-group.action.delete.description")
        }
        onDelete={deleteGroupMutationResult.mutateAsync}
        deleteBtnProps={{
          "data-testid": "modal-confirm-delete",
        }}
        {...deleteModalProps}
      />
      <ConfirmModal
        id="group-disable-modal"
        title={
          groupDetails.type === "helpCategory"
            ? t("model.platform.help-category.action.disable.confirmation")
            : t("model.platform.interest-group.action.disable.confirmation")
        }
        description={
          groupDetails.type === "helpCategory"
            ? t("model.platform.help-category.action.disable.description")
            : t("model.platform.interest-group.action.disable.description")
        }
        theme="danger"
        onReject={() => setDisableGroupId(null)}
        onResolve={async () => {
          await disableGroup.mutateAsync(disableGroupId!);
          setDisableGroupId(null);
        }}
        resolveBtnProps={{
          text: t("common.action.disable"),
          "data-testid": "group-disable-modal-disable",
        }}
        isOpen={disableGroupId != null}
        shouldCloseOnEsc
        isLoading={disableGroup.isPending}
      />
      <Modal
        isOpen={!!residentCreatePostType}
        onRequestClose={() => setResidentCreatePostType(null)}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick={false}
      >
        {residentCreatePostType ? (
          <ResidentCreatePost
            onClose={() => setResidentCreatePostType(null)}
            messageType={residentCreatePostType === "promote" ? "inform" : residentCreatePostType}
            defaultGroupId={residentCreatePostType === "promote" ? undefined : groupDetails.id}
            promotedGroup={residentCreatePostType === "promote" ? groupDetails : undefined}
          />
        ) : null}
      </Modal>
      <GroupMembersModal
        groupId={groupId!}
        isOpen={isGroupMembersModalOpen}
        onClose={groupMembersModalOpenHandlers.setFalse}
        header={
          sessionUser.isAdmin ? (
            <div className="flex flex-col gap-2">
              {hasPermission(canViewGroupAudience) &&
              !groupDetails.disabled &&
              groupDetails.joinedResidents != null &&
              groupDetails.totalResidentsAudience != null ? (
                <Capture2 className="text-grey-darker">
                  <Trans
                    i18nKey={
                      groupDetails.type === "helpCategory"
                        ? "model.help-category.audience.details"
                        : "model.interest-group.audience.details"
                    }
                    values={{
                      percentage:
                        groupDetails.totalResidentsAudience !== 0
                          ? round((groupDetails.joinedResidents / groupDetails.totalResidentsAudience) * 100, 1)
                          : 0,
                      current: groupDetails.joinedResidents,
                      total: groupDetails.totalResidentsAudience,
                    }}
                    components={{
                      bold: <strong className="font-semibold" />,
                    }}
                  />
                </Capture2>
              ) : null}
              <div
                className={twJoin(
                  "flex items-center gap-2 text-grey-darker",
                  groupDetails.disabled && "text-grey-light",
                )}
              >
                <Users size={16} />
                {groupDetails.audience?.length ? (
                  <TagList
                    tags={groupDetails.audience?.map(({ key, ...item }) => ({ content: key, ...item }))}
                    limit={3}
                  />
                ) : (
                  <i>
                    {groupDetails.type === "helpCategory"
                      ? t("model.help-category.audience.placeholder")
                      : t("model.interest-group.audience.placeholder")}
                  </i>
                )}
              </div>
            </div>
          ) : null
        }
      />
    </DocumentPaper>
  );
}
