import { ErrorPage } from "components/Error/ErrorPage";
import { RequireAuth } from "components/Routing/RequireAuth";
import type { RouteObject } from "react-router-dom";

import { CreateOrEditAddressPage } from "./pages/CreateOrEdit";
import { AddressListPage } from "./pages/List";
import { addressRoutes } from "./routes";

export const addressesRoute = [
  {
    path: addressRoutes.list,
    element: (
      <RequireAuth permission={(x) => x.isAdmin && x.addressManagement.canListAddresses}>
        <AddressListPage />
      </RequireAuth>
    ),
  },
  {
    path: addressRoutes.new,
    element: (
      <RequireAuth permission={(x) => x.addressManagement.canManageAddresses}>
        <CreateOrEditAddressPage />
      </RequireAuth>
    ),
  },
  {
    path: addressRoutes.edit,
    element: (
      <RequireAuth permission={(x) => x.addressManagement.canEditAddress}>
        <CreateOrEditAddressPage />
      </RequireAuth>
    ),
  },
  {
    path: "*",
    element: <ErrorPage status={404} />,
  },
] satisfies RouteObject[];
