import { ErrorPage } from "components/Error/ErrorPage";
import type { RouteObject } from "react-router-dom";

import { SignInSignUpPage } from "./pages";
import { signInSignUpRoutes } from "./routes";

export const signInSignUpRoute = [
  {
    path: signInSignUpRoutes.main,
    element: <SignInSignUpPage />,
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
