import alertTriangleIcon from "assets/icons/alert-triangle.svg";
import chevronLeftIcon from "assets/icons/chevron-left.svg";
import dotsGridIcon from "assets/icons/dots-grid.svg";
import messageSquareIcon from "assets/icons/message-square-02.svg";
import homesIcon from "assets/icons/project-homes-filled.svg";
import searchIcon from "assets/icons/search-sm.svg";
import settingsO4Icon from "assets/icons/settings-04.svg";
import switchHorizontalIcon from "assets/icons/switch-horizontal-01.svg";
import toolIcon from "assets/icons/tool-02.svg";
import usersIcon from "assets/icons/users-01.svg";
import logoDark from "assets/images/logo-dark.svg";
import { Icon } from "components/Icon/Icon";
import { ProjectLogo } from "components/ProjectLogo/ProjectLogo";
import { ProjectList } from "components/ProjectSwitcher/ProjectList";
import { useRecentProjects } from "components/ProjectSwitcher/useRecentProjects";
import { SearchInput } from "components/SearchInput/SearchInput";
import { Overline2, Subtitle2 } from "components/Text/Text";
import { AnimatePresence, motion } from "framer-motion";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useBool } from "hooks/useBool";
import { useConfig } from "hooks/useConfig";
import { useKey } from "hooks/useKey";
import { useScreenIsBiggerThan } from "hooks/useScreenIsBiggerThan";
import { unionBy } from "lodash-es";
import { useProjectContext } from "providers/ProjectContext";
import { memo, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

import { NavigationContext } from "./NavigationContext";
import { NavigationLink } from "./NavigationLink";
import { NavigationLinkContainer } from "./NavigationLinkContainer";
import { NavigationSubLink } from "./NavigationSubLink";
import { UserMenu } from "./UserMenu";

interface Props {
  isWide: boolean;
  isInProject: boolean;
  onToggle: () => void;
}

export const PortfolioSidebar = memo(PortfolioSidebarContent);

function PortfolioSidebarContent({ isWide, isInProject, onToggle }: Props): React.ReactNode {
  const { t } = useTranslation();

  const connectedProjects = useConnectedProjects();

  const isMd = useScreenIsBiggerThan("md");
  const isXl = useScreenIsBiggerThan("xl");
  const is2Xl = useScreenIsBiggerThan("2xl");

  const showAlerts =
    connectedProjects.data?.some((x) => x.userRole !== "resident" && x.userRole !== "maintenance") || false;

  const { setting: showAvailableIntegrations } = useConfig("showAvailableIntegrations");

  return (
    <NavigationContext.Provider value={{ isWide }}>
      <div
        className={twJoin(
          "group/portfolio-sidebar z-30 flex flex-row md:z-auto md:h-full",
          isWide || isInProject ? "" : "-mr-3",
        )}
      >
        <motion.div
          className={twJoin(
            // I hope you never have to mess with overflow, it's a nightmare. Make sure you test mobile, desktop small screen height, collapsed and uncollapsed sidebar.
            "absolute bottom-0 flex h-auto max-h-screen-minus-16 flex-col gap-4 overflow-y-auto rounded-t-lg pb-4 pt-8 md:static md:bottom-auto md:h-full md:max-h-none md:overflow-y-visible md:rounded-none",
            isInProject ? "bg-[#fafafa] shadow-inset-right-border-grey" : "bg-white",
          )}
          variants={{
            closed: {
              width: "auto",
              paddingLeft: 8,
              paddingRight: 8,
            },
            open: {
              width: is2Xl ? "290px" : isXl ? "260px" : isMd ? "230px" : "100%",
              paddingLeft: 32,
              paddingRight: isMd ? 1 : 32,
            },
          }}
          initial={isMd ? "closed" : "open"}
          animate={isWide ? "open" : "closed"}
          exit="closed"
          transition={{ duration: 0.2 }}
        >
          <div className={twJoin("flex flex-row items-center", isWide ? "mb-2" : "size-10 p-1")}>
            <img
              src={logoDark}
              className={twJoin("aspect-[80/39]", isWide ? "w-16" : "w-8")}
              alt="Area of People logo"
            />
          </div>
          <div className="flex flex-col gap-4">
            <NavigationLink
              to={routes.portfolio.overview()}
              exact
              icon={<Icon name={dotsGridIcon} size={16} />}
              isBig
              isFaded={isInProject}
            >
              {t("navigation.portfolio.portfolio")}
            </NavigationLink>
            <NavigationLink
              to={routes.portfolio.createMassMessage()}
              icon={<Icon name={messageSquareIcon} size={16} />}
              isFaded={isInProject}
              permission={() => showAlerts}
            >
              {t("navigation.portfolio.mass-message")}
            </NavigationLink>
            <NavigationLink
              to={routes.portfolio.tickets()}
              icon={<Icon name={toolIcon} size={16} />}
              isFaded={isInProject}
              permission={() => whereMoreThan(1, (x) => x.userRole !== "resident", connectedProjects.data)}
            >
              {t("navigation.portfolio.tickets")}
            </NavigationLink>
            <NavigationLink
              to={routes.alerts.list()}
              icon={<Icon name={alertTriangleIcon} size={16} />}
              isFaded={isInProject}
              permission={() => showAlerts}
            >
              {t("navigation.portfolio.alerts")}
            </NavigationLink>
            <NavigationLink
              icon={<Icon name={usersIcon} size={16} />}
              to={routes.portfolio.userLookup()}
              permission={(x) => x.isSuperAdmin}
              isFaded={isInProject}
            >
              {t("navigation.portfolio.user-search")}
            </NavigationLink>
            <NavigationLink
              icon={<Icon name={switchHorizontalIcon} size={16} />}
              to={routes.portfolio.integrations()}
              permission={() => !!showAvailableIntegrations}
              isFaded={isInProject}
            >
              {t("navigation.portfolio.integrations")}
            </NavigationLink>
            <NavigationLinkContainer
              icon={<Icon name={settingsO4Icon} size={16} />}
              isFaded={isInProject}
              title={t("navigation.portfolio.system-settings")}
            >
              <NavigationSubLink to={routes.projectConnections.list()} permission={(x) => x.isSuperAdmin}>
                {t("navigation.portfolio.project-connections")}
              </NavigationSubLink>
              <NavigationSubLink to={routes.platformGroups.list()} permission={(x) => x.isSuperAdmin}>
                {t("navigation.portfolio.platform-groups")}
              </NavigationSubLink>
              <NavigationSubLink to={routes.automatedSurveys.list()} permission={(x) => x.isSuperAdmin}>
                {t("navigation.portfolio.automated-surveys")}
              </NavigationSubLink>
              <NavigationSubLink to={routes.portfolio.newProject()} permission={(x) => x.isSuperAdmin}>
                {t("navigation.portfolio.new-project")}
              </NavigationSubLink>
            </NavigationLinkContainer>
          </div>

          {isMd && <hr className={twJoin("border-grey-lightest", isWide ? "ml-4 h-lg:my-5" : "h-sm:my-5")} />}

          {isMd && <ProjectsList isWide={isWide} inProject={isInProject} />}

          {!isInProject && <UserMenu isWide={isWide} isPortfolio />}
        </motion.div>

        {/* close portfolio sidebar */}
        {!isInProject && (
          <button
            className={twJoin("group relative left-0 hidden outline-none md:flex", isWide ? "bg-white px-4" : "px-1.5")}
            onClick={onToggle}
          >
            {isWide && (
              <div className="absolute inset-0 mx-auto h-full w-px bg-grey-lightest opacity-0 transition-all group-hover:bg-grey-lighter group-hover/portfolio-sidebar:opacity-100 group-focus-visible:bg-grey-lighter" />
            )}
            <div
              className={twJoin(
                "absolute mx-auto flex w-6 items-center justify-center rounded-full bg-grey-lightest p-1 text-grey opacity-0 group-hover/portfolio-sidebar:opacity-100 group-focus-visible:bg-grey-lighter group-focus-visible:text-grey-darker group-focus-visible:opacity-100 hocus:bg-grey-lighter hocus:text-grey-darker",
                isWide ? "left-1 top-16" : "-left-3 top-[68px]",
              )}
            >
              <div className={twJoin("", isWide ? "-ml-px" : "ml-px rotate-180")}>
                <Icon name={chevronLeftIcon} size={16} />
              </div>
            </div>
          </button>
        )}
      </div>

      {createPortal(
        <div
          className={twJoin("absolute inset-0 z-20", !isMd && isWide ? "pointer-events-auto" : "pointer-events-none")}
          aria-hidden={isMd || !isWide}
        >
          <AnimatePresence>
            {!isMd && isWide ? (
              <motion.button
                aria-hidden
                className="absolute inset-0 bg-grey-darkest/60"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={onToggle}
              />
            ) : null}
          </AnimatePresence>
        </div>,
        document.body,
      )}
    </NavigationContext.Provider>
  );
}

function ProjectsList({ isWide, inProject }: { isWide: boolean; inProject: boolean }) {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>("");
  const { changeProject, projectId } = useProjectContext();
  const { projects, recentProjects } = useRecentProjects(projectId, !inProject, 3);

  const [projectSwitcher, projectSwitcherHandlers] = useBool();

  const hasProjectSearch = projects.length > 3;

  useKey("Escape", projectSwitcherHandlers.setFalse, projectSwitcher);

  const lastOpenedProjects = useMemo(
    () => (recentProjects.length < 3 ? unionBy(recentProjects, projects, (x) => x.id) : recentProjects).slice(0, 3),
    [projects, recentProjects],
  );

  return (
    <div className="relative flex flex-col gap-2">
      {createPortal(
        <div
          className={twJoin(
            "pointer-events-none absolute inset-0 z-20",
            projectSwitcher ? "pointer-events-auto" : "pointer-events-none",
          )}
          aria-hidden={!projectSwitcher}
        >
          <AnimatePresence>
            {projectSwitcher ? (
              <motion.button
                aria-hidden
                className="absolute inset-0 bg-grey-darkest/60"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={projectSwitcherHandlers.setFalse}
              />
            ) : null}
          </AnimatePresence>
        </div>,
        document.body,
      )}

      {isWide && (
        <h2 className="flex items-center text-xl font-semibold">
          <span className="p-2">
            <Icon name={homesIcon} size={16} />
          </span>
          <span>{t("navigation.portfolio.projects")}</span>
        </h2>
      )}
      {hasProjectSearch ? (
        <div className="relative">
          {isWide ? (
            <SearchInput
              className="bg-transparent"
              placeholder={t("component.project-switcher.search.placeholder")}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onFocus={() => projectSwitcherHandlers.setTrue()}
            />
          ) : (
            <button
              className={twJoin(
                "flex w-full items-center justify-center rounded-lg p-3 outline-none hover:bg-aop-basic-blue-lightest hover:text-black focus-visible:bg-aop-basic-blue-lightest focus-visible:text-black",
                inProject ? "text-grey" : "",
              )}
              onClick={projectSwitcherHandlers.setTrue}
            >
              <Icon name={searchIcon} size={16} />
            </button>
          )}

          {projectSwitcher && (
            <div className={twJoin("absolute -top-4 z-30 w-[289px] max-w-[calc(100vw-32px)]", isWide ? "-left-4" : "")}>
              <div className="overflow-hidden rounded-lg bg-white p-4 shadow">
                <ProjectList
                  currentProjectId={projectId}
                  projects={projects}
                  onSelectProject={(x) => {
                    changeProject(x, true);
                    projectSwitcherHandlers.setFalse();
                  }}
                  maxProjects={3}
                  showCurrentProject={!inProject}
                  noBottomPadding
                />
              </div>
            </div>
          )}
        </div>
      ) : null}

      {lastOpenedProjects && (
        <div
          className={twJoin("flex flex-col", isWide ? "gap-2" : hasProjectSearch ? "mt-1.5 gap-3" : "-mt-0.5 gap-3")}
        >
          {isWide && hasProjectSearch && (
            <h3 className="mt-2 text-xs font-bold uppercase tracking-wide text-grey-darker">
              {t("navigation.portfolio.projects.last-opened")}
            </h3>
          )}
          {lastOpenedProjects.map((project) => (
            <Project
              key={project.id}
              logoUrl={project.logoUrl}
              name={project.name}
              location={project.city}
              isWide={isWide}
              isFaded={inProject}
              onClick={() => {
                changeProject(project.id, true);
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function Project({
  hasUnread,
  name,
  location,
  logoUrl,
  onClick,
  isWide,
  isFaded,
}: {
  name: string;
  location?: string;
  logoUrl: string;
  hasUnread?: boolean;
  onClick?: () => void;
  isWide?: boolean;
  isFaded?: boolean;
}): React.ReactNode {
  return (
    <button
      className={twJoin(
        "group relative flex items-center gap-4 rounded-md outline-none hover:bg-aop-basic-blue-lightest focus-visible:bg-aop-basic-blue-lightest",
        isWide ? "w-full p-2" : "mx-auto my-0.5 w-auto p-1",
      )}
      onClick={onClick}
    >
      <ProjectLogo
        className={twJoin(
          "shrink-0",
          isWide ? "size-10" : "size-8",
          isFaded
            ? "opacity-50 saturate-0 group-hover:opacity-100 group-hover:saturate-100 group-focus-visible:opacity-100 group-focus-visible:saturate-100"
            : "",
        )}
        src={logoUrl}
      />
      {isWide && (
        <div className="flex flex-1 flex-col items-start">
          <Subtitle2 className="line-clamp-1 break-all text-left">{name}</Subtitle2>
          {location ? <Overline2 className="text-grey-darker">{location}</Overline2> : null}
        </div>
      )}
      {hasUnread ? (
        <div className="ml-auto shrink-0 pr-4">
          <div className="size-2 rounded-full bg-red" />
        </div>
      ) : null}
      {!isWide && (
        <span className="pointer-events-none absolute left-full top-0 z-30 mb-3 ml-2 flex flex-col whitespace-nowrap rounded-lg bg-black p-2 font-semibold text-white opacity-0 transition-opacity delay-300 group-hover:opacity-100 group-focus-visible:opacity-100 group-has-[:focus-visible]/sidebar:delay-0">
          {name}
        </span>
      )}
    </button>
  );
}

function whereMoreThan<T>(count: number, where: (item: T) => boolean, list: T[] | undefined): boolean {
  if (!list) {
    return false;
  }

  let items = 0;
  for (const item of list) {
    if (where(item)) {
      items++;
      if (items >= count) {
        return true;
      }
    }
  }

  return false;
}
