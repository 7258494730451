import type { InputProps } from "components/Input/Input";
import { Input } from "components/Input/Input";
import { forwardRef } from "react";
import { Search as SearchIcon } from "react-feather";

export interface SearchInputProps extends Omit<InputProps, "prefix" | "inputMode" | "type"> {}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  function SearchInput(props, ref): React.ReactNode {
    return <Input ref={ref} prefix={<SearchIcon size={16} />} inputMode="search" type="search" {...props} />;
  },
);
