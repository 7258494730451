import type { SelfUserRolePermissionsDto } from "api/types";
import { cloneDeep } from "lodash-es";
import type { ExcludeByValue, IncludeByValue } from "types/utility-types";

import { useSessionUserOptional } from "./Network/useSessionUser";

type TicketCategoryPermissionSelector = (
  x: IncludeByValue<SelfUserRolePermissionsDto["repairCategories"][number], boolean>,
) => boolean;

export type PermissionSelector = (
  x: ExcludeByValue<SelfUserRolePermissionsDto, Array<any>> & {
    isSuperAdmin: boolean;
    isAdmin: boolean;
    isResident: boolean;
    isMaintenance: boolean;
    isCaretaker: boolean;
    hasTicketCategoryPermission: (categoryId: string, selector: TicketCategoryPermissionSelector) => boolean;
  },
) => boolean;

export function usePermission(): (permission: PermissionSelector) => boolean {
  const [user] = useSessionUserOptional({ keepPreviousData: true });

  if (!user) {
    // If the user is not loaded yet, we return false for all permissions.
    return () => false;
  }

  // Super admins are a special role. They return false for all permissions for security reasons.
  // Array values (like ticket categories) are not returned because it's a shared non-project specific role.
  // Thus they have to be handled differently here.

  const permissions = user.isSuperAdmin ? makeAllBoolsInObjectTrueDeep(user.role.permissions) : user.role.permissions;

  return (selector) =>
    selector({
      ...permissions,
      isSuperAdmin: user.isSuperAdmin,
      isAdmin: user.role.type !== "resident",
      isResident: user.role.type === "resident",
      isMaintenance: user.role.type === "maintenance",
      isCaretaker: user.role.type === "caretaker",
      hasTicketCategoryPermission: (categoryId, selector) => {
        if (user.isSuperAdmin) {
          return true;
        }

        const category = permissions.repairCategories.find((x) => x.categoryId === categoryId);
        if (category === undefined) {
          return false;
        }

        return selector(category);
      },
    });
}

export function useResolvedPermission(selector: PermissionSelector): boolean {
  const hasPermission = usePermission();

  return hasPermission(selector);
}

function makeAllBoolsInObjectTrueDeep<T extends object>(object: T) {
  const root = cloneDeep(object);

  for (const key in root) {
    const value = root[key];

    if (Array.isArray(value)) {
      (root[key] as any) = value.map((x) => makeAllBoolsInObjectTrueDeep(x));
    } else if (typeof value === "object") {
      if (value) {
        (root[key] as any) = makeAllBoolsInObjectTrueDeep(value);
      }
    } else if (typeof value === "boolean") {
      (root[key] as any) = true;
    }
  }

  return object;
}
