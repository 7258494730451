import type { DragEndEvent, DragStartEvent } from "@dnd-kit/core";
import { Button, IconButton } from "components/Button/Button";
import { DynamicRepeater } from "components/DynamicRepeater/DynamicRepeater";
import { DynamicRepeaterItem } from "components/DynamicRepeater/DynamicRepeaterItem";
import { DynamicRepeaterItemOverlay } from "components/DynamicRepeater/DynamicRepeaterItemOverlay";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { FormSelect } from "components/Form/FormSelect";
import { FormTextArea } from "components/Form/FormTextArea";
import { InfoIcon } from "components/InfoIcon/InfoIcon";
import { createRequiredStringRule } from "helpers/rules";
import type { QuestionType } from "modules/surveys/constants";
import { useState } from "react";
import { Trash2 as TrashIcon } from "react-feather";
import type { Control, FieldArrayWithId, UseFieldArrayRemove, UseFieldArrayUpdate } from "react-hook-form";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type { FormValues } from "../Layout";

interface Props {
  idx: number;
  control: Control<FormValues>;
  updateField: UseFieldArrayUpdate<FormValues, "questions">;
  removeField: UseFieldArrayRemove;
  types: QuestionType[];
  question: FieldArrayWithId<FormValues, "questions", "id">;
  showOptions?: boolean;
  showDelete?: boolean;
  showUseForAnalysisOption?: boolean;
  isUseForAnalysisDisabled?: boolean;
}

const MAX_LENGTH_QUESTION = 255;

export function FormQuestion({
  idx,
  control,
  updateField,
  removeField,
  types,
  question,
  showOptions,
  showDelete,
  showUseForAnalysisOption,
  isUseForAnalysisDisabled,
}: Props): React.ReactNode {
  const { t } = useTranslation();
  const [dragItemId, setDragItemId] = useState<number | null>(null);
  const { fields, update, append, remove, move } = useFieldArray({
    control: control,
    name: `questions.${idx}.options`,
  });

  function handleDragStart(event: DragStartEvent) {
    setDragItemId(event.active.data.current?.sortable.index);
  }

  function handleDragEnd(event: DragEndEvent) {
    setDragItemId(null);
    const { active, over } = event;

    if (over && active.id !== over.id) {
      if (active.data.current && active.data.current.sortable && over.data.current && over.data.current.sortable) {
        move(active.data.current.sortable.index, over.data.current.sortable.index);
      }
    }
  }

  return (
    <div className="flex flex-col gap-6" data-testid="form-question">
      <FormContent maxWidth="2xl">
        <FormField label={t("page.surveys.create-or-edit.form.question.type.label")} required>
          <FormSelect<FormValues, QuestionType>
            name={`questions.${idx}.type`}
            items={types}
            defaultValue={question.type}
            keySelector={(x) => x}
            renderOption={(x) => {
              switch (x) {
                case "multipleChoice":
                  return t("page.surveys.create-or-edit.question.type.multiple-choice");
                case "yesNo":
                  return t("page.surveys.create-or-edit.question.type.yes-no");
                case "open":
                  return t("page.surveys.create-or-edit.question.type.open");
                case "rating":
                  return t("page.surveys.create-or-edit.question.type.rating");
              }
            }}
            // This update forces a re-render, so that we can show the delete buttons on the right questions
            onChange={(type) => updateField(idx, { ...question, type: type! })}
            rules={{
              required: t("components.form.error.required", {
                inputName: t("page.surveys.create-or-edit.form.question.type.label"),
              }),
            }}
          />
        </FormField>
        <FormField label={t("page.surveys.create-or-edit.form.question.text.label")} required>
          <FormTextArea<FormValues>
            name={`questions.${idx}.question`}
            onBlur={(e) => updateField(idx, { ...question, question: e ? e.target.value : "" })}
            placeholder={t("page.surveys.create-or-edit.form.question.text.placeholder")}
            rules={{
              maxLength: {
                message: t("components.form.error.max-length", { length: MAX_LENGTH_QUESTION }),
                value: MAX_LENGTH_QUESTION,
              },
              validate: {
                required: createRequiredStringRule(t, "page.surveys.create-or-edit.form.question.type.label"),
              },
            }}
          />
        </FormField>
        {showOptions && (
          <DynamicRepeater
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            itemIds={fields.map((field) => field.id)}
          >
            {fields.map((option, optionIdx) => (
              <DynamicRepeaterItem key={option.id} id={option.id} disabled={false}>
                <FormOption
                  questionIdx={idx}
                  optionIdx={optionIdx}
                  optionId={option.oId}
                  updateField={update}
                  removeField={remove}
                  showDelete={!!showDelete}
                />
              </DynamicRepeaterItem>
            ))}
            <DynamicRepeaterItemOverlay
              show={dragItemId !== null}
              title={t("page.surveys.create-or-edit.form.question.option.label", { count: dragItemId! + 1 })}
            >
              <FormOption
                questionIdx={idx}
                optionIdx={dragItemId!}
                updateField={() => null}
                removeField={() => null}
                showDelete={!!showDelete}
              />
            </DynamicRepeaterItemOverlay>
          </DynamicRepeater>
        )}
        {question.type === "rating" && showUseForAnalysisOption && (
          <span className="flex items-center gap-2">
            <FormCheckbox<FormValues>
              name={`questions.${idx}.useForAnalysis`}
              label={t("page.surveys.create-or-edit.form.question.use-for-analysis.label")}
              disabled={isUseForAnalysisDisabled}
              onChange={(e) => updateField(idx, { ...question, useForAnalysis: e.target.checked })}
            />
            <InfoIcon tooltip={t("page.surveys.create-or-edit.form.question.use-for-analysis.tooltip")} />
          </span>
        )}
        {question.type === "multipleChoice" && (
          <Button
            data-testid="add-option"
            styling="primaryFaded"
            onClick={() => append({ option: "" }, { shouldFocus: false })}
          >
            {t("page.surveys.create-or-edit.form.question.option.add")}
          </Button>
        )}
      </FormContent>
      {showDelete && (
        <div className="self-end">
          <Button styling="primaryRed" onClick={() => removeField(idx)}>
            {t("page.surveys.create-or-edit.question.delete")}
          </Button>
        </div>
      )}
    </div>
  );
}

interface FormOptionProps {
  questionIdx: number;
  optionIdx: number;
  optionId?: string;
  updateField: UseFieldArrayUpdate<FormValues, `questions.${number}.options`>;
  removeField: UseFieldArrayRemove;
  showDelete: boolean;
}

function FormOption({
  questionIdx,
  optionIdx,
  optionId,
  updateField,
  removeField,
  showDelete,
}: FormOptionProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div className="flex items-end gap-2">
      <FormField label={t("page.surveys.create-or-edit.form.question.option.label", { count: optionIdx + 1 })} required>
        <FormInput<FormValues>
          name={`questions.${questionIdx}.options.${optionIdx}.option`}
          onBlur={(e) => updateField(optionIdx, { oId: optionId, option: e ? e.target.value : "" })}
          placeholder={t("page.surveys.create-or-edit.form.question.option.placeholder")}
          rules={{
            maxLength: {
              message: t("components.form.error.max-length", { length: MAX_LENGTH_QUESTION }),
              value: MAX_LENGTH_QUESTION,
            },
            validate: {
              required: createRequiredStringRule(t, "page.surveys.create-or-edit.form.question.type.label"),
            },
          }}
        />
      </FormField>
      {showDelete ? (
        <IconButton
          className="text-red"
          title={t("page.surveys.create-or-edit.form.question.option.delete")}
          onClick={() => removeField(optionIdx)}
        >
          <TrashIcon size={24} />
        </IconButton>
      ) : null}
    </div>
  );
}
