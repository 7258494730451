import { ErrorPage } from "components/Error/ErrorPage";
import { useConfig } from "hooks/useConfig";
import type { RouteObject } from "react-router-dom";

import { ChatDetailPage } from "./pages/Detail/ChatDetailPage";
import { ChatListPage } from "./pages/List";
import { ChatListPageOld } from "./pages/ListOld";
import { chatsRoutes } from "./routes";

export const chatsRoute = [
  {
    path: chatsRoutes.list,
    element: <ChatFeatureWrapper />,
  },
  {
    path: chatsRoutes.details,
    element: <ChatDetailPage />,
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];

function ChatFeatureWrapper() {
  const { loading, setting } = useConfig("hasChatsV2Migrated", false);

  if (loading) {
    return null;
  }

  if (setting) {
    return <ChatListPage />;
  } else {
    return <ChatListPageOld />;
  }
}
