import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import barchartIcon from "assets/icons/bar-chart-01.svg";
import bellIcon from "assets/icons/bell-01.svg";
import calendarIcon from "assets/icons/calendar.svg";
import bookingIcon from "assets/icons/calendar-heart-02.svg";
import chevronDownIcon from "assets/icons/chevron-down.svg";
import chevronLeftIcon from "assets/icons/chevron-left.svg";
import dotsGridIcon from "assets/icons/dots-grid.svg";
import homeIcon from "assets/icons/home-02.svg";
import inbox01Icon from "assets/icons/inbox-01.svg";
import chatsIcon from "assets/icons/message-circle-01.svg";
import messagesIcon from "assets/icons/message-square-01.svg";
import piechartIcon from "assets/icons/pie-chart-01.svg";
import searchRefractionIcon from "assets/icons/search-refraction.svg";
import settingsIcon from "assets/icons/settings-01.svg";
import stars02Icon from "assets/icons/stars-02.svg";
import toolIcon from "assets/icons/tool-02.svg";
import logoDark from "assets/images/logo-dark.svg";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { ProjectLogo } from "components/ProjectLogo/ProjectLogo";
import { ProjectSwitcher } from "components/ProjectSwitcher/ProjectSwitcher";
import { Subtitle2 } from "components/Text/Text";
import { AnimatePresence, motion } from "framer-motion";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUserOptional } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useConfig } from "hooks/useConfig";
import { useKey } from "hooks/useKey";
import { usePermission } from "hooks/usePermission";
import { useScreenIsBiggerThan } from "hooks/useScreenIsBiggerThan";
import { useSlug } from "hooks/useSlug";
import { canList as canListDocuments } from "modules/documents/permissions";
import { canList as canListEvents } from "modules/events/permissions";
import { canListMessages } from "modules/messages/permissions";
import { canSeeList as canListServicePartners } from "modules/service-partners/permissions";
import { canListAnySurvey } from "modules/surveys/permissions";
import { canListUsers } from "modules/users/permissions";
import { QUERY_KEYS } from "query-keys";
import { memo, useMemo } from "react";
import { createPortal } from "react-dom";
import { X as XIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

import { NavigationContext } from "./NavigationContext";
import { NavigationLink } from "./NavigationLink";
import { NavigationLinkContainer } from "./NavigationLinkContainer";
import { NavigationSubLink } from "./NavigationSubLink";
import { UserMenu } from "./UserMenu";

interface ProjectSidebarProps {
  isWide: boolean;
  onToggle: () => void;
}

export const ProjectSidebar = memo(ProjectSidebarContent);

function ProjectSidebarContent({ isWide, onToggle: onClose }: ProjectSidebarProps): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { t } = useTranslation();
  // sessionUser and project can be undefined during a project switch
  const [sessionUser, { isPlaceholderData }] = useSessionUserOptional({ keepPreviousData: true });
  const project = sessionUser?.project;
  const hasPermission = usePermission();
  const { setting: allowBookings, loading: isLoadingBookingsConfig } = useConfig("allowBookings");
  const api = useApi();

  const { data: chatsStatus } = useQuery({
    queryKey: QUERY_KEYS.CHATS_STATUS(projectId),
    queryFn: () => api.getChatsStatusV1(),
    select: commonAPIDataSelector,
    staleTime: 5000,
  });

  const { data: adminNotificationStats } = useQuery({
    queryKey: QUERY_KEYS.ADMIN_NOTIFICATION_STATUS(projectId),
    queryFn: () => api.getAdminNotificationsStatusV1(),
    select: commonAPIDataSelector,
    staleTime: 30 * 1000,
    refetchInterval: 30 * 1000,
    enabled: sessionUser?.isAdmin,
  });

  const isLoading = isLoadingBookingsConfig;

  const [projectSwitcher, projectSwitcherHandlers] = useBool();

  useKey("Escape", projectSwitcherHandlers.setFalse, projectSwitcher);

  const connectedProjects = useConnectedProjects();

  const canSwitchProjects = connectedProjects.data && connectedProjects.data?.length > 1;
  const canViewPortfolio = useMemo(
    () => connectedProjects.data?.some((x) => x.userRole !== "resident"),
    [connectedProjects.data],
  );

  const showNotifications = sessionUser?.isAdmin;

  const isMd = useScreenIsBiggerThan("md");
  const isXl = useScreenIsBiggerThan("xl");
  const is2Xl = useScreenIsBiggerThan("2xl");

  return (
    <NavigationContext.Provider value={{ isWide }}>
      <div
        className={twJoin(
          "group/project-sidebar absolute bottom-0 z-30 grid max-h-screen-minus-16 w-full grid-cols-[1fr,min-content] rounded-t-xl bg-white md:static md:z-auto md:max-h-none md:w-auto md:rounded-t-none",
          isWide ? "overflow-y-auto" : "-mr-3 overflow-x-visible",
        )}
      >
        <div className="relative flex">
          {!isMd && (
            <button
              className="absolute right-4 top-4 rounded p-0.5 text-grey focus-within:bg-grey-light focus-within:text-white hover:bg-grey-light hover:text-white focus:outline-none"
              aria-label={t("common.action.cancel")}
              onClick={onClose}
            >
              <XIcon size={24} />
            </button>
          )}

          <motion.div
            className="flex flex-col gap-4 overflow-x-visible bg-white pt-8 md:flex-auto"
            variants={{
              closed: {
                width: "auto",
                paddingLeft: "8px",
                paddingRight: "8px",
                paddingBottom: "0px",
              },
              open: {
                width: is2Xl ? "290px" : isXl ? "260px" : isMd ? "230px" : "100%",
                paddingLeft: "16px",
                paddingRight: isMd ? "0" : "16px",
                paddingBottom: "8px",
              },
            }}
            initial={isMd ? "closed" : "open"}
            animate={isWide ? "open" : "closed"}
            exit="closed"
            transition={{ duration: 0.2 }}
          >
            {isMd ? (
              isPlaceholderData ? (
                <div className="flex h-10 items-center justify-center">
                  <LoadingIcon className="w-7 animate-fade-in-delay opacity-0" />
                </div>
              ) : canSwitchProjects ? (
                <button
                  className={twJoin(
                    "flex flex-row items-center gap-1.5 overflow-visible rounded-lg outline-none transition-colors hover:bg-aop-basic-blue-lightest focus-visible:bg-aop-basic-blue-lightest",
                    isWide ? "-my-1.5 p-1.5 pr-2" : "p-1",
                  )}
                  onClick={projectSwitcherHandlers.toggle}
                >
                  <ProjectLogo className={twJoin("shrink-0", isWide ? "size-10" : "size-8")} src={project?.logoUrl} />
                  {isWide && (
                    <>
                      <Subtitle2 className="line-clamp-1 flex-1 break-all text-left">{project?.name}</Subtitle2>
                      <Icon name={chevronDownIcon} />
                    </>
                  )}
                </button>
              ) : (
                <div
                  className={twJoin(
                    "flex flex-row items-center gap-1.5 overflow-visible rounded-lg",
                    isWide ? "-my-1.5 p-1.5 pr-2" : "p-1",
                  )}
                >
                  <ProjectLogo className={twJoin("shrink-0", isWide ? "size-10" : "size-8")} src={project?.logoUrl} />
                  {isWide && <Subtitle2 className="line-clamp-1 break-all text-left">{project?.name}</Subtitle2>}
                </div>
              )
            ) : (
              <div className="flex flex-col gap-2">
                <div className="mb-2 mt-6">
                  <img src={logoDark} className="aspect-[80/39] w-16" alt="Area of People logo" />
                </div>
                {canViewPortfolio ? (
                  <NavigationLink
                    to={routes.portfolio.overview()}
                    exact
                    icon={<Icon name={dotsGridIcon} size={16} />}
                    isBig
                  >
                    {t("navigation.portfolio.portfolio")}
                  </NavigationLink>
                ) : null}
              </div>
            )}
            {/* Home */}
            <NavigationLink
              to={routes.home.home({ slug })}
              icon={<Icon name={homeIcon} />}
              permission={(x) => x.isAdmin && !x.isMaintenance}
              exact
            >
              {t("navigation.home")}
            </NavigationLink>
            {/* Discover */}
            <NavigationLinkContainer
              icon={<Icon name={searchRefractionIcon} />}
              title={t("navigation.discover")}
              data-testid="navigation-discovery-group"
            >
              <NavigationSubLink
                to={routes.users.list({ slug })}
                permission={(x) => x.isAdmin && x.userManagement.canListUsers}
              >
                {t("navigation.discover.neighbours")}
              </NavigationSubLink>
              <NavigationSubLink
                to={routes.addresses.list({ slug })}
                permission={(x) => x.isAdmin && x.addressManagement.canListAddresses}
              >
                {t("navigation.discover.addresses")}
              </NavigationSubLink>
              {project?.type === "companyBased" ? (
                <NavigationSubLink
                  to={routes.companies.list({ slug })}
                  permission={(x) => x.addressManagement.canManageCompanies}
                >
                  {t("navigation.discover.companies")}
                </NavigationSubLink>
              ) : null}
              <NavigationSubLink
                to={routes.interestGroups.list({ slug })}
                data-testid="navigation-link-interest-groups"
              >
                {t("navigation.discover.groups")}
              </NavigationSubLink>
              <NavigationSubLink
                to={routes.helpCategories.list({ slug })}
                data-testid="navigation-link-help-categories"
              >
                {t("navigation.discover.help")}
              </NavigationSubLink>
            </NavigationLinkContainer>
            {/* Communityfeed */}
            <NavigationLink
              to={routes.messageFeed.list({ slug })}
              permission={canListMessages}
              icon={<Icon name={messagesIcon} />}
            >
              {t("navigation.community-feed")}
            </NavigationLink>
            {/* Chats */}
            <NavigationLink
              to={routes.chats.list({ slug })}
              icon={<Icon name={chatsIcon} />}
              isUnread={chatsStatus?.hasNewMessages}
              permission={canListUsers}
            >
              {t("navigation.chats")}
            </NavigationLink>
            <NavigationLink
              data-testid="navigation-calendar"
              to={routes.calendar.list({ slug })}
              icon={<Icon name={calendarIcon} />}
              permission={canListEvents}
            >
              {t("navigation.calendar")}
            </NavigationLink>
            {/* Services */}
            <NavigationLink
              to={routes.servicePartners.list({ slug })}
              data-testid="navigation-service-list"
              icon={<Icon name={stars02Icon} />}
              permission={canListServicePartners}
            >
              {t("navigation.services")}
            </NavigationLink>
            {/* Bookings */}
            {allowBookings ? (
              hasPermission((x) => x.assets.canViewSchedule) ? (
                <NavigationLinkContainer
                  icon={<Icon name={bookingIcon} />}
                  title={t("navigation.bookings")}
                  canCombineIntoOneLink
                >
                  <NavigationSubLink to={routes.bookings.list({ slug })}>
                    {t("navigation.bookings.assets")}
                  </NavigationSubLink>
                  <NavigationSubLink to={routes.reservations.list({ slug })}>
                    {t("navigation.bookings.reservations")}
                  </NavigationSubLink>
                </NavigationLinkContainer>
              ) : (
                <NavigationLink to={routes.bookings.list({ slug })} icon={<Icon name={bookingIcon} />}>
                  {t("navigation.bookings")}
                </NavigationLink>
              )
            ) : null}
            {/* Tickets */}
            {sessionUser?.role.type === "resident" ? (
              <NavigationLink
                to={routes.tickets.list({ slug })}
                icon={<Icon name={toolIcon} />}
                data-testid="navigation-resident-tickets"
                permission={(x) => x.canCreateTicket}
              >
                {t("navigation.resident-tickets")}
              </NavigationLink>
            ) : (
              <NavigationLink
                to={routes.tickets.list({ slug })}
                icon={<Icon name={toolIcon} />}
                data-testid="navigation-tickets"
              >
                {t("navigation.tickets")}
              </NavigationLink>
            )}
            {/* Practical and more */}
            <NavigationLink
              data-testid="navigation-documents-root-list"
              to={routes.documents.rootList({ slug })}
              icon={<Icon name={inbox01Icon} />}
              permission={canListDocuments}
            >
              {t("navigation.documents")}
            </NavigationLink>

            {/* Surveys */}
            <NavigationLinkContainer
              icon={<Icon name={barchartIcon} />}
              title={t("navigation.surveys-group")}
              canCombineIntoOneLink
            >
              <NavigationSubLink to={routes.surveys.list({ slug })} permission={canListAnySurvey}>
                {t("navigation.surveys")}
              </NavigationSubLink>
              <NavigationSubLink to={routes.automatedSurveyQueues.list({ slug })} permission={(x) => x.isSuperAdmin}>
                {t("navigation.automated-survey-queues")}
              </NavigationSubLink>
            </NavigationLinkContainer>

            <NavigationLink
              to={routes.analytics.overview({ slug })}
              icon={<Icon name={piechartIcon} />}
              permission={(x) => x.isAdmin && !x.isMaintenance && !x.isCaretaker}
            >
              {t("navigation.analytics")}
            </NavigationLink>
            {/* Administration */}
            <NavigationLinkContainer
              icon={<Icon name={settingsIcon} />}
              title={t("navigation.admin")}
              data-testid="navigation-management-group"
              isBottomOfScreen
            >
              <NavigationSubLink
                to={routes.buildings.list({ slug })}
                permission={(x) => x.addressManagement.canManageBuildings}
              >
                {t("navigation.admin.buildings")}
              </NavigationSubLink>
              <NavigationSubLink to={routes.admin.addressBulkUpload({ slug })} permission={(x) => x.isSuperAdmin}>
                {t("navigation.admin.addresses")}
              </NavigationSubLink>
              <NavigationSubLink
                to={routes.admin.companyBulkUpload({ slug })}
                permission={(x) => x.isSuperAdmin && project?.type === "companyBased"}
              >
                {t("navigation.admin.companies")}
              </NavigationSubLink>
              <NavigationSubLink to={routes.admin.userBulkUpload({ slug })} permission={(x) => x.isSuperAdmin}>
                {t("navigation.platform.user-upload")}
              </NavigationSubLink>
              <NavigationSubLink to={routes.projects.edit({ slug })} permission={(x) => x.isSuperAdmin}>
                {t("navigation.admin.settings")}
              </NavigationSubLink>
              <NavigationSubLink to={routes.projects.styling({ slug })} permission={(x) => x.isSuperAdmin}>
                {t("navigation.admin.styling")}
              </NavigationSubLink>
              <NavigationSubLink to={routes.onboardingScreens.list({ slug })} permission={(x) => x.isSuperAdmin}>
                {t("navigation.admin.onboarding")}
              </NavigationSubLink>
              <NavigationSubLink
                to={routes.roles.list({ slug })}
                permission={(x) => x.userManagement.canManageRoles}
                data-testid="navigation-role-list"
              >
                {t("navigation.admin.roles")}
              </NavigationSubLink>
              <NavigationSubLink to={routes.projects.plusButtonOptions({ slug })} permission={(x) => x.isSuperAdmin}>
                {t("navigation.admin.plus-button")}
              </NavigationSubLink>
              <NavigationSubLink to={routes.projects.integrations({ slug })} permission={(x) => x.isSuperAdmin}>
                {t("navigation.admin.integrations")}
              </NavigationSubLink>
            </NavigationLinkContainer>

            {showNotifications && isMd ? (
              <div className="mt-auto flex flex-col gap-2 py-4">
                {isWide ? (
                  <h3 className="text-xs font-bold uppercase tracking-wide text-grey-darker">
                    {t("navigation.notifications.section-title")}
                  </h3>
                ) : null}
                <NavigationLink
                  permission={(x) => x.isAdmin}
                  unreadCount={adminNotificationStats?.amount}
                  isUnread={adminNotificationStats?.hasUnread}
                  icon={<Icon name={bellIcon} size={16} />}
                  to={routes.adminNotifications.list({ slug })}
                >
                  {t("navigation.notifications.title")}
                </NavigationLink>
              </div>
            ) : null}

            <div className="mt-auto pb-2">
              <UserMenu isWide={isWide} />
            </div>
          </motion.div>

          <AnimatePresence>
            {(!sessionUser || isLoading) && (
              <motion.div className="absolute inset-0 bg-white">
                <FullSizeLoader size="small" />
              </motion.div>
            )}
          </AnimatePresence>

          {createPortal(
            <div
              className={twJoin(
                "absolute inset-0 z-20",
                projectSwitcher ? "pointer-events-auto" : "pointer-events-none",
              )}
              aria-hidden={!projectSwitcher}
            >
              <AnimatePresence>
                {projectSwitcher ? (
                  <motion.button
                    aria-hidden
                    className="absolute inset-0 bg-grey-darkest/60"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={projectSwitcherHandlers.setFalse}
                  />
                ) : null}
              </AnimatePresence>

              <div className="absolute left-16 top-0 max-w-sm md:w-96">
                <ProjectSwitcher
                  isOpen={projectSwitcher}
                  onClose={projectSwitcherHandlers.setFalse}
                  showRecentProjects
                  showCurrentProject={false}
                />
              </div>
            </div>,
            document.body,
          )}

          {createPortal(
            <div
              className={twJoin(
                "absolute inset-0 z-20",
                !isMd && isWide ? "pointer-events-auto" : "pointer-events-none",
              )}
              aria-hidden={isMd || !isWide}
            >
              <AnimatePresence>
                {!isMd && isWide ? (
                  <motion.button
                    aria-hidden
                    className="absolute inset-0 bg-grey-darkest/60"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={onClose}
                  />
                ) : null}
              </AnimatePresence>
            </div>,
            document.body,
          )}

          {/* close portfolio sidebar */}
          <button
            className={twJoin(
              "group relative left-0 z-10 hidden h-full outline-none md:flex",
              isWide ? "bg-white px-4" : "bg-aop-off-white px-1.5",
            )}
            onClick={onClose}
          >
            {isWide && (
              <div className="absolute inset-0 mx-auto h-full w-px bg-grey-lightest opacity-0 transition-all group-hover:bg-grey-lighter group-hover/project-sidebar:opacity-100 group-focus-visible:bg-grey-lighter" />
            )}
            <div
              className={twJoin(
                "absolute mx-auto flex w-6 items-center justify-center rounded-full bg-grey-lightest p-1 text-grey opacity-0 group-hover/project-sidebar:opacity-100 group-focus-visible:bg-grey-lighter group-focus-visible:text-grey-darker group-focus-visible:opacity-100 hocus:bg-grey-lighter hocus:text-grey-darker",
                isWide ? "left-1 top-[70px]" : "-left-3 top-[68px]",
              )}
            >
              <div className={twJoin(isWide ? "-ml-px" : "ml-px rotate-180")}>
                <Icon name={chevronLeftIcon} size={16} />
              </div>
            </div>
          </button>
        </div>
      </div>
    </NavigationContext.Provider>
  );
}
