import React from "react";

export interface SecurityData {
  getToken?: () => Promise<string | undefined>;
}

const SecurityDataContext = React.createContext<SecurityData | undefined>(undefined);

export const useSecurityData = (): SecurityData | undefined => React.useContext(SecurityDataContext);

export const SecurityDataProvider = SecurityDataContext.Provider;
