import type { SilentRequest } from "@azure/msal-browser";
import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { ApiClientProvider } from "api/ApiClientProvider";
import { useMsalConfigContext } from "authentication/MsalConfigContext";
import { SecurityDataProvider } from "authentication/SecurityDataContext";
import type React from "react";
import { useCallback, useEffect, useMemo, useRef } from "react";

// Map to prevent calling acquireTokenSilent multiple times for the same request
const globalRef = new Map<string, Promise<string | undefined>>();

export function AuthenticationTokenProvider({ children }: React.PropsWithChildren<{}>): React.ReactNode {
  const { instance, inProgress, accounts } = useMsal();
  const msalContext = useMsalConfigContext();

  const interactionInProgress = useRef(inProgress !== InteractionStatus.None);
  interactionInProgress.current = inProgress !== InteractionStatus.None;

  const getToken = useCallback(() => {
    const cacheKey = JSON.stringify(msalContext.authRequest);
    const item = globalRef.get(cacheKey);
    if (item) {
      return item;
    }

    const request: SilentRequest = { ...msalContext.authRequest };
    // To make sure we get a token for the right policy
    if (accounts[0]?.idTokenClaims?.tfp === msalContext.b2cPolicies.names.signUp) {
      request.authority = msalContext.b2cPolicies.authorities.signUp.authority;
    }

    const method = instance
      .acquireTokenSilent(request)
      .then((data) => data.accessToken)
      .catch(async (error) => {
        if (error.name == "InteractionRequiredAuthError") {
          if (!interactionInProgress.current) {
            await instance.loginRedirect({
              ...msalContext.authRequest,
              loginHint: accounts[0]?.username,
              extraQueryParameters: {
                auto_focus: "true",
              },
            });
          }
        }

        return undefined;
      })
      .finally(() => {
        globalRef.delete(cacheKey);
      });

    globalRef.set(cacheKey, method);

    return method;
  }, [
    accounts,
    instance,
    msalContext.b2cPolicies.authorities.signUp.authority,
    msalContext.b2cPolicies.names.signUp,
    msalContext.authRequest,
  ]);

  useEffect(() => {
    window.addEventListener("focus", getToken);

    return () => {
      window.removeEventListener("focus", getToken);
    };
  }, [getToken]);

  const data = useMemo(() => ({ getToken }), [getToken]);

  return (
    <SecurityDataProvider value={data}>
      <ApiClientProvider>{children}</ApiClientProvider>
    </SecurityDataProvider>
  );
}
