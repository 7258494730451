import { twResolve } from "helpers/tw-resolve";
import type React from "react";
import { forwardRef } from "react";
import { Check as CheckIcon } from "react-feather";

export type RadioProps = {
  name: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
  disabled?: boolean;
  "data-testid"?: string;
  defaultChecked?: boolean;
} & ({ value: string } | { checked: boolean });

export const Radio = forwardRef(function Radio(
  { className, ...props }: RadioProps,
  ref: React.ForwardedRef<HTMLInputElement>,
): React.ReactNode {
  return (
    <div className={twResolve("relative inline-flex size-4 shrink-0", className)}>
      <input
        {...props}
        className="peer absolute z-10 size-full opacity-0 disabled:pointer-events-none"
        ref={ref}
        type="radio"
      />
      <div className="flex size-full items-center justify-center rounded-full border border-grey-light bg-white text-white peer-checked:bg-aop-basic-blue peer-hover:bg-blue-lightest peer-focus-visible:ring-1 peer-focus-visible:ring-grey-darkest peer-focus-visible:ring-offset-1 peer-disabled:bg-grey-lightest peer-checked-hover:bg-aop-basic-blue-dark peer-checked-focus-visible:checked:ring-aop-basic-blue-dark peer-checked-disabled:bg-grey-lighter peer-checked-all-child:block">
        <CheckIcon className="absolute hidden" size={12} />
      </div>
    </div>
  );
});
