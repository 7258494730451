import type { AdminTicketDetailsDto } from "api/types";
import { LinkButton } from "components/Button/Button";
import { FlagIcon } from "components/Icons/Icons";
import { Capture1, Capture2, Subtitle1, Subtitle2 } from "components/Text/Text";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { formatAddress } from "helpers/address";
import { useLanguages } from "helpers/languages";
import { useSlug } from "hooks/useSlug";
import { Mail as MailIcon, Phone as PhoneIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

export function TicketResidentInfo({
  ticket,
  isGray,
}: {
  ticket: AdminTicketDetailsDto;
  isGray?: boolean;
}): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const { data: languages } = useLanguages();

  const language = languages?.find((x) => x.id === ticket.user.languageId);

  return (
    <div className={twJoin("flex flex-col gap-4 rounded-lg p-4", isGray ? "bg-grey-lightest" : "bg-white")}>
      <Subtitle1 className="text-left">{t("page.tickets.details.user.reporter")}</Subtitle1>
      <div className="flex flex-row items-center gap-2">
        <div className="size-24 self-center">
          <UserAvatar img={ticket.user.avatar} isUserDeleted={!!ticket.user.deletedAt} />
        </div>
        <div className="flex flex-col">
          <Subtitle2 className="line-clamp-1">{ticket.user.fullName}</Subtitle2>
          <div className="text-grey-darker">
            {ticket.company ? <p>{ticket.company.name}</p> : null}
            {ticket.address ? <p>{formatAddress(ticket.address)}</p> : null}
            {ticket.address ? (
              <p>
                {ticket.address.building.name}
                {ticket.address.floor ? ` - ${ticket.address.floor}` : ""}
              </p>
            ) : null}
          </div>
        </div>
      </div>
      {ticket.user.canViewProfile ? (
        <LinkButton styling="primaryFaded" to={routes.users.details({ slug, id: ticket.user.id })} target="_blank">
          {t("page.tickets.details.user.link")}
        </LinkButton>
      ) : null}
      <div>
        <Capture1>{t("page.tickets.details.user.contact-details")}</Capture1>
        <hr className="my-2 text-grey-lighter" />
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <span className="shrink-0">
              <FlagIcon languageId={ticket.user.languageId} />
            </span>
            {language ? <Capture2 className="break-all">{language.description}</Capture2> : null}
          </div>
          <div className="flex items-center gap-2">
            <span className="shrink-0">
              <MailIcon className="text-grey-dark" size={16} />
            </span>
            <Capture2>{ticket.user.email || "-"}</Capture2>
          </div>
          <div className="flex items-center gap-2">
            <span className="shrink-0">
              <PhoneIcon className="text-grey-dark" size={16} />
            </span>
            <Capture2>{ticket.user.phoneNumber || "-"}</Capture2>
          </div>
        </div>
      </div>
      {ticket.address?.typeConstraint ? (
        <div>
          <Capture1>{t("page.tickets.details.user.address-type")}</Capture1>
          <hr className="my-2 text-grey-lighter" />
          <Capture2>{ticket.address?.typeConstraint?.key}</Capture2>
        </div>
      ) : null}
    </div>
  );
}
