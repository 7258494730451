import type { LanguageDto } from "api/types";
import { Button } from "components/Button/Button";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormDocumentInput } from "components/Form/FormDocumentInput";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { validateSize } from "helpers/file-size";
import type { FormTranslations } from "helpers/languages";
import { createRequiredStringRule } from "helpers/rules";
import { isValidHttpUrl } from "helpers/validation";
import { useEffect } from "react";
import type { FieldPath } from "react-hook-form";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface LayoutProps {
  defaultFormValues: LayoutFormValues;
  languages: LanguageDto[];
  isSubmitting: boolean;
  onSubmit: (values: LayoutFormValues) => Promise<unknown>;
  isEditMode: boolean;
}

export function Layout(props: LayoutProps): React.ReactNode {
  const { t } = useTranslation();
  const formMethods = useForm<LayoutFormValues>({
    mode: "onChange",
    defaultValues: props.defaultFormValues,
  });
  const touchedDocumentOrLinkField =
    formMethods.formState.touchedFields.link || formMethods.formState.touchedFields.documents;

  function submit(values: LayoutFormValues) {
    void props.onSubmit(values);
  }

  const documents = useWatch({ control: formMethods.control, name: "documents" });
  const { trigger, setValue, getValues } = formMethods;
  useEffect(() => {
    if (documents?.[0]) {
      setValue("link", "");

      void trigger("link");
    } else {
      if (touchedDocumentOrLinkField || getValues().link) {
        void trigger("link");
      }
    }
  }, [documents, setValue, touchedDocumentOrLinkField, trigger, getValues]);

  return (
    <DocumentPaper
      theme="constrained"
      title={props.isEditMode ? t("page.document.file.edit.title") : t("page.document.file.create.title")}
    >
      <Form className="flex flex-col items-start" formMethods={formMethods} onSubmit={submit}>
        <FormContent>
          {props.languages.map((lng) => (
            <FormField
              key={lng.id}
              required
              htmlFor={`name-translations-${lng.id}`}
              label={`${t("page.document.file.create.name")} (${lng.poEditorCode})`}
              description={t("page.document.file.create.name.description")}
            >
              <FormInput<LayoutFormValues>
                className="max-w-full sm:w-96"
                data-testid="documents-file-name"
                id={`name-translations-${lng.id}`}
                name={`nameTranslations.${lng.id}`}
                placeholder={`${t("page.document.file.create.name.placeholder", {
                  language: lng.description,
                })}`}
                rules={{
                  validate: {
                    required: createRequiredStringRule(t, "page.document.file.create.name"),
                  },
                  maxLength: {
                    value: NAME_MAX_LENGTH,
                    message: t("components.form.error.max-length", {
                      length: NAME_MAX_LENGTH,
                    }),
                  },
                }}
              />
            </FormField>
          ))}

          <FormField htmlFor="document" required label={t("page.document.file.create.file")}>
            <FormDocumentInput<LayoutFormValues, "documents">
              data-testid="documents-file-input"
              id="document"
              name="documents"
              rules={{
                validate: {
                  required: () => {
                    const values = formMethods.getValues();
                    if (!values.link && !values.documents?.[0]) {
                      return t("components.form.error.either-required", {
                        inputName1: t("page.document.file.create.file"),
                        inputName2: t("page.document.file.create.link"),
                      });
                    }
                  },
                  size(documents) {
                    if (documents?.[0]) {
                      return validateSize(t, documents?.[0]);
                    }
                  },
                },
              }}
            />
          </FormField>

          <span className="block font-semibold uppercase text-grey-light">{t("page.document.file.create.or")}</span>

          <FormField htmlFor="link" label={t("page.document.file.create.link")} required>
            <FormInput<LayoutFormValues, "link">
              className="w-96 max-w-full"
              data-testid="documents-file-link"
              name="link"
              id="link"
              placeholder={t("page.document.file.create.link.placeholder")}
              disabled={documents && documents.length > 0}
              rules={{
                validate: {
                  required: () => {
                    const values = formMethods.getValues();
                    if (!values.link && !values.documents?.[0]) {
                      return t("components.form.error.either-required", {
                        inputName1: t("page.document.file.create.file"),
                        inputName2: t("page.document.file.create.link"),
                      });
                    }
                  },
                  isValid: (value) => {
                    if (value && !isValidHttpUrl(value)) {
                      return t("components.form.error.invalid-url");
                    }
                  },
                },
                deps: ["documents"] satisfies FieldPath<LayoutFormValues>[],
              }}
            />
          </FormField>
        </FormContent>

        <Button data-testid="documents-file-submit" type="submit" isLoading={props.isSubmitting}>
          {props.isEditMode ? t("common.action.edit") : t("common.action.create")}
        </Button>
      </Form>
    </DocumentPaper>
  );
}

export interface LayoutFormValues {
  nameTranslations: FormTranslations;
  documents: FormDocument[];
  link: string;
}

const NAME_MAX_LENGTH = 40;
