import { useQuery } from "@tanstack/react-query";
import { getPreprIntegrationList } from "api/prepr";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { LinkButton } from "components/Button/Button";
import { Card, CardActions, CardContent, CardHeader } from "components/Card/Card";
import { CardGrid } from "components/CardGrid/CardGrid";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Label } from "components/Label/Label";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

export function AvailableIntegrationsPage(): React.ReactNode {
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const { data, isLoading, error } = useQuery({
    queryKey: QUERY_KEYS.AVAILABLE_INTEGRATIONS(sessionUser.language.id),
    queryFn: () => getPreprIntegrationList(sessionUser.language.id),
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.available-integrations.title")}
      subTitle={
        <Breadcrumbs
          pages={[
            {
              name: t("page.portfolio.title"),
              to: routes.portfolio.overview(),
            },
            {
              name: t("page.available-integrations.title"),
            },
          ]}
        />
      }
    >
      <CardGrid>
        {data?.data.Integrations.items.map((integration) => (
          <Card
            key={integration._id}
            link={integration._slug ? routes.portfolio.integrationDetails({ id: integration._slug }) : undefined}
          >
            <CardContent gap="4">
              <div className="flex justify-between">
                {integration.profile_image?.url ? (
                  <div className="size-16 shrink-0 overflow-hidden rounded border border-grey-lightest object-contain object-center">
                    <img
                      src={integration.profile_image.url}
                      className="block"
                      style={{
                        aspectRatio: `${integration.profile_image.width}/${integration.profile_image.height}`,
                      }}
                      alt=""
                    />
                  </div>
                ) : null}
                {integration.new ? (
                  <Label size="big" theme="green">
                    {t("page.available-integrations.integration.new")}
                  </Label>
                ) : null}
              </div>
              <div className="mb-4 flex flex-col gap-2">
                <CardHeader
                  title={integration.name || ""}
                  titleLink={
                    integration._slug ? routes.portfolio.integrationDetails({ id: integration._slug }) : undefined
                  }
                />
                <div>
                  <p className="line-clamp-4 whitespace-pre-line">{integration.content || ""}</p>
                </div>
              </div>
              {integration._slug && (
                <CardActions alignLeft>
                  <LinkButton
                    styling="primaryFaded"
                    to={routes.portfolio.integrationDetails({
                      id: integration._slug,
                    })}
                  >
                    {t("page.available-integrations.integration.learn-more")}
                  </LinkButton>
                </CardActions>
              )}
            </CardContent>
          </Card>
        ))}
      </CardGrid>
    </DocumentPaper>
  );
}
