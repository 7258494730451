import type { BookableAssetTranslationDto, LanguageDto } from "api/types";
import type { FormTranslations } from "helpers/languages";

export function toFormTranslations(
  languages: LanguageDto[],
  translations: BookableAssetTranslationDto[] | undefined,
  field: "name" | "description",
): FormTranslations {
  return Object.fromEntries(
    languages.map((lng) => [lng.id, translations?.find((nameLng) => lng.id === nameLng.languageId)?.[field] ?? ""]),
  ) as FormTranslations;
}

export function toTranslationsRequest(
  languages: LanguageDto[],
  nameTranslations: FormTranslations,
  descriptionTranslations: FormTranslations,
): BookableAssetTranslationDto[] {
  return languages.map<BookableAssetTranslationDto>((lng) => ({
    languageId: lng.id,
    name: nameTranslations[lng.id],
    description: descriptionTranslations[lng.id],
  }));
}
