import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import type {
  CreateFastMenuActionItemRequest,
  FastMenuActionItemDto,
  FastMenuActionItemTranslationRequest,
  HttpResponse,
  UpdateFastMenuActionItemRequest,
} from "api/types";
import ticketIcon from "assets/icons/asterisk-01.svg";
import marketplaceIcon from "assets/icons/building-02.svg";
import eventIcon from "assets/icons/calendar.svg";
import ideaIcon from "assets/icons/edit-05.svg";
import urlIcon from "assets/icons/globe-01.svg";
import helpIcon from "assets/icons/message-question-square.svg";
import ziezodanIcon from "assets/icons/tool-02.svg";
import { Button, IconButton, TableEditButton } from "components/Button/Button";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { ContextMenu } from "components/ContextMenu/ContextMenu";
import { Icon } from "components/Icon/Icon";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Select } from "components/Select/Select";
import { Table } from "components/Table/Table";
import { Capture2, Subtitle2 } from "components/Text/Text";
import { useLanguages } from "helpers/languages";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useCallback, useMemo, useState } from "react";
import { Edit, Plus, Trash2 } from "react-feather";
import { useTranslation } from "react-i18next";

import { createFormTranslations, CustomUrlModal } from "./components/CustomUrlModal";
import { MaintainerModal } from "./components/MaintainerModal";
import type { PlusButtonOptionTypes, TicketOptionType, TicketServiceLabels } from "./constants";
import { mandatoryOptions, menuOptions, ticketOptions } from "./constants";

export interface LayoutProps {
  buttonOptions: FastMenuActionItemDto[];
  isZiezodanProject: boolean;
  createOption: (payload: CreateFastMenuActionItemRequest) => Promise<HttpResponse<FastMenuActionItemDto, any>>;
  isCreatingOption: boolean;
  editOption({
    id,
    payload,
  }: {
    id: string;
    payload: UpdateFastMenuActionItemRequest;
  }): Promise<HttpResponse<FastMenuActionItemDto, any>>;
  isEditingOption: boolean;
  deleteOption: (id: string) => Promise<HttpResponse<void, any>>;
  isDeletingOption: boolean;
}

export function Layout({
  buttonOptions,
  isZiezodanProject,
  createOption,
  isCreatingOption,
  editOption,
  isEditingOption,
  deleteOption,
  isDeletingOption,
}: LayoutProps): React.ReactNode {
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const { data: languages = [] } = useLanguages();
  const [selectedOption, setSelectedOption] = useState<FastMenuActionItemDto | null>(null);
  const [isMaintainerModalOpen, maintainerModalHandler] = useBool(false);
  const [isCustomUrlModalOpen, customUrlModalHandler] = useBool(false);
  const [isDeleteModalOpen, deleteModalHandler] = useBool(false);

  const getIcon = useCallback(
    (type: PlusButtonOptionTypes, size: number) => {
      switch (type) {
        case "createInform":
          return <Icon name={ideaIcon} size={size} className="text-black" />;
        case "createHelp":
          return <Icon name={helpIcon} size={size} className="text-black" />;
        case "createMarketplace":
          return <Icon name={marketplaceIcon} size={size} className="text-black" />;
        case "createEvent":
          return <Icon name={eventIcon} size={size} className="text-black" />;
        case "createTicket":
          return isZiezodanProject ? (
            <Icon name={ticketIcon} size={size} className="text-black" />
          ) : (
            <Icon name={ziezodanIcon} size={size} className="text-black" />
          );
        case "createServiceRequest":
          return <Icon name={ticketIcon} size={size} className="text-black" />;
        case "openZiezodan":
          return isZiezodanProject ? (
            <Icon name={ziezodanIcon} size={size} className="text-black" />
          ) : (
            <Icon name={ticketIcon} size={size} className="text-black" />
          );
        case "openExternalUrl":
          return <Icon name={urlIcon} size={size} className="text-black" />;
      }
    },
    [isZiezodanProject],
  );

  const getLabel = useCallback(
    (translationLabel: string): string => {
      switch (translationLabel) {
        case "createInform":
          return t("page.plus-button-links.labels.inform");
        case "createHelp":
          return t("page.plus-button-links.labels.help");
        case "createMarketplace":
          return t("page.plus-button-links.labels.marketplace");
        case "createEvent":
          return t("page.plus-button-links.labels.event");
        case "createTicket":
          return t("page.plus-button-links.labels.ticket");
        case "createServiceRequest":
          return t("page.plus-button-links.labels.service");
        case "openZiezodan":
          return t("page.plus-button-links.labels.ziezodan");
        case "openExternalUrl":
          return t("page.plus-button-links.labels.custom-url");
        default:
          return "";
      }
    },
    [t],
  );

  const getTypeLabel = useCallback(
    (type: PlusButtonOptionTypes): string => {
      switch (type) {
        case "createInform":
          return t("page.plus-button-links.types.inform");
        case "createHelp":
          return t("page.plus-button-links.types.help");
        case "createMarketplace":
          return t("page.plus-button-links.types.marketplace");
        case "createEvent":
          return t("page.plus-button-links.types.event");
        case "createTicket":
          return t("page.plus-button-links.types.ticket");
        case "createServiceRequest":
          return t("page.plus-button-links.types.service");
        case "openZiezodan":
          return t("page.plus-button-links.types.ziezodan");
        case "openExternalUrl":
          return t("page.plus-button-links.types.custom-url");
      }
    },
    [t],
  );

  const getTicketLabel = useCallback(
    (serviceLabel: TicketServiceLabels): string => {
      switch (serviceLabel) {
        case "create.ticket":
        default:
          return t("page.plus-button-links.labels.ticket");
        case "create.ticket.maintainer":
          return t("page.plus-button-links.labels.ticket.create-for-maintainer");
        case "create.service.request":
          return t("page.plus-button-links.labels.service");
        case "create.service.request.maintainer":
          return t("page.plus-button-links.labels.service.ask-maintainer");
      }
    },
    [t],
  );

  const getMenuLabel = useCallback(
    (type: PlusButtonOptionTypes): string => {
      switch (type) {
        case "createMarketplace":
          return t("page.plus-button-links.options.marketplace");
        case "createEvent":
          return t("page.plus-button-links.options.event");
        case "createTicket":
          return t("page.plus-button-links.options.service");
        case "openZiezodan":
          return t("page.plus-button-links.options.ziezodan");
        case "openExternalUrl":
          return t("page.plus-button-links.options.custom-url");
        default:
          return "";
      }
    },
    [t],
  );

  const columns = useMemo(() => {
    const helper = createColumnHelper<FastMenuActionItemDto>();

    return [
      helper.accessor("type", {
        id: "icon",
        header: t("page.plus-button-links.table.column.icon"),
        cell: (cell) => getIcon(cell.getValue(), 24),

        maxSize: 40,
      }),
      helper.accessor("type", {
        id: "name",
        header: t("page.plus-button-links.table.column.label"),
        cell: (cell) =>
          cell.row.original.type === "createTicket" || cell.row.original.type === "createServiceRequest" ? (
            <div className="flex gap-2">
              <Select<TicketOptionType>
                selected={{
                  id:
                    !cell.row.original.translationLabel || cell.row.original.translationLabel === "create.ticket"
                      ? "ticket"
                      : cell.row.original.translationLabel === "create.ticket.maintainer"
                        ? "ticketWithMaintainer"
                        : cell.row.original.translationLabel === "create.service.request"
                          ? "serviceRequest"
                          : "serviceRequestWithMaintainer",
                  type: cell.row.original.type,
                  label: cell.row.original.translationLabel as TicketServiceLabels,
                }}
                items={ticketOptions}
                onChange={(x) => {
                  if (x.label === "create.ticket.maintainer" || x.label === "create.service.request.maintainer") {
                    setSelectedOption({ ...cell.row.original, translationLabel: x.label });
                    maintainerModalHandler.setTrue();
                  } else {
                    void editOption({
                      id: cell.row.original.id,
                      payload: { type: x.type, selectedLabel: x.label },
                    });
                  }
                }}
                keySelector={(x) => x.id}
                renderOption={(x) => getTicketLabel(x.label)}
              />
              {(cell.row.original.translationLabel === "create.service.request.maintainer" ||
                cell.row.original.translationLabel === "create.ticket.maintainer") && (
                <TableEditButton
                  onClick={() => {
                    setSelectedOption(cell.row.original);
                    maintainerModalHandler.setTrue();
                  }}
                >
                  <Capture2>{cell.row.original.maintainer}</Capture2>
                  <Edit className="stroke-grey-darker" />
                </TableEditButton>
              )}
            </div>
          ) : cell.row.original.type === "openExternalUrl" ? (
            <TableEditButton
              onClick={() => {
                setSelectedOption(cell.row.original);
                customUrlModalHandler.setTrue();
              }}
            >
              <Capture2>
                {cell.row.original.translations?.find((translation) => translation.language === sessionUser.language.id)
                  ?.text ?? getLabel(cell.getValue())}
              </Capture2>
              <Edit className="stroke-grey-darker" />
            </TableEditButton>
          ) : (
            <Subtitle2 className="font-normal">{getLabel(cell.getValue())}</Subtitle2>
          ),
      }),
      helper.accessor("type", {
        header: t("page.plus-button-links.table.column.type"),
        cell: (cell) => <Subtitle2 className="font-normal">{getTypeLabel(cell.getValue())}</Subtitle2>,
      }),
      helper.accessor("type", {
        id: "actions",
        header: "",
        cell: (cell) => (
          <div className="flex flex-row-reverse items-center justify-between">
            {!mandatoryOptions.find((mandatoryOption) => mandatoryOption === cell.getValue()) && (
              <IconButton
                className="px-2"
                title={t("common.action.delete")}
                onClick={() => {
                  setSelectedOption(cell.row.original);
                  deleteModalHandler.setTrue();
                }}
                disabled={isEditingOption}
              >
                <Trash2 />
              </IconButton>
            )}
            {cell.row.original.url && (
              <TableEditButton
                disabled={cell.row.original.type === "openZiezodan"}
                onClick={() => {
                  if (cell.row.original.type === "openZiezodan") return;
                  setSelectedOption(cell.row.original);
                  customUrlModalHandler.setTrue();
                }}
              >
                <Capture2 className="max-w-sm truncate">{cell.row.original.url}</Capture2>
                {cell.row.original.type === "openExternalUrl" && <Edit className="stroke-grey-darker" />}
              </TableEditButton>
            )}
          </div>
        ),
      }),
    ];
  }, [
    t,
    getIcon,
    getLabel,
    getTicketLabel,
    getTypeLabel,
    editOption,
    isEditingOption,
    maintainerModalHandler,
    deleteModalHandler,
    customUrlModalHandler,
    sessionUser,
  ]);

  const tableInstance = useReactTable<FastMenuActionItemDto>({
    columns,
    data: buttonOptions,
    getCoreRowModel: getCoreRowModel(),
  });

  const availableOptions = useMemo(() => {
    const actions: ContextMenuAction[] = [];

    menuOptions.forEach((option) => {
      actions.push({
        text: getMenuLabel(option),
        icon: getIcon(option, 16),
        callback:
          option === "openExternalUrl"
            ? () => customUrlModalHandler.setTrue()
            : option === "createTicket"
              ? () => createOption({ type: option, selectedLabel: "create.ticket" })
              : () => createOption({ type: option }),
        status: {
          disabled:
            !!buttonOptions.find(
              (buttonOption) =>
                (buttonOption.type === option && option !== "openExternalUrl") ||
                (buttonOption.type === "createServiceRequest" && option === "createTicket") ||
                (buttonOption.type === "createTicket" && option === "createServiceRequest"),
            ) ||
            (option === "openZiezodan" && !isZiezodanProject),
        },
      });
    });

    return actions;
  }, [buttonOptions, getMenuLabel, getIcon, isZiezodanProject, createOption, customUrlModalHandler]);

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.plus-button-links.title")}
      subTitle={t("page.plus-button-links.subtitle")}
      actions={
        <ContextMenu actions={availableOptions}>
          {(props) => (
            <Button
              styling="primary"
              disabled={buttonOptions.length === 7 || isCreatingOption}
              title={buttonOptions.length === 7 ? t("page.plus-button-links.add-option-disabled") : undefined}
              pressed={props.isOpen}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.openHandlers.toggle();
              }}
            >
              <span className="flex items-center gap-1">
                <Plus size={16} />
                {t("page.plus-button-links.add-option")}
              </span>
            </Button>
          )}
        </ContextMenu>
      }
    >
      <Table table={tableInstance} />
      <MaintainerModal
        key={`maintainer_modal_${selectedOption?.id}`}
        isOpen={isMaintainerModalOpen}
        isLoading={false}
        onClose={() => {
          setSelectedOption(null);
          maintainerModalHandler.setFalse();
        }}
        onSubmit={async (id: string | undefined, maintainer: string) => {
          if (!id) {
            await createOption({
              type: selectedOption!.type,
              maintainer: maintainer,
            });
          } else {
            await editOption({
              id: id,
              payload: {
                type: selectedOption!.type,
                maintainer: maintainer,
                selectedLabel: selectedOption!.translationLabel,
              },
            });
          }
          setSelectedOption(null);
          maintainerModalHandler.setFalse();
        }}
        defaultValues={selectedOption ? { id: selectedOption.id, maintainer: selectedOption.maintainer! } : undefined}
      />
      <CustomUrlModal
        key={`custom_url_modal_${selectedOption?.id}`}
        isOpen={isCustomUrlModalOpen}
        isLoading={isCreatingOption || isEditingOption}
        onClose={() => {
          setSelectedOption(null);
          customUrlModalHandler.setFalse();
        }}
        onSubmit={async (id: string | undefined, translations: FastMenuActionItemTranslationRequest[], url: string) => {
          if (!id) {
            await createOption({
              type: "openExternalUrl",
              translations: translations,
              url: url,
            });
          } else {
            await editOption({
              id: id,
              payload: {
                type: "openExternalUrl",
                translations: translations,
                url: url,
              },
            });
          }
          setSelectedOption(null);
          customUrlModalHandler.setFalse();
        }}
        languageIds={languages.map((lng) => lng.id)}
        defaultValues={
          selectedOption
            ? {
                id: selectedOption.id,
                urlTranslations: createFormTranslations(languages, selectedOption.translations),
                url: selectedOption.url!,
              }
            : undefined
        }
      />
      <ConfirmModal
        id="plus-button-options-delete-modal"
        title={t("page.plus-button-links.delete.modal.title")}
        isLoading={isDeletingOption}
        theme="danger"
        onReject={() => {
          setSelectedOption(null);
          deleteModalHandler.setFalse();
        }}
        rejectBtnProps={{
          "data-testid": "plus-button-options-delete-modal-cancel",
        }}
        onResolve={async () => {
          if (selectedOption !== null) {
            await deleteOption(selectedOption?.id);
          }
          deleteModalHandler.setFalse();
          setSelectedOption(null);
        }}
        resolveBtnProps={{
          text: t("common.action.delete"),
          "data-testid": "plus-button-options-delete-modal-delete",
        }}
        isOpen={isDeleteModalOpen}
        shouldCloseOnEsc
      />
    </DocumentPaper>
  );
}
