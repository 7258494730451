import type { ImageDto } from "api/types";
import emailIcon from "assets/icons/mail-01.svg";
import connectionIcon from "assets/icons/marker-pin-01.svg";
import phoneIcon from "assets/icons/phone.svg";
import { Card, CardContent, CardDescription, CardHeader, CardImage } from "components/Card/Card";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useSlug } from "hooks/useSlug";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

interface ServiceCardProps {
  id: string;
  title: string;
  type: string;
  subtitle?: string;
  image?: ImageDto;
  phone?: string;
  email?: string;
  canEdit: boolean;
  canDelete: boolean;
  connectionName?: string;
  onDelete: (config: { id: string }) => void;
  dataTestId?: string;
}

export function ServiceCard({
  id,
  title,
  type,
  subtitle,
  image,
  phone,
  email,
  canEdit,
  canDelete,
  connectionName,
  onDelete,
  dataTestId,
}: ServiceCardProps): React.ReactNode {
  const slug = useSlug();
  const navigate = useNavigate();
  const sessionUser = useSessionUser();
  const { t } = useTranslation();

  const actions = useMemo(() => {
    const result: ContextMenuAction[] = [];

    if (canEdit) {
      result.push({
        callback: () => {
          navigate(routes.servicePartners.edit({ slug, id }));
        },
        text: t("common.action.edit"),
      });
    }
    if (canDelete) {
      result.push({
        callback: () => {
          onDelete({ id });
        },
        text: t("common.action.delete"),
      });
    }

    return result;
  }, [canDelete, canEdit, slug, navigate, id, onDelete, t]);

  return (
    <Card data-testid={dataTestId} link={routes.servicePartners.details({ slug, id })}>
      <CardImage
        image={image ? image : sessionUser.project.backgroundImage ? sessionUser.project.backgroundImage : null}
        labels={[
          <Label key="service-type" theme="blue">
            {type}
          </Label>,
          connectionName ? (
            <Label key="connection-name" theme="gray">
              <div className="flex items-center gap-1">
                <Icon name={connectionIcon} />
                {connectionName}
              </div>
            </Label>
          ) : null,
        ]}
      />

      <CardContent>
        <CardHeader title={title} titleLink={routes.servicePartners.details({ slug, id })} menuActions={actions} />

        <div className="flex flex-col gap-2">
          {phone ? (
            <div className="flex w-fit items-center gap-1 rounded-lg border border-grey-lightest px-1 py-0.5 text-grey-dark">
              <Icon name={phoneIcon} />
              <span>{phone}</span>
            </div>
          ) : null}
          {email ? (
            <div className="flex w-fit items-center gap-1 rounded-lg border border-grey-lightest px-1 py-0.5 text-grey-dark">
              <Icon name={emailIcon} />
              <span>{email}</span>
            </div>
          ) : null}
        </div>

        <CardDescription description={subtitle ?? ""} />
      </CardContent>
    </Card>
  );
}
