import type { AutomatedSurveyQueueDetailsDto } from "api/types";
import { Modal } from "components/Modal/Modal";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { SurveyQuestionGroupAnswer } from "components/SurveyQuestionGroupAnswer/SurveyQuestionGroupAnswer";
import { Headline3, Subtitle2 } from "components/Text/Text";
import { parseISO } from "date-fns";
import { useBool } from "hooks/useBool";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import type { QuestionData } from "./QuestionDetails";
import { QuestionDetails } from "./QuestionDetails";

export interface LayoutProps {
  queueDetails: AutomatedSurveyQueueDetailsDto | undefined;
}

export function Layout({ queueDetails }: LayoutProps): React.ReactNode {
  const { t } = useTranslation();
  const [isQuestionModalOpen, questionModalHandler] = useBool(false);
  const [selectedQuestionData, setSelectedQuestionData] = useState<QuestionData | null>(null);
  const [selectedQuestionId, setSelectedQuestionId] = useState<string>("");
  const { id: queueId } = useParams<{ id: string }>();

  function handleViewQuestionDetails(idx: number) {
    if (!queueDetails) return;

    setSelectedQuestionId(queueDetails.questionDetails[idx].questionGroupId);
    setSelectedQuestionData({
      questionNum: idx + 1,
      ratingQuestion: queueDetails.questionDetails[idx].ratingQuestionText!,
      openQuestion: queueDetails.questionDetails[idx].openQuestionText!,
    });
    questionModalHandler.setTrue();
  }

  const isEnded = queueDetails ? parseISO(queueDetails.endDate) < new Date() : false;

  return (
    <DocumentPaper
      theme="minimal"
      title={queueDetails?.title}
      header={
        <div className="flex w-full flex-col items-center justify-around gap-2 md:flex-row">
          <div className="flex flex-col items-center justify-between">
            <Headline3>{queueDetails?.numberResponses}</Headline3>
            <Subtitle2 className="font-normal">{t("page.automated-surveys-queue-details.header.responses")}</Subtitle2>
          </div>
          <div className="flex flex-col items-center justify-between">
            <Headline3>{queueDetails?.numberLeftInQueue}</Headline3>
            <Subtitle2 className="font-normal">
              {t("page.automated-surveys-queue-details.header.n-left-in-queue")}
            </Subtitle2>
          </div>
          <div className="flex flex-col items-center justify-between">
            <Headline3>{queueDetails?.questionDetails.length}</Headline3>
            <Subtitle2 className="font-normal">
              {t("page.automated-surveys-queue-details.header.n-of-questions")}
            </Subtitle2>
          </div>
          <div className="flex flex-col items-center gap-2">
            <div className="flex items-center gap-2 rounded-full px-2 ring-1 ring-grey-dark">
              <span
                className={twJoin(
                  "block size-2.5 shrink-0 rounded-full",
                  isEnded ? "bg-aop-basic-blue" : queueDetails?.isActive ? "bg-green-dark" : "bg-yellow",
                )}
              />
              <span className="whitespace-nowrap">
                {isEnded
                  ? t("page.automated-surveys-queue-details.header.status.ended")
                  : queueDetails?.isActive
                    ? t("page.automated-surveys-queue-details.header.status.active")
                    : t("page.automated-surveys-queue-details.header.status.inactive")}
              </span>
            </div>
            <Subtitle2 className="font-normal">{t("page.automated-surveys-queue-details.header.status")}</Subtitle2>
          </div>
        </div>
      }
    >
      <div className="grid grid-cols-1 gap-6 xxl:w-1/2 xxl:grid-cols-1">
        {queueDetails?.questionDetails.map((questionDetails, idx) => {
          const data: { x: string; y: number }[] = [];
          for (let i = 1; i <= 5; i++) {
            data.push({ x: String(i), y: questionDetails.ratingGroupedNumber[String(i)] });
          }

          return (
            <SurveyQuestionGroupAnswer
              key={idx}
              number={idx + 1}
              category={questionDetails.questionGroupType}
              onViewDetails={() => handleViewQuestionDetails(idx)}
              isMandatory
              ratingQuestion={questionDetails.ratingQuestionText!}
              avgRating={questionDetails.averageRating}
              showChart
              data={data}
              openQuestion={questionDetails.openQuestionText}
              latestResponse={questionDetails.latestOpenQuestionResponse}
            />
          );
        })}
      </div>
      <Modal
        isOpen={isQuestionModalOpen}
        onRequestClose={() => {
          questionModalHandler.setFalse();
        }}
        fullScreen
      >
        <QuestionDetails queueId={queueId} questionGroupId={selectedQuestionId} questionData={selectedQuestionData} />
      </Modal>
    </DocumentPaper>
  );
}
