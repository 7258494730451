import { useImageResolver } from "api/hooks/useImageResolver";
import type { CommunityGroupV2Dto, ImageDto } from "api/types";
import award from "assets/images/award.svg";
import bgPlaceholder from "assets/images/image-placeholder.png";
import { Button, StaticButton } from "components/Button/Button";
import { Capture1, Capture2 } from "components/Text/Text";
import { UserAvatarList } from "components/UserAvatarList/UserAvatarList";
import { GroupIcon } from "modules/community-groups/components/GroupIcons";
import { Plus } from "react-feather";
import { useTranslation } from "react-i18next";

interface Props {
  group: CommunityGroupV2Dto;
  projectDefaultImage: ImageDto | undefined;
  onFollow: (id: string) => void;
  onUnfollow: (id: string) => void;
}

export function OnboardingGroup({ group, projectDefaultImage, onFollow, onUnfollow }: Props): React.ReactNode {
  const { t } = useTranslation();
  const resolveImage = useImageResolver();

  const image = !group.isResidentGroup && group.image ? group.image : projectDefaultImage;

  return (
    <div className="mx-4 flex flex-col gap-2 rounded-lg border border-grey-lighter p-2">
      <div
        className="mx-auto flex h-44 w-full shrink-0 items-center justify-center overflow-hidden rounded-3px bg-center"
        style={{
          backgroundImage: `url(${image ? resolveImage(image, "large") : bgPlaceholder})`,
        }}
      >
        {!group.isResidentGroup ? (
          <div className="flex size-14 items-center justify-center rounded-md bg-white">
            <GroupIcon
              size={24}
              className="flex w-10 items-center justify-center text-[2.5rem] sm:w-6 sm:text-[1.5rem]"
              icon={group.icon}
            />
          </div>
        ) : null}
      </div>
      <Capture1>{group.name}</Capture1>
      <Capture2>{group.description}</Capture2>
      {group.members.items.length > 0 && (
        <UserAvatarList
          limit={3}
          images={group.members.items.filter((member) => member.avatar !== undefined).map((member) => member.avatar!)}
        />
      )}
      <div className="inline-flex">
        {group.isOwner ? (
          <StaticButton>
            <div className="flex items-center gap-1">
              <img className="size-4" src={award} alt="" />
              <span>{t("model.interest-group.owner")}</span>
            </div>
          </StaticButton>
        ) : group.isMember ? (
          <Button className="flex items-center gap-1" styling="secondaryGreen" onClick={() => onUnfollow(group.id)}>
            <span className="flex items-center gap-1">{t("page.onboarding.groups.unfollow")}</span>
          </Button>
        ) : (
          <Button className="flex items-center gap-1" styling="secondary" onClick={() => onFollow(group.id)}>
            <span className="flex items-center gap-1">
              <Plus className="inline" size={16} />
              {t("page.onboarding.groups.follow")}
            </span>
          </Button>
        )}
      </div>
    </div>
  );
}
