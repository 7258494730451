import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Tooltip } from "components/Tooltip/Tooltip";
import { AnimatePresence, motion } from "framer-motion";
import { isDefined } from "helpers/util";
import { useBool } from "hooks/useBool";
import { zipWith } from "lodash-es";
import { ChevronRight } from "react-feather";
import { useTranslation } from "react-i18next";
import * as V from "victory";

import { useElementScale } from "../hooks/useElementScale";
import { chartTheme, dataColors, fontStyling } from "../theme";
import { createDateTickFormat, createXAxis, createYAxis } from "./utility/Axis";
import { HoverLabel } from "./utility/HoverLabel";

interface DataPoint {
  x: string;
  y: number | undefined;
}

type NonEmptyDataPoint<T extends DataPoint> = T & { y: NonNullable<T["y"]> };

interface ZippedData<TPrimaryDataPoint extends DataPoint, TSecondaryDataPoint extends DataPoint> {
  x: string;
  benchmark: DataPoint;
  primary?: TPrimaryDataPoint;
  secondary?: TSecondaryDataPoint;
}

interface Props<TPrimaryDataPoint extends DataPoint, TSecondaryDataPoint extends DataPoint> {
  minY?: number;
  maxY?: number;
  type: "text" | "date";
  primaryData: TPrimaryDataPoint[];
  benchmark: DataPoint[];
  benchmarkLabel: string;
  renderLabel: (data: ZippedData<TPrimaryDataPoint, TSecondaryDataPoint>, isSecondary: boolean) => React.ReactNode;
  formatYTick: (y: NonEmptyDataPoint<TPrimaryDataPoint>["y"]) => string | number;
  arrowHoverText?: string | undefined;
}

const CHART_WIDTH = 400;
const CHART_HEIGHT = 150;
const LABEL_OFFSET_X = 12;
const LABEL_OFFSET_Y = -16;

export function ScrollableBarChart<TPrimaryDataPoint extends DataPoint, TSecondaryDataPoint extends DataPoint>({
  minY = 0,
  maxY,
  type,
  benchmark,
  benchmarkLabel,
  primaryData,
  formatYTick,
  renderLabel,
  arrowHoverText,
}: Props<TPrimaryDataPoint, TSecondaryDataPoint>): React.ReactNode {
  const [showScrollIndicator, showScrollIndicatorHandlers] = useBool(true);
  const { i18n } = useTranslation();
  const { scale, ref } = useElementScale<HTMLDivElement>(CHART_WIDTH, 1.55);

  const barWidth = primaryData.length > 10 ? 10 : 14;

  const data: ZippedData<TPrimaryDataPoint, TSecondaryDataPoint>[] = zipWith(
    benchmark,
    primaryData,
    (benchmark, primary) => ({
      x: primary.x,
      benchmark,
      primary,
    }),
  );

  return (
    <div className="relative" ref={ref}>
      <V.VictoryChart
        minDomain={{ y: minY }}
        maxDomain={isDefined(maxY) ? { y: maxY } : undefined}
        theme={chartTheme}
        width={scale * CHART_WIDTH}
        height={scale * CHART_HEIGHT}
        padding={{
          top: 38,
          right: 8,
          bottom: 34,
          left: 42,
        }}
        domainPadding={{ x: 24, y: [0, 120] }}
        categories={{ x: data.map((d) => d.x) }}
        containerComponent={
          <V.VictoryZoomContainer
            allowZoom={false}
            allowPan={primaryData.length > 5}
            zoomDimension="x"
            zoomDomain={{
              x: [0, 8],
              y: maxY ? [0, maxY] : undefined,
            }}
            onZoomDomainChange={(event) => {
              if (showScrollIndicator) {
                showScrollIndicatorHandlers.set(event.x[0] === 0);
              }
            }}
          />
        }
      >
        {createYAxis({ tickCount: 6, tickFormat: formatYTick })}
        {createXAxis({
          tickFormat:
            type === "text"
              ? (x) => (x.length > 19 ? x.substring(0, 16) + "…" : x)
              : createDateTickFormat(i18n, data.length, scale),
        })}

        <V.VictoryBar
          barWidth={barWidth * scale}
          style={{
            data: {
              fill: dataColors.darkPrimary,
              strokeWidth: 18,
              strokeLinejoin: "round",
              transform: "translateY(9px)",
            },
          }}
          labelComponent={
            <V.VictoryTooltip
              text=""
              flyoutComponent={<></>}
              labelComponent={
                <HoverLabel<(typeof data)[number]>
                  offsetX={LABEL_OFFSET_X * scale}
                  offsetY={LABEL_OFFSET_Y * scale}
                  containerWidth={CHART_WIDTH * scale}
                >
                  {(data) => (
                    <>
                      {type === "text" ? null : <FormattedDate format="monthYear" date={data.x} />}
                      {renderLabel(data, false)}
                    </>
                  )}
                </HoverLabel>
              }
            />
          }
          labels={(d) => d}
          y={(d: (typeof data)[number]) => d.primary?.y || 0}
          data={data.map((d) => (isDefined(d.primary?.y) ? d : undefined)).filter(isDefined)}
        />

        {benchmark ? (
          benchmark.length === 1 ? (
            <V.VictoryLine
              style={{ data: { pointerEvents: "none", strokeWidth: 2, stroke: dataColors.benchmark } }}
              data={[
                { x: data[0].x, y: benchmark[0].y },
                { x: "-", y: benchmark[0].y },
              ]}
              labels={[benchmarkLabel]}
              labelComponent={renderBarLabel(scale)}
            />
          ) : (
            <V.VictoryLine
              style={{ data: { pointerEvents: "none", strokeWidth: 2, stroke: dataColors.benchmark } }}
              data={benchmark.map((d) => (isDefined(d?.y) ? d : undefined)).filter(isDefined)}
              labels={benchmark.map((_, i) => (i === 0 ? benchmarkLabel : ""))}
              labelComponent={renderBarLabel(scale)}
            />
          )
        ) : null}
      </V.VictoryChart>
      <AnimatePresence>
        {data.length > 8 && showScrollIndicator ? (
          <motion.div
            className="pointer-events-none absolute inset-y-0 right-0 flex h-full w-32 items-center justify-end bg-gradient-to-r from-transparent to-white p-4 text-grey"
            exit={{ opacity: 0 }}
            onMouseDown={() => showScrollIndicatorHandlers.setFalse()}
          >
            <div className="pointer-events-auto">
              <Tooltip tooltip={arrowHoverText}>
                <ChevronRight />
              </Tooltip>
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
}

function renderBarLabel(scale: number) {
  return (
    <V.VictoryLabel
      style={{
        fill: dataColors.benchmark,
        fontFamily: fontStyling.fontFamily,
        fontSize: fontStyling.fontSize,
        stroke: dataColors.benchmarkDark,
        strokeWidth: 0.05,
      }}
      labelPlacement="perpendicular"
      dx={15 * scale}
      dy={-5}
    />
  );
}
