import { AzureB2CLink, AzureB2CPage, AzureB2CSubmitButton } from "components/AzureB2CPage/AzureB2CPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { isHttpError } from "helpers/Network/errors";
import { useBool } from "hooks/useBool";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

export interface LayoutProps {
  lastUsedEmail?: string;
  onLogin: (email: string) => Promise<unknown>;
}

// Attributes that are the same in Azure B2C
const azureB2cEmailRegex =
  "^[a-zA-Z0-9!#$%&'+^_`{}~-]+(?:.[a-zA-Z0-9!#$%&'+^_`{}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$";

export function Layout({ lastUsedEmail, onLogin }: LayoutProps): React.ReactNode {
  const { t, i18n } = useTranslation();
  const [isSubmitting, submittingHandlers] = useBool(false);
  const [error, setError] = useState<unknown>(undefined);
  const [formError, setFormError] = useState<string | undefined>(undefined);

  const languagePath = i18n.language === "de" ? "/de/" : i18n.language === "en" ? "/en/" : "/";

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (isSubmitting) {
      return false;
    }

    const email = (e.target as any).email?.value as string | undefined;
    if (!email) {
      setFormError(
        t("components.form.error.required", {
          inputName: t("page.login.form.email.label"),
        }),
      );

      return false;
    }

    // Shared pattern from Azure B2C
    if (!new RegExp(azureB2cEmailRegex).test(email)) {
      setFormError(t("page.login.errors.email-invalid-pattern"));

      return false;
    }

    setFormError(undefined);
    setError(undefined);

    // async in a IIFE to avoid the need to make the function async and not being able to return false to prevent the form from submitting
    void (async () => {
      try {
        submittingHandlers.setTrue();
        await onLogin(email);
      } catch (e) {
        setError(e);
      } finally {
        submittingHandlers.setFalse();
      }
    })();
  }

  return (
    <AzureB2CPage header={t("page.login.title")} body={t("page.login.subtitle")}>
      <form onSubmit={onSubmit} noValidate id="localAccountForm">
        {isSubmitting ? (
          <div className="py-16">
            <FullSizeLoader />
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-6">
              <div className="mt-4 flex flex-col gap-0.5 text-left">
                {error || formError ? (
                  <div className="mb-4 text-center text-sm text-red-dark">
                    {formError
                      ? formError
                      : isHttpError(error) && error.status === 429
                        ? t("page.login.errors.too-many-attempts")
                        : t("page.login.errors.unknown")}
                  </div>
                ) : null}
                <label className="mb-5px text-sm font-semibold" htmlFor="email">
                  {t("page.login.form.email.label")}
                </label>
                <input
                  className="h-10 rounded-lg border border-grey-lighter bg-white p-2 outline-none transition-all focus:shadow-azure focus:ring-1 focus:ring-aop-dark-blue"
                  onChange={() => {
                    if (formError) {
                      setFormError(undefined);
                    }

                    if (error) {
                      setError(undefined);
                    }
                  }}
                  id="email"
                  type="email"
                  name="email"
                  autoComplete="on"
                  autoFocus
                  aria-required
                  pattern={azureB2cEmailRegex}
                  defaultValue={lastUsedEmail}
                />
              </div>
              <AzureB2CSubmitButton>{t("page.login.form.submit")}</AzureB2CSubmitButton>
            </div>
            <p className="text-[12px]">
              <Trans
                i18nKey="page.login.form.license"
                components={{
                  newline: <br />,
                  terms: <AzureB2CLink href={`https://areaofpeople.com${languagePath}license-agreement`} />,
                  policy: <AzureB2CLink href={`https://areaofpeople.com${languagePath}privacy-policy`} />,
                }}
              />
            </p>
          </div>
        )}
      </form>
    </AzureB2CPage>
  );
}
