export function DefaultUserAvatar({ className }: { className?: string }): React.ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={218}
      height={218}
      fill="none"
      viewBox="0 0 218 218"
      className={className}
    >
      <g clipPath="url(#clip0)">
        <path fill="#fff" d="M0 0h218v218H0z" />
        <path fill="#9B9B9B" d="M0 0h218v218H0z" />
        <ellipse cx="109" cy="226" fill="#fff" rx="68" ry="99" />
        <circle cx="109" cy="91" r="41" fill="#fff" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h218v218H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
