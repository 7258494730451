import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { EventDto } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { useLanguages } from "helpers/languages";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useResolvedPermission } from "hooks/usePermission";
import { QUERY_KEYS } from "query-keys";
import type React from "react";
import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

import type { LayoutFormValues, LayoutProps } from "./Layout";
import { APIToUI, useCreateEvent, useEditEvent } from "./Manager";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader(props: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const sessionUser = useSessionUser();
  const { id: eventId } = useParams<{ id: string }>();
  const { state } = useLocation();
  const { id: eventIdToDuplicate } = useMemo(
    () => (state && typeof state === "object" && "id" in state ? (state as { id: EventDto["id"] }) : { id: undefined }),
    [state],
  );
  const api = useApi();
  const isResident = useResolvedPermission((x) => x.isResident);
  const { create: createEvent, isSubmitting: isSubmittingCreate } = useCreateEvent();
  const { edit: editEvent, isSubmitting: isSubmittingEdit } = useEditEvent(eventId!);
  const {
    data: eventCategories = [],
    isFetching: eventCategoriesIsFetching,
    error: eventCategoriesError,
  } = useQuery({
    queryKey: QUERY_KEYS.EVENT_CATEGORIES(projectId),
    queryFn: () => api.getEventsCategoriesV1(),
    select: commonAPIDataSelector,
  });
  const { data: languages = [], isFetching: languagesIsFetching, error: languagesError } = useLanguages();
  const {
    data: eventDetails,
    isFetching: eventDetailsIsFetching,
    error: eventDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.EVENT_DETAILS(projectId, (eventId || eventIdToDuplicate)!),
    queryFn: () => api.getEventsDetailsV1((eventId || eventIdToDuplicate)!),
    enabled: eventId != null || eventIdToDuplicate != null,
    select: commonAPIDataSelector,
  });

  const isDataLoading = eventCategoriesIsFetching || languagesIsFetching || eventDetailsIsFetching;
  const defaultFormValues = useMemo(() => {
    if (isDataLoading) {
      return null;
    }

    return APIToUI(eventIdToDuplicate != undefined, languages, eventCategories, eventDetails, sessionUser);
  }, [isDataLoading, eventIdToDuplicate, languages, eventCategories, eventDetails, sessionUser]);

  function onSubmit(formValues: LayoutFormValues) {
    if (eventId == null) {
      createEvent(formValues);
    } else {
      editEvent(formValues);
    }
  }

  const showAnnouncementModal = eventIdToDuplicate != null || eventId == null;

  const error = eventDetailsError || eventCategoriesError || languagesError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <>
      {defaultFormValues == null ? (
        <FullSizeLoader withPadding />
      ) : (
        props.children({
          isResident,
          defaultFormValues,
          languages,
          isSubmitting: isSubmittingEdit || isSubmittingCreate,
          onSubmit,
          showAnnouncementModal,
          eventCategories: isResident ? eventCategories.filter((x) => x.id !== "maintenance") : eventCategories,
          organizer: eventDetails?.organizer || sessionUser,
          isPublished: eventDetails?.publishedAt != null,
        })
      )}
    </>
  );
}
