import { twResolve } from "helpers/tw-resolve";
import { forwardRef } from "react";

export const Headline2 = forwardRef(function Headline2<T extends React.ElementType>(
  props: React.ComponentPropsWithRef<"span"> & { className?: string; as?: T },
  ref: React.ForwardedRef<HTMLElement>,
): React.ReactNode {
  const Component = props.as || "span";

  return (
    <Component
      ref={ref}
      {...props}
      className={twResolve("text-headline2 font-semibold leading-headline2 tracking-normal", props.className)}
    />
  );
});

export const Headline3 = forwardRef(function Headline3<T extends React.ElementType>(
  props: React.ComponentPropsWithRef<"span"> & { className?: string; as?: T },
  ref: React.ForwardedRef<HTMLElement>,
): React.ReactNode {
  const Component = props.as || "span";

  return (
    <Component
      ref={ref}
      {...props}
      className={twResolve("text-headline3 font-semibold leading-headline3 tracking-normal", props.className)}
    />
  );
});

export const Headline4 = forwardRef(function Headline4<T extends React.ElementType>(
  props: React.ComponentPropsWithRef<"span"> & { className?: string; as?: T },
  ref: React.ForwardedRef<HTMLElement>,
): React.ReactNode {
  const Component = props.as || "span";

  return (
    <Component
      ref={ref}
      {...props}
      className={twResolve("text-headline4 font-semibold leading-headline4 tracking-normal", props.className)}
    />
  );
});

export const Subtitle1 = forwardRef(function Subtitle1<T extends React.ElementType>(
  props: React.ComponentPropsWithRef<"span"> & { className?: string; as?: T },
  ref: React.ForwardedRef<HTMLElement>,
): React.ReactNode {
  const Component = props.as || "span";

  return (
    <Component
      ref={ref}
      {...props}
      className={twResolve("text-subtitle1 font-bold uppercase leading-subtitle1 tracking-wide", props.className)}
    />
  );
});

export const Subtitle2 = forwardRef(function Subtitle2<T extends React.ElementType>(
  props: React.ComponentPropsWithRef<"span"> & { className?: string; as?: T },
  ref: React.ForwardedRef<HTMLElement>,
): React.ReactNode {
  const Component = props.as || "span";

  return (
    <Component
      ref={ref}
      {...props}
      className={twResolve("text-subtitle2 font-semibold leading-subtitle2 tracking-normal", props.className)}
    />
  );
});

export const ButtonStyle = "text-sm font-semibold tracking-normal";

export const Capture1 = forwardRef(function Capture1<T extends React.ElementType>(
  props: React.ComponentPropsWithRef<"span"> & { className?: string; as?: T },
  ref: React.ForwardedRef<HTMLElement>,
): React.ReactNode {
  const Component = props.as || "span";

  return (
    <Component
      ref={ref}
      {...props}
      className={twResolve("text-capture1 font-semibold leading-capture1 tracking-normal", props.className)}
    />
  );
});

export const Capture2 = forwardRef(function Capture2<T extends React.ElementType>(
  props: React.ComponentPropsWithRef<"span"> & { className?: string; as?: T },
  ref: React.ForwardedRef<HTMLElement>,
): React.ReactNode {
  const Component = props.as || "span";

  return (
    <Component
      ref={ref}
      {...props}
      className={twResolve("text-capture2 font-normal leading-capture2 tracking-normal", props.className)}
    />
  );
});

export const Overline1 = forwardRef(function Overline1<T extends React.ElementType>(
  props: React.ComponentPropsWithRef<"span"> & { className?: string; as?: T },
  ref: React.ForwardedRef<HTMLElement>,
): React.ReactNode {
  const Component = props.as || "span";

  return (
    <Component
      ref={ref}
      {...props}
      className={twResolve("text-overline font-semibold leading-overline tracking-normal", props.className)}
    />
  );
});

export const Overline2 = forwardRef(function Overline2<T extends React.ElementType = "span">(
  props: React.ComponentPropsWithRef<"span"> & { className?: string; as?: T },
  ref: React.ForwardedRef<HTMLElement>,
): React.ReactNode {
  const Component = props.as || "span";

  return (
    <Component
      ref={ref}
      {...props}
      className={twResolve("text-overline leading-overline tracking-normal", props.className)}
    />
  );
});
