import type { TippyProps } from "@tippyjs/react/headless";
import Tippy from "@tippyjs/react/headless";
import { motion, useSpring } from "framer-motion";
import type React from "react";

export interface TooltipProps {
  tooltip: React.ReactNode;
  children: React.ReactElement;
  placement?: TippyProps["placement"];
}

export function Tooltip({ children, tooltip, placement = "top" }: TooltipProps): React.ReactNode {
  if (tooltip == null || tooltip == "") {
    return children;
  }

  return (
    <WithTooltip tooltip={tooltip} placement={placement}>
      {children}
    </WithTooltip>
  );
}

function WithTooltip({ children, tooltip, placement }: TooltipProps) {
  const initialScale = 0.5;
  const spring = { stiffness: 500, damping: 25 };
  const opacity = useSpring(0, spring);
  const scale = useSpring(initialScale, spring);

  function onMount() {
    scale.set(1);
    opacity.set(1);
  }

  function onHide({ unmount }: { unmount: () => void }) {
    const cleanup = scale.on("change", (value) => {
      if (value <= initialScale) {
        cleanup();
        unmount();
      }
    });

    scale.set(initialScale);
    opacity.set(0);
  }

  return (
    <Tippy
      touch={["hold", 300]}
      animation
      arrow
      delay={300}
      placement={placement}
      onMount={onMount}
      onHide={onHide}
      render={(props) => (
        <motion.div
          id="tippy-tooltip"
          role="tooltip"
          style={{ scale, opacity }}
          {...props}
          className="max-w-md whitespace-pre-line rounded-2px bg-grey-darkest px-3 py-2 text-xs text-white shadow-md"
        >
          {tooltip}
          <div id="tippy-arrow" data-popper-arrow="" />
        </motion.div>
      )}
    >
      {children}
    </Tippy>
  );
}
