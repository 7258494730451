import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { usePermission } from "hooks/usePermission";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import type { ApiQueryParams } from "types/api-types";

import type { LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader({ children }: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const hasPermission = usePermission();
  const { t } = useTranslation();

  const assetsQuery: ApiQueryParams<"getBookableAssetsV1"> = {
    HideUnpublished: !hasPermission((x) => x.assets.canManageAll || x.assets.canManageOwn),
  };

  const {
    data: assets = [],
    isFetching: isFetchingAssets,
    error: assetsError,
  } = useQuery({
    queryKey: QUERY_KEYS.BOOKINGS_ASSETS(projectId, assetsQuery),
    queryFn: () => api.getBookableAssetsV1(assetsQuery),
    select: (data) => commonAPIDataSelector(data).items,
  });

  const assetStatistics = useMutation({
    mutationFn: (id: string) => api.getBookableAssetsStatisticsV1(id).then((x) => x.data),
  });

  const deleteAsset = useMutation({
    mutationFn: (id: string) => api.deleteBookableAssetsByIdV1(id).then((x) => x.data),
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("page.bookings.delete-asset.notification.success"),
      });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.BOOKINGS_ASSETS(projectId, assetsQuery) });
    },
    onError: () =>
      showFlashToast({
        type: "error",
        title: t("page.bookings.delete-asset.notification.error"),
      }),
  });

  const error = assetsError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const loading = isFetchingAssets;
  if (loading) {
    return <FullSizeLoader withPadding />;
  }

  return children({ assets, getFutureBookings: assetStatistics.mutateAsync, onDelete: deleteAsset.mutateAsync });
}
