import type { TippyProps } from "@tippyjs/react/headless";
import Tippy from "@tippyjs/react/headless";
import type { ConstraintListItemDto } from "api/types";
import { BorderlessButton } from "components/Button/Button";
import { Capture1 } from "components/Text/Text";
import { useBool } from "hooks/useBool";
import { groupBy } from "lodash-es";
import type React from "react";
import { useTranslation } from "react-i18next";

import { Tag } from "./Tag";

export interface TagListProps {
  tags: TagListItem[];
  limit: number;
}
export function TagList({ tags: allTags, limit }: TagListProps): React.ReactNode {
  const { t } = useTranslation();
  const [isTooltipOpen, handleTooltipState] = useBool();
  const tagsToShow = allTags.slice(0, limit);
  const tagsToHide = allTags.slice(limit);

  return (
    <div className="flex items-center gap-1" data-testid="tag-list-root">
      <TagListRow tags={tagsToShow} />
      <Tippy
        touch={["hold", 300]}
        interactive
        visible={isTooltipOpen}
        onClickOutside={handleTooltipState.setFalse}
        placement="auto-end"
        render={(attr) => <TagListTooltip tags={allTags} {...attr} />}
      >
        <BorderlessButton
          className="flex items-center"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleTooltipState.toggle();
          }}
        >
          <Capture1>
            {t("component.tag-list.details")}
            {tagsToHide.length ? ` (+${tagsToHide.length})` : null}
          </Capture1>
        </BorderlessButton>
      </Tippy>
    </div>
  );
}

interface TagListTooltipProps extends TippyRenderAttrs {
  tags: TagListItem[];
}
function TagListTooltip({ tags, ...attrs }: TagListTooltipProps) {
  const { t } = useTranslation();
  const tagsByType = groupBy(tags, ({ type }) => type);
  function getTypeTranslation(type: string) {
    switch (type) {
      case "company":
        return t("model.constraint.type.company");
      case "floor":
        return t("model.constraint.type.floor");
      case "building":
        return t("model.constraint.type.building");
      case "apartmentType":
        return t("model.constraint.type.apartment-type");
      default:
        return type;
    }
  }

  return (
    <div {...attrs} className="flex flex-col gap-2 rounded-2px border border-grey-lighter bg-white p-4 shadow-sm">
      {Object.entries(tagsByType).map(([type, tags]) => {
        return (
          <div key={type}>
            <Capture1 className="mb-1 block">{getTypeTranslation(type)}:</Capture1>
            <TagListRow tags={tags} />
          </div>
        );
      })}
    </div>
  );
}

interface TagListRowProps {
  tags: TagListItem[];
}
function TagListRow({ tags }: TagListRowProps) {
  return (
    <ul className="flex gap-0.5">
      {tags.map(({ id, content }) => (
        <li key={id}>
          <Tag>{content}</Tag>
        </li>
      ))}
    </ul>
  );
}

interface TagListItem {
  id: string;
  content: React.ReactNode;
  type: string;
}
type TippyRenderAttrs = Parameters<NonNullable<TippyProps["render"]>>[0];

export function transformConstraintToTag({ key, ...constraint }: ConstraintListItemDto): TagListItem {
  return { content: key, ...constraint };
}
