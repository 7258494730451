import type { DocumentUploadDto } from "api/types";
import { Icon54 } from "components/Icon54/Icon54";
import { UploadDocumentIcon, UploadImageIcon } from "components/Icons/Icons";
import { Pdf } from "components/Pdf/Pdf";
import { ButtonStyle } from "components/Text/Text";
import { twResolve } from "helpers/tw-resolve";
import type { GroupIconType } from "modules/community-groups/components/GroupIcons";
import { GroupIcon } from "modules/community-groups/components/GroupIcons";
import type { AriaAttributes, FocusEventHandler } from "react";
import type React from "react";
import {
  Edit as EditIcon,
  MinusCircle as MinusCircleIcon,
  Plus as PlusIcon,
  PlusCircle as PlusCircleIcon,
  User as UserIcon,
  X as XIcon,
} from "react-feather";
import { useTranslation } from "react-i18next";

import type { FormDocument } from "../DocumentInput/useDocumentFile";
import { MediaXButton } from "./MediaXButton";

export interface DocumentMediaProps extends MediaProps {
  document: FormDocument | DocumentUploadDto | undefined;
  withPreview?: boolean;
}

export function DocumentMedia({ document, withPreview, ...props }: DocumentMediaProps): React.ReactNode {
  const { t } = useTranslation();

  if (withPreview && document) {
    return (
      <Pdf
        fileName={"file" in document ? document.file.name : document.fileName}
        fileSize={"file" in document ? document.file.size : "fileSize" in document ? document.fileSize : undefined}
        onDelete={props.onDelete}
        isInvalid={!!props["aria-invalid"]}
      />
    );
  }

  return (
    <Media {...props} type="document" document={document}>
      {!withPreview && document ? (
        <button
          className={twResolve(
            "flex size-full cursor-pointer items-center justify-center gap-2 px-2 text-grey-darker",
            ButtonStyle,
            innerButtonStyles,
          )}
          type="button"
          onClick={props.onDelete}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
        >
          <MinusCircleIcon />
          <span className="truncate">{t("common.action.remove")}</span>
        </button>
      ) : null}
    </Media>
  );
}

export interface ImageMediaProps extends MediaProps {
  src: string | undefined;
}

export function ImageMedia({ src, ...props }: ImageMediaProps): React.ReactNode {
  return (
    <Media {...props} type="image" image={src}>
      {src ? <img className="relative left-0 top-0 size-full rounded-lg object-cover" src={src} alt="" /> : null}
    </Media>
  );
}

export interface IconMediaProps extends MediaProps {
  charCode: number | undefined;
}

export function IconMedia({ charCode, ...props }: IconMediaProps): React.ReactNode {
  return (
    <Media {...props}>
      {typeof charCode === "number" ? (
        <Icon54
          className="absolute left-0 top-0 flex size-full items-center justify-center object-cover"
          charCode={charCode}
          disableUserSelect
        />
      ) : null}
    </Media>
  );
}

export interface GroupIconMediaProps extends MediaProps {
  icon: GroupIconType | undefined;
}

export function GroupIconMedia({ icon, ...props }: GroupIconMediaProps): React.ReactNode {
  return (
    <Media {...props}>
      {icon ? <GroupIcon size={48} className="flex size-full items-center justify-center p-2" icon={icon} /> : null}
    </Media>
  );
}

interface MediaProps extends AriaAttributes {
  texts?: {
    add?: string;
    edit?: string;
  };
  onAdd?: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  className?: string;
  theme?: "light" | "dark";
  "data-testid"?: string;
  "data-add-testid"?: string;
  "data-edit-testid"?: string;
  "data-delete-testid"?: string;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
  disabled?: boolean;
  children?: React.ReactNode;
}

export function AvatarMedia({ src, ...props }: ImageMediaProps): React.ReactNode {
  return (
    <MediaInputBox {...props}>
      {src == null ? (
        <>
          <button
            className={twResolve(
              "flex size-full cursor-pointer items-center justify-center p-10 text-grey-lighter",
              ButtonStyle,
              innerButtonStyles,
              props.disabled && "cursor-not-allowed",
              props.theme === "dark" ? "text-grey-lightest" : undefined,
            )}
            data-testid={props["data-add-testid"]}
            type="button"
            onClick={props.onAdd}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            disabled={props.disabled}
          >
            <UserIcon size={64} />
          </button>
          <button
            className={
              "absolute bottom-0 right-8 z-10 flex size-10 translate-x-1/2 items-center justify-center rounded-full bg-aop-basic-blue ring-4 ring-grey-lightest"
            }
            type="button"
            data-testid={props["data-delete-testid"]}
            onClick={props.onAdd}
            disabled={props.disabled}
          >
            <PlusIcon className="text-white" size={24} />
          </button>
        </>
      ) : (
        <>
          {props.onEdit != null && (
            <button
              className="absolute left-0 top-0 z-10 flex size-full items-center justify-center rounded-full bg-grey-darkest p-10 text-white opacity-0 hocus:opacity-70"
              type="button"
              data-testid={props["data-edit-testid"]}
              onClick={props.onEdit}
            >
              <EditIcon size={64} />
            </button>
          )}

          {props.onDelete != null && (
            <button
              className={
                "absolute bottom-0 right-8 z-10 flex size-10 translate-x-1/2 items-center justify-center rounded-full bg-red-dark ring-4 ring-red-lighter"
              }
              type="button"
              data-testid={props["data-delete-testid"]}
              onClick={props.onDelete}
            >
              <XIcon className="text-white" size={24} />
            </button>
          )}

          <img className="relative left-0 top-0 size-full rounded-full" src={src} alt="" />
        </>
      )}
    </MediaInputBox>
  );
}

interface CustomMediaProps {
  type?: "image" | "document";
  document?: FormDocument;
  image?: string;
}

function Media(props: MediaProps & CustomMediaProps): React.ReactNode {
  const { t } = useTranslation();

  if (props.children === null) {
    return (
      <MediaInputBox {...props}>
        <button
          className={twResolve(
            "flex h-20 min-w-28 cursor-pointer items-center justify-center gap-2 px-2 text-grey-darker",
            ButtonStyle,
            innerButtonStyles,
            props.disabled && "cursor-not-allowed",
            props.theme === "dark" ? "text-grey-lightest" : undefined,
          )}
          data-testid={props["data-add-testid"]}
          type="button"
          onClick={props.onAdd}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          disabled={props.disabled}
        >
          {props.type === "image" ? (
            <UploadImageIcon size={16} />
          ) : props.type === "document" ? (
            <UploadDocumentIcon size={16} />
          ) : (
            <PlusCircleIcon size={24} />
          )}
          <span className="max-w-[128px] whitespace-pre-line">{props.texts?.add || t("common.action.add")}</span>
        </button>
      </MediaInputBox>
    );
  }

  return (
    <MediaInputBox {...props}>
      {props.onEdit != null && (
        <button
          className={twResolve(
            "absolute left-0 top-0 z-10 flex size-full flex-1 items-center justify-center gap-1 bg-[rgba(64,61,74,0.65)] text-white opacity-0 hocus:opacity-100",
            ButtonStyle,
            innerButtonStyles,
          )}
          data-testid={props["data-edit-testid"]}
          onClick={props.onEdit}
          type="button"
        >
          <EditIcon size={16} />
          {props.texts?.edit || t("common.action.edit")}
        </button>
      )}
      {props.onDelete != null && (
        <div className="absolute right-0 top-0 z-10 -translate-y-1/2 translate-x-1/2">
          <MediaXButton data-testid={props["data-delete-testid"]} onClick={props.onDelete} />
        </div>
      )}
      {props.children}
    </MediaInputBox>
  );
}

function MediaInputBox(props: MediaProps & { children: React.ReactNode }) {
  return (
    <div
      data-testid={props["data-testid"]}
      className={twResolve(
        "relative h-20 min-w-28 rounded-lg border border-grey-lighter hocus:bg-grey-lightest",
        props["aria-invalid"] ? "border-grey-lighter/0 ring-2 ring-red-dark" : undefined,
        props.disabled && "bg-grey-lightest",
        props.theme === "dark" ? "bg-grey-darkest hocus:bg-grey-darker" : undefined,
        props.className,
      )}
      aria-invalid={props["aria-invalid"]}
    >
      {props.children}
    </div>
  );
}

const innerButtonStyles =
  "active:text-blue disabled:text-grey rounded-lg focus-visible:outline-none focus-visible:before:content-[''] focus-visible:before:absolute focus-visible:before:-inset-2px focus-visible:before:pointer-events-none focus-visible:before:ring-1 focus-visible:before:ring-grey-darkest focus-visible:before:rounded-lg";
