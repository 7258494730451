import type { UseComboboxPropGetters } from "downshift";
import { useCombobox } from "downshift";
import { twResolve } from "helpers/tw-resolve";
import type { ReactNode } from "react";
import { memo, useMemo, useRef, useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import { twJoin } from "tailwind-merge";

export interface SearchableSelectProps<T> {
  id?: string;
  placeholder?: string;
  selected: T | undefined;
  disabled?: boolean;
  items: T[];
  "aria-invalid"?: boolean;
  searchableFieldSelector: (item: T) => string[];
  renderOption: (item: T, selected: boolean) => ReactNode;
  renderSelected?: (item: T) => ReactNode;
  keySelector: (item: T) => string | number;
  onChange: (items: T | undefined) => void;
}

export function SearchableSelect<T>({
  id,
  selected,
  disabled,
  placeholder,
  items,
  renderOption,
  renderSelected = (x) => renderOption(x, false),
  searchableFieldSelector,
  keySelector,
  onChange,
  "aria-invalid": isInvalid,
}: SearchableSelectProps<T>): React.ReactNode {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [inputValue, setInputValue] = useState("");
  const filteredItems = useMemo(
    () =>
      items.filter((item) =>
        searchableFieldSelector(item).some((x) => x.toLowerCase().includes(inputValue.toLowerCase())),
      ),
    [inputValue, items, searchableFieldSelector],
  );
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    getLabelProps,
    closeMenu,
  } = useCombobox({
    inputValue,
    defaultHighlightedIndex: 0,
    selectedItem: selected ?? null,
    items: filteredItems,
    onSelectedItemChange(changes) {
      onChange(changes.selectedItem || undefined);
      setInputValue("");
      closeMenu();
      buttonRef.current?.focus();
    },
    onStateChange: ({ inputValue, type }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue || "");
          break;
        case useCombobox.stateChangeTypes.InputBlur:
          closeMenu();
          buttonRef.current?.focus();
          setInputValue("");
          break;
      }
    },
  });

  return (
    <div
      className={twResolve("relative", isOpen && "z-10", disabled && "pointer-events-none")}
      data-testid="searchable-select"
    >
      <div>
        <div
          {...getLabelProps()}
          className={twResolve(
            "relative flex min-h-[2.25rem] w-full cursor-default flex-wrap items-center bg-white pl-2 pr-10 text-left leading-[26px] ring-1 ring-grey-lighter hocus:outline-none hocus:ring-grey-darker",
            disabled && "bg-grey-lightest text-grey-light",
            isOpen ? "rounded-t-lg ring-grey-darker hocus:ring-grey-darker" : "rounded-lg",
            isInvalid && "ring-2 ring-red-dark",
          )}
        >
          <div className="pointer-events-none absolute">
            {selected && !isOpen && !inputValue ? renderSelected(selected) : null}
          </div>
          <input
            {...getInputProps()}
            placeholder={isOpen || !selected ? placeholder : undefined}
            className="h-9 w-0 min-w-[30px] flex-1 bg-transparent py-1 text-base focus:outline-none"
          />
          <button
            {...getToggleButtonProps({ ref: buttonRef, type: "button", id })}
            aria-label="toggle menu"
            className="absolute right-0 top-0 flex size-9 items-center justify-center focus:outline-none"
            data-testid="searchable-select-toggle"
          >
            <span className="flex items-center text-grey-darker">
              {isOpen ? <ChevronUp className="w-4" /> : <ChevronDown className="w-4" />}
            </span>
          </button>
        </div>
      </div>
      <ul
        {...getMenuProps()}
        className={twJoin(
          "absolute flex max-h-96 min-h-[2.25rem] w-full flex-col items-start overflow-auto rounded-lg rounded-t-none bg-white shadow-md ring-1 ring-grey-darker hocus:outline-none",
          isOpen ? "opacity-100" : "opacity-0",
        )}
      >
        {isOpen &&
          filteredItems.map((item, index) => (
            <ListItem<T>
              key={keySelector(item) || `__${index}`}
              renderOption={renderOption}
              item={item}
              isSelected={item === selected}
              index={index}
              isHighlighted={highlightedIndex === index}
              getItemProps={getItemProps}
            />
          ))}
      </ul>
    </div>
  );
}

const ListItem = memo(ListItemInner) as typeof ListItemInner;

function ListItemInner<T>({
  item,
  index,
  isHighlighted,
  isSelected,
  getItemProps,
  renderOption,
}: {
  item: T;
  index: number;
  isHighlighted: boolean;
  isSelected: boolean;
  getItemProps: UseComboboxPropGetters<T>["getItemProps"];
  renderOption: SearchableSelectProps<T>["renderOption"];
}) {
  return (
    <li
      data-testid="searchable-select-item"
      {...getItemProps({ index, item })}
      className={twJoin(
        "w-full cursor-pointer select-none px-3 py-1 text-black first:mt-2 last:mb-2",
        isHighlighted ? "bg-blue-lightest" : undefined,
      )}
    >
      <div className="flex items-center">{renderOption(item, isSelected)}</div>
    </li>
  );
}
