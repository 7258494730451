import type { DocumentsRootFolderDto } from "api/types";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { ContextMenu } from "components/ContextMenu/ContextMenu";
import { Icon54 } from "components/Icon54/Icon54";
import { InfoIcon } from "components/InfoIcon/InfoIcon";
import { Capture2, Overline2, Subtitle2 } from "components/Text/Text";
import { useResolvedPermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { canManageRootFolders } from "modules/documents/permissions";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

export interface RootFolderProps {
  folder: DocumentsRootFolderDto;
  onDelete: (id: string) => void;
}

export function RootFolder({ folder, onDelete }: RootFolderProps): React.ReactNode {
  const slug = useSlug();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const canManage = useResolvedPermission(canManageRootFolders);

  const actions = useMemo(() => {
    const result: ContextMenuAction[] = [];
    if (canManage) {
      result.push({
        text: t("common.action.edit"),
        callback: () => {
          navigate(routes.documents.rootEdit({ slug, folderId: folder.id }));
        },
      });
    }
    if (canManage) {
      result.push({
        text: t("common.action.delete"),
        callback: () => onDelete(folder.id),
        status: {
          disabled: !folder.isEmpty,
          disabledText: t("model.document.folder.action.delete.not-allowed"),
        },
      });
    }

    return result;
  }, [canManage, folder.id, folder.isEmpty, slug, onDelete, navigate, t]);

  return (
    <article
      className={twJoin(
        "relative flex h-36 items-center gap-4 rounded-lg bg-white py-8 pl-6 pr-2 shadow-sm sm:pr-4",
        folder.isEmpty && !folder.canAddItem ? "opacity-50" : "hover:shadow-md",
      )}
      data-testid="documents-root-item"
    >
      <Link
        className={twJoin("flex gap-2 sm:gap-4", folder.isEmpty && !folder.canAddItem ? "pointer-events-none" : "")}
        to={routes.documents.documentsList({ slug, folderId: folder.id })}
      >
        <div
          className="flex items-center justify-center rounded-lg p-2.5 ring-1 ring-grey-lightest"
          role="presentation"
        >
          <Icon54 className="w-12 text-[3rem]" char={folder.iconChar} />
        </div>

        <div className="flex flex-col gap-1.5">
          <Subtitle2 className="line-clamp-1" as="h2">
            {folder.name}
          </Subtitle2>
          {folder.description ? (
            <Capture2 className="line-clamp-2 text-grey-darker" as="p">
              {folder.description}
            </Capture2>
          ) : null}
        </div>
      </Link>
      <ContextMenu className="ml-auto self-start" actions={actions} />
      {folder.isEmpty && folder.canAddItem ? (
        <div
          className="absolute bottom-0 right-0 flex items-center gap-2 overflow-hidden p-4"
          data-testid="documents-root-item-empty"
        >
          <Overline2 className="text-grey-darker">{t("page.document.root.list.disabled")}</Overline2>
          <InfoIcon tooltip={t("page.document.root.list.disabled.description")} />
        </div>
      ) : null}
    </article>
  );
}
