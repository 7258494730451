import { LinkButton } from "components/Button/Button";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useConfig } from "hooks/useConfig";
import { useResolvedPermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { List } from "modules/community-groups/components/List/List";
import { canCreateInterestGroup } from "modules/community-groups/permissions";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

export function InterestGroupListPage(): React.ReactNode {
  const sessionUser = useSessionUser();
  const slug = useSlug();
  const { t } = useTranslation();
  const canCreateNew = useResolvedPermission(canCreateInterestGroup);
  const { setting: useNewCardLayout } = useConfig("useNewCardLayout");

  return (
    <DocumentPaper
      theme={useNewCardLayout ? "minimal" : "wide"}
      title={t("page.interest-group.list.title")}
      subTitle={t("page.interest-group.list.sub-title")}
      actions={
        canCreateNew ? (
          <LinkButton
            data-testid="group-action-create"
            to={
              sessionUser.isAdmin
                ? routes.interestGroups.create({ slug })
                : routes.interestGroups.createResidentGroup({ slug })
            }
          >
            {t("model.interest-group.action.create")}
          </LinkButton>
        ) : null
      }
    >
      <List
        type="interest"
        followLabels={{
          button: t("model.interest-group.action.follow"),
          failedNotification: t("model.interest-group.action.follow.notification-error"),
        }}
        unfollowLabels={{
          button: t("model.interest-group.action.unfollow"),
          failedNotification: t("model.interest-group.action.unfollow.notification-error"),
        }}
        allAudienceLabel={t("model.interest-group.audience.placeholder")}
        deleteLabels={{
          confirmation: t("model.interest-group.action.delete.confirmation"),
          description: t("model.interest-group.action.delete.description"),
          success: t("model.interest-group.action.delete.notification.success"),
          error: t("model.interest-group.action.delete.notification.error"),
        }}
        statusLabels={{
          disable: {
            confirmation: t("model.platform.interest-group.action.disable.confirmation"),
            description: t("model.platform.interest-group.action.disable.description"),
            success: t("model.platform.interest-group.action.disable.notification.success"),
            error: t("model.platform.interest-group.action.disable.notification.error"),
          },
          enable: {
            success: t("model.platform.interest-group.action.enable.notification.success"),
            error: t("model.platform.interest-group.action.enable.notification.error"),
          },
        }}
      />
    </DocumentPaper>
  );
}
