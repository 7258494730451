import React from "react";

import { urlify } from "../../helpers/text";

export const LinkFormatter = React.memo(function LinkFormatter({
  children,
}: {
  children: string | undefined;
}): React.ReactNode {
  return <span className="whitespace-pre-line break-words">{urlify(children)}</span>;
});
