import { motion, useAnimationControls } from "framer-motion";
import { Star as StarIcon } from "react-feather";
import { twJoin } from "tailwind-merge";

export interface StarInputProps {
  value: number | undefined;
  onChange: (stars: number) => void;
}

const animations = {
  in: {
    opacity: 1,
    scale: 1.25,
  },
  out: {
    opacity: 0,
    scale: 0.75,
  },
  none: {},
};

export function StarInput({ value, onChange }: StarInputProps): React.ReactNode {
  const controls = useAnimationControls();

  return (
    <span className="group flex gap-1">
      {Array(5)
        .fill(undefined)
        .map((_, i) => (
          <button
            className={twJoin(
              "relative scale-100 transition-all active:scale-110 can-hover:hover:scale-110 can-hover:group-hover:text-yellow-dark can-hover:hover-sibling:!text-grey-lighter",
              value != null && value > i ? "text-yellow" : "text-grey-lighter",
            )}
            key={i}
            type="button"
            onClick={() => {
              onChange(i + 1);
            }}
            onPointerDown={() => {
              void controls.start((item) => (i === item ? animations.in : animations.none));
            }}
            onPointerUp={() => {
              // for dragging stars
              onChange(i + 1);
              void controls.start((item) => (i === item ? animations.out : animations.none));
            }}
            onMouseOut={() => {
              void controls.start((item) => (i === item ? animations.out : animations.none));
            }}
          >
            <StarIcon size={32} fill="currentColor" />
            <motion.span
              className="absolute inset-0 -z-10 scale-75 rounded-full bg-yellow-lighter opacity-0"
              animate={controls}
              custom={i}
            />
          </button>
        ))}
    </span>
  );
}
