import type { ForwardedRef, MutableRefObject } from "react";
import { useEffect, useRef } from "react";

/**
 * Combine refs when using forwardRef, so refs can be accessed in the 'inner' component
 * @param refs list of refs from forwardRef and 'inner' useRef
 */
export function useCombinedRefs<T>(...refs: ForwardedRef<T>[]): MutableRefObject<T | null> {
  const targetRef = useRef<T | null>(null);

  useEffect(() => {
    for (const ref of refs) {
      if (!ref) {
        return;
      }

      if (typeof ref === "function") {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    }
  }, [refs]);

  return targetRef;
}
