import type { AssetBookingDto } from "api/types";
import { Button } from "components/Button/Button";
import { DateRange } from "components/DateRange/DateRange";
import { Headline4, Subtitle2 } from "components/Text/Text";
import { User } from "components/User/User";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import { parse } from "date-fns";
import { useTranslation } from "react-i18next";

export function BookingDetailModal({
  booking,
  onClose,
  onDeleteBooking,
}: {
  booking: AssetBookingDto;
  onClose: () => void;
  onDeleteBooking: (bookingId: string) => void;
}): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div className="flex max-w-xl flex-col gap-4 p-4">
      <Headline4>{t("page.bookings.asset-detail.booking-details.title")}</Headline4>
      <div className="flex flex-col gap-0.5">
        <Subtitle2 className="font-semibold">{t("page.bookings.asset-detail.booking-details.date")}</Subtitle2>
        <DateRange
          start={parse(booking.startTime, "HH:mm:ss", parse(booking.date, "yyyy-MM-dd", new Date()))}
          end={parse(booking.endTime, "HH:mm:ss", parse(booking.date, "yyyy-MM-dd", new Date()))}
        />
      </div>
      {booking.author ? (
        <div className="flex flex-col gap-0.5">
          <Subtitle2 className="font-semibold">{t("page.bookings.asset-detail.booking-details.author")}</Subtitle2>
          {booking.author.canViewProfile ? (
            <UserNameLink user={booking.author}>
              <User title={booking.author.fullName} avatar={booking.author.avatar} />
            </UserNameLink>
          ) : (
            <User title={booking.author.fullName} avatar={booking.author.avatar} />
          )}
        </div>
      ) : null}
      {booking.bookingReason ? (
        <div className="flex flex-col gap-0.5">
          <Subtitle2 className="font-semibold">{t("page.bookings.asset-detail.booking-details.reason")}</Subtitle2>
          <p className="max-w-prose">{booking.bookingReason}</p>
        </div>
      ) : null}
      <div className="flex gap-2">
        {/* Can only decline future or ongoing reservations */}
        {booking.canDelete ? (
          <Button onClick={() => onDeleteBooking(booking.id)} styling="primaryRed" className="w-full">
            {t("page.bookings.asset-detail.booking-details.cancel")}
          </Button>
        ) : null}
        <Button onClick={onClose} className="w-full">
          {t("page.bookings.asset-detail.booking-details.ok")}
        </Button>
      </div>
    </div>
  );
}
