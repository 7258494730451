import { useMsal } from "@azure/msal-react";
import { useCallback } from "react";
import { Async } from "react-async";
import { Navigate, useLocation } from "react-router-dom";
import { routes } from "routes";

import { useMsalConfigContext } from "./MsalConfigContext";

const QUERY_PARAMS_TO_PRESERVE = ["lang"];

export function LogInRedirect({ children }: { children: React.ReactNode }): React.ReactNode {
  const { instance, accounts } = useMsal();
  const msalContext = useMsalConfigContext();

  const acquireTokenSilent = useCallback(async () => {
    if (accounts.length === 0) {
      await instance.acquireTokenSilent(msalContext.authRequest);
    }
  }, [accounts.length, instance, msalContext.authRequest]);

  return (
    <Async promiseFn={acquireTokenSilent}>
      <Async.Settled>
        <LogInRedirectInternal>{children}</LogInRedirectInternal>
      </Async.Settled>
    </Async>
  );
}

function LogInRedirectInternal({ children }: { children: React.ReactNode }): React.ReactNode {
  const { accounts } = useMsal();

  if (accounts.length === 0) {
    return <NavigateToLogin />;
  }

  return children;
}

function NavigateToLogin() {
  const location = useLocation();
  const path = location.pathname + location.search + location.hash;

  const query = new URLSearchParams();

  if (path !== "/" && path !== "") {
    query.append("redirect", path);
  }

  const currentParams = new URLSearchParams(location.search);
  for (const paramName of QUERY_PARAMS_TO_PRESERVE) {
    const value = currentParams.get(paramName);
    if (value) {
      query.append(paramName, value);
    }
  }

  const queryStr = query.toString();

  return <Navigate to={routes.signInSignUp.main() + (queryStr ? `?${queryStr}` : "")} />;
}
