import { useMutation, useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type {
  BookableAssetCreateRequest,
  BookableAssetUpdateBookingsToBeCancelledRequest,
  BookableAssetUpdateRequest,
  TranslateRequest,
} from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { useLanguages } from "helpers/languages";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { isDefined } from "helpers/util";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadDocument } from "hooks/Network/useUploadDocument";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "routes";

import type { FormValues, LayoutProps } from "./Layout";
import { getBookableAssetRequest, getDefaultFormValues } from "./Manager";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader({ children }: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const api = useApi();
  const { id: assetId } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();
  const { uploadFormImage, isUploadingImage } = useUploadImage();
  const { isUploadingDocument, uploadFormDocument } = useUploadDocument();
  const { data: languages = [] } = useLanguages();
  const sessionUser = useSessionUser();
  const navigate = useNavigate();

  const isEditMode = isDefined(assetId);

  const {
    data: assetDetails,
    isFetching: isFetchingAsset,
    error: assetError,
  } = useQuery({
    queryKey: QUERY_KEYS.BOOKINGS_ASSET_DETAILS(projectId, assetId!),
    queryFn: () => api.getBookableAssetsDetailsV1(assetId!),
    select: commonAPIDataSelector,
    enabled: isEditMode,
  });

  const {
    data: assetStatistics,
    isFetching: isFetchingAssetStatistics,
    error: assetStatisticsError,
  } = useQuery({
    queryKey: QUERY_KEYS.BOOKINGS_ASSET_STATISTICS(projectId, assetId!),
    queryFn: () => api.getBookableAssetsStatisticsV1(assetId!, { includeToday: true }),
    select: commonAPIDataSelector,
    enabled: isEditMode,
  });

  const { mutateAsync: getBookingsToBeCancelled } = useMutation({
    mutationFn: (payload: { id: string; data: BookableAssetUpdateBookingsToBeCancelledRequest }) =>
      api.postBookableAssetsBookingsToBeCancelledV1(payload.id, payload.data).then((x) => x.data),
  });

  const { mutateAsync: onTranslate, isPending: isTranslating } = useMutation({
    mutationFn: (payload: TranslateRequest) => api.postTranslationsTranslateV1(payload).then((x) => x.data),
  });

  const deleteAsset = useMutation({
    mutationFn: (id: string) => api.deleteBookableAssetsByIdV1(id).then((x) => x.data),
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("page.bookings.delete-asset.notification.success"),
      });
      navigate(routes.bookings.list({ slug }));
    },
    onError: () =>
      showFlashToast({
        type: "error",
        title: t("page.bookings.delete-asset.notification.error"),
      }),
  });

  const createAsset = useMutation({
    mutationFn: (payload: BookableAssetCreateRequest) => api.postBookableAssetsV1(payload),
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("page.bookings.create-or-edit-asset.create.notification.success"),
      });
      navigate(routes.bookings.list({ slug }));
    },
    onError: () =>
      showFlashToast({
        type: "error",
        title: t("page.bookings.create-or-edit-asset.create.notification.error"),
      }),
  });

  const editAsset = useMutation({
    mutationFn: (payload: BookableAssetUpdateRequest) => api.putBookableAssetsV1(assetId!, payload),
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("page.bookings.create-or-edit-asset.edit.notification.success"),
      });
      navigate(routes.bookings.list({ slug }));
    },
    onError: () =>
      showFlashToast({
        type: "error",
        title: t("page.bookings.create-or-edit-asset.edit.notification.error"),
      }),
  });

  async function onSubmit(data: FormValues) {
    const imageIds: string[] | undefined = [];
    for (const image of data.images) {
      const uploadedImage = await uploadFormImage(image);
      if (uploadedImage) {
        imageIds.push(uploadedImage.id);
      }
    }

    let documentId: string | undefined;
    if (data.regulationDocument) {
      const uploadedDocument = await uploadFormDocument(data.regulationDocument[0]);
      if (uploadedDocument) {
        documentId = uploadedDocument.id;
      }
    }

    const request = getBookableAssetRequest(languages, imageIds, documentId, data);

    if (isEditMode) {
      await editAsset.mutateAsync(request);
    } else {
      await createAsset.mutateAsync({
        ...request,
        projectConnectionId: data.audience.type === "project" ? undefined : data.audience.id,
      });
    }
  }

  const error = assetError || assetStatisticsError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  if (isEditMode && assetDetails && !assetDetails.canEdit) {
    return <ErrorPage status={403} />;
  }

  const loading = isFetchingAsset || isFetchingAssetStatistics;
  if (loading) {
    return <FullSizeLoader withPadding />;
  }

  const audience: { type: "project" | "projectConnection"; id: string; name: string }[] = [
    { type: "project", id: sessionUser.project.id, name: sessionUser.project.name },
    ...sessionUser.connections.map((x) => ({
      type: "projectConnection" as const,
      id: x.id,
      name: x.name,
    })),
  ];

  return children({
    languages,
    audience,
    onTranslate,
    isTranslating,
    getBookingsToBeCancelled,
    onSubmit,
    isSubmitting:
      createAsset.isPending || editAsset.isPending || isUploadingImage || isUploadingDocument || deleteAsset.isPending,
    isEditMode,
    canEditTimeslot: !assetStatistics || assetStatistics.futureBookingsCount === 0,
    futureBookings: assetStatistics?.futureBookingsCount ?? 0,
    onDelete: (id: string) => deleteAsset.mutateAsync(id),
    defaultValues: getDefaultFormValues(languages, audience, sessionUser.email, assetDetails),
    assetId,
    isPublished: assetDetails?.publishedAt != null,
  });
}
