import type { QuickReplyRepairRequestDto } from "api/types";
import { Capture1, Capture2, Subtitle1, Subtitle2 } from "components/Text/Text";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { Mail as MailIcon, Phone as PhoneIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

export function TicketResidentInfo({
  ticket,
  isGray,
}: {
  ticket: QuickReplyRepairRequestDto;
  isGray?: boolean;
}): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div className={twJoin("flex flex-col gap-4 rounded-lg p-4", isGray ? "bg-grey-lightest" : "bg-white")}>
      <Subtitle1 className="text-left">{t("page.tickets.details.user.reporter")}</Subtitle1>
      <div className="flex flex-row items-center gap-2">
        <div className="size-24 self-center">
          <UserAvatar img={ticket.author.avatar} isUserDeleted={!!ticket.author.deletedAt} />
        </div>
        <div className="flex flex-col">
          <Subtitle2 className="line-clamp-3">{ticket.author.fullName}</Subtitle2>
          <div className="text-grey-darker">
            {ticket.company ? <p>{ticket.company}</p> : null}
            {ticket.address ? <p>{ticket.address}</p> : null}
          </div>
        </div>
      </div>
      <div>
        <Capture1>{t("page.tickets.details.user.contact-details")}</Capture1>
        <hr className="my-2 text-grey-lighter" />
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <span className="shrink-0">
              <MailIcon className="text-grey-dark" size={16} />
            </span>
            <Capture2>{ticket.author.email || "-"}</Capture2>
          </div>
          <div className="flex items-center gap-2">
            <span className="shrink-0">
              <PhoneIcon className="text-grey-dark" size={16} />
            </span>
            <Capture2>{ticket.author.phoneNumber || "-"}</Capture2>
          </div>
        </div>
      </div>
      {ticket.addressType ? (
        <div>
          <Capture1>{t("page.tickets.details.user.address-type")}</Capture1>
          <hr className="my-2 text-grey-lighter" />
          <Capture2>{ticket.addressType}</Capture2>
        </div>
      ) : null}
    </div>
  );
}
