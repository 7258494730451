import Tippy from "@tippyjs/react/headless";
import { MoreButton } from "components/Button/Button";
import { Tooltip } from "components/Tooltip/Tooltip";
import { twResolve } from "helpers/tw-resolve";
import type { UseBoolHandlers } from "hooks/useBool";
import { useBool } from "hooks/useBool";
import { useKey } from "hooks/useKey";
import { memo } from "react";
import { useTranslation } from "react-i18next";

export interface ContextMenuAction {
  text: string;
  dataTestId?: string;
  icon?: React.ReactNode;
  callback: () => void;
  status?: {
    disabled: boolean;
    disabledText?: string;
  };
}

export interface ContextMenuProps {
  actions: ContextMenuAction[];
  className?: string;
  children?: (props: { isOpen: boolean; openHandlers: UseBoolHandlers }) => React.ReactNode;
}

export const ContextMenu = memo(function ContextMenu({ actions, ...props }: ContextMenuProps): React.ReactNode {
  const { t } = useTranslation();
  const [isOpen, openHandlers] = useBool();

  useKey("Escape", openHandlers.setFalse, actions.length > 0);

  if (actions.length === 0) {
    return null;
  }

  return (
    <Tippy
      touch={["hold", 300]}
      interactive
      visible={isOpen}
      onClickOutside={openHandlers.setFalse}
      placement="auto-end"
      offset={[15, -5]}
      render={(tippyProps) => (
        <div
          {...tippyProps}
          data-testid="context-menu-action-list"
          className="flex min-w-28 flex-col rounded border border-grey-lighter bg-white text-black shadow-md"
        >
          {actions.map((action) => (
            <Tooltip
              tooltip={action.status !== undefined && action.status.disabled ? action.status?.disabledText : undefined}
              placement="auto"
              key={action.text}
            >
              <div>
                <button
                  className="h-9 w-full p-2 text-left hover:bg-blue-lightest focus-visible:bg-blue-lightest focus-visible:outline-none disabled:cursor-default disabled:bg-white disabled:text-grey-light disabled:[&_svg]:stroke-grey-light"
                  type="button"
                  data-testid={action.dataTestId}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    action.callback();
                    openHandlers.setFalse();
                  }}
                  disabled={action.status?.disabled}
                >
                  <span className="flex items-center gap-1">
                    {action.icon}
                    {action.text}
                  </span>
                </button>
              </div>
            </Tooltip>
          ))}
        </div>
      )}
    >
      <div data-testid="context-menu" className={twResolve("inline-flex", props.className)}>
        {props.children == null ? (
          <MoreButton
            title={t("component.context-menu.action.open")}
            pressed={isOpen}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              openHandlers.toggle();
            }}
          />
        ) : (
          props.children({ isOpen, openHandlers })
        )}
      </div>
    </Tippy>
  );
});
