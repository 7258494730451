import type { UserDto } from "api/types";
import { AlertBar } from "components/AlertBar/AlertBar";
import { Button } from "components/Button/Button";
import { CommentFieldWithAvatar } from "components/CommentField/CommentField";
import type { FormImage } from "components/ImageInput/useImageInput";
import { useImageInput } from "components/ImageInput/useImageInput";
import { Capture2, Overline2 } from "components/Text/Text";
import { useState } from "react";
import { Star } from "react-feather";
import { useTranslation } from "react-i18next";

interface Props {
  user: UserDto;
  canComment: boolean;
  onComment: (payload: { content: string; image?: FormImage; internal?: boolean }) => Promise<unknown>;
  onReject?: () => void;
  onRate?: () => void;
  statusName?: string;
  canRateOrReject?: boolean;
  showOtherUserCanNotCommentMessage?: boolean;
  disallowImages?: boolean;
  wasTicketCreatedInWeb?: boolean;
}

export function TicketResidentCommentField({
  user,
  canComment,
  onComment,
  canRateOrReject,
  onRate,
  onReject,
  statusName,
  showOtherUserCanNotCommentMessage,
  disallowImages,
  wasTicketCreatedInWeb,
}: Props): React.ReactNode {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [images, setImages] = useState<FormImage[]>([]);
  const { addImages, removeImage, removeImages } = useImageInput({ onChange: setImages });

  return (
    <div className="flex flex-col">
      {showOtherUserCanNotCommentMessage && (
        <div className="flex w-full justify-around text-grey-dark">
          <Capture2>{t("page.tickets.details.comment-field.resident.comment-not-allowed")}</Capture2>
        </div>
      )}
      {canComment && !canRateOrReject && (
        <div className="rounded-lg rounded-tl-none p-2">
          <CommentFieldWithAvatar
            onSubmit={async () => {
              await onComment({
                content: value,
                image: images[0],
                internal: false,
              });
              removeImages();
              setValue("");
            }}
            withoutTopRow
            user={user}
            allowsImages={!disallowImages}
            imageUploadTooltip={t("page.tickets.details.comment-field.resident.image-tooltip")}
            onRemoveImage={removeImage}
            onAddImages={addImages}
            images={images}
            sendTooltip={t("page.tickets.details.comment-field.resident.send-message-tooltip")}
            placeholder={t("page.tickets.details.comment-field.resident.placeholder-message")}
            isNote={false}
            onChange={setValue}
            value={value}
          />
        </div>
      )}
      {canRateOrReject && (
        <div className="flex w-full flex-wrap gap-2 py-4">
          <AlertBar theme="yellow" icon={<Star size={16} />}>
            {t("page.tickets.details.comment-field.resident.ticket-settled", { status: statusName || "" })}
          </AlertBar>
          <div className="flex gap-2">
            {onReject ? (
              <Button styling="primaryFaded" onClick={onReject}>
                {t("page.tickets.details.comment-field.resident.reject")}
              </Button>
            ) : null}
            {onRate ? (
              <Button styling="primary" onClick={onRate}>
                {t("page.tickets.details.comment-field.resident.rate")}
              </Button>
            ) : null}
          </div>
        </div>
      )}
      {canComment && !canRateOrReject && wasTicketCreatedInWeb && (
        <Overline2 className="pt-2 text-grey">
          {t("page.tickets.details.comment-field.resident.notification-reminder")}
        </Overline2>
      )}
    </div>
  );
}
