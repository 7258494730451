import { twJoin } from "tailwind-merge";

interface SwitchProps {
  size: "sm" | "md" | "lg";
  label?: string;
  icon?: React.ReactNode;
  isChecked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  toggleColour?: "green" | "white";
  "data-testid"?: string;
}

export function Switch({
  size,
  label,
  icon,
  isChecked,
  onChange,
  toggleColour = "green",
  "data-testid": dataTestId,
}: SwitchProps): React.ReactNode {
  return (
    <label data-testid={dataTestId} className="relative inline-flex cursor-pointer items-center">
      <input type="checkbox" value="" className="peer sr-only" onChange={onChange} checked={isChecked} />
      <div
        className={twJoin(
          "rounded-full bg-grey-dark peer-checked:bg-green",
          size === "lg" ? "h-10 w-40" : size === "md" ? "h-8 w-16" : "h-5 w-10",
        )}
      />
      <div
        className={twJoin(
          "absolute flex items-center justify-center rounded-full bg-grey-lightest text-grey transition-all peer-checked:text-white",
          size === "lg"
            ? "left-[5px] top-1 size-8 peer-checked:translate-x-[118px]"
            : size === "md"
              ? "left-[3px] top-1 size-6 peer-checked:translate-x-[34px]"
              : "left-[2px] top-0.5 size-4 peer-checked:translate-x-[20px]",
          toggleColour === "white" ? "peer-checked:bg-white" : "peer-checked:bg-green-darker",
        )}
      >
        {icon}
      </div>
      {label ? (
        <span className="absolute left-12 top-2.5 text-center text-sm font-semibold text-white transition-all peer-checked:left-4 peer-checked:text-white">
          {label}
        </span>
      ) : null}
    </label>
  );
}
