import { X as XIcon } from "react-feather";

export function MediaXButton(props: { onClick: () => void; "data-testid"?: string }): React.ReactNode {
  return (
    <button
      className="flex size-3.5 items-center justify-center rounded-full bg-grey-darkest"
      data-testid={props["data-testid"]}
      onClick={props.onClick}
      type="button"
    >
      <XIcon className="text-white" size={12} />
    </button>
  );
}
