import type {
  BookableAssetCreateRequest,
  BookableAssetDetailDto,
  BookableAssetUpdateRequest,
  LanguageDto,
} from "api/types";
import { format, parse, parseISO } from "date-fns";
import { parseAsNumber } from "helpers/util";
import type { Specification } from "modules/bookings/constants";
import { daysOptions } from "modules/bookings/constants";
import { toFormTranslations, toTranslationsRequest } from "modules/bookings/translations";

import type { FormValues } from "./Layout";

export function getDefaultFormValues(
  languages: LanguageDto[],
  projects: { type: "project" | "projectConnection"; id: string; name: string }[],
  userEmail: string,
  assetDetails?: BookableAssetDetailDto,
): FormValues {
  return {
    nameTranslations: toFormTranslations(languages, assetDetails?.translations, "name"),
    descriptionTranslations: toFormTranslations(languages, assetDetails?.translations, "description"),
    images: assetDetails?.images ?? [],
    specifications: mapAvailableSpecifications({
      sizeSpecification: assetDetails?.sizeSpecification,
      pricePerHourSpecification: assetDetails?.pricePerHourSpecification,
      capacitySpecification: assetDetails?.capacitySpecification,
      locationSpecification: assetDetails?.locationSpecification,
    }),
    sizeSpecification: assetDetails?.sizeSpecification,
    pricePerHourSpecification: assetDetails?.pricePerHourSpecification?.toString(),
    capacitySpecification: assetDetails?.capacitySpecification?.toString(),
    locationSpecification: assetDetails?.locationSpecification,
    regulationsApplicable: !!assetDetails?.regulationDocument,
    regulationDocument: assetDetails?.regulationDocument ? [assetDetails.regulationDocument] : [],
    audience: assetDetails?.projectConnection
      ? projects.find((p) => p.id === assetDetails.projectConnection?.id)!
      : projects[0],
    bookableDays: assetDetails?.bookableDays
      ? daysOptions
          .map((x) => assetDetails.bookableDays.find((y) => y.day === x)!)
          .map((d) => ({
            ...d,
            endTime: d.endTime ? parse(d.endTime, "HH:mm:ss", new Date()) : undefined,
            startTime: d.startTime ? parse(d.startTime, "HH:mm:ss", new Date()) : undefined,
          }))
      : daysOptions.map((d) => ({ day: d, allDay: false, enabled: false })),
    timeslot: assetDetails?.timeslot,
    canBookMultipleSlots: assetDetails?.canBookMultipleSlots ?? true,
    notificationEnabled: assetDetails?.notificationEnabled ?? false,
    notificationEmail: assetDetails?.notificationEmail ?? userEmail,
    canBookSameDay: assetDetails?.canBookSameDay ?? true,
    maxDaysInAdvance: assetDetails?.maxDaysInAdvance?.toString(),
    hoursForMutations: assetDetails?.hoursForMutations?.toString(),
    requireBookingReason: assetDetails?.requireBookingReason ?? false,
    publishAt: assetDetails?.publishAt ? parseISO(assetDetails?.publishAt) : "",
    unpublishAt: assetDetails?.unpublishAt ? parseISO(assetDetails?.unpublishAt) : "",
    availableFrom: assetDetails?.availableFrom ? parseISO(assetDetails?.availableFrom) : "",
  };
}

function mapAvailableSpecifications({
  sizeSpecification,
  pricePerHourSpecification,
  capacitySpecification,
  locationSpecification,
}: {
  sizeSpecification?: string;
  pricePerHourSpecification?: number;
  capacitySpecification?: number;
  locationSpecification?: string;
}) {
  const specifications: Specification[] = [];
  if (sizeSpecification) {
    specifications.push("sizeSpecification");
  }
  if (pricePerHourSpecification) {
    specifications.push("pricePerHourSpecification");
  }
  if (capacitySpecification) {
    specifications.push("capacitySpecification");
  }
  if (locationSpecification) {
    specifications.push("locationSpecification");
  }

  return specifications;
}

export function getBookableAssetRequest(
  languages: LanguageDto[],
  images: string[],
  documentId: string | undefined,
  values: FormValues,
): BookableAssetCreateRequest | BookableAssetUpdateRequest {
  return {
    translations: toTranslationsRequest(languages, values.nameTranslations, values.descriptionTranslations),
    images,
    sizeSpecification: values.sizeSpecification,
    pricePerHourSpecification: parseAsNumber(values.pricePerHourSpecification),
    capacitySpecification: parseAsNumber(values.capacitySpecification),
    locationSpecification: values.locationSpecification,
    regulationDocumentId: values.regulationsApplicable ? documentId : undefined,
    bookableDays: values.bookableDays.map((x) => ({
      ...x,
      startTime: x.startTime && x.enabled && !x.allDay ? format(x.startTime, "HH:mm:ss") : undefined,
      endTime: x.endTime && x.enabled && !x.allDay ? format(x.endTime, "HH:mm:ss") : undefined,
    })),
    timeslot: values.timeslot || "allDay",
    canBookMultipleSlots: values.canBookMultipleSlots,
    notificationEnabled: values.notificationEnabled,
    notificationEmail: values.notificationEnabled ? values.notificationEmail : undefined,
    canBookSameDay: values.canBookSameDay,
    maxDaysInAdvance: parseAsNumber(values.maxDaysInAdvance),
    hoursForMutations: parseAsNumber(values.hoursForMutations),
    requireBookingReason: values.requireBookingReason,
    publishAt: values.publishAt && values.publishAt > new Date() ? values.publishAt.toISOString() : undefined,
    availableFrom: values.availableFrom ? values.availableFrom.toISOString() : undefined,
    unpublishAt: values.unpublishAt ? values.unpublishAt.toISOString() : undefined,
    enabled: true,
  };
}
