import type { AdminTicketActivityDto, AdminTicketCommentDto, AdminTicketDetailsDto, UserDto } from "api/types";
import { BorderlessButton } from "components/Button/Button";
import { formatDistance } from "components/FormattedDistance/FormattedDistance";
import { LoadingIcon } from "components/Icons/Icons";
import type { FormImage } from "components/ImageInput/useImageInput";
import { Capture2, Headline4 } from "components/Text/Text";
import { TicketCommentField } from "components/Ticket/TicketCommentField";
import { parseISO } from "date-fns";
import { daysBetween } from "helpers/date";
import { TicketActivitiesSection } from "modules/tickets/components/TicketActivitySection";
import { useState } from "react";
import { BarChart as BarChartIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

interface TicketLogProps {
  ticket: AdminTicketDetailsDto;
  sessionUser: UserDto;
  activities: AdminTicketActivityDto[];
  isFetchingMoreActivities: boolean;
  hasMoreActivities: boolean;
  fetchMoreActivities: () => void;
  isActivitiesDescending: boolean;
  onToggleActivitySorting: () => void;
  askStatusChangeOrReply: (comment: { content: string; image?: FormImage; internal?: boolean }) => Promise<unknown>;
  editComment: ({
    commentId,
    content,
    image,
  }: {
    commentId: string;
    content: string;
    image?: FormImage;
  }) => Promise<void>;
  scrollTargetRef: React.RefObject<HTMLDivElement>;
}

export function TicketLog({
  ticket,
  sessionUser,
  activities,
  isFetchingMoreActivities,
  hasMoreActivities,
  fetchMoreActivities,
  isActivitiesDescending,
  onToggleActivitySorting,
  askStatusChangeOrReply,
  editComment,
  scrollTargetRef,
}: TicketLogProps): React.ReactNode {
  const { t, i18n } = useTranslation();
  const [editingComment, setEditingComment] = useState<AdminTicketCommentDto | undefined>(undefined);

  return (
    <div className="rounded-lg bg-white p-4">
      <div className="flex items-center justify-between">
        <div className="flex w-full flex-col gap-2">
          <div className="flex justify-between">
            <Headline4>{t("page.tickets.details.activities.title")}</Headline4>
            <BorderlessButton onClick={onToggleActivitySorting}>
              <div className="flex items-center gap-1">
                {isActivitiesDescending ? (
                  <>
                    <span>{t("page.tickets.details.activities.sort-descending")}</span>
                    <BarChartIcon className="block rotate-90" size={16} />
                  </>
                ) : (
                  <>
                    <span>{t("page.tickets.details.activities.sort-ascending")}</span>
                    <BarChartIcon className="block -rotate-90" size={16} />
                  </>
                )}
              </div>
            </BorderlessButton>
          </div>
          {ticket.lastActivityBy ? (
            <Capture2 className="text-grey-darkest">
              {t("page.tickets.details.activities.subtitle", {
                time: formatDistance(i18n, { start: parseISO(ticket.lastActivityAt) }),
                name: ticket.lastActivityBy.fullName,
              })}
            </Capture2>
          ) : null}
        </div>
      </div>
      <div className={twJoin("flex flex-col pt-2", isActivitiesDescending ? "mt-4 gap-2" : "flex-col-reverse gap-4")}>
        <TicketCommentField
          canAddInternalNote={ticket.canAddInternalNote}
          canAddPublicComment={ticket.canAddPublicComment}
          onComment={askStatusChangeOrReply}
          onEditComment={editComment}
          user={sessionUser}
          ticketId={ticket.id}
          editingComment={editingComment}
          showCopilot
          autoFocus
        />
        <div className="max-h-96 overflow-y-auto rounded-lg border border-grey-lightest p-2">
          <TicketActivitiesSection
            ticketId={ticket.id}
            daysOpen={ticket.closedAt ? daysBetween(ticket.createdAt, ticket.closedAt) : undefined}
            forResident={false}
            activities={
              isActivitiesDescending
                ? [
                    ...activities,
                    {
                      id: "activity_created",
                      postedAt: ticket.createdAt,
                      author: ticket.createdBy,
                      created: ticket.createdAt,
                    },
                  ]
                : [
                    {
                      id: "activity_created",
                      postedAt: ticket.createdAt,
                      author: ticket.createdBy,
                      created: ticket.createdAt,
                    },
                    ...activities,
                  ]
            }
            onNoteEdit={(comment) => setEditingComment(comment)}
            isDescending={isActivitiesDescending}
            scrollTarget={<div ref={scrollTargetRef} />}
          />
          {hasMoreActivities ? (
            <div className="flex w-full items-center justify-center p-4">
              {isFetchingMoreActivities ? (
                <LoadingIcon className="inset-0 my-4 w-6" />
              ) : (
                <BorderlessButton onClick={() => fetchMoreActivities()}>
                  {t("page.tickets.details.activities.load-more")}
                </BorderlessButton>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
