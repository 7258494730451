import { useInfiniteQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { useProjectId } from "hooks/Network/useProjectId";
import { sumBy } from "lodash-es";
import { QUERY_KEYS } from "query-keys";

const SERVICE_AMOUNT_TO_SHOW = 10;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useFetchServicesList() {
  const projectId = useProjectId();
  const api = useApi();

  return useInfiniteQuery({
    queryKey: QUERY_KEYS.SERVICE_PARTNERS_ALL_INFINITE(projectId),
    queryFn: async ({ pageParam }) => {
      return await api
        .getServicepartnersV1({
          Offset: pageParam,
          Limit: SERVICE_AMOUNT_TO_SHOW,
          includeFromConnection: true,
          returnAllowedTypes: true,
        })
        .then(({ data }) => data);
    },
    select: (data) => ({
      allowedTypes: data.pages[0].allowedTypes,
      pages: data.pages.flatMap((data) => data.items),
      pageParams: data.pageParams,
    }),
    initialPageParam: 0,
    getNextPageParam: (latestResponseData, allResponses) => {
      const newOffset = sumBy(allResponses, (page) => page.items.length);

      return latestResponseData?.hasMore ? newOffset : undefined;
    },
  });
}
