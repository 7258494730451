import type { ConstraintListItemDto, ImageDto } from "api/types";
import award from "assets/images/award.svg";
import { Button, StaticButton } from "components/Button/Button";
import { Card, CardActions, CardContent, CardDescription, CardHeader, CardImage } from "components/Card/Card";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { Label } from "components/Label/Label";
import { TagList } from "components/Tag/TagList";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { Users as UsersIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

interface GroupCardProps {
  id: string;
  actions: ContextMenuAction[];
  image?: ImageDto;
  name: string;
  description: string;
  type: "regular" | "realEstate" | "projectConnection" | "system";
  detailLink?: string;
  isMember: boolean;
  isOwner: boolean;
  followButtonLabel: string;
  unfollowButtonLabel: string;
  onFollow: (id: string) => void;
  onUnfollow: (id: string) => void;
  groupAudience?: ConstraintListItemDto[];
  allAudienceLabel: string;
  isGroupDisabled: boolean;
  isResidentGroup: boolean;
}

export function GroupCard({
  id,
  actions,
  image,
  name,
  description,
  type,
  detailLink,
  isMember,
  isOwner,
  followButtonLabel,
  unfollowButtonLabel,
  onFollow,
  onUnfollow,
  groupAudience,
  allAudienceLabel,
  isGroupDisabled,
  isResidentGroup,
}: GroupCardProps): React.ReactNode {
  const { t } = useTranslation();
  const user = useSessionUser();

  return (
    <Card data-testid="group-list-item" link={detailLink} isFaded={isGroupDisabled}>
      <CardImage
        image={image && !isResidentGroup ? image : user.project.backgroundImage ? user.project.backgroundImage : null}
        labels={[
          !user.isAdmin || type === "regular" ? undefined : (
            <Label theme="gray">
              {type === "realEstate"
                ? t("page.discovery.group-type.real-estate")
                : type === "projectConnection"
                  ? t("page.discovery.group-type.project-connection")
                  : type === "system"
                    ? t("page.discovery.group-type.platform")
                    : undefined}
            </Label>
          ),
        ].filter(Boolean)}
      />
      <CardContent>
        <CardHeader title={name} titleLink={detailLink} menuActions={actions} />

        <div
          className={twJoin(
            "hidden sm:flex sm:items-center sm:gap-1",
            isGroupDisabled ? "text-grey-light" : "text-grey-dark",
          )}
        >
          <UsersIcon size={12} />
          {groupAudience?.length ? (
            <TagList tags={groupAudience?.map(({ key, ...item }) => ({ content: key, ...item }))} limit={3} />
          ) : (
            <i>{allAudienceLabel}</i>
          )}
        </div>

        <CardDescription description={description} />

        <CardActions>
          {isOwner ? (
            <StaticButton>
              <div className="flex items-center gap-1">
                <img className="size-4" src={award} alt="" />
                <span>{t("model.interest-group.owner")}</span>
              </div>
            </StaticButton>
          ) : (
            <Button
              className="w-full sm:w-auto sm:max-w-full"
              data-testid="group-action-follow-unfollow"
              styling={isMember ? "secondaryGreen" : "secondary"}
              onClick={() => {
                if (isMember) {
                  onUnfollow(id);
                } else {
                  onFollow(id);
                }
              }}
            >
              {isMember ? unfollowButtonLabel : followButtonLabel}
            </Button>
          )}
        </CardActions>
      </CardContent>
    </Card>
  );
}
