import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useApi } from "api/hooks/useApi";
import type { UserLookupDto } from "api/types";
import { Anchor } from "components/Anchor/Anchor";
import { BorderlessButton, Button } from "components/Button/Button";
import { ErrorPage } from "components/Error/ErrorPage";
import { Form } from "components/Form/Form";
import { FormInput } from "components/Form/FormInput";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Table } from "components/Table/Table";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useBool } from "hooks/useBool";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { routes } from "routes";
import type { ApiQueryParams } from "types/api-types";

type UsersLookupInputs = ApiQueryParams<"getUsersLookupV1">;

export function UsersLookupPage(): React.ReactNode {
  const TABLE_ROWS_LIMIT_STEP = 10;
  const { t } = useTranslation();
  const api = useApi();
  const formMethods = useForm<NonNullable<UsersLookupInputs>>({
    defaultValues: {
      IncludeAdmins: true,
      IncludeNotRegistered: true,
      IncludeDeleted: false,
    },
  });
  const [tableRowsLimit, setTableRowsLimit] = useState(TABLE_ROWS_LIMIT_STEP);
  const [searchQuery, setSearchQuery] = useState<UsersLookupInputs>(() => formMethods.getValues());
  const [isEnableDataFetching, enableDataFetching] = useBool();
  const { data, isLoading, error } = useQuery({
    queryKey: ["users-lookup", searchQuery],
    queryFn: () => api.getUsersLookupV1(searchQuery),
    select: commonAPIDataSelector,
    enabled: isEnableDataFetching,
    placeholderData: keepPreviousData,
  });
  const { data: connectedProjects = [] } = useConnectedProjects();

  const columns = useMemo(() => {
    const helper = createColumnHelper<UserLookupDto>();

    return [
      helper.accessor("id", {
        header: "id",
      }),
      helper.accessor("fullName", {
        header: "Name",
        cell: (cell) => {
          const original = cell.row.original;
          const connectedProject = connectedProjects.find((x) => x.id === original.projectId);

          return connectedProject ? (
            <Anchor to={routes.users.details({ slug: connectedProject.slug, id: original.id })}>
              {cell.getValue()}
            </Anchor>
          ) : (
            <span>{cell.getValue()}</span>
          );
        },
      }),
      helper.accessor("email", {
        header: "Email",
      }),
      helper.accessor("projectName", {
        header: "Project Name",
      }),
      helper.accessor("createdAt", {
        header: "Created at",
        cell: (cell) => {
          const value = cell.getValue();
          if (!value) {
            return <span />;
          }

          return <FormattedDate date={value} format="dateRelative" />;
        },
      }),
      helper.accessor("locatedAt", {
        header: "Address",
      }),
      helper.accessor("city", {
        header: "City",
      }),
      helper.accessor("zipCode", {
        header: "Zip",
      }),
    ];
  }, [connectedProjects]);

  const tableInstance = useReactTable<UserLookupDto>({
    columns,
    data: data?.items || [],
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    setSearchQuery((prevSearchQuery) => ({ ...prevSearchQuery, Limit: tableRowsLimit }));
  }, [tableRowsLimit]);

  function submit(values: UsersLookupInputs) {
    enableDataFetching.setTrue();
    setSearchQuery(values);
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <DocumentPaper
      theme="wide"
      title={t("page.user-lookup.title")}
      header={
        <Form
          className="mb-3 grid grid-cols-2 items-center gap-4 sm:grid-cols-3"
          formMethods={formMethods}
          onSubmit={submit}
          noPrompt
        >
          <label className="flex flex-col gap-1">
            <span>City</span>
            <FormInput<UsersLookupInputs> name="City" />
          </label>
          <label className="flex flex-col gap-1">
            <span>Street</span>
            <FormInput<UsersLookupInputs> name="Street" />
          </label>
          <label className="flex flex-col gap-1">
            <span>House</span>
            <FormInput<UsersLookupInputs> name="House" />
          </label>
          <label className="flex flex-col gap-1">
            <span>First Name</span>
            <FormInput<UsersLookupInputs> name="FirstName" />
          </label>
          <label className="flex flex-col gap-1">
            <span>Last Name</span>
            <FormInput<UsersLookupInputs> name="LastName" />
          </label>
          <label className="flex flex-col gap-1">
            <span>Email</span>
            <FormInput<UsersLookupInputs> name="Email" />
          </label>
          <Button type="submit">{isLoading ? "Loading..." : "Search"}</Button>
          {data?.total != null && (
            <span>
              Shows {data?.items?.length || 0} of {data?.total} users
            </span>
          )}
        </Form>
      }
    >
      {isEnableDataFetching ? (
        <div className="block max-w-full overflow-x-auto overflow-y-hidden">
          {data?.items?.length ? (
            <Table<UserLookupDto> className="min-w-[1000px]" table={tableInstance} />
          ) : isLoading ? (
            <FullSizeLoader size="small" />
          ) : (
            "No users found"
          )}
        </div>
      ) : (
        "Please fill in the form and click 'Search' to see the results."
      )}
      {data?.hasMore && (
        <BorderlessButton
          className="sticky bottom-0 mt-3 w-full justify-center"
          onClick={() => {
            setTableRowsLimit(tableRowsLimit + TABLE_ROWS_LIMIT_STEP);
          }}
        >
          Show more
        </BorderlessButton>
      )}
    </DocumentPaper>
  );
}
