import {
  ArrowDownRight as ArrowDownRightIcon,
  ArrowRight as ArrowRightIcon,
  ArrowUpRight as ArrowUpRightIcon,
} from "react-feather";
import { twJoin } from "tailwind-merge";

type Props = {
  increasingIsBad?: boolean;
  isWide?: boolean;
  showPlus?: boolean;
} & (
  | { type: "number" | "percentage"; oldValue: number; newValue: number; decimalPlaces?: number }
  | { percentage: number; showZero?: boolean }
);

export function GrowLabel({ increasingIsBad = false, isWide, showPlus, ...props }: Props): React.ReactNode {
  const isGrowth = "percentage" in props ? props.percentage > 0 : props.newValue > props.oldValue;
  const isSame = "percentage" in props ? props.percentage === 0 : props.newValue === props.oldValue;

  const icon = isSame ? (
    <ArrowRightIcon size={18} />
  ) : isGrowth ? (
    <ArrowUpRightIcon size={18} />
  ) : (
    <ArrowDownRightIcon size={18} />
  );

  const value = resolveValue(isSame, props);

  return (
    <span
      className={twJoin(
        "inline-flex items-center justify-between gap-1 whitespace-nowrap rounded py-1 pl-1 pr-1.5 text-sm font-semibold",
        isSame
          ? "bg-blue-lightest text-blue-dark"
          : increasingIsBad === isGrowth
            ? "bg-red-lightest text-red-dark"
            : "bg-green-lightest text-green-dark",
        isWide ? "min-w-20" : undefined,
      )}
    >
      {icon}
      {value}
    </span>
  );
}

function resolveValue(isSame: boolean, props: Props): string | number {
  if (isSame || ("oldValue" in props && props.oldValue === 0)) {
    if ("showZero" in props && props.showZero) {
      return "0%";
    }

    return "---";
  }

  if ("type" in props && props.type === "number") {
    if (props.decimalPlaces) {
      return (props.newValue - props.oldValue).toFixed(props.decimalPlaces);
    }

    return Math.round((props.newValue - props.oldValue) * 10) / 10;
  }

  const percentage = getPercentage(props);

  return `${percentage}%`;
}

function getPercentage(props: Props) {
  const percentage =
    "percentage" in props ? props.percentage : ((props.oldValue - props.newValue) / props.oldValue) * 100 * -1;

  const percentageDifference = Math.round(percentage * 10) / 10;

  if (percentageDifference > 999) {
    return 999;
  }

  if (percentageDifference < -999) {
    return -999;
  }

  if ("decimalPlaces" in props) {
    return percentageDifference.toFixed(props.decimalPlaces);
  }

  return percentageDifference;
}
