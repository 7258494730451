import type { AssetBookingDto, BookableAssetDetailDto, BookableAssetStatisticsDto } from "api/types";
import downloadIcon from "assets/icons/download-01.svg";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { BorderlessButton, Button, LinkButton } from "components/Button/Button";
import { Checkbox } from "components/Checkbox/Checkbox";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { InfoIcon } from "components/InfoIcon/InfoIcon";
import { Modal } from "components/Modal/Modal";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Capture1, Capture2, Headline4, Overline2, Subtitle2 } from "components/Text/Text";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { addDays, format, isEqual, parse, parseISO, startOfMonth, startOfWeek } from "date-fns";
import { dayOfWeekIndex } from "helpers/date";
import { useBool } from "hooks/useBool";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { BookingDetailModal } from "modules/bookings/components/BookingDetailModal";
import { CalendarMonthView } from "modules/bookings/components/CalendarMonthView";
import { CalendarWeekView } from "modules/bookings/components/CalendarWeekView";
import { DeclineBookingModal } from "modules/bookings/components/DeclineBookingModal";
import { daysOptions } from "modules/bookings/constants";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

export interface LayoutProps {
  assetDetails: BookableAssetDetailDto;
  isLoadingBookings: boolean;
  bookings: AssetBookingDto[];
  isLoadingAssetStatistics: boolean;
  assetStatistics: BookableAssetStatisticsDto | undefined;
  startDate: Date;
  endDate: Date;
  view: "week" | "month";
  onChangeView: (view: "week" | "month", startDate: Date) => void;
  exportBookings: (includeHistory: boolean) => void;
  isExporting: boolean;
}

export function Layout({
  assetDetails,
  isLoadingBookings,
  bookings,
  isLoadingAssetStatistics,
  assetStatistics,
  startDate,
  endDate,
  view,
  onChangeView,
  exportBookings,
  isExporting,
}: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const hasPermission = usePermission();
  const ref = useRef<HTMLInputElement>(null);

  const [isExportModalOpen, exportModalHandler] = useBool(false);

  const [detailBooking, setDetailBooking] = useState<{
    booking: AssetBookingDto | undefined;
    isOpen: boolean;
  }>({
    booking: undefined,
    isOpen: false,
  });

  const [declinedBooking, setDeclinedBooking] = useState<{
    booking: AssetBookingDto | undefined;
    isOpen: boolean;
  }>({
    booking: undefined,
    isOpen: false,
  });

  const getLastContinuousBooking = useCallback(
    (bookings: AssetBookingDto[], curr: AssetBookingDto): AssetBookingDto => {
      const helperDate = new Date();
      const nextBooking = bookings.find((booking) =>
        isEqual(parse(booking.startTime, "HH:mm:ss", helperDate), parse(curr.endTime, "HH:mm:ss", helperDate)),
      );

      if (nextBooking) {
        return getLastContinuousBooking(bookings, nextBooking);
      }

      return curr;
    },
    [],
  );

  const assetStatus: { status: "unavailable" | "occupied" | "available"; until?: Date } = useMemo(() => {
    const date = new Date();
    const weekday = daysOptions[dayOfWeekIndex(date)];

    const today = assetDetails.bookableDays.find((day) => day.day === weekday)!;

    if (!today.enabled) {
      return {
        status: "unavailable",
      };
    }

    const availableTimes = {
      start: today.allDay ? "00:00:00" : today.startTime!,
      end: today.allDay ? "00:00:00" : today.endTime!,
    };

    const openTime = parse(availableTimes.start, "HH:mm:ss", date);
    const closeTime = parse(
      availableTimes.end,
      "HH:mm:ss",
      availableTimes.end === "00:00:00" ? addDays(date, 1) : date,
    );

    if (
      (assetDetails.publishAt && parseISO(assetDetails.publishAt) > new Date()) ||
      (assetDetails.availableFrom && parseISO(assetDetails.availableFrom) > new Date()) ||
      (assetDetails.unpublishAt && parseISO(assetDetails.unpublishAt) < new Date()) ||
      openTime > date ||
      closeTime < date
    ) {
      return {
        status: "unavailable",
      };
    }

    const todayBookings = bookings.filter((booking) => booking.date === format(date, "yyyy-MM-dd"));
    const presentBooking = todayBookings.find(
      (booking) =>
        parse(booking.startTime, "HH:mm:ss", date) <= date && parse(booking.endTime, "HH:mm:ss", date) >= date,
    );
    if (presentBooking) {
      const lastContinuousBooking = getLastContinuousBooking(todayBookings, presentBooking);

      return {
        status: "occupied",
        until: parse(lastContinuousBooking.endTime, "HH:mm:ss", date),
      };
    }

    const nextBooking = todayBookings.find((booking) => parse(booking.startTime, "HH:mm:ss", date) > date);

    return {
      status: "available",
      until: nextBooking ? parse(nextBooking.startTime, "HH:mm:ss", date) : undefined,
    };
  }, [assetDetails, bookings, getLastContinuousBooking]);

  return (
    <DocumentPaper
      title={assetDetails.name}
      subTitle={
        <Breadcrumbs
          pages={[
            {
              name: t("page.bookings.list-assets.title"),
              to: routes.bookings.list({ slug }),
            },
            {
              name: assetDetails.name,
            },
          ]}
        />
      }
      theme="minimal"
      actions={
        <div className="flex flex-col items-center gap-2 md:flex-row">
          {hasPermission((x) => x.assets.canManageAll || x.assets.canManageOwn) ? (
            <Button styling="primaryFaded" onClick={exportModalHandler.setTrue} isLoading={isExporting}>
              <span className="flex items-center gap-2">
                <Icon name={downloadIcon} />
                {t("page.bookings.asset-detail.actions.export")}
              </span>
            </Button>
          ) : null}
          <LinkButton to={routes.bookings.bookAsset({ slug, aid: assetDetails.id })} styling="primary">
            {t("page.bookings.asset-detail.actions.book")}
          </LinkButton>
        </div>
      }
    >
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <AssetAvailabilityCard status={assetStatus.status} until={assetStatus.until} />
        <AssetLastRenterCard
          booking={assetStatistics?.mostRecentBooking}
          isLoading={isLoadingAssetStatistics}
          onViewDetails={(booking) => setDetailBooking({ booking, isOpen: true })}
        />
      </div>
      <div className="mt-4 bg-white px-5 py-4 xs:rounded-lg">
        <Headline4 as="h2">{t("page.bookings.asset-detail.calendar.title")}</Headline4>
        {view === "week" ? (
          <CalendarWeekView
            assetDetails={assetDetails}
            bookings={bookings}
            startOfWeek={startDate}
            endOfWeek={endDate}
            onChangeWeek={(date) => onChangeView("week", date)}
            onChangeView={() => onChangeView("month", startOfMonth(new Date()))}
            isLoadingBookings={isLoadingBookings}
            onSelectBooking={(booking) => setDetailBooking({ booking, isOpen: true })}
          />
        ) : (
          <CalendarMonthView
            assetDetails={assetDetails}
            bookings={bookings}
            endDate={endDate}
            startDate={startDate}
            onChangeMonth={(date) => onChangeView("month", date)}
            onChangeView={(date) => onChangeView("week", startOfWeek(date || new Date(), { weekStartsOn: 1 }))}
            onSelectBooking={(booking) => setDetailBooking({ booking, isOpen: true })}
          />
        )}
      </div>
      <Modal
        isActionRequired
        isOpen={detailBooking.isOpen}
        onAfterClose={() => setDetailBooking({ booking: undefined, isOpen: false })}
      >
        {detailBooking.booking ? (
          <BookingDetailModal
            booking={detailBooking.booking}
            onClose={() => setDetailBooking((x) => ({ ...x, isOpen: false }))}
            onDeleteBooking={() => {
              setDeclinedBooking({ booking: detailBooking.booking, isOpen: true });
              setDetailBooking((x) => ({ ...x, isOpen: false }));
            }}
          />
        ) : null}
      </Modal>
      <Modal
        isActionRequired
        isOpen={declinedBooking.isOpen}
        onAfterClose={() => setDeclinedBooking({ booking: undefined, isOpen: false })}
        overflowYVisible
      >
        {declinedBooking.booking ? (
          <DeclineBookingModal
            bookingId={declinedBooking.booking.id}
            assetId={declinedBooking.booking.asset.id}
            onClose={() => setDeclinedBooking((x) => ({ ...x, isOpen: false }))}
          />
        ) : null}
      </Modal>
      <Modal
        isOpen={isExportModalOpen}
        onRequestClose={exportModalHandler.setFalse}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
      >
        <div className="flex flex-col gap-4 p-8">
          <Headline4>{t("page.bookings.asset-detail.export.modal.title")}</Headline4>
          <span className="flex items-center gap-2">
            <Checkbox ref={ref} />
            <Capture2>{t("page.bookings.asset-detail.export.modal.checkbox")}</Capture2>
          </span>
          <Overline2 className="text-grey">{t("page.bookings.asset-detail.export.modal.description")}</Overline2>
          <div className="flex items-center gap-2">
            <Button styling="primaryFaded" onClick={exportModalHandler.setFalse}>
              {t("common.action.cancel")}
            </Button>
            <Button
              styling="primary"
              onClick={() => {
                exportBookings(ref.current?.checked ?? false);
                exportModalHandler.setFalse();
              }}
            >
              {t("page.bookings.asset-detail.export.modal.button")}
            </Button>
          </div>
        </div>
      </Modal>
    </DocumentPaper>
  );
}

function AssetInsightsCard({
  title,
  tooltip,
  children,
}: {
  title: string;
  tooltip: string;
  children: React.ReactNode;
}) {
  return (
    <div className="flex flex-col gap-2 rounded-lg bg-white p-4">
      <div className="flex justify-between">
        <Subtitle2>{title}</Subtitle2>
        <InfoIcon tooltip={tooltip} />
      </div>
      {children}
    </div>
  );
}

function AssetAvailabilityCard({ status, until }: { status: "unavailable" | "occupied" | "available"; until?: Date }) {
  const { t } = useTranslation();

  return (
    <AssetInsightsCard
      title={t("page.bookings.asset-detail.insights.status.title")}
      tooltip={t("page.bookings.asset-detail.insights.status.tooltip")}
    >
      <div
        className={twJoin(
          "flex w-full justify-center rounded-full p-2",
          status === "unavailable"
            ? "bg-grey-lightest text-grey"
            : status === "occupied"
              ? "bg-red-lightest text-red-dark"
              : "bg-green-lightest text-green-darker",
        )}
      >
        {status === "unavailable" ? (
          <Capture1>{t("page.bookings.asset-detail.insights.status.option.unavailable")}</Capture1>
        ) : status === "occupied" ? (
          <Capture1>
            {t("page.bookings.asset-detail.insights.status.option.occupied", {
              time: format(until!, "HH:mm"),
            })}
          </Capture1>
        ) : (
          <Capture1>
            {until
              ? t("page.bookings.asset-detail.insights.status.option.available-until", {
                  time: format(until, "HH:mm"),
                })
              : t("page.bookings.asset-detail.insights.status.option.available-all-day")}
          </Capture1>
        )}
      </div>
    </AssetInsightsCard>
  );
}

function AssetLastRenterCard({
  booking,
  isLoading,
  onViewDetails,
}: {
  booking?: AssetBookingDto;
  isLoading: boolean;
  onViewDetails: (booking: AssetBookingDto) => void;
}) {
  const { t } = useTranslation();

  return (
    <AssetInsightsCard
      title={t("page.bookings.asset-detail.insights.last-renter.title")}
      tooltip={t("page.bookings.asset-detail.insights.last-renter.tooltip")}
    >
      {isLoading ? (
        <LoadingIcon className="size-4" />
      ) : booking && booking.author ? (
        <div className="flex cursor-pointer items-center gap-2">
          <div className="size-8" onClick={() => onViewDetails(booking)}>
            <UserAvatar img={booking.author.avatar} isUserDeleted={!!booking.author.deletedAt} />
          </div>
          <BorderlessButton onClick={() => onViewDetails(booking)}>{booking.author.fullName}</BorderlessButton>
        </div>
      ) : (
        <Capture1>{t("page.bookings.asset-detail.insights.last-renter.not-available")}</Capture1>
      )}
    </AssetInsightsCard>
  );
}
