import type { ConversationDto } from "api/types";
import { BorderlessButton, Button, IconButton } from "components/Button/Button";
import { Checkbox } from "components/Checkbox/Checkbox";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { LoadingIcon } from "components/Icons/Icons";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Capture2, Overline2 } from "components/Text/Text";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { UserDeletedTag } from "components/UserDeletedTag/UserDeletedTag";
import { cutOffAfterFirstLine, limitTextByChar } from "helpers/util";
import { useBool } from "hooks/useBool";
import { useOnIntersection } from "hooks/useOnIntersection";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { UserSearchModal } from "modules/chats/components/UserSearchModal";
import { useCallback } from "react";
import { Paperclip, Plus } from "react-feather";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

export interface ChatsListLayoutProps {
  chats: ConversationDto[];
  hasMoreChats: boolean | undefined;
  fetchMoreChats: () => void;
  refreshChats: () => void;
  isLoadingChats: boolean;
  isLoadingMoreChats: boolean;
  chatState: boolean | undefined;
  toggleChats: (state: boolean) => Promise<void>;
}

export function Layout({
  chats,
  hasMoreChats,
  fetchMoreChats,
  refreshChats,
  isLoadingChats,
  isLoadingMoreChats,
  chatState,
  toggleChats,
}: ChatsListLayoutProps): React.ReactNode {
  const { t } = useTranslation();
  const ref = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isLoadingMoreChats && hasMoreChats) {
        fetchMoreChats();
      }
    }, [fetchMoreChats, hasMoreChats, isLoadingMoreChats]),
  });
  const [isModalOpen, modalOpenHandler] = useBool(false);
  const hasPermission = usePermission();

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.chats.title")}
      subTitle={t("page.chats.subtitle")}
      actions={
        <>
          <Button
            className="hidden md:block"
            data-testid="create-chat"
            title={
              !chatState || !hasPermission((x) => x.userManagement.canListUsers)
                ? t("page.chats.new-chat.disabled")
                : undefined
            }
            disabled={!chatState || !hasPermission((x) => x.userManagement.canListUsers)}
            onClick={modalOpenHandler.setTrue}
          >
            + {t("page.chats.new-chat")}
          </Button>
          <IconButton
            className="inline rounded-full bg-aop-basic-blue p-2 text-white md:hidden"
            disabled={!chatState || !hasPermission((x) => x.userManagement.canListUsers)}
            title={
              !chatState || !hasPermission((x) => x.userManagement.canListUsers)
                ? t("page.chats.new-chat.disabled")
                : t("page.message-feed.button.create.post")
            }
            onClick={modalOpenHandler.setTrue}
          >
            <Plus size={24} />
          </IconButton>
        </>
      }
      header={
        <div className="flex items-center justify-center gap-4 md:justify-end">
          <label className="hidden items-center gap-2 md:flex" htmlFor="chat_toggle">
            <Checkbox name="chat_toggle" onChange={() => toggleChats(!chatState)} checked={!!chatState} />
            <span>{t("page.chats.toggle")}</span>
          </label>
          <BorderlessButton
            className="font-normal text-aop-basic-blue underline underline-offset-4"
            onClick={refreshChats}
          >
            {t("page.chats.refresh")}
          </BorderlessButton>
        </div>
      }
    >
      {isLoadingChats ? (
        <FullSizeLoader withPadding />
      ) : chats.length === 0 ? (
        <div className="rounded-md bg-white p-4">
          <Capture2 className="text-grey-dark">{t("page.chats.no-chats")}</Capture2>
        </div>
      ) : (
        <div className="max-h-[680px] overflow-y-auto rounded-md bg-white">
          <ul>
            {chats.map((chat) => (
              <li data-testid="conversation-row" key={chat.id}>
                <ChatMessage {...chat} />
              </li>
            ))}
          </ul>
          {hasMoreChats && (
            <div className="p-4" ref={ref}>
              {isLoadingMoreChats && <LoadingIcon className="inset-0 mx-auto my-4 w-6" />}
            </div>
          )}
        </div>
      )}
      <UserSearchModal isOpen={isModalOpen} onClose={modalOpenHandler.setFalse} />
    </DocumentPaper>
  );
}

const CHAR_LIMIT = 120;

function ChatMessage({ person, latestMessage, unreadMessages, isUnread }: ConversationDto): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();

  return person ? (
    <Link
      className="flex cursor-pointer items-center justify-between rounded-md border-b border-b-grey-lightest bg-white p-4 hover:drop-shadow-lg focus:outline-none focus-visible:cursor-pointer focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-aop-basic-blue active:bg-grey-lightest"
      to={routes.users.details({ slug, id: person.id })}
    >
      <div>
        <div className="flex grow items-center gap-3">
          <div className="size-10 shrink-0">
            <UserAvatar img={person.avatar} isUserDeleted={!!person.deletedAt} hideDeletedIcon />
          </div>
          <div className="text-sm">
            <div className="flex items-center gap-1">
              <span className={twJoin("font-semibold", person.deletedAt ? "text-grey" : "text-black")}>
                {person.fullName}
              </span>
              {!!person.deletedAt && <UserDeletedTag />}
            </div>
            {latestMessage?.time && (
              <p className={isUnread ? "text-aop-basic-blue" : "text-grey"}>
                <FormattedDate format="datetime" date={latestMessage?.time} />
              </p>
            )}
          </div>
        </div>
        <div className="pt-2">
          {latestMessage?.text ? (
            <Capture2 className="sentry-block">
              {cutOffAfterFirstLine(limitTextByChar(latestMessage.text, CHAR_LIMIT))}
            </Capture2>
          ) : (
            latestMessage?.image && (
              <span className="flex items-center gap-1">
                <Paperclip className="text-grey-light" size={16} />
                <Capture2>{t("page.chats.attachment")}</Capture2>
              </span>
            )
          )}
        </div>
      </div>
      {unreadMessages > 0 && (
        <div className="flex size-5 items-center justify-center rounded-full bg-aop-basic-blue p-1 text-white md:mr-11">
          <Overline2>{unreadMessages}</Overline2>
        </div>
      )}
    </Link>
  ) : (
    <></>
  );
}
