import type { UserRoleDto } from "api/types";

interface BaseRoleDto {
  roleType: "readOnly" | "resident" | "projectOwner" | "maintenance" | "caretaker";
}

export type FormPermissions<T extends BaseRoleDto> = Record<string, T & { name: string }>;

export function createFormPermissions<T extends BaseRoleDto>(
  roles: UserRoleDto[],
  defaultPermissions: T[],
  permissions: T[] | undefined,
): FormPermissions<T> {
  return Object.fromEntries(
    defaultPermissions.map((defaultPermission) => {
      const permission = permissions?.find(({ roleType }) => roleType === defaultPermission.roleType);
      const role = roles.find(({ type }) => type === defaultPermission.roleType);

      if (role == null) {
        console.error("permissions are invalid");
      }

      const formPermission = {
        ...defaultPermission,
        ...permission,
        name: role?.name || "[ROLE_NAME]",
      };

      return [formPermission.roleType, formPermission];
    }),
  );
}

export function toPermissionsRequest<T extends BaseRoleDto>(formPermissions: FormPermissions<T>): T[] {
  return Object.values(formPermissions);
}
