import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import type { AddressDto, UserDto } from "api/types";
import { Anchor } from "components/Anchor/Anchor";
import { Button, IconButton, LinkButton } from "components/Button/Button";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { ContextMenu } from "components/ContextMenu/ContextMenu";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Modal } from "components/Modal/Modal";
import { MultiSelect } from "components/MultiSelect/MultiSelect";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { SearchInput } from "components/SearchInput/SearchInput";
import { Table } from "components/Table/Table";
import { Subtitle1 } from "components/Text/Text";
import { formatAddress } from "helpers/address";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useDebounce } from "hooks/useDebounce";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { convertToCsv, downloadCsvAsExcel } from "modules/admin/helpers/sheet";
import { useCallback, useDeferredValue, useMemo, useState } from "react";
import { Async } from "react-async";
import { Download as DownloadIcon, RefreshCcw as RefreshCcwIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

export interface LayoutProps {
  addresses: AddressDto[];
  addressTypes: string[];
  onRefreshLocationCode: (addressId: string) => Promise<unknown>;
  onDelete: (address: AddressDto) => Promise<unknown>;
}

const DEBOUNCE_WAIT = 200;

export function Layout({ addresses, addressTypes, onRefreshLocationCode, onDelete }: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const sessionUser = useSessionUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<AddressDto>("address-delete-modal");
  const [locationCodeModalAddressId, setLocationCodeModalAddressId] = useState<string>();
  const [isRefreshingLocationCode, refreshingLocationCodeHandlers] = useBool();
  const [query, setQuery] = useState("");
  const [addressTypeFilter, setAddressTypeFilter] = useState<string[]>([]);
  const deferredQuery = useDeferredValue(useDebounce(query.toLowerCase().trim(), DEBOUNCE_WAIT));
  const [showExportModal, showExportModalHandlers] = useBool();

  const mappedAddresses = useMemo(
    () =>
      addresses.map((x) => ({
        ...x,
        address: formatAddress(x),
        registeredUsers: x.users.filter((u) => u.registeredAt),
        notRegisteredUsers: x.users.filter((u) => !u.registeredAt),
        searchableString: `${formatAddress(x)}|||${x.postalCode}|||${x.building.name})|||${x.floor}|||${
          x.typeConstraint?.key ?? ""
        }|||${x.city}|||${x.locationCode}|||${x.users.map((x) => x.fullName).join("|||")}|||${x.users
          .map((x) => x.email)
          .join("|||")}`.toLowerCase(),
      })),
    [addresses],
  );

  const hasPermission = usePermission();
  const canManage = hasPermission((x) => x.addressManagement.canManageAddresses);
  const canEdit = hasPermission((x) => x.addressManagement.canEditAddress);

  const filteredAddresses = useMemo(
    () =>
      mappedAddresses
        .filter((x) => x.searchableString.includes(deferredQuery))
        .filter((x) => addressTypeFilter.length === 0 || addressTypeFilter.includes(x.typeConstraint?.key ?? "")),
    [mappedAddresses, deferredQuery, addressTypeFilter],
  );

  const anyUsers = useMemo(() => addresses.some((x) => x.users.length > 0), [addresses]);

  type ColumnType = (typeof mappedAddresses)[number];

  const columns = useMemo(() => {
    const helper = createColumnHelper<ColumnType>();

    return [
      helper.accessor("building.name", {
        header: t("page.addresses.list.building"),
      }),
      helper.accessor("city", {
        header: t("page.addresses.list.city"),
      }),
      helper.accessor("postalCode", {
        header: t("page.addresses.list.postal-code"),
      }),
      helper.accessor("address", {
        header: t("page.addresses.list.address"),
        cell: (cell) =>
          canEdit ? (
            <Anchor to={routes.addresses.edit({ slug, id: cell.row.original.id })}>{cell.getValue()}</Anchor>
          ) : (
            <div>{cell.getValue()}</div>
          ),
      }),
      helper.accessor("typeConstraint", {
        header: t("page.addresses.list.type"),
        cell: (cell) => <div>{cell.getValue()?.key ?? "-"}</div>,
      }),
      ...(sessionUser.project.type === "addressBased"
        ? [
            helper.accessor("locationCode", {
              header: t("page.addresses.list.location-code"),
              cell: (cell) => (
                <div className="flex items-center justify-between gap-2">
                  <span>{cell.getValue()}</span>
                  {canEdit ? (
                    <IconButton
                      className="text-aop-basic-blue"
                      title={t("page.addresses.list.refresh-location-code")}
                      onClick={() => setLocationCodeModalAddressId(cell.row.original.id)}
                    >
                      <RefreshCcwIcon size={16} />
                    </IconButton>
                  ) : null}
                </div>
              ),
            }),
          ]
        : []),
      ...(anyUsers
        ? [
            helper.accessor("registeredUsers", {
              header: t("page.addresses.list.onboarded-users"),
              cell: (cell) => <UsersList users={cell.getValue()} />,
            }),
            helper.accessor("notRegisteredUsers", {
              header: t("page.addresses.list.invited-users"),
              cell: (cell) => <UsersList users={cell.getValue()} />,
            }),
          ]
        : []),
      helper.accessor("id", {
        header: "",
        cell: (cell) => {
          const id = cell.getValue();
          const actions: ContextMenuAction[] = [];

          if (canEdit) {
            actions.push({
              dataTestId: "context-menu-edit-btn",
              callback: () => {
                navigate(routes.addresses.edit({ slug, id: id }));
              },
              text: t("common.action.edit"),
            });
          }

          if (canManage) {
            actions.push({
              dataTestId: "context-menu-delete-btn",
              callback: () => openDeleteModal(cell.row.original),
              text: t("common.action.delete"),
              status: {
                disabled: cell.row.original.users.some((x) => x.deletedAt == null),
                disabledText: t("page.addresses.list.actions.delete.forbidden"),
              },
            });
          }

          return (
            <div className="flex justify-end px-2">
              <ContextMenu className="text-grey-darker" actions={actions} />
            </div>
          );
        },
      }),
    ];
  }, [anyUsers, canEdit, canManage, slug, navigate, openDeleteModal, sessionUser.project.type, t]);

  const tableInstance = useReactTable({
    columns,
    data: filteredAddresses,
    getCoreRowModel: getCoreRowModel(),
  });

  const registeredAddressCount = mappedAddresses.filter((x) => x.registeredUsers.some((x) => !x.isAdmin)).length;
  const addressCount = mappedAddresses.length === 0 ? 0 : mappedAddresses.length - 1;

  const csv = useCallback(
    async () => ({
      activated: await convertToCsv(
        mappedAddresses.flatMap((a) =>
          a.registeredUsers.map((u) => ({
            building: a.building.name,
            address: formatAddress(a),
            email: u.email,
            fullName: u.fullName,
            locationCode: a.locationCode,
          })),
        ),
        { skipHeader: true },
      ),
      notActivated: await convertToCsv(
        mappedAddresses.flatMap((a) =>
          a.notRegisteredUsers.map((u) => ({
            building: a.building.name,
            address: formatAddress(a),
            email: u.email,
            fullName: u.fullName,
            locationCode: a.locationCode,
          })),
        ),
        { skipHeader: true },
      ),
    }),
    [mappedAddresses],
  );

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.addresses.title")}
      subTitle={t("page.addresses.subtitle")}
      actions={
        canManage ? (
          <LinkButton to={routes.addresses.new({ slug })} data-testid="address-create-link">
            {t("page.addresses.actions.create")}
          </LinkButton>
        ) : null
      }
      header={
        <div className="flex flex-col flex-wrap items-center justify-between gap-4 md:flex-row">
          <div className="flex flex-col items-center gap-4 md:flex-row">
            <SearchInput
              className="h-10"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder={t("page.addresses.list.search.placeholder")}
            />
            {addressTypes.length > 1 ? (
              <div className="min-w-52">
                <MultiSelect
                  placeholder={t("page.addresses.list.filter.placeholder")}
                  selected={addressTypeFilter}
                  items={addressTypes}
                  renderOption={(x) => x}
                  keySelector={(x) => x}
                  onChange={setAddressTypeFilter}
                />
              </div>
            ) : null}
          </div>
          <p className="pl-2 text-right text-grey-darker">
            <span>{t("page.addresses.list.address-count", { count: filteredAddresses.length })}</span>
            {sessionUser.project.type === "companyBased" ? null : (
              <>
                <span> </span>
                <span>
                  {t("page.addresses.list.registered", {
                    registered: registeredAddressCount,
                    addresses: addressCount,
                    percentage: addressCount === 0 ? 0 : Math.round((registeredAddressCount / addressCount) * 100),
                  })}
                </span>
              </>
            )}
          </p>
        </div>
      }
    >
      <div className="overflow-auto">
        {addresses.length === 0 || filteredAddresses.length === 0 ? (
          <div className="rounded-lg bg-white p-5">
            <p>{addresses.length === 0 ? t("page.addresses.list.empty") : t("page.addresses.list.no-result")}</p>
          </div>
        ) : (
          <div className="flex flex-col gap-8 pb-8">
            <Table
              table={tableInstance}
              data-testid="address-list"
              getRowProps={() => ({
                "data-testid": "address-item",
              })}
            />
            {deferredQuery ? null : (
              <div className="flex justify-end">
                <Button onClick={showExportModalHandlers.setTrue}>
                  <span className="flex items-center gap-2">
                    <DownloadIcon size={16} />
                    {t("page.addresses.download-export")}
                  </span>
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      <Modal isOpen={showExportModal} onRequestClose={showExportModalHandlers.setFalse} shouldCloseOnEsc>
        {showExportModal ? (
          <Async promiseFn={csv} onReject={console.error}>
            <Async.Fulfilled<{ activated: string; notActivated: string }>>
              {(x) => (
                <div className="flex flex-col gap-6 px-10 py-6">
                  <div className="flex min-h-32 flex-col gap-2">
                    <Subtitle1 className="flex gap-2 px-2">
                      <span>{t("page.addresses.list.onboarded-users")}</span>
                      <IconButton
                        onClick={() => downloadCsvAsExcel(`activated-users-${sessionUser.project.name}`, x.activated)}
                        title={t("page.addresses.list.export-as-xlsx")}
                      >
                        <DownloadIcon size={16} />
                      </IconButton>
                    </Subtitle1>
                    <pre className="overflow-auto rounded-lg bg-grey-lightest px-2 py-1">
                      <code>{x.activated}</code>
                    </pre>
                  </div>
                  <div className="flex min-h-32 flex-col gap-2">
                    <Subtitle1 className="flex gap-2 px-2">
                      <span>{t("page.addresses.list.invited-users")}</span>
                      <IconButton
                        onClick={() =>
                          downloadCsvAsExcel(`non-activated-users-${sessionUser.project.name}`, x.notActivated)
                        }
                        title={t("page.addresses.list.export-as-xlsx")}
                      >
                        <DownloadIcon size={16} />
                      </IconButton>
                    </Subtitle1>
                    <pre className="overflow-auto rounded-lg bg-grey-lightest px-2 py-1">
                      <code>{x.notActivated}</code>
                    </pre>
                  </div>
                </div>
              )}
            </Async.Fulfilled>
            <Async.Pending>
              <div className="w-52 max-w-sm py-12">
                <FullSizeLoader />
              </div>
            </Async.Pending>
            <Async.Rejected>
              <p className="p-8">Something went wrong...</p>
            </Async.Rejected>
          </Async>
        ) : null}
      </Modal>
      <DeleteModal
        title={t("page.addresses.actions.delete.title")}
        description={t("page.addresses.actions.delete.description")}
        onDelete={onDelete}
        deleteBtnProps={{
          "data-testid": "modal-confirm-delete",
        }}
        {...deleteModalProps}
      />
      <ConfirmModal
        id="location-code-modal"
        data-testid="location-code-modal"
        title={t("page.addresses.list.location-code.refresh.title")}
        description={t("page.addresses.list.location-code.refresh.description")}
        isOpen={!!locationCodeModalAddressId}
        shouldCloseOnEsc
        onReject={() => setLocationCodeModalAddressId(undefined)}
        rejectBtnProps={{
          text: t("page.addresses.list.location-code.refresh.actions.cancel"),
        }}
        onResolve={async () => {
          try {
            if (locationCodeModalAddressId) {
              refreshingLocationCodeHandlers.setTrue();
              await onRefreshLocationCode(locationCodeModalAddressId);
            }
          } finally {
            refreshingLocationCodeHandlers.setFalse();

            setLocationCodeModalAddressId(undefined);
          }
        }}
        resolveBtnProps={{
          text: t("page.addresses.list.location-code.refresh.actions.confirm"),
        }}
        isLoading={isRefreshingLocationCode}
      />
    </DocumentPaper>
  );
}

function UsersList({ users }: { users: UserDto[] }) {
  const slug = useSlug();

  return (
    <div className="flex flex-col py-2">
      {users.map((x) =>
        x.canViewProfile ? (
          <Anchor key={x.id} to={routes.users.details({ slug, id: x.id })}>
            <span className="my-0.5 text-sm">{x.fullName}</span>
          </Anchor>
        ) : (
          <span key={x.id}>{x.fullName}</span>
        ),
      )}
    </div>
  );
}
