import type { AdminTicketActivityDto, AdminTicketCommentDto } from "api/types";
import { formatDate, FormattedDate } from "components/FormattedDate/FormattedDate";
import { Capture2, Subtitle2 } from "components/Text/Text";
import { TicketStatus } from "components/Ticket/TicketStatus";
import { AnimatePresence } from "framer-motion";
import { memo, useMemo } from "react";
import { Star as StarIcon } from "react-feather";
import { Trans, useTranslation } from "react-i18next";

import { groupActivities, TicketActivityWrapper, TicketComment } from "./TicketActivity";

interface TicketActivity extends AdminTicketActivityDto {
  created?: string;
}

interface Props {
  ticketId: string;
  daysOpen?: number;
  forResident: boolean;
  activities: TicketActivity[];
  onNoteEdit?: (comment: AdminTicketCommentDto) => void;
  isDescending?: boolean;
  scrollTarget?: React.ReactNode;
}

function TicketActivitySection({
  ticketId,
  daysOpen,
  forResident,
  activities,
  onNoteEdit,
  isDescending,
  scrollTarget,
}: Props): React.ReactNode {
  const { i18n, t } = useTranslation();
  const groupedActivities = useMemo(() => groupActivities(activities), [activities]);

  if (activities.length === 0) {
    return <div className="pt-6">{t("page.tickets.details.activities.none")}</div>;
  }

  return (
    <>
      {isDescending && scrollTarget}
      {groupedActivities.map(([date, activities]) => (
        <div key={date.toString()} data-testid="activities-date-group">
          <div className="relative my-4 flex items-center justify-center whitespace-nowrap text-sm text-grey-lighter">
            <span className="absolute h-px w-full max-w-xs bg-grey-lighter" />
            <Capture2 className="relative bg-white px-4">
              <FormattedDate format="dateRelative" date={new Date(Number(date)).toISOString()} />
            </Capture2>
          </div>
          <div>
            <AnimatePresence>
              {activities.map((activity) => {
                if (activity.comment) {
                  return (
                    <TicketComment
                      key={"comment-" + activity.comment.id}
                      ticketId={ticketId}
                      comment={activity.comment}
                      onEdit={onNoteEdit}
                    />
                  );
                }

                if (activity.assigneeChange) {
                  return (
                    <TicketActivityWrapper key={activity.id} author={activity.author} postedAt={activity.postedAt}>
                      {activity.assigneeChange.oldAssignee ? (
                        <Trans
                          i18nKey="page.tickets.details.activities.assignee-change"
                          components={{
                            assignee: (
                              <span
                                className="mx-1 font-semibold not-italic"
                                data-testid="activity-assignee-change"
                                data-value={activity.assigneeChange.newAssignee.fullName}
                              />
                            ),
                          }}
                          values={{
                            oldAssignee: activity.assigneeChange.oldAssignee.fullName,
                            newAssignee: activity.assigneeChange.newAssignee.fullName,
                          }}
                        />
                      ) : (
                        <Trans
                          i18nKey="page.tickets.details.activities.new-assignee"
                          components={{
                            assignee: (
                              <span
                                className="mx-1 font-semibold not-italic"
                                data-testid="activity-assignee-change"
                                data-value={activity.assigneeChange.newAssignee.fullName}
                              />
                            ),
                          }}
                          values={{
                            newAssignee: activity.assigneeChange.newAssignee.fullName,
                          }}
                        />
                      )}
                    </TicketActivityWrapper>
                  );
                }

                if (activity.statusChange) {
                  return (
                    <TicketActivityWrapper key={activity.id} author={activity.author} postedAt={activity.postedAt}>
                      <Trans
                        i18nKey="page.tickets.details.activities.status-change"
                        components={{
                          oldStatus: (
                            <span className="mx-1 inline-block font-semibold not-italic">
                              <TicketStatus
                                description={activity.statusChange.oldStatus.name}
                                labelColor={activity.statusChange.oldStatus.color}
                              />
                              <span />
                            </span>
                          ),
                          newStatus: (
                            <span
                              className="mx-1 inline-block font-semibold not-italic"
                              data-testid="activity-status-change"
                              data-value={activity.statusChange.newStatus.id}
                            >
                              <TicketStatus
                                description={activity.statusChange.newStatus.name}
                                labelColor={activity.statusChange.newStatus.color}
                              />
                              <span />
                            </span>
                          ),
                        }}
                      />
                    </TicketActivityWrapper>
                  );
                }

                if (activity.created) {
                  return (
                    <TicketActivityWrapper key={activity.id} author={activity.author} postedAt={activity.postedAt}>
                      {t("page.tickets.details.activities.created", {
                        date: formatDate(i18n, "datetimeRelative", activity.postedAt),
                      })}
                    </TicketActivityWrapper>
                  );
                }

                if (activity.rating) {
                  return (
                    <TicketActivityWrapper
                      key={activity.id}
                      author={activity.author}
                      postedAt={activity.postedAt}
                      withoutBorder
                    >
                      <span className="grid items-center gap-x-2 gap-y-0.5 not-italic all-child:odd:mt-2 xs:grid-cols-[auto_1fr] xs:gap-y-2 xs:all-child:odd:mt-0">
                        <span className="font-semibold xs:font-normal">
                          {t("page.tickets.details.activities.rated.rating")}
                        </span>
                        <span>
                          {activity.rating.rating ? (
                            <span className="inline-flex items-center gap-1 rounded bg-yellow-lightest px-2 py-0.5">
                              <span className="flex gap-0.5">
                                {Array(5)
                                  .fill(undefined)
                                  .map((_, i) => (
                                    <StarIcon
                                      className={(activity.rating?.rating || 0) > i ? "text-yellow" : "text-white"}
                                      key={i}
                                      size={16}
                                      fill="currentColor"
                                    />
                                  ))}
                              </span>
                              <Subtitle2>{activity.rating.rating ?? "-"}</Subtitle2>
                            </span>
                          ) : (
                            "-"
                          )}
                        </span>
                        {forResident ? null : (
                          <>
                            <span className="font-semibold xs:font-normal">
                              {t("page.tickets.details.activities.rated.run-time")}
                            </span>
                            <span>
                              {daysOpen == null
                                ? "-"
                                : t("page.tickets.details.activities.rated.run-time.days", { count: daysOpen })}
                            </span>
                          </>
                        )}
                        <span className="font-semibold xs:font-normal">
                          {t("page.tickets.details.activities.rated.remark")}
                        </span>
                        {activity.rating?.ratingRemark ? (
                          <span>{activity.rating.ratingRemark}</span>
                        ) : (
                          <span className="italic">
                            {activity.rating.ratingRemark || t("page.tickets.details.activities.rated.remark.none")}
                          </span>
                        )}
                      </span>
                    </TicketActivityWrapper>
                  );
                }

                return null;
              })}
            </AnimatePresence>
          </div>
        </div>
      ))}
      {!isDescending && scrollTarget}
    </>
  );
}

const TicketActivitiesSectionMemo = memo(TicketActivitySection);

export { TicketActivitiesSectionMemo as TicketActivitiesSection };
