import type { UserDto } from "api/types";
import { AnchorButton } from "components/Anchor/Anchor";
import { LoadingIcon } from "components/Icons/Icons";
import { Modal } from "components/Modal/Modal";
import { Headline4 } from "components/Text/Text";
import { useBool } from "hooks/useBool";
import { useTranslation } from "react-i18next";

import { TicketCommentField } from "./TicketCommentField";

interface Props {
  title: React.ReactNode;
  description: React.ReactNode;
  canCommentInternal: boolean;
  canCommentPublic: boolean;
  isOpen: boolean;
  assignee?: UserDto;
  user: UserDto;
  onClose?: () => void | Promise<void>;
  onSubmit: (value: string, note: boolean) => void | Promise<void>;
}

export function TicketLeaveCommentModal({
  title,
  description,
  canCommentInternal,
  canCommentPublic,
  isOpen,
  user,
  onClose,
  onSubmit,
}: Props): React.ReactNode {
  const { t } = useTranslation();
  const [submitting, submittingHandlers] = useBool();

  async function submit({ value, note = false }: { value: string; note?: boolean }) {
    if (submitting) {
      return;
    }

    try {
      submittingHandlers.setTrue();

      if (value) {
        await onSubmit?.(value, note);
      } else {
        await onClose?.();
      }
    } finally {
      submittingHandlers.setFalse();
    }
  }

  async function close() {
    if (submitting) {
      return;
    }

    try {
      submittingHandlers.setTrue();

      await onClose?.();
    } finally {
      submittingHandlers.setFalse();
    }
  }

  return (
    <Modal
      isOpen={isOpen && (canCommentPublic || canCommentInternal)}
      onRequestClose={close}
      shouldCloseOnEsc={!submitting}
    >
      <div className="max-w-md rounded p-4 text-center" data-testid="quick-repair-request-leave-comment-modal">
        <Headline4 className="my-4 block">{title}</Headline4>

        {submitting ? (
          <LoadingIcon className="mx-auto mb-6 mt-8 w-8" />
        ) : (
          <>
            <p className="mx-1 my-4">{description}</p>

            <TicketCommentField
              canAddInternalNote={canCommentInternal}
              canAddPublicComment={canCommentPublic}
              user={user}
              disallowImages
              onComment={(payload) => submit({ value: payload.content, note: payload.internal })}
              autoFocus
            />

            <div className="mt-2 flex w-full justify-center text-right">
              <AnchorButton onClick={close}>{t("component.tickets.leave-comment-modal.skip")}</AnchorButton>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}
