import { useSessionUser } from "hooks/Network/useSessionUser";
import { useSlug } from "hooks/useSlug";
import { Navigate } from "react-router-dom";
import { routes } from "routes";

import { Layout } from "./Layout";
import { Loader } from "./Loader";

export function Home(): React.ReactNode {
  const slug = useSlug();
  const sessionUser = useSessionUser();

  if (sessionUser.role.type === "maintenance") {
    return <Navigate to={routes.tickets.list({ slug })} replace />;
  }

  if (!sessionUser.isAdmin) {
    return <Navigate to={routes.messageFeed.list({ slug })} replace />;
  }

  return <Loader>{(data) => <Layout {...data} />}</Loader>;
}
