import type { UserDetailsWithAddressDto } from "api/types";
import { Capture1, Capture2, Headline4, Subtitle2 } from "components/Text/Text";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { addressTagsToDictionary } from "helpers/address";
import React from "react";
import type { IconProps } from "react-feather";
import {
  Briefcase as BriefcaseIcon,
  Home as HomeIcon,
  Key as KeyIcon,
  Layers as LayersIcon,
  Mail as MailIcon,
  MapPin as MapPinIcon,
  Phone as PhoneIcon,
} from "react-feather";
import { useTranslation } from "react-i18next";

interface Props {
  user: UserDetailsWithAddressDto;
}

export function UserDetails({ user }: Props): React.ReactNode {
  const { t } = useTranslation();
  const { building, apartmentType, company, floor } = addressTagsToDictionary(user.addressTags);

  return (
    <div className="flex max-w-sm flex-col gap-3.5">
      <Subtitle2 className="text-grey">{t("page.tickets.create.reporter.title")}</Subtitle2>
      <div className="rounded-lg border border-grey-lighter p-6 shadow-sm">
        <div className="flex gap-5">
          <UserAvatar className="size-20" img={user.avatar} isUserDeleted={!!user.deletedAt} />
          <div className="flex flex-1 flex-col gap-4 text-grey-darker">
            <div className="flex flex-col gap-1.5">
              <Headline4 className="text-black">{user.fullName}</Headline4>
              {building ? <UserDetail icon={<HomeIcon />}>{building}</UserDetail> : null}
              {!company && user.locatedAt ? <UserDetail icon={<MapPinIcon />}>{user.locatedAt}</UserDetail> : null}
              {floor ? <UserDetail icon={<LayersIcon />}>{floor}</UserDetail> : null}
              {apartmentType ? <UserDetail icon={<KeyIcon />}>{apartmentType}</UserDetail> : null}
            </div>
            {user.email || user.phoneNumber ? (
              <>
                <hr className="h-px w-full border-0 bg-grey-lighter" />
                <div className="flex flex-col gap-1.5">
                  <Capture1 className="text-black">{t("page.tickets.create.reporter.contact-details")}</Capture1>
                  {user.email ? <UserDetail icon={<MailIcon />}>{user.email}</UserDetail> : null}
                  {user.phoneNumber ? <UserDetail icon={<PhoneIcon />}>{user.phoneNumber}</UserDetail> : null}
                </div>
              </>
            ) : null}
            {company ? (
              <>
                <hr className="h-px w-full border-0 bg-grey-lighter" />
                <div className="flex flex-col gap-1.5">
                  <Capture1 className="text-black">{t("page.tickets.create.reporter.company")}</Capture1>
                  <UserDetail icon={<BriefcaseIcon />}>{company}</UserDetail>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

function UserDetail({ icon, children }: { icon: React.ReactElement<IconProps>; children: string }) {
  return (
    <Capture2 className="flex items-center gap-2">
      {React.cloneElement(icon, { size: 16 })}
      {children}
    </Capture2>
  );
}
