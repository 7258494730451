import type { AudienceTexts, AudienceType } from "components/Audience/Audience";
import { Audience } from "components/Audience/Audience";
import type { FieldPath, FieldPathValue, FieldValues, RegisterOptions } from "react-hook-form";
import { useController } from "react-hook-form";

import { FormErrorWrapper } from "./FormErrorWrapper";

interface Props<TFormValues extends FieldValues, TPath extends FieldPath<TFormValues> = FieldPath<TFormValues>> {
  name: TPath;
  rules?: RegisterOptions;
  texts: Record<AudienceType, AudienceTexts>;
  defaultValue?: FieldPathValue<TFormValues, TPath>;
  className?: string;
  disabled?: boolean;
}

export function FormAudience<
  TFormValues extends FieldValues,
  TPath extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>({ name, defaultValue, ...props }: Props<TFormValues, TPath>): React.ReactNode {
  const {
    field: { ref, ...field },
  } = useController<TFormValues, TPath>({
    name,
    rules: { ...props.rules, validate: { ...props.rules?.validate } },
    defaultValue,
  });

  return (
    <FormErrorWrapper<TFormValues> name={name}>
      <Audience {...field} {...props} value={field.value as any} />
    </FormErrorWrapper>
  );
}
