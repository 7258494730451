import type { RemsStatusDto } from "api/types";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import remsLogoBig from "../assets/rems-logo-big.svg";
import remsLogoSmall from "../assets/rems-logo-small.svg";

export function RemsStatus({
  bigLogo,
  rems,
  isLoading,
}: {
  bigLogo?: boolean;
  rems?: RemsStatusDto;
  isLoading?: boolean;
}): React.ReactNode {
  const { t } = useTranslation();

  if (!rems) {
    return null;
  }

  return (
    <div className={twJoin("flex items-center font-semibold text-grey-dark", bigLogo ? "flex-col gap-4" : "gap-2")}>
      {bigLogo ? (
        <img src={remsLogoBig} width="153.4px" height="35px" alt="Aareon" />
      ) : (
        <img src={remsLogoSmall} width="22px" height="21.9px" alt="Aareon" />
      )}
      <div className="flex items-center gap-2">
        <span className={bigLogo ? undefined : "inline-block max-w-xs truncate"}>
          {isLoading || rems.state === "pending" ? (
            <>
              {t("page.tickets.rems.state")} <span>{t("page.tickets.rems.state.pending")}</span>
            </>
          ) : rems.state === "synchronized" ? (
            <>
              {t("page.tickets.rems.synced")} <span className="text-green-darker">{rems.externalId || "-"}</span>
            </>
          ) : rems.state === "ready" ? (
            <>
              {t("page.tickets.rems.state")}{" "}
              <span className="text-green-darker">{t("page.tickets.rems.state.ready")}</span>
            </>
          ) : rems.state === "errorNoUserFound" ? (
            <>
              {t("page.tickets.rems.state")}{" "}
              <span className="text-red-dark">{t("page.tickets.rems.state.user-not-found")}</span>
            </>
          ) : rems.state === "errorNoContracts" ? (
            <>
              {t("page.tickets.rems.state")}{" "}
              <span className="text-red-dark">{t("page.tickets.rems.state.user-no-contracts")}</span>
            </>
          ) : rems.state === "errorGeneric" ? (
            <>
              {t("page.tickets.rems.state")} <span className="text-red-dark">{t("page.tickets.rems.state.error")}</span>
            </>
          ) : rems.state === "notStarted" ? (
            <>
              {t("page.tickets.rems.state")} <span>{t("page.tickets.rems.state.not-started")}</span>
            </>
          ) : null}
        </span>
      </div>
    </div>
  );
}
