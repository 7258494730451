import { Overline1 } from "components/Text/Text";
import { AnimatePresence, motion } from "framer-motion";
import { useKey } from "hooks/useKey";
import { useProjectContext } from "providers/ProjectContext";
import { useRef } from "react";
import { X as XIcon } from "react-feather";
import { useTranslation } from "react-i18next";

import { ProjectList } from "./ProjectList";
import { useRecentProjects } from "./useRecentProjects";

const RECENT_PROJECT_COUNT = 3;

interface Props {
  isOpen: boolean;
  showCurrentProject?: boolean;
  showRecentProjects?: boolean;
  showPortfolio?: boolean;
  onClose: () => void;
  isDisabled?: boolean;
}

export function ProjectSwitcher({
  isOpen,
  showCurrentProject = true,
  showRecentProjects = true,
  showPortfolio,
  onClose,
  isDisabled,
}: Props): React.ReactNode {
  const rootRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { changeProject, projectId } = useProjectContext();

  const { recentProjects, isLoading, projects } = useRecentProjects(
    projectId,
    showCurrentProject,
    RECENT_PROJECT_COUNT,
  );

  useKey("Escape", onClose, isOpen);

  function goToProject(projectId: string) {
    changeProject(projectId, true);
    onClose();
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="relative -top-5 grid max-h-screen-minus-24 w-full grid-rows-[min-content,minmax(0,1fr)] gap-4 rounded-b-xl border border-t-0 border-grey-lighter bg-white px-6 pt-11 text-grey-darker shadow-lg"
          ref={rootRef}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
        >
          <div className="flex items-center justify-between">
            <Overline1 className="uppercase leading-none text-grey">{t("component.project-switcher.title")}</Overline1>
            <button
              className="mt-0.5 rounded p-0.5 text-grey focus-within:bg-grey-light focus-within:text-white hover:bg-grey-light hover:text-white focus:outline-none"
              aria-label={t("common.action.cancel")}
              onClick={onClose}
              disabled={isDisabled}
            >
              <XIcon size={24} />
            </button>
          </div>
          {(showCurrentProject || projects.some((x) => x.id !== projectId)) && (
            <ProjectList
              projects={projects}
              recentProjects={recentProjects}
              currentProjectId={projectId}
              showCurrentProject={showCurrentProject}
              showRecentProjects={showRecentProjects}
              showPortfolio={showPortfolio}
              isLoading={isLoading}
              onSelectProject={goToProject}
              onClose={onClose}
            />
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
