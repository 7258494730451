import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { CommentDto } from "api/types";
import { BorderlessButton } from "components/Button/Button";
import { CommentFieldWithAvatar } from "components/CommentField/CommentField";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { formatDate, FormattedDate } from "components/FormattedDate/FormattedDate";
import { formatDistance } from "components/FormattedDistance/FormattedDistance";
import { Gallery } from "components/Gallery/Gallery";
import { LoadingIcon } from "components/Icons/Icons";
import type { FormImage } from "components/ImageInput/useImageInput";
import { useImageInput } from "components/ImageInput/useImageInput";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { Capture2, Subtitle2 } from "components/Text/Text";
import { Tooltip } from "components/Tooltip/Tooltip";
import { UserAvatarLink } from "components/UserAvatarLink/UserAvatarLink";
import { UserDeletedTag } from "components/UserDeletedTag/UserDeletedTag";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import { motion } from "framer-motion";
import { DEFAULT_MAXIMUM_FILE_SIZE_IN_MEGA_BYTES, megaBytesToBytes } from "helpers/file-size";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useEffect, useState } from "react";
import { CornerDownRight, ThumbsUp } from "react-feather";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

interface Props {
  messageId: string;
  comment: CommentDto;
  isGreyedOut: boolean;
  onEdit: (comment: CommentDto) => void;
  onDelete: (comment: CommentDto) => void;
  createComment: ({
    comment,
    files,
    failureMessage,
    parentId,
  }: {
    comment: string;
    files: FormImage[];
    parentId?: string;
    failureMessage: string;
  }) => Promise<CommentDto>;
  editComment: ({
    comment,
    commentId,
    files,
    failureMessage,
  }: {
    comment: string;
    commentId: string;
    files: FormImage[];
    failureMessage: string;
  }) => void;
  onLike: (commentId: string) => void;
  onUnlike: (commentId: string) => void;
  onViewCommentLikes: (commentId: string) => void;
}

export function Comment({
  messageId,
  comment,
  isGreyedOut,
  createComment,
  onEdit,
  editComment,
  onDelete,
  onLike,
  onUnlike,
  onViewCommentLikes,
}: Props): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const api = useApi();
  const showFlashToast = useFlashToast();
  const [hideAllReplies, hideAllRepliesHandler] = useBool(true);
  const [showReplyField, showReplyFieldHandler] = useBool(false);
  const sessionUser = useSessionUser();
  const [editingReply, setEditingReply] = useState<string | undefined>(undefined);
  const [replyImages, setReplyImages] = useState<FormImage[]>([]);
  const {
    addImages: addReplyImages,
    removeImage: removeReplyImage,
    removeImages: removeReplyImages,
  } = useImageInput({ onChange: setReplyImages });
  const { getValues, setValue, watch, register } = useForm<{ replyValue: string }>();

  useEffect(() => {
    register("replyValue");
  }, [register]);

  const replyValue = watch("replyValue");

  async function onReply() {
    const { replyValue } = getValues();

    if (editingReply) {
      editComment({
        comment: replyValue,
        commentId: editingReply,
        files: replyImages,
        failureMessage: t("component.community-post.comments.edit-error"),
      });
      setEditingReply(undefined);
    } else {
      await createComment({
        parentId: comment.id,
        comment: replyValue,
        files: replyImages,
        failureMessage: t("component.community-post.comments.error"),
      });
    }

    removeReplyImages();
    setValue("replyValue", "");
    showReplyFieldHandler.setFalse();
  }

  const onEditReply = useCallback(
    (comment: CommentDto) => {
      setValue("replyValue", comment.content || "");
      if (comment.image) {
        setReplyImages([comment.image]);
      }
      setEditingReply(comment.id);
      showReplyFieldHandler.setTrue();
    },
    [setValue, setReplyImages, setEditingReply, showReplyFieldHandler],
  );

  const onCancelReplyEdit = useCallback(() => {
    setValue("replyValue", "");
    removeReplyImages();
    setEditingReply(undefined);
    showReplyFieldHandler.setFalse();
  }, [removeReplyImages, setValue, setEditingReply, showReplyFieldHandler]);

  const onChangeReplyContent = useCallback((value: string) => setValue("replyValue", value), [setValue]);

  const onRemoveReplyImage = useCallback(
    (imageToBeRemoved: FormImage) => {
      removeReplyImage(imageToBeRemoved);
    },
    [removeReplyImage],
  );

  const {
    data: commentDetails,
    isLoading: isLoadingReplies,
    refetch: refetchCommentDetails,
  } = useQuery({
    queryKey: QUERY_KEYS.MESSAGE_COMMENT_DETAILS(projectId, messageId, comment.id),
    queryFn: () => api.getMessagesCommentsDetailsV1(messageId, comment.id),
    select: commonAPIDataSelector,
    enabled: false,
  });

  function handleLike(comment: CommentDto) {
    if (comment.hasLiked) {
      onUnlike(comment.id);
    } else {
      onLike(comment.id);
    }
  }

  return (
    <div className="flex flex-col">
      <CommentInternal
        messageId={messageId}
        comment={comment}
        showReplyButton={comment.canReply}
        onReply={showReplyFieldHandler.toggle}
        isGreyedOut={isGreyedOut}
        onEdit={onEdit}
        onDelete={onDelete}
        onClickLike={() => handleLike(comment)}
        onViewCommentLikes={onViewCommentLikes}
      />
      <div className="ml-10" data-testid="comment-replies-section">
        {hideAllReplies && comment.latestReply ? (
          <>
            {comment.totalRepliesCount > 1 && (
              <BorderlessButton
                className="mb-2 self-start text-aop-basic-blue no-underline"
                onClick={async () => {
                  hideAllRepliesHandler.setFalse();

                  if (!commentDetails && !isLoadingReplies) {
                    try {
                      await refetchCommentDetails();
                    } catch (error) {
                      showFlashToast({
                        type: "error",
                        title: t("component.community-post.comments.reply.show-error"),
                      });
                    }
                  }
                }}
              >
                <span>
                  {t("component.community-post.comments.replies.show-replies", {
                    count: comment.totalRepliesCount - 1,
                  })}
                </span>
              </BorderlessButton>
            )}
            <CommentInternal
              messageId={messageId}
              comment={comment.latestReply}
              isGreyedOut={isGreyedOut}
              onEdit={onEditReply}
              onDelete={onDelete}
              onClickLike={() => handleLike(comment.latestReply!)}
              onViewCommentLikes={onViewCommentLikes}
            />
          </>
        ) : (
          <>
            {isLoadingReplies && (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.4 }}>
                <LoadingIcon className="mx-auto mb-2 w-6" />
              </motion.div>
            )}
            {commentDetails &&
              (commentDetails.replies || [commentDetails.latestReply]).map((reply) => (
                <CommentInternal
                  key={reply.id}
                  messageId={messageId}
                  comment={reply}
                  isGreyedOut={isGreyedOut}
                  onEdit={onEditReply}
                  onDelete={onDelete}
                  onClickLike={() => handleLike(reply)}
                  onViewCommentLikes={onViewCommentLikes}
                />
              ))}
            {!hideAllReplies && (
              <BorderlessButton
                className="mb-4 self-start text-aop-basic-blue no-underline"
                onClick={() => hideAllRepliesHandler.setTrue()}
              >
                <span>
                  {t("component.community-post.comments.replies.hide-replies", {
                    count: comment.totalRepliesCount - 1,
                  })}
                </span>
              </BorderlessButton>
            )}
          </>
        )}
        {showReplyField && (
          <form className="mb-6">
            <div className="flex items-end">
              <CommentFieldWithAvatar
                isReply
                sendTooltip={
                  editingReply
                    ? t("component.community-post.comments.edit.tooltip")
                    : t("component.community-post.comments.reply.tooltip")
                }
                placeholder={
                  editingReply
                    ? t("component.community-post.comments.edit.placeholder")
                    : t("component.community-post.comments.reply.placeholder")
                }
                user={sessionUser}
                value={replyValue}
                onChange={onChangeReplyContent}
                images={replyImages}
                onSubmit={onReply}
                isEdit={editingReply !== undefined}
                onCancel={onCancelReplyEdit}
                inlineCancel
                allowsImages
                onRemoveImage={onRemoveReplyImage}
                onAddImages={(images) => {
                  const maxMegaBytes = DEFAULT_MAXIMUM_FILE_SIZE_IN_MEGA_BYTES;
                  const maxBytes = megaBytesToBytes(maxMegaBytes);
                  if (Array.from(images).some((x) => x.size > maxBytes)) {
                    showFlashToast({
                      title: t("components.form.error.file-too-big", {
                        sizeInMegaBytes: maxMegaBytes,
                      }),
                      type: "error",
                    });

                    return;
                  }

                  addReplyImages(images);
                }}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

interface InternalProps {
  messageId: string;
  comment: CommentDto;
  showReplyButton?: boolean;
  onReply?: () => void;
  isGreyedOut: boolean;
  onEdit: (comment: CommentDto) => void;
  onDelete: (comment: CommentDto) => void;
  onClickLike: () => void;
  onViewCommentLikes: (commentId: string) => void;
}

function CommentInternal({
  messageId,
  comment,
  showReplyButton,
  onReply,
  onEdit,
  onDelete,
  onClickLike,
  onViewCommentLikes,
}: InternalProps): React.ReactNode {
  const projectId = useProjectId();
  const { i18n, t } = useTranslation();
  const api = useApi();
  const showFlashToast = useFlashToast();
  const sessionUser = useSessionUser();

  const [hideTranslation, hideTranslationHandler] = useBool(true);
  const translation = useQuery({
    queryKey: QUERY_KEYS.MESSAGES_COMMENT_TRANSLATION(projectId, messageId, comment.id, sessionUser.language.id),
    queryFn: () => api.getMessagesCommentsTranslationsDetailsV2(messageId, comment.id, sessionUser.language.id),
    retry: false,
    enabled: false,
  });

  const hasTranslation = translation.data && !hideTranslation;
  const translateButton =
    comment.content && comment.languageIsoCode !== sessionUser.language.id && !comment.deletedAt ? (
      <BorderlessButton
        className="self-start pl-0 font-normal text-aop-basic-blue no-underline"
        isLoading={translation.isLoading}
        onClick={async () => {
          hideTranslationHandler.toggle();

          if (!translation.data) {
            try {
              await translation.refetch();
            } catch (error) {
              showFlashToast({ type: "error", title: t("component.community-post.comments.translate-error") });
            }
          }
        }}
      >
        <span>
          {hasTranslation
            ? t("component.community-post.comments.translate.original")
            : t("component.community-post.comments.translate")}
        </span>
      </BorderlessButton>
    ) : null;

  return (
    <div
      className={twJoin("flex rounded-3px pb-4 pt-2", comment.deletedAt && "text-grey-light first:pt-0")}
      data-testid="comment-root"
    >
      <div className={twJoin("mr-2 mt-2.5 size-8", comment.deletedAt ? "opacity-40" : undefined)}>
        <UserAvatarLink user={comment.author} hideDeletedIcon />
      </div>
      <div className="grid flex-1 grid-cols-1">
        <div className="rounded-lg bg-grey-lightest/50 p-2">
          <div className={twJoin("flex items-start", comment.deletedAt ? "text-grey-light" : undefined)}>
            <div className="flex flex-wrap items-center gap-1">
              <div className="flex items-center gap-1">
                <UserNameLink user={comment.author}>
                  <Subtitle2 className={twJoin("mr-2 truncate", comment.deletedAt ? "text-grey-light" : "text-black")}>
                    {comment.author.fullName}
                  </Subtitle2>
                </UserNameLink>

                {!!comment.author.deletedAt && <UserDeletedTag />}
              </div>

              <Capture2 className="text-grey">{formatDistance(i18n, { start: new Date(comment.postedAt) })}</Capture2>

              {comment.deletedAt ? (
                <Tooltip tooltip={<FormattedDate date={comment.deletedAt} format="datetime" />}>
                  <Capture2
                    className={
                      "relative ml-1 cursor-default italic text-grey after:absolute after:inset-x-0 after:bottom-0 after:hidden after:h-px after:w-full after:bg-grey after:content-[''] hover:after:block"
                    }
                  >
                    (
                    {t("component.community-post.comments.deleted-at", {
                      time: formatDate(i18n, "datetime", comment.deletedAt),
                    })}
                    )
                  </Capture2>
                </Tooltip>
              ) : comment.updatedAt ? (
                <Tooltip tooltip={<FormattedDate date={comment.updatedAt} format="datetime" />}>
                  <Capture2
                    className={
                      "relative ml-1 cursor-default italic text-grey after:absolute after:inset-x-0 after:bottom-0 after:hidden after:h-px after:w-full after:bg-grey after:content-[''] hover:after:block"
                    }
                  >
                    (
                    {t("component.community-post.comments.changed-at", {
                      time: formatDate(i18n, "datetime", comment.updatedAt),
                    })}
                    )
                  </Capture2>
                </Tooltip>
              ) : null}
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <p
              className={twJoin("flex-1", comment.deletedAt && comment.content ? "line-through" : undefined)}
              data-testid="comment-content"
            >
              {comment.deletedAt && !comment.content ? (
                <span>{t("component.community-post.comments.deleted")}</span>
              ) : (
                <LinkFormatter>{hasTranslation ? translation.data.data.content : comment.content}</LinkFormatter>
              )}
            </p>
            {translateButton}
          </div>

          {comment.image && (
            <div className="max-w-full pt-2">
              <Gallery images={[comment.image]} isDeleted={!!comment.deletedAt} />
            </div>
          )}
        </div>
        <div className="-ml-1 flex gap-2 pt-2 text-grey">
          <div className="flex items-center">
            {comment.canLike || comment.totalLikesCount > 0 ? (
              <BorderlessButton
                data-testid="like-comment"
                onClick={onClickLike}
                title={t("common.action.like")}
                disabled={!comment.canLike}
              >
                <ThumbsUp
                  className={
                    comment.hasLiked ? (comment.deletedAt ? "fill-grey-lightest" : "fill-grey-darker") : undefined
                  }
                  size={14}
                />
              </BorderlessButton>
            ) : null}
            {comment.totalLikesCount > 0 && (
              <BorderlessButton data-testid="like-list" onClick={() => onViewCommentLikes(comment.id)}>
                {t("component.community-post.comment.likes.total", { count: comment.totalLikesCount })}
              </BorderlessButton>
            )}
          </div>
          {showReplyButton && (
            <BorderlessButton
              title={t("component.community-post.comments.reply")}
              data-testid="reply-comment"
              onClick={onReply}
            >
              <span className="flex items-center gap-1">
                <CornerDownRight size={16} />
                {comment.totalRepliesCount ? <Capture2>{comment.totalRepliesCount}</Capture2> : null}
              </span>
            </BorderlessButton>
          )}
          {comment.canEdit && (
            <BorderlessButton data-testid="edit-comment" onClick={() => onEdit(comment)}>
              {t("common.action.edit")}
            </BorderlessButton>
          )}
          {comment.canDelete && (
            <BorderlessButton data-testid="delete-comment" onClick={() => onDelete(comment)}>
              {t("common.action.delete")}
            </BorderlessButton>
          )}
        </div>
      </div>
    </div>
  );
}
