import { ErrorPage } from "components/Error/ErrorPage";
import { RequireAuth } from "components/Routing/RequireAuth";
import type { RouteObject } from "react-router-dom";

import { CreateOrEditBuildingPage } from "./pages/CreateOrEdit";
import { BuildingsListPage } from "./pages/List";
import { buildingRoutes } from "./routes";

export const buildingsRoute = [
  {
    path: buildingRoutes.list,
    element: (
      <RequireAuth permission={(x) => x.isAdmin && x.addressManagement.canListAddresses}>
        <BuildingsListPage />
      </RequireAuth>
    ),
  },
  {
    path: buildingRoutes.new,
    element: (
      <RequireAuth permission={(x) => x.addressManagement.canManageBuildings}>
        <CreateOrEditBuildingPage />
      </RequireAuth>
    ),
  },
  {
    path: buildingRoutes.edit,
    element: (
      <RequireAuth permission={(x) => x.addressManagement.canManageBuildings}>
        <CreateOrEditBuildingPage />
      </RequireAuth>
    ),
  },
  {
    path: "*",
    element: <ErrorPage status={404} />,
  },
] satisfies RouteObject[];
