import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { ConstraintListItemDto } from "api/types";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { MultiSelect } from "components/MultiSelect/MultiSelect";
import { Subtitle2 } from "components/Text/Text";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { groupBy } from "lodash-es";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

export interface AudienceProps {
  value?: ConstraintListItemDto[];
  onChange: (items: ConstraintListItemDto[]) => void;
  texts: Record<AudienceType, AudienceTexts>;
  className?: string;
  disabled?: boolean;
}

const audienceKeys: AudienceType[] = ["apartmentType", "building", "floor", "company"];

export function Audience(props: AudienceProps): React.ReactNode {
  const projectId = useProjectId();
  const { onChange, disabled } = props;
  const api = useApi();
  const { data: constraints, isFetching: isConstraintsFetching } = useQuery({
    queryKey: QUERY_KEYS.CONSTRAINTS(projectId),
    queryFn: () => api.getConstraintsV1(),
    select: commonAPIDataSelector,
  });
  const allAudience = useMemo(
    () => groupBy(constraints?.items, (constraint) => constraint.type) as AudienceDictionary,
    [constraints?.items],
  );
  const [state, setState] = useState<AudienceDictionary>({
    apartmentType: [],
    building: [],
    floor: [],
    company: [],
  });

  useEffect(() => {
    if (props.value) {
      const data = groupBy(props.value, (x) => x.type);

      setState({
        apartmentType: data.apartmentType ?? [],
        building: data.building ?? [],
        floor: data.floor ?? [],
        company: data.company ?? [],
      });
    }
  }, [props.value]);

  const createOnChangeHandler = useCallback(
    (type: AudienceType) => (items: ConstraintListItemDto[] | null) => {
      setState((prevValue) => {
        const newValue = { ...prevValue, [type]: items };
        onChange(Object.values(newValue).flat());

        return newValue;
      });
    },
    [onChange],
  );
  const staticTexts = useGetTranslations();

  if (isConstraintsFetching) {
    return <FullSizeLoader />;
  }

  return (
    <div data-testid="audience-root" className={props.className}>
      {audienceKeys.map((audienceKey) =>
        audienceKey in allAudience ? (
          <Item
            key={audienceKey}
            audienceKey={audienceKey}
            title={staticTexts[audienceKey].title}
            description={props.texts[audienceKey].description}
            selected={state[audienceKey]}
            placeholder={props.texts[audienceKey].placeholder || staticTexts[audienceKey].defaultPlaceholder}
            data={allAudience[audienceKey]}
            onChangeCreator={createOnChangeHandler}
            disabled={disabled}
          />
        ) : null,
      )}
    </div>
  );
}

export type AudienceType = ConstraintListItemDto["type"];
type AudienceDictionary = Record<AudienceType, ConstraintListItemDto[]>;
export interface AudienceTexts {
  description: string;
  placeholder?: string;
}

function Item(props: {
  audienceKey: AudienceType;
  title: string;
  description: string;
  selected: ConstraintListItemDto[];
  placeholder: string;
  data: ConstraintListItemDto[];
  disabled?: boolean;
  onChangeCreator: (type: AudienceType) => (items: ConstraintListItemDto[] | null) => void;
}) {
  const { onChangeCreator, audienceKey, disabled } = props;
  const onChangeHandler = useMemo(() => onChangeCreator(audienceKey), [audienceKey, onChangeCreator]);

  return (
    <div className="mb-6" data-testid="audience-item">
      <Subtitle2 className={twJoin("block", disabled ? "text-grey-light" : undefined)}>{props.title}</Subtitle2>
      <p className={disabled ? "text-grey-lighter" : "text-grey-darker"}>{props.description}</p>
      <MultiSelect<ConstraintListItemDto>
        selected={props.selected}
        disabled={disabled}
        placeholder={props.placeholder}
        items={props.data}
        keySelector={({ id }) => id}
        renderOption={({ key }) => key}
        onChange={onChangeHandler}
      />
    </div>
  );
}

function useGetTranslations(): Record<AudienceType, { title: string; defaultPlaceholder: string }> {
  const { t } = useTranslation();

  return {
    apartmentType: {
      title: t("model.constraint.address"),
      defaultPlaceholder: t("model.constraint.address.placeholder"),
    },
    company: { title: t("model.constraint.company"), defaultPlaceholder: t("model.constraint.company.placeholder") },
    floor: { title: t("model.constraint.floor"), defaultPlaceholder: t("model.constraint.floor.placeholder") },
    building: { title: t("model.constraint.building"), defaultPlaceholder: t("model.constraint.building.placeholder") },
  };
}
