import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { LanguageDto, TranslationRequest, TranslationsDto } from "api/types";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { QUERY_KEYS } from "query-keys";

export type FormTranslations = Record<LanguageDto["id"], string>;

export function createFormTranslations(
  languages: LanguageDto[],
  translations: TranslationsDto[] | undefined,
): FormTranslations {
  return Object.fromEntries(
    languages.map((lng) => [lng.id, translations?.find((nameLng) => lng.id === nameLng.languageId)?.value ?? ""]),
  ) as FormTranslations;
}

export function toTranslationsRequest(formTranslations: FormTranslations): TranslationRequest[] {
  return Object.entries(formTranslations).map<TranslationRequest>(([languageId, value]) => ({
    languageId: languageId as LanguageDto["id"],
    value,
  }));
}

/*
 * hooks
 * */
export function useCurrentTranslation(
  translations: Partial<FormTranslations> | undefined,
): FormTranslations[keyof FormTranslations] {
  const {
    language: { id: languageId },
  } = useSessionUser();

  return translations?.[languageId] ?? "";
}

export function useLanguages(): {
  data: LanguageDto[] | undefined;
  isFetching: boolean;
  error: unknown;
} {
  const projectId = useProjectId();
  const api = useApi();

  const { data, isFetching, error } = useQuery({
    queryKey: QUERY_KEYS.LANGUAGES(projectId),
    queryFn: () => api.getLanguagesV2(),
    select: commonAPIDataSelector,
    notifyOnChangeProps: ["data", "isFetching", "error"],
    staleTime: Infinity,
  });

  return { data, isFetching, error };
}
