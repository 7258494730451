import { ChevronIcon } from "components/Icons/Icons";
import { motion } from "framer-motion";
import type React from "react";
import { twJoin } from "tailwind-merge";

export function NavigationLinkContent({
  isWide,
  children,
  icon,
  isUnread,
  unreadCount,
  isBig,
  isFaded,
  chevron,
}: {
  isWide: boolean;
  icon: React.ReactNode;
  isUnread: boolean;
  unreadCount?: number;
  children: string;
  isBig?: boolean;
  isFaded?: boolean;
  chevron?: "right" | "down";
}): React.ReactNode {
  return (
    <div className="flex w-full items-center justify-between gap-2">
      <span
        className={twJoin(
          "relative flex w-4",
          isFaded
            ? "group-hover:text-black group-focus-visible:text-black group-has-[:focus-visible]:text-black md:text-grey"
            : "",
        )}
      >
        {icon}
        {isUnread && <span className="absolute right-0 size-1.5 rounded-full bg-red shadow-lg" />}
      </span>
      <motion.span
        className={twJoin(
          "mr-auto block flex-1 truncate text-left md:max-w-[190px]",
          isWide ? "" : "hidden",
          isBig ? "text-xl font-semibold" : "text-subtitle2",
        )}
      >
        {children}
      </motion.span>

      {isWide && unreadCount != null && unreadCount > 0 && (
        <span className="min-w-6 rounded-full bg-grey-lightest p-1 px-1.5 text-center text-xs font-normal group-[[aria-current]]:bg-aop-basic-blue/10 group-hocus:bg-aop-basic-blue/10">
          <span>{unreadCount}</span>
        </span>
      )}

      {chevron && <ChevronIcon orientation={chevron} className="mr-0.5 w-3.5" />}
    </div>
  );
}
