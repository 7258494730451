import type { DocumentsItemDto } from "api/types";
import { FileText as DocumentIcon, Folder as FolderIcon, Image as ImageIcon, Link as LinkIcon } from "react-feather";

export function DocumentsFileIcon({ type }: { type: DocumentsItemDto["type"] }): React.ReactNode {
  switch (type) {
    case "folder":
      return <FolderIcon className="text-yellow-dark" size={16} />;
    case "document":
      return <DocumentIcon className="text-blue-darker" size={16} />;
    case "image":
      return <ImageIcon className="text-green-darker" size={16} />;
    case "link":
      return <LinkIcon className="text-grey-darker" size={16} />;
    default:
      console.warn(`File type ${type} unknown`);

      return <DocumentIcon className="text-blue-darker" size={16} />;
  }
}
