import type { ImageDto } from "api/types";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Gallery } from "components/Gallery/Gallery";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { Capture2, Overline2 } from "components/Text/Text";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { Check } from "react-feather";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

interface ChatMessageProps {
  isOwnMessage: boolean;
  hasPrevMessage: boolean;
  isUnread: boolean;
  userAvatar?: ImageDto | undefined;
  content?: string;
  image?: ImageDto;
  sentAt: string;
}

export function ChatMessage({
  isOwnMessage,
  hasPrevMessage,
  isUnread,
  userAvatar,
  content,
  image,
  sentAt,
}: ChatMessageProps): React.ReactNode {
  const { i18n } = useTranslation();

  return (
    <div className={twJoin("flex gap-2", isOwnMessage ? "self-end" : "self-start")} data-testid="chat-message">
      <div className={twJoin("size-9 self-end", isOwnMessage && "order-last", hasPrevMessage && "invisible")}>
        <UserAvatar img={userAvatar} />
      </div>
      <div
        className={twJoin(
          "sentry-block relative rounded-lg p-3",
          isOwnMessage ? "bg-green-lightest" : "bg-grey-lightest",
        )}
      >
        <div className="flex max-w-[200px] flex-col gap-2 lg:max-w-md">
          {content && (
            <Capture2 className="self-start">
              <LinkFormatter>{content}</LinkFormatter>
            </Capture2>
          )}
          {image && (
            <div className="max-w-full self-start">
              <Gallery images={[image]} />
            </div>
          )}
          <div className="flex items-center gap-2 self-end">
            <Overline2 className="self-end text-grey-dark">{formatDate(i18n, "time", sentAt)}</Overline2>
            {!isUnread && isOwnMessage && (
              <div className="flex text-aop-basic-blue">
                <Check className="-mr-2.5" size={16} />
                <Check size={16} />
              </div>
            )}
          </div>
        </div>
        <div
          className={twJoin(
            "absolute -bottom-1 h-3 w-2 -translate-x-1/2 rotate-45",
            isOwnMessage ? "right-3 bg-green-lightest" : "left-6 bg-grey-lightest",
            hasPrevMessage && "hidden",
          )}
        ></div>
      </div>
    </div>
  );
}
