import { ErrorPage } from "components/Error/ErrorPage";
import { ReservationDetailsPage } from "modules/reservations/pages/ReservationDetails";
import type { RouteObject } from "react-router-dom";

import { CalendarPage } from "./pages/Calendar";
import { calendarRoutes } from "./routes";

export const calendarRoute = [
  {
    path: calendarRoutes.list,
    element: <CalendarPage />,
  },
  {
    path: calendarRoutes.reservationDetails,
    element: <ReservationDetailsPage />,
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
