import { ErrorPage } from "components/Error/ErrorPage";
import { RequireAuth } from "components/Routing/RequireAuth";
import type { RouteObject } from "react-router-dom";

import { CreateOrEditCompanyPage } from "./pages/CreateOrEdit";
import { CompanyListPage } from "./pages/List";
import { companyRoutes } from "./routes";

export const companyRoute = [
  {
    path: companyRoutes.list,
    element: (
      <RequireAuth permission={(x) => x.addressManagement.canManageCompanies}>
        <CompanyListPage />
      </RequireAuth>
    ),
  },
  {
    path: companyRoutes.new,
    element: (
      <RequireAuth permission={(x) => x.addressManagement.canManageCompanies}>
        <CreateOrEditCompanyPage />
      </RequireAuth>
    ),
  },
  {
    path: companyRoutes.edit,
    element: (
      <RequireAuth permission={(x) => x.addressManagement.canManageCompanies}>
        <CreateOrEditCompanyPage />
      </RequireAuth>
    ),
  },
  {
    path: "*",
    element: <ErrorPage status={404} />,
  },
] satisfies RouteObject[];
