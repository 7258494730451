import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import bellIcon from "assets/icons/bell-01.svg";
import chevronDownIcon from "assets/icons/chevron-down.svg";
import chatsIcon from "assets/icons/message-circle-01.svg";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { ProjectSwitcher } from "components/ProjectSwitcher/ProjectSwitcher";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { AnimatePresence, motion } from "framer-motion";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUserOptional } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { memo, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

interface Props {
  isInProject: boolean;
  onToggleMenu: () => void;
}

export const MobileMenuBar = memo(MobileMenuBarContent);

function MobileMenuBarContent({ isInProject, onToggleMenu }: Props): React.ReactNode {
  const { t } = useTranslation();
  const slug = useSlug({ optional: true });
  const [sessionUser, { isPlaceholderData }] = useSessionUserOptional({ keepPreviousData: true });

  const isAtTopOfPage = useIsAtTopOfMainContent();
  const [projectSwitcher, projectSwitcherHandlers] = useBool(false);

  const connectedProjects = useConnectedProjects();

  const projectId = useProjectId();
  const api = useApi();
  const { data: chatsStatus } = useQuery({
    queryKey: QUERY_KEYS.CHATS_STATUS(projectId),
    queryFn: () => api.getChatsStatusV1(),
    select: commonAPIDataSelector,
    staleTime: 5000,
    enabled: isInProject,
  });

  const { data: adminNotificationStats } = useQuery({
    queryKey: QUERY_KEYS.ADMIN_NOTIFICATION_STATUS(projectId),
    queryFn: () => api.getAdminNotificationsStatusV1(),
    select: commonAPIDataSelector,
    staleTime: 30 * 1000,
    refetchInterval: 60 * 1000,
    enabled: isInProject && sessionUser?.isAdmin,
  });

  const canSwitchProjects = connectedProjects.data && connectedProjects.data?.length > 1;
  const canSeePortfolio = connectedProjects.data && connectedProjects.data.some((x) => x.userRole !== "resident");

  return (
    <div
      className={twJoin(
        "grid h-12 items-center border-grey-lightest px-1 md:hidden",
        "grid-cols-[minmax(0,1fr),minmax(0,3fr),minmax(0,1fr)]",
        isAtTopOfPage ? "border-b-0" : "border-b",
      )}
    >
      <div className="flex items-center">
        <button className="flex size-12 items-center justify-center p-2" onClick={onToggleMenu}>
          <UserAvatar img={sessionUser?.avatar} />
        </button>
      </div>

      {sessionUser ? (
        isPlaceholderData ? (
          <div className="mx-auto inline-flex w-min">
            <LoadingIcon className="w-6" />
          </div>
        ) : isInProject ? (
          canSwitchProjects ? (
            <button
              className="mx-auto inline-flex h-12 w-min max-w-full items-center gap-2 px-3 text-aop-dark-blue hover:text-black"
              onClick={projectSwitcherHandlers.toggle}
            >
              <span className="truncate text-base font-bold">{sessionUser?.project.name}</span>
              <Icon name={chevronDownIcon} size={16} className="mt-0.5" />
            </button>
          ) : (
            <span className="truncate text-center text-base font-bold text-aop-dark-blue">
              {sessionUser?.project.name}
            </span>
          )
        ) : (
          <button
            className="mx-auto inline-flex h-12 w-min max-w-full items-center gap-2 px-3 text-aop-dark-blue hover:text-black"
            onClick={projectSwitcherHandlers.toggle}
          >
            <span className="truncate text-base">{t("page.portfolio.project-switcher")}</span>
            <Icon name={chevronDownIcon} size={16} className="mt-0.5" />
          </button>
        )
      ) : (
        // Need to render an element or the layout will break
        <span />
      )}

      {isInProject && slug && (
        <div className="flex justify-end">
          <Link to={routes.chats.list({ slug })} className="flex size-12 items-center justify-center p-1">
            <span className="relative flex w-4">
              <Icon name={chatsIcon} size={20} />
              {chatsStatus?.hasNewMessages && (
                <span className="absolute -right-0.5 size-1.5 rounded-full bg-red shadow-lg" />
              )}
            </span>
          </Link>
          {sessionUser?.isAdmin && (
            <Link
              to={routes.adminNotifications.list({ slug })}
              className="flex size-12 items-center justify-center p-1"
            >
              <span className="relative flex w-4">
                <Icon name={bellIcon} size={20} />
                {adminNotificationStats?.hasUnread && (
                  <span className="absolute -right-0.5 size-1.5 rounded-full bg-red shadow-lg" />
                )}
              </span>
            </Link>
          )}
        </div>
      )}

      {createPortal(
        <div
          className={twJoin("absolute inset-0 z-10", projectSwitcher ? "pointer-events-auto" : "pointer-events-none")}
        >
          <AnimatePresence>
            {projectSwitcher ? (
              <motion.button
                aria-hidden
                className="absolute inset-0 bg-grey-darkest/60"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={projectSwitcherHandlers.setFalse}
              />
            ) : null}
          </AnimatePresence>

          <div className="absolute inset-x-0 mx-auto max-w-lg px-2 xs:px-4">
            <ProjectSwitcher
              isOpen={projectSwitcher}
              onClose={projectSwitcherHandlers.setFalse}
              showRecentProjects
              showCurrentProject={!isInProject}
              showPortfolio={canSeePortfolio && isInProject}
            />
          </div>
        </div>,
        document.body,
      )}
    </div>
  );
}

function useIsAtTopOfMainContent() {
  const [isAtTopOfPage, setIsAtTopOfPage] = useState(() => {
    const element = getMainElement();

    return element ? element.scrollTop === 0 : true;
  });

  useEffect(() => {
    const element = getMainElement();
    if (!element) {
      return;
    }

    function handleScroll() {
      setIsAtTopOfPage(element!.scrollTop === 0);
    }

    element.addEventListener("scroll", handleScroll);

    return () => element.removeEventListener("scroll", handleScroll);
  }, []);

  return isAtTopOfPage;
}

function getMainElement() {
  return document.getElementById("main");
}
