import type { Configuration } from "@azure/msal-browser";
import { LogLevel } from "@azure/msal-browser";
import type { BaseAuthRequest } from "@azure/msal-common";
import { getFromConfig } from "helpers/config";
import React, { useContext } from "react";

export interface MsalConfigContextValue {
  b2cPolicies: B2cPoliciesConfig;
  config: Configuration;
  authRequest: Awaited<ReturnType<typeof buildAuthRequest>>;
}

interface B2cPoliciesConfig {
  names: B2cPolicyNames;
  authorities: B2cPolicyAuthorities;
  authorityDomain: string;
}

interface B2cPolicyNames {
  signIn: string;
  signUp: string;
}

interface B2cPolicyAuthorities {
  signIn: B2cPolicyAuthority;
  signUp: B2cPolicyAuthority;
}

interface B2cPolicyAuthority {
  authority: string;
}

export const MsalConfigContext = React.createContext<MsalConfigContextValue>(null!);

export function useMsalConfigContext(): MsalConfigContextValue {
  return useContext(MsalConfigContext);
}

export const MsalConfigContextProvider = MsalConfigContext.Provider;

export async function buildMsalConfigContext(): Promise<MsalConfigContextValue> {
  const policies = await buildPoliciesConfig();
  const config = await buildMsalConfig(policies);
  const authRequest = await buildAuthRequest();

  return {
    authRequest: authRequest,
    b2cPolicies: policies,
    config: config,
  };
}

async function buildPoliciesConfig() {
  const instance = await getFromConfig("azureAdB2cInstance");
  if (!instance) {
    throw new Error(`Config key "azureAdB2cInstance" not found`);
  }

  const domain = await getFromConfig("azureAdB2cDomain");
  if (!domain) {
    throw new Error(`Config key "azureAdB2cDomain" not found`);
  }

  const signInPolicyId = await getFromConfig("azureAdB2cDashboardSignInPolicyId");
  if (!signInPolicyId) {
    throw new Error(`Config key "azureAdB2cDashboardSignInPolicyId" not found`);
  }

  const signUpPolicyId = await getFromConfig("azureAdB2cPoliciesSignUpV2");
  if (!signUpPolicyId) {
    throw new Error(`Config key "azureAdB2cPoliciesSignUpV2" not found`);
  }

  const result: B2cPoliciesConfig = {
    names: {
      signIn: signInPolicyId,
      signUp: signUpPolicyId,
    },
    authorities: {
      signIn: {
        authority: `${instance}/${domain}/${signInPolicyId}`,
      },
      signUp: {
        authority: `${instance}/${domain}/${signUpPolicyId}`,
      },
    },
    authorityDomain: instance,
  };

  return result;
}

async function buildMsalConfig(b2cPolicyConfig: B2cPoliciesConfig) {
  const clientId = await getFromConfig("azureAdB2cNewDashboardClientId");
  if (!clientId) {
    throw new Error(`Config key "azureAdB2cNewDashboardClientId" not found`);
  }

  const result: Configuration = {
    auth: {
      clientId: clientId, // This is the ONLY mandatory field that you need to supply.
      authority: b2cPolicyConfig.authorities.signIn.authority, // Choose SUSI as your default authority.
      knownAuthorities: [b2cPolicyConfig.authorityDomain], // Mark your B2C tenant's domain as trusted.
      redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
      postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
      navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
      cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
          if (containsPii) {
            return;
          }

          switch (level) {
            case LogLevel.Error:
              console.error(message);

              return;
            case LogLevel.Info:
              console.debug(message);

              return;
            case LogLevel.Verbose:
              console.debug(message);

              return;
            case LogLevel.Warning:
              console.warn(message);

              return;
          }
        },
      },
    },
  };

  return result;
}

async function buildAuthRequest() {
  const scope = await getFromConfig("azureAdB2cCoreApiScope");
  if (!scope) {
    throw new Error(`Config key "azureAdB2cCoreApiScope" not found`);
  }

  return {
    scopes: [scope],
  } satisfies Partial<BaseAuthRequest>;
}
