import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { addDays, endOfDay, startOfDay } from "date-fns";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { Speedometer } from "modules/home/components/Speedometer";
import { QUERY_KEYS } from "query-keys";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export function SentimentModal({ isOpen }: { isOpen: boolean }): React.ReactNode {
  const { t } = useTranslation();
  const from = useMemo(() => startOfDay(addDays(new Date(), -14)).toISOString(), []);
  const to = useMemo(() => endOfDay(new Date()).toISOString(), []);

  const projectId = useProjectId();
  const api = useApi();
  const { data, isLoading, isPlaceholderData, error } = useQuery({
    queryKey: QUERY_KEYS.PROJECT_SUMMARY(projectId, from, to),
    queryFn: () => api.getProjectSummaryV1({ from, to }),
    select: commonAPIDataSelector,
    enabled: isOpen,
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  return (
    <div className="flex flex-col gap-4 p-4 md:max-w-[880px]">
      <h1 className="text-headline2 font-semibold leading-headline2">{t("page.message-feed.sentiment.modal.title")}</h1>
      <div className="relative flex flex-col flex-wrap items-center gap-4 md:flex-row">
        <div className="flex max-w-md flex-col gap-4">
          <p className="whitespace-pre-line break-words">
            {data?.communitySummary || (isLoading || !data ? "x".repeat(60) : "")}
          </p>
          <div className="flex flex-wrap gap-3 lowercase text-grey-dark">
            {data?.hashtags.map((x) => <span key={x}>{x.startsWith("#") ? x : "#" + x}</span>)}
          </div>
        </div>
        <div className="flex flex-col items-center px-8">
          <div className="w-56">
            <Speedometer score={data ? data.sentimentPercentage / 100 : 0} fastAnimation />
          </div>
          <div className="flex items-baseline text-[60px] font-semibold">
            <span className="mr-3 self-center text-[30px]">
              {getEmojiFromSentiment(data?.sentimentPercentage || 0)}
            </span>
            <span className="text-[60px] font-semibold">{data?.sentimentPercentage}</span>
            <span className="text-[24px] font-normal">/100</span>
          </div>
        </div>
        {isLoading || isPlaceholderData ? (
          <div className="absolute inset-0 bg-white">
            <FullSizeLoader withPadding />
          </div>
        ) : error ? (
          <div className="absolute inset-0 bg-white">
            <p>{t("page.message-feed.sentiment.modal.failed")}</p>
          </div>
        ) : !data ? (
          <div className="absolute inset-0 bg-white">
            <p>{t("page.message-feed.sentiment.modal.no-data")}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
}

function getEmojiFromSentiment(score: number) {
  if (score < 0 || score > 100) {
    throw new Error("Score must be between 0 and 100");
  }

  if (score >= 80) {
    return "😁"; // Very happy
  }

  if (score >= 60) {
    return "😊"; // Happy
  }

  if (score >= 40) {
    return "😐"; // Neutral
  }

  if (score >= 20) {
    return "😞"; // Sad
  }

  return "😢"; // Very sad
}
