import { useMutation } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { useImageResolver } from "api/hooks/useImageResolver";
import type { ImageDto } from "api/types";
import award from "assets/images/award.svg";
import bgPlaceholder from "assets/images/image-placeholder.png";
import { CardButton, StaticButton } from "components/Button/Button";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Capture2, Headline4 } from "components/Text/Text";
import { useSlug } from "hooks/useSlug";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

interface PromotedGroupProps {
  id: string;
  name: string;
  description: string;
  image?: ImageDto;
  isReadonly?: boolean;
  isResidentGroup?: boolean;
  isOwner?: boolean;
  isMember?: boolean;
  followCallback?: (id: string, action: "join" | "leave") => void;
}

export function PromotedGroupCard({
  id,
  name,
  description,
  image,
  isReadonly,
  isResidentGroup,
  isOwner,
  isMember,
  followCallback,
}: PromotedGroupProps): React.ReactNode {
  const api = useApi();
  const slug = useSlug();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const showFlashToast = useFlashToast();
  const resolveImage = useImageResolver();

  const { mutate: follow } = useMutation({
    mutationFn: () => api.postGroupsFollowV1(id),
    onSuccess() {
      followCallback?.(id, "join");
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.interest-group.action.follow.notification-error"),
      });
    },
  });

  const { mutate: unfollow } = useMutation({
    mutationFn: () => api.postGroupsUnfollowV1(id),
    onSuccess() {
      followCallback?.(id, "leave");
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.interest-group.action.unfollow.notification-error"),
      });
    },
  });

  return (
    <div
      className={twJoin("flex w-72 flex-col rounded-lg bg-white shadow-sm", !isReadonly && "cursor-pointer")}
      onClick={isReadonly ? undefined : () => navigate(routes.interestGroups.details({ slug, id }))}
    >
      <div
        className="flex h-40 w-full flex-col items-center justify-center gap-2 rounded-t-lg bg-grey-light bg-cover bg-center p-2"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8)), url(${image ? resolveImage(image, "large") : bgPlaceholder}) `,
        }}
      >
        <Headline4 className="text-center text-white">{name}</Headline4>
        <div className="flex items-center gap-1">
          {isOwner && isResidentGroup ? (
            <StaticButton>
              <div className="flex items-center gap-1">
                <img className="size-4" src={award} alt="" />
                <span>{t("model.interest-group.owner")}</span>
              </div>
            </StaticButton>
          ) : (
            <CardButton
              styling={isMember ? "filled" : "default"}
              className={twJoin("rounded-lg", isReadonly && "pointer-events-none")}
              onClick={
                isReadonly
                  ? undefined
                  : (e) => {
                      e.stopPropagation();
                      if (isMember) {
                        unfollow();
                      } else {
                        follow();
                      }
                    }
              }
            >
              {isMember ? t("model.interest-group.action.unfollow") : t("model.interest-group.action.follow")}
            </CardButton>
          )}
        </div>
      </div>
      <div className="rounded-b-lg p-4">
        <Capture2 className="whitespace-pre-wrap">{description}</Capture2>
      </div>
    </div>
  );
}
