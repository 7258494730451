import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";

export function LogoutRedirect(): null {
  const { instance, inProgress } = useMsal();

  // Workaround for MSAL issue, see below.
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5807
  useEffect(() => {
    const itemKey = "msal.interaction.status";
    if (sessionStorage.getItem(itemKey)) {
      sessionStorage.removeItem(itemKey);
    }
  }, []);

  useEffect(() => {
    async function logout() {
      if (inProgress === InteractionStatus.None) {
        await instance.logoutRedirect();
      }
    }

    void logout();
  }, [instance, inProgress]);

  return null;
}
