import type { DocumentsFolderDetailsDto, LanguageDto } from "api/types";
import { createFormTranslations } from "helpers/languages";

import type { CreateOrEditFormFields } from "./";

export function getDefaultFormValues(
  parentFolderId: string,
  languages: LanguageDto[],
  folderDetails?: DocumentsFolderDetailsDto,
): CreateOrEditFormFields {
  return {
    nameTranslations: createFormTranslations(languages, folderDetails?.nameTranslations),
    parentFolderId: folderDetails ? folderDetails.parentFolderId : parentFolderId,
  };
}
