import type { EventDto } from "api/types";
import { Overline2 } from "components/Text/Text";
import { isDefined } from "helpers/util";
import type { TFunction } from "i18next";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

export interface EventStatusProps extends EventDto {
  className?: string;
}

export const EventStatus = memo(function EventStatus(props: EventStatusProps): React.ReactNode {
  const { t } = useTranslation();

  const participants = props.deletedAt ? 0 : props.participantCount;

  const statusTranslation = getStatusTranslation(props, t);
  const numberOfFreeSpots = getRestAmountOfSpots(props.maxParticipants, participants);
  const isLastSpots = numberOfFreeSpots <= 3;
  const isUnlimitedEvent = props.type === "common" && props.maxParticipants == null;
  const isStatusAccented = (!props.isSignedUp && isLastSpots) || (isUnlimitedEvent && !props.isSignedUp);

  return statusTranslation != null ? (
    <div className="flex items-center">
      {props.deletedAt ? null : (
        <Overline2>{`${participants || 0}${
          props.maxParticipants != null ? `/${props.maxParticipants}` : ""
        }`}</Overline2>
      )}
      <div
        className={twJoin(
          "ml-1.5 flex grow-0 items-center gap-1.5 text-overline leading-overline",
          isStatusAccented ? "text-red-dark" : undefined,
        )}
      >
        {props.deletedAt ? null : <span className="block size-1 shrink-0 rounded-full bg-current content-['']" />}
        <span className="line-clamp-1">{statusTranslation}</span>
      </div>
    </div>
  ) : null;
});

export function getStatusTranslation(data: EventDto, t: TFunction): string | null {
  if (data.deletedAt) {
    return t("model.event.status.deleted");
  }

  const participantsExist = isDefined(data.maxParticipants) && isDefined(data.participantCount);
  const isFull = participantsExist ? data.participantCount! >= data.maxParticipants! : false;
  const restSpotsAmount = participantsExist && !isFull ? data.maxParticipants! - data.participantCount! : null;
  const noLimit = data.maxParticipants == null;
  if (data.isSignedUp) {
    return t("model.event.status.is-signed-up");
  }

  if (isFull) {
    return t("model.event.status.no-spots");
  }

  if (!data.canParticipate) {
    return null;
  }

  if (isDefined(restSpotsAmount)) {
    return t("model.event.status.spots", { count: data.maxParticipants! - data.participantCount! });
  }

  if (noLimit) {
    return t("model.event.status.is-not-signed-up");
  }

  return null;
}

function getRestAmountOfSpots(max?: number, current?: number) {
  if (max != null && current != null) {
    const result = max - current;
    if (result < 0) {
      console.error("Invalid amount of participants");
    }
    if (!result) {
      return Infinity;
    }

    return result;
  }

  return Infinity;
}
