import type { RadioProps } from "components/Radio/Radio";
import { Radio } from "components/Radio/Radio";
import { twResolve } from "helpers/tw-resolve";
import type { FieldPath, FieldPathValue, FieldValues, RegisterOptions } from "react-hook-form";
import { useController } from "react-hook-form";

export interface FormRadioProps<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> extends Omit<RadioProps, "onChange" | "checked" | "defaultChecked"> {
  name: TName;
  value: FieldPathValue<TFormValues, TName>;
  displayValue: string;
  className?: string;
  rules?: RegisterOptions;
}

/**
 * Values for radio buttons can only be strings. If you need to use a different type, you have to convert it to a string yourself.
 */
export function FormRadio<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>({ rules, displayValue, className, name, ...props }: FormRadioProps<TFormValues, TName>): React.ReactNode {
  const { field } = useController<TFormValues, TName>({ name, rules });

  return (
    <label
      className={twResolve(
        "my-0.5 inline-flex flex-row-reverse items-center justify-end gap-2 first-of-type:mt-0 last-of-type:mb-0",
        className,
      )}
    >
      <span>{displayValue}</span>
      <Radio {...props} {...field} value={props.value} defaultChecked={field.value === props.value} />
    </label>
  );
}
