import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { DocumentsRootFolderRequest } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { createFormTranslations, toTranslationsRequest, useLanguages } from "helpers/languages";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { createFormPermissions, toPermissionsRequest } from "helpers/permissions";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "routes";

import type { FormValues, RouteParams } from "./";
import type { LayoutProps } from "./Layout";

export interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader(props: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { folderId } = useParams<RouteParams>();
  const isEditMode = folderId != null;
  const api = useApi();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const navigate = useNavigate();
  const { data: languages = [], isFetching: languagesIsFetching, error: languagesError } = useLanguages();
  const {
    data: roles = [],
    isFetching: rolesIsFetching,
    error: rolesError,
  } = useQuery({
    queryKey: QUERY_KEYS.PERMISSION_ROLES(projectId),
    queryFn: () => api.getPermissionsRolesV1(),
    select: commonAPIDataSelector,
    notifyOnChangeProps: ["data", "isFetching", "error"],
  });
  const {
    data: rootFolderDetails,
    isFetching: rootFolderDetailsIsFetching,
    error: rootFolderDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.DOCUMENTS_ROOT_FOLDER_DETAILS(projectId, folderId!),
    queryFn: () => api.getDocumentsRootFolderDetailsV1(folderId!),
    enabled: isEditMode,
    select: commonAPIDataSelector,
    gcTime: 0,
    notifyOnChangeProps: ["data", "isFetching", "error"],
  });
  const {
    data: defaultFolderPermissions = [],
    isFetching: defaultFolderPermissionsIsFetching,
    error: defaultFolderPermissionsError,
  } = useQuery({
    queryKey: QUERY_KEYS.DOCUMENTS_ROOT_DEFAULT_PERMISSIONS(projectId),
    queryFn: () => api.getPermissionsDefaultFolderV1(),
    select: commonAPIDataSelector,
    notifyOnChangeProps: ["data", "isFetching", "error"],
  });
  const { mutate: createRoot, isPending: isSubmittingCreate } = useMutation({
    mutationFn: api.postDocumentsRootFolderV1,
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("model.document.root.action.create.notification.success"),
      });
      navigate(routes.documents.rootList({ slug }));
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.document.root.action.create.notification.failure"),
      });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.DOCUMENTS_ROOT_LIST(projectId) });
    },
  });
  const { mutate: editRoot, isPending: isSubmittingEdit } = useMutation({
    mutationFn: ({ id, body }: { id: string; body: DocumentsRootFolderRequest }) =>
      api.putDocumentsRootFolderV1(id, body),

    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("model.document.root.action.edit.notification.success"),
      });
      navigate(routes.documents.rootList({ slug }));
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.document.root.action.edit.notification.failure"),
      });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.DOCUMENTS_ROOT_LIST(projectId) });
    },
  });

  const loading =
    rootFolderDetailsIsFetching || rolesIsFetching || defaultFolderPermissionsIsFetching || languagesIsFetching;
  if (loading) {
    return <FullSizeLoader withPadding />;
  }

  const error = rootFolderDetailsError || rolesError || defaultFolderPermissionsError || languagesError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const onSubmit = (data: FormValues) => {
    const body: DocumentsRootFolderRequest = {
      ...data,
      nameTranslations: toTranslationsRequest(data.nameTranslations),
      descriptionTranslations: data.descriptionTranslations
        ? toTranslationsRequest(data.descriptionTranslations)
        : undefined,
      permissions: toPermissionsRequest(data.permissions),
    };

    return isEditMode ? editRoot({ id: folderId, body }) : createRoot(body);
  };

  const defaultValues: FormValues = {
    nameTranslations: createFormTranslations(languages, rootFolderDetails?.nameTranslations),
    descriptionTranslations: createFormTranslations(languages, rootFolderDetails?.descriptionTranslations),
    audience: rootFolderDetails?.audience ?? [],
    permissions: createFormPermissions(roles, defaultFolderPermissions, rootFolderDetails?.permissions),
    iconChar: rootFolderDetails?.iconChar || "\uEECA",
  };

  return (
    <>
      {props.children({
        languages,
        onSubmit,
        defaultValues,
        isSubmitting: isSubmittingCreate || isSubmittingEdit,
        isEditMode,
        folderId,
      })}
    </>
  );
}
