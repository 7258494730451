import { ApiClientContext } from "api/ApiClientProvider";
import type { Api } from "api/types";
import type { SecurityData } from "authentication/SecurityDataContext";
import { useContext } from "react";

export function useApiClient(): Api<SecurityData> {
  return useContext(ApiClientContext).api;
}

export function useApi(): Api<SecurityData>["api"] {
  return useApiClient().api;
}

export function useApiIsAuthenticated(): boolean {
  return useContext(ApiClientContext)?.isAuthenticated || false;
}
