export type PlusButtonOptionTypes =
  | "createInform"
  | "createHelp"
  | "createMarketplace"
  | "createEvent"
  | "createTicket"
  | "createServiceRequest"
  | "openZiezodan"
  | "openExternalUrl";

export const allOptions: PlusButtonOptionTypes[] = [
  "createInform",
  "createHelp",
  "createMarketplace",
  "createEvent",
  "createTicket",
  "createServiceRequest",
  "openZiezodan",
  "openExternalUrl",
];

export const menuOptions: PlusButtonOptionTypes[] = [
  "createMarketplace",
  "createEvent",
  "createTicket",
  "openZiezodan",
  "openExternalUrl",
];

export const mandatoryOptions: PlusButtonOptionTypes[] = ["createInform", "createHelp"];

export type TicketOptionId = "ticket" | "ticketWithMaintainer" | "serviceRequest" | "serviceRequestWithMaintainer";

export type TicketServiceLabels =
  | "create.ticket"
  | "create.service.request"
  | "create.service.request.maintainer"
  | "create.ticket.maintainer";

export type TicketOptionType = {
  id: TicketOptionId;
  type: "createTicket" | "createServiceRequest";
  label: TicketServiceLabels;
};

export const ticketOptions: TicketOptionType[] = [
  { id: "ticket", type: "createTicket", label: "create.ticket" },
  {
    id: "ticketWithMaintainer",
    type: "createTicket",
    label: "create.ticket.maintainer",
  },
  { id: "serviceRequest", type: "createServiceRequest", label: "create.service.request" },
  {
    id: "serviceRequestWithMaintainer",
    type: "createServiceRequest",
    label: "create.service.request.maintainer",
  },
];
