import type { LanguageDto, ServicePartnerCreateRequest, ServicePartnerRequest } from "api/types";
import { toPermissionsRequest } from "helpers/permissions";
import type { UploadFormImage } from "hooks/Network/useUploadImage";

import type { CreateOrEditFormFields } from "./Layout";

export async function UIToAPI(
  formData: CreateOrEditFormFields,
  isAdmin: boolean,
  uploadFormImage: UploadFormImage,
): Promise<ServicePartnerRequest | ServicePartnerCreateRequest> {
  if (formData.headerImage == null || formData.profileImage == null || formData.category?.id == null) {
    throw new Error("Required fields are empty");
  }
  const headerImage = await uploadFormImage(formData.headerImage[0]);
  const profileImage = await uploadFormImage(formData.profileImage[0]);

  return {
    translations: Object.entries(formData.titleTranslations).map((x) => ({
      languageId: x[0] as LanguageDto["id"],
      title: x[1],
      subtitle: formData.subtitleTranslations[x[0] as keyof typeof formData.subtitleTranslations] || "",
      description: formData.descriptionTranslations[x[0] as keyof typeof formData.descriptionTranslations] || "",
    })),
    audience: formData.connection || !isAdmin || formData.type === "iqbi" ? [] : formData.audience,
    projectConnectionId: formData.connection?.id,
    category: formData.category.id,
    email: formData.email,
    phoneNumber: formData.phoneNumber,
    headerImageId: headerImage?.id,
    profileImageId: profileImage!.id,
    permissions: isAdmin ? toPermissionsRequest(formData.permissions) : [],
    type: formData.type,
  };
}
