import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import type {
  EngagedResidentsMonthlyPercentageDto,
  MonthlyReportDateRangeProjectEngagementPercentageItemDto,
} from "api/types";
import { BorderlessLinkButton } from "components/Button/Button";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { LoadingIcon } from "components/Icons/Icons";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Select } from "components/Select/Select";
import { Table } from "components/Table/Table";
import { Capture1, Subtitle2 } from "components/Text/Text";
import { Capture2 } from "components/Text/Text";
import { useSlug } from "hooks/useSlug";
import { sortBy } from "lodash-es";
import { BarChart } from "modules/analytics/components/BarChart";
import { Card, CardHeader } from "modules/analytics/components/Card";
import { NoData } from "modules/analytics/components/NoData";
import type { DateRangeOption } from "modules/analytics/util";
import { formatChartDate } from "modules/analytics/util";
import { useMemo } from "react";
import { ChevronLeft } from "react-feather";
import { useTranslation } from "react-i18next";
import { routes } from "routes";
import type { NonEmptyArray } from "types/utility-types";

export interface LayoutProps {
  contribution: EngagedResidentsMonthlyPercentageDto;
  isRefetchingContributionData: boolean;
  selectedDateRange: DateRangeOption;
  dateRangeOptions: NonEmptyArray<DateRangeOption>;
  onDateRangeChange: (dateRange: DateRangeOption) => void;
}

export function Layout({
  contribution,
  isRefetchingContributionData,
  selectedDateRange,
  dateRangeOptions,
  onDateRangeChange,
}: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { i18n, t } = useTranslation();

  const contributiontionDataPoints = useMemo(
    () =>
      contribution
        ? contribution.data.map((point) => ({ x: formatChartDate(point.range), y: point.engagedResidentPercentage }))
        : [],
    [contribution],
  );

  const benchmark = useMemo(
    () =>
      contribution
        ? contribution.benchmark.map((point) => ({
            x: formatChartDate(point.range),
            y: point.engagedResidentPercentage,
          }))
        : [],
    [contribution],
  );

  const noContributionDataAvailable =
    !contribution || contribution.data.every((x) => x.engagedResidentPercentage === 0);

  const columns = useMemo(() => {
    const helper = createColumnHelper<MonthlyReportDateRangeProjectEngagementPercentageItemDto>();

    return [
      helper.accessor("range", {
        header: t("page.analytics-details.contribution.table.date"),
        cell: (cell) => <span>{formatDate(i18n, "monthYearShort", formatChartDate(cell.getValue()))}</span>,
      }),
      helper.accessor("engagedResident", {
        header: t("page.analytics-details.contribution.table.engaged-residents"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("postCount", {
        header: t("page.analytics-details.contribution.table.posts"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("likeCount", {
        header: t("page.analytics-details.contribution.table.likes"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("commentLikeCount", {
        header: t("page.analytics-details.contribution.table.comment-likes"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("commentCount", {
        header: t("page.analytics-details.contribution.table.comments"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("chatCount", {
        header: t("page.analytics-details.contribution.table.chats"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("eventCount", {
        header: t("page.analytics-details.contribution.table.events"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("eventsAtendeesCount", {
        header: t("page.analytics-details.contribution.table.event-attendees"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("surveyCount", {
        header: t("page.analytics-details.contribution.table.surveys"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("surveysAnsweredCount", {
        header: t("page.analytics-details.contribution.table.survey-responses"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("pollCount", {
        header: t("page.analytics-details.contribution.table.polls"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("pollsAnsweredCount", {
        header: t("page.analytics-details.contribution.table.polls-answered"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("residentGroupsCreatedCount", {
        header: t("page.analytics-details.contribution.table.resident-groups-created"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("privateTicketCount", {
        header: t("page.analytics-details.contribution.table.tickets"),
        cell: (cell) => (
          <span>
            {cell.getValue() + cell.row.original.collectiveTicketCount + (cell.row.original.ratedTicketCount ?? 0)}
          </span>
        ),
      }),
      helper.accessor("reservationCount", {
        header: t("page.analytics-details.contribution.table.reservations"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
    ];
  }, [t, i18n]);

  const tableInstance = useReactTable<MonthlyReportDateRangeProjectEngagementPercentageItemDto>({
    columns,
    data: sortBy(contribution.data, (x) => new Date(x.range.from)).reverse(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.analytics-details.contribution.title")}
      subTitle={
        <BorderlessLinkButton className="pl-0 font-normal text-aop-basic-blue" to={routes.analytics.overview({ slug })}>
          <ChevronLeft size={18} />
          {t("page.analytics-details.back")}
        </BorderlessLinkButton>
      }
      header={
        <div className="flex items-center gap-2 py-4">
          <Subtitle2>{t("page.analytics-details.time-range.label")}</Subtitle2>
          <Select
            items={dateRangeOptions}
            selected={selectedDateRange}
            onChange={onDateRangeChange}
            renderOption={(x) => x.name}
            keySelector={(x) => x.value}
          />
        </div>
      }
    >
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
        <Card className="order-1 h-fit lg:col-span-2">
          <CardHeader title={t("page.analytics-details.contribution.chart.contribution.title")}></CardHeader>
          {isRefetchingContributionData ? (
            <LoadingIcon className="mx-auto w-8" />
          ) : noContributionDataAvailable ? (
            <NoData error={noContributionDataAvailable} />
          ) : (
            <div className="flex flex-col gap-4">
              <Capture2 className="text-grey-darker">
                {t("page.analytics-details.contribution.chart.contribution.description")}
              </Capture2>
              <div className="w-full">
                <BarChart
                  type="date"
                  height={250}
                  primaryData={contributiontionDataPoints}
                  benchmark={benchmark}
                  formatYTick={(y) => y}
                  formatXTick={(x) => x}
                  maxY={100}
                  yLabel={t("page.analytics-details.contribution.chart.contribution.y-axis")}
                  showLegend={false}
                  renderLabel={(data) => (
                    <div className="whitespace-pre-line">
                      {t("page.analytics-overview.contribution.chart.contribution-benchmark.tooltip", {
                        percentage: data.primary?.y,
                        benchmark: data.benchmark?.y,
                      })}
                    </div>
                  )}
                />
              </div>
            </div>
          )}
        </Card>
        <Card className="order-3 col-span-3 overflow-x-scroll">
          <CardHeader title={t("page.analytics-details.contribution.table.title")}></CardHeader>
          <Table
            table={tableInstance}
            data-testid="contribution-details-table"
            isLoading={isRefetchingContributionData}
          />
        </Card>
        <div className="flex flex-col gap-4">
          <Card className="h-fit justify-start">
            <CardHeader title={t("page.analytics-details.contribution.information.title")} />
            <Capture1>{t("page.analytics-details.contribution.information.subtitle")}</Capture1>
            <ul className="list-disc p-4">
              {t("page.analytics-details.contribution.information.content")
                .split("\n")
                .map((item, idx) => item.length > 0 && <li key={`information-${idx}`}>{item}</li>)}
            </ul>
          </Card>
          <Card className="h-fit justify-start">
            <CardHeader title={t("page.analytics-details.contribution.best-practices.title")} />
            <Capture1>{t("page.analytics-details.contribution.best-practices.subtitle")}</Capture1>
            <ul className="list-disc p-4">
              {t("page.analytics-details.contribution.best-practices.content")
                .split("\n")
                .map((item, idx) => item.length > 0 && <li key={`best-practices-${idx}`}>{item}</li>)}
            </ul>
          </Card>
        </div>
      </div>
    </DocumentPaper>
  );
}
