import { Button } from "components/Button/Button";
import type { FormInputProps } from "components/Form/FormInput";
import { GroupIconMedia } from "components/Media/Media";
import { Modal } from "components/Modal/Modal";
import { Headline4 } from "components/Text/Text";
import { isDefined } from "helpers/util";
import { useBool } from "hooks/useBool";
import { get } from "lodash-es";
import { GroupIconPicker } from "modules/community-groups/components/GroupIconPicker";
import type { GroupIconType } from "modules/community-groups/components/GroupIcons";
import { useEffect, useState } from "react";
import type { FieldPath, FieldValues } from "react-hook-form";
import { useFormContext, useFormState, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormErrorWrapper } from "./FormErrorWrapper";

export interface FormIconPickerProps<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> extends FormInputProps<TFormValues, TName> {
  titleText?: string;
  openText?: string;
  cancelText?: string;
  selectText?: string;
}

export function FormGroupIconPicker<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>({ name, ...props }: FormIconPickerProps<TFormValues, TName>): React.ReactNode {
  const { t } = useTranslation();
  const { register, setValue } = useFormContext<TFormValues>();
  const { errors } = useFormState<TFormValues>();
  const selectedIcon = (useWatch({ name }) as GroupIconType) || "";
  const error = get(errors, name);
  const [modalIcon, setModalCharIcon] = useState<GroupIconType | null>(null);
  const [isOpen, modalOpenHandlers] = useBool(false);

  useEffect(() => {
    setModalCharIcon(selectedIcon);
  }, [selectedIcon]);

  function openModal() {
    modalOpenHandlers.setTrue();
    setModalCharIcon(selectedIcon ? selectedIcon : null);
  }

  const title = props.titleText || t("component.group-icon-picker-input.title");

  return (
    <FormErrorWrapper className="flex items-start gap-4" name={name} data-testid={props["data-testid"]}>
      <GroupIconMedia
        icon={selectedIcon}
        onAdd={openModal}
        onEdit={openModal}
        aria-invalid={isDefined(error)}
        data-testid="icon-char-media"
        data-edit-testid="icon-char-action-open"
        data-add-testid="icon-char-action-open"
      />
      <input type="hidden" readOnly {...register(name, props.rules)} className="hidden" />
      <Modal isOpen={isOpen} onRequestClose={modalOpenHandlers.setFalse} shouldCloseOnEsc>
        <div className="p-6">
          <Headline4 className="mb-6 block">{title}</Headline4>
          <div className="h-[295px] w-[calc(100vw-80px)] overflow-auto border border-grey-lighter sm:w-[616px] sm:p-8">
            <GroupIconPicker selectedIcon={modalIcon} onChange={setModalCharIcon} />
          </div>
          <div className="mt-6 flex justify-center gap-4">
            <Button
              onClick={() => {
                setModalCharIcon(null);
                modalOpenHandlers.setFalse();
              }}
              styling="primaryFaded"
            >
              {props.cancelText || t("common.action.cancel")}
            </Button>
            <Button
              data-testid="icon-char-action-submit"
              onClick={() => {
                if (modalIcon != null) {
                  setValue(name, modalIcon as any, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                  });

                  modalOpenHandlers.setFalse();
                }
              }}
              disabled={modalIcon == null}
              styling="primary"
            >
              {props.selectText || t("common.action.select")}
            </Button>
          </div>
        </div>
      </Modal>
    </FormErrorWrapper>
  );
}
