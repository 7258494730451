import type { ImageDto, UserDto } from "api/types";
import undoIcon from "assets/icons/reverse-left.svg";
import redoIcon from "assets/icons/reverse-right.svg";
import { BorderlessButton, Button, IconButton } from "components/Button/Button";
import { Gallery } from "components/Gallery/Gallery";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import type { FormImage } from "components/ImageInput/useImageInput";
import { ScalingTextArea } from "components/ScalingTextArea/ScalingTextArea";
import { Tooltip } from "components/Tooltip/Tooltip";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { twResolve } from "helpers/tw-resolve";
import { useBool } from "hooks/useBool";
import { useCombinedRefs } from "hooks/useCombinedRef";
import { useKey } from "hooks/useKey";
import type React from "react";
import { forwardRef, useEffect, useRef } from "react";
import { Camera, Edit, Lock, Send, X } from "react-feather";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

export interface CommentFieldProps {
  autoFocus?: boolean;
  value?: string;
  placeholder?: string;
  imageUploadTooltip?: string;
  sendTooltip?: string;
  isEdit?: boolean;
  isNote?: boolean;
  disabled?: boolean;
  isCancellable?: boolean;
  allowsImages?: boolean;
  images?: FormImage[];
  withoutTopRow?: boolean;
  inlineCancel?: boolean;
  onChange: (value: string) => void;
  onAddImages?: (images: FileList) => void;
  onRemoveImage?: (image: FormImage) => void;
  onCancel?: () => void;
  onSubmit: () => Promise<void>;
}

export function CommentField(props: CommentFieldProps): React.ReactNode {
  const [isSubmitting, setSubmitting] = useBool();

  useKey("Escape", () => {
    if (props.isCancellable) {
      props.onCancel?.();
    }
  });

  async function submit() {
    try {
      setSubmitting.setTrue();
      await props.onSubmit();
    } finally {
      setSubmitting.setFalse();
    }
  }

  return (
    <CommentFieldWrapper>
      {props.withoutTopRow ? null : (
        <CommentFieldRow>
          <CommentFieldTopBar
            isNote={props.isNote}
            isCancellable={props.isEdit || props.isCancellable}
            onCancel={props.onCancel}
          />
        </CommentFieldRow>
      )}
      <CommentFieldRow>
        <CommentFieldInputBox isSubmitting={isSubmitting} isNote={props.isNote} disabled={props.disabled}>
          <CommentFieldInput
            autoFocus={props.autoFocus}
            value={props.value}
            isEdit={props.isEdit}
            isNote={props.isNote}
            isSubmitting={isSubmitting}
            onChange={props.onChange}
            placeholder={props.placeholder}
            disabled={props.disabled}
          />
          <CommentFieldActions isNote={props.isNote} isSubmitting={isSubmitting}>
            <CommentFieldImageAction
              allowsImages={props.allowsImages}
              isSubmitting={isSubmitting}
              disabled={props.disabled}
              onAddImages={props.onAddImages}
              imageUploadTooltip={props.imageUploadTooltip}
            />
            <CommentFieldSend
              isSubmitting={isSubmitting}
              isEdit={props.isEdit}
              canSend={(props.images && props.images.length > 0) || (props.value && props.value.length > 0) || false}
              onSubmit={submit}
              sendTooltip={props.sendTooltip}
            />
          </CommentFieldActions>
        </CommentFieldInputBox>
      </CommentFieldRow>
      <CommentFieldGallery images={props.images} onRemoveImage={props.onRemoveImage} />
    </CommentFieldWrapper>
  );
}

export interface CommentFieldWithAvatarProps extends CommentFieldProps {
  user: UserDto;
  showCopilot?: boolean;
  copilotLabel?: string;
  onCopilotClick?: () => void;
  isCopilotText?: boolean;
  hasCopilotText?: boolean;
  onCopilotUndo?: () => void;
  onCopilotRedo?: () => void;
  isReply?: boolean;
}

export const CommentFieldWithAvatar = forwardRef<HTMLTextAreaElement, CommentFieldWithAvatarProps>(
  function CommentFieldWithAvatar(props, ref): React.ReactNode {
    const [isSubmitting, setSubmitting] = useBool();

    useKey("Escape", () => {
      if (props.isCancellable) {
        props.onCancel?.();
      }
    });

    async function submit() {
      try {
        setSubmitting.setTrue();
        await props.onSubmit();
      } finally {
        setSubmitting.setFalse();
      }
    }

    return (
      <CommentFieldWrapper>
        {props.withoutTopRow || props.inlineCancel ? null : (
          <CommentFieldRow>
            <CommentFieldTopBar
              noAvatar
              isNote={props.isNote}
              onCancel={props.onCancel}
              isCancellable={props.isEdit || props.isCancellable}
            />
          </CommentFieldRow>
        )}
        <CommentFieldRow>
          <div className="flex w-full items-end gap-2">
            <CommentFieldAvatar avatar={props.user.avatar} isDeleted={!!props.user.deletedAt} />
            <CommentFieldInputBox isSubmitting={isSubmitting} isNote={props.isNote} disabled={props.disabled}>
              {props.showCopilot && (
                <div className="flex shrink-0 grow flex-row gap-1 self-start md:gap-2 md:self-end">
                  <button
                    className="min-w-fit self-start rounded-md border border-aop-bright-purple bg-aop-bright-purple/10 px-1 py-2 text-sm font-semibold leading-none text-aop-bright-purple hover:bg-aop-bright-purple/20 md:-mr-1 md:self-end"
                    onClick={props.onCopilotClick}
                  >
                    {props.copilotLabel}
                  </button>
                  {props.isCopilotText ? (
                    <button
                      className="min-w-fit self-start rounded-md border border-aop-bright-purple bg-aop-bright-purple/10 px-1 py-2 text-aop-bright-purple hover:bg-aop-bright-purple/20 md:-mr-1 md:self-end"
                      onClick={props.onCopilotUndo}
                    >
                      <Icon name={undoIcon} size={14} />
                    </button>
                  ) : props.hasCopilotText ? (
                    <button
                      className="min-w-fit self-start rounded-md border border-aop-bright-purple bg-aop-bright-purple/10 px-1 py-2 text-aop-bright-purple hover:bg-aop-bright-purple/20 md:-mr-1 md:self-end"
                      onClick={props.onCopilotRedo}
                    >
                      <Icon name={redoIcon} size={14} />
                    </button>
                  ) : null}
                </div>
              )}
              <CommentFieldInput
                ref={ref}
                autoFocus={props.autoFocus}
                value={props.value}
                isEdit={props.isEdit}
                isSubmitting={isSubmitting}
                onChange={props.onChange}
                isNote={props.isNote}
                placeholder={props.placeholder}
                disabled={props.disabled}
              />
              <CommentFieldActions isNote={props.isNote} isSubmitting={isSubmitting}>
                <CommentFieldImageAction
                  allowsImages={props.allowsImages}
                  isSubmitting={isSubmitting}
                  disabled={props.disabled}
                  onAddImages={props.onAddImages}
                  imageUploadTooltip={props.imageUploadTooltip}
                />
                {!props.isReply && (
                  <>
                    <CommentFieldSend
                      isSubmitting={isSubmitting}
                      isEdit={props.isEdit}
                      canSend={
                        (props.images && props.images.length > 0) || (props.value && props.value.length > 0) || false
                      }
                      onSubmit={submit}
                      sendTooltip={props.sendTooltip}
                    />
                    {props.inlineCancel && (
                      <CommentFieldInlineCancel
                        onCancel={props.onCancel}
                        isCancellable={props.isEdit || props.isCancellable}
                      />
                    )}
                  </>
                )}
              </CommentFieldActions>
            </CommentFieldInputBox>
          </div>

          {props.isReply && (
            <>
              <CommentFieldSend
                isReply
                isSubmitting={isSubmitting}
                isEdit={props.isEdit}
                canSend={(props.images && props.images.length > 0) || (props.value && props.value.length > 0) || false}
                onSubmit={submit}
                sendTooltip={props.sendTooltip}
              />
              {props.inlineCancel && (
                <CommentFieldInlineCancel
                  onCancel={props.onCancel}
                  isCancellable={props.isEdit || props.isCancellable}
                />
              )}
            </>
          )}
        </CommentFieldRow>
        <CommentFieldGallery images={props.images} withAvatar onRemoveImage={props.onRemoveImage} />
      </CommentFieldWrapper>
    );
  },
);

function CommentFieldWrapper({ children }: React.PropsWithChildren<{}>) {
  return <div className="flex flex-1 flex-col">{children}</div>;
}

function CommentFieldRow({ children }: React.PropsWithChildren<{}>) {
  return <div className="relative flex flex-col items-end gap-2 md:flex-row md:items-center">{children}</div>;
}

function CommentFieldInputBox({
  isSubmitting,
  isNote,
  disabled,
  children,
}: React.PropsWithChildren<{ isSubmitting?: boolean; isNote?: boolean; disabled?: boolean }>) {
  return (
    <div
      className={twResolve(
        "flex w-full flex-1 flex-col items-center gap-2 rounded-lg border border-grey-lighter bg-white p-1 focus-within:border-grey-darkest md:flex-row",
        isSubmitting && "pointer-events-none opacity-30",
        disabled ? "bg-grey-lightest" : isNote ? "bg-yellow-lightest" : "",
      )}
    >
      {children}
    </div>
  );
}

function CommentFieldTopBar({
  noAvatar,
  isCancellable,
  isNote,
  onCancel,
}: {
  noAvatar?: boolean;
  isCancellable?: boolean;
  isNote?: boolean;
  onCancel?: () => void;
}) {
  const { t } = useTranslation();

  const showCancelButton = isCancellable && onCancel;

  return (
    <div className={twJoin("flex h-8 flex-1 text-grey", noAvatar ? "ml-10" : undefined)}>
      {isNote ? (
        <span className="my-1 flex items-center text-sm text-yellow-darker">
          <Lock className="mr-1 block" size={14} /> {t("model.repair-request.comment-note")}
        </span>
      ) : null}
      {showCancelButton ? (
        <div className="my-1 ml-auto mr-3">
          <BorderlessButton onClick={onCancel}>{t("common.action.cancel")}</BorderlessButton>
        </div>
      ) : null}
    </div>
  );
}

const CommentFieldInput = forwardRef<
  HTMLTextAreaElement,
  {
    autoFocus?: boolean;
    disabled?: boolean;
    value?: string;
    placeholder?: string;
    onChange: (value: string) => void;
    isEdit?: boolean;
    isNote?: boolean;
    isSubmitting?: boolean;
  }
>(function CommentFieldInput({ autoFocus, value, onChange, placeholder, isEdit, isNote, isSubmitting, disabled }, ref) {
  const { t } = useTranslation();
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const combinedRef = useCombinedRefs(textAreaRef, ref);

  useEffect(() => {
    if (isEdit && textAreaRef.current) {
      textAreaRef.current.focus();
      textAreaRef.current.setSelectionRange(textAreaRef.current.value.length, textAreaRef.current.value.length);
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
    }
  }, [isEdit, textAreaRef]);

  return (
    <ScalingTextArea
      className={twJoin(
        "block w-full resize-none bg-transparent pl-1 placeholder:text-grey focus:outline-none",
        isSubmitting && "pointer-events-none opacity-30",
        isNote && "bg-yellow-lightest",
      )}
      disabled={disabled}
      autoFocus={autoFocus}
      data-testid="comment-field"
      name="content"
      ref={combinedRef}
      value={value || ""}
      onChange={(event) => onChange(event.target.value)}
      placeholder={
        placeholder
          ? placeholder
          : isNote
            ? t("component.comment-field.create-new-note")
            : t("component.comment-field.create-new-reaction")
      }
    />
  );
});

function CommentFieldGallery({
  images,
  withAvatar,
  onRemoveImage,
}: {
  images?: FormImage[];
  withAvatar?: boolean;
  onRemoveImage?: (image: FormImage) => void;
}) {
  return images && images.length > 0 ? (
    <div className={withAvatar ? "pl-10" : undefined}>
      <Gallery images={images} onRemove={onRemoveImage} />
    </div>
  ) : null;
}

function CommentFieldAvatar({ avatar, isDeleted }: { avatar?: ImageDto; isDeleted?: boolean }) {
  return (
    <div className="my-1 size-8 shrink-0">
      <UserAvatar img={avatar} isUserDeleted={isDeleted} />
    </div>
  );
}

function CommentFieldActions({
  isSubmitting,
  isNote,
  children,
}: {
  isSubmitting?: boolean;
  isNote?: boolean;
  children?: React.ReactNode;
}) {
  return (
    <div
      className={twResolve(
        "flex h-8 items-center self-end bg-transparent text-grey",
        isNote && "bg-yellow-lightest",
        isSubmitting && "pointer-events-none text-grey-lighter",
        isNote && isSubmitting && "bg-yellow-lightest/30",
      )}
    >
      {children}
    </div>
  );
}

function CommentFieldImageAction({
  allowsImages,
  isSubmitting,
  disabled,
  onAddImages,
  imageUploadTooltip,
}: {
  allowsImages?: boolean;
  isSubmitting?: boolean;
  disabled?: boolean;
  onAddImages?: (images: FileList) => void;
  imageUploadTooltip?: string;
}) {
  const fileInput = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  return allowsImages ? (
    <Tooltip tooltip={imageUploadTooltip ? imageUploadTooltip : t("component.comment-field.upload-image")}>
      <label
        className={twJoin(
          "relative mr-1 flex size-7 cursor-pointer items-center justify-center rounded-full",
          disabled
            ? "opacity-30"
            : "focus-within:text-grey-darkest hover:bg-blue-lightest hover:text-grey-darkest focus:outline-none",
          isSubmitting && "pointer-events-none opacity-30",
        )}
      >
        <input
          className="absolute left-0 top-0 size-full text-base opacity-0"
          ref={fileInput}
          type="file"
          id="img"
          name="img"
          accept="image/png, image/jpeg"
          onChange={(event) => {
            if (event.target.files) {
              onAddImages?.(event.target.files);
            }

            event.target.value = "";
          }}
          disabled={disabled}
        />
        <Camera size="20" />
      </label>
    </Tooltip>
  ) : null;
}

function CommentFieldSend({
  sendTooltip,
  isSubmitting,
  isEdit,
  canSend,
  onSubmit,
  isReply,
}: {
  sendTooltip?: string;
  isSubmitting?: boolean;
  isEdit?: boolean;
  canSend?: boolean;
  onSubmit: () => Promise<void>;
  isReply?: boolean;
}) {
  const { t } = useTranslation();

  return isReply ? (
    <Button
      title={sendTooltip ? sendTooltip : t("component.comment-field.send-comment")}
      data-testid="send-reply"
      styling="primary"
      disabled={isSubmitting || !canSend}
      onClick={onSubmit}
      isLoading={isSubmitting}
    >
      {isEdit ? t("component.community-post.comments.edit") : t("component.community-post.comments.reply")}
    </Button>
  ) : (
    <Tooltip tooltip={sendTooltip ? sendTooltip : t("component.comment-field.send-comment")}>
      <span>
        <button
          className={twResolve(
            "flex size-7 cursor-pointer items-center justify-center rounded-full hover:bg-blue-lightest focus:bg-blue-lighter focus:outline-none disabled:pointer-events-none",
            canSend ? "text-aop-basic-blue" : "opacity-30",
            isSubmitting && "pointer-events-none",
          )}
          data-testid="send-comment"
          disabled={isSubmitting || !canSend}
          type="button"
          onClick={onSubmit}
        >
          {isSubmitting ? (
            <LoadingIcon className="w-5" />
          ) : isEdit ? (
            <Edit size="20" />
          ) : (
            <Send className="-translate-x-0.5 rotate-45" size="20" />
          )}
        </button>
      </span>
    </Tooltip>
  );
}
function CommentFieldInlineCancel({ onCancel, isCancellable }: { onCancel?: () => void; isCancellable?: boolean }) {
  const { t } = useTranslation();

  if (!isCancellable || !onCancel) {
    return null;
  }

  return (
    <IconButton onClick={onCancel} title={t("common.action.cancel")}>
      <X size="20" />
    </IconButton>
  );
}
