import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { useImageResolver } from "api/hooks/useImageResolver";
import type {
  ConsumptionResultDto,
  ServicePartnerPageDetailsDto,
  ServicePartnerPageMyPupPropertiesDto,
} from "api/types";
import alertCircleIcon from "assets/icons/alert-circle.svg";
import homeIcon from "assets/icons/home-02.svg";
import bgPlaceholder from "assets/images/image-placeholder.png";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { ContextMenu, type ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { DateRange } from "components/DateRange/DateRange";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { GrowLabel } from "components/GrowLabel/GrowLabel";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { MonthPicker } from "components/MonthPicker/MonthPicker";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Headline3, Headline4, Subtitle2 } from "components/Text/Text";
import { endOfMonth, format, isSameMonth, startOfMonth } from "date-fns";
import { getFromConfig } from "helpers/config";
import { asUtc } from "helpers/date";
import { isHttpError } from "helpers/Network/errors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useEffect, useState } from "react";
import { useMemo } from "react";
import { Mail, Phone } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

import electricityIcon from "../../assets/iqbi-electricity.png";
import heatingIcon from "../../assets/iqbi-heating.png";
import solarIcon from "../../assets/iqbi-solar.png";
import waterIcon from "../../assets/iqbi-water.png";

export interface LayoutProps {
  serviceDetails: ServicePartnerPageDetailsDto;
  onDelete: () => Promise<unknown>;
}

export function Layout({ serviceDetails, onDelete }: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const navigate = useNavigate();
  const { componentProps: deleteModalProps, openDeleteModal } =
    useDeleteModal<DeleteRequestParams>("service-delete-modal");
  const resolveImage = useImageResolver();

  const actions = useMemo(() => {
    const result: ContextMenuAction[] = [];

    if (serviceDetails.canEdit) {
      result.push({
        callback: () => {
          navigate(routes.servicePartners.edit({ slug, id: serviceDetails.id }), { state: { from: "details" } });
        },
        text: t("common.action.edit"),
        dataTestId: "context-menu-edit-btn",
      });
    }
    if (serviceDetails.canDelete) {
      result.push({
        callback: () => {
          openDeleteModal({ id: serviceDetails.id });
        },
        text: t("common.action.delete"),
        dataTestId: "context-menu-delete-btn",
      });
    }

    return result;
  }, [serviceDetails.canEdit, serviceDetails.canDelete, serviceDetails.id, t, navigate, slug, openDeleteModal]);

  const bgImage = serviceDetails.headerImage || sessionUser.project.backgroundImage;

  return (
    <DocumentPaper
      theme="minimal-constrained"
      title={t("page.service-details.title")}
      subTitle={
        <Breadcrumbs
          pages={[
            {
              name: t("page.service.list.title"),
              to: routes.servicePartners.list({ slug }),
            },
            {
              name: t("page.service-details.breadcrumbs.self"),
            },
          ]}
        />
      }
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col rounded-lg bg-white shadow-md">
          <div className="relative">
            <div
              className="h-64 rounded-t-lg bg-grey-light bg-cover bg-center p-2"
              style={{
                backgroundImage: `url(${bgImage ? resolveImage(bgImage, "large") : bgPlaceholder})`,
              }}
            />
            <img
              className="absolute -bottom-12 right-8 size-24 rounded-full border-4 border-white bg-white object-cover"
              src={serviceDetails.profileImage?.url}
              alt={serviceDetails.title}
            />
          </div>
          <div className="flex flex-col gap-4 p-4">
            <Headline4>{serviceDetails.title}</Headline4>
            <Subtitle2>{serviceDetails.subtitle}</Subtitle2>
            {serviceDetails.phoneNumber || serviceDetails.email || actions.length ? (
              <div className="flex w-full items-center justify-end gap-4">
                {serviceDetails.phoneNumber ? (
                  <a
                    className="flex cursor-pointer items-center justify-center rounded-full bg-grey-lightest p-4 hover:bg-grey-lighter [&>svg]:stroke-aop-dark-blue hover:[&>svg]:stroke-blue-darkest"
                    href={`tel:${serviceDetails.phoneNumber}`}
                  >
                    <Phone size={24} />
                  </a>
                ) : null}
                {serviceDetails.email ? (
                  <a
                    className="flex cursor-pointer items-center justify-around rounded-full bg-grey-lightest p-4 hover:bg-grey-lighter [&>svg]:stroke-aop-dark-blue hover:[&>svg]:stroke-blue-darkest"
                    href={`mailto:${serviceDetails.email}`}
                  >
                    <Mail size={24} />
                  </a>
                ) : null}
                <ContextMenu actions={actions} />
              </div>
            ) : null}
            {serviceDetails.type !== "iqbi" && (
              <div
                className="prose whitespace-pre-wrap"
                dangerouslySetInnerHTML={{
                  __html: serviceDetails.description!,
                }}
              />
            )}
          </div>
        </div>

        {serviceDetails.type === "iqbi" ? <Iqbi serviceDetails={serviceDetails} /> : null}
        {serviceDetails.type === "myPup" && serviceDetails.partnerProperties.myPup ? (
          <MyPup id={serviceDetails.id} partnerProperties={serviceDetails.partnerProperties.myPup} />
        ) : null}
      </div>

      <DeleteModal<DeleteRequestParams>
        title={t("model.service.action.delete.confirmation")}
        description={t("model.service.action.delete.description")}
        onDelete={onDelete}
        {...deleteModalProps}
      />
    </DocumentPaper>
  );
}

interface DeleteRequestParams {
  id: string;
}

const DEFAULT_IQBI_CONSUMPTION = [
  {
    requestedMonth: {
      value: 0,
    },
    unitType: "electricity",
    percentageChange: 0,
    previousMonth: {
      value: 0,
    },
    usageChange: 0,
  },
  {
    requestedMonth: {
      value: 0,
    },
    unitType: "gas",
    percentageChange: 0,
    previousMonth: {
      value: 0,
    },
    usageChange: 0,
  },
  {
    requestedMonth: {
      value: 0,
    },
    unitType: "water",
    percentageChange: 0,
    previousMonth: {
      value: 0,
    },
    usageChange: 0,
  },
] satisfies ConsumptionResultDto[];

function Iqbi({ serviceDetails }: { serviceDetails: ServicePartnerPageDetailsDto }) {
  const [iqbiDate, setIqbiDate] = useState<Date>(startOfMonth(new Date()));
  const month = format(asUtc(iqbiDate), "yyyy-MM-dd");
  const showFlashToast = useFlashToast();
  const projectId = useProjectId();
  const api = useApi();
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const sessionUser = useSessionUser();
  const [iqbiVerifyModalOpen, iqbiVerifyModalHandlers] = useBool(false);

  const {
    data: iqbiConsumption,
    isFetching: isLoadingIqbi,
    error: iqbiError,
  } = useQuery({
    queryKey: QUERY_KEYS.SERVICE_PARTNER_IQBI(projectId, serviceDetails.id, month),
    queryFn: () => api.getServicepartnersIqbiConsumptionV1({ month }),
    enabled: serviceDetails?.type === "iqbi",
    placeholderData: keepPreviousData,
    staleTime: Infinity,
  });

  const verifyIqbi = useMutation({
    mutationFn: () => api.postServicepartnersIqbiConfirmAddressV1(),
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("model.service.action.iqbi-verify.notification.success"),
      });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SERVICE_PARTNER_IQBI(projectId, serviceDetails.id) });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.service.action.iqbi-verify.notification.error"),
      });
    },
  });

  const noAccessToIqbi = isHttpError(iqbiError) && iqbiError.status === 403;
  const noData = iqbiConsumption?.status === 204 || (isHttpError(iqbiError) && iqbiError.status === 404);

  const blurry = noAccessToIqbi || isLoadingIqbi;

  return (
    <>
      <div className="flex flex-col gap-2 rounded-lg bg-white p-4 shadow-md">
        <Headline4>{t("page.service-details.iqbi-address.title")}</Headline4>
        {!iqbiConsumption && !iqbiError && isLoadingIqbi ? (
          <LoadingIcon className="mx-auto my-2 size-8" />
        ) : noAccessToIqbi ? (
          <div className="flex items-center gap-2">
            <Icon name={alertCircleIcon} size={22} className="text-red" />
            <p>{t("page.service-details.iqbi-consumption.no-access")}</p>
          </div>
        ) : (
          <div className="flex items-center gap-2">
            <Icon name={homeIcon} size={28} />
            <p className="text-grey-darkest">
              {sessionUser.address ? (
                <>
                  {sessionUser.address.location}
                  <br />
                  {sessionUser.address?.zipCode} {sessionUser.address?.city}
                </>
              ) : (
                sessionUser.company?.name
              )}
            </p>
          </div>
        )}
      </div>

      <div className="flex flex-col gap-4 rounded-lg bg-white p-4 shadow-md">
        <Headline3>{t("page.service-details.iqbi-consumption.title")}</Headline3>
        <div className="relative">
          <div className={twJoin(blurry ? "blur-sm" : undefined, "flex flex-col gap-4")}>
            <div className="flex flex-row justify-between">
              <MonthPicker onChange={setIqbiDate} placement="left" value={iqbiDate} maxDate={new Date()} />
            </div>
            {noData ? (
              <p className="my-2 text-grey-darker">{t("page.service-details.iqbi-consumption.no-data")}</p>
            ) : (
              <>
                <div className="mt-2 flex grid-cols-2 flex-col gap-8 gap-x-20 lg:grid">
                  {(iqbiConsumption?.data || DEFAULT_IQBI_CONSUMPTION)?.map((consumption) => {
                    const icon =
                      consumption.unitType === "electricity"
                        ? electricityIcon
                        : consumption.unitType === "gas"
                          ? heatingIcon
                          : consumption.unitType === "water"
                            ? waterIcon
                            : solarIcon;

                    const metric = consumption.unitType === "electricity" ? "kWh" : "m3";

                    return (
                      <div className="flex items-center justify-between gap-2" key={consumption.unitType}>
                        <div className="flex items-center gap-4">
                          <img src={icon} width={40} height={40} alt="" />
                          <p className="flex items-baseline gap-1">
                            <span className="text-3xl font-semibold">{consumption.requestedMonth.value}</span>
                            <span>{metric}</span>
                          </p>
                        </div>

                        <div>
                          <GrowLabel
                            type="percentage"
                            percentage={consumption.percentageChange || 0}
                            showZero={consumption.percentageChange != null}
                            isWide
                            increasingIsBad
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <p className="text-grey-dark">
                  {isSameMonth(iqbiDate, new Date()) ? (
                    <span>
                      * {t("page.service-details.date.until", { date: formatDate(i18n, "date", new Date()) })}
                    </span>
                  ) : (
                    <span>
                      *{" "}
                      <DateRange
                        start={startOfMonth(iqbiDate)}
                        end={endOfMonth(iqbiDate)}
                        format="noTime"
                        className="inline"
                      />
                    </span>
                  )}
                </p>
              </>
            )}
          </div>
          {blurry ? <div className="absolute inset-0 bg-white/80" /> : null}
          {isLoadingIqbi ? <LoadingIcon className="absolute inset-0 m-auto size-8" /> : null}
          {noAccessToIqbi && !iqbiVerifyModalOpen ? (
            <div className="absolute inset-0 flex items-center justify-center">
              <div>
                <Button onClick={iqbiVerifyModalHandlers.setTrue}>{t("page.service-details.iqbi.verify-btn")}</Button>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <ConfirmModal
        isOpen={iqbiVerifyModalOpen}
        id="iqbi-address"
        title={t("page.service-details.iqbi.verify.title")}
        isLoading={verifyIqbi.isPending}
        onResolve={() => {
          verifyIqbi.mutate();
          iqbiVerifyModalHandlers.setFalse();
        }}
        onReject={() => iqbiVerifyModalHandlers.setFalse()}
        resolveBtnProps={{
          text: t("page.service-details.iqbi.verify.confirm-btn"),
        }}
        rejectBtnProps={{
          text: t("page.service-details.iqbi.verify.reject-btn"),
        }}
      >
        <div className="flex flex-col gap-4 text-center">
          <p>{t("page.service-details.iqbi.verify.description")}</p>
          <p>
            {sessionUser.address ? (
              <>
                {sessionUser.address.location}
                <br />
                {sessionUser.address?.zipCode} {sessionUser.address?.city}
              </>
            ) : (
              sessionUser.company?.name
            )}
          </p>
        </div>
      </ConfirmModal>
    </>
  );
}

interface MyPupProps {
  id: string;
  partnerProperties: ServicePartnerPageMyPupPropertiesDto;
}

function MyPup({ id, partnerProperties }: MyPupProps) {
  const api = useApi();
  const slug = useSlug();
  const { t } = useTranslation();

  const registerMyPup = useMutation({ mutationFn: () => api.postServicepartnersMypupRegisterV1() });

  useEffect(() => {
    const url = new URL(window.location.href);
    const myPupLoginUrl = url.searchParams.get("com_return_url");

    if (myPupLoginUrl) {
      if (!partnerProperties.isRegistered) {
        registerMyPup.mutate();
      }
      window.open(myPupLoginUrl, "_self");
    }
  }, [partnerProperties.isRegistered, registerMyPup]);

  async function createMyPupUrl() {
    const dashboardRootUrl = await getFromConfig("newDashboardRootUrl");
    const baseUrl = "https://login.mypup.app/LoginCommunity";

    const queryParams = {
      email: encodeURIComponent(partnerProperties.email),
      uuid: encodeURIComponent(partnerProperties.uuid),
      auth: encodeURIComponent(partnerProperties.authKey),
      first: encodeURIComponent(partnerProperties.firstName),
      last: encodeURIComponent(partnerProperties.lastName),
      phone: partnerProperties.phoneNumber ? encodeURIComponent(partnerProperties.phoneNumber) : "",
      pup: partnerProperties.pup ? encodeURIComponent(partnerProperties.pup) : "",
      lang: encodeURIComponent(partnerProperties.language),
      returnUrl: dashboardRootUrl
        ? encodeURIComponent(`${dashboardRootUrl}${routes.servicePartners.details({ slug, id })}`)
        : "",
    };

    const myPupUrl = `${baseUrl}?${Object.entries(queryParams)
      .filter(([, value]) => value)
      .map(([key, value]) => `${key}=${value}`)
      .join("&")}`;
    window.open(myPupUrl, "_blank");
  }

  return (
    <div className="flex flex-col gap-4 rounded-lg bg-white p-4 shadow-md">
      <Headline4>{t("page.service-details.mypup.title")}</Headline4>
      <Button styling="primary" onClick={createMyPupUrl}>
        {t("page.service-details.mypup.access")}
      </Button>
    </div>
  );
}
