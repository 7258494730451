import { InfoIcon } from "components/InfoIcon/InfoIcon";
import { Subtitle2 } from "components/Text/Text";
import { twResolve } from "helpers/tw-resolve";
import React from "react";

export const Card = React.forwardRef(function Card<T extends React.ElementType>(
  props: React.ComponentPropsWithRef<"article"> & { className?: string; as?: T },
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const Component = props.as || "article";

  return (
    <Component
      {...props}
      ref={ref}
      className={twResolve(
        "flex flex-col justify-between gap-2 rounded-lg bg-white p-5 pt-4 shadow-sm",
        props.className,
      )}
    />
  );
});

export function CardHeader({
  title,
  tooltip,
  children,
}: React.PropsWithChildren<{ title: string; tooltip?: string }>): React.ReactNode {
  return (
    <div className="flex w-full flex-col flex-wrap justify-between gap-2 lg:flex-row">
      <div className="flex h-fit items-center gap-2">
        <Subtitle2 as="h3">{title}</Subtitle2>

        {tooltip ? <InfoIcon tooltip={tooltip} /> : null}
      </div>
      {children}
    </div>
  );
}
