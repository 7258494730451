import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import type { UserRoleDto } from "api/types";
import { Anchor } from "components/Anchor/Anchor";
import { BorderlessButton, BorderlessLinkButton, LinkButton } from "components/Button/Button";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Table } from "components/Table/Table";
import { Tooltip } from "components/Tooltip/Tooltip";
import { useSlug } from "hooks/useSlug";
import { useMemo } from "react";
import { Copy as CopyIcon, Edit as EditIcon, Trash2 as DeleteIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

export interface LayoutProps {
  roles: UserRoleDto[];
  deleteRole: (roleId: string) => Promise<unknown>;
}

export function Layout(props: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { deleteRole } = props;
  const { t } = useTranslation();
  const { componentProps, openDeleteModal } = useDeleteModal<string>("role-delete-modal");

  const columns = useMemo(() => {
    function getRoleTypeTranslation(roleType: UserRoleDto["type"]): string {
      switch (roleType) {
        case "resident":
          return t("model.role.type.resident");
        case "projectOwner":
          return t("model.role.type.project-owner");
        case "readOnly":
          return t("model.role.type.readOnly");
        case "maintenance":
          return t("model.role.type.maintenance");
        case "caretaker":
          return t("model.role.type.caretaker");
        default:
          console.error(`Unknown user role type "${roleType}"`);

          return "";
      }
    }

    const helper = createColumnHelper<UserRoleDto>();

    return [
      helper.accessor("name", {
        header: t("model.role.name"),
        cell: (cell) => (
          <Anchor data-testid="role-details-page-link" to={routes.roles.details({ slug, id: cell.row.original.id })}>
            {cell.getValue()}
          </Anchor>
        ),
      }),
      helper.accessor("type", {
        header: t("model.role.type"),
        cell: (cell) => <div className="text-grey-dark">{getRoleTypeTranslation(cell.getValue())}</div>,
      }),
      helper.accessor("id", {
        header: "",
        cell: (cell) => (
          <div className="flex justify-end gap-4 px-2">
            <Tooltip
              tooltip={cell.row.original.locked ? t("model.role.action.edit.is-locked") : t("common.action.edit")}
            >
              <BorderlessLinkButton
                data-testid="role-edit-page-link"
                aria-label={t("common.action.edit")}
                disabled={cell.row.original.locked}
                to={routes.roles.edit({ slug, id: cell.getValue() })}
              >
                <EditIcon size={16} />
              </BorderlessLinkButton>
            </Tooltip>
            <Tooltip tooltip={t("common.action.duplicate")}>
              <BorderlessLinkButton
                data-testid="role-duplicate-page-link"
                aria-label={t("common.action.duplicate")}
                to={routes.roles.create({ slug })}
                state={{ roleId: cell.getValue() }}
              >
                <CopyIcon size={16} />
              </BorderlessLinkButton>
            </Tooltip>
            <Tooltip
              tooltip={cell.row.original.default ? t("model.role.action.delete.is-default") : t("common.action.delete")}
            >
              <BorderlessButton
                data-testid="role-delete-button"
                aria-label={t("common.action.delete")}
                disabled={cell.row.original?.default}
                styling="red"
                onClick={() => {
                  openDeleteModal(cell.getValue());
                }}
              >
                <DeleteIcon size={16} />
              </BorderlessButton>
            </Tooltip>
          </div>
        ),
      }),
    ];
  }, [slug, t, openDeleteModal]);

  const tableInstance = useReactTable<UserRoleDto>({
    columns,
    data: props.roles,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <DocumentPaper
      title={t("page.role.list.title")}
      theme="minimal"
      actions={
        <LinkButton to={routes.roles.create({ slug })} data-testid="role-create-page-link">
          {t("model.role.action.create")}
        </LinkButton>
      }
    >
      <Table table={tableInstance} getRowProps={() => ({ "data-testid": "role-list-item" })} data-testid="role-list" />
      <DeleteModal<string>
        title={t("model.role.action.delete.confirmation")}
        description={t("model.role.action.delete.description")}
        onDelete={deleteRole}
        {...componentProps}
      />
    </DocumentPaper>
  );
}
