import { useInfiniteQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { QUERY_KEYS } from "query-keys";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { ApiResponseType } from "types/api-types";

import type { ChatsListLayoutProps } from "./Layout";

interface ChatListLoaderProps {
  children: (props: ChatsListLayoutProps) => React.ReactNode;
}

const CHATS_PAGE = 10;

export function Loader(props: ChatListLoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const {
    data: chatsData,
    hasNextPage: hasMoreChats,
    fetchNextPage: fetchMoreChats,
    fetchPreviousPage: fetchPreviousChatPage,
    isPending: isLoadingChats,
    isFetchingNextPage: isLoadingMoreChats,
    error: chatsError,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.CHATS_LIST(projectId),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getChatsV1({ Offset: pageParam * CHATS_PAGE, Limit: CHATS_PAGE })
        .then((items) => commonAPIDataSelector(items)),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
    getPreviousPageParam: () => {
      return 0;
    },
  });

  const sessionUser = useSessionUser();
  const chatState = sessionUser.chatEnabled;

  const toggleChats = useMutation({
    mutationFn: (state: boolean) => api.postChatsStateV1({ hideChat: !state }).then((x) => x.data),
    onMutate: (variables) => {
      queryClient.setQueryData<ApiResponseType<"getSelfV2"> | undefined>(QUERY_KEYS.SELF(projectId), (oldResponse) => {
        if (oldResponse) {
          return {
            ...oldResponse,
            data: {
              ...oldResponse.data,
              chatEnabled: variables,
            },
          };
        }
      });
    },
    onSuccess() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SELF(projectId) });
    },
    onError() {
      showFlashToast({ type: "error", title: t("page.chats.toggle.error") });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SELF(projectId) });
    },
  });

  const chats = useMemo(() => chatsData?.pages.flatMap((x) => x.items) ?? [], [chatsData]);

  const refreshChats = () => {
    queryClient.removeQueries({ queryKey: QUERY_KEYS.CHATS_LIST(projectId) });
    void fetchPreviousChatPage();
  };

  const error = chatsError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  return props.children({
    chats,
    hasMoreChats,
    fetchMoreChats,
    refreshChats,
    isLoadingChats,
    isLoadingMoreChats,
    chatState,
    toggleChats: toggleChats.mutateAsync,
  });
}
