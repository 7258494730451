import { Input } from "components/Input/Input";
import { twJoin } from "tailwind-merge";

interface Props {
  id?: string;
  value?: string;
  name?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  "aria-invalid"?: boolean;
  readOnly?: boolean;
}

export function ColorPicker({ value = "", onChange, onBlur, ...props }: Props): React.ReactNode {
  return (
    <div className="flex gap-2">
      <Input
        id={props.id}
        name={props.name}
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
        onBlur={onBlur}
        aria-invalid={props["aria-invalid"]}
        readOnly={props["readOnly"]}
      />
      <input
        className={twJoin(
          "size-9 appearance-none rounded-2px p-0.5 leading-6 hover:ring-grey-darker focus-visible:outline-none focus-visible:ring-grey-darkest disabled:bg-grey-lightest disabled:text-grey-light",
          props["aria-invalid"] ? "ring-2 ring-red-dark" : "ring-1 ring-grey-lighter",
          props["readOnly"] ? "bg-grey-lightest text-grey-dark" : "bg-white",
        )}
        type="color"
        name={props.name}
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
        onBlur={onBlur}
      />
    </div>
  );
}
