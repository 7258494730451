import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { DocumentsFileRequest } from "api/types";
import { isDocumentUploaded } from "components/DocumentInput/useDocumentFile";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { createFormTranslations, toTranslationsRequest, useLanguages } from "helpers/languages";
import { parseErrorMessage } from "helpers/Network/errors";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useBool } from "hooks/useBool";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import type React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "routes";

import type { LayoutFormValues, LayoutProps } from "./Layout";

export interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader(props: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const [isCreating, isCreatingHandlers] = useBool(false);
  const { t } = useTranslation();
  const api = useApi();
  const showFlashToast = useFlashToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { folderId, fileId } = useParams<{ folderId: string; fileId: string }>();
  const isEditMode = fileId != null;
  const { data: languages = [], isFetching: languagesIsFetching, error: languagesError } = useLanguages();
  const {
    data: fileDetails,
    isFetching: fileDetailsIsFetching,
    error: fileDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.DOCUMENTS_FILE_DETAILS(projectId, folderId!, fileId!),
    queryFn: () => api.getDocumentsFileDetailsV1(fileId!),
    select: commonAPIDataSelector,
    enabled: isEditMode,
  });
  const { mutateAsync: uploadDocument } = useMutation({
    mutationFn: (payload: { File: File } | { Link: string }) => api.postFilesDocumentsV1(payload).then((x) => x.data),
  });
  const { mutateAsync: createItem } = useMutation({
    mutationFn: (document: DocumentsFileRequest) => api.postDocumentsFileV1(document),
  });
  const { mutate: editItem, isPending: isEditing } = useMutation({
    mutationFn: (document: DocumentsFileRequest) => api.putDocumentsFileV1(fileId!, document),
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("model.document.file.action.edit.notification.success"),
      });

      navigate(routes.documents.documentsList({ slug, folderId: folderId! }));
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.document.file.action.edit.notification.error"),
      });
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.DOCUMENTS_LIST(projectId, folderId!) });
    },
  });

  async function edit(data: LayoutFormValues) {
    const upload = await uploadDocumentIfNotUploaded(data);

    editItem({
      nameTranslations: toTranslationsRequest(data.nameTranslations),
      parentFolderId: folderId!,
      uploadId: upload.id,
    });
  }

  async function uploadDocumentIfNotUploaded(data: LayoutFormValues) {
    const document = data.documents?.[0];
    if (isDocumentUploaded(document)) {
      return document;
    } else if (document) {
      return await uploadDocument({ File: document.file });
    } else if (data.link) {
      if (data.link === fileDetails?.document?.url) {
        return fileDetails.document;
      } else {
        return await uploadDocument({ Link: data.link });
      }
    } else {
      throw new Error("Missing document/link");
    }
  }

  async function create(data: LayoutFormValues) {
    isCreatingHandlers.setTrue();
    try {
      const upload = await uploadDocumentIfNotUploaded(data);

      await createItem({
        nameTranslations: toTranslationsRequest(data.nameTranslations),
        parentFolderId: folderId!,
        uploadId: upload.id,
      });

      showFlashToast({
        type: "success",
        title: t("model.document.file.action.create.notification.success"),
      });

      navigate(routes.documents.documentsList({ slug, folderId: folderId! }));
    } catch (error) {
      showFlashToast({
        type: "error",
        title: t("model.document.file.action.create.notification.error"),
        description: parseErrorMessage(error),
      });

      isCreatingHandlers.setFalse();
    } finally {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.DOCUMENTS_LIST(projectId, folderId!) });
    }
  }

  const error = fileDetailsError || languagesError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  if (languagesIsFetching || fileDetailsIsFetching) {
    return <FullSizeLoader withPadding />;
  }

  const defaultFormValues: LayoutFormValues = {
    link: fileDetails?.document === undefined || fileDetails.type !== "link" ? "" : fileDetails.document.url,
    nameTranslations: createFormTranslations(languages, fileDetails?.nameTranslations),
    documents: fileDetails?.document === undefined || fileDetails.type === "link" ? [] : [fileDetails.document],
  };

  return (
    <>
      {props.children({
        defaultFormValues,
        languages,
        onSubmit: isEditMode ? edit : create,
        isSubmitting: isCreating || isEditing,
        isEditMode,
      })}
    </>
  );
}
