import type { AssetBookingDto } from "api/types";
import { BorderlessButton, LinkButton } from "components/Button/Button";
import { CardGrid } from "components/CardGrid/CardGrid";
import { LoadingIcon } from "components/Icons/Icons";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { SearchInput } from "components/SearchInput/SearchInput";
import { Capture2, Subtitle2 } from "components/Text/Text";
import { useDebounce } from "hooks/useDebounce";
import { useSlug } from "hooks/useSlug";
import { useDeferredValue, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

import { ReservationCard } from "./components/ReservationsCard";

interface ReservationData {
  items: AssetBookingDto[];
  total?: number;
  isLoading: boolean;
  hasMore?: boolean;
  loadMore: () => void;
  isLoadingMore: boolean;
}

export interface LayoutProps {
  onSearch: (search: string) => void;
  upcoming: ReservationData;
  past: ReservationData;
}

const DEBOUNCE_WAIT = 250;

export function Layout({ onSearch, upcoming, past }: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const deferredSearch = useDeferredValue(useDebounce(search.toLowerCase().trim(), DEBOUNCE_WAIT));

  useEffect(() => {
    onSearch(deferredSearch);
  }, [deferredSearch, onSearch]);

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.bookings.reservations.title")}
      subTitle={t("page.bookings.reservations.subtitle")}
      actions={
        <LinkButton to={routes.bookings.list({ slug })}>
          {t("page.bookings.reservations.button.new-reservation")}
        </LinkButton>
      }
      header={
        <SearchInput
          className="lg:w-96"
          placeholder={t("page.bookings.reservations.search.placeholder")}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      }
    >
      <div className="flex flex-col gap-4">
        <Subtitle2>{t("page.bookings.reservations.section.upcoming")}</Subtitle2>
        {upcoming.isLoading ? (
          <div className="flex w-full justify-center">
            <LoadingIcon className="size-4" />
          </div>
        ) : upcoming.items.length === 0 ? (
          <Capture2>
            {search
              ? t("page.bookings.reservations.search.no-results")
              : t("page.bookings.reservations.no-upcoming-reservations")}
          </Capture2>
        ) : (
          <>
            <CardGrid>
              {upcoming.items.map((reservation) => (
                <ReservationCard key={reservation.id} reservation={reservation} />
              ))}
            </CardGrid>
            {upcoming.isLoadingMore ? (
              <div className="flex w-full justify-center">
                <LoadingIcon className="size-4" />
              </div>
            ) : upcoming.hasMore ? (
              <BorderlessButton className="text-aop-basic-blue" onClick={upcoming.loadMore}>
                {t("page.bookings.reservations.section.show-more")}
              </BorderlessButton>
            ) : null}
          </>
        )}
        <Subtitle2>{t("page.bookings.reservations.section.past")}</Subtitle2>
        {past.isLoading ? (
          <div className="flex w-full justify-center">
            <LoadingIcon className="size-4" />
          </div>
        ) : past.items.length === 0 ? (
          <Capture2>
            {search
              ? t("page.bookings.reservations.search.no-results")
              : t("page.bookings.reservations.no-past-reservations")}
          </Capture2>
        ) : (
          <>
            <CardGrid>
              {past.items.map((reservation) => (
                <ReservationCard key={reservation.id} reservation={reservation} isPast />
              ))}
            </CardGrid>
            {past.isLoadingMore ? (
              <div className="flex w-full justify-center">
                <LoadingIcon className="size-4" />
              </div>
            ) : past.hasMore ? (
              <BorderlessButton className="text-aop-basic-blue" onClick={past.loadMore}>
                {t("page.bookings.reservations.section.show-more")}
              </BorderlessButton>
            ) : null}
          </>
        )}
      </div>
    </DocumentPaper>
  );
}
