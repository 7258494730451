import type { SimpleProjectDto } from "api/types";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormContent, FormSplitContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormHiddenInput } from "components/Form/FormHiddenInput";
import { FormInput } from "components/Form/FormInput";
import { FormMultiSelect } from "components/Form/FormMultiSelect";
import { FormTextArea } from "components/Form/FormTextArea";
import { Capture2, Headline4, Subtitle2 } from "components/Text/Text";
import { createRequiredStringRule } from "helpers/rules";
import { parseAsNumber, sortAlphabetically } from "helpers/util";
import { isValidEmail } from "helpers/validation";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type { AlertType, SaveRequest } from "../Layout";

interface Props {
  defaultFormValues: FormValues;
  onSave: ({ id, type, payload }: { id: string; type: AlertType; payload: SaveRequest }) => void;
  onClose: () => void;
}

export interface FormValues {
  id: string;
  enabled: boolean;
  emailEnabled: boolean;
  type: AlertType;
  name: string;
  email: string;
  projects: SimpleProjectDto[];
  threshold?: string;
  keywords?: string;
}

const MIN_PERCENTAGE = 1;
const MAX_PERCENTAGE = 100;
const MIN_THRESHOLD = 1;

export function AlertModal({ defaultFormValues, onSave, onClose }: Props): React.ReactNode {
  const { t } = useTranslation();
  const { data: projects = [] } = useConnectedProjects();
  const form = useForm<FormValues>({ defaultValues: defaultFormValues });

  function handleClose() {
    form.reset();
    onClose();
  }

  function handleSave() {
    onClose();
    const formValues = form.getValues();
    const payload: SaveRequest = {
      enabled: formValues.enabled,
      enableEmail: formValues.emailEnabled,
      name: formValues.name,
      email: formValues.email,
      projectIds: formValues.projects.map((project) => project.id),
      threshold: parseAsNumber(formValues.threshold),
      keywords: formValues.keywords?.split(","),
    };

    onSave({ id: formValues.id, type: formValues.type, payload });
    form.reset();
  }

  function addAllProjects() {
    form.setValue("projects", projects);
  }

  function clearProjectSelection() {
    form.setValue("projects", []);
  }

  function getAlertTitle() {
    switch (defaultFormValues.type) {
      case "likes":
        return t("page.alerts.modal.alert-type.title.likes");
      case "comments":
        return t("page.alerts.modal.alert-type.title.comments");
      case "keywords_post":
        return t("page.alerts.modal.alert-type.title.post-keywords");
      case "keywords_comments":
        return t("page.alerts.modal.alert-type.title.comment-keywords");
    }
  }

  function getAlertDescription() {
    switch (defaultFormValues.type) {
      case "likes":
        return t("page.alerts.modal.alert-type.description.likes");
      case "comments":
        return t("page.alerts.modal.alert-type.description.comments");
      case "keywords_post":
        return t("page.alerts.modal.alert-type.description.post-keywords");
      case "keywords_comments":
        return t("page.alerts.modal.alert-type.description.comment-keywords");
    }
  }

  return (
    <div className="w-[576px] max-w-full rounded-lg bg-white p-5 shadow-md">
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-4 lg:gap-48">
          <div className="flex flex-col gap-3">
            <Headline4 className="mr-auto">
              {defaultFormValues.id ? t("page.alerts.modal.edit.title") : t("page.alerts.modal.create.title")}
            </Headline4>
            <div className="flex flex-col gap-1">
              <Subtitle2>{getAlertTitle()}</Subtitle2>
              <Capture2>{getAlertDescription()}</Capture2>
            </div>
          </div>
        </div>
        <Form formMethods={form} onSubmit={handleSave}>
          <FormContent maxWidth="xl">
            <FormHiddenInput<FormValues> name="id" />
            <FormHiddenInput<FormValues> name="enabled" />
            <FormHiddenInput<FormValues> name="emailEnabled" />
            <FormHiddenInput<FormValues> name="type" />
            <FormField label={t("page.alerts.modal.form.name.title")} required>
              <FormInput<FormValues>
                name="name"
                placeholder={t("page.alerts.modal.form.name.placeholder")}
                rules={{
                  validate: {
                    required: createRequiredStringRule(t, "page.alerts.modal.form.name.title"),
                  },
                }}
              />
            </FormField>
            <FormField
              label={t("page.alerts.modal.form.email.title")}
              tooltip={t("page.alerts.modal.form.email.tooltip")}
              required
            >
              <FormInput<FormValues, "email">
                name="email"
                placeholder={t("page.alerts.modal.form.email.placeholder")}
                rules={{
                  validate: {
                    required: createRequiredStringRule(t, "page.alerts.modal.form.email.title"),
                    isValid(value) {
                      value = value?.trim();

                      if (!value) {
                        return;
                      }

                      return isValidEmail(value) ? undefined : t("components.form.error.invalid-email-address");
                    },
                  },
                }}
              />
            </FormField>
            <FormSplitContent>
              <FormField label={t("page.alerts.modal.form.projects.title")} required>
                <FormMultiSelect<FormValues, "projects">
                  name="projects"
                  placeholder={t("page.alerts.modal.form.projects.placeholder")}
                  items={projects ? projects : []}
                  sortSelectedItems={sortAlphabetically}
                  keySelector={(x) => x.id}
                  renderOption={(x) => x.name}
                  rules={{
                    required: t("components.form.error.required", {
                      inputName: t("page.alerts.modal.form.projects.title"),
                    }),
                  }}
                />
              </FormField>
              <div className="flex flex-col gap-2">
                <Button className="w-full" styling="primaryFaded" onClick={addAllProjects}>
                  {t("page.alerts.modal.form.projects.add-all")}
                </Button>
                <Button className="w-full" styling="secondaryRed" onClick={clearProjectSelection}>
                  {t("page.alerts.modal.form.projects.clear-all")}
                </Button>
              </div>
            </FormSplitContent>
            {defaultFormValues.type === "likes" && (
              <FormField
                label={t("page.alerts.modal.form.percentage-threshold.title")}
                tooltip={t("page.alerts.modal.form.percentage-threshold.tooltip")}
                required
              >
                <FormInput<FormValues>
                  name="threshold"
                  inputMode="numeric"
                  type="number"
                  placeholder={t("page.alerts.modal.form.percentage-threshold.placeholder")}
                  rules={{
                    required: t("components.form.error.required", {
                      inputName: t("page.alerts.modal.form.percentage-threshold.title"),
                    }),
                    min: {
                      message: t("page.alerts.modal.form.percentage-threshold.rules.min", { count: MIN_PERCENTAGE }),
                      value: MIN_PERCENTAGE,
                    },
                    max: {
                      message: t("page.alerts.modal.form.percentage-threshold.rules.max", { count: MAX_PERCENTAGE }),
                      value: MAX_PERCENTAGE,
                    },
                  }}
                />
              </FormField>
            )}
            {defaultFormValues.type === "comments" && (
              <FormField
                label={t("page.alerts.modal.form.exact-threshold.title")}
                tooltip={t("page.alerts.modal.form.exact-threshold.tooltip")}
                required
              >
                <FormInput<FormValues>
                  name="threshold"
                  inputMode="numeric"
                  type="number"
                  placeholder={t("page.alerts.modal.form.exact-threshold.placeholder")}
                  rules={{
                    required: t("components.form.error.required", {
                      inputName: t("page.alerts.modal.form.exact-threshold.title"),
                    }),
                    min: {
                      message: t("page.alerts.modal.form.exact-threshold.rules.min", { count: MIN_THRESHOLD }),
                      value: MIN_THRESHOLD,
                    },
                  }}
                />
              </FormField>
            )}
            {(defaultFormValues.type === "keywords_post" || defaultFormValues.type === "keywords_comments") && (
              <FormField
                label={t("page.alerts.modal.form.keywords.title")}
                description={t("page.alerts.modal.form.keywords.subtitle")}
                tooltip={t("page.alerts.modal.form.keywords.tooltip")}
                required
              >
                <FormTextArea<FormValues>
                  name="keywords"
                  placeholder={t("page.alerts.modal.form.keywords.placeholder")}
                  rules={{
                    required: t("components.form.error.required", {
                      inputName: t("page.alerts.modal.form.keywords.title"),
                    }),
                  }}
                />
              </FormField>
            )}
          </FormContent>
          <div className="flex justify-end gap-4">
            <Button styling="primaryFaded" onClick={handleClose}>
              {t("common.action.cancel")}
            </Button>
            <Button styling="primary" type="submit" isLoading={false}>
              {t("common.action.save")}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
