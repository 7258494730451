import type { PermissionSelector } from "hooks/usePermission";
import type React from "react";
import { NavLink } from "react-router-dom";

export interface NavigationSubLinkProps {
  to: string;
  permission?: PermissionSelector;
  "data-testid"?: string;
  exact?: boolean;
}

export function NavigationSubLink({
  to,
  children,
  exact,
  ...props
}: React.PropsWithChildren<NavigationSubLinkProps>): React.ReactNode {
  return (
    <NavLink
      end={exact}
      className="flex-1 truncate rounded-lg px-4 py-2 transition-colors hover:bg-aop-basic-blue-lightest focus:outline-none focus-visible:bg-aop-basic-blue-lightest [&.active]:bg-aop-basic-blue-lightest [&.active]:font-semibold [&.active]:text-aop-basic-blue"
      to={to}
      data-testid={props["data-testid"]}
    >
      {children}
    </NavLink>
  );
}
