/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ActiveWidgetsDto {
  activeWidgets: ("ticketRating" | "ticketSolvingTime" | "engagement" | "contribution")[];
}

export interface AddressBuildingDto {
  id: string;
  name: string;
}

export interface AddressCompanyDto {
  id: string;
  locatedAt: string;
}

export interface AddressCreateRequest {
  buildingId: string;
  streetName: string;
  /** @format int32 */
  housenumber: number;
  housenumberSuffix?: string;
  postalCode: string;
  city: string;
  type?: string;
  floor?: string;
}

export interface AddressDto {
  id: string;
  streetName: string;
  houseNumber: string;
  houseNumberSuffix?: string;
  postalCode: string;
  floor?: string;
  city: string;
  building: AddressBuildingDto;
  users: AddressUserDto[];
  typeConstraint?: ConstraintListItemDto;
  locationCode: string;
}

export interface AddressDtoPaginationResultDto {
  items: AddressDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface AddressEditRequest {
  buildingId: string;
  streetName: string;
  /** @format int32 */
  housenumber: number;
  housenumberSuffix?: string;
  postalCode: string;
  city: string;
  floor?: string;
  type?: string;
}

export interface AddressUnitDto {
  id: string;
  /** Not required, only used for safety rail guard. */
  zipcode?: string;
  /** Not required, only used for safety rail guard. */
  houseNumber?: string;
}

export interface AddressUserDto {
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  /** @deprecated */
  avatarUrl?: string;
  avatar?: ImageDto;
  /** @format date-time */
  deletedAt?: string;
  email?: string;
  isAdmin: boolean;
  projectId: string;
  canViewProfile: boolean;
  chatEnabled: boolean;
  phoneNumberHidden: boolean;
  emailHidden: boolean;
  hideHouseNumber: boolean;
  /** @format date-time */
  registeredAt?: string;
}

export interface AdminNotificationDto {
  id: string;
  isRead: boolean;
  message: string;
  type: "unknown" | "message" | "messageComment" | "groupMessage" | "ticket" | "ticketAssignee" | "ticketComment";
  buttonUrl: string;
  isOldDashboard: boolean;
  /** @format date-time */
  sentAt: string;
}

export interface AdminNotificationDtoPaginationResultDto {
  items: AdminNotificationDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface AdminNotificationStatusDto {
  hasUnread: boolean;
  /** @format int32 */
  amount: number;
}

export interface AdminTicketActivityDto {
  id: string;
  /** @format date-time */
  postedAt: string;
  author: UserDto;
  comment?: AdminTicketCommentDto;
  statusChange?: AdminTicketStatusChangeActivityDto;
  assigneeChange?: AdminTicketAssigneeChangeActivityDto;
  rating?: AdminTicketRatingActivityDto;
}

export interface AdminTicketActivityDtoPaginationResultDto {
  items: AdminTicketActivityDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface AdminTicketAddressDto {
  id: string;
  streetName: string;
  houseNumber: string;
  houseNumberSuffix?: string;
  postalCode: string;
  floor?: string;
  city: string;
  building: AddressBuildingDto;
  typeConstraint?: ConstraintListItemDto;
}

export interface AdminTicketAssigneeChangeActivityDto {
  newAssignee: UserDto;
  oldAssignee?: UserDto;
}

export interface AdminTicketCommentDto {
  id: string;
  content?: string;
  internal: boolean;
  images: ImageDto[];
  author: UserDto;
  /** @format date-time */
  postedAt: string;
  /**
   * @deprecated
   * @format date-time
   */
  updatedAt?: string;
  /**
   * @deprecated
   * @format date-time
   */
  deletedAt?: string;
  canEdit: boolean;
  canDelete: boolean;
  languageIsoCode?: string;
  isAutoReply: boolean;
}

export interface AdminTicketCompanyDto {
  id: string;
  name: string;
}

export interface AdminTicketDetailedListItemDto {
  id: string;
  title: string;
  /** @format date-time */
  createdAt: string;
  user: UserDetailsDto;
  assignee?: UserDto;
  address?: AdminTicketAddressDto;
  company?: AdminTicketCompanyDto;
  category: BaseTicketCategoryWithClosedDayInactivityDto;
  status: TicketStatusDto;
  /** @format int32 */
  rating?: number;
  visibility: "sharedWithBuilding" | "sharedWithCommunity" | "private";
  /** @format date-time */
  postedAt: string;
  /** @format date-time */
  lastActivityAt: string;
  lastActivityBy?: UserDto;
  /** @format date-time */
  closedAt?: string;
  closedBy?: UserDto;
  canChangeStatus: boolean;
  canDelete: boolean;
  /** @format date-time */
  remindAt?: string;
  languageIsoCode?: string;
  /** @format int32 */
  activityCount: number;
  hasUnreadActivity: boolean;
  /** @format int32 */
  commentCount: number;
  hasUnreadComment: boolean;
  rems?: RemsStatusDto;
}

export interface AdminTicketDetailedListItemDtoPaginationResultDto {
  items: AdminTicketDetailedListItemDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface AdminTicketDetailsDto {
  id: string;
  title: string;
  /** @format date-time */
  createdAt: string;
  user: UserDetailsDto;
  assignee?: UserDto;
  address?: AdminTicketAddressDto;
  company?: AdminTicketCompanyDto;
  category: BaseTicketCategoryWithClosedDayInactivityDto;
  status: TicketStatusDto;
  /** @format int32 */
  rating?: number;
  visibility: "sharedWithBuilding" | "sharedWithCommunity" | "private";
  /** @format date-time */
  postedAt: string;
  /** @format date-time */
  lastActivityAt: string;
  lastActivityBy?: UserDto;
  /** @format date-time */
  closedAt?: string;
  closedBy?: UserDto;
  canChangeStatus: boolean;
  canDelete: boolean;
  /** @format date-time */
  remindAt?: string;
  languageIsoCode?: string;
  /** @format int32 */
  activityCount: number;
  hasUnreadActivity: boolean;
  /** @format int32 */
  commentCount: number;
  hasUnreadComment: boolean;
  rems?: RemsStatusDto;
  content: string;
  images: ImageDto[];
  createdBy: UserDto;
  possibleAssignees: UserDto[];
  possibleStatuses: TicketStatusDto[];
  canAddPublicComment: boolean;
  canAddInternalNote: boolean;
  canChangeAssignee: boolean;
  canSetReminder: boolean;
  ratingRemark?: string;
  homeDna?: HomeDnaStatusDto;
}

export interface AdminTicketFiltersDto {
  assignees: UserDto[];
  statuses: TicketStatusDto[];
  categories: BaseTicketCategoryDto[];
  addressTypes: string[];
}

export interface AdminTicketListItemDto {
  id: string;
  title: string;
  /** @format date-time */
  createdAt: string;
  user: UserDetailsDto;
  assignee?: UserDto;
  address?: AdminTicketAddressDto;
  company?: AdminTicketCompanyDto;
  category: BaseTicketCategoryWithClosedDayInactivityDto;
  status: TicketStatusDto;
  /** @format int32 */
  rating?: number;
  visibility: "sharedWithBuilding" | "sharedWithCommunity" | "private";
  /** @format date-time */
  postedAt: string;
  /** @format date-time */
  lastActivityAt: string;
  lastActivityBy?: UserDto;
  /** @format date-time */
  closedAt?: string;
  closedBy?: UserDto;
  canChangeStatus: boolean;
  canDelete: boolean;
  /** @format date-time */
  remindAt?: string;
  languageIsoCode?: string;
}

export interface AdminTicketListItemDtoPaginationResultDto {
  items: AdminTicketListItemDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface AdminTicketRatingActivityDto {
  /** @format int32 */
  rating?: number;
  ratingRemark?: string;
}

export interface AdminTicketStatsDto {
  /** @format int32 */
  tickets: number;
}

export interface AdminTicketStatusChangeActivityDto {
  oldStatus: TicketStatusDto;
  newStatus: TicketStatusDto;
}

export interface AdminTicketTabsStatsDto {
  /** @format int32 */
  newTickets: number;
  /** @format int32 */
  inProgressTickets: number;
  /** @format int32 */
  closedTickets: number;
  /** @format int32 */
  reminderTickets: number;
}

export interface AdoptionRateDetailsGraphItemDto {
  /** @format double */
  percentage: number;
  range: MonthlyReportDateRange;
}

export interface AdoptionRateDto {
  /** @format double */
  adoptionRatePercentage: number;
  /** @format int32 */
  totalAddresses: number;
  /** @format int32 */
  totalAddressesInvited: number;
  /** @format int32 */
  invitedAddressesActivated: number;
  /** @format int32 */
  invitedAddressesNotActivated: number;
  /** @format int32 */
  unknownStatusAddresses: number;
  /** @format int32 */
  totalResidents: number;
}

export interface AlertConfigurationsDto {
  postKeywordAlerts: KeywordAlertConfigurationDto[];
  commentKeywordAlerts: KeywordAlertConfigurationDto[];
  numberOfLikesAlerts: ThresholdAlertConfigurationDto[];
  numberOfCommentsAlerts: ThresholdAlertConfigurationDto[];
}

export interface AnswerItem {
  questionId: string;
  /** @format int32 */
  rating?: number;
  yesNo?: boolean;
  text?: string;
  offeredAnswerId?: string;
  ranking?: RankingAnswerItem[];
}

export interface AnswerRequest {
  answers: AnswerItem[];
}

export interface AppNotificationGroupSettingDto {
  groupId: string;
  status: "off" | "newPostsOnly" | "newPostsAndComments";
}

export interface AppNotificationGroupSettingStatusRequest {
  status: "off" | "newPostsOnly" | "newPostsAndComments";
}

export interface AppNotificationSettingDto {
  appEnabled: boolean;
}

export interface AppNotificationSettingRequest {
  appEnabled?: boolean;
}

export interface AppTicketDto {
  id: string;
  title: string;
  content: string;
  images: ImageDto[];
  user: UserDto;
  author: UserDto;
  category: BaseTicketCategoryDto;
  status: TicketStatusDto;
  /** @format date-time */
  postedAt: string;
  /** @format int32 */
  numberOfComments: number;
  visibility: "sharedWithBuilding" | "sharedWithCommunity" | "private";
  locatedAt?: string;
  /** @format date-time */
  lastActivityAt?: string;
  unread: boolean;
  /** @format date-time */
  modifiedAt: string;
  /** @format date-time */
  closedAt?: string;
  isClosed: boolean;
  /** @format int32 */
  rating?: number;
  remark?: string;
  isCollective: boolean;
  canComment: boolean;
  requestOrigin: "app" | "web";
  newComment: boolean;
  languageIsoCode?: string;
}

export interface AppTicketDtoPaginationResultDto {
  items: AppTicketDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

/** Appointment date and time information. */
export interface AppointmentDateTimeInformation {
  /** Delivery time frame details. */
  deliveryTimeFrame: DeliveryTimeFrame;
}

export interface AssetBookingDetailDto {
  id: string;
  /**
   * @format date
   * @example "2024-01-01"
   */
  date: string;
  /**
   * @format time
   * @example "12:00:00"
   */
  startTime: string;
  /**
   * @format time
   * @example "12:00:00"
   */
  endTime: string;
  asset: BookableAssetDto;
  /** @format date-time */
  cancelledAt?: string;
  author?: UserDto;
  bookingReason?: string;
  canEdit: boolean;
  canDelete: boolean;
  /** CanCancel is an alias for CanDelete. */
  canCancel: boolean;
  cancelledBy?: UserDto;
  cancellationReason?: "cancelled" | "assetDeleted" | "assetUnpublished" | "assetUpdated";
  cancellationReasonDescription?: string;
  regulationDocument?: DocumentUploadDto;
}

export interface AssetBookingDto {
  id: string;
  /**
   * @format date
   * @example "2024-01-01"
   */
  date: string;
  /**
   * @format time
   * @example "12:00:00"
   */
  startTime: string;
  /**
   * @format time
   * @example "12:00:00"
   */
  endTime: string;
  asset: BookableAssetDto;
  /** @format date-time */
  cancelledAt?: string;
  author?: UserDto;
  bookingReason?: string;
  canEdit: boolean;
  canDelete: boolean;
  /** CanCancel is an alias for CanDelete. */
  canCancel: boolean;
}

export interface AssetBookingDtoPaginationResultDto {
  items: AssetBookingDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface AssetBookingRequest {
  /**
   * @format date
   * @example "2024-01-01"
   */
  date: string;
  /**
   * @format time
   * @example "12:00:00"
   */
  startTime: string;
  /**
   * @format time
   * @example "12:00:00"
   */
  endTime: string;
  reason?: string;
}

export interface AutomatedSurveyDto {
  id: string;
  /** @format date-time */
  createdAt: string;
  questionGroups: QuestionGroupDto[];
  category: "other" | "feelAtHome";
  title: string;
  description: string;
  isLocked: boolean;
  thankYouMessage: string;
  /** @format int32 */
  weeklyInterval: number;
  /** @format int32 */
  usersSampleSize: number;
  enabledProjects: SimpleProjectDto[];
}

export interface AutomatedSurveyDtoPaginationResultDto {
  items: AutomatedSurveyDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface AutomatedSurveyEditRequest {
  category: "other" | "feelAtHome";
  title: string;
  description: string;
  isLocked: boolean;
  thankYouMessage: string;
  /** @format int32 */
  weeklyInterval: number;
  /** @format int32 */
  usersSampleSize: number;
  enabledProjectsIds: string[];
  id: string;
  questionGroups: QuestionGroupEditRequest[];
}

export interface AutomatedSurveyIntervalResultDto {
  /** @format date-time */
  intervalDate: string;
  /** @format int32 */
  totalAsked: number;
  /** @format int32 */
  totalAnswered: number;
  /** @format double */
  averageScore: number;
}

export interface AutomatedSurveyQueueDetailsDto {
  id: string;
  title: string;
  /** @format int32 */
  numberLeftInQueue: number;
  /** @format int32 */
  numberResponses: number;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  isActive: boolean;
  questionDetails: AutomatedSurveyQueueQuestionDetailsListDto[];
}

export interface AutomatedSurveyQueueDetailsListDto {
  projectId: string;
  projectName: string;
  /** @format int32 */
  totalResponses: number;
  /** @format int32 */
  numberLeftInQueue: number;
  /** @format double */
  feelingAtHomeAverage: number;
  /** @format double */
  communityAverage: number;
  /** @format double */
  serviceAverage: number;
  /** @format double */
  buildingAverage: number;
}

export interface AutomatedSurveyQueueDetailsListDtoPaginationResultDto {
  items: AutomatedSurveyQueueDetailsListDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface AutomatedSurveyQueueDto {
  id: string;
  title: string;
  /** @format int32 */
  numberLeftInQueue: number;
  /** @format int32 */
  numberResponses: number;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  isActive: boolean;
}

export interface AutomatedSurveyQueueDtoPaginationResultDto {
  items: AutomatedSurveyQueueDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface AutomatedSurveyQueueQuestionDetailsDto {
  questionText?: string;
  questionGroupAnswers: QuestionGroupAnswerDtoPaginationResultDto;
}

export interface AutomatedSurveyQueueQuestionDetailsListDto {
  /** @format double */
  averageRating: number;
  ratingGroupedNumber: Record<string, number>;
  latestOpenQuestionResponse?: string;
  questionGroupType: "other" | "community" | "service" | "building" | "feelAtHome";
  ratingQuestionText?: string;
  queueId: string;
  questionGroupId: string;
  openQuestionText?: string;
}

export interface AutomatedSurveyRequest {
  category: "other" | "feelAtHome";
  title: string;
  description: string;
  isLocked: boolean;
  thankYouMessage: string;
  /** @format int32 */
  weeklyInterval: number;
  /** @format int32 */
  usersSampleSize: number;
  enabledProjectsIds: string[];
  questionGroups: QuestionGroupRequest[];
}

export interface BaseTicketCategoryDto {
  id: string;
  name: string;
}

export interface BaseTicketCategoryWithClosedDayInactivityDto {
  id: string;
  name: string;
  /** @format int32 */
  closeAfterInactiveDayAmount: number;
}

export interface BenchmarkFeelingAtHomeDto {
  /** @format double */
  rating: number;
}

export interface BookableAssetCreateRequest {
  translations: BookableAssetTranslationDto[];
  images: string[];
  sizeSpecification?: string;
  /** @format int32 */
  capacitySpecification?: number;
  locationSpecification?: string;
  /** @format double */
  pricePerHourSpecification?: number;
  regulationDocumentId?: string;
  bookableDays: BookableDayDto[];
  notificationEnabled: boolean;
  notificationEmail?: string;
  timeslot: "fifteenMinutes" | "thirtyMinutes" | "oneHour" | "twoHours" | "fourHours" | "allDay";
  canBookSameDay: boolean;
  /** @format int32 */
  maxDaysInAdvance?: number;
  requireBookingReason: boolean;
  /**
   * Defines the number of hours a user can make changes (or cancel) to a booking before the booking starts.
   * @format int32
   */
  hoursForMutations?: number;
  canBookMultipleSlots: boolean;
  /**
   * The date when this bookable asset will be visible.
   * @format date-time
   */
  publishAt?: string;
  /**
   * The date when this bookable asset will be available for booking.
   * @format date-time
   */
  availableFrom?: string;
  /**
   * Defines the date and time when the bookable asset will be unpublished.
   * This also means that users will not be able to book the asset after this date.
   * @format date-time
   */
  unpublishAt?: string;
  enabled: boolean;
  projectConnectionId?: string;
}

export interface BookableAssetDeletedResult {
  /** @format int32 */
  bookingsCancelled: number;
}

export interface BookableAssetDetailDto {
  id: string;
  translations: BookableAssetTranslationDto[];
  name: string;
  description: string;
  images: ImageDto[];
  sizeSpecification?: string;
  /** @format int32 */
  capacitySpecification?: number;
  locationSpecification?: string;
  /** @format double */
  pricePerHourSpecification?: number;
  regulationDocumentId?: string;
  projectConnectionId?: string;
  notificationEnabled: boolean;
  notificationEmail?: string;
  timeslot: "fifteenMinutes" | "thirtyMinutes" | "oneHour" | "twoHours" | "fourHours" | "allDay";
  canBookSameDay: boolean;
  /** @format int32 */
  maxDaysInAdvance?: number;
  requireBookingReason: boolean;
  /** @format int32 */
  hoursForMutations?: number;
  canBookMultipleSlots: boolean;
  /** @format date-time */
  publishAt?: string;
  /** @format date-time */
  unpublishAt?: string;
  /**
   * From when other users can see the asset.
   * @format date-time
   */
  publishedAt?: string;
  /**
   * From when you can book the asset.
   * @format date-time
   */
  availableFrom?: string;
  enabled: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  deletedAt?: string;
  authorId: string;
  author: UserDto;
  canEdit: boolean;
  canDelete: boolean;
  projectConnection?: ProjectConnectionDto;
  regulationDocument?: DocumentUploadDto;
  bookableDays: BookableDayDto[];
  updatedBy?: UserDto;
}

export interface BookableAssetDto {
  id: string;
  translations: BookableAssetTranslationDto[];
  name: string;
  description: string;
  images: ImageDto[];
  sizeSpecification?: string;
  /** @format int32 */
  capacitySpecification?: number;
  locationSpecification?: string;
  /** @format double */
  pricePerHourSpecification?: number;
  regulationDocumentId?: string;
  projectConnectionId?: string;
  notificationEnabled: boolean;
  notificationEmail?: string;
  timeslot: "fifteenMinutes" | "thirtyMinutes" | "oneHour" | "twoHours" | "fourHours" | "allDay";
  canBookSameDay: boolean;
  /** @format int32 */
  maxDaysInAdvance?: number;
  requireBookingReason: boolean;
  /** @format int32 */
  hoursForMutations?: number;
  canBookMultipleSlots: boolean;
  /** @format date-time */
  publishAt?: string;
  /** @format date-time */
  unpublishAt?: string;
  /**
   * From when other users can see the asset.
   * @format date-time
   */
  publishedAt?: string;
  /**
   * From when you can book the asset.
   * @format date-time
   */
  availableFrom?: string;
  enabled: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  deletedAt?: string;
  authorId: string;
  author: UserDto;
  canEdit: boolean;
  canDelete: boolean;
}

export interface BookableAssetDtoPaginationResultDto {
  items: BookableAssetDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface BookableAssetStatisticsDto {
  /** @format int32 */
  futureBookingsCount: number;
  mostRecentBooking?: AssetBookingDto;
}

export interface BookableAssetTranslationDto {
  languageId: "nl" | "en" | "de" | "dutch" | "english" | "german";
  name: string;
  description: string;
}

export interface BookableAssetUpdateBookingsToBeCancelledDto {
  /** @format int32 */
  bookingsToBeCancelled: number;
}

export interface BookableAssetUpdateBookingsToBeCancelledRequest {
  /** @format date-time */
  availableFrom?: string;
  /** @format date-time */
  unpublishAt?: string;
  bookableDays?: BookableDayDto[];
}

export interface BookableAssetUpdateRequest {
  translations: BookableAssetTranslationDto[];
  images: string[];
  sizeSpecification?: string;
  /** @format int32 */
  capacitySpecification?: number;
  locationSpecification?: string;
  /** @format double */
  pricePerHourSpecification?: number;
  regulationDocumentId?: string;
  bookableDays: BookableDayDto[];
  notificationEnabled: boolean;
  notificationEmail?: string;
  timeslot: "fifteenMinutes" | "thirtyMinutes" | "oneHour" | "twoHours" | "fourHours" | "allDay";
  canBookSameDay: boolean;
  /** @format int32 */
  maxDaysInAdvance?: number;
  requireBookingReason: boolean;
  /**
   * Defines the number of hours a user can make changes (or cancel) to a booking before the booking starts.
   * @format int32
   */
  hoursForMutations?: number;
  canBookMultipleSlots: boolean;
  /**
   * The date when this bookable asset will be visible.
   * @format date-time
   */
  publishAt?: string;
  /**
   * The date when this bookable asset will be available for booking.
   * @format date-time
   */
  availableFrom?: string;
  /**
   * Defines the date and time when the bookable asset will be unpublished.
   * This also means that users will not be able to book the asset after this date.
   * @format date-time
   */
  unpublishAt?: string;
  enabled: boolean;
}

export interface BookableDayDto {
  day: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";
  /**
   * @format time
   * @example "12:00:00"
   */
  startTime?: string;
  /**
   * @format time
   * @example "12:00:00"
   */
  endTime?: string;
  allDay: boolean;
  enabled: boolean;
}

export interface BucketLeadTimeMonthlyDto {
  data: MonthlyReportDateRangeBucketLeadTimeItemDto[];
}

export interface BuildingCreateRequest {
  /** @minLength 1 */
  name: string;
}

export interface BuildingDto {
  id: string;
  name: string;
  /** @format int32 */
  addressCount: number;
}

export interface BuildingDtoPaginationResultDto {
  items: BuildingDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface BuildingEditRequest {
  /** @minLength 1 */
  name: string;
}

export interface CanMassMessageDto {
  canMassMessageAnnouncementPost: boolean;
  canMassMessageGeneralPost: boolean;
}

export interface ChatMessage {
  role: "user" | "bot";
  message: string;
}

export interface ChatStateChangeRequest {
  hideChat: boolean;
}

export interface ChatStatusDto {
  /** @format int32 */
  unreadCount: number;
  hasNewMessages: boolean;
}

export interface CommentCreateRequest {
  content?: string;
  imageId?: string;
  parentId?: string;
}

export interface CommentDetailDto {
  id: string;
  content?: string;
  image?: ImageDto;
  author: UserDto;
  /** @format date-time */
  postedAt: string;
  /** @format date-time */
  deletedAt?: string;
  /** @format date-time */
  updatedAt?: string;
  hasLiked: boolean;
  /** @format int32 */
  totalLikesCount: number;
  hasReplied: boolean;
  /** @format int32 */
  totalRepliesCount: number;
  canEdit: boolean;
  canDelete: boolean;
  canLike: boolean;
  canReply: boolean;
  /** @deprecated */
  languageId: string;
  languageIsoCode?: string;
  latestReply?: CommentDto;
  parentId?: string;
  replies: NestedCommentDto[];
}

export interface CommentDto {
  id: string;
  content?: string;
  image?: ImageDto;
  author: UserDto;
  /** @format date-time */
  postedAt: string;
  /** @format date-time */
  deletedAt?: string;
  /** @format date-time */
  updatedAt?: string;
  hasLiked: boolean;
  /** @format int32 */
  totalLikesCount: number;
  hasReplied: boolean;
  /** @format int32 */
  totalRepliesCount: number;
  canEdit: boolean;
  canDelete: boolean;
  canLike: boolean;
  canReply: boolean;
  /** @deprecated */
  languageId: string;
  languageIsoCode?: string;
  latestReply?: CommentDto;
}

export interface CommentDtoPaginationResultDto {
  items: CommentDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface CommentTranslationDto {
  content: string;
}

export interface CommentUpdateRequest {
  content?: string;
  imageId?: string;
}

export interface CommunityFeedAudienceDto {
  interestGroups: CommunityFeedAudienceGroupDto[];
  helpCategories: CommunityFeedAudienceGroupDto[];
  realEstateGroups: CommunityFeedAudienceGroupDto[];
  projectConnections: CommunityFeedAudienceProjectConnectionDto[];
  project?: CommunityFeedAudienceProjectDto;
}

export interface CommunityFeedAudienceGroupDto {
  id: string;
  name: string;
  type: "interest" | "helpCategory" | "realEstate";
  /** @format date-time */
  lastActivityAt?: string;
  isResidentGroup: boolean;
  defaultPostingLevel: "project" | "projectConnection";
  icon:
    | "announcement"
    | "asterisk"
    | "batteryMid"
    | "bellRinging"
    | "bicycle"
    | "bookOpen"
    | "building01"
    | "building02"
    | "car"
    | "carrot"
    | "coffee"
    | "compass"
    | "cursorClick"
    | "dice"
    | "dog"
    | "faceHappy"
    | "factory"
    | "forkAndKnife"
    | "gamingPad"
    | "gift"
    | "handshake"
    | "imageUserCheck"
    | "lightBulb"
    | "mailbox"
    | "map"
    | "markerPin"
    | "medicalSquare"
    | "messageChatSquare"
    | "messageDotsSquare"
    | "musicNote"
    | "network"
    | "package"
    | "plant"
    | "search"
    | "shoppingCart"
    | "sport"
    | "target"
    | "tool"
    | "users"
    | "wifi"
    | "wineGlass";
  image?: ImageDto;
  selectableAudience: CommunityFeedSelectableAudienceDto[];
}

export interface CommunityFeedAudienceProjectConnectionDto {
  id: string;
  name: string;
  /** @format int32 */
  audienceCount: number;
  canCreatePost: boolean;
  canCreateAnnouncement: boolean;
}

export interface CommunityFeedAudienceProjectDto {
  id: string;
  name: string;
  /** @format int32 */
  audienceCount: number;
  canCreatePost: boolean;
  canCreateAnnouncement: boolean;
}

export interface CommunityFeedItemV1Dto {
  type: "message" | "survey" | "poll";
  /** @format date-time */
  updatedAt?: string;
  message?: MessageV1Dto;
  survey?: CommunityFeedSurveyDto;
}

export interface CommunityFeedItemV1DtoPaginationResultDto {
  items: CommunityFeedItemV1Dto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface CommunityFeedSelectableAudienceDto {
  type: "project" | "projectConnection";
  project?: CommunityFeedSelectableAudienceItemDto;
  projectConnection?: CommunityFeedSelectableAudienceItemDto;
}

export interface CommunityFeedSelectableAudienceItemDto {
  id: string;
  name: string;
  /** @format int32 */
  audienceCount: number;
  canCreatePost: boolean;
  canCreateAnnouncement: boolean;
}

export interface CommunityFeedSurveyDto {
  id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  startsAt?: string;
  /** @format date-time */
  endsAt?: string;
  group?: GroupDto;
  title: string;
  description: string;
  canRespond: "yes" | "notAllowed" | "notStarted" | "ended" | "alreadyAnswered";
  createdBy: UserDto;
  isUnread: boolean;
  scope: "project" | "automated";
  /** @format int32 */
  questionCount: number;
}

export interface CommunityGroupCreateRequest {
  nameTranslations: TranslationRequest[];
  descriptionTranslations: TranslationRequest[];
  iconChar?: string;
  audience: ConstraintListItemDto[];
  permissions: UserRoleCommunityGroupPermissionsDto[];
  imageId?: string;
  addAllResidents: boolean;
  postingLevel?: "project" | "projectConnection" | "projectAndProjectConnection";
  icon:
    | "announcement"
    | "asterisk"
    | "batteryMid"
    | "bellRinging"
    | "bicycle"
    | "bookOpen"
    | "building01"
    | "building02"
    | "car"
    | "carrot"
    | "coffee"
    | "compass"
    | "cursorClick"
    | "dice"
    | "dog"
    | "faceHappy"
    | "factory"
    | "forkAndKnife"
    | "gamingPad"
    | "gift"
    | "handshake"
    | "imageUserCheck"
    | "lightBulb"
    | "mailbox"
    | "map"
    | "markerPin"
    | "medicalSquare"
    | "messageChatSquare"
    | "messageDotsSquare"
    | "musicNote"
    | "network"
    | "package"
    | "plant"
    | "search"
    | "shoppingCart"
    | "sport"
    | "target"
    | "tool"
    | "users"
    | "wifi"
    | "wineGlass";
  type: "interest" | "helpCategory" | "realEstate";
}

export interface CommunityGroupDetailsDto {
  id: string;
  type: "interest" | "helpCategory" | "realEstate";
  isResidentGroup: boolean;
  name: string;
  /** @deprecated */
  iconChar?: string;
  description: string;
  isMember: boolean;
  /** @format int32 */
  members: number;
  membersList: UserDtoPaginationResultDto;
  /** @format int32 */
  totalAudience?: number;
  /** @format int32 */
  joinedAudience?: number;
  image?: ImageDto;
  audience?: ConstraintListItemDto[];
  canCreatePost: boolean;
  canCreateAnnouncement: boolean;
  canEdit: boolean;
  canDelete: boolean;
  disabled: boolean;
  projectConnectionGroup?: ProjectConnectionGroupDto;
  systemGroup?: SystemGroupDto;
  postingLevel?: "project" | "projectConnection" | "projectAndProjectConnection";
  icon:
    | "announcement"
    | "asterisk"
    | "batteryMid"
    | "bellRinging"
    | "bicycle"
    | "bookOpen"
    | "building01"
    | "building02"
    | "car"
    | "carrot"
    | "coffee"
    | "compass"
    | "cursorClick"
    | "dice"
    | "dog"
    | "faceHappy"
    | "factory"
    | "forkAndKnife"
    | "gamingPad"
    | "gift"
    | "handshake"
    | "imageUserCheck"
    | "lightBulb"
    | "mailbox"
    | "map"
    | "markerPin"
    | "medicalSquare"
    | "messageChatSquare"
    | "messageDotsSquare"
    | "musicNote"
    | "network"
    | "package"
    | "plant"
    | "search"
    | "shoppingCart"
    | "sport"
    | "target"
    | "tool"
    | "users"
    | "wifi"
    | "wineGlass";
  isOwner: boolean;
  nameTranslations?: TranslationsDto[];
  descriptionTranslations?: TranslationsDto[];
  permissions?: UserRoleCommunityGroupPermissionsDto[];
  notificationSettings: AppNotificationGroupSettingDto;
  canPromote: boolean;
  /**
   * Indicates whether to show the promotion banner to the author.
   * Returns true as long as the group is not promoted or skipped by the author.
   */
  hasPromotionBanner: boolean;
}

export interface CommunityGroupDto {
  id: string;
  type: "interest" | "helpCategory" | "realEstate";
  isResidentGroup: boolean;
  name: string;
  /** @deprecated */
  iconChar?: string;
  description: string;
  isMember: boolean;
  /** @format int32 */
  members: number;
  membersList: UserDtoPaginationResultDto;
  /** @format int32 */
  totalAudience?: number;
  /** @format int32 */
  joinedAudience?: number;
  image?: ImageDto;
  audience?: ConstraintListItemDto[];
  canCreatePost: boolean;
  canCreateAnnouncement: boolean;
  canEdit: boolean;
  canDelete: boolean;
  disabled: boolean;
  projectConnectionGroup?: ProjectConnectionGroupDto;
  systemGroup?: SystemGroupDto;
  postingLevel?: "project" | "projectConnection" | "projectAndProjectConnection";
  icon:
    | "announcement"
    | "asterisk"
    | "batteryMid"
    | "bellRinging"
    | "bicycle"
    | "bookOpen"
    | "building01"
    | "building02"
    | "car"
    | "carrot"
    | "coffee"
    | "compass"
    | "cursorClick"
    | "dice"
    | "dog"
    | "faceHappy"
    | "factory"
    | "forkAndKnife"
    | "gamingPad"
    | "gift"
    | "handshake"
    | "imageUserCheck"
    | "lightBulb"
    | "mailbox"
    | "map"
    | "markerPin"
    | "medicalSquare"
    | "messageChatSquare"
    | "messageDotsSquare"
    | "musicNote"
    | "network"
    | "package"
    | "plant"
    | "search"
    | "shoppingCart"
    | "sport"
    | "target"
    | "tool"
    | "users"
    | "wifi"
    | "wineGlass";
  isOwner: boolean;
}

export interface CommunityGroupDtoPaginationResultDto {
  items: CommunityGroupDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface CommunityGroupEditRequest {
  nameTranslations: TranslationRequest[];
  descriptionTranslations: TranslationRequest[];
  iconChar?: string;
  audience: ConstraintListItemDto[];
  permissions: UserRoleCommunityGroupPermissionsDto[];
  imageId?: string;
  addAllResidents: boolean;
  postingLevel?: "project" | "projectConnection" | "projectAndProjectConnection";
  icon:
    | "announcement"
    | "asterisk"
    | "batteryMid"
    | "bellRinging"
    | "bicycle"
    | "bookOpen"
    | "building01"
    | "building02"
    | "car"
    | "carrot"
    | "coffee"
    | "compass"
    | "cursorClick"
    | "dice"
    | "dog"
    | "faceHappy"
    | "factory"
    | "forkAndKnife"
    | "gamingPad"
    | "gift"
    | "handshake"
    | "imageUserCheck"
    | "lightBulb"
    | "mailbox"
    | "map"
    | "markerPin"
    | "medicalSquare"
    | "messageChatSquare"
    | "messageDotsSquare"
    | "musicNote"
    | "network"
    | "package"
    | "plant"
    | "search"
    | "shoppingCart"
    | "sport"
    | "target"
    | "tool"
    | "users"
    | "wifi"
    | "wineGlass";
}

export interface CompanyAdoptionRateDto {
  /** @format double */
  adoptionRatePercentage: number;
  /** @format int32 */
  totalCompanies: number;
  /** @format int32 */
  totalCompaniesWithUsers: number;
  /** @format int32 */
  totalResidents: number;
}

export interface CompanyDto {
  id: string;
  name: string;
  description: string;
  logoUrl: string;
  logo?: ImageDto;
  backgroundImageUrl?: string;
  backgroundImage?: ImageDto;
  buildingName: string;
  address: AddressCompanyDto;
  /** @format int32 */
  activeUserCount: number;
  /** @format int32 */
  inactiveUserCount?: number;
  locationCode?: string;
  canDelete: boolean;
}

export interface CompanyDtoPaginationResultDto {
  items: CompanyDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface CompanyRequest {
  name: string;
  description?: string;
  logoId?: string;
  backgroundImageId?: string;
  addressId: string;
}

export interface ConnectedProjectDto {
  id: string;
  name: string;
  slug: string;
  logoUrl: string;
  styling: ProjectStylingDto;
  splashScreenLogoUrl: string;
  splashScreenBackgroundUrl: string;
  city: string;
  owner: string;
  maintenance: string;
  userRole: "readOnly" | "resident" | "projectOwner" | "maintenance" | "caretaker";
  unread: boolean;
  projectBaseLanguageId: "nl" | "en" | "de" | "dutch" | "english" | "german";
  customIconId?: "stadstuin" | "bAmsterdam" | "bundleDomijn";
  projectType: "addressBased" | "companyBased";
  backgroundImage?: ImageDto;
  isZiezodan: boolean;
}

export interface ConnectedProjectsDto {
  projects: ConnectedProjectDto[];
}

export interface ConstraintListItemDto {
  id: string;
  key: string;
  type: "company" | "building" | "floor" | "apartmentType";
}

export interface ConstraintListItemDtoPaginationResultDto {
  items: ConstraintListItemDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface ConsumptionResultDto {
  requestedMonth: ConsumptionUsageDto;
  previousMonth?: ConsumptionUsageDto;
  /** @format int32 */
  percentageChange?: number;
  /** @format int32 */
  usageChange?: number;
  unitType: "electricity" | "gas" | "water";
}

export interface ConsumptionUsageDto {
  /** @format double */
  value: number;
}

export interface ConversationDto {
  id: string;
  person: UserDto;
  latestMessage?: ConversationReplyDto;
  /** @format int32 */
  unreadMessages: number;
  isUnread: boolean;
}

export interface ConversationDtoPaginationResultDto {
  items: ConversationDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface ConversationReplyCreateRequest {
  imageId?: string;
  text?: string;
}

export interface ConversationReplyDto {
  id: string;
  conversationId: string;
  image?: ImageDto;
  text?: string;
  /** @format date-time */
  time: string;
  author?: UserDto;
  isUnread: boolean;
}

export interface ConversationReplyDtoPaginationResultDto {
  items: ConversationReplyDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface ConversationsMonthlyDto {
  data: MonthlyReportDateRangeConversationsStartedItemDto[];
}

export interface CopilotPromptConfigurationDto {
  type: "community" | "ticket";
  prompt: string;
  model: "gpt35" | "gpt4" | "gpt4O";
}

export interface CopilotPromptConfigurationsDto {
  configurations: CopilotPromptConfigurationDto[];
}

export interface CopilotRequest {
  userInput: string;
  copilotOptions: ("makeUnderstandable" | "makeShorter" | "makeMorePositive")[];
}

export interface CopilotResponseDto {
  id: string;
  generatedText: string;
  generatedTitle: string;
}

export interface CreatableTicketCategoryDto {
  id: string;
  name: string;
  notificationType: "canBeCollective" | "alwaysPrivate";
  projectHasMultipleBuildings: boolean;
  customIntroText?: string;
  regulations?: DocumentUploadDto;
  requireSignOffRegulations: boolean;
}

export interface CreateEventRequest {
  nameTranslations: TranslationRequest[];
  descriptionTranslations: TranslationRequest[];
  /** @format int32 */
  maxParticipants?: number;
  categoryType: "unknown" | "maintenance" | "social" | "sport";
  imageId?: string;
  /** @format date-time */
  startsAt: string;
  /** @format date-time */
  endsAt: string;
  /** @format date-time */
  publishAt?: string;
  location?: string;
  audience?: ConstraintListItemDto[];
  type: "common" | "announcement";
  projectConnectionId?: string;
}

export interface CreateFastMenuActionItemRequest {
  type:
    | "createInform"
    | "createHelp"
    | "createMarketplace"
    | "createEvent"
    | "createTicket"
    | "createServiceRequest"
    | "openZiezodan"
    | "openExternalUrl";
  selectedLabel?: string;
  translations?: FastMenuActionItemTranslationRequest[];
  url?: string;
  maintainer?: string;
}

export interface CreateOfferedAnswerRequestDto {
  answer: string;
}

export interface CreateQuestionRequestDto {
  question: string;
  type: "multipleChoice" | "yesNo" | "open" | "rating" | "ranking";
  /** @format int32 */
  sorting: number;
  offeredAnswers?: CreateOfferedAnswerRequestDto[];
  useForAnalysis: boolean;
}

/** During meeting of 17-04-2024 we discussed that this list should only have one renter, as there are no VHE's with multiple renters. */
export interface CreateRelationRequest {
  /**
   * The Id which is tracked by the external system. This Id is used to identify the relation. This Id is unique and should never change.
   * An Id is unique to the contract and not to the renter.
   */
  id: string;
  addressUnitDto: AddressUnitDto;
  /** The renters which are part of this relation. */
  renters: RenterDto[];
  /**
   * The date when the relation starts.
   * The StartDate is only used for tracking purposes. The user will be added to the address unit as soon as the relation is processed.
   * @format date-time
   */
  startDate?: string;
}

export interface CreateSurveyRequestDto {
  title: string;
  description: string;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  surveyCompleted: string;
  questions: CreateQuestionRequestDto[];
  groupId?: string;
  type: "survey" | "poll";
  category: "other" | "feelAtHome";
}

export interface CreatedEntityDto {
  id: string;
}

export interface DeleteAssetBookingRequest {
  reason?: string;
}

/** Delivery time frame details. */
export interface DeliveryTimeFrame {
  /**
   * Earliest delivery date.
   * @format date
   * @example "2024-01-01"
   */
  deliveryDateEarliest: string;
  /**
   * Earliest delivery time.
   * @format time
   * @example "12:00:00"
   */
  deliveryTimeEarliest: string;
  /**
   * Latest delivery date.
   * @format date
   * @example "2024-01-01"
   */
  deliveryDateLatest: string;
  /**
   * Latest delivery time.
   * @format time
   * @example "12:00:00"
   */
  deliveryTimeLatest: string;
}

export interface DeviceRegistrationRequest {
  /** APNS token or FCM token */
  deviceToken: string;
  platform: "android" | "iOS";
  /** Our own custom support version. Used to version our push notification payloads. */
  supportVersion: "v1" | "v2";
  /** Some extra info about the device */
  deviceInfo?: string;
}

export interface DocumentDto {
  id: string;
  url: string;
  fileName?: string;
}

export interface DocumentUploadDto {
  id: string;
  previewImage?: ImageDto;
  url: string;
  fileName?: string;
  /** @format int64 */
  fileSize?: number;
}

export interface DocumentsFileDetailsDto {
  id: string;
  name: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt?: string;
  type: "folder" | "image" | "document" | "link";
  document?: DocumentDto;
  isEmpty?: boolean;
  canEdit: boolean;
  canDelete: boolean;
  canMove: boolean;
  section?: DocumentsItemDto;
  /** @format date-time */
  sortOrder: string;
  nameTranslations: TranslationsDto[];
}

export interface DocumentsFileRequest {
  nameTranslations: TranslationRequest[];
  parentFolderId: string;
  uploadId: string;
}

export interface DocumentsFolderDto {
  id: string;
  name: string;
}

export interface DocumentsFolderDetailsDto {
  id: string;
  name: string;
  nameTranslations: TranslationsDto[];
  parentFolderId: string;
}

export interface DocumentsFolderRequest {
  nameTranslations: TranslationRequest[];
  parentFolderId: string;
}

export interface DocumentsItemDto {
  id: string;
  name: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt?: string;
  type: "folder" | "image" | "document" | "link";
  document?: DocumentDto;
  isEmpty?: boolean;
  canEdit: boolean;
  canDelete: boolean;
  canMove: boolean;
  section?: DocumentsItemDto;
  /** @format date-time */
  sortOrder: string;
}

export interface DocumentsItemDtoPaginationResultDto {
  items: DocumentsItemDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface DocumentsListDto {
  id: string;
  ancestors: DocumentsFolderDto[];
  items: DocumentsItemDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
  name: string;
  canAddNew: boolean;
  /** @deprecated */
  canDeleteExisting: boolean;
}

export interface DocumentsRootDto {
  rootFolders: DocumentsRootFolderDto[];
}

export interface DocumentsRootFolderDto {
  id: string;
  name: string;
  description?: string;
  iconChar: string;
  /** @deprecated */
  isEmpty: boolean;
  canAddItem: boolean;
  canDelete: boolean;
}

export interface DocumentsRootFolderDetailsDto {
  id: string;
  name: string;
  nameTranslations: TranslationsDto[];
  descriptionTranslations: TranslationsDto[];
  audience: ConstraintListItemDto[];
  permissions: UserRoleDocumentFolderPermissionsDto[];
  iconChar?: string;
}

export interface DocumentsRootFolderRequest {
  nameTranslations: TranslationRequest[];
  descriptionTranslations?: TranslationRequest[];
  audience: ConstraintListItemDto[];
  permissions: UserRoleDocumentFolderPermissionsDto[];
  iconChar?: string;
}

export interface EditTicketCommentRequest {
  imageId?: string;
  content?: string;
}

export interface EmailChangeDto {
  email: string;
  verificationCode: string;
}

export interface EmailChangeRequestDto {
  email: string;
}

export interface EmailExistsDto {
  exists: boolean;
  userId?: string;
}

export interface EmailExistsRequest {
  email: string;
}

export interface EndRelationRequest {
  /** The Id which is tracked by the external system. This Id is used to identify the relation. */
  id: string;
  /**
   * DateTime in UTC when the relation should end. This is the date when the renter should be removed from the address unit.
   * Default is null, which means the relation will be ended immediately.
   * @format date-time
   */
  endDate?: string;
}

export interface EngagedResidentsMonthlyDto {
  benchmark: MonthlyReportDateRangeProjectEngagementBenchmarkItemDto[];
  data: MonthlyReportDateRangeProjectEngagementItemDto[];
}

export interface EventCategoryDto {
  id: "unknown" | "maintenance" | "social" | "sport";
  name: string;
  iconChar: string;
}

export interface EventCreatedDto {
  id: string;
}

export interface EventDetailsDto {
  id: string;
  /** @format date-time */
  startsAt: string;
  /** @format date-time */
  endsAt: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  deletedAt?: string;
  /** @format date-time */
  publishAt: string;
  /** @format date-time */
  publishedAt?: string;
  image?: ImageDto;
  location?: string;
  category: EventCategoryDto;
  type: "common" | "announcement";
  name: string;
  description: string;
  isSignedUp: boolean;
  /** @format int32 */
  participantCount?: number;
  /** @format int32 */
  maxParticipants?: number;
  organizer: UserDto;
  canEdit: boolean;
  canDelete: boolean;
  canParticipate: boolean;
  projectConnection?: ProjectConnectionDto;
  participants: UserDetailsDto[];
  nameTranslations: TranslationsDto[];
  descriptionTranslations: TranslationsDto[];
  audience: ConstraintListItemDto[];
}

export interface EventDto {
  id: string;
  /** @format date-time */
  startsAt: string;
  /** @format date-time */
  endsAt: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  deletedAt?: string;
  /** @format date-time */
  publishAt: string;
  /** @format date-time */
  publishedAt?: string;
  image?: ImageDto;
  location?: string;
  category: EventCategoryDto;
  type: "common" | "announcement";
  name: string;
  description: string;
  isSignedUp: boolean;
  /** @format int32 */
  participantCount?: number;
  /** @format int32 */
  maxParticipants?: number;
  organizer: UserDto;
  canEdit: boolean;
  canDelete: boolean;
  canParticipate: boolean;
  projectConnection?: ProjectConnectionDto;
  participants: UserDetailsDto[];
}

export interface EventDtoPaginationResultDto {
  items: EventDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface FastMenuActionItemDto {
  id: string;
  /** @format int32 */
  order: number;
  type:
    | "createInform"
    | "createHelp"
    | "createMarketplace"
    | "createEvent"
    | "createTicket"
    | "createServiceRequest"
    | "openZiezodan"
    | "openExternalUrl";
  translationLabel?: string;
  text?: string;
  translations?: FastMenuActionItemTranslationDto[];
  url?: string;
  maintainer?: string;
}

export interface FastMenuActionItemTranslationDto {
  text: string;
  language: "nl" | "en" | "de" | "dutch" | "english" | "german";
}

export interface FastMenuActionItemTranslationRequest {
  languageId: "nl" | "en" | "de" | "dutch" | "english" | "german";
  label: string;
}

export interface FeelingAtHomeDetailsDto {
  /** @format date-time */
  startedAt: string;
  /** @format int32 */
  totalResponseCount?: number;
  /** @format int32 */
  totalAskedCount?: number;
  questions: FeelingAtHomeSurveyQuestionDto[];
}

export interface FeelingAtHomeQuestionResponseDto {
  user: UserDto;
  /** @format date-time */
  answeredAt: string;
  /** @format int32 */
  rating: number;
  answer?: string;
}

export interface FeelingAtHomeSurveyQuestionDto {
  id: string;
  ratingQuestionText: string;
  explanation?: string;
  openQuestionText?: string;
  /** @format double */
  averageRating?: number;
  /** @format int32 */
  responseCount: number;
  category: "other" | "community" | "service" | "building" | "feelAtHome";
  responses: FeelingAtHomeQuestionResponseDto[];
}

export interface FeelingAtHomeWidgetV2Dto {
  totalFeelingAtHomeScore?: TotalFeelingAtHomeScoreDto;
  lastFeelingAtHomeScore?: LastFeelingAtHomeScoreDto;
  benchmarkFeelingAtHomeScore?: BenchmarkFeelingAtHomeDto;
}

export interface GenericAlertTriggeredDto {
  alertId: string;
  alertType: "likeThreshold" | "commentThreshold" | "postKeyword" | "commentKeyword";
  /** @format date-time */
  sentAt: string;
  alertName: string;
  messageId: string;
  projectName: string;
  projectId: string;
  matchedKeywords?: string;
  content: string;
  emailSent: boolean;
}

export interface GenericAlertTriggeredDtoPaginationResultDto {
  items: GenericAlertTriggeredDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface GlobalNotificationSettingDto {
  appEnabled: boolean;
  dashboardEnabled: boolean;
  emailEnabled: boolean;
}

export interface GlobalNotificationSettingRequest {
  appEnabled?: boolean;
  dashboardEnabled?: boolean;
  emailEnabled?: boolean;
}

export interface GroupDto {
  id: string;
  name: string;
  type: "interest" | "helpCategory" | "realEstate";
  isMember: boolean;
}

export interface GroupNotificationSettingDto {
  groupId: string;
  groupName: string;
  type: "interest" | "helpCategory" | "realEstate";
  status: "off" | "newPostsOnly" | "newPostsAndComments";
}

export interface GroupNotificationSettingRequest {
  groupId: string;
  status: "off" | "newPostsOnly" | "newPostsAndComments";
}

export interface GroupRecentActivityDto {
  group: GroupWithImageDto;
  /** @format int32 */
  newPosts: number;
}

export interface GroupWithIconDto {
  id: string;
  name: string;
  type: "interest" | "helpCategory" | "realEstate";
  isMember: boolean;
  iconChar?: string;
  icon:
    | "announcement"
    | "asterisk"
    | "batteryMid"
    | "bellRinging"
    | "bicycle"
    | "bookOpen"
    | "building01"
    | "building02"
    | "car"
    | "carrot"
    | "coffee"
    | "compass"
    | "cursorClick"
    | "dice"
    | "dog"
    | "faceHappy"
    | "factory"
    | "forkAndKnife"
    | "gamingPad"
    | "gift"
    | "handshake"
    | "imageUserCheck"
    | "lightBulb"
    | "mailbox"
    | "map"
    | "markerPin"
    | "medicalSquare"
    | "messageChatSquare"
    | "messageDotsSquare"
    | "musicNote"
    | "network"
    | "package"
    | "plant"
    | "search"
    | "shoppingCart"
    | "sport"
    | "target"
    | "tool"
    | "users"
    | "wifi"
    | "wineGlass";
  groupType: "interest" | "helpCategory" | "realEstate";
}

export interface GroupWithImageDto {
  id: string;
  name: string;
  type: "interest" | "helpCategory" | "realEstate";
  isMember: boolean;
  image?: ImageDto;
}

export interface HeaderImageDto {
  imageUrl: string;
}

export interface HomeDnaProjectConfigurationDto {
  enabled: boolean;
  clientTenant?: string;
  clientSecret?: string;
  buyerGln?: string;
  buyerName?: string;
  contractorGln?: string;
  contractorName?: string;
}

export interface HomeDnaStatusDto {
  state: "notStarted" | "sent" | "failed" | "accepted" | "planned" | "finished";
  errorDetails?: string;
  /** @format date-time */
  plannedFrom?: string;
  /** @format date-time */
  plannedTo?: string;
}

export interface IAuthenticationData {
  auth?: string;
  channel_data?: string;
  shared_secret?: string;
}

export interface ImageDto {
  id: string;
  /** @format uuid */
  publicId: string;
  description?: string;
  url: string;
  blurHash?: string;
}

export interface InfoProjectDto {
  id: string;
  name: string;
  isSameProject: boolean;
}

/** Instruction data that can include ACC, AFH, or GER status. */
export interface InstructionData {
  /** Instruction number. */
  instructionNumber: string;
  /** Status of the instruction. */
  status: "acc" | "afh" | "ger";
  /** Appointment date and time information. */
  appointmentDateTimeInformation?: AppointmentDateTimeInformation;
  /**
   * Date when the task is ready, applicable for GER status.
   * @format date
   * @example "2024-01-01"
   */
  dateReady?: string;
  /** Free text description, applicable for GER status. */
  freeText?: string;
}

export interface IntercomIdentityDto {
  hmac: string;
}

export interface IqbiProjectConfigurationDto {
  enabled: boolean;
  credentials?: string;
  locationUuid?: string;
}

export interface KeywordAlertConfigurationCreateRequest {
  enabled: boolean;
  name: string;
  email?: string;
  projectIds: string[];
  alertNotificationType: "dashboardAndEmail" | "dashboardOnly";
  keywords: string[];
}

export interface KeywordAlertConfigurationDto {
  id: string;
  enabled: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  modifiedAt?: string;
  name: string;
  email: string;
  projects: SimpleProjectDto[];
  alertNotificationType: "dashboardAndEmail" | "dashboardOnly";
  keywords?: string;
}

export interface LanguageDto {
  id: "nl" | "en" | "de" | "dutch" | "english" | "german";
  description: string;
  poEditorCode: string;
}

export interface LastFeelingAtHomeScoreDto {
  /** @format double */
  rating: number;
  /** @format date-time */
  month: string;
}

export interface MassMessageCreatedEntityDto {
  /** @format int32 */
  projectCount: number;
  /** @format int32 */
  addressCount?: number;
  /** @format int32 */
  audienceResidentsCount: number;
  /** @format date-time */
  expectedFinishDateTime?: string;
  /** @format int32 */
  minutesExpectedToFinish: number;
}

export interface MessageCommentLikeDto {
  commentId: string;
  author: UserDto;
  /** @format date-time */
  postedAt: string;
}

export interface MessageCommentLikeDtoPaginationResultDto {
  items: MessageCommentLikeDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface MessageCreateRequest {
  title: string;
  content: string;
  imageId?: string;
  documentIds?: string[];
  groupId?: string;
  projectConnectionId?: string;
  type: "undefined" | "announcement" | "announcementReadOnly";
  isHiddenFromAdmins: boolean;
  /** @format date-time */
  scheduledFor?: string;
  messageLabel?: "askHelp" | "inform" | "sellItem";
  relatedGroupId?: string;
}

export interface MessageCreatedEntityDto {
  id: string;
  /** @format int32 */
  addressCount?: number;
  /** @format int32 */
  unknownAddressCount?: number;
  /** @format int32 */
  audienceResidentsCount?: number;
}

export interface MessageDetailV1Dto {
  id: string;
  title?: string;
  content?: string;
  images: ImageDto[];
  type: "undefined" | "announcement" | "announcementReadOnly";
  /** @format int32 */
  totalLikeCount: number;
  author: UserDto;
  group?: GroupDto;
  projectConnection?: ProjectConnectionDto;
  hasCommented: boolean;
  canEdit: boolean;
  canLike: boolean;
  canComment: boolean;
  canReport: boolean;
  canShare: boolean;
  canArchive: boolean;
  canDelete: boolean;
  isHiddenFromAdmins: boolean;
  isUnread: boolean;
  isLiked: boolean;
  isMuted: boolean;
  hasAdminBeenInformed: boolean;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  reportedAt?: string;
  /** @format date-time */
  lastActivityAt?: string;
  /** @format date-time */
  scheduledAt?: string;
  /** @format date-time */
  postedAt?: string;
  /** @format date-time */
  modifiedAt?: string;
  /** @format date-time */
  deletedAt?: string;
  /** @format date-time */
  archivedAt?: string;
  messageLabel?: "askHelp" | "inform" | "sellItem";
  languageIsoCode: string;
  /** @format int32 */
  totalCommentCount: number;
  /** @format date-time */
  updatedAt?: string;
  likes: MessageLikeDto[];
}

export interface MessageFeedStatsDto {
  /** @format int32 */
  allCount: number;
  /** @format int32 */
  announcementsCount: number;
  /** @format int32 */
  myPostsCount: number;
}

export interface MessageInformDto {
  informedBy: UserDto;
  informed: UserDto;
  /** @format date-time */
  createdAt: string;
}

export interface MessageInformDtoPaginationResultDto {
  items: MessageInformDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface MessageInformRequest {
  /** Extra information on why the user is being informed */
  content: string;
  /** List of user IDs to inform; maximum of 25 users */
  adminsToInform: string[];
}

export interface MessageLabelsStatMonthlyDto {
  data: MonthlyReportDateRangeMessageLabelsItemDto[];
}

export interface MessageLikeDto {
  messageId: string;
  author: UserDto;
  /** @format date-time */
  postedAt: string;
}

export interface MessageLikeDtoPaginationResultDto {
  items: MessageLikeDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface MessageMultipleProjectsCreateRequest {
  title: string;
  content: string;
  imageId?: string;
  documentIds?: string[];
  type: "undefined" | "announcement" | "announcementReadOnly";
  systemGroupId?: string;
  /** @format date-time */
  scheduledFor?: string;
  messageLabel?: "askHelp" | "inform" | "sellItem";
  projectSelected: string[];
}

export interface MessageReportRequest {
  reason:
    | "other"
    | "explicitLanguage"
    | "spam"
    | "sexuallyExplicit"
    | "discrimination"
    | "bullying"
    | "impersonating"
    | "promotingViolence";
  description?: string;
}

export interface MessageSentimentDto {
  /** @format int32 */
  score?: number;
  /** @format int32 */
  outOf: number;
}

export interface MessageStatusChangeRequest {
  newStatus: "archived" | "deleted";
  reason:
    | "undefined"
    | "solvedHelpInsideCommunity"
    | "solvedHelpOutsideCommunity"
    | "solvedProblemIrrelevant"
    | "solvedOther"
    | "marketplaceSoldInsideCommunity"
    | "marketplaceSoldOutsideCommunity"
    | "marketplaceNoInterest"
    | "marketplaceBorrowed"
    | "mistake"
    | "other";
  details?: string;
}

export interface MessageTopicsDto {
  topics: string[];
}

export interface MessageTranslationDto {
  title: string;
  content: string;
}

export interface MessageUpdateRequest {
  title: string;
  documentIds?: string[];
  content: string;
  imageId?: string;
  /** @format date-time */
  scheduledAt?: string;
}

export interface MessageV1Dto {
  id: string;
  title?: string;
  content?: string;
  images: ImageDto[];
  type: "undefined" | "announcement" | "announcementReadOnly";
  /** @format int32 */
  totalLikeCount: number;
  author: UserDto;
  group?: GroupDto;
  projectConnection?: ProjectConnectionDto;
  hasCommented: boolean;
  canEdit: boolean;
  canLike: boolean;
  canComment: boolean;
  canReport: boolean;
  canShare: boolean;
  canArchive: boolean;
  canDelete: boolean;
  isHiddenFromAdmins: boolean;
  isUnread: boolean;
  isLiked: boolean;
  isMuted: boolean;
  hasAdminBeenInformed: boolean;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  reportedAt?: string;
  /** @format date-time */
  lastActivityAt?: string;
  /** @format date-time */
  scheduledAt?: string;
  /** @format date-time */
  postedAt?: string;
  /** @format date-time */
  modifiedAt?: string;
  /** @format date-time */
  deletedAt?: string;
  /** @format date-time */
  archivedAt?: string;
  messageLabel?: "askHelp" | "inform" | "sellItem";
  languageIsoCode: string;
  /** @format int32 */
  totalCommentCount: number;
  /** @format date-time */
  updatedAt?: string;
}

export interface MessageV1DtoPaginationResultDto {
  items: MessageV1Dto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface MonthlyReportDateRange {
  /** @format date-time */
  from: string;
  /** @format date-time */
  to: string;
  /** @format int32 */
  year: number;
  /** @format int32 */
  month: number;
}

export interface MonthlyReportDateRangeBucketLeadTimeItemDto {
  range: MonthlyReportDateRange;
  /** @format int32 */
  bucket1Percentage: number;
  /** @format int32 */
  bucket1TierPercentage: number;
  /** @format int32 */
  bucket1MaxDays: number;
  /** @format int32 */
  bucket2Percentage: number;
  /** @format int32 */
  bucket2TierPercentage: number;
  /** @format int32 */
  bucket2MaxDays: number;
  /** @format int32 */
  bucket3Percentage: number;
  /** @format int32 */
  bucket3TierPercentage: number;
}

export interface MonthlyReportDateRangeConversationsStartedItemDto {
  range: MonthlyReportDateRange;
  /** @format int32 */
  count: number;
}

export interface MonthlyReportDateRangeMessageLabelsItemDto {
  range: MonthlyReportDateRange;
  /** @format int32 */
  askHelpCount: number;
  /** @format int32 */
  informCount: number;
  /** @format int32 */
  sellItemCount: number;
}

export interface MonthlyReportDateRangeProjectEngagementBenchmarkItemDto {
  range: MonthlyReportDateRange;
  /** @format double */
  engagedResidentPercentage: number;
}

export interface MonthlyReportDateRangeProjectEngagementItemDto {
  range: MonthlyReportDateRange;
  /** @format double */
  engagedResidentPercentage: number;
  /** @format int32 */
  postCount: number;
  /** @format int32 */
  commentCount: number;
  /** @format int32 */
  likeCount: number;
  /** @format int32 */
  commentLikeCount: number;
  /** @format int32 */
  collectiveTicketCount: number;
  /** @format int32 */
  eventCount: number;
  /** @format int32 */
  surveyCount: number;
  /** @format int32 */
  pollCount: number;
  /** @format int32 */
  reservationCount: number;
}

export interface MonthlyReportDateRangeTicketsAverageLeadTimeItemDto {
  range: MonthlyReportDateRange;
  /** @format int32 */
  closedTicketCount: number;
  /** @format double */
  averageDaysBeforeTicketClosed?: number;
  categoryName: string;
  categoryId: string;
}

export interface MonthlyReportDateRangeTicketsAverageRatingItemDto {
  range: MonthlyReportDateRange;
  /** @format int32 */
  ratedTicketCount: number;
  /** @format double */
  averageRating?: number;
  categoryName: string;
  categoryId: string;
}

export interface MonthlyReportDateRangeTicketsBenchmarkItemDto {
  range: MonthlyReportDateRange;
  /** @format double */
  averageRating?: number;
  /** @format double */
  benchmarkDaysBeforeTicketClosed?: number;
}

export interface MonthlyReportDateRangeTicketsBenchmarkOverviewItemDto {
  range: MonthlyReportDateRange;
  /** @format double */
  averageTicketRating?: number;
  /** @format int32 */
  ticketClosedByResidentPercentage: number;
}

export interface MonthlyReportDateRangeTicketsOverviewItemDto {
  range: MonthlyReportDateRange;
  /** @format int32 */
  ticketCount: number;
  /** @format int32 */
  ticketClosedCount: number;
  /** @format double */
  averageTicketRating?: number;
  /** @format int32 */
  ticketClosedByResidentPercentage: number;
}

export interface MyPupProjectConfigurationDto {
  enabled: boolean;
  authKey?: string;
}

export interface NestedCommentDto {
  id: string;
  content?: string;
  image?: ImageDto;
  author: UserDto;
  /** @format date-time */
  postedAt: string;
  /** @format date-time */
  deletedAt?: string;
  /** @format date-time */
  updatedAt?: string;
  hasLiked: boolean;
  /** @format int32 */
  totalLikesCount: number;
  hasReplied: boolean;
  /** @format int32 */
  totalRepliesCount: number;
  canEdit: boolean;
  canDelete: boolean;
  canLike: boolean;
  canReply: boolean;
  /** @deprecated */
  languageId: string;
  languageIsoCode?: string;
}

export interface NotificationAuthenticationRequest {
  socket_id: string;
  channel_name: string;
}

export interface NotificationSettingsDto {
  myTicketStatusChange: AppNotificationSettingDto;
  myTicketNewComment: AppNotificationSettingDto;
  ticketAssignToMe: GlobalNotificationSettingDto;
  ticketAssignedCommentNew: GlobalNotificationSettingDto;
  ticketUnassignedUpdates: TicketCategoryNotificationSettingDto[];
  generalMessageNew: GlobalNotificationSettingDto;
  newGeneralMessageInProjectConnection?: AppNotificationSettingDto;
  generalMessageCommentNew: GlobalNotificationSettingDto;
  newGeneralAnnouncement: AppNotificationSettingDto;
  newGeneralAnnouncementComments: AppNotificationSettingDto;
  groups: GroupNotificationSettingDto[];
  eventChange: AppNotificationSettingDto;
  eventReminder: AppNotificationSettingDto;
  chatNew: GlobalNotificationSettingDto;
  surveyNew: AppNotificationSettingDto;
  sendCommunitySummaryEmailInterval: "never" | "everyHour" | "everyThreeHours" | "everyDay" | "everyMonth";
  sendMonthlyReportingEmail: boolean;
  cancelledReservations: AppNotificationSettingDto;
  reservationReminders: AppNotificationSettingDto;
}

export interface NotificationSettingsRequest {
  myTicketStatusChange?: AppNotificationSettingRequest;
  myTicketNewComment?: AppNotificationSettingRequest;
  ticketAssignToMe?: GlobalNotificationSettingRequest;
  ticketAssignedCommentNew?: GlobalNotificationSettingRequest;
  ticketUnassignedUpdates?: TicketCategoryNotificationSettingRequest[];
  generalMessageNew?: GlobalNotificationSettingRequest;
  newGeneralMessageInProjectConnection?: AppNotificationSettingRequest;
  generalMessageCommentNew?: GlobalNotificationSettingRequest;
  newGeneralAnnouncement?: AppNotificationSettingRequest;
  newGeneralAnnouncementComments?: AppNotificationSettingRequest;
  groups?: GroupNotificationSettingRequest[];
  eventChange?: AppNotificationSettingRequest;
  eventReminder?: AppNotificationSettingRequest;
  chatNew?: GlobalNotificationSettingRequest;
  surveyNew?: AppNotificationSettingRequest;
  sendCommunitySummaryEmailInterval?: "never" | "everyHour" | "everyThreeHours" | "everyDay" | "everyMonth";
  sendMonthlyReportingEmail?: boolean;
  cancelledReservations?: AppNotificationSettingRequest;
  reservationReminders?: AppNotificationSettingRequest;
}

export interface OfferedAnswerDto {
  id: string;
  text: string;
  subtitle?: string;
}

export interface OnboardingScreenCreateRequest {
  titleTranslations: TranslationRequest[];
  subtitleTranslations: TranslationRequest[];
  imageId: string;
}

export interface OnboardingScreenDetailsDto {
  id: string;
  title: string;
  subtitle?: string;
  image: ImageDto;
  titleTranslations: TranslationsDto[];
  subtitleTranslations: TranslationsDto[];
}

export interface OnboardingScreenDto {
  id: string;
  title: string;
  subtitle?: string;
  image: ImageDto;
}

export interface OnboardingScreenDtoPaginationResultDto {
  items: OnboardingScreenDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface OnboardingScreenEditRequest {
  titleTranslations: TranslationRequest[];
  subtitleTranslations: TranslationRequest[];
  imageId: string;
}

export interface PartnerCategoriesDto {
  id:
    | "unknown"
    | "areaOfPeople"
    | "maintenance"
    | "realEstate"
    | "servicePartner"
    | "servicePartnerOnSite"
    | "utilities"
    | "safety"
    | "residents";
  name: string;
}

/** Represents a party involved in the transaction. */
export interface Party {
  /** Global Location Number of the party. */
  gln: string;
}

export interface PollAnswerUpdateRequest {
  id: string;
  text: string;
}

export interface PollAnswersResultsDto {
  /** @format int32 */
  totalVoters: number;
  pollResults: PollResultDto[];
}

export interface PollCreateRequest {
  title: string;
  description: string;
  imageId?: string;
  documentIds?: string[];
  hideFromPropertyManager: boolean;
  allowMultipleAnswers: boolean;
  /** @format date-time */
  endsAt: string;
  question: string;
  answers: string[];
  groupId?: string;
  projectConnectionId?: string;
}

export interface PollDto {
  id: string;
  title: string;
  description: string;
  images: ImageDto[];
  /** @format date-time */
  createdAt?: string;
  createdBy: UserDto;
  /** @format date-time */
  communityFeedDate?: string;
  /** @format date-time */
  endsAt?: string;
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  deletedAt?: string;
  group?: GroupDto;
  projectConnection?: ProjectConnectionDto;
  isUnread: boolean;
  canSeeResults: boolean;
  question: string;
  answers: OfferedAnswerDto[];
  allowMultipleAnswers: boolean;
  /**
   * CanRespond states the status of the poll for the current user.
   * e.g. this property returns Ended if the poll has ended, even if the user has already answered.
   */
  canRespond: "yes" | "notAllowed" | "notStarted" | "ended" | "alreadyAnswered";
  canEdit: boolean;
  canDelete: boolean;
  isHiddenFromAdmins: boolean;
  userResponse?: UserPollResponseDto;
  results?: PollResultDto[];
  documents?: DocumentUploadDto[];
}

export interface PollDtoPaginationResult {
  items: PollDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface PollResultDto {
  answer: OfferedAnswerDto;
  /** @format int32 */
  count: number;
  hasWon: boolean;
  voters: UserDto[];
}

export interface PollUpdateRequest {
  title: string;
  description: string;
  documentIds?: string[];
  imageId?: string;
  question: string;
  answers: PollAnswerUpdateRequest[];
}

export interface PortfolioAdminTicketDto {
  id: string;
  project: SimpleProjectDto;
  /** @format date-time */
  createdAt: string;
  title: string;
  locatedAt?: string;
  user: UserDto;
  category: BaseTicketCategoryDto;
  status: TicketStatusDto;
  rems?: RemsStatusDto;
  assignee?: UserDto;
  /** @format date-time */
  remindAt?: string;
  /** @format int32 */
  activityCount: number;
  hasUnreadActivity: boolean;
  visibility: "sharedWithBuilding" | "sharedWithCommunity" | "private";
  /** @format date-time */
  lastActivityAt: string;
  lastActivityBy?: UserDto;
  /** @format date-time */
  closedAt?: string;
  closedBy?: UserDto;
  /** @format int32 */
  rating?: number;
  canChangeStatus: boolean;
  canDelete: boolean;
}

export interface PortfolioAdminTicketDtoPaginationResultDto {
  items: PortfolioAdminTicketDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface PortfolioMinimalTicketDto {
  ticketId: string;
  projectId: string;
}

/** Request model for processing ACC, AFH, or GER status. */
export interface ProcessRequest {
  /** Represents a party involved in the transaction. */
  buyer: Party;
  /** Represents a party involved in the transaction. */
  contractor: Party;
  /** Instruction data that can include ACC, AFH, or GER status. */
  instructionData: InstructionData;
}

export interface ProjectConnectionCreateRequest {
  name: string;
  contentTranslations: TranslationRequest[];
  description?: string;
  backgroundImageId: string;
  projectIds: string[];
}

export interface ProjectConnectionDetailsDto {
  id: string;
  name: string;
  backgroundImage?: ImageDto;
  content: string;
  contentTranslations: TranslationsDto[];
  internalDescription: string;
  projects: ProjectConnectionProjectDto[];
  canJoin: boolean;
  isJoined: boolean;
  groups?: ProjectConnectionGroupDtoPaginationResult;
}

export interface ProjectConnectionDetailsDtoPaginationResultDto {
  items: ProjectConnectionDetailsDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface ProjectConnectionDto {
  id: string;
  name: string;
}

export interface ProjectConnectionEditRequest {
  name: string;
  contentTranslations: TranslationRequest[];
  description?: string;
  backgroundImageId: string;
}

export interface ProjectConnectionGroupCreateRequest {
  type: "interest" | "helpCategory" | "realEstate";
  icon:
    | "announcement"
    | "asterisk"
    | "batteryMid"
    | "bellRinging"
    | "bicycle"
    | "bookOpen"
    | "building01"
    | "building02"
    | "car"
    | "carrot"
    | "coffee"
    | "compass"
    | "cursorClick"
    | "dice"
    | "dog"
    | "faceHappy"
    | "factory"
    | "forkAndKnife"
    | "gamingPad"
    | "gift"
    | "handshake"
    | "imageUserCheck"
    | "lightBulb"
    | "mailbox"
    | "map"
    | "markerPin"
    | "medicalSquare"
    | "messageChatSquare"
    | "messageDotsSquare"
    | "musicNote"
    | "network"
    | "package"
    | "plant"
    | "search"
    | "shoppingCart"
    | "sport"
    | "target"
    | "tool"
    | "users"
    | "wifi"
    | "wineGlass";
  translations: SystemGroupTranslationDto[];
  imageId: string;
  permissions: UserRoleTypeCommunityGroupPermissionsEntityRequest[];
  postingLevel: "project" | "projectConnection" | "projectAndProjectConnection";
  defaultPostingAudience: "project" | "projectConnection";
}

export interface ProjectConnectionGroupDetailDto {
  id: string;
  type: "interest" | "helpCategory" | "realEstate";
  icon:
    | "announcement"
    | "asterisk"
    | "batteryMid"
    | "bellRinging"
    | "bicycle"
    | "bookOpen"
    | "building01"
    | "building02"
    | "car"
    | "carrot"
    | "coffee"
    | "compass"
    | "cursorClick"
    | "dice"
    | "dog"
    | "faceHappy"
    | "factory"
    | "forkAndKnife"
    | "gamingPad"
    | "gift"
    | "handshake"
    | "imageUserCheck"
    | "lightBulb"
    | "mailbox"
    | "map"
    | "markerPin"
    | "medicalSquare"
    | "messageChatSquare"
    | "messageDotsSquare"
    | "musicNote"
    | "network"
    | "package"
    | "plant"
    | "search"
    | "shoppingCart"
    | "sport"
    | "target"
    | "tool"
    | "users"
    | "wifi"
    | "wineGlass";
  translations?: SystemGroupTranslationDto[];
  image: ImageDto;
  postingLevel: "project" | "projectConnection" | "projectAndProjectConnection";
  defaultPostingAudience: "project" | "projectConnection";
  projectConnectionId: string;
  permissions: UserRoleTypeCommunityGroupPermissionsEntityRequest[];
}

export interface ProjectConnectionGroupDto {
  id: string;
  type: "interest" | "helpCategory" | "realEstate";
  icon:
    | "announcement"
    | "asterisk"
    | "batteryMid"
    | "bellRinging"
    | "bicycle"
    | "bookOpen"
    | "building01"
    | "building02"
    | "car"
    | "carrot"
    | "coffee"
    | "compass"
    | "cursorClick"
    | "dice"
    | "dog"
    | "faceHappy"
    | "factory"
    | "forkAndKnife"
    | "gamingPad"
    | "gift"
    | "handshake"
    | "imageUserCheck"
    | "lightBulb"
    | "mailbox"
    | "map"
    | "markerPin"
    | "medicalSquare"
    | "messageChatSquare"
    | "messageDotsSquare"
    | "musicNote"
    | "network"
    | "package"
    | "plant"
    | "search"
    | "shoppingCart"
    | "sport"
    | "target"
    | "tool"
    | "users"
    | "wifi"
    | "wineGlass";
  translations?: SystemGroupTranslationDto[];
  image: ImageDto;
  postingLevel: "project" | "projectConnection" | "projectAndProjectConnection";
  defaultPostingAudience: "project" | "projectConnection";
  projectConnectionId: string;
}

export interface ProjectConnectionGroupDtoPaginationResult {
  items: ProjectConnectionGroupDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface ProjectConnectionGroupDtoPaginationResultDto {
  items: ProjectConnectionGroupDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface ProjectConnectionGroupUpdateRequest {
  type: "interest" | "helpCategory" | "realEstate";
  icon:
    | "announcement"
    | "asterisk"
    | "batteryMid"
    | "bellRinging"
    | "bicycle"
    | "bookOpen"
    | "building01"
    | "building02"
    | "car"
    | "carrot"
    | "coffee"
    | "compass"
    | "cursorClick"
    | "dice"
    | "dog"
    | "faceHappy"
    | "factory"
    | "forkAndKnife"
    | "gamingPad"
    | "gift"
    | "handshake"
    | "imageUserCheck"
    | "lightBulb"
    | "mailbox"
    | "map"
    | "markerPin"
    | "medicalSquare"
    | "messageChatSquare"
    | "messageDotsSquare"
    | "musicNote"
    | "network"
    | "package"
    | "plant"
    | "search"
    | "shoppingCart"
    | "sport"
    | "target"
    | "tool"
    | "users"
    | "wifi"
    | "wineGlass";
  translations: SystemGroupTranslationDto[];
  imageId: string;
  permissions: UserRoleTypeCommunityGroupPermissionsEntityRequest[];
  defaultPostingAudience: "project" | "projectConnection";
}

export interface ProjectConnectionProjectDto {
  id: string;
  name: string;
  baseLanguageId: "unknown" | "dutch" | "english" | "german" | "other";
}

export interface ProjectConnectionUserPrivacySettingsRequest {
  showAvatar?: boolean;
}

export interface ProjectConnectionUserSettingsRequest {
  showConnectionsInCommunityFeed?: boolean;
  showConnectionsInEvents?: boolean;
  showConnectionsInServices?: boolean;
}

export interface ProjectCreateRequest {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  city: string;
  /** @minLength 1 */
  owner: string;
  /** @minLength 1 */
  maintenance: string;
  languageId: "nl" | "en" | "de" | "dutch" | "english" | "german";
  projectType: "addressBased" | "companyBased";
  cloneProjectId?: string;
}

export interface ProjectDetailedDto {
  id: string;
  name: string;
  slug: string;
  city: string;
  owner: string;
  maintenance: string;
  languageId: "nl" | "en" | "de" | "dutch" | "english" | "german";
  type: "addressBased" | "companyBased";
  logo?: ImageDto;
  logoLight?: ImageDto;
  backgroundImage?: ImageDto;
  splashScreenBackground?: ImageDto;
  styling: ProjectStylingDto;
  useBackgroundImageForSplashScreen: boolean;
  selectedSplashScreenLogo: "light" | "dark" | "none";
  isZiezodan: boolean;
}

export interface ProjectEditRequest {
  /** @minLength 1 */
  name: string;
  /** @maxLength 64 */
  slug?: string;
  /** @minLength 1 */
  city: string;
  /** @minLength 1 */
  owner: string;
  /** @minLength 1 */
  maintenance: string;
  projectType: "addressBased" | "companyBased";
}

export interface ProjectOverviewItemDto {
  id: string;
  name: string;
  city: string;
  logoUrl: string;
  logo?: ImageDto;
  backgroundImageUrl?: string;
  backgroundImage?: ImageDto;
  type: "addressBased" | "companyBased";
  /** @format date-time */
  createdAt: string;
  stats?: ProjectStatsDto;
  previousStats?: ProjectStatsDto;
  userRoleType: "readOnly" | "resident" | "projectOwner" | "maintenance" | "caretaker";
}

export interface ProjectOverviewItemDtoPaginationResultDto {
  items: ProjectOverviewItemDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface ProjectStatsDto {
  /** @format date-time */
  from: string;
  /** @format double */
  engagedResidentPercentage?: number;
  /** @format double */
  adoptionPercentage?: number;
  /** @format double */
  averageTicketRating?: number;
  /** @format double */
  feelingAtHomeAverageRatingAllTime?: number;
}

export interface ProjectStylingDto {
  /** Page header background color */
  tintColor: string;
  /** The color of the text on the page header (when the page header goes transparent after scroll?) */
  textLightColor: string;
  /** Button color */
  navigationColor: string;
  /** Main background color */
  backgroundColor: string;
  /** Unread dots color */
  dangerColor: string;
  /** Body text color */
  textDarkColor: string;
  /** Title text color */
  textHeadersColor: string;
  /** The color of the text on the page header (when not scrolled?) */
  pageTitleUnscrolledColor: string;
}

export interface ProjectStylingRequest {
  /**
   * @minLength 1
   * @pattern ^#(?:[0-9a-fA-F]{3}){1,2}$
   */
  color1: string;
  /**
   * @minLength 1
   * @pattern ^#(?:[0-9a-fA-F]{3}){1,2}$
   */
  color2: string;
  /**
   * @minLength 1
   * @pattern ^#(?:[0-9a-fA-F]{3}){1,2}$
   */
  color3: string;
  /**
   * @minLength 1
   * @pattern ^#(?:[0-9a-fA-F]{3}){1,2}$
   */
  pageTitleUnscrolledColor: string;
  /**
   * @minLength 1
   * @pattern ^#(?:[0-9a-fA-F]{3}){1,2}$
   */
  backgroundColor: string;
  imageLogoDarkId?: string;
  imageLogoLightId?: string;
  backgroundImageId?: string;
  splashScreenBackgroundId?: string;
  useBackgroundImageForSplashScreen: boolean;
  selectedSplashScreenLogo: "light" | "dark" | "none";
}

export interface ProjectSummaryDto {
  communitySummary: string;
  /** @format int32 */
  sentimentPercentage: number;
  hashtags: string[];
}

export interface QuestionDto {
  id: string;
  type: "multipleChoice" | "yesNo" | "open" | "rating" | "ranking";
  question: string;
  description?: string;
  /** @format int32 */
  sorting: number;
  answers?: OfferedAnswerDto[];
  responses?: ResponseDto[];
  useForAnalysis: boolean;
}

export interface QuestionGroupAnswerDto {
  userName?: string;
  /** @format int32 */
  rating: number;
  openAnswerText?: string;
  /** @format date-time */
  date?: string;
}

export interface QuestionGroupAnswerDtoPaginationResultDto {
  items: QuestionGroupAnswerDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface QuestionGroupDto {
  id: string;
  ratingQuestionTemplate: string;
  openQuestionTemplate: string;
  explanation: string;
  type: "other" | "community" | "service" | "building" | "feelAtHome";
  /** @format int32 */
  order: number;
}

export interface QuestionGroupEditRequest {
  ratingQuestionTemplate: string;
  openQuestionTemplate?: string;
  explanation?: string;
  type: "other" | "community" | "service" | "building" | "feelAtHome";
  /** @format int32 */
  order: number;
  id?: string;
}

export interface QuestionGroupRequest {
  ratingQuestionTemplate: string;
  openQuestionTemplate?: string;
  explanation?: string;
  type: "other" | "community" | "service" | "building" | "feelAtHome";
  /** @format int32 */
  order: number;
}

export interface QuickReplyMessageCommentDto {
  id: string;
  author: UserDto;
  /** @format date-time */
  postedAt: string;
  content?: string;
  images: ImageDto[];
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  deletedAt?: string;
  languageIsoCode?: string;
  canEdit: boolean;
  canDelete: boolean;
  /** @format int32 */
  likeCount: number;
  hasLiked: boolean;
  canLike: boolean;
  latestReply?: QuickReplyMessageCommentDto;
}

export interface QuickReplyMessageCreateCommentRequest {
  content?: string;
  imageId?: string;
}

export interface QuickReplyMessageCreateTokenRequest {
  messageId: string;
  userId: string;
}

export interface QuickReplyMessageDto {
  id: string;
  projectId: string;
  projectSlug: string;
  group?: GroupDto;
  title: string;
  content: string;
  images: ImageDto[];
  author: UserDto;
  /** @format date-time */
  postedAt: string;
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  deletedAt?: string;
  /** @format date-time */
  archivedAt?: string;
  comments: QuickReplyMessageCommentDto[];
  /** @format int32 */
  commentCount: number;
  /** @format int32 */
  likeCount: number;
  liked: boolean;
  hiddenFromPropertyOwner: boolean;
  canComment: boolean;
  loggedInUser: QuickReplySelfUserDto;
  languageIsoCode: string;
  projectConnection?: ProjectConnectionDto;
  documents?: DocumentUploadDto[];
}

export interface QuickReplyRepairRequestActivityDto {
  /** @format date-time */
  postedAt: string;
  comment?: QuickReplyRepairRequestCommentDto;
  statusChange?: QuickReplyRepairRequestStatusChangeActivityDto;
  assigneeChange?: QuickReplyRepairRequestAssigneeChangeActivityDto;
}

export interface QuickReplyRepairRequestActivityDtoPaginationResultDto {
  items: QuickReplyRepairRequestActivityDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface QuickReplyRepairRequestAssigneeChangeActivityDto {
  id: string;
  author: UserDto;
  /** @format date-time */
  postedAt: string;
  newAssignee: UserDto;
  oldAssignee?: UserDto;
}

export interface QuickReplyRepairRequestAssigneeChangeRequest {
  note?: string;
  assigneeId: string;
}

export interface QuickReplyRepairRequestCategoryDto {
  name: string;
}

export interface QuickReplyRepairRequestCommentDto {
  id: string;
  author: UserDto;
  /** @format date-time */
  postedAt: string;
  content?: string;
  images: ImageDto[];
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  deletedAt?: string;
  languageIsoCode?: string;
  canEdit: boolean;
  canDelete: boolean;
  /** @format int32 */
  likeCount: number;
  hasLiked: boolean;
  canLike: boolean;
  latestReply?: QuickReplyMessageCommentDto;
  internal: boolean;
  isAutoReply: boolean;
}

export interface QuickReplyRepairRequestCreateCommentRequest {
  content?: string;
  imageId?: string;
  internal?: boolean;
}

export interface QuickReplyRepairRequestCreateTokenRequest {
  repairRequestId: string;
  userId: string;
}

export interface QuickReplyRepairRequestDto {
  requestId: string;
  projectId: string;
  projectSlug: string;
  projectName: string;
  category: QuickReplyRepairRequestCategoryDto;
  title: string;
  content: string;
  images: ImageDto[];
  createdBy: UserDto;
  author: UserDetailsDto;
  status: QuickReplyRepairRequestStatusDto;
  possibleStatuses?: QuickReplyRepairRequestStatusDto[];
  assignee?: UserDto;
  possibleAssignees?: UserDto[];
  /** @format date-time */
  postedAt: string;
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  deletedAt?: string;
  /** @deprecated */
  comments: QuickReplyRepairRequestCommentDto[];
  /** @format int32 */
  publicCommentCount: number;
  /**
   * @deprecated
   * @format int32
   */
  commentCount: number;
  loggedInUser: QuickReplySelfUserDto;
  /** @deprecated */
  canComment?: boolean;
  canAddPublicComment: boolean;
  canAddInternalNote: boolean;
  isCollective: boolean;
  languageIsoCode?: string;
  address?: string;
  addressType?: string;
  company?: string;
  rems?: RemsStatusDto;
}

export interface QuickReplyRepairRequestEditCommentRequest {
  content?: string;
  imageId?: string;
}

export interface QuickReplyRepairRequestStatusChangeActivityDto {
  id: string;
  author: UserDto;
  /** @format date-time */
  postedAt: string;
  oldStatus: QuickReplyRepairRequestStatusDto;
  newStatus: QuickReplyRepairRequestStatusDto;
}

export interface QuickReplyRepairRequestStatusChangeDto {
  status: QuickReplyRepairRequestStatusDto;
  possibleStatuses: QuickReplyRepairRequestStatusDto[];
}

export interface QuickReplyRepairRequestStatusChangeRequest {
  silent?: boolean;
  statusId: string;
}

export interface QuickReplyRepairRequestStatusDto {
  id: string;
  description: string;
  isDefault: boolean;
  labelColor: string;
  defaultStatusId: "new" | "inProgress" | "closed" | "rated" | "rejected" | "autoClosed";
}

export interface QuickReplySelfUserDto {
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  /** @deprecated */
  avatarUrl?: string;
  avatar?: ImageDto;
  /** @format date-time */
  deletedAt?: string;
  isAdmin: boolean;
  projectId: string;
  canViewProfile: boolean;
  chatEnabled: boolean;
  phoneNumberHidden: boolean;
  emailHidden: boolean;
  hideHouseNumber: boolean;
  email: string;
  isSuperAdmin: boolean;
  language: LanguageDto;
}

export interface RankingAnswerItem {
  answerId: string;
  /** @format int32 */
  sort: number;
}

export interface RecentActivityDto {
  /** @format int32 */
  newAnnouncements: number;
  /** @format int32 */
  newPostsInGroups: number;
  recentPostsInGroups: GroupRecentActivityDto[];
  surveys: SimpleSurveyDto[];
}

export interface RegistrationRequestV1 {
  /** @format uuid */
  accessToken?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  phoneNumber?: string;
  languageId?: string;
  locationCodeInput?: "unknown" | "manual" | "navigationLinkApp" | "navigationLinkEmail" | "noInput";
}

export interface ReminderStatusDto {
  canBeReminded: boolean;
  recentlyInvited: boolean;
  reminders: SignupReminderDto[];
}

export interface RemsContractDto {
  id: string;
  description: string;
}

export interface RemsProjectConfigurationDto {
  enabled: boolean;
  tenantId: string;
  communityUserContactId: string;
  communityUserRelationId: string;
  defectCode: string;
  complexCode?: string;
}

export interface RemsProjectConfigurationRequest {
  enabled: boolean;
  tenantId?: string;
  communityUserContactId?: string;
  communityUserRelationId?: string;
  defectCode?: string;
  complexCode?: string;
}

export interface RemsStatusDto {
  state: "notStarted" | "pending" | "ready" | "synchronized" | "errorGeneric" | "errorNoUserFound" | "errorNoContracts";
  errorDetails?: string;
  externalId?: string;
  contracts: RemsContractDto[];
}

export interface RemsSyncRequest {
  contractId?: string;
  sendAsCollective: boolean;
}

export interface RenterDto {
  /** The Id which is tracked by the external system. This Id is used to identify the renter. This Id is unique and should never change. */
  id: string;
  /** Required if initial is empty */
  firstName?: string;
  /** Required if FirstName is empty */
  initial?: string;
  lastName: string;
  email: string;
}

export interface ResidentEngagementDto {
  engagements: ResidentTimeEngagementDto[];
}

export interface ResidentEngagementMonthlyDto {
  /** @format double */
  percentage: number;
  range: MonthlyReportDateRange;
}

export interface ResidentGroupCreateRequest {
  name: string;
  description: string;
  imageId: string;
}

export interface ResidentGroupEditRequest {
  name: string;
  description: string;
  imageId: string;
}

export interface ResidentNotificationDto {
  id: string;
  entityId: string;
  /** For special cases where two ids are needed to identify the entity. For example; the booking of an asset. */
  subEntityId?: string;
  content: string;
  notificationType:
    | "event"
    | "repairRequest"
    | "message"
    | "survey"
    | "repairReaction"
    | "poll"
    | "repairStatusChange"
    | "informative"
    | "pollV2"
    | "assetBooking"
    | "adminTicket";
  poster: UserDto;
  /** @format date-time */
  posted: string;
  read: boolean;
}

export interface ResidentNotificationDtoPaginationResultDto {
  items: ResidentNotificationDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface ResidentTimeEngagementDto {
  /** @format double */
  engagementPercentage: number;
  /** @format int32 */
  engagementCount: number;
  type: "lastMonth" | "last7Days" | "last24Hours";
  /** @format date-time */
  from: string;
  /** @format date-time */
  to: string;
}

export interface ResponseDto {
  id: string;
  respondent: UserDto;
  /** @format int32 */
  rating?: number;
  text?: string;
  offeredAnswerId?: string;
  pollAnswer?: boolean;
}

export interface SelfAddressDto {
  id: string;
  location: string;
  city?: string;
  zipCode?: string;
  building?: AddressBuildingDto;
}

export interface SelfCompanyDto {
  id: string;
  name: string;
  building: AddressBuildingDto;
}

export interface SelfConnectedProjectSettingsDto {
  showLastName: boolean;
  showAvatar: boolean;
  hasCommunityBanner: boolean;
  showConnectionInCommunityFeed: boolean;
  showConnectionInEvents: boolean;
  showConnectionInServices: boolean;
}

export interface SelfDeleteRequest {
  reason: "other" | "moving" | "appMalfunctioning";
  details?: string;
}

export interface SelfDto {
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  /** @deprecated */
  avatarUrl?: string;
  avatar?: ImageDto;
  /** @format date-time */
  deletedAt?: string;
  isAdmin: boolean;
  projectId: string;
  canViewProfile: boolean;
  chatEnabled: boolean;
  phoneNumberHidden: boolean;
  emailHidden: boolean;
  hideHouseNumber: boolean;
  email: string;
  isSuperAdmin: boolean;
  isAssignableToTickets: boolean;
  isOnlyAllowedToViewAssignedOrOwnTickets: boolean;
  language: LanguageDto;
  role: SelfUserRoleDto;
  project: SelfProjectDto;
  address?: SelfAddressDto;
  company?: SelfCompanyDto;
  /** @format date-time */
  onboardedAt?: string;
  backgroundImage?: ImageDto;
  splashScreenLogo?: ImageDto;
  splashScreenBackground?: ImageDto;
  biography?: string;
  occupation?: string;
  phoneNumber?: string;
  connectionSettings: SelfConnectedProjectSettingsDto;
  connections: ProjectConnectionDto[];
  newConnectionStatus: "firstConnection" | "newConnection" | "none";
  marketPlaceGroupId?: string;
  marketPlaceGroupName?: string;
  hasNativeRateActive: boolean;
  calendarVisibility: "none" | "events" | "reservations" | "all";
}

export interface SelfLanguageRequest {
  languageId: "nl" | "en" | "de" | "dutch" | "english" | "german";
}

export interface SelfPrivacySettingsRequest {
  hideEmail?: boolean;
  hidePhoneNumber?: boolean;
  enableChat?: boolean;
  hideHouseNumber?: boolean;
}

export interface SelfProjectDto {
  id: string;
  name: string;
  city: string;
  type: "addressBased" | "companyBased";
  logoUrl: string;
  styling: ProjectStylingDto;
  backgroundImage?: ImageDto;
}

export interface SelfRegistrationDetailsDto {
  project?: SimpleProjectDto;
  code: string;
  /** @format uuid */
  guid: string;
}

export interface SelfRequest {
  languageId: "nl" | "en" | "de" | "dutch" | "english" | "german";
  hideEmail?: boolean;
  hidePhonenumber?: boolean;
  isAssignableToTickets?: boolean;
  enableChat?: boolean;
}

export interface SelfUserRoleDto {
  id: string;
  name: string;
  type: "readOnly" | "resident" | "projectOwner" | "maintenance" | "caretaker";
  permissions: SelfUserRolePermissionsDto;
}

export interface SelfUserRolePermissionsDto {
  community: UserRoleCommunityPermissionsDto;
  repairManagement: UserRoleRepairManagementPermissionsDto;
  userManagement: UserRoleUserManagementPermissionsDto;
  addressManagement: UserRoleAddressManagementPermissionsDto;
  servicePartners: UserRoleServicePartnersPermissionsDto;
  surveys: UserRoleSurveysPermissionsDto;
  events: UserRoleEventsPermissionsDto;
  assets: UserRoleAssetsPermissionsDto;
  repairCategories: UserRoleRepairCategoryPermissionsEntityDto[];
  canListDocuments: boolean;
  canListPosts: boolean;
  canCreatePosts: boolean;
  canCreatePostsInGroup: boolean;
  canCreateTicket: boolean;
  canPostInMarketplace: boolean;
}

export interface ServicePartnerCreateRequest {
  translations: ServicePartnerPageTranslationRequest[];
  audience: ConstraintListItemDto[];
  permissions: UserRoleServicePartnerPagePermissionsDto[];
  category:
    | "unknown"
    | "areaOfPeople"
    | "maintenance"
    | "realEstate"
    | "servicePartner"
    | "servicePartnerOnSite"
    | "utilities"
    | "safety"
    | "residents";
  email?: string;
  phoneNumber?: string;
  headerImageId?: string;
  profileImageId: string;
  projectConnectionId?: string;
  type: "general" | "iqbi" | "myPup";
}

export interface ServicePartnerPageDetailsDto {
  id: string;
  category: PartnerCategoriesDto;
  title: string;
  subtitle?: string;
  description?: string;
  email?: string;
  phoneNumber?: string;
  profileImage?: ImageDto;
  headerImage?: ImageDto;
  connection?: ProjectConnectionDto;
  canEdit: boolean;
  canDelete: boolean;
  type: "general" | "iqbi" | "myPup";
  titleTranslations: TranslationsDto[];
  subtitleTranslations: TranslationsDto[];
  descriptionTranslations: TranslationsDto[];
  audience: ConstraintListItemDto[];
  permissions: UserRoleServicePartnerPagePermissionsDto[];
  partnerProperties: ServicePartnerPagePropertiesDto;
}

export interface ServicePartnerPageDto {
  id: string;
  category: PartnerCategoriesDto;
  title: string;
  subtitle?: string;
  description?: string;
  email?: string;
  phoneNumber?: string;
  profileImage?: ImageDto;
  headerImage?: ImageDto;
  connection?: ProjectConnectionDto;
  canEdit: boolean;
  canDelete: boolean;
  type: "general" | "iqbi" | "myPup";
}

export interface ServicePartnerPageIqbiPropertiesDto {
  status: "noConsent" | "waitingForConsentApproval" | "notVerified" | "ready";
}

export interface ServicePartnerPageMyPupPropertiesDto {
  isRegistered: boolean;
  /** @format uuid */
  uuid: string;
  authKey: string;
  pup?: string;
  email: string;
  firstName: string;
  lastName: string;
  language: string;
  phoneNumber?: string;
}

export interface ServicePartnerPagePaginationResultDto {
  items: ServicePartnerPageDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
  /** List of all types that can be created. */
  allowedTypes: ("general" | "iqbi" | "myPup")[];
}

export interface ServicePartnerPagePropertiesDto {
  iqbi?: ServicePartnerPageIqbiPropertiesDto;
  myPup?: ServicePartnerPageMyPupPropertiesDto;
}

export interface ServicePartnerPageTranslationRequest {
  languageId: "nl" | "en" | "de" | "dutch" | "english" | "german";
  title: string;
  subtitle?: string;
  description?: string;
}

export interface ServicePartnerRequest {
  translations: ServicePartnerPageTranslationRequest[];
  audience: ConstraintListItemDto[];
  permissions: UserRoleServicePartnerPagePermissionsDto[];
  category:
    | "unknown"
    | "areaOfPeople"
    | "maintenance"
    | "realEstate"
    | "servicePartner"
    | "servicePartnerOnSite"
    | "utilities"
    | "safety"
    | "residents";
  email?: string;
  phoneNumber?: string;
  headerImageId?: string;
  profileImageId: string;
  projectConnectionId?: string;
}

export interface SetReminderDto {
  /** @format date-time */
  reminderDate: string;
}

export interface SignupReminderDto {
  /** @format date-time */
  lastReminded: string;
  state: "reminderOne" | "reminderTwo" | "reminderThree" | "initialInvite";
}

export interface SimpleProjectDto {
  id: string;
  name: string;
}

export interface SimpleProjectDtoPaginationResultDto {
  items: SimpleProjectDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface SimpleSurveyDto {
  id: string;
  title: string;
}

export interface SurveyDetailsDto {
  id: string;
  type: "survey" | "poll";
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  startsAt?: string;
  /** @format date-time */
  endsAt?: string;
  group?: GroupDto;
  title: string;
  description: string;
  canRespond: "yes" | "notAllowed" | "notStarted" | "ended" | "alreadyAnswered";
  canView: boolean;
  canViewResults: boolean;
  canEdit: boolean;
  canDelete: boolean;
  createdBy?: UserDto;
  /** @format int32 */
  questionCount: number;
  /** @format int32 */
  answerCount?: number;
  isUnread: boolean;
  isPublished: boolean;
  category: "other" | "feelAtHome";
  questions: QuestionDto[];
  completedMessage: string;
  endedMessage: string;
  scope: "project" | "automated";
}

export interface SurveyDto {
  id: string;
  type: "survey" | "poll";
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  startsAt?: string;
  /** @format date-time */
  endsAt?: string;
  group?: GroupDto;
  title: string;
  description: string;
  canRespond: "yes" | "notAllowed" | "notStarted" | "ended" | "alreadyAnswered";
  canView: boolean;
  canViewResults: boolean;
  canEdit: boolean;
  canDelete: boolean;
  createdBy?: UserDto;
  /** @format int32 */
  questionCount: number;
  /** @format int32 */
  answerCount?: number;
  isUnread: boolean;
  isPublished: boolean;
  category: "other" | "feelAtHome";
}

export interface SurveyDtoPaginationResultDto {
  items: SurveyDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface SurveyExportLine {
  id: string;
  question: string;
  answer: string;
  respondent: string;
  address: string;
  building: string;
  addressType: string;
  floor: string;
}

export interface SystemGroupCreateRequest {
  type: "interest" | "helpCategory" | "realEstate";
  icon:
    | "announcement"
    | "asterisk"
    | "batteryMid"
    | "bellRinging"
    | "bicycle"
    | "bookOpen"
    | "building01"
    | "building02"
    | "car"
    | "carrot"
    | "coffee"
    | "compass"
    | "cursorClick"
    | "dice"
    | "dog"
    | "faceHappy"
    | "factory"
    | "forkAndKnife"
    | "gamingPad"
    | "gift"
    | "handshake"
    | "imageUserCheck"
    | "lightBulb"
    | "mailbox"
    | "map"
    | "markerPin"
    | "medicalSquare"
    | "messageChatSquare"
    | "messageDotsSquare"
    | "musicNote"
    | "network"
    | "package"
    | "plant"
    | "search"
    | "shoppingCart"
    | "sport"
    | "target"
    | "tool"
    | "users"
    | "wifi"
    | "wineGlass";
  translations: SystemGroupTranslationDto[];
  imageId: string;
  excludedProjectIds: string[];
  permissions: UserRoleTypeCommunityGroupPermissionsEntityRequest[];
  postingLevel: "project" | "projectConnection" | "projectAndProjectConnection";
  defaultPostingAudience: "project" | "projectConnection";
}

export interface SystemGroupDetailDto {
  id: string;
  type: "interest" | "helpCategory" | "realEstate";
  icon:
    | "announcement"
    | "asterisk"
    | "batteryMid"
    | "bellRinging"
    | "bicycle"
    | "bookOpen"
    | "building01"
    | "building02"
    | "car"
    | "carrot"
    | "coffee"
    | "compass"
    | "cursorClick"
    | "dice"
    | "dog"
    | "faceHappy"
    | "factory"
    | "forkAndKnife"
    | "gamingPad"
    | "gift"
    | "handshake"
    | "imageUserCheck"
    | "lightBulb"
    | "mailbox"
    | "map"
    | "markerPin"
    | "medicalSquare"
    | "messageChatSquare"
    | "messageDotsSquare"
    | "musicNote"
    | "network"
    | "package"
    | "plant"
    | "search"
    | "shoppingCart"
    | "sport"
    | "target"
    | "tool"
    | "users"
    | "wifi"
    | "wineGlass";
  translations?: SystemGroupTranslationDto[];
  image: ImageDto;
  postingLevel: "project" | "projectConnection" | "projectAndProjectConnection";
  defaultPostingAudience: "project" | "projectConnection";
  excludedProjects?: SimpleProjectDto[];
  permissions: UserRoleTypeCommunityGroupPermissionsEntityRequest[];
}

export interface SystemGroupDto {
  id: string;
  type: "interest" | "helpCategory" | "realEstate";
  icon:
    | "announcement"
    | "asterisk"
    | "batteryMid"
    | "bellRinging"
    | "bicycle"
    | "bookOpen"
    | "building01"
    | "building02"
    | "car"
    | "carrot"
    | "coffee"
    | "compass"
    | "cursorClick"
    | "dice"
    | "dog"
    | "faceHappy"
    | "factory"
    | "forkAndKnife"
    | "gamingPad"
    | "gift"
    | "handshake"
    | "imageUserCheck"
    | "lightBulb"
    | "mailbox"
    | "map"
    | "markerPin"
    | "medicalSquare"
    | "messageChatSquare"
    | "messageDotsSquare"
    | "musicNote"
    | "network"
    | "package"
    | "plant"
    | "search"
    | "shoppingCart"
    | "sport"
    | "target"
    | "tool"
    | "users"
    | "wifi"
    | "wineGlass";
  translations?: SystemGroupTranslationDto[];
  image: ImageDto;
  postingLevel: "project" | "projectConnection" | "projectAndProjectConnection";
  defaultPostingAudience: "project" | "projectConnection";
  excludedProjects?: SimpleProjectDto[];
}

export interface SystemGroupDtoPaginationResult {
  items: SystemGroupDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface SystemGroupTranslationDto {
  languageId: "nl" | "en" | "de" | "dutch" | "english" | "german";
  name: string;
  description: string;
}

export interface SystemGroupUpdateRequest {
  type: "interest" | "helpCategory" | "realEstate";
  icon:
    | "announcement"
    | "asterisk"
    | "batteryMid"
    | "bellRinging"
    | "bicycle"
    | "bookOpen"
    | "building01"
    | "building02"
    | "car"
    | "carrot"
    | "coffee"
    | "compass"
    | "cursorClick"
    | "dice"
    | "dog"
    | "faceHappy"
    | "factory"
    | "forkAndKnife"
    | "gamingPad"
    | "gift"
    | "handshake"
    | "imageUserCheck"
    | "lightBulb"
    | "mailbox"
    | "map"
    | "markerPin"
    | "medicalSquare"
    | "messageChatSquare"
    | "messageDotsSquare"
    | "musicNote"
    | "network"
    | "package"
    | "plant"
    | "search"
    | "shoppingCart"
    | "sport"
    | "target"
    | "tool"
    | "users"
    | "wifi"
    | "wineGlass";
  translations: SystemGroupTranslationDto[];
  imageId: string;
  permissions: UserRoleTypeCommunityGroupPermissionsEntityRequest[];
  defaultPostingAudience: "project" | "projectConnection";
}

export interface ThresholdAlertConfigurationCreateRequest {
  enabled: boolean;
  name: string;
  email?: string;
  projectIds: string[];
  alertNotificationType: "dashboardAndEmail" | "dashboardOnly";
  /** @format int32 */
  percentageThreshold: number;
}

export interface ThresholdAlertConfigurationDto {
  id: string;
  enabled: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  modifiedAt?: string;
  name: string;
  email: string;
  projects: SimpleProjectDto[];
  alertNotificationType: "dashboardAndEmail" | "dashboardOnly";
  /** @format int32 */
  percentageThreshold?: number;
}

export interface TicketAssigneeChangeRequest {
  note?: string;
  assigneeId: string;
}

export interface TicketAverageLeadTimeMonthlyDto {
  benchmark: MonthlyReportDateRangeTicketsBenchmarkItemDto[];
  data: MonthlyReportDateRangeTicketsAverageLeadTimeItemDto[];
}

export interface TicketAverageRatingMonthlyDto {
  /** @format double */
  minRating: number;
  /** @format double */
  maxRating: number;
  benchmark: MonthlyReportDateRangeTicketsBenchmarkItemDto[];
  data: MonthlyReportDateRangeTicketsAverageRatingItemDto[];
}

export interface TicketCategoryDetailsDto {
  id: string;
  name: string;
  defaultAssignee?: UserDto;
  disabled: boolean;
  notificationType: "canBeCollective" | "alwaysPrivate";
  audience: ConstraintListItemDto[];
  canDelete: boolean;
  projectHasMultipleBuildings: boolean;
  /** @format int32 */
  closeAfterInactiveDayAmount: number;
  translations: TranslationsDto[];
  permissions: UserRoleRepairCategoryPermissionsDto[];
}

export interface TicketCategoryDto {
  id: string;
  name: string;
  defaultAssignee?: UserDto;
  disabled: boolean;
  notificationType: "canBeCollective" | "alwaysPrivate";
  audience: ConstraintListItemDto[];
  canDelete: boolean;
  projectHasMultipleBuildings: boolean;
}

export interface TicketCategoryDtoPaginationResultDto {
  items: TicketCategoryDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface TicketCategoryNotificationSettingDto {
  ticketCategoryId: string;
  ticketCategoryName: string;
  dashboardEnabled: boolean;
  emailEnabled: boolean;
  appEnabled: boolean;
}

export interface TicketCategoryNotificationSettingRequest {
  ticketCategoryId: string;
  dashboardEnabled?: boolean;
  emailEnabled?: boolean;
  appEnabled?: boolean;
}

export interface TicketCategoryRequest {
  translations: TranslationRequest[];
  audience?: ConstraintListItemDto[];
  defaultAssigneeId?: string;
  disabled: boolean;
  /** @format int32 */
  closeAfterInactiveDayAmount?: number;
  notificationType: "canBeCollective" | "alwaysPrivate";
  permissions: UserRoleRepairCategoryPermissionsDto[];
}

export interface TicketCommentDto {
  id: string;
  /** @format int32 */
  ticketId: number;
  content: string;
  image?: ImageDto;
  author: UserDto;
  /** @format date-time */
  postedAt: string;
  /**
   * @deprecated
   * @format date-time
   */
  modifiedAt?: string;
  /**
   * @deprecated
   * @format date-time
   */
  deletedAt?: string;
  languageIsoCode?: string;
  isAutoReply: boolean;
}

export interface TicketCommentDtoPaginationResultDto {
  items: TicketCommentDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface TicketCommentRequest {
  imageId?: string;
  content?: string;
  accessType?: "public" | "internal";
}

export interface TicketCommentTranslationDto {
  content?: string;
}

export interface TicketFiltersDto {
  assignees: UserDto[];
  statuses: TicketStatusDto[];
  categories: BaseTicketCategoryDto[];
}

export interface TicketOverviewDto {
  benchmark: MonthlyReportDateRangeTicketsBenchmarkOverviewItemDto[];
  data: MonthlyReportDateRangeTicketsOverviewItemDto[];
}

export interface TicketRateRequest {
  remark: string;
  /** @format int32 */
  rating?: number;
  /** @format date-time */
  closedAt?: string;
}

export interface TicketRequest {
  /** @minLength 1 */
  categoryId: string;
  /** @minLength 1 */
  title: string;
  /** @minLength 1 */
  content: string;
  reporterId?: string;
  visibility?: "sharedWithBuilding" | "sharedWithCommunity" | "private";
  assigneeId?: string;
  imageIds?: string[];
  requestOrigin?: "app" | "web";
}

export interface TicketResponseSuggestionDto {
  ticketId: string;
  response: string;
}

export interface TicketStatsDto {
  /** @format int32 */
  tickets: number;
}

export interface TicketStatusChangeRequest {
  silent?: boolean;
  statusId: string;
}

export interface TicketStatusDetailsDto {
  id: string;
  type: "new" | "inProgress" | "closed" | "rated" | "rejected" | "autoClosed";
  name: string;
  color: string;
  nameTranslations: TranslationsDto[];
  isDefault: boolean;
  /** @format int32 */
  notificationIntervalInHours?: number;
  /** @format int32 */
  timesUsed: number;
}

export interface TicketStatusDetailsDtoPaginationResultDto {
  items: TicketStatusDetailsDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface TicketStatusDto {
  id: string;
  type: "new" | "inProgress" | "closed" | "rated" | "rejected" | "autoClosed";
  name: string;
  color: string;
}

export interface TicketStatusRequest {
  /**
   * @minLength 1
   * @pattern ^#(?:[0-9a-fA-F]{3}){1,2}$
   */
  color: string;
  nameTranslations: TranslationRequest[];
  type: "new" | "inProgress" | "closed" | "rated" | "rejected" | "autoClosed";
  isDefault: boolean;
  /** @format int32 */
  notificationIntervalInHours?: number;
}

export interface TicketTabsStatsDto {
  /** @format int32 */
  openTickets: number;
  /** @format int32 */
  closedTickets: number;
}

export interface TicketTranslationDto {
  title?: string;
  content?: string;
}

export interface TotalFeelingAtHomeScoreDto {
  /** @format double */
  rating: number;
}

export interface TranslateRequest {
  text: string;
  languages: ("nl" | "en" | "de" | "dutch" | "english" | "german")[];
}

export interface TranslateResult {
  text: string;
  languages: "nl" | "en" | "de" | "dutch" | "english" | "german";
}

export interface TranslationRequest {
  languageId: "nl" | "en" | "de" | "dutch" | "english" | "german";
  value: string;
}

export interface TranslationsDto {
  languageId: "nl" | "en" | "de" | "dutch" | "english" | "german";
  value: string;
}

export interface UnreadStatusDto {
  /** @format int32 */
  unreadChats: number;
  /** @format int32 */
  unreadMessages: number;
  /** @format int32 */
  unreadNotifications: number;
  /** @format int32 */
  unseenNotifications: number;
}

export interface UpdateEventRequest {
  nameTranslations: TranslationRequest[];
  descriptionTranslations: TranslationRequest[];
  /** @format int32 */
  maxParticipants?: number;
  categoryType: "unknown" | "maintenance" | "social" | "sport";
  imageId?: string;
  /** @format date-time */
  startsAt: string;
  /** @format date-time */
  endsAt: string;
  /** @format date-time */
  publishAt?: string;
  location?: string;
  audience?: ConstraintListItemDto[];
}

export interface UpdateFastMenuActionItemRequest {
  type:
    | "createInform"
    | "createHelp"
    | "createMarketplace"
    | "createEvent"
    | "createTicket"
    | "createServiceRequest"
    | "openZiezodan"
    | "openExternalUrl";
  selectedLabel?: string;
  translations?: FastMenuActionItemTranslationRequest[];
  url?: string;
  maintainer?: string;
}

export interface UpdateOfferedAnswerRequestDto {
  answer: string;
  id?: string;
}

export interface UpdateQuestionRequestDto {
  question: string;
  type: "multipleChoice" | "yesNo" | "open" | "rating" | "ranking";
  /** @format int32 */
  sorting: number;
  offeredAnswers?: UpdateOfferedAnswerRequestDto[];
  id?: string;
}

export interface UpdateSurveyRequestDto {
  title: string;
  description: string;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  surveyCompleted: string;
  questions: UpdateQuestionRequestDto[];
  groupId?: string;
}

export interface UserCreateRequest {
  /**
   * @format email
   * @minLength 1
   */
  email: string;
  /** @minLength 1 */
  firstName: string;
  prefix?: string;
  /** @minLength 1 */
  lastName: string;
  language: "nl" | "en" | "de" | "dutch" | "english" | "german";
  occupation?: string;
  addressId?: string;
  companyId?: string;
  roleId?: string;
  isSuperAdmin?: boolean;
  mobileNumber?: string;
  avatarId?: string;
  /** @format date-time */
  deactivateAfter?: string;
  /** Only usable for super admins */
  hideChat?: boolean;
  /** Only usable for super admins */
  emailSignOffUserId?: string;
  isOnlyAllowedOwnRepairRequests?: boolean;
  onboardingCompleted?: boolean;
  mfaMethod?: "none" | "email";
  relationInformation?: UserRelationCreateRequest;
}

export interface UserDeletionDateRequest {
  /** @format date-time */
  deleteAt?: string;
}

export interface UserDetailsDto {
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  /** @deprecated */
  avatarUrl?: string;
  avatar?: ImageDto;
  /** @format date-time */
  deletedAt?: string;
  email?: string;
  isAdmin: boolean;
  projectId: string;
  canViewProfile: boolean;
  chatEnabled: boolean;
  phoneNumberHidden: boolean;
  emailHidden: boolean;
  hideHouseNumber: boolean;
  roleId: string;
  locatedAt?: string;
  phoneNumber?: string;
  backgroundImage?: ImageDto;
  biography?: string;
  occupation?: string;
  isAssignableToTickets: boolean;
  isOnlyAllowedOwnTickets: boolean;
  canBeDeleted: boolean;
  canBeEdited: boolean;
  /** @format date-time */
  registeredAt?: string;
  deletedByUserId?: string;
  languageId: "nl" | "en" | "de" | "dutch" | "english" | "german";
  isSuperAdmin: boolean;
}

export interface UserDetailsDtoPaginationResultDto {
  items: UserDetailsDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface UserDetailsWithAddressDto {
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  /** @deprecated */
  avatarUrl?: string;
  avatar?: ImageDto;
  /** @format date-time */
  deletedAt?: string;
  email?: string;
  isAdmin: boolean;
  projectId: string;
  canViewProfile: boolean;
  chatEnabled: boolean;
  phoneNumberHidden: boolean;
  emailHidden: boolean;
  hideHouseNumber: boolean;
  roleId: string;
  locatedAt?: string;
  phoneNumber?: string;
  backgroundImage?: ImageDto;
  biography?: string;
  occupation?: string;
  isAssignableToTickets: boolean;
  isOnlyAllowedOwnTickets: boolean;
  canBeDeleted: boolean;
  canBeEdited: boolean;
  /** @format date-time */
  registeredAt?: string;
  deletedByUserId?: string;
  languageId: "nl" | "en" | "de" | "dutch" | "english" | "german";
  isSuperAdmin: boolean;
  addressId: string;
  companyId?: string;
  addressTags: ConstraintListItemDto[];
  /** @format date-time */
  createdAt?: string;
}

export interface UserDetailsWithAddressDtoPaginationResultDto {
  items: UserDetailsWithAddressDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface UserDetailsWithAddressInterestsAndHelpDto {
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  /** @deprecated */
  avatarUrl?: string;
  avatar?: ImageDto;
  /** @format date-time */
  deletedAt?: string;
  email?: string;
  isAdmin: boolean;
  projectId: string;
  canViewProfile: boolean;
  chatEnabled: boolean;
  phoneNumberHidden: boolean;
  emailHidden: boolean;
  hideHouseNumber: boolean;
  roleId: string;
  locatedAt?: string;
  phoneNumber?: string;
  backgroundImage?: ImageDto;
  biography?: string;
  occupation?: string;
  isAssignableToTickets: boolean;
  isOnlyAllowedOwnTickets: boolean;
  canBeDeleted: boolean;
  canBeEdited: boolean;
  /** @format date-time */
  registeredAt?: string;
  deletedByUserId?: string;
  languageId: "nl" | "en" | "de" | "dutch" | "english" | "german";
  isSuperAdmin: boolean;
  addressId: string;
  companyId?: string;
  addressTags: ConstraintListItemDto[];
  /** @format date-time */
  createdAt?: string;
  interestGroups: GroupWithIconDto[];
  helpCategories: GroupWithIconDto[];
  canEmailBeEdited: boolean;
  canRoleBeEdited: boolean;
  /** @format date-time */
  deleteAfter?: string;
  infoProject: InfoProjectDto;
  project?: SimpleProjectDto;
  mfaMethod?: "none" | "email";
  userRelationInformation?: UserRelationInformationDto;
}

export interface UserDto {
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  /** @deprecated */
  avatarUrl?: string;
  avatar?: ImageDto;
  /** @format date-time */
  deletedAt?: string;
  email?: string;
  isAdmin: boolean;
  projectId: string;
  canViewProfile: boolean;
  chatEnabled: boolean;
  phoneNumberHidden: boolean;
  emailHidden: boolean;
  hideHouseNumber: boolean;
}

export interface UserDtoPaginationResultDto {
  items: UserDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface UserLookupDto {
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  /** @deprecated */
  avatarUrl?: string;
  avatar?: ImageDto;
  /** @format date-time */
  deletedAt?: string;
  email?: string;
  isAdmin: boolean;
  canViewProfile: boolean;
  chatEnabled: boolean;
  phoneNumberHidden: boolean;
  emailHidden: boolean;
  hideHouseNumber: boolean;
  roleId: string;
  locatedAt?: string;
  phoneNumber?: string;
  backgroundImage?: ImageDto;
  biography?: string;
  occupation?: string;
  isAssignableToTickets: boolean;
  isOnlyAllowedOwnTickets: boolean;
  canBeDeleted: boolean;
  canBeEdited: boolean;
  /** @format date-time */
  registeredAt?: string;
  deletedByUserId?: string;
  languageId: "nl" | "en" | "de" | "dutch" | "english" | "german";
  isSuperAdmin: boolean;
  /** @format date-time */
  createdAt?: string;
  projectName: string;
  projectId: string;
  city?: string;
  zipCode?: string;
}

export interface UserLookupDtoPaginationResultDto {
  items: UserLookupDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface UserPollResponseDto {
  answers: OfferedAnswerDto[];
  /** @format date-time */
  answeredAt: string;
}

export interface UserProfileTicketsConfigurationDto {
  /** This is shown at the top of the page when creating a new ticket */
  customIntroText?: string;
  regulations?: DocumentUploadDto;
  /** Whether the user must sign off the regulations document before creating a ticket */
  requireSignOffRegulations: boolean;
  /** When a ticket is created, the user will receive an automatic reply with this text */
  autoReplyText?: string;
}

export interface UserProfileTicketsConfigurationRequest {
  customIntroText?: string;
  regulationsId?: string;
  requireSignOffRegulations: boolean;
  autoReplyText?: string;
}

export interface UserRelationCreateRequest {
  /** Provide the ID if the relation already exists. */
  id?: string;
  /**
   * Only used if the relation is new.
   * @format date-time
   */
  startDate?: string;
  /** Only used if the relation is new. This is the ID of the external partner. Not needed if the relation is internal. */
  partnerId?: string;
}

export interface UserRelationInformationDetailDto {
  id: string;
  /** @format date-time */
  startDate: string;
  partnerId?: string;
  users: UserDto[];
}

export interface UserRelationInformationDto {
  id: string;
  /** @format date-time */
  startDate: string;
  partnerId?: string;
}

export interface UserRoleAddressManagementPermissionsDto {
  canManageCompanies: boolean;
  canManageAddresses: boolean;
  canListAddresses: boolean;
  canEditAddress: boolean;
  canManageBuildings: boolean;
  roleId: string;
}

export interface UserRoleAddressManagementPermissionsRequest {
  canManageCompanies: boolean;
  canManageAddresses: boolean;
  canListAddresses: boolean;
  canEditAddress: boolean;
  canManageBuildings: boolean;
}

export interface UserRoleAssetsPermissionsDto {
  canViewSchedule: boolean;
  canManageOwn: boolean;
  canManageAll: boolean;
  roleId: string;
}

export interface UserRoleAssetsPermissionsRequest {
  canViewSchedule: boolean;
  canManageOwn: boolean;
  canManageAll: boolean;
}

export interface UserRoleCommunityGroupPermissionsDto {
  canCreatePost: boolean;
  canCreateAnnouncement: boolean;
  canListPosts: boolean;
  canCommentPost: boolean;
  roleId: string;
}

export interface UserRoleCommunityGroupPermissionsEntityDto {
  canCreatePost: boolean;
  canCreateAnnouncement: boolean;
  canListPosts: boolean;
  canCommentPost: boolean;
  roleId: string;
  groupId: string;
}

export interface UserRoleCommunityGroupPermissionsEntityRequest {
  canCreatePost: boolean;
  canCreateAnnouncement: boolean;
  canListPosts: boolean;
  canCommentPost: boolean;
  /** @minLength 1 */
  groupId: string;
}

export interface UserRoleCommunityPermissionsDto {
  canListGroups: boolean;
  canCreateGroup: boolean;
  canEditGroup: boolean;
  canDeleteGroup: boolean;
  canCreateGeneralPost: boolean;
  canCreateGeneralPostInProjectConnection: boolean;
  canCreateGeneralAnnouncement: boolean;
  canListGeneralPosts: boolean;
  canCommentGeneralPosts: boolean;
  roleId: string;
}

export interface UserRoleCommunityPermissionsRequest {
  canListGroups: boolean;
  canCreateGroup: boolean;
  canEditGroup: boolean;
  canDeleteGroup: boolean;
  canCreateGeneralPost: boolean;
  canCreateGeneralPostInProjectConnection: boolean;
  canCreateGeneralAnnouncement: boolean;
  canListGeneralPosts: boolean;
  canCommentGeneralPosts: boolean;
}

export interface UserRoleDto {
  id: string;
  name: string;
  locked: boolean;
  default: boolean;
  hasUsers: boolean;
  type: "readOnly" | "resident" | "projectOwner" | "maintenance" | "caretaker";
  permissions: UserRolePermissionsDto;
}

export interface UserRoleDetailsDto {
  id: string;
  name: string;
  locked: boolean;
  default: boolean;
  hasUsers: boolean;
  type: "readOnly" | "resident" | "projectOwner" | "maintenance" | "caretaker";
  permissions: UserRolePermissionsDto;
  users: UserDto[];
}

export interface UserRoleDocumentFolderPermissionsDto {
  canCreateDocument: boolean;
  canDeleteDocument: boolean;
  canListDocuments: boolean;
  roleId: string;
}

export interface UserRoleDocumentFolderPermissionsEntityDto {
  canCreateDocument: boolean;
  canDeleteDocument: boolean;
  canListDocuments: boolean;
  roleId: string;
  folderId: string;
}

export interface UserRoleDocumentFolderPermissionsEntityRequest {
  canCreateDocument: boolean;
  canDeleteDocument: boolean;
  canListDocuments: boolean;
  /** @minLength 1 */
  folderId: string;
}

export interface UserRoleEventsPermissionsDto {
  canManage: boolean;
  canListAll: boolean;
  roleId: string;
}

export interface UserRoleEventsPermissionsRequest {
  canManage: boolean;
  canListAll: boolean;
}

export interface UserRolePermissionsDto {
  community: UserRoleCommunityPermissionsDto;
  communityGroups: UserRoleCommunityGroupPermissionsEntityDto[];
  repairManagement: UserRoleRepairManagementPermissionsDto;
  repairCategories: UserRoleRepairCategoryPermissionsEntityDto[];
  folders: UserRoleDocumentFolderPermissionsEntityDto[];
  userManagement: UserRoleUserManagementPermissionsDto;
  addressManagement: UserRoleAddressManagementPermissionsDto;
  servicePartners: UserRoleServicePartnersPermissionsDto;
  servicePages: UserRoleServicePartnerPagePermissionsEntityDto[];
  surveys: UserRoleSurveysPermissionsDto;
  events: UserRoleEventsPermissionsDto;
  assets: UserRoleAssetsPermissionsDto;
}

export interface UserRolePermissionsRequest {
  community: UserRoleCommunityPermissionsRequest;
  communityGroups: UserRoleCommunityGroupPermissionsEntityRequest[];
  repairManagement: UserRoleRepairManagementPermissionsRequest;
  repairCategories: UserRoleRepairCategoryPermissionsEntityRequest[];
  folders: UserRoleDocumentFolderPermissionsEntityRequest[];
  userManagement: UserRoleUserManagementPermissionsRequest;
  addressManagement: UserRoleAddressManagementPermissionsRequest;
  servicePartners: UserRoleServicePagesPermissionsRequest;
  servicePages: UserRoleServicePartnerPagePermissionsEntityRequest[];
  surveys: UserRoleSurveysPermissionsRequest;
  events: UserRoleEventsPermissionsRequest;
  assets: UserRoleAssetsPermissionsRequest;
}

export interface UserRoleRepairCategoryPermissionsDto {
  canAddRequest: boolean;
  canUpdateRequestStatus: boolean;
  canBeAssigned: boolean;
  canListAllRequests: boolean;
  canListAssignedToMeRequests: boolean;
  canListCollectiveRequests: boolean;
  canCommentPublic: boolean;
  canCommentInternal: boolean;
  roleId: string;
}

export interface UserRoleRepairCategoryPermissionsEntityDto {
  canAddRequest: boolean;
  canUpdateRequestStatus: boolean;
  canBeAssigned: boolean;
  canListAllRequests: boolean;
  canListAssignedToMeRequests: boolean;
  canListCollectiveRequests: boolean;
  canCommentPublic: boolean;
  canCommentInternal: boolean;
  roleId: string;
  categoryId: string;
}

export interface UserRoleRepairCategoryPermissionsEntityRequest {
  canAddRequest: boolean;
  canUpdateRequestStatus: boolean;
  canBeAssigned: boolean;
  canListAllRequests: boolean;
  canListAssignedToMeRequests: boolean;
  canListCollectiveRequests: boolean;
  canCommentPublic: boolean;
  canCommentInternal: boolean;
  /** @minLength 1 */
  categoryId: string;
}

export interface UserRoleRepairManagementPermissionsDto {
  canManageCategories: boolean;
  canListCategories: boolean;
  canManageStatuses: boolean;
  canListStatuses: boolean;
  roleId: string;
}

export interface UserRoleRepairManagementPermissionsRequest {
  canManageCategories: boolean;
  canListCategories: boolean;
  canManageStatuses: boolean;
  canListStatuses: boolean;
}

export interface UserRoleRequest {
  /** @minLength 1 */
  name: string;
  type: "readOnly" | "resident" | "projectOwner" | "maintenance" | "caretaker";
  permissions: UserRolePermissionsRequest;
}

export interface UserRoleServicePagesPermissionsRequest {
  canManagePages: boolean;
  canCreatePages: boolean;
  canListPages: boolean;
}

export interface UserRoleServicePartnerPagePermissionsDto {
  canManageContent: boolean;
  roleId: string;
}

export interface UserRoleServicePartnerPagePermissionsEntityDto {
  canManageContent: boolean;
  roleId: string;
  pageId: string;
}

export interface UserRoleServicePartnerPagePermissionsEntityRequest {
  canManageContent: boolean;
  /** @minLength 1 */
  pageId: string;
}

export interface UserRoleServicePartnersPermissionsDto {
  canManagePages: boolean;
  canCreatePages: boolean;
  canListPages: boolean;
  roleId: string;
}

export interface UserRoleSurveysPermissionsDto {
  canListAll: boolean;
  canManage: boolean;
  roleId: string;
}

export interface UserRoleSurveysPermissionsRequest {
  canListAll: boolean;
  canManage: boolean;
}

export interface UserRoleTypeCommunityGroupPermissionsEntityRequest {
  canCreatePost: boolean;
  canCreateAnnouncement: boolean;
  canListPosts: boolean;
  canCommentPost: boolean;
  roleType: "readOnly" | "resident" | "projectOwner" | "maintenance" | "caretaker";
}

export interface UserRoleTypePermissionsDto {
  canCreatePost: boolean;
  canCreateAnnouncement: boolean;
  canListPosts: boolean;
  canCommentPost: boolean;
  roleType: "readOnly" | "resident" | "projectOwner" | "maintenance" | "caretaker";
}

export interface UserRoleUserManagementPermissionsDto {
  canManageRoles: boolean;
  canAssignRoles: boolean;
  canInviteUser: boolean;
  canDeleteUser: boolean;
  canEditResidentInfo: boolean;
  canEditAdminInfo: boolean;
  canListUsers: boolean;
  roleId: string;
}

export interface UserRoleUserManagementPermissionsRequest {
  canManageRoles: boolean;
  canAssignRoles: boolean;
  canInviteUser: boolean;
  canDeleteUser: boolean;
  canEditResidentInfo: boolean;
  canEditAdminInfo: boolean;
  canListUsers: boolean;
}

export interface UserStatsDto {
  /** @format int32 */
  totalResidents: number;
}

export interface UserTicketHistoryDto {
  /** @format int32 */
  userTotal: number;
  /** @format int32 */
  addressTotal: number;
  history: AdminTicketListItemDtoPaginationResultDto;
}

export interface UserUpdateRequest {
  /** @minLength 1 */
  firstName: string;
  /** @minLength 1 */
  lastName: string;
  mobileNumber: string;
  /**
   * @format email
   * @minLength 1
   */
  email: string;
  occupation?: string;
  addressId?: string;
  companyId?: string;
  language: "nl" | "en" | "de" | "dutch" | "english" | "german";
  avatarId?: string;
  hideChat: boolean;
  roleId?: string;
  isSuperAdmin?: boolean;
  /** @format date-time */
  deactivateAfter?: string;
  isOnlyAllowedOwnTickets?: boolean;
  onboardingCompleted?: boolean;
  mfaMethod?: "none" | "email";
}

export interface UserWithAudienceDto {
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  /** @deprecated */
  avatarUrl?: string;
  avatar?: ImageDto;
  /** @format date-time */
  deletedAt?: string;
  email?: string;
  isAdmin: boolean;
  projectId: string;
  canViewProfile: boolean;
  chatEnabled: boolean;
  phoneNumberHidden: boolean;
  emailHidden: boolean;
  hideHouseNumber: boolean;
  postId: string;
  canAccess: boolean;
}

export interface UserWithAudienceDtoPaginationResultDto {
  items: UserWithAudienceDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface ValidateRegistrationCodeRequest {
  /**
   * Unique device or session identifier.
   * Will be used to detect multiple failed attempts to avoid abuse.
   * Could be a random string in worst case.
   */
  deviceId: string;
  /** Registration code */
  code: string;
}

export interface ValidatedRegistrationCodeDto {
  /** @format uuid */
  accessToken: string;
  accessType: "address" | "company";
  codeOwner: string;
  projectId: string;
  projectName: string;
}

export interface VerifyEmailRequest {
  email: string;
}

export interface VerifyEmailResultDto {
  exists: boolean;
}

export interface WeeklyCountDto {
  /** @format int32 */
  weekNumber?: number;
  /** @format int32 */
  count: number;
}

export interface WeeklyCountOverviewDto {
  weeklyCount: WeeklyCountDto[];
  /** @format int32 */
  totalOverall: number;
}

export interface WidgetDataDto {
  /** @format double */
  percentage: number;
  isIncreasePositive: boolean;
}

export interface WidgetsDataTypesRequest {
  widgetDataType: ("ticketRating" | "ticketSolvingTime" | "engagement" | "contribution")[];
}

export interface ZiezodanProjectConfigurationDto {
  enabled: boolean;
}

export interface ChatDetailDto {
  id: string;
  groupChatInfo?: GroupChatInfoDto;
  privateChatInfo?: PrivateChatInfoDto;
  chatType: "single" | "group";
  hasUnreadReply: boolean;
  /** @format int32 */
  unreadReplies: number;
  latestReply?: ChatReplyDto;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt?: string;
  replies: ChatReplyDtoPaginationResultDto;
}

export interface ChatDto {
  id: string;
  groupChatInfo?: GroupChatInfoDto;
  privateChatInfo?: PrivateChatInfoDto;
  chatType: "single" | "group";
  hasUnreadReply: boolean;
  /** @format int32 */
  unreadReplies: number;
  latestReply?: ChatReplyDto;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt?: string;
}

export interface ChatDtoPaginationResultDto {
  items: ChatDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface ChatReplyDto {
  id: string;
  image?: ImageDto;
  text?: string;
  /** @format date-time */
  sentAt: string;
  author?: UserDto;
  isUnread: boolean;
  hasBeenSeenByAll: boolean;
  replyType?: "creation" | "userAdded" | "userDeleted" | "groupNameChanged" | "groupImageChanged";
  users?: UserDto[];
}

export interface ChatReplyDtoPaginationResultDto {
  items: ChatReplyDto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface ChatReplyRequest {
  imageId?: string;
  text?: string;
}

export interface CommunityFeedItemV2Dto {
  type: "message" | "survey" | "poll";
  /** @format date-time */
  updatedAt?: string;
  message?: MessageV2Dto;
  survey?: CommunityFeedSurveyDto;
  poll?: PollDto;
}

export interface CommunityFeedItemV2DtoPaginationResultDto {
  items: CommunityFeedItemV2Dto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface CommunityGroupDetailsV2Dto {
  id: string;
  type: "interest" | "helpCategory" | "realEstate";
  isResidentGroup: boolean;
  name: string;
  description: string;
  isMember: boolean;
  image?: ImageDto;
  members: UserDtoPaginationResultDto;
  audience?: ConstraintListItemDto[];
  canCreatePost: boolean;
  canCreateAnnouncement: boolean;
  canEdit: boolean;
  canDelete: boolean;
  disabled: boolean;
  projectConnectionGroupId?: string;
  systemGroupId?: string;
  postingLevel?: "project" | "projectConnection" | "projectAndProjectConnection";
  icon:
    | "announcement"
    | "asterisk"
    | "batteryMid"
    | "bellRinging"
    | "bicycle"
    | "bookOpen"
    | "building01"
    | "building02"
    | "car"
    | "carrot"
    | "coffee"
    | "compass"
    | "cursorClick"
    | "dice"
    | "dog"
    | "faceHappy"
    | "factory"
    | "forkAndKnife"
    | "gamingPad"
    | "gift"
    | "handshake"
    | "imageUserCheck"
    | "lightBulb"
    | "mailbox"
    | "map"
    | "markerPin"
    | "medicalSquare"
    | "messageChatSquare"
    | "messageDotsSquare"
    | "musicNote"
    | "network"
    | "package"
    | "plant"
    | "search"
    | "shoppingCart"
    | "sport"
    | "target"
    | "tool"
    | "users"
    | "wifi"
    | "wineGlass";
  isOwner: boolean;
  /** @format date-time */
  lastActivityAt?: string;
  nameTranslations?: TranslationsDto[];
  descriptionTranslations?: TranslationsDto[];
  permissions?: UserRoleCommunityGroupPermissionsDto[];
  notificationSettings: AppNotificationGroupSettingDto;
  canPromote: boolean;
  /**
   * Indicates whether to show the promotion banner to the author.
   * Returns true as long as the group is not promoted or skipped by the author.
   */
  hasPromotionBanner: boolean;
  /** @format int32 */
  totalResidentsAudience?: number;
  /** @format int32 */
  joinedResidents?: number;
}

export interface CommunityGroupV2Dto {
  id: string;
  type: "interest" | "helpCategory" | "realEstate";
  isResidentGroup: boolean;
  name: string;
  description: string;
  isMember: boolean;
  image?: ImageDto;
  members: UserDtoPaginationResultDto;
  audience?: ConstraintListItemDto[];
  canCreatePost: boolean;
  canCreateAnnouncement: boolean;
  canEdit: boolean;
  canDelete: boolean;
  disabled: boolean;
  projectConnectionGroupId?: string;
  systemGroupId?: string;
  postingLevel?: "project" | "projectConnection" | "projectAndProjectConnection";
  icon:
    | "announcement"
    | "asterisk"
    | "batteryMid"
    | "bellRinging"
    | "bicycle"
    | "bookOpen"
    | "building01"
    | "building02"
    | "car"
    | "carrot"
    | "coffee"
    | "compass"
    | "cursorClick"
    | "dice"
    | "dog"
    | "faceHappy"
    | "factory"
    | "forkAndKnife"
    | "gamingPad"
    | "gift"
    | "handshake"
    | "imageUserCheck"
    | "lightBulb"
    | "mailbox"
    | "map"
    | "markerPin"
    | "medicalSquare"
    | "messageChatSquare"
    | "messageDotsSquare"
    | "musicNote"
    | "network"
    | "package"
    | "plant"
    | "search"
    | "shoppingCart"
    | "sport"
    | "target"
    | "tool"
    | "users"
    | "wifi"
    | "wineGlass";
  isOwner: boolean;
  /** @format date-time */
  lastActivityAt?: string;
}

export interface CommunityGroupV2DtoPaginationResultDto {
  items: CommunityGroupV2Dto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface CreateGroupChatRequest {
  name: string;
  userIds: string[];
  imageId?: string;
}

export interface CreatePrivateChatRequest {
  userId: string;
}

export interface EngagedResidentsMonthlyPercentageDto {
  benchmark: MonthlyReportDateRangeProjectEngagementBenchmarkItemDto[];
  data: MonthlyReportDateRangeProjectEngagementPercentageItemDto[];
}

export interface FeelingAtHomeDto {
  id?: string;
  /** @format date-time */
  endDate?: string;
  /** @format double */
  averageRating: number;
  isAutomated: boolean;
}

export interface FeelingAtHomeWidgetDto {
  feelingAtHomeScore?: FeelingAtHomeDto;
  previousFeelingAtHomeScore?: FeelingAtHomeDto;
  benchmarkFeelingAtHomeScore: FeelingAtHomeDto;
}

export interface GroupChatInfoDto {
  name: string;
  image?: ImageDto;
}

export interface MessageDetailV2Dto {
  id: string;
  title?: string;
  content?: string;
  images: ImageDto[];
  type: "undefined" | "announcement" | "announcementReadOnly";
  /** @format int32 */
  totalLikeCount: number;
  author: UserDto;
  group?: GroupDto;
  projectConnection?: ProjectConnectionDto;
  hasCommented: boolean;
  canEdit: boolean;
  canLike: boolean;
  canComment: boolean;
  canReport: boolean;
  canShare: boolean;
  canArchive: boolean;
  canDelete: boolean;
  isHiddenFromAdmins: boolean;
  isUnread: boolean;
  isLiked: boolean;
  isMuted: boolean;
  hasAdminBeenInformed: boolean;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  reportedAt?: string;
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  scheduledAt?: string;
  /** @format date-time */
  postedAt?: string;
  /** @format date-time */
  deletedAt?: string;
  /** @format date-time */
  archivedAt?: string;
  messageLabel?: "askHelp" | "inform" | "sellItem";
  languageIsoCode: string;
  /** @format int32 */
  totalCommentCount: number;
  /** @format date-time */
  lastActivityAt?: string;
  documents?: DocumentUploadDto[];
  relatedGroup?: RelatedGroupDto;
  likes: MessageLikeDto[];
}

export interface MessageV2Dto {
  id: string;
  title?: string;
  content?: string;
  images: ImageDto[];
  type: "undefined" | "announcement" | "announcementReadOnly";
  /** @format int32 */
  totalLikeCount: number;
  author: UserDto;
  group?: GroupDto;
  projectConnection?: ProjectConnectionDto;
  hasCommented: boolean;
  canEdit: boolean;
  canLike: boolean;
  canComment: boolean;
  canReport: boolean;
  canShare: boolean;
  canArchive: boolean;
  canDelete: boolean;
  isHiddenFromAdmins: boolean;
  isUnread: boolean;
  isLiked: boolean;
  isMuted: boolean;
  hasAdminBeenInformed: boolean;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  reportedAt?: string;
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  scheduledAt?: string;
  /** @format date-time */
  postedAt?: string;
  /** @format date-time */
  deletedAt?: string;
  /** @format date-time */
  archivedAt?: string;
  messageLabel?: "askHelp" | "inform" | "sellItem";
  languageIsoCode: string;
  /** @format int32 */
  totalCommentCount: number;
  /** @format date-time */
  lastActivityAt?: string;
  documents?: DocumentUploadDto[];
  relatedGroup?: RelatedGroupDto;
}

export interface MessageV2DtoPaginationResultDto {
  items: MessageV2Dto[];
  /** @format int32 */
  total: number;
  hasMore: boolean;
}

export interface MonthlyReportDateRangeProjectEngagementPercentageItemDto {
  range: MonthlyReportDateRange;
  /** @format double */
  engagedResidentPercentage: number;
  /** @format int32 */
  postCount: number;
  /** @format int32 */
  commentCount: number;
  /** @format int32 */
  likeCount: number;
  /** @format int32 */
  commentLikeCount: number;
  /** @format int32 */
  collectiveTicketCount: number;
  /** @format int32 */
  eventCount: number;
  /** @format int32 */
  surveyCount: number;
  /** @format int32 */
  pollCount: number;
  /** @format int32 */
  reservationCount: number;
  /** @format int32 */
  engagedResident: number;
  /** @format double */
  eventCountPercentage: number;
  /** @format double */
  likeCountPercentage: number;
  /** @format double */
  postCountPercentage: number;
  /** @format double */
  surveyCountPercentage: number;
  /** @format double */
  pollCountPercentage: number;
  /** @format double */
  collectiveTicketCountPercentage: number;
  /** @format double */
  commentCountPercentage: number;
  /** @format double */
  commentLikeCountPercentage: number;
  /** @format int32 */
  privateTicketCount: number;
  /** @format int32 */
  chatCount: number;
  /** @format double */
  chatCountPercentage: number;
  /** @format int32 */
  ratedTicketCount?: number;
  /** @format int32 */
  surveysAnsweredCount: number;
  /** @format int32 */
  eventsAtendeesCount: number;
  /** @format int32 */
  pollsAnsweredCount: number;
  /** @format int32 */
  residentGroupsCreatedCount: number;
}

export interface PrivateChatInfoDto {
  receiver: UserDto;
}

export interface RegistrationConnectDto {
  result: "success" | "unknownLocation" | "locationUserLimitReached";
}

export interface RegistrationRequestV2 {
  locationCode?: string;
  languageId?: string;
  locationCodeInput?: "unknown" | "manual" | "navigationLinkApp" | "navigationLinkEmail" | "noInput";
}

export interface RelatedGroupDto {
  id: string;
  name: string;
  type: "interest" | "helpCategory" | "realEstate";
  isMember: boolean;
  image?: ImageDto;
  description: string;
  isOwner: boolean;
  isResidentGroup: boolean;
}

export interface SelfUpdateRequest {
  languageId: "nl" | "en" | "de" | "dutch" | "english" | "german";
  hideEmail: boolean;
  hidePhoneNumber: boolean;
  hideHouseNumber: boolean;
  enableChat: boolean;
  firstName: string;
  lastName: string;
  biography?: string;
  phoneNumber?: string;
  occupation?: string;
  avatarId?: string;
}

export interface SurveyDetailsV2Dto {
  id: string;
  type: "survey" | "poll";
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  startsAt?: string;
  /** @format date-time */
  endsAt?: string;
  group?: GroupDto;
  title: string;
  description: string;
  canRespond: "yes" | "notAllowed" | "notStarted" | "ended" | "alreadyAnswered";
  canView: boolean;
  canViewResults: boolean;
  canEdit: boolean;
  canDelete: boolean;
  createdBy?: UserDto;
  /** @format int32 */
  questionCount: number;
  /** @format int32 */
  answerCount?: number;
  isUnread: boolean;
  isPublished: boolean;
  category: "other" | "feelAtHome";
  questionGroups: SurveyQuestionGroupDto[];
  completedMessage: string;
  endedMessage: string;
  scope: "project" | "automated";
}

export interface SurveyQuestionGroupDto {
  explanation: string;
  /** @format int32 */
  order: number;
  questions: QuestionDto[];
}

export interface ResidentNotificationResultDto {
  notifications: ResidentNotificationDtoPaginationResultDto;
  /** @format date-time */
  lastRequested?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Area of People Core API
 * @version v3
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags -
   * @name Get
   * @request GET:/
   */
  get = (
    query?: {
      "api-version"?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  api = {
    /**
     * No description
     *
     * @tags Addresses
     * @name GetAddressesV1
     * @request GET:/api/v1/addresses
     * @secure
     */
    getAddressesV1: (
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<AddressDtoPaginationResultDto, any>({
        path: `/api/v1/addresses`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Addresses
     * @name PostAddressesV1
     * @request POST:/api/v1/addresses
     * @secure
     */
    postAddressesV1: (data: AddressCreateRequest, params: RequestParams = {}) =>
      this.request<AddressDto, any>({
        path: `/api/v1/addresses`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Addresses
     * @name GetAddressesDetailsV1
     * @request GET:/api/v1/addresses/{id}
     * @secure
     */
    getAddressesDetailsV1: (id: string, params: RequestParams = {}) =>
      this.request<AddressDto, any>({
        path: `/api/v1/addresses/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Addresses
     * @name PutAddressesV1
     * @request PUT:/api/v1/addresses/{id}
     * @secure
     */
    putAddressesV1: (id: string, data: AddressEditRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/addresses/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Addresses
     * @name DeleteAddressesByIdV1
     * @request DELETE:/api/v1/addresses/{id}
     * @secure
     */
    deleteAddressesByIdV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/addresses/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Addresses
     * @name PutAddressesRegenerateLocationCodeV1
     * @request PUT:/api/v1/addresses/{id}/regenerate-location-code
     * @secure
     */
    putAddressesRegenerateLocationCodeV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/addresses/${id}/regenerate-location-code`,
        method: "PUT",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Addresses
     * @name GetAddressesTypesV1
     * @request GET:/api/v1/addresses/types
     * @secure
     */
    getAddressesTypesV1: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/api/v1/addresses/types`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdminNotifications
     * @name GetAdminNotificationsV1
     * @request GET:/api/v1/admin-notifications
     * @secure
     */
    getAdminNotificationsV1: (
      query?: {
        ExcludeRead?: boolean;
        Types?: (
          | "unknown"
          | "message"
          | "messageComment"
          | "groupMessage"
          | "ticket"
          | "ticketAssignee"
          | "ticketComment"
        )[];
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<AdminNotificationDtoPaginationResultDto, any>({
        path: `/api/v1/admin-notifications`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdminNotifications
     * @name GetAdminNotificationsStatusV1
     * @request GET:/api/v1/admin-notifications/status
     * @secure
     */
    getAdminNotificationsStatusV1: (params: RequestParams = {}) =>
      this.request<AdminNotificationStatusDto, any>({
        path: `/api/v1/admin-notifications/status`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdminNotifications
     * @name DeleteAdminNotificationsReadByIdV1
     * @request DELETE:/api/v1/admin-notifications/read/{id}
     * @secure
     */
    deleteAdminNotificationsReadByIdV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/admin-notifications/read/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdminNotifications
     * @name DeleteAdminNotificationsReadV1
     * @request DELETE:/api/v1/admin-notifications/read
     * @secure
     */
    deleteAdminNotificationsReadV1: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/admin-notifications/read`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdminTickets
     * @name GetAdminTicketsV1
     * @request GET:/api/v1/admin/tickets
     * @secure
     */
    getAdminTicketsV1: (
      query?: {
        StatusIds?: string[];
        CategoryIds?: string[];
        AssigneeIds?: string[];
        Ratings?: number[];
        AddressTypes?: string[];
        UnreadOnly?: boolean;
        Filter?: "private" | "collective";
        SortBy?:
          | "unread"
          | "newest"
          | "oldest"
          | "leastRecentActivity"
          | "mostRecentActivity"
          | "leastRecentResidentActivity"
          | "mostRecentResidentActivity"
          | "mostRecentReminder";
        Tab?: "inProgress" | "new" | "closed" | "reminder";
        Search?: string;
        ResidentFocusMode?: boolean;
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<AdminTicketDetailedListItemDtoPaginationResultDto, any>({
        path: `/api/v1/admin/tickets`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdminTickets
     * @name PostAdminTicketsReadV1
     * @request POST:/api/v1/admin/tickets/read
     * @secure
     */
    postAdminTicketsReadV1: (
      query?: {
        StatusIds?: string[];
        CategoryIds?: string[];
        AssigneeIds?: string[];
        Ratings?: number[];
        AddressTypes?: string[];
        UnreadOnly?: boolean;
        Filter?: "private" | "collective";
        SortBy?:
          | "unread"
          | "newest"
          | "oldest"
          | "leastRecentActivity"
          | "mostRecentActivity"
          | "leastRecentResidentActivity"
          | "mostRecentResidentActivity"
          | "mostRecentReminder";
        Tab?: "inProgress" | "new" | "closed" | "reminder";
        Search?: string;
        ResidentFocusMode?: boolean;
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/admin/tickets/read`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdminTickets
     * @name GetAdminTicketsFiltersV1
     * @request GET:/api/v1/admin/tickets/filters
     * @secure
     */
    getAdminTicketsFiltersV1: (params: RequestParams = {}) =>
      this.request<AdminTicketFiltersDto, any>({
        path: `/api/v1/admin/tickets/filters`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdminTickets
     * @name GetAdminTicketsStatsDetailsV1
     * @request GET:/api/v1/admin/tickets/stats/{tab}
     * @secure
     */
    getAdminTicketsStatsDetailsV1: (tab: "inProgress" | "new" | "closed" | "reminder", params: RequestParams = {}) =>
      this.request<AdminTicketStatsDto, any>({
        path: `/api/v1/admin/tickets/stats/${tab}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdminTickets
     * @name GetAdminTicketsStatsAllTabsV1
     * @request GET:/api/v1/admin/tickets/stats/all-tabs
     * @secure
     */
    getAdminTicketsStatsAllTabsV1: (
      query?: {
        StatusIds?: string[];
        CategoryIds?: string[];
        AssigneeIds?: string[];
        Ratings?: number[];
        AddressTypes?: string[];
        UnreadOnly?: boolean;
        Filter?: "private" | "collective";
        SortBy?:
          | "unread"
          | "newest"
          | "oldest"
          | "leastRecentActivity"
          | "mostRecentActivity"
          | "leastRecentResidentActivity"
          | "mostRecentResidentActivity"
          | "mostRecentReminder";
        Tab?: "inProgress" | "new" | "closed" | "reminder";
        Search?: string;
        ResidentFocusMode?: boolean;
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<AdminTicketTabsStatsDto, any>({
        path: `/api/v1/admin/tickets/stats/all-tabs`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdminTickets
     * @name GetAdminTicketsExportV1
     * @request GET:/api/v1/admin/tickets/export
     * @secure
     */
    getAdminTicketsExportV1: (
      query?: {
        StatusIds?: string[];
        CategoryIds?: string[];
        AssigneeIds?: string[];
        Ratings?: number[];
        AddressTypes?: string[];
        UnreadOnly?: boolean;
        Filter?: "private" | "collective";
        SortBy?:
          | "unread"
          | "newest"
          | "oldest"
          | "leastRecentActivity"
          | "mostRecentActivity"
          | "leastRecentResidentActivity"
          | "mostRecentResidentActivity"
          | "mostRecentReminder";
        Tab?: "inProgress" | "new" | "closed" | "reminder";
        Search?: string;
        ResidentFocusMode?: boolean;
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
        /**
         * @format double
         * @default 0
         */
        utcHourOffset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/admin/tickets/export`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdminTickets
     * @name GetAdminTicketsDetailsV1
     * @request GET:/api/v1/admin/tickets/{id}
     * @secure
     */
    getAdminTicketsDetailsV1: (id: string, params: RequestParams = {}) =>
      this.request<AdminTicketDetailsDto, any>({
        path: `/api/v1/admin/tickets/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdminTickets
     * @name DeleteAdminTicketsByIdV1
     * @request DELETE:/api/v1/admin/tickets/{id}
     * @secure
     */
    deleteAdminTicketsByIdV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/admin/tickets/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdminTickets
     * @name GetAdminTicketsActivitiesV1
     * @request GET:/api/v1/admin/tickets/{id}/activities
     * @secure
     */
    getAdminTicketsActivitiesV1: (
      id: string,
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
        /** @default false */
        sortDescending?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<AdminTicketActivityDtoPaginationResultDto, any>({
        path: `/api/v1/admin/tickets/${id}/activities`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdminTickets
     * @name PutAdminTicketsAssigneeV1
     * @request PUT:/api/v1/admin/tickets/{id}/assignee
     * @secure
     */
    putAdminTicketsAssigneeV1: (id: string, data: TicketAssigneeChangeRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/admin/tickets/${id}/assignee`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdminTickets
     * @name PutAdminTicketsStatusV1
     * @request PUT:/api/v1/admin/tickets/{id}/status
     * @secure
     */
    putAdminTicketsStatusV1: (id: string, data: TicketStatusChangeRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/admin/tickets/${id}/status`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdminTickets
     * @name PostAdminTicketsMoveToInProgressV1
     * @request POST:/api/v1/admin/tickets/{id}/move-to-in-progress
     * @secure
     */
    postAdminTicketsMoveToInProgressV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/admin/tickets/${id}/move-to-in-progress`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdminTickets
     * @name PostAdminTicketsRemsSyncV1
     * @request POST:/api/v1/admin/tickets/{id}/rems-sync
     * @secure
     */
    postAdminTicketsRemsSyncV1: (id: string, params: RequestParams = {}) =>
      this.request<RemsStatusDto, any>({
        path: `/api/v1/admin/tickets/${id}/rems-sync`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdminTickets
     * @name PostAdminTicketsRemsSendTicketV1
     * @request POST:/api/v1/admin/tickets/{id}/rems-send-ticket
     * @secure
     */
    postAdminTicketsRemsSendTicketV1: (id: string, data: RemsSyncRequest, params: RequestParams = {}) =>
      this.request<RemsStatusDto, any>({
        path: `/api/v1/admin/tickets/${id}/rems-send-ticket`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdminTickets
     * @name PostAdminTicketsReminderV1
     * @request POST:/api/v1/admin/tickets/{id}/reminder
     * @secure
     */
    postAdminTicketsReminderV1: (id: string, data: SetReminderDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/admin/tickets/${id}/reminder`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdminTickets
     * @name DeleteAdminTicketsReminderDeleteV1
     * @request DELETE:/api/v1/admin/tickets/{id}/reminder/delete
     * @secure
     */
    deleteAdminTicketsReminderDeleteV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/admin/tickets/${id}/reminder/delete`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AlertsConfigurations
     * @name GetAlertsConfigurationsV1
     * @request GET:/api/v1/alerts-configurations
     * @secure
     */
    getAlertsConfigurationsV1: (params: RequestParams = {}) =>
      this.request<AlertConfigurationsDto, any>({
        path: `/api/v1/alerts-configurations`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AlertsConfigurations
     * @name PostAlertsConfigurationsPostKeywordsV1
     * @request POST:/api/v1/alerts-configurations/post-keywords
     * @secure
     */
    postAlertsConfigurationsPostKeywordsV1: (
      data: KeywordAlertConfigurationCreateRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/alerts-configurations/post-keywords`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AlertsConfigurations
     * @name PutAlertsConfigurationsPostKeywordsV1
     * @request PUT:/api/v1/alerts-configurations/post-keywords/{id}
     * @secure
     */
    putAlertsConfigurationsPostKeywordsV1: (
      id: string,
      data: KeywordAlertConfigurationCreateRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/alerts-configurations/post-keywords/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AlertsConfigurations
     * @name PostAlertsConfigurationsCommentKeywordsV1
     * @request POST:/api/v1/alerts-configurations/comment-keywords
     * @secure
     */
    postAlertsConfigurationsCommentKeywordsV1: (
      data: KeywordAlertConfigurationCreateRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/alerts-configurations/comment-keywords`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AlertsConfigurations
     * @name PutAlertsConfigurationsCommentKeywordsV1
     * @request PUT:/api/v1/alerts-configurations/comment-keywords/{id}
     * @secure
     */
    putAlertsConfigurationsCommentKeywordsV1: (
      id: string,
      data: KeywordAlertConfigurationCreateRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/alerts-configurations/comment-keywords/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AlertsConfigurations
     * @name PostAlertsConfigurationsNumberOfLikesV1
     * @request POST:/api/v1/alerts-configurations/number-of-likes
     * @secure
     */
    postAlertsConfigurationsNumberOfLikesV1: (
      data: ThresholdAlertConfigurationCreateRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/alerts-configurations/number-of-likes`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AlertsConfigurations
     * @name PutAlertsConfigurationsNumberOfLikesV1
     * @request PUT:/api/v1/alerts-configurations/number-of-likes/{id}
     * @secure
     */
    putAlertsConfigurationsNumberOfLikesV1: (
      id: string,
      data: ThresholdAlertConfigurationCreateRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/alerts-configurations/number-of-likes/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AlertsConfigurations
     * @name PostAlertsConfigurationsNumberOfCommentsV1
     * @request POST:/api/v1/alerts-configurations/number-of-comments
     * @secure
     */
    postAlertsConfigurationsNumberOfCommentsV1: (
      data: ThresholdAlertConfigurationCreateRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/alerts-configurations/number-of-comments`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AlertsConfigurations
     * @name PutAlertsConfigurationsNumberOfCommentsV1
     * @request PUT:/api/v1/alerts-configurations/number-of-comments/{id}
     * @secure
     */
    putAlertsConfigurationsNumberOfCommentsV1: (
      id: string,
      data: ThresholdAlertConfigurationCreateRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/alerts-configurations/number-of-comments/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name GetAnalyticsTicketRatingMonthlyV1
     * @request GET:/api/v1/analytics/ticket-rating/monthly
     * @secure
     */
    getAnalyticsTicketRatingMonthlyV1: (
      query?: {
        /** @default "last6Months" */
        dateRange?: "last6Months" | "lastYear";
      },
      params: RequestParams = {},
    ) =>
      this.request<TicketAverageRatingMonthlyDto, any>({
        path: `/api/v1/analytics/ticket-rating/monthly`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name GetAnalyticsTicketLeadTimeMonthlyV1
     * @request GET:/api/v1/analytics/ticket-lead-time/monthly
     * @secure
     */
    getAnalyticsTicketLeadTimeMonthlyV1: (
      query?: {
        /** @default "last6Months" */
        dateRange?: "last6Months" | "lastYear";
      },
      params: RequestParams = {},
    ) =>
      this.request<TicketAverageLeadTimeMonthlyDto, any>({
        path: `/api/v1/analytics/ticket-lead-time/monthly`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name GetAnalyticsEngagedResidentsMonthlyV1
     * @request GET:/api/v1/analytics/engaged-residents/monthly
     * @deprecated
     * @secure
     */
    getAnalyticsEngagedResidentsMonthlyV1: (
      query?: {
        /** @default "last6Months" */
        dateRange?: "last6Months" | "lastYear";
      },
      params: RequestParams = {},
    ) =>
      this.request<EngagedResidentsMonthlyDto, any>({
        path: `/api/v1/analytics/engaged-residents/monthly`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name GetAnalyticsTicketOverviewMonthlyV1
     * @request GET:/api/v1/analytics/ticket-overview/monthly
     * @secure
     */
    getAnalyticsTicketOverviewMonthlyV1: (
      query?: {
        /** @default "last6Months" */
        dateRange?: "last6Months" | "lastYear";
      },
      params: RequestParams = {},
    ) =>
      this.request<TicketOverviewDto, any>({
        path: `/api/v1/analytics/ticket-overview/monthly`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name GetAnalyticsConversationsMonthlyV1
     * @request GET:/api/v1/analytics/conversations/monthly
     * @secure
     */
    getAnalyticsConversationsMonthlyV1: (
      query?: {
        /** @default "last6Months" */
        dateRange?: "last6Months" | "lastYear";
      },
      params: RequestParams = {},
    ) =>
      this.request<ConversationsMonthlyDto, any>({
        path: `/api/v1/analytics/conversations/monthly`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name GetAnalyticsBucketTicketLeadTimeMonthlyV1
     * @request GET:/api/v1/analytics/bucket-ticket-lead-time/monthly
     * @secure
     */
    getAnalyticsBucketTicketLeadTimeMonthlyV1: (
      query?: {
        /** @default "last6Months" */
        dateRange?: "last6Months" | "lastYear";
      },
      params: RequestParams = {},
    ) =>
      this.request<BucketLeadTimeMonthlyDto, any>({
        path: `/api/v1/analytics/bucket-ticket-lead-time/monthly`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name GetAnalyticsAdoptionRateV1
     * @request GET:/api/v1/analytics/adoption-rate
     * @secure
     */
    getAnalyticsAdoptionRateV1: (params: RequestParams = {}) =>
      this.request<AdoptionRateDto, any>({
        path: `/api/v1/analytics/adoption-rate`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name GetAnalyticsCompanyAdoptionRateV1
     * @request GET:/api/v1/analytics/company-adoption-rate
     * @secure
     */
    getAnalyticsCompanyAdoptionRateV1: (params: RequestParams = {}) =>
      this.request<CompanyAdoptionRateDto, any>({
        path: `/api/v1/analytics/company-adoption-rate`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name GetAnalyticsAdoptionRateDetailsDetailsV1
     * @request GET:/api/v1/analytics/adoption-rate/details/{adoptionRateDetailsGraphType}
     * @secure
     */
    getAnalyticsAdoptionRateDetailsDetailsV1: (
      adoptionRateDetailsGraphType: "adoption" | "vacant",
      query?: {
        /** @default "last6Months" */
        dateRange?: "last6Months" | "lastYear";
      },
      params: RequestParams = {},
    ) =>
      this.request<AdoptionRateDetailsGraphItemDto[], any>({
        path: `/api/v1/analytics/adoption-rate/details/${adoptionRateDetailsGraphType}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags Analytics
 * @name GetAnalyticsResidentEngagementV1
 * @summary The engagement of residents on a current basis (last 24 hours, last 7 days, last 30 days)
These numbers are calculated on the fly
 * @request GET:/api/v1/analytics/resident-engagement
 * @secure
 */
    getAnalyticsResidentEngagementV1: (params: RequestParams = {}) =>
      this.request<ResidentEngagementDto, any>({
        path: `/api/v1/analytics/resident-engagement`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags Analytics
 * @name GetAnalyticsResidentEngagementDetailsV1
 * @summary The engagement of residents on a monthly basis
These numbers are stored on a monthly basis
 * @request GET:/api/v1/analytics/resident-engagement/details
 * @secure
 */
    getAnalyticsResidentEngagementDetailsV1: (
      query?: {
        /** @default "last6Months" */
        dateRange?: "last6Months" | "lastYear";
      },
      params: RequestParams = {},
    ) =>
      this.request<ResidentEngagementMonthlyDto[], any>({
        path: `/api/v1/analytics/resident-engagement/details`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name GetAnalyticsWeeklyChatConversationsV1
     * @request GET:/api/v1/analytics/weekly-chat-conversations
     * @secure
     */
    getAnalyticsWeeklyChatConversationsV1: (
      query?: {
        /** @default "last6Months" */
        dateRange?: "last6Months" | "lastYear";
      },
      params: RequestParams = {},
    ) =>
      this.request<WeeklyCountOverviewDto, any>({
        path: `/api/v1/analytics/weekly-chat-conversations`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name GetAnalyticsWeeklyMessagesV1
     * @request GET:/api/v1/analytics/weekly-messages
     * @secure
     */
    getAnalyticsWeeklyMessagesV1: (
      query?: {
        /** @default "last6Months" */
        dateRange?: "last6Months" | "lastYear";
      },
      params: RequestParams = {},
    ) =>
      this.request<WeeklyCountOverviewDto, any>({
        path: `/api/v1/analytics/weekly-messages`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name GetAnalyticsMessageLabelStatsV1
     * @request GET:/api/v1/analytics/message-label-stats
     * @secure
     */
    getAnalyticsMessageLabelStatsV1: (
      query?: {
        /** @default "last6Months" */
        dateRange?: "last6Months" | "lastYear";
      },
      params: RequestParams = {},
    ) =>
      this.request<MessageLabelsStatMonthlyDto, any>({
        path: `/api/v1/analytics/message-label-stats`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name GetAnalyticsFeelingAtHomeV1
     * @request GET:/api/v1/analytics/feeling-at-home
     * @secure
     */
    getAnalyticsFeelingAtHomeV1: (params: RequestParams = {}) =>
      this.request<FeelingAtHomeWidgetV2Dto, any>({
        path: `/api/v1/analytics/feeling-at-home`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name GetAnalyticsFeelingAtHomeDetailsV1
     * @request GET:/api/v1/analytics/feeling-at-home/details
     * @secure
     */
    getAnalyticsFeelingAtHomeDetailsV1: (
      query?: {
        /** @format date-time */
        month?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FeelingAtHomeDetailsDto, any>({
        path: `/api/v1/analytics/feeling-at-home/details`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name GetAnalyticsFeelingAtHomeExportV1
     * @request GET:/api/v1/analytics/feeling-at-home/export
     * @secure
     */
    getAnalyticsFeelingAtHomeExportV1: (
      query?: {
        /** @format date-time */
        month?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/analytics/feeling-at-home/export`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AutomatedSurveys
     * @name PostAutomatedSurveysV1
     * @request POST:/api/v1/automated-surveys
     * @secure
     */
    postAutomatedSurveysV1: (data: AutomatedSurveyRequest, params: RequestParams = {}) =>
      this.request<AutomatedSurveyDto, any>({
        path: `/api/v1/automated-surveys`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AutomatedSurveys
     * @name GetAutomatedSurveysV1
     * @request GET:/api/v1/automated-surveys
     * @secure
     */
    getAutomatedSurveysV1: (
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<AutomatedSurveyDtoPaginationResultDto, any>({
        path: `/api/v1/automated-surveys`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AutomatedSurveys
     * @name PutAutomatedSurveysV1
     * @request PUT:/api/v1/automated-surveys/{id}
     * @secure
     */
    putAutomatedSurveysV1: (id: string, data: AutomatedSurveyEditRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/automated-surveys/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AutomatedSurveys
     * @name GetAutomatedSurveysDetailsV1
     * @request GET:/api/v1/automated-surveys/{id}
     * @secure
     */
    getAutomatedSurveysDetailsV1: (
      id: string,
      query?: {
        /** @default false */
        includeRankingQuestion?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<AutomatedSurveyDto, any>({
        path: `/api/v1/automated-surveys/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AutomatedSurveys
     * @name GetAutomatedSurveysQueuesV1
     * @request GET:/api/v1/automated-surveys/queues
     * @secure
     */
    getAutomatedSurveysQueuesV1: (
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<AutomatedSurveyQueueDtoPaginationResultDto, any>({
        path: `/api/v1/automated-surveys/queues`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AutomatedSurveys
     * @name GetAutomatedSurveysActiveDetailsV1
     * @request GET:/api/v1/automated-surveys/{id}/active-details
     * @secure
     */
    getAutomatedSurveysActiveDetailsV1: (
      id: string,
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<AutomatedSurveyQueueDetailsListDtoPaginationResultDto, any>({
        path: `/api/v1/automated-surveys/${id}/active-details`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AutomatedSurveys
     * @name GetAutomatedSurveysQueuesDetailsV1
     * @request GET:/api/v1/automated-surveys/queues/{queueId}/details
     * @secure
     */
    getAutomatedSurveysQueuesDetailsV1: (
      queueId: string,
      query?: {
        /** @default false */
        includeRankingQuestion?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<AutomatedSurveyQueueDetailsDto, any>({
        path: `/api/v1/automated-surveys/queues/${queueId}/details`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AutomatedSurveys
     * @name GetAutomatedSurveysQueuesQuestionsDetailsV1
     * @request GET:/api/v1/automated-surveys/queues/{queueId}/questions/{questionGroupId}/details
     * @secure
     */
    getAutomatedSurveysQueuesQuestionsDetailsV1: (
      queueId: string,
      questionGroupId: string,
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<AutomatedSurveyQueueQuestionDetailsDto, any>({
        path: `/api/v1/automated-surveys/queues/${queueId}/questions/${questionGroupId}/details`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AutomatedSurveys
     * @name GetAutomatedSurveysAnalyticsDetailsV1
     * @summary Gets the results of the survey per interval for the given project
     * @request GET:/api/v1/automated-surveys/{id}/analytics/{questionGroupId}
     * @secure
     */
    getAutomatedSurveysAnalyticsDetailsV1: (id: string, questionGroupId: string, params: RequestParams = {}) =>
      this.request<AutomatedSurveyIntervalResultDto[], any>({
        path: `/api/v1/automated-surveys/${id}/analytics/${questionGroupId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BookableAssets
     * @name GetBookableAssetsV1
     * @request GET:/api/v1/bookable-assets
     * @secure
     */
    getBookableAssetsV1: (
      query?: {
        HideUnpublished?: boolean;
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<BookableAssetDtoPaginationResultDto, any>({
        path: `/api/v1/bookable-assets`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BookableAssets
     * @name PostBookableAssetsV1
     * @request POST:/api/v1/bookable-assets
     * @secure
     */
    postBookableAssetsV1: (data: BookableAssetCreateRequest, params: RequestParams = {}) =>
      this.request<CreatedEntityDto, any>({
        path: `/api/v1/bookable-assets`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BookableAssets
     * @name GetBookableAssetsDetailsV1
     * @request GET:/api/v1/bookable-assets/{id}
     * @secure
     */
    getBookableAssetsDetailsV1: (id: string, params: RequestParams = {}) =>
      this.request<BookableAssetDetailDto, any>({
        path: `/api/v1/bookable-assets/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BookableAssets
     * @name PutBookableAssetsV1
     * @request PUT:/api/v1/bookable-assets/{id}
     * @secure
     */
    putBookableAssetsV1: (id: string, data: BookableAssetUpdateRequest, params: RequestParams = {}) =>
      this.request<CreatedEntityDto, any>({
        path: `/api/v1/bookable-assets/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BookableAssets
     * @name DeleteBookableAssetsByIdV1
     * @request DELETE:/api/v1/bookable-assets/{id}
     * @secure
     */
    deleteBookableAssetsByIdV1: (id: string, params: RequestParams = {}) =>
      this.request<BookableAssetDeletedResult, any>({
        path: `/api/v1/bookable-assets/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BookableAssets
     * @name PostBookableAssetsBookingsToBeCancelledV1
     * @request POST:/api/v1/bookable-assets/{id}/bookings-to-be-cancelled
     * @secure
     */
    postBookableAssetsBookingsToBeCancelledV1: (
      id: string,
      data: BookableAssetUpdateBookingsToBeCancelledRequest,
      params: RequestParams = {},
    ) =>
      this.request<BookableAssetUpdateBookingsToBeCancelledDto, any>({
        path: `/api/v1/bookable-assets/${id}/bookings-to-be-cancelled`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BookableAssets
     * @name GetBookableAssetsStatisticsV1
     * @summary Retrieves the statistics for a bookable asset.
     * @request GET:/api/v1/bookable-assets/{id}/statistics
     * @secure
     */
    getBookableAssetsStatisticsV1: (
      id: string,
      query?: {
        /**
         * Optional. Determines whether to include today's bookings in the statistics.
         * Only applies to AOP.Core.Api.Controllers.V1.Bookings.BookableAssetStatisticsDto.MostRecentBooking
         * @default true
         */
        includeToday?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<BookableAssetStatisticsDto, any>({
        path: `/api/v1/bookable-assets/${id}/statistics`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bookings
     * @name GetBookableAssetsBookingsV1
     * @summary Retrieves the bookings for a specific asset based on the provided filter.
     * @request GET:/api/v1/bookable-assets/{assetId}/bookings
     * @secure
     */
    getBookableAssetsBookingsV1: (
      assetId: string,
      query?: {
        /**
         * @format date
         * @example "2024-01-01"
         */
        Date?: string;
        /**
         * Optional. If not provided, the filter will be applied only for the specified date.
         * Cannot be greater than 32 days from the specified date.
         * @format date
         * @example "2024-01-01"
         */
        Until?: string;
        IncludeCancelled?: boolean;
        /**
         * Collection of booking IDs that are excluded from the results of a request, to prevent them from being considered in the current operation.
         * This is particularly useful in scenarios such as editing a booking, where the booking being edited (e.g., ID 7) should not appear in the query results to (incorrect) avoid conflicts.
         */
        ExcludedBookingIds?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<AssetBookingDto[], any>({
        path: `/api/v1/bookable-assets/${assetId}/bookings`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bookings
     * @name PostBookableAssetsBookingsV1
     * @request POST:/api/v1/bookable-assets/{assetId}/bookings
     * @secure
     */
    postBookableAssetsBookingsV1: (assetId: string, data: AssetBookingRequest, params: RequestParams = {}) =>
      this.request<CreatedEntityDto, void>({
        path: `/api/v1/bookable-assets/${assetId}/bookings`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bookings
     * @name GetBookableAssetsMyBookingsV1
     * @summary Retrieves all the bookings for the logged-in user based on the specified pagination settings.
     * @request GET:/api/v1/bookable-assets/bookings
     * @secure
     */
    getBookableAssetsMyBookingsV1: (
      query?: {
        TimeFrame?: "all" | "futureOrToday" | "past";
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<AssetBookingDtoPaginationResultDto, any>({
        path: `/api/v1/bookable-assets/bookings`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bookings
     * @name GetBookableAssetsBookingsDetailsV1
     * @request GET:/api/v1/bookable-assets/{assetId}/bookings/{id}
     * @secure
     */
    getBookableAssetsBookingsDetailsV1: (id: string, assetId: string, params: RequestParams = {}) =>
      this.request<AssetBookingDetailDto, any>({
        path: `/api/v1/bookable-assets/${assetId}/bookings/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bookings
     * @name DeleteBookableAssetsBookingsByIdV1
     * @request DELETE:/api/v1/bookable-assets/{assetId}/bookings/{id}
     * @secure
     */
    deleteBookableAssetsBookingsByIdV1: (
      id: string,
      assetId: string,
      data: DeleteAssetBookingRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/bookable-assets/${assetId}/bookings/${id}`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bookings
     * @name PutBookableAssetsBookingsV1
     * @request PUT:/api/v1/bookable-assets/{assetId}/bookings/{id}
     * @secure
     */
    putBookableAssetsBookingsV1: (id: string, assetId: string, data: AssetBookingRequest, params: RequestParams = {}) =>
      this.request<AssetBookingDetailDto, void>({
        path: `/api/v1/bookable-assets/${assetId}/bookings/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bookings
     * @name GetBookableAssetsBookingsExportV1
     * @request GET:/api/v1/bookable-assets/{assetId}/bookings/export
     * @secure
     */
    getBookableAssetsBookingsExportV1: (
      assetId: string,
      query?: {
        /** @default false */
        includeHistory?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/bookable-assets/${assetId}/bookings/export`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bookings
     * @name GetBookableAssetsBookingsProjectExportV1
     * @request GET:/api/v1/bookable-assets/bookings/export
     * @secure
     */
    getBookableAssetsBookingsProjectExportV1: (
      query?: {
        /** @default false */
        includeHistory?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/bookable-assets/bookings/export`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Buildings
     * @name GetBuildingsV1
     * @request GET:/api/v1/buildings
     * @secure
     */
    getBuildingsV1: (
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<BuildingDtoPaginationResultDto, any>({
        path: `/api/v1/buildings`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Buildings
     * @name PostBuildingsV1
     * @request POST:/api/v1/buildings
     * @secure
     */
    postBuildingsV1: (data: BuildingCreateRequest, params: RequestParams = {}) =>
      this.request<BuildingDto, any>({
        path: `/api/v1/buildings`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Buildings
     * @name GetBuildingsDetailsV1
     * @request GET:/api/v1/buildings/{id}
     * @secure
     */
    getBuildingsDetailsV1: (id: string, params: RequestParams = {}) =>
      this.request<BuildingDto, any>({
        path: `/api/v1/buildings/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Buildings
     * @name PutBuildingsV1
     * @request PUT:/api/v1/buildings/{id}
     * @secure
     */
    putBuildingsV1: (id: string, data: BuildingEditRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/buildings/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Buildings
     * @name DeleteBuildingsByIdV1
     * @request DELETE:/api/v1/buildings/{id}
     * @secure
     */
    deleteBuildingsByIdV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/buildings/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ChatBot
     * @name PostChatsBotV1
     * @request POST:/api/v1/chats/bot
     * @secure
     */
    postChatsBotV1: (data: ChatMessage[], params: RequestParams = {}) =>
      this.request<ChatMessage[], any>({
        path: `/api/v1/chats/bot`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chats
     * @name GetChatsV1
     * @request GET:/api/v1/chats
     * @secure
     */
    getChatsV1: (
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ConversationDtoPaginationResultDto, any>({
        path: `/api/v1/chats`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chats
     * @name GetChatsStatusV1
     * @request GET:/api/v1/chats/status
     * @secure
     */
    getChatsStatusV1: (params: RequestParams = {}) =>
      this.request<ChatStatusDto, any>({
        path: `/api/v1/chats/status`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chats
     * @name GetChatsAvailableUsersV1
     * @request GET:/api/v1/chats/available-users
     * @secure
     */
    getChatsAvailableUsersV1: (
      query?: {
        search?: string;
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserDetailsWithAddressDtoPaginationResultDto, any>({
        path: `/api/v1/chats/available-users`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chats
     * @name GetChatsAvailableUsersDetailsV1
     * @request GET:/api/v1/chats/available-users/{id}
     * @secure
     */
    getChatsAvailableUsersDetailsV1: (id: string, params: RequestParams = {}) =>
      this.request<UserDto, any>({
        path: `/api/v1/chats/available-users/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chats
     * @name PostChatsStateV1
     * @request POST:/api/v1/chats/state
     * @secure
     */
    postChatsStateV1: (data: ChatStateChangeRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/chats/state`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chats
     * @name GetChatsRepliesV1
     * @summary Gets the conversation replies between a user
     * @request GET:/api/v1/chats/{recipient}/replies
     * @secure
     */
    getChatsRepliesV1: (
      recipient: string,
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ConversationReplyDtoPaginationResultDto, any>({
        path: `/api/v1/chats/${recipient}/replies`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chats
     * @name PostChatsRepliesV1
     * @request POST:/api/v1/chats/{recipient}/replies
     * @secure
     */
    postChatsRepliesV1: (recipient: string, data: ConversationReplyCreateRequest, params: RequestParams = {}) =>
      this.request<ConversationReplyDto, any>({
        path: `/api/v1/chats/${recipient}/replies`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chats
     * @name PostChatsReadV1
     * @request POST:/api/v1/chats/{recipient}/read
     * @secure
     */
    postChatsReadV1: (recipient: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/chats/${recipient}/read`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chats
     * @name DeleteChatsByIdV1
     * @request DELETE:/api/v1/chats/{id}
     * @secure
     */
    deleteChatsByIdV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/chats/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Do note that this paginationQuery applies it to each of the entities. Meaning that if you set the limit to 3, this would set the limit to 3 for both Messages and Surveys; resulting into 6 items (if both Messages and Surveys have enough available).
     *
     * @tags CommunityFeed
     * @name GetCommunityFeedV1
     * @request GET:/api/v1/community-feed
     * @deprecated
     * @secure
     */
    getCommunityFeedV1: (
      query?: {
        Query?: string;
        PosterId?: string;
        /** @format date-time */
        StartDate?: string;
        /** @format date-time */
        EndDate?: string;
        GroupIds?: string[];
        Tab?: "all" | "announcements" | "own" | "myFeed";
        Connection?: "excluded" | "only" | "included";
        ExcludeScheduledPosts?: boolean;
        ExcludeArchivedPosts?: boolean;
        FeedTypes?: ("messages" | "surveys" | "polls")[];
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CommunityFeedItemV1DtoPaginationResultDto, any>({
        path: `/api/v1/community-feed`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CommunityFeed
     * @name GetCommunityFeedAudienceV1
     * @request GET:/api/v1/community-feed/audience
     * @secure
     */
    getCommunityFeedAudienceV1: (
      query?: {
        /** Whether the endpoint returns only the audience you can create normal posts in, or also the audience you can create announcements in. */
        IncludeAnnouncements?: boolean;
        /** Whether the endpoint returns only the groups you can create project posts in. */
        ExcludeProjectConnections?: boolean;
        GroupId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CommunityFeedAudienceDto, any>({
        path: `/api/v1/community-feed/audience`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CommunityGroupAi
     * @name PostGroupsAiGenerateHeaderImageV1
     * @request POST:/api/v1/groups/{id}/ai/generate-header-image
     * @secure
     */
    postGroupsAiGenerateHeaderImageV1: (
      id: string,
      query?: {
        extraInformation?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<HeaderImageDto[], any>({
        path: `/api/v1/groups/${id}/ai/generate-header-image`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name GetCompaniesV1
     * @summary Retrieves Companies for the logged in user
     * @request GET:/api/v1/companies
     * @secure
     */
    getCompaniesV1: (
      query?: {
        search?: string;
        SortBy?: "name" | "activatedUsers";
        SortDescending?: boolean;
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CompanyDtoPaginationResultDto, any>({
        path: `/api/v1/companies`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name PostCompaniesV1
     * @request POST:/api/v1/companies
     * @secure
     */
    postCompaniesV1: (data: CompanyRequest, params: RequestParams = {}) =>
      this.request<CompanyDto, any>({
        path: `/api/v1/companies`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This endpoint will filter out company the logged in user can't see
     *
     * @tags Companies
     * @name GetCompaniesDetailsV1
     * @summary Retrieves companies by given id
     * @request GET:/api/v1/companies/{id}
     * @secure
     */
    getCompaniesDetailsV1: (id: string, params: RequestParams = {}) =>
      this.request<CompanyDto, any>({
        path: `/api/v1/companies/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name PutCompaniesV1
     * @request PUT:/api/v1/companies/{id}
     * @secure
     */
    putCompaniesV1: (id: string, data: CompanyRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/companies/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name DeleteCompaniesByIdV1
     * @request DELETE:/api/v1/companies/{id}
     * @secure
     */
    deleteCompaniesByIdV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/companies/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name PutCompaniesRegenerateLocationCodeV1
     * @request PUT:/api/v1/companies/{id}/regenerate-location-code
     * @secure
     */
    putCompaniesRegenerateLocationCodeV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/companies/${id}/regenerate-location-code`,
        method: "PUT",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name GetCompaniesMembersV1
     * @request GET:/api/v1/companies/{id}/members
     * @secure
     */
    getCompaniesMembersV1: (
      id: string,
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserDetailsDtoPaginationResultDto, any>({
        path: `/api/v1/companies/${id}/members`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Configuration
     * @name GetConfigurationV1
     * @request GET:/api/v1/configuration
     */
    getConfigurationV1: (params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/v1/configuration`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Constraints
     * @name GetConstraintsV1
     * @request GET:/api/v1/constraints
     * @secure
     */
    getConstraintsV1: (
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ConstraintListItemDtoPaginationResultDto, any>({
        path: `/api/v1/constraints`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Copilot
     * @name PostCopilotV1
     * @request POST:/api/v1/copilot
     * @secure
     */
    postCopilotV1: (data: CopilotRequest, params: RequestParams = {}) =>
      this.request<CopilotResponseDto, any>({
        path: `/api/v1/copilot`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name GetDocumentsV1
     * @request GET:/api/v1/documents
     * @secure
     */
    getDocumentsV1: (params: RequestParams = {}) =>
      this.request<DocumentsRootDto, any>({
        path: `/api/v1/documents`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name GetDocumentsRecentlyUploadedV1
     * @summary Retrieves a list of recently uploaded documents based on the specified criteria.
     * @request GET:/api/v1/documents/recently-uploaded
     * @secure
     */
    getDocumentsRecentlyUploadedV1: (
      query?: {
        /**
         * The date and time since which the documents should be retrieved. If not specified, no date filter is applied. (optional)
         * @format date-time
         */
        since?: string;
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<DocumentsItemDtoPaginationResultDto, any>({
        path: `/api/v1/documents/recently-uploaded`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name GetDocumentsDetailsV1
     * @request GET:/api/v1/documents/{folderId}
     * @secure
     */
    getDocumentsDetailsV1: (
      folderId: string,
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<DocumentsListDto, any>({
        path: `/api/v1/documents/${folderId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name PostDocumentsRootFolderV1
     * @request POST:/api/v1/documents/root-folder
     * @secure
     */
    postDocumentsRootFolderV1: (data: DocumentsRootFolderRequest, params: RequestParams = {}) =>
      this.request<CreatedEntityDto, any>({
        path: `/api/v1/documents/root-folder`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name GetDocumentsRootFolderDetailsV1
     * @request GET:/api/v1/documents/root-folder/{id}
     * @secure
     */
    getDocumentsRootFolderDetailsV1: (id: string, params: RequestParams = {}) =>
      this.request<DocumentsRootFolderDetailsDto, any>({
        path: `/api/v1/documents/root-folder/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name PutDocumentsRootFolderV1
     * @request PUT:/api/v1/documents/root-folder/{id}
     * @secure
     */
    putDocumentsRootFolderV1: (id: string, data: DocumentsRootFolderRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/documents/root-folder/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name DeleteDocumentsRootFolderByIdV1
     * @request DELETE:/api/v1/documents/root-folder/{id}
     * @secure
     */
    deleteDocumentsRootFolderByIdV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/documents/root-folder/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name PostDocumentsFolderV1
     * @request POST:/api/v1/documents/folder
     * @secure
     */
    postDocumentsFolderV1: (data: DocumentsFolderRequest, params: RequestParams = {}) =>
      this.request<CreatedEntityDto, any>({
        path: `/api/v1/documents/folder`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name GetDocumentsFolderDetailsV1
     * @request GET:/api/v1/documents/folder/{id}
     * @secure
     */
    getDocumentsFolderDetailsV1: (id: string, params: RequestParams = {}) =>
      this.request<DocumentsFolderDetailsDto, any>({
        path: `/api/v1/documents/folder/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name PutDocumentsFolderV1
     * @request PUT:/api/v1/documents/folder/{id}
     * @secure
     */
    putDocumentsFolderV1: (id: string, data: DocumentsFolderRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/documents/folder/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name DeleteDocumentsFolderByIdV1
     * @request DELETE:/api/v1/documents/folder/{id}
     * @secure
     */
    deleteDocumentsFolderByIdV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/documents/folder/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name PostDocumentsFileV1
     * @request POST:/api/v1/documents/file
     * @secure
     */
    postDocumentsFileV1: (data: DocumentsFileRequest, params: RequestParams = {}) =>
      this.request<CreatedEntityDto, any>({
        path: `/api/v1/documents/file`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name GetDocumentsFileDetailsV1
     * @request GET:/api/v1/documents/file/{id}
     * @secure
     */
    getDocumentsFileDetailsV1: (id: string, params: RequestParams = {}) =>
      this.request<DocumentsFileDetailsDto, any>({
        path: `/api/v1/documents/file/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name PutDocumentsFileV1
     * @request PUT:/api/v1/documents/file/{id}
     * @secure
     */
    putDocumentsFileV1: (id: string, data: DocumentsFileRequest, params: RequestParams = {}) =>
      this.request<CreatedEntityDto, any>({
        path: `/api/v1/documents/file/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name DeleteDocumentsFileByIdV1
     * @request DELETE:/api/v1/documents/file/{id}
     * @secure
     */
    deleteDocumentsFileByIdV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/documents/file/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Events
     * @name GetEventsV1
     * @request GET:/api/v1/events
     * @secure
     */
    getEventsV1: (
      query: {
        TimeFrame: "future" | "past" | "soon" | "all";
        Category?: "unknown" | "maintenance" | "social" | "sport";
        Search?: string;
        CreatedByMe?: boolean;
        IsSignedUp?: boolean;
        Connection?: "excluded" | "only" | "included";
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<EventDtoPaginationResultDto, any>({
        path: `/api/v1/events`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Events
     * @name PostEventsV1
     * @request POST:/api/v1/events
     * @secure
     */
    postEventsV1: (data: CreateEventRequest, params: RequestParams = {}) =>
      this.request<EventCreatedDto, any>({
        path: `/api/v1/events`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Events
     * @name GetEventsCategoriesV1
     * @request GET:/api/v1/events/categories
     * @secure
     */
    getEventsCategoriesV1: (params: RequestParams = {}) =>
      this.request<EventCategoryDto[], any>({
        path: `/api/v1/events/categories`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Events
     * @name GetEventsDetailsV1
     * @request GET:/api/v1/events/{id}
     * @secure
     */
    getEventsDetailsV1: (
      id: string,
      query?: {
        /** @default false */
        includeAllParticipants?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<EventDetailsDto, any>({
        path: `/api/v1/events/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Events
     * @name PutEventsV1
     * @request PUT:/api/v1/events/{id}
     * @secure
     */
    putEventsV1: (id: string, data: UpdateEventRequest, params: RequestParams = {}) =>
      this.request<EventCreatedDto, any>({
        path: `/api/v1/events/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Events
     * @name DeleteEventsByIdV1
     * @request DELETE:/api/v1/events/{id}
     * @secure
     */
    deleteEventsByIdV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/events/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Events
     * @name GetEventsParticipantsV1
     * @request GET:/api/v1/events/{id}/participants
     * @secure
     */
    getEventsParticipantsV1: (
      id: string,
      query?: {
        search?: string;
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserDetailsDtoPaginationResultDto, any>({
        path: `/api/v1/events/${id}/participants`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Events
     * @name PostEventsSignupV1
     * @request POST:/api/v1/events/{id}/signup
     * @secure
     */
    postEventsSignupV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/events/${id}/signup`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Events
     * @name PostEventsSignoffV1
     * @request POST:/api/v1/events/{id}/signoff
     * @secure
     */
    postEventsSignoffV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/events/${id}/signoff`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Events
     * @name PostEventsReadV1
     * @request POST:/api/v1/events/{event}/read
     * @secure
     */
    postEventsReadV1: (event: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/events/${event}/read`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags FastMenuActionItems
     * @name GetFastActionItemsV1
     * @request GET:/api/v1/fast-action-items
     * @secure
     */
    getFastActionItemsV1: (params: RequestParams = {}) =>
      this.request<FastMenuActionItemDto[], any>({
        path: `/api/v1/fast-action-items`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FastMenuActionItems
     * @name PostFastActionItemsV1
     * @request POST:/api/v1/fast-action-items
     * @secure
     */
    postFastActionItemsV1: (data: CreateFastMenuActionItemRequest, params: RequestParams = {}) =>
      this.request<FastMenuActionItemDto, any>({
        path: `/api/v1/fast-action-items`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FastMenuActionItems
     * @name GetFastActionItemsDetailsV1
     * @request GET:/api/v1/fast-action-items/{id}
     * @secure
     */
    getFastActionItemsDetailsV1: (id: string, params: RequestParams = {}) =>
      this.request<FastMenuActionItemDto, any>({
        path: `/api/v1/fast-action-items/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FastMenuActionItems
     * @name PutFastActionItemsV1
     * @request PUT:/api/v1/fast-action-items/{id}
     * @secure
     */
    putFastActionItemsV1: (id: string, data: UpdateFastMenuActionItemRequest, params: RequestParams = {}) =>
      this.request<FastMenuActionItemDto, any>({
        path: `/api/v1/fast-action-items/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FastMenuActionItems
     * @name DeleteFastActionItemsByIdV1
     * @request DELETE:/api/v1/fast-action-items/{id}
     * @secure
     */
    deleteFastActionItemsByIdV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/fast-action-items/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name PostFilesDocumentsV1
     * @request POST:/api/v1/files/documents
     * @secure
     */
    postFilesDocumentsV1: (
      data: {
        /** @format binary */
        File?: File;
        Link?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DocumentDto, any>({
        path: `/api/v1/files/documents`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name PostFilesImagesV1
     * @request POST:/api/v1/files/images
     * @secure
     */
    postFilesImagesV1: (
      data: {
        Description?: string;
        /** @format binary */
        File: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<ImageDto, any>({
        path: `/api/v1/files/images`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name PostImagesV1
     * @request POST:/api/v1/images
     * @deprecated
     * @secure
     */
    postImagesV1: (
      data: {
        Description?: string;
        /** @format binary */
        File: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<ImageDto, any>({
        path: `/api/v1/images`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GroupNotificationSettings
     * @name GetGroupsNotificationSettingsV1
     * @request GET:/api/v1/groups/{group}/notification-settings
     * @secure
     */
    getGroupsNotificationSettingsV1: (group: string, params: RequestParams = {}) =>
      this.request<AppNotificationGroupSettingDto, any>({
        path: `/api/v1/groups/${group}/notification-settings`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GroupNotificationSettings
     * @name PutGroupsNotificationSettingsV1
     * @request PUT:/api/v1/groups/{group}/notification-settings
     * @secure
     */
    putGroupsNotificationSettingsV1: (
      group: string,
      data: AppNotificationGroupSettingStatusRequest,
      params: RequestParams = {},
    ) =>
      this.request<AppNotificationGroupSettingDto, any>({
        path: `/api/v1/groups/${group}/notification-settings`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name GetGroupsV1
     * @request GET:/api/v1/groups
     * @deprecated
     * @secure
     */
    getGroupsV1: (
      query?: {
        groupType?: "interest" | "helpCategory" | "realEstate";
        search?: string;
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CommunityGroupDtoPaginationResultDto, any>({
        path: `/api/v1/groups`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name PostGroupsV1
     * @request POST:/api/v1/groups
     * @deprecated
     * @secure
     */
    postGroupsV1: (data: CommunityGroupCreateRequest, params: RequestParams = {}) =>
      this.request<CommunityGroupDetailsDto, any>({
        path: `/api/v1/groups`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name GetGroupsDetailsV1
     * @request GET:/api/v1/groups/{id}
     * @deprecated
     * @secure
     */
    getGroupsDetailsV1: (id: string, params: RequestParams = {}) =>
      this.request<CommunityGroupDetailsDto, any>({
        path: `/api/v1/groups/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name PutGroupsV1
     * @request PUT:/api/v1/groups/{id}
     * @deprecated
     * @secure
     */
    putGroupsV1: (id: string, data: CommunityGroupEditRequest, params: RequestParams = {}) =>
      this.request<CommunityGroupDetailsDto, any>({
        path: `/api/v1/groups/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name DeleteGroupsByIdV1
     * @request DELETE:/api/v1/groups/{id}
     * @secure
     */
    deleteGroupsByIdV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/groups/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name PostGroupsFollowV1
     * @request POST:/api/v1/groups/{id}/follow
     * @secure
     */
    postGroupsFollowV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/groups/${id}/follow`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name PostGroupsUnfollowV1
     * @request POST:/api/v1/groups/{id}/unfollow
     * @secure
     */
    postGroupsUnfollowV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/groups/${id}/unfollow`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
 * No description
 *
 * @tags Groups
 * @name PostGroupsSkipPromotionV1
 * @summary Skip the promotion of a group as the owner of the group.
Only the owner of the group can call this endpoint.
 * @request POST:/api/v1/groups/{id}/skip-promotion
 * @secure
 */
    postGroupsSkipPromotionV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/groups/${id}/skip-promotion`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name GetGroupsMembersV1
     * @request GET:/api/v1/groups/{id}/members
     * @secure
     */
    getGroupsMembersV1: (
      id: string,
      query?: {
        search?: string;
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserDetailsDtoPaginationResultDto, any>({
        path: `/api/v1/groups/${id}/members`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name PostGroupsEnableV1
     * @request POST:/api/v1/groups/{id}/enable
     * @secure
     */
    postGroupsEnableV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/groups/${id}/enable`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HomeDnaAdminTickets
     * @name PostAdminTicketsHomednaV1
     * @summary Sends a ticket to HomeDNA service.
     * @request POST:/api/v1/admin/tickets/{ticketId}/homedna
     * @secure
     */
    postAdminTicketsHomednaV1: (ticketId: string, params: RequestParams = {}) =>
      this.request<HomeDnaStatusDto, HomeDnaStatusDto>({
        path: `/api/v1/admin/tickets/${ticketId}/homedna`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags HomeDnaConnector
     * @name PostApiPartnersHomednaProcess
     * @summary Currently supports processing a request for ACC, AFH, or GER status.
     * @request POST:/api/partners/homedna/{ticketId}/process
     */
    postApiPartnersHomednaProcess: (
      ticketId: string,
      data: ProcessRequest,
      query?: {
        "api-version"?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/api/partners/homedna/${ticketId}/process`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name GetImages90X90V1
     * @request GET:/api/v1/images/{id}/90x90
     */
    getImages90x90V1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/images/${id}/90x90`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name GetImages180X180V1
     * @request GET:/api/v1/images/{id}/180x180
     */
    getImages180x180V1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/images/${id}/180x180`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name GetImagesLargeV1
     * @request GET:/api/v1/images/{id}/large
     */
    getImagesLargeV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/images/${id}/large`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Integrations
     * @name GetIntegrationsRemsV1
     * @request GET:/api/v1/integrations/rems
     * @secure
     */
    getIntegrationsRemsV1: (params: RequestParams = {}) =>
      this.request<RemsProjectConfigurationDto, any>({
        path: `/api/v1/integrations/rems`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Integrations
     * @name PutIntegrationsRemsV1
     * @request PUT:/api/v1/integrations/rems
     * @secure
     */
    putIntegrationsRemsV1: (data: RemsProjectConfigurationRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/integrations/rems`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Integrations
     * @name GetIntegrationsZiezodanV1
     * @request GET:/api/v1/integrations/ziezodan
     * @secure
     */
    getIntegrationsZiezodanV1: (params: RequestParams = {}) =>
      this.request<ZiezodanProjectConfigurationDto, any>({
        path: `/api/v1/integrations/ziezodan`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Integrations
     * @name PutIntegrationsZiezodanV1
     * @request PUT:/api/v1/integrations/ziezodan
     * @secure
     */
    putIntegrationsZiezodanV1: (data: ZiezodanProjectConfigurationDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/integrations/ziezodan`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Integrations
     * @name GetIntegrationsIqbiV1
     * @request GET:/api/v1/integrations/iqbi
     * @secure
     */
    getIntegrationsIqbiV1: (params: RequestParams = {}) =>
      this.request<IqbiProjectConfigurationDto, any>({
        path: `/api/v1/integrations/iqbi`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Integrations
     * @name PutIntegrationsIqbiV1
     * @request PUT:/api/v1/integrations/iqbi
     * @secure
     */
    putIntegrationsIqbiV1: (data: IqbiProjectConfigurationDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/integrations/iqbi`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Integrations
     * @name GetIntegrationsMypupV1
     * @request GET:/api/v1/integrations/mypup
     * @secure
     */
    getIntegrationsMypupV1: (params: RequestParams = {}) =>
      this.request<MyPupProjectConfigurationDto, any>({
        path: `/api/v1/integrations/mypup`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Integrations
     * @name PutIntegrationsMypupV1
     * @request PUT:/api/v1/integrations/mypup
     * @secure
     */
    putIntegrationsMypupV1: (data: MyPupProjectConfigurationDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/integrations/mypup`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Integrations
     * @name GetIntegrationsCopilotPromptConfigurationsV1
     * @request GET:/api/v1/integrations/copilot-prompt-configurations
     * @secure
     */
    getIntegrationsCopilotPromptConfigurationsV1: (params: RequestParams = {}) =>
      this.request<CopilotPromptConfigurationsDto, any>({
        path: `/api/v1/integrations/copilot-prompt-configurations`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Integrations
     * @name PutIntegrationsCopilotPromptConfigurationsV1
     * @request PUT:/api/v1/integrations/copilot-prompt-configurations
     * @secure
     */
    putIntegrationsCopilotPromptConfigurationsV1: (data: CopilotPromptConfigurationsDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/integrations/copilot-prompt-configurations`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Integrations
     * @name GetIntegrationsHomednaV1
     * @request GET:/api/v1/integrations/homedna
     * @secure
     */
    getIntegrationsHomednaV1: (params: RequestParams = {}) =>
      this.request<HomeDnaProjectConfigurationDto, any>({
        path: `/api/v1/integrations/homedna`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Integrations
     * @name PutIntegrationsHomednaV1
     * @request PUT:/api/v1/integrations/homedna
     * @secure
     */
    putIntegrationsHomednaV1: (data: HomeDnaProjectConfigurationDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/integrations/homedna`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Intercom
     * @name GetIntercomIdentityV1
     * @request GET:/api/v1/intercom/identity
     * @secure
     */
    getIntercomIdentityV1: (params: RequestParams = {}) =>
      this.request<IntercomIdentityDto, any>({
        path: `/api/v1/intercom/identity`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Iqbi
     * @name GetServicepartnersIqbiConsumptionV1
     * @request GET:/api/v1/servicepartners/iqbi/consumption
     * @secure
     */
    getServicepartnersIqbiConsumptionV1: (
      query?: {
        /**
         * The month for which to get the consumption data. Since this is a date parameter, the day is ignored.
         * @format date
         * @example "2024-01-01"
         */
        month?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ConsumptionResultDto[], void>({
        path: `/api/v1/servicepartners/iqbi/consumption`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags Iqbi
 * @name PostServicepartnersIqbiConfirmAddressV1
 * @summary Confirm current address of the user.
Necessary to ensure the user can use the IQBI service and only see the data since the address confirmation.
 * @request POST:/api/v1/servicepartners/iqbi/confirm-address
 * @secure
 */
    postServicepartnersIqbiConfirmAddressV1: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/servicepartners/iqbi/confirm-address`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Iqbi
     * @name PostServicepartnersIqbiConsentV1
     * @summary Provide consent for the user's address to be used with the IQBI service.
     * @request POST:/api/v1/servicepartners/iqbi/consent
     * @secure
     */
    postServicepartnersIqbiConsentV1: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/servicepartners/iqbi/consent`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Languages
     * @name GetLanguagesV1
     * @request GET:/api/v1/languages
     * @deprecated
     * @secure
     */
    getLanguagesV1: (params: RequestParams = {}) =>
      this.request<LanguageDto[], any>({
        path: `/api/v1/languages`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Languages
     * @name GetLanguagesBaseV1
     * @request GET:/api/v1/languages/base
     * @secure
     */
    getLanguagesBaseV1: (params: RequestParams = {}) =>
      this.request<LanguageDto[], any>({
        path: `/api/v1/languages/base`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Languages
     * @name GetLanguagesAllV1
     * @request GET:/api/v1/languages/all
     * @secure
     */
    getLanguagesAllV1: (params: RequestParams = {}) =>
      this.request<LanguageDto[], any>({
        path: `/api/v1/languages/all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MassMessages
     * @name GetMassMessagesV1
     * @request GET:/api/v1/mass-messages
     * @secure
     */
    getMassMessagesV1: (params: RequestParams = {}) =>
      this.request<CanMassMessageDto, any>({
        path: `/api/v1/mass-messages`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MassMessages
     * @name PostMassMessagesV1
     * @request POST:/api/v1/mass-messages
     * @secure
     */
    postMassMessagesV1: (data: MessageMultipleProjectsCreateRequest, params: RequestParams = {}) =>
      this.request<MassMessageCreatedEntityDto, any>({
        path: `/api/v1/mass-messages`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MassMessages
     * @name GetMassMessagesGroupsV1
     * @request GET:/api/v1/mass-messages/groups
     * @secure
     */
    getMassMessagesGroupsV1: (
      query?: {
        MessageType?: "undefined" | "announcement" | "announcementReadOnly";
        Sorting?: "id" | "name";
      },
      params: RequestParams = {},
    ) =>
      this.request<SystemGroupDto[], any>({
        path: `/api/v1/mass-messages/groups`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MassMessages
     * @name GetMassMessagesProjectsV1
     * @request GET:/api/v1/mass-messages/projects
     * @secure
     */
    getMassMessagesProjectsV1: (
      query?: {
        MessageType?: "undefined" | "announcement" | "announcementReadOnly";
        GroupId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SimpleProjectDto[], any>({
        path: `/api/v1/mass-messages/projects`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessageCommentLikes
     * @name GetMessagesCommentsLikesV1
     * @request GET:/api/v1/messages/{messageId}/comments/{commentId}/likes
     * @secure
     */
    getMessagesCommentsLikesV1: (
      messageId: string,
      commentId: string,
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<MessageCommentLikeDtoPaginationResultDto, any>({
        path: `/api/v1/messages/${messageId}/comments/${commentId}/likes`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessageCommentLikes
     * @name PostMessagesCommentsLikesV1
     * @request POST:/api/v1/messages/{messageId}/comments/{commentId}/likes
     * @secure
     */
    postMessagesCommentsLikesV1: (messageId: string, commentId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/messages/${messageId}/comments/${commentId}/likes`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessageCommentLikes
     * @name DeleteMessagesCommentsLikesV1
     * @request DELETE:/api/v1/messages/{messageId}/comments/{commentId}/likes
     * @secure
     */
    deleteMessagesCommentsLikesV1: (messageId: string, commentId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/messages/${messageId}/comments/${commentId}/likes`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessageComments
     * @name GetMessagesCommentsV1
     * @request GET:/api/v1/messages/{messageId}/comments
     * @secure
     */
    getMessagesCommentsV1: (
      messageId: string,
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
        SortDescending?: boolean;
        SortBy?: "none" | "postedAt";
      },
      params: RequestParams = {},
    ) =>
      this.request<CommentDtoPaginationResultDto, any>({
        path: `/api/v1/messages/${messageId}/comments`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessageComments
     * @name PostMessagesCommentsV1
     * @request POST:/api/v1/messages/{messageId}/comments
     * @secure
     */
    postMessagesCommentsV1: (messageId: string, data: CommentCreateRequest, params: RequestParams = {}) =>
      this.request<CommentDto, any>({
        path: `/api/v1/messages/${messageId}/comments`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessageComments
     * @name GetMessagesCommentsDetailsV1
     * @request GET:/api/v1/messages/{messageId}/comments/{commentId}
     * @secure
     */
    getMessagesCommentsDetailsV1: (messageId: string, commentId: string, params: RequestParams = {}) =>
      this.request<CommentDetailDto, any>({
        path: `/api/v1/messages/${messageId}/comments/${commentId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessageComments
     * @name DeleteMessagesCommentsByIdV1
     * @request DELETE:/api/v1/messages/{messageId}/comments/{commentId}
     * @secure
     */
    deleteMessagesCommentsByIdV1: (messageId: string, commentId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/messages/${messageId}/comments/${commentId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessageComments
     * @name PutMessagesCommentsV1
     * @request PUT:/api/v1/messages/{messageId}/comments/{commentId}
     * @secure
     */
    putMessagesCommentsV1: (
      messageId: string,
      commentId: string,
      data: CommentUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/messages/${messageId}/comments/${commentId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessageInforms
     * @name PostMessagesInformsV1
     * @request POST:/api/v1/messages/{messageId}/informs
     * @secure
     */
    postMessagesInformsV1: (messageId: string, data: MessageInformRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/messages/${messageId}/informs`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessageInforms
     * @name GetMessagesInformsV1
     * @request GET:/api/v1/messages/{messageId}/informs
     * @secure
     */
    getMessagesInformsV1: (
      messageId: string,
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<MessageInformDtoPaginationResultDto, any>({
        path: `/api/v1/messages/${messageId}/informs`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessageInforms
     * @name GetMessagesInformsUsersV1
     * @request GET:/api/v1/messages/{messageId}/informs/users
     * @secure
     */
    getMessagesInformsUsersV1: (
      messageId: string,
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserWithAudienceDtoPaginationResultDto, any>({
        path: `/api/v1/messages/${messageId}/informs/users`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessageLikes
     * @name GetMessagesLikesV1
     * @request GET:/api/v1/messages/{messageId}/likes
     * @secure
     */
    getMessagesLikesV1: (
      messageId: string,
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<MessageLikeDtoPaginationResultDto, any>({
        path: `/api/v1/messages/${messageId}/likes`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessageLikes
     * @name PostMessagesLikeV1
     * @request POST:/api/v1/messages/{messageId}/like
     * @secure
     */
    postMessagesLikeV1: (messageId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/messages/${messageId}/like`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessageLikes
     * @name DeleteMessagesLikeV1
     * @request DELETE:/api/v1/messages/{messageId}/like
     * @secure
     */
    deleteMessagesLikeV1: (messageId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/messages/${messageId}/like`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messages
     * @name GetMessagesV1
     * @request GET:/api/v1/messages
     * @deprecated
     * @secure
     */
    getMessagesV1: (
      query?: {
        Query?: string;
        PosterId?: string;
        /** @format date-time */
        StartDate?: string;
        /** @format date-time */
        EndDate?: string;
        GroupIds?: string[];
        Tab?: "all" | "announcements" | "own" | "myFeed";
        Connection?: "excluded" | "only" | "included";
        ExcludeScheduledPosts?: boolean;
        ExcludeArchivedPosts?: boolean;
        FeedTypes?: ("messages" | "surveys" | "polls")[];
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<MessageV1DtoPaginationResultDto, any>({
        path: `/api/v1/messages`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messages
     * @name PostMessagesV1
     * @request POST:/api/v1/messages
     * @secure
     */
    postMessagesV1: (data: MessageCreateRequest, params: RequestParams = {}) =>
      this.request<MessageCreatedEntityDto, any>({
        path: `/api/v1/messages`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messages
     * @name GetMessagesUnreadStatsV1
     * @request GET:/api/v1/messages/unread-stats
     * @secure
     */
    getMessagesUnreadStatsV1: (params: RequestParams = {}) =>
      this.request<MessageFeedStatsDto, any>({
        path: `/api/v1/messages/unread-stats`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messages
     * @name GetMessagesDetailsV1
     * @request GET:/api/v1/messages/{messageId}
     * @deprecated
     * @secure
     */
    getMessagesDetailsV1: (messageId: string, params: RequestParams = {}) =>
      this.request<MessageDetailV1Dto, any>({
        path: `/api/v1/messages/${messageId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messages
     * @name DeleteMessagesByIdV1
     * @request DELETE:/api/v1/messages/{messageId}
     * @deprecated
     * @secure
     */
    deleteMessagesByIdV1: (messageId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/messages/${messageId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messages
     * @name PutMessagesV1
     * @request PUT:/api/v1/messages/{messageId}
     * @secure
     */
    putMessagesV1: (messageId: string, data: MessageUpdateRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/messages/${messageId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messages
     * @name PostMessagesMarkAllAsReadV1
     * @request POST:/api/v1/messages/markAllAsRead
     * @secure
     */
    postMessagesMarkAllAsReadV1: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/messages/markAllAsRead`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messages
     * @name PostMessagesMarkReadV1
     * @request POST:/api/v1/messages/{message}/markRead
     * @deprecated
     * @secure
     */
    postMessagesMarkReadV1: (message: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/messages/${message}/markRead`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messages
     * @name PostMessagesReadV1
     * @request POST:/api/v1/messages/{message}/read
     * @secure
     */
    postMessagesReadV1: (message: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/messages/${message}/read`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messages
     * @name PostMessagesReportV1
     * @request POST:/api/v1/messages/{messageId}/report
     * @secure
     */
    postMessagesReportV1: (messageId: string, data: MessageReportRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/messages/${messageId}/report`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messages
     * @name PostMessagesChangeStatusV1
     * @request POST:/api/v1/messages/{messageId}/change-status
     * @secure
     */
    postMessagesChangeStatusV1: (messageId: string, data: MessageStatusChangeRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/messages/${messageId}/change-status`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messages
     * @name PutMessagesMuteV1
     * @request PUT:/api/v1/messages/{messageId}/mute
     * @secure
     */
    putMessagesMuteV1: (messageId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/messages/${messageId}/mute`,
        method: "PUT",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messages
     * @name DeleteMessagesMuteV1
     * @request DELETE:/api/v1/messages/{messageId}/mute
     * @secure
     */
    deleteMessagesMuteV1: (messageId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/messages/${messageId}/mute`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messages
     * @name GetMessagesRecentActivityV1
     * @request GET:/api/v1/messages/recent-activity
     * @secure
     */
    getMessagesRecentActivityV1: (params: RequestParams = {}) =>
      this.request<RecentActivityDto, any>({
        path: `/api/v1/messages/recent-activity`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessagesAI
     * @name PostMessagesAiSentimentV1
     * @request POST:/api/v1/messages-ai/{id}/sentiment
     * @secure
     */
    postMessagesAiSentimentV1: (id: string, params: RequestParams = {}) =>
      this.request<MessageSentimentDto, any>({
        path: `/api/v1/messages-ai/${id}/sentiment`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessagesAI
     * @name PostMessagesAiTopicsV1
     * @request POST:/api/v1/messages-ai/{id}/topics
     * @secure
     */
    postMessagesAiTopicsV1: (id: string, params: RequestParams = {}) =>
      this.request<MessageTopicsDto, any>({
        path: `/api/v1/messages-ai/${id}/topics`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MyPup
     * @name PostServicepartnersMypupRegisterV1
     * @request POST:/api/v1/servicepartners/mypup/register
     * @secure
     */
    postServicepartnersMypupRegisterV1: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/servicepartners/mypup/register`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name PostNotificationsAppRegistrationV1
     * @request POST:/api/v1/notifications/app/registration
     * @secure
     */
    postNotificationsAppRegistrationV1: (data: DeviceRegistrationRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/notifications/app/registration`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name DeleteNotificationsAppRegistrationByIdV1
     * @request DELETE:/api/v1/notifications/app/registration/{token}
     */
    deleteNotificationsAppRegistrationByIdV1: (token: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/notifications/app/registration/${token}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name PostNotificationsPusherAuthenticateV1
     * @request POST:/api/v1/notifications/pusher/authenticate
     * @secure
     */
    postNotificationsPusherAuthenticateV1: (data: NotificationAuthenticationRequest, params: RequestParams = {}) =>
      this.request<IAuthenticationData, any>({
        path: `/api/v1/notifications/pusher/authenticate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags NotificationSettings
     * @name GetNotificationSettingsSelfV1
     * @request GET:/api/v1/notification-settings/self
     * @secure
     */
    getNotificationSettingsSelfV1: (params: RequestParams = {}) =>
      this.request<NotificationSettingsDto, any>({
        path: `/api/v1/notification-settings/self`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags NotificationSettings
     * @name PatchNotificationSettingsSelfV1
     * @request PATCH:/api/v1/notification-settings/self
     * @secure
     */
    patchNotificationSettingsSelfV1: (data: NotificationSettingsRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/notification-settings/self`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags NotificationSettings
     * @name GetNotificationSettingsDetailsV1
     * @request GET:/api/v1/notification-settings/{userId}
     * @secure
     */
    getNotificationSettingsDetailsV1: (userId: string, params: RequestParams = {}) =>
      this.request<NotificationSettingsDto, any>({
        path: `/api/v1/notification-settings/${userId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags NotificationSettings
     * @name PatchNotificationSettingsV1
     * @request PATCH:/api/v1/notification-settings/{userId}
     * @secure
     */
    patchNotificationSettingsV1: (userId: string, data: NotificationSettingsRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/notification-settings/${userId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OnboardingScreens
     * @name GetOnboardingScreensV1
     * @request GET:/api/v1/onboarding-screens
     * @secure
     */
    getOnboardingScreensV1: (params: RequestParams = {}) =>
      this.request<OnboardingScreenDtoPaginationResultDto, any>({
        path: `/api/v1/onboarding-screens`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags OnboardingScreens
     * @name PostOnboardingScreensV1
     * @request POST:/api/v1/onboarding-screens
     * @secure
     */
    postOnboardingScreensV1: (data: OnboardingScreenCreateRequest, params: RequestParams = {}) =>
      this.request<OnboardingScreenDetailsDto, any>({
        path: `/api/v1/onboarding-screens`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags OnboardingScreens
     * @name GetOnboardingScreensDetailsV1
     * @request GET:/api/v1/onboarding-screens/{id}
     * @secure
     */
    getOnboardingScreensDetailsV1: (id: string, params: RequestParams = {}) =>
      this.request<OnboardingScreenDetailsDto, any>({
        path: `/api/v1/onboarding-screens/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags OnboardingScreens
     * @name PutOnboardingScreensV1
     * @request PUT:/api/v1/onboarding-screens/{id}
     * @secure
     */
    putOnboardingScreensV1: (id: string, data: OnboardingScreenEditRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/onboarding-screens/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OnboardingScreens
     * @name DeleteOnboardingScreensByIdV1
     * @request DELETE:/api/v1/onboarding-screens/{id}
     * @secure
     */
    deleteOnboardingScreensByIdV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/onboarding-screens/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permissions
     * @name GetPermissionsRolesV1
     * @request GET:/api/v1/permissions/roles
     * @secure
     */
    getPermissionsRolesV1: (params: RequestParams = {}) =>
      this.request<UserRoleDto[], any>({
        path: `/api/v1/permissions/roles`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permissions
     * @name PostPermissionsRolesV1
     * @request POST:/api/v1/permissions/roles
     * @secure
     */
    postPermissionsRolesV1: (data: UserRoleRequest, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/v1/permissions/roles`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permissions
     * @name GetPermissionsDefaultTypeV1
     * @request GET:/api/v1/permissions/default/type
     * @secure
     */
    getPermissionsDefaultTypeV1: (params: RequestParams = {}) =>
      this.request<UserRoleTypePermissionsDto[], any>({
        path: `/api/v1/permissions/default/type`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permissions
     * @name GetPermissionsDefaultGroupV1
     * @request GET:/api/v1/permissions/default/group
     * @secure
     */
    getPermissionsDefaultGroupV1: (params: RequestParams = {}) =>
      this.request<UserRoleCommunityGroupPermissionsDto[], any>({
        path: `/api/v1/permissions/default/group`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permissions
     * @name GetPermissionsDefaultRepairCategoryV1
     * @request GET:/api/v1/permissions/default/repair-category
     * @secure
     */
    getPermissionsDefaultRepairCategoryV1: (params: RequestParams = {}) =>
      this.request<UserRoleRepairCategoryPermissionsDto[], any>({
        path: `/api/v1/permissions/default/repair-category`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permissions
     * @name GetPermissionsDefaultServicePageV1
     * @request GET:/api/v1/permissions/default/service-page
     * @secure
     */
    getPermissionsDefaultServicePageV1: (params: RequestParams = {}) =>
      this.request<UserRoleServicePartnerPagePermissionsDto[], any>({
        path: `/api/v1/permissions/default/service-page`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permissions
     * @name GetPermissionsDefaultFolderV1
     * @request GET:/api/v1/permissions/default/folder
     * @secure
     */
    getPermissionsDefaultFolderV1: (params: RequestParams = {}) =>
      this.request<UserRoleDocumentFolderPermissionsDto[], any>({
        path: `/api/v1/permissions/default/folder`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permissions
     * @name GetPermissionsRolesDetailsV1
     * @request GET:/api/v1/permissions/roles/{id}
     * @secure
     */
    getPermissionsRolesDetailsV1: (id: string, params: RequestParams = {}) =>
      this.request<UserRoleDetailsDto, any>({
        path: `/api/v1/permissions/roles/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permissions
     * @name PutPermissionsRolesV1
     * @request PUT:/api/v1/permissions/roles/{id}
     * @secure
     */
    putPermissionsRolesV1: (id: string, data: UserRoleRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/permissions/roles/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permissions
     * @name DeletePermissionsRolesByIdV1
     * @request DELETE:/api/v1/permissions/roles/{id}
     * @secure
     */
    deletePermissionsRolesByIdV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/permissions/roles/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Polls
     * @name GetPollsV1
     * @request GET:/api/v1/polls
     * @secure
     */
    getPollsV1: (
      query?: {
        Query?: string;
        PosterId?: string;
        /** @format date-time */
        StartDate?: string;
        /** @format date-time */
        EndDate?: string;
        GroupIds?: string[];
        Tab?: "all" | "announcements" | "own" | "myFeed";
        Connection?: "excluded" | "only" | "included";
        ExcludeScheduledPosts?: boolean;
        ExcludeArchivedPosts?: boolean;
        FeedTypes?: ("messages" | "surveys" | "polls")[];
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PollDtoPaginationResult, any>({
        path: `/api/v1/polls`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Polls
     * @name PostPollsV1
     * @request POST:/api/v1/polls
     * @secure
     */
    postPollsV1: (data: PollCreateRequest, params: RequestParams = {}) =>
      this.request<CreatedEntityDto, any>({
        path: `/api/v1/polls`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Polls
     * @name GetPollsDetailsV1
     * @request GET:/api/v1/polls/{id}
     * @secure
     */
    getPollsDetailsV1: (id: string, params: RequestParams = {}) =>
      this.request<PollDto, any>({
        path: `/api/v1/polls/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Polls
     * @name PutPollsV1
     * @request PUT:/api/v1/polls/{id}
     * @secure
     */
    putPollsV1: (id: string, data: PollUpdateRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/polls/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Polls
     * @name DeletePollsByIdV1
     * @request DELETE:/api/v1/polls/{id}
     * @secure
     */
    deletePollsByIdV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/polls/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Polls
     * @name PostPollsVoteV1
     * @request POST:/api/v1/polls/{id}/vote
     * @secure
     */
    postPollsVoteV1: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/polls/${id}/vote`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Polls
     * @name DeletePollsVoteV1
     * @request DELETE:/api/v1/polls/{id}/vote
     * @secure
     */
    deletePollsVoteV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/polls/${id}/vote`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Polls
     * @name GetPollsAnswersV1
     * @request GET:/api/v1/polls/{id}/answers
     * @secure
     */
    getPollsAnswersV1: (id: string, params: RequestParams = {}) =>
      this.request<PollAnswersResultsDto, any>({
        path: `/api/v1/polls/${id}/answers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Polls
     * @name GetPollsAnswersDetailsV1
     * @request GET:/api/v1/polls/{id}/answers/{answerId}
     * @secure
     */
    getPollsAnswersDetailsV1: (id: string, answerId: string, params: RequestParams = {}) =>
      this.request<PollAnswersResultsDto, any>({
        path: `/api/v1/polls/${id}/answers/${answerId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Polls
     * @name PostPollsReadV1
     * @request POST:/api/v1/polls/{id}/read
     * @secure
     */
    postPollsReadV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/polls/${id}/read`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PortfolioAdminTickets
     * @name GetAdminPortfolioTicketsV1
     * @request GET:/api/v1/admin/portfolio-tickets
     * @secure
     */
    getAdminPortfolioTicketsV1: (
      query?: {
        Ratings?: number[];
        ProjectIds?: string[];
        UnreadOnly?: boolean;
        Filter?: "private" | "collective";
        SortBy?:
          | "unread"
          | "newest"
          | "oldest"
          | "leastRecentActivity"
          | "mostRecentActivity"
          | "leastRecentResidentActivity"
          | "mostRecentResidentActivity"
          | "mostRecentReminder";
        Tab?: "inProgress" | "new" | "closed" | "reminder";
        Search?: string;
        ResidentFocusMode?: boolean;
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PortfolioAdminTicketDtoPaginationResultDto, any>({
        path: `/api/v1/admin/portfolio-tickets`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PortfolioAdminTickets
     * @name PostAdminPortfolioTicketsReadV1
     * @request POST:/api/v1/admin/portfolio-tickets/read
     * @secure
     */
    postAdminPortfolioTicketsReadV1: (
      query?: {
        Ratings?: number[];
        ProjectIds?: string[];
        UnreadOnly?: boolean;
        Filter?: "private" | "collective";
        SortBy?:
          | "unread"
          | "newest"
          | "oldest"
          | "leastRecentActivity"
          | "mostRecentActivity"
          | "leastRecentResidentActivity"
          | "mostRecentResidentActivity"
          | "mostRecentReminder";
        Tab?: "inProgress" | "new" | "closed" | "reminder";
        Search?: string;
        ResidentFocusMode?: boolean;
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/admin/portfolio-tickets/read`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PortfolioAdminTickets
     * @name GetAdminPortfolioTicketsStatsDetailsV1
     * @request GET:/api/v1/admin/portfolio-tickets/stats/{tab}
     * @secure
     */
    getAdminPortfolioTicketsStatsDetailsV1: (
      tab: "inProgress" | "new" | "closed" | "reminder",
      params: RequestParams = {},
    ) =>
      this.request<AdminTicketStatsDto, any>({
        path: `/api/v1/admin/portfolio-tickets/stats/${tab}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PortfolioAdminTickets
     * @name GetAdminPortfolioTicketsStatsAllTabsV1
     * @request GET:/api/v1/admin/portfolio-tickets/stats/all-tabs
     * @secure
     */
    getAdminPortfolioTicketsStatsAllTabsV1: (
      query?: {
        Ratings?: number[];
        ProjectIds?: string[];
        UnreadOnly?: boolean;
        Filter?: "private" | "collective";
        SortBy?:
          | "unread"
          | "newest"
          | "oldest"
          | "leastRecentActivity"
          | "mostRecentActivity"
          | "leastRecentResidentActivity"
          | "mostRecentResidentActivity"
          | "mostRecentReminder";
        Tab?: "inProgress" | "new" | "closed" | "reminder";
        Search?: string;
        ResidentFocusMode?: boolean;
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<AdminTicketTabsStatsDto, any>({
        path: `/api/v1/admin/portfolio-tickets/stats/all-tabs`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PortfolioAdminTickets
     * @name GetAdminPortfolioTicketsIdsV1
     * @summary Used for the 'next' button in the ticket details view
     * @request GET:/api/v1/admin/portfolio-tickets/ids
     * @secure
     */
    getAdminPortfolioTicketsIdsV1: (
      query?: {
        Ratings?: number[];
        ProjectIds?: string[];
        UnreadOnly?: boolean;
        Filter?: "private" | "collective";
        SortBy?:
          | "unread"
          | "newest"
          | "oldest"
          | "leastRecentActivity"
          | "mostRecentActivity"
          | "leastRecentResidentActivity"
          | "mostRecentResidentActivity"
          | "mostRecentReminder";
        Tab?: "inProgress" | "new" | "closed" | "reminder";
        Search?: string;
        ResidentFocusMode?: boolean;
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PortfolioMinimalTicketDto[], any>({
        path: `/api/v1/admin/portfolio-tickets/ids`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PortfolioAnalytics
     * @name GetPortfolioAnalyticsResidentEngagementV1
     * @request GET:/api/v1/portfolio-analytics/resident-engagement
     * @secure
     */
    getPortfolioAnalyticsResidentEngagementV1: (params: RequestParams = {}) =>
      this.request<ResidentTimeEngagementDto, any>({
        path: `/api/v1/portfolio-analytics/resident-engagement`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name GetProjectOverviewV1
     * @request GET:/api/v1/project/overview
     * @secure
     */
    getProjectOverviewV1: (
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
        /** @format date-time */
        month?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProjectOverviewItemDtoPaginationResultDto, any>({
        path: `/api/v1/project/overview`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name GetProjectOverviewBenchmarkV1
     * @request GET:/api/v1/project/overview/benchmark
     * @secure
     */
    getProjectOverviewBenchmarkV1: (
      query?: {
        /** @format date-time */
        month?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProjectStatsDto, any>({
        path: `/api/v1/project/overview/benchmark`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name GetProjectDetailsV1
     * @request GET:/api/v1/project/details
     * @secure
     */
    getProjectDetailsV1: (params: RequestParams = {}) =>
      this.request<ProjectDetailedDto, any>({
        path: `/api/v1/project/details`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name PostProjectV1
     * @request POST:/api/v1/project
     * @secure
     */
    postProjectV1: (data: ProjectCreateRequest, params: RequestParams = {}) =>
      this.request<ProjectDetailedDto, any>({
        path: `/api/v1/project`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name PutProjectV1
     * @request PUT:/api/v1/project/{id}
     * @secure
     */
    putProjectV1: (id: string, data: ProjectEditRequest, params: RequestParams = {}) =>
      this.request<ProjectDetailedDto, any>({
        path: `/api/v1/project/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name PutProjectStylingV1
     * @request PUT:/api/v1/project/{id}/styling
     * @secure
     */
    putProjectStylingV1: (id: string, data: ProjectStylingRequest, params: RequestParams = {}) =>
      this.request<ProjectDetailedDto, any>({
        path: `/api/v1/project/${id}/styling`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProjectConnectionGroups
     * @name GetProjectConnectionsGroupsV1
     * @request GET:/api/v1/project-connections/{connectionId}/groups
     * @secure
     */
    getProjectConnectionsGroupsV1: (
      connectionId: string,
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProjectConnectionGroupDtoPaginationResultDto, any>({
        path: `/api/v1/project-connections/${connectionId}/groups`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProjectConnectionGroups
     * @name PostProjectConnectionsGroupsV1
     * @request POST:/api/v1/project-connections/{connectionId}/groups
     * @secure
     */
    postProjectConnectionsGroupsV1: (
      connectionId: string,
      data: ProjectConnectionGroupCreateRequest,
      params: RequestParams = {},
    ) =>
      this.request<ProjectConnectionGroupDetailDto, any>({
        path: `/api/v1/project-connections/${connectionId}/groups`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProjectConnectionGroups
     * @name GetProjectConnectionsGroupsDetailsV1
     * @request GET:/api/v1/project-connections/{connectionId}/groups/{id}
     * @secure
     */
    getProjectConnectionsGroupsDetailsV1: (id: string, connectionId: string, params: RequestParams = {}) =>
      this.request<ProjectConnectionGroupDetailDto, any>({
        path: `/api/v1/project-connections/${connectionId}/groups/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProjectConnectionGroups
     * @name PutProjectConnectionsGroupsV1
     * @request PUT:/api/v1/project-connections/{connectionId}/groups/{id}
     * @secure
     */
    putProjectConnectionsGroupsV1: (
      connectionId: string,
      id: string,
      data: ProjectConnectionGroupUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/project-connections/${connectionId}/groups/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProjectConnectionGroups
     * @name DeleteProjectConnectionsGroupsByIdV1
     * @request DELETE:/api/v1/project-connections/{connectionId}/groups/{id}
     * @secure
     */
    deleteProjectConnectionsGroupsByIdV1: (connectionId: string, id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/project-connections/${connectionId}/groups/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProjectConnectionGroups
     * @name PostProjectConnectionsGroupsEnableV1
     * @request POST:/api/v1/project-connections/{connectionId}/groups/{id}/enable/{projectId}
     * @secure
     */
    postProjectConnectionsGroupsEnableV1: (
      id: string,
      projectId: string,
      connectionId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/project-connections/${connectionId}/groups/${id}/enable/${projectId}`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * @description Groups are only returned if IncludeAllFromProfile is true
     *
     * @tags ProjectConnections
     * @name GetProjectConnectionsV1
     * @request GET:/api/v1/project-connections
     * @secure
     */
    getProjectConnectionsV1: (
      query?: {
        /** Only get project connections that this project is in */
        IncludeAllFromProfile?: boolean;
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProjectConnectionDetailsDtoPaginationResultDto, any>({
        path: `/api/v1/project-connections`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProjectConnections
     * @name PostProjectConnectionsV1
     * @request POST:/api/v1/project-connections
     * @secure
     */
    postProjectConnectionsV1: (data: ProjectConnectionCreateRequest, params: RequestParams = {}) =>
      this.request<ProjectConnectionDetailsDto, any>({
        path: `/api/v1/project-connections`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProjectConnections
     * @name GetProjectConnectionsDetailsV1
     * @request GET:/api/v1/project-connections/{id}
     * @secure
     */
    getProjectConnectionsDetailsV1: (id: string, params: RequestParams = {}) =>
      this.request<ProjectConnectionDetailsDto, any>({
        path: `/api/v1/project-connections/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProjectConnections
     * @name PutProjectConnectionsV1
     * @request PUT:/api/v1/project-connections/{id}
     * @secure
     */
    putProjectConnectionsV1: (id: string, data: ProjectConnectionEditRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/project-connections/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProjectConnections
     * @name DeleteProjectConnectionsByIdV1
     * @request DELETE:/api/v1/project-connections/{id}
     * @secure
     */
    deleteProjectConnectionsByIdV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/project-connections/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProjectConnections
     * @name GetProjectConnectionsInvitesV1
     * @request GET:/api/v1/project-connections/invites
     * @secure
     */
    getProjectConnectionsInvitesV1: (params: RequestParams = {}) =>
      this.request<ProjectConnectionDetailsDto[], any>({
        path: `/api/v1/project-connections/invites`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProjectConnections
     * @name PostProjectConnectionsJoinV1
     * @request POST:/api/v1/project-connections/{id}/join
     * @secure
     */
    postProjectConnectionsJoinV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/project-connections/${id}/join`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProjectConnections
     * @name PatchProjectConnectionsSettingsV1
     * @request PATCH:/api/v1/project-connections/settings
     * @secure
     */
    patchProjectConnectionsSettingsV1: (data: ProjectConnectionUserSettingsRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/project-connections/settings`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProjectConnections
     * @name PatchProjectConnectionsPrivacySettingsV1
     * @request PATCH:/api/v1/project-connections/privacy-settings
     * @secure
     */
    patchProjectConnectionsPrivacySettingsV1: (
      data: ProjectConnectionUserPrivacySettingsRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/project-connections/privacy-settings`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProjectConnections
     * @name PostProjectConnectionsPrivacySettingsSeenV1
     * @request POST:/api/v1/project-connections/privacy-settings/seen
     * @secure
     */
    postProjectConnectionsPrivacySettingsSeenV1: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/project-connections/privacy-settings/seen`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProjectSummary
     * @name GetProjectSummaryV1
     * @request GET:/api/v1/project/summary
     * @secure
     */
    getProjectSummaryV1: (
      query?: {
        /**
         * Date from when posts should be used to load a summary
         * @format date-time
         */
        from?: string;
        /**
         * Date to when posts should be used to load a summary
         * @format date-time
         */
        to?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProjectSummaryDto, any>({
        path: `/api/v1/project/summary`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyMessage
     * @name PostQuickReplyMessageCreateTokenV1
     * @request POST:/api/v1/QuickReplyMessage/create-token
     * @secure
     */
    postQuickReplyMessageCreateTokenV1: (data: QuickReplyMessageCreateTokenRequest, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/v1/QuickReplyMessage/create-token`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyMessage
     * @name GetQuickReplyMessageDetailsV1
     * @request GET:/api/v1/QuickReplyMessage/{token}
     */
    getQuickReplyMessageDetailsV1: (token: string, params: RequestParams = {}) =>
      this.request<QuickReplyMessageDto, any>({
        path: `/api/v1/QuickReplyMessage/${token}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyMessage
     * @name GetQuickReplyMessageCommentsV1
     * @request GET:/api/v1/QuickReplyMessage/{token}/comments
     */
    getQuickReplyMessageCommentsV1: (
      token: string,
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<QuickReplyMessageCommentDto[], any>({
        path: `/api/v1/QuickReplyMessage/${token}/comments`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyMessage
     * @name PostQuickReplyMessageLikeV1
     * @request POST:/api/v1/QuickReplyMessage/{token}/like
     */
    postQuickReplyMessageLikeV1: (token: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/QuickReplyMessage/${token}/like`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyMessage
     * @name PostQuickReplyMessageUnlikeV1
     * @request POST:/api/v1/QuickReplyMessage/{token}/unlike
     */
    postQuickReplyMessageUnlikeV1: (token: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/QuickReplyMessage/${token}/unlike`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyMessage
     * @name GetQuickReplyMessageTranslationsDetailsV1
     * @request GET:/api/v1/QuickReplyMessage/{token}/translations/{isoCode}
     */
    getQuickReplyMessageTranslationsDetailsV1: (token: string, isoCode: string, params: RequestParams = {}) =>
      this.request<MessageTranslationDto, any>({
        path: `/api/v1/QuickReplyMessage/${token}/translations/${isoCode}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyMessage
     * @name PostQuickReplyMessageReplyImageV1
     * @request POST:/api/v1/QuickReplyMessage/{token}/reply/image
     */
    postQuickReplyMessageReplyImageV1: (
      token: string,
      data: {
        Description?: string;
        /** @format binary */
        File: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<ImageDto, any>({
        path: `/api/v1/QuickReplyMessage/${token}/reply/image`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyMessage
     * @name PostQuickReplyMessageReplyV1
     * @request POST:/api/v1/QuickReplyMessage/{token}/reply
     */
    postQuickReplyMessageReplyV1: (
      token: string,
      data: QuickReplyMessageCreateCommentRequest,
      params: RequestParams = {},
    ) =>
      this.request<QuickReplyMessageCommentDto, any>({
        path: `/api/v1/QuickReplyMessage/${token}/reply`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyMessage
     * @name PutQuickReplyMessageReplyV1
     * @request PUT:/api/v1/QuickReplyMessage/{token}/reply/{id}
     */
    putQuickReplyMessageReplyV1: (
      token: string,
      id: string,
      data: QuickReplyMessageCreateCommentRequest,
      params: RequestParams = {},
    ) =>
      this.request<QuickReplyMessageCommentDto, any>({
        path: `/api/v1/QuickReplyMessage/${token}/reply/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyMessage
     * @name DeleteQuickReplyMessageReplyByIdV1
     * @request DELETE:/api/v1/QuickReplyMessage/{token}/reply/{id}
     */
    deleteQuickReplyMessageReplyByIdV1: (token: string, id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/QuickReplyMessage/${token}/reply/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyMessage
     * @name GetQuickReplyMessageReplyTranslationsDetailsV1
     * @request GET:/api/v1/QuickReplyMessage/{token}/reply/{id}/translations/{isoCode}
     */
    getQuickReplyMessageReplyTranslationsDetailsV1: (
      token: string,
      id: string,
      isoCode: string,
      params: RequestParams = {},
    ) =>
      this.request<CommentTranslationDto, any>({
        path: `/api/v1/QuickReplyMessage/${token}/reply/${id}/translations/${isoCode}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyMessage
     * @name GetQuickReplyMessageReplyLikeV1
     * @request GET:/api/v1/QuickReplyMessage/{token}/reply/{id}/like
     */
    getQuickReplyMessageReplyLikeV1: (token: string, id: string, params: RequestParams = {}) =>
      this.request<QuickReplyMessageCommentDto, any>({
        path: `/api/v1/QuickReplyMessage/${token}/reply/${id}/like`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyMessage
     * @name GetQuickReplyMessageReplyUnlikeV1
     * @request GET:/api/v1/QuickReplyMessage/{token}/reply/{id}/unlike
     */
    getQuickReplyMessageReplyUnlikeV1: (token: string, id: string, params: RequestParams = {}) =>
      this.request<QuickReplyMessageCommentDto, any>({
        path: `/api/v1/QuickReplyMessage/${token}/reply/${id}/unlike`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyRepairRequest
     * @name PostQuickReplyRepairRequestCreateTokenV1
     * @request POST:/api/v1/QuickReplyRepairRequest/create-token
     * @secure
     */
    postQuickReplyRepairRequestCreateTokenV1: (
      data: QuickReplyRepairRequestCreateTokenRequest,
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/v1/QuickReplyRepairRequest/create-token`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyRepairRequest
     * @name GetQuickReplyRepairRequestDetailsV1
     * @request GET:/api/v1/QuickReplyRepairRequest/{token}
     */
    getQuickReplyRepairRequestDetailsV1: (token: string, params: RequestParams = {}) =>
      this.request<QuickReplyRepairRequestDto, any>({
        path: `/api/v1/QuickReplyRepairRequest/${token}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyRepairRequest
     * @name GetQuickReplyRepairRequestCommentsV1
     * @request GET:/api/v1/QuickReplyRepairRequest/{token}/comments
     * @deprecated
     */
    getQuickReplyRepairRequestCommentsV1: (
      token: string,
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<QuickReplyRepairRequestCommentDto[], any>({
        path: `/api/v1/QuickReplyRepairRequest/${token}/comments`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyRepairRequest
     * @name GetQuickReplyRepairRequestActivitiesV1
     * @request GET:/api/v1/QuickReplyRepairRequest/{token}/activities
     */
    getQuickReplyRepairRequestActivitiesV1: (
      token: string,
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<QuickReplyRepairRequestActivityDtoPaginationResultDto, any>({
        path: `/api/v1/QuickReplyRepairRequest/${token}/activities`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyRepairRequest
     * @name GetQuickReplyRepairRequestTranslationsDetailsV1
     * @request GET:/api/v1/QuickReplyRepairRequest/{token}/translations/{isoCode}
     */
    getQuickReplyRepairRequestTranslationsDetailsV1: (token: string, isoCode: string, params: RequestParams = {}) =>
      this.request<TicketTranslationDto, any>({
        path: `/api/v1/QuickReplyRepairRequest/${token}/translations/${isoCode}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyRepairRequest
     * @name GetQuickReplyRepairRequestActivitiesTranslationsDetailsV1
     * @request GET:/api/v1/QuickReplyRepairRequest/{token}/activities/{commentId}/translations/{isoCode}
     */
    getQuickReplyRepairRequestActivitiesTranslationsDetailsV1: (
      token: string,
      commentId: string,
      isoCode: string,
      params: RequestParams = {},
    ) =>
      this.request<TicketCommentTranslationDto, any>({
        path: `/api/v1/QuickReplyRepairRequest/${token}/activities/${commentId}/translations/${isoCode}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyRepairRequest
     * @name PostQuickReplyRepairRequestReplyImageV1
     * @request POST:/api/v1/QuickReplyRepairRequest/{token}/reply/image
     */
    postQuickReplyRepairRequestReplyImageV1: (
      token: string,
      data: {
        Description?: string;
        /** @format binary */
        File: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<ImageDto, any>({
        path: `/api/v1/QuickReplyRepairRequest/${token}/reply/image`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyRepairRequest
     * @name PostQuickReplyRepairRequestReplyV1
     * @request POST:/api/v1/QuickReplyRepairRequest/{token}/reply
     */
    postQuickReplyRepairRequestReplyV1: (
      token: string,
      data: QuickReplyRepairRequestCreateCommentRequest,
      params: RequestParams = {},
    ) =>
      this.request<QuickReplyRepairRequestCommentDto, any>({
        path: `/api/v1/QuickReplyRepairRequest/${token}/reply`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyRepairRequest
     * @name PutQuickReplyRepairRequestReplyV1
     * @request PUT:/api/v1/QuickReplyRepairRequest/{token}/reply/{commentId}
     */
    putQuickReplyRepairRequestReplyV1: (
      token: string,
      commentId: string,
      data: QuickReplyRepairRequestEditCommentRequest,
      params: RequestParams = {},
    ) =>
      this.request<QuickReplyRepairRequestCommentDto, any>({
        path: `/api/v1/QuickReplyRepairRequest/${token}/reply/${commentId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyRepairRequest
     * @name PutQuickReplyRepairRequestAssigneeV1
     * @request PUT:/api/v1/QuickReplyRepairRequest/{token}/assignee
     */
    putQuickReplyRepairRequestAssigneeV1: (
      token: string,
      data: QuickReplyRepairRequestAssigneeChangeRequest,
      params: RequestParams = {},
    ) =>
      this.request<QuickReplyRepairRequestCommentDto, any>({
        path: `/api/v1/QuickReplyRepairRequest/${token}/assignee`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyRepairRequest
     * @name PutQuickReplyRepairRequestStatusV1
     * @request PUT:/api/v1/QuickReplyRepairRequest/{token}/status
     */
    putQuickReplyRepairRequestStatusV1: (
      token: string,
      data: QuickReplyRepairRequestStatusChangeRequest,
      params: RequestParams = {},
    ) =>
      this.request<QuickReplyRepairRequestStatusChangeDto, any>({
        path: `/api/v1/QuickReplyRepairRequest/${token}/status`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyRepairRequest
     * @name PostQuickReplyRepairRequestRemsSyncV1
     * @request POST:/api/v1/QuickReplyRepairRequest/{token}/rems-sync
     */
    postQuickReplyRepairRequestRemsSyncV1: (token: string, params: RequestParams = {}) =>
      this.request<RemsStatusDto, any>({
        path: `/api/v1/QuickReplyRepairRequest/${token}/rems-sync`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickReplyRepairRequest
     * @name PostQuickReplyRepairRequestRemsSendTicketV1
     * @request POST:/api/v1/QuickReplyRepairRequest/{token}/rems-send-ticket
     */
    postQuickReplyRepairRequestRemsSendTicketV1: (token: string, data: RemsSyncRequest, params: RequestParams = {}) =>
      this.request<RemsStatusDto, any>({
        path: `/api/v1/QuickReplyRepairRequest/${token}/rems-send-ticket`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Registration
     * @name PostRegistrationValidateCodeV1
     * @request POST:/api/v1/registration/validate-code
     */
    postRegistrationValidateCodeV1: (data: ValidateRegistrationCodeRequest, params: RequestParams = {}) =>
      this.request<ValidatedRegistrationCodeDto, any>({
        path: `/api/v1/registration/validate-code`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Registration
     * @name PostRegistrationConnectV1
     * @request POST:/api/v1/registration/connect
     * @deprecated
     * @secure
     */
    postRegistrationConnectV1: (data: RegistrationRequestV1, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/registration/connect`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResidentGroups
     * @name PostResidentGroupsV1
     * @request POST:/api/v1/resident-groups
     * @deprecated
     * @secure
     */
    postResidentGroupsV1: (data: ResidentGroupCreateRequest, params: RequestParams = {}) =>
      this.request<CommunityGroupDetailsDto, any>({
        path: `/api/v1/resident-groups`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResidentGroups
     * @name PutResidentGroupsV1
     * @request PUT:/api/v1/resident-groups/{id}
     * @deprecated
     * @secure
     */
    putResidentGroupsV1: (id: string, data: ResidentGroupEditRequest, params: RequestParams = {}) =>
      this.request<CommunityGroupDetailsDto, any>({
        path: `/api/v1/resident-groups/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResidentNotifications
     * @name GetResidentNotificationsV1
     * @request GET:/api/v1/resident-notifications
     * @secure
     */
    getResidentNotificationsV1: (
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ResidentNotificationDtoPaginationResultDto, any>({
        path: `/api/v1/resident-notifications`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResidentNotifications
     * @name GetResidentNotificationsStatusV1
     * @request GET:/api/v1/resident-notifications/status
     * @deprecated
     * @secure
     */
    getResidentNotificationsStatusV1: (params: RequestParams = {}) =>
      this.request<UnreadStatusDto, any>({
        path: `/api/v1/resident-notifications/status`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResidentNotifications
     * @name DeleteResidentNotificationsBadgeV1
     * @request DELETE:/api/v1/resident-notifications/badge
     * @secure
     */
    deleteResidentNotificationsBadgeV1: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/resident-notifications/badge`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResidentNotifications
     * @name DeleteResidentNotificationsByIdV1
     * @request DELETE:/api/v1/resident-notifications/{id}
     * @secure
     */
    deleteResidentNotificationsByIdV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/resident-notifications/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Self
     * @name GetSelfV1
     * @request GET:/api/v1/self
     * @deprecated
     * @secure
     */
    getSelfV1: (params: RequestParams = {}) =>
      this.request<SelfDto, any>({
        path: `/api/v1/self`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Self
     * @name PutSelfV1
     * @request PUT:/api/v1/self
     * @deprecated
     * @secure
     */
    putSelfV1: (data: SelfRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/self`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Self
     * @name PutSelfLanguageV1
     * @request PUT:/api/v1/self/language
     * @secure
     */
    putSelfLanguageV1: (data: SelfLanguageRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/self/language`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Self
     * @name PatchSelfPrivacySettingsV1
     * @request PATCH:/api/v1/self/privacy-settings
     * @secure
     */
    patchSelfPrivacySettingsV1: (data: SelfPrivacySettingsRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/self/privacy-settings`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Self
     * @name GetSelfProjectsV1
     * @request GET:/api/v1/self/projects
     * @secure
     */
    getSelfProjectsV1: (params: RequestParams = {}) =>
      this.request<ConnectedProjectsDto, any>({
        path: `/api/v1/self/projects`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags Self
 * @name PutSelfProjectsV1
 * @summary No need to use this endpoint when Backend API usage is replaced with Core API for all use cases.
The reason to update DB on project switch is that Backend API is not working with project context (headers).
 * @request PUT:/api/v1/self/projects
 * @secure
 */
    putSelfProjectsV1: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/self/projects`,
        method: "PUT",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Self
     * @name PostSelfDeleteV1
     * @summary GDPR Compliant delete endpoint.
     * @request POST:/api/v1/self/delete
     * @secure
     */
    postSelfDeleteV1: (data: SelfDeleteRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/self/delete`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Self
     * @name PostSelfOnboardingV1
     * @summary Mark user as onboarded.
     * @request POST:/api/v1/self/onboarding
     * @secure
     */
    postSelfOnboardingV1: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/self/onboarding`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Self
     * @name PutSelfAssignableV1
     * @request PUT:/api/v1/self/assignable
     * @secure
     */
    putSelfAssignableV1: (data: boolean, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/self/assignable`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Self
     * @name GetSelfAddressV1
     * @request GET:/api/v1/self/address
     * @secure
     */
    getSelfAddressV1: (params: RequestParams = {}) =>
      this.request<SelfRegistrationDetailsDto, any>({
        path: `/api/v1/self/address`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServicePartners
     * @name GetServicepartnersV1
     * @request GET:/api/v1/servicepartners
     * @secure
     */
    getServicepartnersV1: (
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
        /** @default false */
        includeFromConnection?: boolean;
        /** @default false */
        returnAllowedTypes?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ServicePartnerPagePaginationResultDto, any>({
        path: `/api/v1/servicepartners`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServicePartners
     * @name PostServicepartnersV1
     * @request POST:/api/v1/servicepartners
     * @secure
     */
    postServicepartnersV1: (data: ServicePartnerCreateRequest, params: RequestParams = {}) =>
      this.request<CreatedEntityDto, any>({
        path: `/api/v1/servicepartners`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServicePartners
     * @name GetServicepartnersCategoriesV1
     * @request GET:/api/v1/servicepartners/categories
     * @secure
     */
    getServicepartnersCategoriesV1: (params: RequestParams = {}) =>
      this.request<PartnerCategoriesDto[], any>({
        path: `/api/v1/servicepartners/categories`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServicePartners
     * @name GetServicepartnersDetailsV1
     * @request GET:/api/v1/servicepartners/{id}
     * @secure
     */
    getServicepartnersDetailsV1: (
      id: string,
      query?: {
        /** @default false */
        replaceParameters?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ServicePartnerPageDetailsDto, any>({
        path: `/api/v1/servicepartners/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServicePartners
     * @name PutServicepartnersV1
     * @request PUT:/api/v1/servicepartners/{id}
     * @secure
     */
    putServicepartnersV1: (id: string, data: ServicePartnerRequest, params: RequestParams = {}) =>
      this.request<CreatedEntityDto, any>({
        path: `/api/v1/servicepartners/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServicePartners
     * @name DeleteServicepartnersByIdV1
     * @request DELETE:/api/v1/servicepartners/{id}
     * @secure
     */
    deleteServicepartnersByIdV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/servicepartners/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Surveys
     * @name GetSurveysV1
     * @request GET:/api/v1/surveys
     * @secure
     */
    getSurveysV1: (
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SurveyDtoPaginationResultDto, any>({
        path: `/api/v1/surveys`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Surveys
     * @name PostSurveysV1
     * @request POST:/api/v1/surveys
     * @secure
     */
    postSurveysV1: (data: CreateSurveyRequestDto, params: RequestParams = {}) =>
      this.request<SurveyDetailsDto, any>({
        path: `/api/v1/surveys`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Surveys
     * @name GetSurveysDetailsV1
     * @request GET:/api/v1/surveys/{id}
     * @secure
     */
    getSurveysDetailsV1: (
      id: string,
      query?: {
        /** @default false */
        includeRankingQuestion?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<SurveyDetailsDto, any>({
        path: `/api/v1/surveys/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Surveys
     * @name PutSurveysV1
     * @request PUT:/api/v1/surveys/{id}
     * @secure
     */
    putSurveysV1: (
      id: string,
      data: UpdateSurveyRequestDto,
      query?: {
        /** @default false */
        includeRankingQuestion?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<SurveyDetailsDto, any>({
        path: `/api/v1/surveys/${id}`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Surveys
     * @name DeleteSurveysByIdV1
     * @request DELETE:/api/v1/surveys/{id}
     * @secure
     */
    deleteSurveysByIdV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/surveys/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Surveys
     * @name PostSurveysReadV1
     * @request POST:/api/v1/surveys/{id}/read
     * @secure
     */
    postSurveysReadV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/surveys/${id}/read`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Surveys
     * @name PostSurveysAnswerV1
     * @request POST:/api/v1/surveys/{id}/answer
     * @secure
     */
    postSurveysAnswerV1: (id: string, data: AnswerRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/surveys/${id}/answer`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Surveys
     * @name GetSurveysExportV1
     * @request GET:/api/v1/surveys/{id}/export
     * @secure
     */
    getSurveysExportV1: (id: string, params: RequestParams = {}) =>
      this.request<SurveyExportLine[], any>({
        path: `/api/v1/surveys/${id}/export`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemGroups
     * @name GetSystemGroupsV1
     * @request GET:/api/v1/system-groups
     * @secure
     */
    getSystemGroupsV1: (
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
        /** @default "id" */
        sorting?: "id" | "name";
      },
      params: RequestParams = {},
    ) =>
      this.request<SystemGroupDtoPaginationResult, any>({
        path: `/api/v1/system-groups`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemGroups
     * @name PostSystemGroupsV1
     * @request POST:/api/v1/system-groups
     * @secure
     */
    postSystemGroupsV1: (data: SystemGroupCreateRequest, params: RequestParams = {}) =>
      this.request<SystemGroupDetailDto, any>({
        path: `/api/v1/system-groups`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemGroups
     * @name GetSystemGroupsDetailsV1
     * @request GET:/api/v1/system-groups/{id}
     * @secure
     */
    getSystemGroupsDetailsV1: (id: string, params: RequestParams = {}) =>
      this.request<SystemGroupDetailDto, any>({
        path: `/api/v1/system-groups/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemGroups
     * @name PutSystemGroupsV1
     * @request PUT:/api/v1/system-groups/{id}
     * @secure
     */
    putSystemGroupsV1: (id: string, data: SystemGroupUpdateRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/system-groups/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemGroups
     * @name DeleteSystemGroupsByIdV1
     * @request DELETE:/api/v1/system-groups/{id}
     * @secure
     */
    deleteSystemGroupsByIdV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/system-groups/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemGroups
     * @name PostSystemGroupsEnableV1
     * @request POST:/api/v1/system-groups/{id}/enable/{projectId}
     * @secure
     */
    postSystemGroupsEnableV1: (id: string, projectId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/system-groups/${id}/enable/${projectId}`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemGroups
     * @name GetSystemGroupsProjectsV1
     * @request GET:/api/v1/system-groups/{id}/projects
     * @secure
     */
    getSystemGroupsProjectsV1: (id: string, params: RequestParams = {}) =>
      this.request<SimpleProjectDtoPaginationResultDto, any>({
        path: `/api/v1/system-groups/${id}/projects`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags TicketCategories
 * @name GetTicketCategoriesV1
 * @summary Permission check is not included by design
Endpoint is used for management pages
 * @request GET:/api/v1/ticket-categories
 * @secure
 */
    getTicketCategoriesV1: (
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
        /**
         * Obsolete, used to be used to filter on categories you can create tickets in. Now use /creatable endpoint instead
         * @default false
         */
        onlyCreatable?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<TicketCategoryDtoPaginationResultDto, any>({
        path: `/api/v1/ticket-categories`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketCategories
     * @name PostTicketCategoriesV1
     * @request POST:/api/v1/ticket-categories
     * @secure
     */
    postTicketCategoriesV1: (data: TicketCategoryRequest, params: RequestParams = {}) =>
      this.request<TicketCategoryDetailsDto, any>({
        path: `/api/v1/ticket-categories`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags TicketCategories
 * @name GetRepairCategoriesV1
 * @summary Permission check is not included by design
Endpoint is used for management pages
 * @request GET:/api/v1/repair-categories
 * @secure
 */
    getRepairCategoriesV1: (
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
        /**
         * Obsolete, used to be used to filter on categories you can create tickets in. Now use /creatable endpoint instead
         * @default false
         */
        onlyCreatable?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<TicketCategoryDtoPaginationResultDto, any>({
        path: `/api/v1/repair-categories`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketCategories
     * @name PostRepairCategoriesV1
     * @request POST:/api/v1/repair-categories
     * @secure
     */
    postRepairCategoriesV1: (data: TicketCategoryRequest, params: RequestParams = {}) =>
      this.request<TicketCategoryDetailsDto, any>({
        path: `/api/v1/repair-categories`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketCategories
     * @name GetTicketCategoriesCreatableV1
     * @summary All categories you can create tickets in
     * @request GET:/api/v1/ticket-categories/creatable
     * @secure
     */
    getTicketCategoriesCreatableV1: (params: RequestParams = {}) =>
      this.request<CreatableTicketCategoryDto[], any>({
        path: `/api/v1/ticket-categories/creatable`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketCategories
     * @name GetRepairCategoriesCreatableV1
     * @summary All categories you can create tickets in
     * @request GET:/api/v1/repair-categories/creatable
     * @secure
     */
    getRepairCategoriesCreatableV1: (params: RequestParams = {}) =>
      this.request<CreatableTicketCategoryDto[], any>({
        path: `/api/v1/repair-categories/creatable`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketCategories
     * @name GetTicketCategoriesDetailsV1
     * @request GET:/api/v1/ticket-categories/{id}
     * @secure
     */
    getTicketCategoriesDetailsV1: (id: string, params: RequestParams = {}) =>
      this.request<TicketCategoryDetailsDto, any>({
        path: `/api/v1/ticket-categories/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketCategories
     * @name PutTicketCategoriesV1
     * @request PUT:/api/v1/ticket-categories/{id}
     * @secure
     */
    putTicketCategoriesV1: (id: string, data: TicketCategoryRequest, params: RequestParams = {}) =>
      this.request<TicketCategoryDetailsDto, any>({
        path: `/api/v1/ticket-categories/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketCategories
     * @name DeleteTicketCategoriesByIdV1
     * @request DELETE:/api/v1/ticket-categories/{id}
     * @secure
     */
    deleteTicketCategoriesByIdV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/ticket-categories/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketCategories
     * @name GetRepairCategoriesDetailsV1
     * @request GET:/api/v1/repair-categories/{id}
     * @secure
     */
    getRepairCategoriesDetailsV1: (id: string, params: RequestParams = {}) =>
      this.request<TicketCategoryDetailsDto, any>({
        path: `/api/v1/repair-categories/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketCategories
     * @name PutRepairCategoriesV1
     * @request PUT:/api/v1/repair-categories/{id}
     * @secure
     */
    putRepairCategoriesV1: (id: string, data: TicketCategoryRequest, params: RequestParams = {}) =>
      this.request<TicketCategoryDetailsDto, any>({
        path: `/api/v1/repair-categories/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketCategories
     * @name DeleteRepairCategoriesByIdV1
     * @request DELETE:/api/v1/repair-categories/{id}
     * @secure
     */
    deleteRepairCategoriesByIdV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/repair-categories/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name PostTicketsV1
     * @request POST:/api/v1/tickets
     * @secure
     */
    postTicketsV1: (data: TicketRequest, params: RequestParams = {}) =>
      this.request<AppTicketDto, any>({
        path: `/api/v1/tickets`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name GetTicketsV1
     * @request GET:/api/v1/tickets
     * @secure
     */
    getTicketsV1: (
      query?: {
        RepairRequestFilterType?: "both" | "onlyCollective" | "onlyPrivate";
        StatusType?: "open" | "closed" | "recent";
        Search?: string;
        StatusIds?: string[];
        ReporterIds?: string[];
        CategoryIds?: string[];
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<AppTicketDtoPaginationResultDto, any>({
        path: `/api/v1/tickets`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name GetTicketsFiltersV1
     * @request GET:/api/v1/tickets/filters
     * @secure
     */
    getTicketsFiltersV1: (params: RequestParams = {}) =>
      this.request<TicketFiltersDto, any>({
        path: `/api/v1/tickets/filters`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name GetTicketsStatsDetailsV1
     * @request GET:/api/v1/tickets/stats/{statusType}
     * @secure
     */
    getTicketsStatsDetailsV1: (statusType: "open" | "closed" | "recent", params: RequestParams = {}) =>
      this.request<TicketStatsDto, any>({
        path: `/api/v1/tickets/stats/${statusType}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name GetTicketsStatsAllStatusesV1
     * @request GET:/api/v1/tickets/stats/all-statuses
     * @secure
     */
    getTicketsStatsAllStatusesV1: (
      query?: {
        RepairRequestFilterType?: "both" | "onlyCollective" | "onlyPrivate";
        StatusType?: "open" | "closed" | "recent";
        Search?: string;
        StatusIds?: string[];
        ReporterIds?: string[];
        CategoryIds?: string[];
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<TicketTabsStatsDto, any>({
        path: `/api/v1/tickets/stats/all-statuses`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name GetTicketsDetailsV1
     * @request GET:/api/v1/tickets/{id}
     * @secure
     */
    getTicketsDetailsV1: (id: string, params: RequestParams = {}) =>
      this.request<AppTicketDto, any>({
        path: `/api/v1/tickets/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name GetTicketsCommentsV1
     * @request GET:/api/v1/tickets/{id}/comments
     * @secure
     */
    getTicketsCommentsV1: (
      id: string,
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<TicketCommentDtoPaginationResultDto, any>({
        path: `/api/v1/tickets/${id}/comments`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name PostTicketsCommentsV1
     * @request POST:/api/v1/tickets/{id}/comments
     * @secure
     */
    postTicketsCommentsV1: (id: string, data: TicketCommentRequest, params: RequestParams = {}) =>
      this.request<TicketCommentDto, any>({
        path: `/api/v1/tickets/${id}/comments`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name GetTicketsHistoryDetailsV1
     * @request GET:/api/v1/tickets/{id}/history/{ticketHistoryType}
     * @secure
     */
    getTicketsHistoryDetailsV1: (
      id: string,
      ticketHistoryType: "users" | "addresses",
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserTicketHistoryDto, any>({
        path: `/api/v1/tickets/${id}/history/${ticketHistoryType}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name PutTicketsCommentsV1
     * @request PUT:/api/v1/tickets/{id}/comments/{commentId}
     * @secure
     */
    putTicketsCommentsV1: (id: string, commentId: string, data: EditTicketCommentRequest, params: RequestParams = {}) =>
      this.request<TicketCommentDto, any>({
        path: `/api/v1/tickets/${id}/comments/${commentId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name PutTicketsRateV1
     * @request PUT:/api/v1/tickets/{id}/rate
     * @secure
     */
    putTicketsRateV1: (id: string, data: TicketRateRequest, params: RequestParams = {}) =>
      this.request<AppTicketDto, any>({
        path: `/api/v1/tickets/${id}/rate`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name PostTicketsReadV1
     * @request POST:/api/v1/tickets/{ticket}/read
     * @secure
     */
    postTicketsReadV1: (ticket: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/tickets/${ticket}/read`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name GetTicketsTranslationsDetailsV1
     * @request GET:/api/v1/tickets/{ticketId}/translations/{isoCode}
     * @secure
     */
    getTicketsTranslationsDetailsV1: (ticketId: string, isoCode: string, params: RequestParams = {}) =>
      this.request<TicketTranslationDto, any>({
        path: `/api/v1/tickets/${ticketId}/translations/${isoCode}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name GetTicketsCommentsTranslationsDetailsV1
     * @request GET:/api/v1/tickets/{ticketId}/comments/{commentId}/translations/{isoCode}
     * @secure
     */
    getTicketsCommentsTranslationsDetailsV1: (
      ticketId: string,
      commentId: string,
      isoCode: string,
      params: RequestParams = {},
    ) =>
      this.request<TicketCommentTranslationDto, any>({
        path: `/api/v1/tickets/${ticketId}/comments/${commentId}/translations/${isoCode}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketStatuses
     * @name GetTicketStatusesV1
     * @request GET:/api/v1/ticket-statuses
     * @secure
     */
    getTicketStatusesV1: (params: RequestParams = {}) =>
      this.request<TicketStatusDetailsDtoPaginationResultDto, any>({
        path: `/api/v1/ticket-statuses`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketStatuses
     * @name PostTicketStatusesV1
     * @request POST:/api/v1/ticket-statuses
     * @secure
     */
    postTicketStatusesV1: (data: TicketStatusRequest, params: RequestParams = {}) =>
      this.request<TicketStatusDetailsDto, any>({
        path: `/api/v1/ticket-statuses`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketStatuses
     * @name GetTicketStatusesDetailsV1
     * @request GET:/api/v1/ticket-statuses/{id}
     * @secure
     */
    getTicketStatusesDetailsV1: (id: string, params: RequestParams = {}) =>
      this.request<TicketStatusDetailsDto, any>({
        path: `/api/v1/ticket-statuses/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketStatuses
     * @name PutTicketStatusesV1
     * @request PUT:/api/v1/ticket-statuses/{id}
     * @secure
     */
    putTicketStatusesV1: (id: string, data: TicketStatusRequest, params: RequestParams = {}) =>
      this.request<TicketStatusDetailsDto, any>({
        path: `/api/v1/ticket-statuses/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketStatuses
     * @name DeleteTicketStatusesByIdV1
     * @request DELETE:/api/v1/ticket-statuses/{id}
     * @secure
     */
    deleteTicketStatusesByIdV1: (
      id: string,
      query?: {
        replacementStatusId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/ticket-statuses/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketSuggestion
     * @name PostTicketsSuggestionV1
     * @request POST:/api/v1/tickets/{id}/suggestion
     * @secure
     */
    postTicketsSuggestionV1: (
      id: string,
      query?: {
        additionalInfo?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TicketResponseSuggestionDto, any>({
        path: `/api/v1/tickets/${id}/suggestion`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Translations
     * @name GetTranslationsDetailsV1
     * @request GET:/api/v1/translations/{clientType}/{languageId}
     */
    getTranslationsDetailsV1: (
      clientType: "dashboard" | "mobile" | "email" | "legacy" | "mobileV3",
      languageId: "nl" | "en" | "de" | "dutch" | "english" | "german",
      params: RequestParams = {},
    ) =>
      this.request<Record<string, string>, any>({
        path: `/api/v1/translations/${clientType}/${languageId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Translations
     * @name PostTranslationsTranslateV1
     * @request POST:/api/v1/translations/translate
     * @secure
     */
    postTranslationsTranslateV1: (data: TranslateRequest, params: RequestParams = {}) =>
      this.request<TranslateResult[], any>({
        path: `/api/v1/translations/translate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TriggeredAlerts
     * @name GetTriggeredAlertsV1
     * @request GET:/api/v1/triggered-alerts
     * @secure
     */
    getTriggeredAlertsV1: (
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
        /** @default false */
        onlyDashboard?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<GenericAlertTriggeredDtoPaginationResultDto, any>({
        path: `/api/v1/triggered-alerts`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileTicketsConfiguration
     * @name GetUserProfileTicketsConfigurationV1
     * @request GET:/api/v1/user-profile-tickets-configuration
     * @secure
     */
    getUserProfileTicketsConfigurationV1: (params: RequestParams = {}) =>
      this.request<UserProfileTicketsConfigurationDto, any>({
        path: `/api/v1/user-profile-tickets-configuration`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileTicketsConfiguration
     * @name PutUserProfileTicketsConfigurationV1
     * @request PUT:/api/v1/user-profile-tickets-configuration
     * @secure
     */
    putUserProfileTicketsConfigurationV1: (data: UserProfileTicketsConfigurationRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/user-profile-tickets-configuration`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserRelations
     * @name GetAddressesRelationV1
     * @request GET:/api/v1/addresses/{addressId}/relation
     * @secure
     */
    getAddressesRelationV1: (addressId: string, params: RequestParams = {}) =>
      this.request<UserRelationInformationDetailDto[], void>({
        path: `/api/v1/addresses/${addressId}/relation`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags UserRelations
 * @name DeleteAddressesRelationByIdV1
 * @summary Ends the relation.
For now this only sets the EndDate to a date. But in the future it will also remove the users related to the relation.
 * @request DELETE:/api/v1/addresses/{addressId}/relation/{relationId}
 * @secure
 */
    deleteAddressesRelationByIdV1: (
      relationId: string,
      addressId: string,
      query?: {
        /** @format date-time */
        endDate?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/api/v1/addresses/${addressId}/relation/${relationId}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserRelations
     * @name GetAddressesRelationHistoryV1
     * @request GET:/api/v1/addresses/{addressId}/relation/history
     * @secure
     */
    getAddressesRelationHistoryV1: (addressId: string, params: RequestParams = {}) =>
      this.request<UserRelationInformationDto[], any>({
        path: `/api/v1/addresses/${addressId}/relation/history`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GetUsersLookupV1
     * @request GET:/api/v1/users/lookup
     * @secure
     */
    getUsersLookupV1: (
      query?: {
        City?: string;
        Street?: string;
        House?: string;
        FirstName?: string;
        LastName?: string;
        FullName?: string;
        Email?: string;
        ProjectId?: string;
        IncludeAdmins?: boolean;
        IncludeDeleted?: boolean;
        IncludeNotRegistered?: boolean;
        SortBy?: "app" | "dashboard" | "id" | "avatarFirstName" | "firstName" | "lastName" | "address" | "company";
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserLookupDtoPaginationResultDto, any>({
        path: `/api/v1/users/lookup`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GetUsersV1
     * @request GET:/api/v1/users
     * @secure
     */
    getUsersV1: (
      query?: {
        Search?: string;
        /** Admin property only */
        IncludeAdmins?: boolean;
        /** Superadmin property only */
        IncludeDeleted?: boolean;
        /** Admin property only */
        IncludeNotRegistered?: boolean;
        CompanyId?: string;
        SortBy?: "app" | "dashboard" | "id" | "avatarFirstName" | "firstName" | "lastName" | "address" | "company";
        SortDescending?: boolean;
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserDetailsDtoPaginationResultDto, any>({
        path: `/api/v1/users`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name PostUsersV1
     * @request POST:/api/v1/users
     * @secure
     */
    postUsersV1: (data: UserCreateRequest, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/v1/users`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GetUsersDetailsV1
     * @request GET:/api/v1/users/{userId}
     * @secure
     */
    getUsersDetailsV1: (userId: string, params: RequestParams = {}) =>
      this.request<UserDetailsWithAddressInterestsAndHelpDto, any>({
        path: `/api/v1/users/${userId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GetUsersGroupsV1
     * @request GET:/api/v1/users/{userId}/groups
     * @deprecated
     * @secure
     */
    getUsersGroupsV1: (
      userId: string,
      query?: {
        groupType?: "interest" | "helpCategory" | "realEstate";
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CommunityGroupDtoPaginationResultDto, any>({
        path: `/api/v1/users/${userId}/groups`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GetUsersRepairAssigneesV1
     * @request GET:/api/v1/users/repair-assignees
     * @secure
     */
    getUsersRepairAssigneesV1: (
      query?: {
        categoryId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserDto[], any>({
        path: `/api/v1/users/repair-assignees`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GetUsersRepairAssigneesByRolesV1
     * @request GET:/api/v1/users/repair-assignees-by-roles
     * @secure
     */
    getUsersRepairAssigneesByRolesV1: (
      query?: {
        roles?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<UserDto[], any>({
        path: `/api/v1/users/repair-assignees-by-roles`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GetUsersStatsV1
     * @request GET:/api/v1/users/stats
     * @secure
     */
    getUsersStatsV1: (params: RequestParams = {}) =>
      this.request<UserStatsDto, any>({
        path: `/api/v1/users/stats`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name PostUsersChangeEmailV1
     * @request POST:/api/v1/users/change-email
     * @secure
     */
    postUsersChangeEmailV1: (data: EmailChangeRequestDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/users/change-email`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name PostUsersVerifyEmailChangeCodeV1
     * @request POST:/api/v1/users/verify-email-change-code
     * @secure
     */
    postUsersVerifyEmailChangeCodeV1: (data: EmailChangeDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/users/verify-email-change-code`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GetUsersAdminsV1
     * @request GET:/api/v1/users/admins
     * @secure
     */
    getUsersAdminsV1: (params: RequestParams = {}) =>
      this.request<UserDto[], any>({
        path: `/api/v1/users/admins`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name PutUsersV1
     * @request PUT:/api/v1/users/{id}
     * @secure
     */
    putUsersV1: (id: string, data: UserUpdateRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/users/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name DeleteUsersByIdV1
     * @request DELETE:/api/v1/users/{id}
     * @secure
     */
    deleteUsersByIdV1: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/users/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name PutUsersAssignableV1
     * @request PUT:/api/v1/users/{id}/assignable
     * @secure
     */
    putUsersAssignableV1: (id: string, data: boolean, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/users/${id}/assignable`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name PostUsersEmailExistsV1
     * @request POST:/api/v1/users/email-exists
     * @secure
     */
    postUsersEmailExistsV1: (data: EmailExistsRequest, params: RequestParams = {}) =>
      this.request<EmailExistsDto, any>({
        path: `/api/v1/users/email-exists`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name PutUsersDeletionDateV1
     * @request PUT:/api/v1/users/{id}/deletion-date
     * @secure
     */
    putUsersDeletionDateV1: (id: string, data: UserDeletionDateRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/users/${id}/deletion-date`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GetUsersProjectsV1
     * @request GET:/api/v1/users/{userId}/projects
     * @secure
     */
    getUsersProjectsV1: (userId: string, params: RequestParams = {}) =>
      this.request<SimpleProjectDto[], any>({
        path: `/api/v1/users/${userId}/projects`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GetUsersRecentJoinedResidentV1
     * @request GET:/api/v1/users/recent-joined-resident
     * @secure
     */
    getUsersRecentJoinedResidentV1: (params: RequestParams = {}) =>
      this.request<UserDto[], any>({
        path: `/api/v1/users/recent-joined-resident`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserSignInSignUp
     * @name PostUserSignInSignUpVerifyEmailV1
     * @request POST:/api/v1/user-sign-in-sign-up/verify-email
     */
    postUserSignInSignUpVerifyEmailV1: (data: VerifyEmailRequest, params: RequestParams = {}) =>
      this.request<VerifyEmailResultDto, any>({
        path: `/api/v1/user-sign-in-sign-up/verify-email`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserSignupReminders
     * @name GetUsersUserSignupRemindersV1
     * @request GET:/api/v1/users/{userId}/user-signup-reminders
     * @secure
     */
    getUsersUserSignupRemindersV1: (userId: string, params: RequestParams = {}) =>
      this.request<ReminderStatusDto, void>({
        path: `/api/v1/users/${userId}/user-signup-reminders`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserSignupReminders
     * @name PostUsersUserSignupRemindersV1
     * @request POST:/api/v1/users/{userId}/user-signup-reminders
     * @secure
     */
    postUsersUserSignupRemindersV1: (userId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/users/${userId}/user-signup-reminders`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags WidgetData
     * @name GetWidgetsDataDetailsV1
     * @request GET:/api/v1/widgets/data/{widgetDataType}
     * @secure
     */
    getWidgetsDataDetailsV1: (
      widgetDataType: "ticketRating" | "ticketSolvingTime" | "engagement" | "contribution",
      params: RequestParams = {},
    ) =>
      this.request<WidgetDataDto, any>({
        path: `/api/v1/widgets/data/${widgetDataType}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WidgetManagement
     * @name GetWidgetsActiveV1
     * @request GET:/api/v1/widgets/active
     * @secure
     */
    getWidgetsActiveV1: (params: RequestParams = {}) =>
      this.request<ActiveWidgetsDto, any>({
        path: `/api/v1/widgets/active`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WidgetManagement
     * @name PostWidgetsActiveV1
     * @request POST:/api/v1/widgets/active
     * @secure
     */
    postWidgetsActiveV1: (data: WidgetsDataTypesRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/widgets/active`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags WoonbedrijfConnector
     * @name PostApiPartnersWoonbedrijfNewRelation
     * @request POST:/api/partners/woonbedrijf/new-relation
     */
    postApiPartnersWoonbedrijfNewRelation: (
      data: CreateRelationRequest,
      query?: {
        "api-version"?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/api/partners/woonbedrijf/new-relation`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags WoonbedrijfConnector
     * @name PostApiPartnersWoonbedrijfEndRelation
     * @summary This ends the "contract" between the renter and the address unit. This will result into the renter being removed from the address unit at the specified date.
     * @request POST:/api/partners/woonbedrijf/end-relation
     */
    postApiPartnersWoonbedrijfEndRelation: (
      data: EndRelationRequest,
      query?: {
        "api-version"?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/api/partners/woonbedrijf/end-relation`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name GetAnalyticsEngagedResidentsMonthlyV2
     * @summary The contribution of residents to the project
     * @request GET:/api/v2/analytics/engaged-residents/monthly
     * @secure
     */
    getAnalyticsEngagedResidentsMonthlyV2: (
      query?: {
        /** @default "last6Months" */
        dateRange?: "last6Months" | "lastYear";
      },
      params: RequestParams = {},
    ) =>
      this.request<EngagedResidentsMonthlyPercentageDto, any>({
        path: `/api/v2/analytics/engaged-residents/monthly`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chats
     * @name PostChatsGroupV2
     * @request POST:/api/v2/chats/group
     * @secure
     */
    postChatsGroupV2: (data: CreateGroupChatRequest, params: RequestParams = {}) =>
      this.request<CreatedEntityDto, any>({
        path: `/api/v2/chats/group`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chats
     * @name PostChatsPrivateV2
     * @request POST:/api/v2/chats/private
     * @secure
     */
    postChatsPrivateV2: (data: CreatePrivateChatRequest, params: RequestParams = {}) =>
      this.request<ChatDto, any>({
        path: `/api/v2/chats/private`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chats
     * @name GetChatsV2
     * @request GET:/api/v2/chats
     * @secure
     */
    getChatsV2: (
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ChatDtoPaginationResultDto, any>({
        path: `/api/v2/chats`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chats
     * @name GetChatsDetailsV2
     * @request GET:/api/v2/chats/{id}
     * @secure
     */
    getChatsDetailsV2: (id: string, params: RequestParams = {}) =>
      this.request<ChatDetailDto, any>({
        path: `/api/v2/chats/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chats
     * @name GetChatsMembersV2
     * @request GET:/api/v2/chats/{id}/members
     * @secure
     */
    getChatsMembersV2: (id: string, params: RequestParams = {}) =>
      this.request<UserDto[], any>({
        path: `/api/v2/chats/${id}/members`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chats
     * @name PostChatsReplyV2
     * @request POST:/api/v2/chats/{id}/reply
     * @secure
     */
    postChatsReplyV2: (id: string, data: ChatReplyRequest, params: RequestParams = {}) =>
      this.request<ChatReplyDto, any>({
        path: `/api/v2/chats/${id}/reply`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chats
     * @name GetChatsRepliesV2
     * @request GET:/api/v2/chats/{id}/replies
     * @secure
     */
    getChatsRepliesV2: (
      id: string,
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ChatReplyDtoPaginationResultDto, any>({
        path: `/api/v2/chats/${id}/replies`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Do note that this paginationQuery applies it to each of the entities. Meaning that if you set the limit to 3, this would set the limit to 3 for both Messages and Surveys; resulting into 6 items (if both Messages and Surveys have enough available).
     *
     * @tags CommunityFeed
     * @name GetCommunityFeedV2
     * @request GET:/api/v2/community-feed
     * @secure
     */
    getCommunityFeedV2: (
      query?: {
        Query?: string;
        PosterId?: string;
        /** @format date-time */
        StartDate?: string;
        /** @format date-time */
        EndDate?: string;
        GroupIds?: string[];
        Tab?: "all" | "announcements" | "own" | "myFeed";
        Connection?: "excluded" | "only" | "included";
        ExcludeScheduledPosts?: boolean;
        ExcludeArchivedPosts?: boolean;
        FeedTypes?: ("messages" | "surveys" | "polls")[];
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CommunityFeedItemV2DtoPaginationResultDto, any>({
        path: `/api/v2/community-feed`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name GetGroupsV2
     * @request GET:/api/v2/groups
     * @secure
     */
    getGroupsV2: (
      query?: {
        groupTypes?: ("interest" | "helpCategory" | "realEstate")[];
        search?: string;
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CommunityGroupV2DtoPaginationResultDto, any>({
        path: `/api/v2/groups`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name PostGroupsV2
     * @request POST:/api/v2/groups
     * @secure
     */
    postGroupsV2: (data: CommunityGroupCreateRequest, params: RequestParams = {}) =>
      this.request<CommunityGroupDetailsV2Dto, any>({
        path: `/api/v2/groups`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name GetGroupsDetailsV2
     * @request GET:/api/v2/groups/{id}
     * @secure
     */
    getGroupsDetailsV2: (id: string, params: RequestParams = {}) =>
      this.request<CommunityGroupDetailsV2Dto, any>({
        path: `/api/v2/groups/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name PutGroupsV2
     * @request PUT:/api/v2/groups/{id}
     * @secure
     */
    putGroupsV2: (id: string, data: CommunityGroupEditRequest, params: RequestParams = {}) =>
      this.request<CommunityGroupDetailsV2Dto, any>({
        path: `/api/v2/groups/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Languages
     * @name GetLanguagesV2
     * @request GET:/api/v2/languages
     * @secure
     */
    getLanguagesV2: (params: RequestParams = {}) =>
      this.request<LanguageDto[], any>({
        path: `/api/v2/languages`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessageComments
     * @name GetMessagesCommentsTranslationsDetailsV2
     * @request GET:/api/v2/messages/{messageId}/comments/{commentId}/translations/{isoCode}
     * @secure
     */
    getMessagesCommentsTranslationsDetailsV2: (
      messageId: string,
      commentId: string,
      isoCode: string,
      params: RequestParams = {},
    ) =>
      this.request<CommentTranslationDto, any>({
        path: `/api/v2/messages/${messageId}/comments/${commentId}/translations/${isoCode}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messages
     * @name GetMessagesTranslationsDetailsV2
     * @request GET:/api/v2/messages/{messageId}/translations/{isoCode}
     * @secure
     */
    getMessagesTranslationsDetailsV2: (messageId: string, isoCode: string, params: RequestParams = {}) =>
      this.request<MessageTranslationDto, any>({
        path: `/api/v2/messages/${messageId}/translations/${isoCode}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messages
     * @name GetMessagesV2
     * @request GET:/api/v2/messages
     * @secure
     */
    getMessagesV2: (
      query?: {
        Query?: string;
        PosterId?: string;
        /** @format date-time */
        StartDate?: string;
        /** @format date-time */
        EndDate?: string;
        GroupIds?: string[];
        Tab?: "all" | "announcements" | "own" | "myFeed";
        Connection?: "excluded" | "only" | "included";
        ExcludeScheduledPosts?: boolean;
        ExcludeArchivedPosts?: boolean;
        FeedTypes?: ("messages" | "surveys" | "polls")[];
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<MessageV2DtoPaginationResultDto, any>({
        path: `/api/v2/messages`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messages
     * @name GetMessagesDetailsV2
     * @request GET:/api/v2/messages/{messageId}
     * @secure
     */
    getMessagesDetailsV2: (messageId: string, params: RequestParams = {}) =>
      this.request<MessageDetailV2Dto, any>({
        path: `/api/v2/messages/${messageId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Registration
     * @name PostRegistrationConnectV2
     * @request POST:/api/v2/registration/connect
     * @secure
     */
    postRegistrationConnectV2: (data: RegistrationRequestV2, params: RequestParams = {}) =>
      this.request<RegistrationConnectDto, any>({
        path: `/api/v2/registration/connect`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResidentGroups
     * @name PostResidentGroupsV2
     * @request POST:/api/v2/resident-groups
     * @secure
     */
    postResidentGroupsV2: (data: ResidentGroupCreateRequest, params: RequestParams = {}) =>
      this.request<CommunityGroupDetailsV2Dto, any>({
        path: `/api/v2/resident-groups`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResidentGroups
     * @name PutResidentGroupsV2
     * @request PUT:/api/v2/resident-groups/{id}
     * @secure
     */
    putResidentGroupsV2: (id: string, data: ResidentGroupEditRequest, params: RequestParams = {}) =>
      this.request<CommunityGroupDetailsV2Dto, any>({
        path: `/api/v2/resident-groups/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResidentNotifications
     * @name GetResidentNotificationsV2
     * @request GET:/api/v2/resident-notifications
     * @secure
     */
    getResidentNotificationsV2: (
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ResidentNotificationDtoPaginationResultDto, any>({
        path: `/api/v2/resident-notifications`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResidentNotifications
     * @name GetResidentNotificationsStatusV2
     * @request GET:/api/v2/resident-notifications/status
     * @secure
     */
    getResidentNotificationsStatusV2: (params: RequestParams = {}) =>
      this.request<UnreadStatusDto, any>({
        path: `/api/v2/resident-notifications/status`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Self
     * @name GetSelfV2
     * @request GET:/api/v2/self
     * @secure
     */
    getSelfV2: (params: RequestParams = {}) =>
      this.request<SelfDto, any>({
        path: `/api/v2/self`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Self
     * @name PutSelfV2
     * @request PUT:/api/v2/self
     * @secure
     */
    putSelfV2: (data: SelfUpdateRequest, params: RequestParams = {}) =>
      this.request<SelfDto, void>({
        path: `/api/v2/self`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SurveyCategories
     * @name GetSurveyCategoriesAverageRatingsV2
     * @request GET:/api/v2/survey-categories/{surveyCategory}/average-ratings
     * @secure
     */
    getSurveyCategoriesAverageRatingsV2: (surveyCategory: "other" | "feelAtHome", params: RequestParams = {}) =>
      this.request<FeelingAtHomeWidgetDto, any>({
        path: `/api/v2/survey-categories/${surveyCategory}/average-ratings`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Surveys
     * @name GetSurveysDetailsV2
     * @request GET:/api/v2/surveys/{id}
     * @secure
     */
    getSurveysDetailsV2: (
      id: string,
      query?: {
        /** @default false */
        includeRankingQuestion?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<SurveyDetailsV2Dto, any>({
        path: `/api/v2/surveys/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Surveys
     * @name GetSurveysExportV2
     * @request GET:/api/v2/surveys/{id}/export
     * @secure
     */
    getSurveysExportV2: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v2/surveys/${id}/export`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GetUsersGroupsV2
     * @request GET:/api/v2/users/{userId}/groups
     * @secure
     */
    getUsersGroupsV2: (
      userId: string,
      query?: {
        groupTypes?: ("interest" | "helpCategory" | "realEstate")[];
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CommunityGroupV2DtoPaginationResultDto, any>({
        path: `/api/v2/users/${userId}/groups`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResidentNotifications
     * @name GetResidentNotificationsV3
     * @request GET:/api/v3/resident-notifications
     * @secure
     */
    getResidentNotificationsV3: (
      query?: {
        /** @format int32 */
        Offset?: number;
        /** @format int32 */
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ResidentNotificationResultDto, any>({
        path: `/api/v3/resident-notifications`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Self
     * @name PutSelfV3
     * @request PUT:/api/v3/self
     * @secure
     */
    putSelfV3: (data: SelfUpdateRequest, params: RequestParams = {}) =>
      this.request<SelfDto, void>({
        path: `/api/v3/self`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
