import { Button } from "components/Button/Button";
import { PlusCircle as PlusCircleIcon } from "react-feather";

export function AddProjectButton({
  children,
  className,
  onClick,
}: React.PropsWithChildren<{ className?: string; onClick: () => void }>): React.ReactNode {
  return (
    <Button onClick={onClick} className={className} data-testid="add-project-btn">
      <span className="-ml-2 -mr-1.5 flex items-center gap-2">
        <PlusCircleIcon className="shrink-0" size={18} />
        <span>{children}</span>
      </span>
    </Button>
  );
}
