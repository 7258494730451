import { ProjectLogo } from "components/ProjectLogo/ProjectLogo";
import { Overline2, Subtitle2 } from "components/Text/Text";

interface Project {
  id: string;
  name: string;
  city?: string;
  logoUrl: string;
}

interface Props {
  project: Project;
  onClick: () => void;
}

export function ProjectItem({ project, onClick }: Props): React.ReactNode {
  return (
    <button
      className="flex w-full items-center gap-4 rounded-md p-2 outline-none hover:bg-aop-basic-blue-lightest focus-visible:bg-aop-basic-blue-lightest focus-visible:ring-grey-darkest"
      onClick={onClick}
    >
      <ProjectLogo className="size-14 shrink-0" src={project.logoUrl} />
      <div className="flex flex-1 flex-col items-start">
        <Subtitle2 className="line-clamp-1 break-all text-left">{project.name}</Subtitle2>
        {project.city ? <Overline2 className="text-grey-darker">{project.city}</Overline2> : null}
      </div>
    </button>
  );
}
