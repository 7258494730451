import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useBool } from "hooks/useBool";
import { getLocalStorageValue, useUpdateLocalStorage } from "hooks/useLocalStorage";
import { useScreenIsBiggerThan } from "hooks/useScreenIsBiggerThan";
import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

import { MobileMenuBar } from "./MobileMenuBar";
import { PortfolioSidebar } from "./PortfolioSidebar";
import { ProjectSidebar } from "./ProjectSidebar";

const MENU_PORTFOLIO_KEY = "portfolio-menu-wide";
const MENU_PROJECT_KEY = "project-menu-wide";

export function NavigationSidebar(): React.ReactNode {
  const [isProjectMenuMobileOpen, projectMenuMobileHandlers] = useBool();
  const [isProjectMenuWide, projectMenuWideHandlers] = useBool(() => getLocalStorageValue(MENU_PROJECT_KEY, false));
  useUpdateLocalStorage(MENU_PROJECT_KEY, isProjectMenuWide);

  const [isPortfolioMenuMobileOpen, portfolioMenuMobileHandlers] = useBool();
  const [isPortfolioMenuWide, portfolioMenuWideHandlers] = useBool(() =>
    getLocalStorageValue(MENU_PORTFOLIO_KEY, false),
  );
  useUpdateLocalStorage(MENU_PORTFOLIO_KEY, isPortfolioMenuWide);

  const params = useParams();
  const isInProject = !!params["slug"];

  const connectedProjects = useConnectedProjects();
  const canViewPortfolio = useMemo(
    () => connectedProjects.data?.some((x) => x.userRole !== "resident"),
    [connectedProjects.data],
  );

  const isDesktop = useScreenIsBiggerThan("md");
  const isMobile = !isDesktop;

  const location = useLocation();
  useEffect(() => {
    // Close menu when navigating
    projectMenuMobileHandlers.setFalse();
    portfolioMenuMobileHandlers.setFalse();
  }, [location.pathname, projectMenuMobileHandlers, portfolioMenuMobileHandlers]);

  const onToggleProjectSidebar = useCallback(() => {
    projectMenuWideHandlers.toggle();
    projectMenuMobileHandlers.setFalse();
  }, [projectMenuWideHandlers, projectMenuMobileHandlers]);

  const onTogglePortfolioSidebar = useCallback(() => {
    portfolioMenuWideHandlers.toggle();
    portfolioMenuMobileHandlers.setFalse();
  }, [portfolioMenuMobileHandlers, portfolioMenuWideHandlers]);

  return (
    <nav
      className="group/sidebar flex flex-col md:grid md:min-h-full"
      style={{
        gridTemplateColumns: isInProject ? "min-content min-content 1fr" : "min-content 1fr",
      }}
    >
      {isMobile && (
        <MobileMenuBar
          isInProject={isInProject}
          onToggleMenu={isInProject ? projectMenuMobileHandlers.setTrue : portfolioMenuMobileHandlers.setTrue}
        />
      )}
      {canViewPortfolio && (isDesktop || (!isInProject && isPortfolioMenuMobileOpen)) && (
        <PortfolioSidebar
          isWide={isMobile || (!isInProject && isPortfolioMenuWide)}
          isInProject={isInProject}
          onToggle={onTogglePortfolioSidebar}
        />
      )}
      {isInProject && (isDesktop || isProjectMenuMobileOpen) && (
        <ProjectSidebar
          isWide={(isProjectMenuWide && isInProject) || (isMobile && isProjectMenuMobileOpen)}
          onToggle={onToggleProjectSidebar}
        />
      )}
    </nav>
  );
}
