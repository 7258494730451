import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type {
  LanguageDto,
  TicketCategoryDetailsDto,
  UserRoleDto,
  UserRoleRepairCategoryPermissionsDto,
} from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { createFormTranslations, useLanguages } from "helpers/languages";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { createFormPermissions } from "helpers/permissions";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useParams } from "react-router-dom";

import type { LayoutProps, TicketCategoryDetailsFields } from "./Layout";
import { DAYS_VARIANTS } from "./Layout";
import { transformFormDataToAPI, useSubmitHandlers } from "./Manager";

interface Props {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader({ children }: Props): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const { categoryId, isEditMode } = usePageParams();
  const { data: languages = [], isFetching: languagesIsFetching, error: languagesError } = useLanguages();
  const {
    data: ticketCategory,
    isFetching: ticketCategoryIsFetching,
    error: ticketCategoryError,
  } = useQuery({
    queryKey: QUERY_KEYS.TICKET_CATEGORY(projectId, categoryId!),
    queryFn: () => api.getTicketCategoriesDetailsV1(categoryId!),
    select: commonAPIDataSelector,
    enabled: isEditMode,
  });
  const {
    data: defaultsPermissions = [],
    isFetching: defaultsPermissionsIsFetching,
    error: defaultPermissionsError,
  } = useQuery({
    queryKey: QUERY_KEYS.REPAIR_CATEGORY_DEFAULT_PERMISSIONS(projectId),
    queryFn: () => api.getPermissionsDefaultRepairCategoryV1(),
    select: commonAPIDataSelector,
  });
  const {
    data: roles = [],
    isFetching: rolesIsFetching,
    error: rolesError,
  } = useQuery({
    queryKey: QUERY_KEYS.PERMISSION_ROLES(projectId),
    queryFn: () => api.getPermissionsRolesV1(),
    select: commonAPIDataSelector,
  });
  const { createTicketCategory, editTicketCategory, isSubmitting } = useSubmitHandlers();
  function onSubmit(formData: TicketCategoryDetailsFields) {
    const body = transformFormDataToAPI(formData);
    if (categoryId == null) {
      createTicketCategory(body);
    } else {
      editTicketCategory({ id: categoryId, body });
    }
  }

  const error = ticketCategoryError || languagesError || defaultPermissionsError || rolesError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  if (languagesIsFetching || defaultsPermissionsIsFetching || ticketCategoryIsFetching || rolesIsFetching) {
    return <FullSizeLoader withPadding />;
  }

  return (
    <>
      {children({
        languages,
        isEditMode,
        isSubmitting,
        onSubmit,
        defaultFormValues: getDefaultFormValues(roles, languages, defaultsPermissions, ticketCategory),
      })}
    </>
  );
}

function usePageParams() {
  const { id: categoryId } = useParams<{ id: string }>();

  return { categoryId, isEditMode: categoryId != null };
}

function getDefaultFormValues(
  roles: UserRoleDto[],
  languages: LanguageDto[],
  defaultPermissions: UserRoleRepairCategoryPermissionsDto[],
  ticketCategory?: TicketCategoryDetailsDto,
): TicketCategoryDetailsFields {
  return {
    translations: createFormTranslations(languages, ticketCategory?.translations),
    defaultAssignee: ticketCategory?.defaultAssignee,
    notificationType: ticketCategory?.notificationType || "canBeCollective",
    closeAfterInactiveDayAmount: ticketCategory
      ? (String(ticketCategory.closeAfterInactiveDayAmount) as (typeof DAYS_VARIANTS)[number])
      : DAYS_VARIANTS[1],
    disabled: ticketCategory?.disabled ? "true" : "false",
    audience: ticketCategory?.audience || [],
    permissions: createFormPermissions(roles, defaultPermissions, ticketCategory?.permissions),
  };
}
