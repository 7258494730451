import type { AdminTicketCommentDto, UserDto } from "api/types";
import { CommentFieldWithAvatar } from "components/CommentField/CommentField";
import type { FormImage } from "components/ImageInput/useImageInput";
import { useImageInput } from "components/ImageInput/useImageInput";
import { Capture2 } from "components/Text/Text";
import { useBool } from "hooks/useBool";
import { useEffect, useRef, useState } from "react";
import { Lock as LockIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import { TicketCopilot } from "./TicketCopilot";

interface Props {
  canAddPublicComment: boolean;
  canAddInternalNote: boolean;
  disallowImages?: boolean;
  autoFocus?: boolean;
  user: UserDto;
  onComment: (payload: { content: string; image?: FormImage; internal?: boolean }) => Promise<unknown>;
  onEditComment?: ({
    commentId,
    content,
    image,
  }: {
    commentId: string;
    content: string;
    image?: FormImage;
  }) => Promise<void>;
  showCopilot?: boolean;
  ticketId?: string;
  editingComment?: AdminTicketCommentDto;
}

export function TicketCommentField({
  canAddInternalNote,
  canAddPublicComment,
  disallowImages,
  autoFocus,
  user,
  onComment,
  onEditComment,
  showCopilot,
  ticketId,
  editingComment,
}: Props): React.ReactNode {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [commentState, setCommentState] = useState<"note" | "comment">(canAddPublicComment ? "comment" : "note");
  const [images, setImages] = useState<FormImage[]>([]);
  const { addImages, removeImage, removeImages } = useImageInput({ onChange: setImages });
  const [isTicketCopilotModalOpen, ticketCopilotModalHandler] = useBool(false);
  const [originalText, setOriginalText] = useState("");
  const [copilotText, setCopilotText] = useState("");
  const [isCopilotText, setIsCopilotText] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  function resetBodyToOriginal() {
    setIsCopilotText(false);
    setValue(originalText);
  }

  function resetBodyToCopilot() {
    setIsCopilotText(true);
    setValue(copilotText);
  }

  function resetInput() {
    removeImages();
    setValue("");
    setOriginalText("");
    setCopilotText("");
    setIsCopilotText(false);
    setIsEdit(false);
  }

  useEffect(() => {
    if (editingComment) {
      setCommentState(editingComment.internal ? "note" : "comment");
      setValue(editingComment.content ?? "");
      setImages(editingComment.images ?? []);
      setIsEdit(true);
    }
  }, [editingComment]);

  const ref = useRef<HTMLTextAreaElement>(null);

  if (!canAddInternalNote && !canAddPublicComment) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col">
        <div className="flex">
          {canAddPublicComment ? (
            <button
              data-testid="public-comment-btn"
              className={twJoin(
                "rounded-tl-lg px-4 py-1.5",
                commentState === "comment"
                  ? "bg-aop-basic-blue text-white"
                  : "bg-blue-lightest text-grey-dark hocus:bg-blue-lighter",
                canAddInternalNote ? undefined : "rounded-tr-lg",
              )}
              type="button"
              onClick={() => setCommentState("comment")}
              disabled={isEdit}
            >
              <Capture2>{t("page.tickets.details.comment-field.comment")}</Capture2>
            </button>
          ) : null}
          {canAddInternalNote ? (
            <button
              data-testid="internal-note-btn"
              className={twJoin(
                "flex items-center gap-1 rounded-tr-lg px-4 py-1.5",
                commentState === "note"
                  ? "bg-yellow-dark text-yellow-lightest"
                  : "bg-yellow-lightest text-yellow-darkest hocus:bg-yellow-lighter",
                canAddPublicComment ? undefined : "rounded-tl-lg",
              )}
              type="button"
              onClick={() => setCommentState("note")}
              disabled={isEdit}
            >
              <LockIcon size={16} />
              <Capture2>{t("page.tickets.details.comment-field.note")}</Capture2>
            </button>
          ) : null}
        </div>
        <div
          className={twJoin(
            "rounded-lg rounded-tl-none border p-4",
            commentState === "comment" ? "border-aop-basic-blue" : "border-yellow-dark",
          )}
        >
          <CommentFieldWithAvatar
            ref={ref}
            onSubmit={async () => {
              if (isEdit && editingComment && onEditComment) {
                await onEditComment({
                  commentId: editingComment.id,
                  content: value,
                  image: images[0],
                });
              } else {
                await onComment({
                  content: value,
                  image: images[0],
                  internal: commentState === "note",
                });
              }
              resetInput();
            }}
            withoutTopRow
            user={user}
            allowsImages={!disallowImages}
            imageUploadTooltip={t("page.tickets.details.comment-field.image-tooltip")}
            onRemoveImage={removeImage}
            onAddImages={addImages}
            images={images}
            sendTooltip={
              commentState === "note"
                ? t("page.tickets.details.comment-field.send-note-tooltip")
                : t("page.tickets.details.comment-field.send-message-tooltip")
            }
            placeholder={
              commentState === "note"
                ? t("page.tickets.details.comment-field.placeholder-note")
                : t("page.tickets.details.comment-field.placeholder-message")
            }
            isNote={commentState === "note"}
            onChange={(value) => {
              setValue(value);
              setOriginalText(value);
            }}
            isEdit={isEdit}
            inlineCancel={true}
            onCancel={resetInput}
            value={value}
            autoFocus={autoFocus}
            showCopilot={showCopilot && commentState === "comment"}
            copilotLabel={
              isCopilotText
                ? t("page.tickets.details.copilot.button.regenerate")
                : t("page.tickets.details.copilot.button.generate")
            }
            onCopilotClick={ticketCopilotModalHandler.setTrue}
            onCopilotUndo={resetBodyToOriginal}
            onCopilotRedo={resetBodyToCopilot}
            isCopilotText={isCopilotText}
            hasCopilotText={copilotText !== ""}
          />
        </div>
      </div>
      <TicketCopilot
        key={value}
        isOpen={isTicketCopilotModalOpen}
        onClose={ticketCopilotModalHandler.setFalse}
        onGenerate={(value) => {
          setValue(value);
          setCopilotText(value);
          setIsCopilotText(true);
          ref.current?.focus();
        }}
        ticketId={ticketId!}
        defaultInput={{ input: value }}
      />
    </>
  );
}
