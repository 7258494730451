import { ErrorPage } from "components/Error/ErrorPage";
import type { RouteObject } from "react-router-dom";

import { ReservationDetailsPage } from "./pages/ReservationDetails";
import { ReservationsListPage } from "./pages/ReservationsList";
import { reservationsRoutes } from "./routes";

export const reservationsRoute = [
  {
    path: reservationsRoutes.list,
    element: <ReservationsListPage />,
  },
  {
    path: reservationsRoutes.reservationDetails,
    element: <ReservationDetailsPage />,
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
