import { useImageResolver } from "api/hooks/useImageResolver";
import type { ImageDto } from "api/types";
import trashIcon from "assets/icons/trash-01.svg";
import xIcon from "assets/icons/x-close.svg";
import { DefaultUserAvatar } from "components/DefaultUserAvatar/DefaultUserAvatar";
import { Icon } from "components/Icon/Icon";
import { twResolve } from "helpers/tw-resolve";
import { useEffect, useState } from "react";

export interface UserAvatarProps {
  img?: ImageDto | undefined;
  isUserDeleted?: boolean;
  hideDeletedIcon?: boolean;
  showDeleteButton?: boolean;
  onDelete?: () => void;
  className?: string;
  big?: boolean;
}

export function UserAvatar({
  img,
  isUserDeleted,
  hideDeletedIcon = false,
  showDeleteButton = false,
  onDelete,
  className,
  big,
}: UserAvatarProps): React.ReactNode {
  const resolveImage = useImageResolver();
  const [errored, setErrored] = useState(!img);

  useEffect(() => {
    setErrored(!img);
  }, [img]);

  return (
    <div
      className={twResolve(
        "relative aspect-square size-full shrink-0 rounded-full object-cover after:absolute after:inset-0 after:block after:size-full after:rounded-full after:shadow-[inset_0_-2px_6px_#00000020] after:content-['']",
        isUserDeleted && "opacity-40",
        className,
      )}
    >
      {img && !errored ? (
        <img
          className="relative size-full select-none rounded-full bg-white object-cover"
          src={resolveImage(img, big ? "squareBig" : "square")}
          alt="user avatar"
          loading="lazy"
          onError={() => setErrored(true)}
        />
      ) : (
        <DefaultUserAvatar className="relative size-full select-none rounded-full" />
      )}
      {!hideDeletedIcon && isUserDeleted ? (
        <div className="absolute -left-1/6 -top-1/6 flex size-1/2 items-center justify-center rounded-full bg-red text-white shadow">
          <Icon name={trashIcon} />
        </div>
      ) : null}
      {showDeleteButton && onDelete ? (
        <button
          type="button"
          onClick={onDelete}
          className="absolute -right-1/6 -top-1/6 flex size-1/2 items-center justify-center rounded-full bg-grey text-white shadow"
        >
          <Icon name={xIcon} />
        </button>
      ) : null}
    </div>
  );
}
