import type { ServicePartnerPageDto } from "api/types";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useOnIntersection } from "hooks/useOnIntersection";
import { ServicePartnersCard } from "modules/service-partners/components/Card/ServicePartnersCard";
import { useCallback } from "react";

export interface ServicePartnersListProps {
  data: ServicePartnerPageDto[];
  isDataLoading: boolean;
  loadMoreData: () => void;
  isMoreDataLoading: boolean;
  hasMoreData: boolean;
  onItemDelete: (config: { id: string }) => void;
}

export function ServicePartnersList(props: ServicePartnersListProps): React.ReactNode {
  const user = useSessionUser();
  const { hasMoreData, isMoreDataLoading, loadMoreData } = props;
  const ref = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (hasMoreData && !isMoreDataLoading) {
        loadMoreData?.();
      }
    }, [hasMoreData, isMoreDataLoading, loadMoreData]),
  });

  return (
    <>
      <ul className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-4" data-testid="service-list">
        {props.data.map((partner) => (
          <li key={partner.id}>
            <ServicePartnersCard
              id={partner.id}
              title={partner.title}
              subtitle={partner.subtitle}
              type={partner.category.name}
              phone={partner.phoneNumber}
              email={partner.email}
              media={partner.headerImage ?? user.project.backgroundImage}
              connectionName={partner.connection?.name}
              onDelete={props.onItemDelete}
              canEdit={partner.canEdit}
              canDelete={partner.canDelete}
            />
          </li>
        ))}
      </ul>
      <div className="p-4" ref={ref}>
        {(props.isDataLoading || isMoreDataLoading) && <FullSizeLoader />}
      </div>
    </>
  );
}
