import type { TippyProps } from "@tippyjs/react";
import Tippy from "@tippyjs/react";
import { motion, useSpring } from "framer-motion";
import { Info } from "react-feather";
import { twJoin } from "tailwind-merge";

export interface InfoIconProps {
  tooltip: NonNullable<React.ReactNode>;
  placement?: TippyProps["placement"];
  grayscale?: boolean;
}

export function InfoIcon({ tooltip, placement = "top", grayscale }: InfoIconProps): React.ReactNode {
  const initialScale = 0.5;
  const spring = { stiffness: 500, damping: 25 };
  const opacity = useSpring(0, spring);
  const scale = useSpring(initialScale, spring);

  function onMount() {
    scale.set(1);
    opacity.set(1);
  }

  function onHide({ unmount }: { unmount: () => void }) {
    const cleanup = scale.on("change", (value) => {
      if (value <= initialScale) {
        cleanup();
        unmount();
      }
    });

    scale.set(initialScale);
    opacity.set(0);
  }

  return (
    <Tippy
      animation
      arrow
      trigger="click"
      placement={placement}
      onMount={onMount}
      onHide={onHide}
      render={(props) => (
        <motion.div
          id="tippy-tooltip"
          role="tooltip"
          style={{ scale, opacity }}
          {...props}
          className="max-w-md whitespace-pre-line rounded-2px bg-grey-darkest px-3 py-2 text-xs text-white shadow-md"
        >
          {tooltip}
          <div id="tippy-arrow" data-popper-arrow="" />
        </motion.div>
      )}
    >
      <button type="button">
        <Info
          className={twJoin(
            "shrink-0",
            grayscale ? "text-grey-dark hover:text-grey-darker" : "text-aop-basic-blue hover:text-blue-darker",
          )}
          size={16}
        />
      </button>
    </Tippy>
  );
}
