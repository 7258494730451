import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";

import type { LayoutProps } from "./Layout";
import { useFetchServicesList } from "./Manager";

export interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader(props: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const { data: services, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage, error } = useFetchServicesList();
  const { mutateAsync: deleteService } = useMutation({
    mutationFn: (config: { id: string }) => api.deleteServicepartnersByIdV1(config.id),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SERVICE_PARTNERS_ALL(projectId) });
      showFlashToast({
        type: "success",
        title: t("model.service.action.delete.notification.success"),
      });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.service.action.delete.notification.error"),
      });
    },
  });

  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <>
      {props.children({
        data: services?.pages || [],
        createAllowedTypes: services?.allowedTypes || [],
        isDataLoading: isLoading,
        loadMoreData: fetchNextPage,
        isMoreDataLoading: isFetchingNextPage,
        hasMoreData: !!hasNextPage,
        deleteService,
      })}
    </>
  );
}
