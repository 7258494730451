import { useImageResolver } from "api/hooks/useImageResolver";
import type { ImageDto } from "api/types";
import { Anchor } from "components/Anchor/Anchor";
import { Badge } from "components/Badge/Badge";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { ContextMenu } from "components/ContextMenu/ContextMenu";
import { Capture2, Subtitle2 } from "components/Text/Text";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useSlug } from "hooks/useSlug";
import { useMemo } from "react";
import { Mail as MailIcon, Phone as PhoneIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

export interface ServicePartnersCardProps {
  id: string;
  title: string;
  type: string;
  subtitle?: string;
  media?: ImageDto;
  phone?: string;
  email?: string;
  canEdit: boolean;
  canDelete: boolean;
  connectionName?: string;
  onDelete: (config: { id: string }) => void;
}
export function ServicePartnersCard(props: ServicePartnersCardProps): React.ReactNode {
  const slug = useSlug();
  const { id, onDelete, canEdit, canDelete } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sessionUser = useSessionUser();
  const resolveImage = useImageResolver();

  const actions = useMemo(() => {
    const result: ContextMenuAction[] = [];

    if (canEdit) {
      result.push({
        callback: () => {
          navigate(routes.servicePartners.edit({ slug, id }));
        },
        text: t("common.action.edit"),
      });
    }
    if (canDelete) {
      result.push({
        callback: () => {
          onDelete({ id });
        },
        text: t("common.action.delete"),
      });
    }

    return result;
  }, [canDelete, canEdit, slug, navigate, id, onDelete, t]);

  return (
    <article className="rounded-3px bg-white text-grey-darker ring-1 ring-grey-lighter" data-testid="service-card">
      <div
        className="h-40 rounded-t-3px bg-grey-light bg-cover bg-center p-2"
        style={{
          backgroundImage: props.media ? `url(${resolveImage(props.media, "large")})` : undefined,
        }}
      />
      <div className="flex flex-col items-start gap-y-2 p-3 pb-4 pt-2">
        <div className="grid w-full grid-cols-[1fr_auto] items-start justify-between">
          <dl className="flex flex-col gap-2 overflow-hidden">
            <div>
              <dt>
                <span className="sr-only">mail</span>
              </dt>
              <dd className="inline-grid grid-cols-[auto_1fr] items-center gap-2">
                <MailIcon size={16} />
                <Capture2 className="truncate">{props.email || <i>{t("model.service.email.empty")}</i>}</Capture2>
              </dd>
            </div>
            <div>
              <dt>
                <span className="sr-only">phone</span>
              </dt>
              <dd className="inline-grid grid-cols-[auto_1fr] items-center gap-2">
                <PhoneIcon size={16} />
                <Capture2 className="truncate">
                  {props.phone || <i>{t("model.service.phone-number.empty")}</i>}
                </Capture2>
              </dd>
            </div>
          </dl>
          <ContextMenu actions={actions} />
        </div>
        <div className="flex gap-2">
          {sessionUser.isSuperAdmin && <Badge selected>{props.type}</Badge>}
          {props.connectionName ? <Badge>{props.connectionName}</Badge> : null}
        </div>
        <div className="mt-1 w-full">
          <Anchor to={routes.servicePartners.details({ slug, id })} style="inherit">
            <Subtitle2 className="line-clamp-1 min-h-6 text-black" as="h2">
              {props.title}
            </Subtitle2>
          </Anchor>
          <Capture2 className="line-clamp-2 min-h-11 text-grey-darker">{props.subtitle}</Capture2>
        </div>
      </div>
    </article>
  );
}
