import { BorderlessButton } from "components/Button/Button";
import { Label } from "components/Label/Label";
import { Capture1, Headline2, Headline3, Headline4, Subtitle2 } from "components/Text/Text";
import { BarChart } from "modules/analytics/components/BarChart";
import { NoData } from "modules/analytics/components/NoData";
import { Star } from "react-feather";
import { Trans, useTranslation } from "react-i18next";

export type QuestionCategory = "other" | "community" | "service" | "building" | "feelAtHome";

interface Props {
  number: number;
  category?: QuestionCategory;
  onViewDetails?: () => void;
  isMandatory?: boolean;
  ratingQuestion: string;
  avgRating?: number;
  showChart?: boolean;
  data?: { x: string; y: number }[];
  openQuestion?: string;
  latestResponse?: string;
}

export function SurveyQuestionGroupAnswer({
  number,
  category,
  onViewDetails,
  isMandatory,
  ratingQuestion,
  avgRating,
  showChart,
  data,
  openQuestion,
  latestResponse,
}: Props): React.ReactNode {
  const { t } = useTranslation();

  function getQuestionCategoryLabel(x: QuestionCategory) {
    switch (x) {
      case "building":
        return t("page.automated-surveys.question-type.building");
      case "community":
        return t("page.automated-surveys.question-type.community");
      case "service":
        return t("page.automated-surveys.question-type.service");
      case "feelAtHome":
        return t("page.automated-surveys.question-type.feeling-at-home");
      case "other":
        return t("page.automated-surveys.types.other");
    }
  }

  const noDataAvailable = !data || data.every((x) => x.y === 0 || x.y === undefined);

  return (
    <div className="mb-2 flex w-full flex-col justify-between gap-8 rounded-lg bg-white p-4 shadow-md">
      <div className="flex justify-between">
        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            <Headline3>{number}.</Headline3>
            <Headline3 className="font-normal">{ratingQuestion}</Headline3>
          </div>
          {isMandatory && (
            <Capture1 className="text-red-darker">
              {t("component.surveys-question-group-answer.label.mandatory")}
            </Capture1>
          )}
        </div>
        <div>{category && <Label theme="blue">{getQuestionCategoryLabel(category)}</Label>}</div>
      </div>
      <div className="flex flex-col items-center justify-around gap-2 lg:flex-row lg:gap-20">
        <div className="flex flex-col items-center gap-2">
          <div className="flex items-center gap-2">
            <Star className="text-yellow" size={48} />
            <Headline2>{!avgRating ? t("component.surveys-question-group-answer.not-available") : avgRating}</Headline2>
          </div>
          <Subtitle2 className="font-normal">{t("component.surveys-question-group-answer.label.avg-rating")}</Subtitle2>
        </div>
        {showChart ? (
          <div>
            {noDataAvailable ? (
              <NoData error={null} />
            ) : (
              <BarChart
                type="text"
                primaryData={data}
                formatYTick={(y) => y}
                formatXTick={(x) => `${x} ⭐`}
                showLegend={false}
                labelOrientation="vertical"
                renderLabel={(data) => (
                  <div className="whitespace-pre-line">
                    {t("component.surveys-question-group-answer.chart.tooltip", {
                      amount: data.primary?.y,
                      rating: data.x,
                    })}
                  </div>
                )}
              />
            )}
          </div>
        ) : null}
      </div>
      <div className="flex items-end justify-between">
        <div className="flex flex-col gap-2">
          {openQuestion && <Headline4 className="font-normal">{openQuestion}</Headline4>}
          {latestResponse && (
            <Subtitle2 className="font-normal">
              <Trans
                i18nKey="component.surveys-question-group-answer.label.latest-response"
                values={{
                  answer: latestResponse,
                }}
                components={{
                  italic: <span className="italic" />,
                }}
              />
            </Subtitle2>
          )}
        </div>
        {(!noDataAvailable || !showChart) && onViewDetails && (
          <BorderlessButton onClick={onViewDetails}>
            {t("component.surveys-question-group-answer.button.details")}
          </BorderlessButton>
        )}
      </div>
    </div>
  );
}
