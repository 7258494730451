import { DragOverlay } from "@dnd-kit/core";
import { Subtitle2 } from "components/Text/Text";
import type { ReactNode } from "react";
import { Menu } from "react-feather";

interface Props {
  show: boolean;
  title?: string;
  children: ReactNode;
}

export function DynamicRepeaterItemOverlay({ show, title, children }: Props): React.ReactNode {
  if (!show) {
    return <></>;
  }

  return (
    <DragOverlay>
      {show ? (
        <div className="flex flex-col gap-6 rounded-lg bg-white px-4 pb-8 pt-4 shadow-2xl ring-2 ring-aop-basic-blue">
          <div className="flex flex-row-reverse justify-between">
            <Menu className="text-grey-light" />
            {title && <Subtitle2>{title}</Subtitle2>}
          </div>
          {children}
        </div>
      ) : null}
    </DragOverlay>
  );
}
