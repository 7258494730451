import { motion } from "framer-motion";
import { useBool } from "hooks/useBool";
import { usePermission } from "hooks/usePermission";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import { NavigationContext } from "./NavigationContext";
import { NavigationLink } from "./NavigationLink";
import { NavigationLinkContent } from "./NavigationLinkContent";
import type { NavigationSubLinkProps } from "./NavigationSubLink";

interface NavigationLinkContainerProps {
  icon: React.ReactNode;
  isUnread?: boolean;
  unreadCount?: number;
  title: string;
  children: (React.ReactElement<NavigationSubLinkProps> | null)[];
  isBottomOfScreen?: boolean;
  canCombineIntoOneLink?: boolean;
  "data-testid"?: string;
  isFaded?: boolean;
}

const LINK_CONTAINER_CLASS = "link-container";

export function NavigationLinkContainer({
  icon,
  isUnread = false,
  unreadCount,
  title,
  children,
  isBottomOfScreen,
  canCombineIntoOneLink,
  isFaded,
  ...props
}: NavigationLinkContainerProps): React.ReactNode {
  const { isWide } = useContext(NavigationContext);
  const [isActive, activeHandlers] = useBool();
  const hasPermission = usePermission();
  const location = useLocation();
  const groupRef = useRef<HTMLElement | null>(null);
  const [hitAreaPathData, setHitAreaPathData] = useState<string | undefined>();

  const childPaths = children
    .filter((x) => x)
    .map((x) => x?.props.to)
    .join("|");
  useEffect(() => {
    const childMatch = childPaths.split("|").some((x) => matchPath(x + "/*", location.pathname));

    activeHandlers.set(childMatch);
  }, [activeHandlers, childPaths, location]);

  const filteredChildren = children.filter(
    (x) => React.isValidElement(x) && (!x.props.permission || hasPermission(x.props.permission)),
  );

  const setGroupRef = useCallback(
    (groupElement: HTMLElement | null) => {
      // When the ref is set we create the dynamic hit area

      groupRef.current = groupElement;

      if (isWide) {
        //  No hover effect on wide sidebar
        return;
      }

      if (!groupElement) {
        return;
      }

      // Need to wait for animation to finish before calculating path
      const timeout = setTimeout(() => {
        const linkContainer = groupElement.querySelector(`.${LINK_CONTAINER_CLASS}`);
        if (!linkContainer) {
          return;
        }

        const bbox = groupElement.getBoundingClientRect();
        const linkBbox = linkContainer.getBoundingClientRect();

        const path = createDynamicHitAreaPath(bbox, linkBbox, isBottomOfScreen);

        setHitAreaPathData(path);
      }, 300);

      return () => clearTimeout(timeout);
    },
    [isBottomOfScreen, isWide],
  );

  // Ref callbacks should return void, hence the useCallback
  const groupRefCallback = useCallback((node: HTMLElement | null) => void setGroupRef(node), [setGroupRef]);

  useEffect(() => {
    if (!hitAreaPathData && groupRef.current && !isWide) {
      // When the ref is already set, but the path is not calculated yet (for whatever reason), we need to calculate it
      return setGroupRef(groupRef.current);
    }
  }, [setGroupRef, isWide, hitAreaPathData]);

  if (filteredChildren.length === 0) {
    return null;
  }

  if (filteredChildren.length === 1 && canCombineIntoOneLink) {
    // If the only child passed/available then container should collapse so that this the only child becoming a new parent

    const theOnlyNode = filteredChildren[0]!;

    return (
      <NavigationLink {...theOnlyNode.props} icon={icon}>
        {title}
      </NavigationLink>
    );
  }

  if (isWide) {
    return (
      <div className="group relative" data-testid={props["data-testid"]}>
        <button
          className={twJoin(
            "flex w-full flex-1 items-center rounded-lg p-2 transition-colors hover:bg-aop-basic-blue-lightest focus:outline-none focus-visible:bg-aop-basic-blue-lightest group-has-[.active]:bg-aop-basic-blue-lightest group-has-[.active]:font-semibold group-has-[.active]:text-aop-basic-blue",
            isActive ? "bg-aop-basic-blue-lightest font-semibold text-aop-basic-blue" : "font-medium",
          )}
          onClick={activeHandlers.toggle}
        >
          <NavigationLinkContent
            isWide
            icon={icon}
            isUnread={isUnread}
            unreadCount={unreadCount}
            isFaded={isFaded}
            chevron={isActive ? "down" : "right"}
          >
            {title}
          </NavigationLinkContent>
        </button>
        <motion.div
          aria-hidden={!isActive}
          className="flex flex-col overflow-hidden"
          animate={isActive ? "open" : "closed"}
          initial="closed"
          variants={{
            open: { height: "auto" },
            closed: { height: 0 },
          }}
          // Typescript doesn't support inert attribute yet, but browsers sure do.
          // We don't want the child elements to be focusable when the section is not expanded.
          {...{ inert: isActive ? undefined : "" }}
        >
          <div className="flex flex-col gap-2 py-2 pl-6">{filteredChildren}</div>
        </motion.div>
      </div>
    );
  }

  return (
    <div ref={groupRefCallback} data-testid={props["data-testid"]} className="group relative">
      {/* This is a focusable button for mobile devices, so when clicking it the popup stays open */}
      <button
        className={twJoin(
          "flex w-full flex-1 cursor-default rounded-lg p-3 transition-colors focus:outline-none focus-visible:bg-aop-basic-blue-lightest group-focus-within:bg-aop-basic-blue-lightest group-hover:bg-aop-basic-blue-lightest group-has-[.active]:bg-aop-basic-blue-lightest group-has-[.active]:font-semibold group-has-[.active]:text-aop-basic-blue",
          isActive && isWide ? "bg-aop-basic-blue-lightest text-aop-basic-blue" : undefined,
        )}
        aria-hidden
        tabIndex={-1}
      >
        <NavigationLinkContent isWide={false} icon={icon} isUnread={isUnread} isFaded={isFaded}>
          {title}
        </NavigationLinkContent>
      </button>
      <div
        className={twJoin(
          LINK_CONTAINER_CLASS,
          // Lot of special group hover/focus states needed for tab accessibility
          "pointer-events-none absolute left-full z-20 opacity-0 transition-opacity group-hover:pointer-events-auto group-hover:opacity-100 group-has-[:focus-visible]:pointer-events-auto group-has-[:focus-visible]:opacity-100",
          // ::after state is used to create a block under the button so hovering a little bit below the button doesn't make the popup disappear
          "after:absolute after:right-full after:block after:h-4 after:w-10",
          // Naive way of making the hover list not show outside the viewport. Doing it based on hover and the page center would require complex ref and hover logic.
          isBottomOfScreen ? "-bottom-2 mt-3 after:bottom-12" : "-top-2 mb-3 after:top-12",
        )}
      >
        {hitAreaPathData ? (
          <svg
            aria-hidden
            className={twJoin(
              "pointer-events-none absolute -left-8 size-full",
              isBottomOfScreen ? "bottom-2 scale-y-[-1]" : "-bottom-2",
            )}
            height="1200"
            width="1200"
          >
            <path d={hitAreaPathData} className="fill-transparent group-hover:pointer-events-auto" />
          </svg>
        ) : null}
        <div
          className={twJoin(
            // after class to create a block under the hover element so hovering a little bit out side of it doesn't make the popup disappear
            "ml-2 flex flex-col rounded-lg border border-grey-lightest bg-white p-2 shadow-lg after:absolute after:-right-8 after:left-0 after:-z-10 after:block",
            isBottomOfScreen ? "after:-bottom-2 after:-top-8" : "after:-bottom-8 after:-top-2",
          )}
        >
          <div className="p-2 font-semibold">{title}</div>
          <div className="flex flex-col gap-2">{filteredChildren}</div>
        </div>
      </div>
    </div>
  );
}

// This draws a path that is used to create a hover area for the navigation link container.
// This means that we draw a shape from the menu element to the container so when you hover over the path, the navigation link container will stay open.
// Improves UX for desktop users by reducing chances of the menu closing on them too early because their mouse was slightly outside the menu item.
// https://css-tricks.com/menus-with-dynamic-hit-areas/
function createDynamicHitAreaPath(
  anchor: { x: number; y: number; width: number; height: number },
  menu: { x: number; y: number; width: number; height: number },
  isBottomOfScreen?: boolean,
) {
  if (isBottomOfScreen) {
    return `
    M 0 0
    Q ${menu.x - anchor.x} 0 ${menu.x - anchor.x} 0
    v ${menu.height}
    Q ${menu.x - anchor.x} ${menu.y + menu.height - anchor.y} 0 ${anchor.height}
    h ${anchor.width}
    v ${-anchor.height}
    z
  `;
  }

  return `
    M 0 0
    Q ${menu.x - anchor.x} 0 ${menu.x - anchor.x} ${menu.y - anchor.y}
    v ${menu.height}
    Q ${menu.x - anchor.x} ${anchor.height} 0 ${anchor.height}
    h ${anchor.width}
    v ${-anchor.height}
    z`;
}
