import type { InputProps } from "components/Input/Input";
import { Input } from "components/Input/Input";
import { useBool } from "hooks/useBool";
import type { ChangeEvent } from "react";
import { AlertCircle as AlertCircleIcon } from "react-feather";
import type { FieldPath, FieldPathValue, FieldValues, RegisterOptions } from "react-hook-form";
import { useController } from "react-hook-form";

import { FormErrorWrapper } from "./FormErrorWrapper";

export interface FormInputProps<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> extends Omit<InputProps, "name"> {
  name: TName;
  rules?: RegisterOptions<TFormValues, TName>;
  onChange?: (value?: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  "data-testid"?: string;
  defaultValue?: FieldPathValue<TFormValues, TName>;
}

/**
 * When using input type="number", the value will always be a string. If you need to use a different type, you have to convert it to a string yourself (during submit).
 */
export function FormInput<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>({ name, rules, defaultValue, className, ...props }: FormInputProps<TFormValues, TName>): React.ReactNode {
  const [isFocused, isFocusHandlers] = useBool(false);
  const {
    field,
    fieldState: { error },
  } = useController<TFormValues, TName>({ name, rules, defaultValue });

  const maxLengthValue = typeof rules?.maxLength === "object" ? rules.maxLength.value : undefined;
  const isError = error != null;
  const currentValueLength = `${field.value || ""}`.length || 0;

  return (
    <FormErrorWrapper
      name={name}
      className={className}
      subtext={
        maxLengthValue != null &&
        isFocused && (
          <>
            <span className={maxLengthValue < currentValueLength ? "text-red-dark" : undefined}>
              {currentValueLength}
            </span>
            /{maxLengthValue}
          </>
        )
      }
    >
      <Input
        {...props}
        {...field}
        className={isError ? "pr-8" : undefined}
        value={(field.value as any) || ""}
        aria-invalid={isError}
        onChange={(e) => {
          field.onChange(e);
          props.onChange?.(e);
        }}
        onFocus={(e) => {
          props.onFocus?.(e);
          isFocusHandlers.setTrue();
        }}
        onBlur={(e) => {
          field.onBlur();
          props.onBlur?.(e);
          isFocusHandlers.setFalse();
        }}
      />
      {isError && <AlertCircleIcon className="absolute right-2 top-2/4 -translate-y-2/4 text-red-dark" size={16} />}
    </FormErrorWrapper>
  );
}
