import { useImageResolver } from "api/hooks/useImageResolver";
import type { ImageDto } from "api/types";
import bgPlaceholder from "assets/images/image-placeholder.png";
import { Anchor } from "components/Anchor/Anchor";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { ContextMenu } from "components/ContextMenu/ContextMenu";
import { Label } from "components/Label/Label";
import { Headline4, Subtitle2 } from "components/Text/Text";
import type { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { twJoin } from "tailwind-merge";

interface CardProps {
  children: ReactNode;
  "data-testid"?: string;
  isFaded?: boolean;
  link?: string;
}

export function Card({ children, "data-testid": dataTestId, isFaded, link }: CardProps): React.ReactNode {
  const navigate = useNavigate();

  return (
    <div
      data-testid={dataTestId}
      className={twJoin(
        "relative flex flex-col gap-0 rounded-lg bg-white shadow-sm",
        isFaded ? "text-grey" : "text-black",
        link ? "hover:cursor-pointer hover:shadow-lg" : "",
      )}
      onClick={link ? () => navigate(link) : undefined}
    >
      {children}
    </div>
  );
}

interface CardContentProps {
  children: ReactNode;
  gap?: "2" | "4";
}

export function CardContent({ children, gap }: CardContentProps): React.ReactNode {
  return <div className={twJoin("flex h-2/3 flex-col p-4", gap === "4" ? "gap-4" : "gap-2")}>{children}</div>;
}

interface CardHeaderProps {
  title: string;
  titleLink?: string;
  tag?: string;
  menuActions?: ContextMenuAction[];
}

export function CardHeader({ title, titleLink, tag, menuActions }: CardHeaderProps): React.ReactNode {
  return (
    <div className="flex justify-between gap-4">
      <div className="flex flex-col gap-2">
        {tag ? <Label theme="gray">{tag}</Label> : null}
        {titleLink ? (
          <Anchor style="inherit" to={titleLink} noPropagation>
            <Headline4>{title}</Headline4>
          </Anchor>
        ) : (
          <Headline4>{title}</Headline4>
        )}
      </div>
      {menuActions && menuActions.length > 0 && (
        <div className="-mr-2 flex flex-col items-end gap-4">
          <ContextMenu actions={menuActions} />
        </div>
      )}
    </div>
  );
}

interface CardDescriptionProps {
  description: string;
}

export function CardDescription({ description }: CardDescriptionProps): React.ReactNode {
  return <Subtitle2 className="line-clamp-3 font-normal">{description}</Subtitle2>;
}

interface CardImageProps {
  image: ImageDto | null;
  isFaded?: boolean;
  labels?: ReactNode[];
}

export function CardImage({ image, isFaded, labels }: CardImageProps): React.ReactNode {
  const resolveImage = useImageResolver();

  return (
    <>
      {labels && (
        <div className="absolute left-2 top-2 flex flex-col items-start gap-1">
          {labels.map((label, i) => (
            <div key={i} className="flex rounded bg-white">
              {label}
            </div>
          ))}
        </div>
      )}
      <div
        className={twJoin("h-56 rounded-t-lg bg-grey-lighter bg-cover bg-center", isFaded ? "opacity-50" : "")}
        style={{ backgroundImage: `url(${image ? resolveImage(image, "large") : bgPlaceholder})` }}
      />
    </>
  );
}

interface CardActionsProps {
  children: ReactNode;
  alignLeft?: boolean;
}

export function CardActions({ children, alignLeft }: CardActionsProps): React.ReactNode {
  return (
    <div
      className={twJoin("mt-auto flex w-full", alignLeft ? "" : "flex-row-reverse")}
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </div>
  );
}
