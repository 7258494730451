import { Capture2, Subtitle2 } from "components/Text/Text";
import { Link } from "react-router-dom";
import { twJoin } from "tailwind-merge";

export interface Tab<T extends string | number> {
  id: T;
  name: string;
  count?: number;
  mobileName?: string;
  icon?: React.ReactNode;
  isSecondary?: boolean;
  isHidden?: boolean;
}

interface ContentTabsProps<T extends string | number> {
  onTabChange: (tabId: T) => void;
  tabs: Tab<T>[];
  activeTabId: T;
  hideOnZero?: boolean;
  children: React.ReactNode;
}

export function ContentTabs<T extends string | number>({
  onTabChange,
  tabs,
  activeTabId,
  hideOnZero,
  children,
}: ContentTabsProps<T>): React.ReactNode {
  return (
    <>
      <nav className="flex h-full overflow-x-auto bg-[#F8F8FA]">
        {tabs.map((tab) =>
          activeTabId === tab.id ? (
            <Link
              className={twJoin(
                "cursor-pointer whitespace-nowrap border-t-4",
                tab.isSecondary ? "border-white bg-aop-basic-blue" : "border-aop-basic-blue bg-white",
                tab.isHidden && "hidden",
              )}
              key={`tab_${tab.id}`}
              to={`?tab=${tab.id}`}
              onClick={(e) => {
                e.preventDefault();
                onTabChange(tab.id);
              }}
            >
              <Subtitle2
                className={twJoin("inline-block p-4 text-center", tab.isSecondary ? "text-white" : "text-black")}
              >
                <span className="flex items-center gap-2">
                  {tab.icon && <span>{tab.icon}</span>}
                  <span className="hidden md:block">{tab.name}</span>
                  {tab.count == null || (hideOnZero && tab.count === 0) ? null : (
                    <Capture2 className="hidden items-center justify-center rounded-full border border-grey-lighter bg-white px-2 py-0.5 font-normal text-grey-darkest md:flex">
                      {tab.count}
                    </Capture2>
                  )}
                  <span className="block md:hidden">{tab.mobileName || tab.name}</span>
                </span>
              </Subtitle2>
            </Link>
          ) : (
            <Link
              className={twJoin(
                "cursor-pointer whitespace-nowrap border-x border-t-4 border-x-grey-lightest first:border-l-0",
                tab.isSecondary
                  ? "border-t-[#CCECF9] bg-[#CCECF9] hover:border-t-[#A8ECF9] hover:bg-[#A8ECF9]"
                  : "border-t-[#F8F8FA] bg-[#F8F8FA] hover:border-t-white hover:bg-white",
                tab.isHidden && "hidden",
              )}
              key={`tab_${tab.id}`}
              to={`?tab=${tab.id}`}
              onClick={(e) => {
                e.preventDefault();
                onTabChange(tab.id);
              }}
            >
              <Capture2
                className={twJoin(
                  "inline-block p-4",
                  tab.isSecondary ? "font-semibold text-black" : "text-grey-dark hover:text-grey-darkest",
                )}
              >
                <span className="flex items-center gap-2">
                  {tab.icon && <span>{tab.icon}</span>}
                  <span className="hidden md:block">{tab.name}</span>
                  {tab.count == null || (hideOnZero && tab.count === 0) ? null : (
                    <Capture2 className="hidden items-center justify-center rounded-full border border-grey-lighter bg-white px-2 py-0.5 font-normal md:flex">
                      {tab.count}
                    </Capture2>
                  )}
                  <span className="block md:hidden">{tab.mobileName || tab.name}</span>
                </span>
              </Capture2>
            </Link>
          ),
        )}
      </nav>
      <div className="mt-0 rounded-b-md bg-white">{children}</div>
    </>
  );
}
