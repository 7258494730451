import { usePermission } from "hooks/usePermission";
import type { ReactNode } from "react";
import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import { NavigationContext } from "./NavigationContext";
import { NavigationLinkContent } from "./NavigationLinkContent";
import type { NavigationSubLinkProps } from "./NavigationSubLink";

interface NavigationLinkProps extends NavigationSubLinkProps {
  icon: ReactNode;
  isUnread?: boolean;
  unreadCount?: number;
  children: string;
  isBig?: boolean;
  isFaded?: boolean;
}

export function NavigationLink({
  permission,
  to,
  icon,
  children,
  isUnread = false,
  unreadCount,
  exact,
  "data-testid": dataTestId,
  isBig,
  isFaded,
}: NavigationLinkProps): React.ReactNode {
  const hasPermission = usePermission();
  const { isWide } = useContext(NavigationContext);

  if (permission && !hasPermission(permission)) {
    return null;
  }

  return (
    <NavLink
      end={exact}
      to={to}
      className={twJoin(
        "group relative flex w-full rounded-lg font-medium transition-colors hover:bg-aop-basic-blue-lightest focus:outline-none focus-visible:bg-aop-basic-blue-lightest [&.active]:bg-aop-basic-blue-lightest [&.active]:font-semibold [&.active]:text-aop-basic-blue",
        isWide ? "p-2" : "p-3",
      )}
      data-testid={dataTestId}
    >
      <NavigationLinkContent
        isWide={isWide}
        icon={icon}
        isUnread={isUnread}
        unreadCount={unreadCount}
        isBig={isBig}
        isFaded={isFaded}
      >
        {children}
      </NavigationLinkContent>
      {!isWide && (
        <span className="pointer-events-none absolute left-full top-0 z-20 mb-3 ml-2 flex flex-col whitespace-nowrap rounded-lg bg-black p-2 font-semibold text-white opacity-0 transition-opacity delay-300 group-hover:opacity-100 group-focus-visible:opacity-100 group-has-[:focus-visible]/sidebar:delay-0">
          {children}
        </span>
      )}
    </NavLink>
  );
}
