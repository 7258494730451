import type { AssetBookingDto, EventCategoryDto, EventDto } from "api/types";
import { BorderlessButton, Button, LinkButton } from "components/Button/Button";
import { CardGrid } from "components/CardGrid/CardGrid";
import { Checkbox } from "components/Checkbox/Checkbox";
import { ContentTabs, type Tab } from "components/ContentTabs/ContentTabs";
import { LoadingIcon } from "components/Icons/Icons";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { SearchInput } from "components/SearchInput/SearchInput";
import { Select } from "components/Select/Select";
import { Capture2, Subtitle2 } from "components/Text/Text";
import { useBool } from "hooks/useBool";
import { useDebounce } from "hooks/useDebounce";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import type { CalendarData, EventsFilters, UpdatableEventFilter } from "modules/calendar/constants";
import { type CalendarTab, EVENTS_TAB } from "modules/calendar/constants";
import { getEventCategoryName } from "modules/events/util";
import { ReservationCard } from "modules/reservations/pages/ReservationsList/components/ReservationsCard";
import { useDeferredValue, useEffect, useState } from "react";
import { Filter, X } from "react-feather";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

import { EventCard } from "./components/EventCard";

export interface LayoutProps {
  tabs: Tab<CalendarTab>[];
  activeTab: CalendarTab;
  onTabChange: (tab: CalendarTab) => void;
  eventsFilters: Omit<EventsFilters, "TimeFrame" | "Connection">;
  eventCategories: EventCategoryDto[];
  onEventFilterChange: (filter: UpdatableEventFilter, value: string | boolean | undefined) => void;
  events: { upcoming: CalendarData<EventDto>; past: CalendarData<EventDto> };
  reservationsSearch: string;
  onReservationsSearch: (query: string) => void;
  reservations: { upcoming: CalendarData<AssetBookingDto>; past: CalendarData<AssetBookingDto> };
}

const DEBOUNCE_WAIT = 250;

export function Layout({
  tabs,
  activeTab,
  onTabChange,
  eventsFilters,
  eventCategories,
  onEventFilterChange,
  events,
  reservationsSearch,
  onReservationsSearch,
  reservations,
}: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const hasPermission = usePermission();

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.calendar.title")}
      subTitle={t("page.calendar.subtitle")}
      actions={
        activeTab === EVENTS_TAB ? (
          hasPermission((x) => x.events.canManage) ? (
            <LinkButton styling="primary" to={routes.events.create({ slug })}>
              {t("page.calendar.button.new-event")}
            </LinkButton>
          ) : null
        ) : (
          <LinkButton styling="primary" to={routes.bookings.list({ slug })}>
            {t("page.calendar.button.new-reservation")}
          </LinkButton>
        )
      }
    >
      <ContentTabs tabs={tabs} activeTabId={activeTab} onTabChange={onTabChange}>
        {activeTab === EVENTS_TAB ? (
          <EventsFilterTab categories={eventCategories} onFilterUpdate={onEventFilterChange} filters={eventsFilters} />
        ) : (
          <ReservationsFilterTab onSearch={onReservationsSearch} />
        )}
      </ContentTabs>
      {activeTab === EVENTS_TAB ? (
        <EventsContentTab search={eventsFilters.Search} upcoming={events.upcoming} past={events.past} />
      ) : (
        <ReservationsContentTab upcoming={reservations.upcoming} past={reservations.past} search={reservationsSearch} />
      )}
    </DocumentPaper>
  );
}

interface EventsFiltersProps {
  onFilterUpdate: (filter: UpdatableEventFilter, value: string | boolean | undefined) => void;
  filters: Omit<EventsFilters, "TimeFrame" | "Connection">;
  categories: EventCategoryDto[];
}

function EventsFilterTab({ categories, onFilterUpdate, filters }: EventsFiltersProps): React.ReactNode {
  const { t } = useTranslation();

  const [showEventsFilters, eventsFiltersHandler] = useBool(false);
  const [search, setSearch] = useState("");
  const deferredSearch = useDeferredValue(useDebounce(search.toLowerCase().trim(), DEBOUNCE_WAIT));

  useEffect(() => {
    onFilterUpdate("Search", deferredSearch);
  }, [deferredSearch, onFilterUpdate]);

  return (
    <div className="flex flex-col gap-6 p-4">
      <div className="flex items-center gap-2">
        <SearchInput
          data-testid="search-input"
          className="lg:w-96"
          placeholder={t("page.calendar.search-events.placeholder")}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button styling="primaryFaded" onClick={eventsFiltersHandler.toggle}>
          <span className="flex items-center gap-2">
            <Filter size={16} />
            {t("page.tickets.header.button.filter")}
          </span>
        </Button>
      </div>
      {showEventsFilters ? (
        <div className="flex flex-col gap-6">
          <Subtitle2>{t("page.calendar.tabs.events.filter.label")}</Subtitle2>
          <div className="flex flex-wrap gap-6">
            <label className="flex items-center gap-2">
              <Checkbox name="CreatedByMe" onChange={(e) => onFilterUpdate("CreatedByMe", e.target.checked)} />
              <Subtitle2 className="font-normal">{t("page.calendar.tabs.events.filter.my-events")}</Subtitle2>
            </label>
            <label className="flex items-center gap-2">
              <Checkbox name="IsSignedUp" onChange={(e) => onFilterUpdate("IsSignedUp", e.target.checked)} />
              <Subtitle2 className="font-normal">{t("page.calendar.tabs.events.filter.going")}</Subtitle2>
            </label>
            <Select
              placeholder={t("page.calendar.tabs.events.filter.type.placeholder")}
              renderOption={(category) => getEventCategoryName(t, category.id)}
              keySelector={(category) => category.id}
              items={[{ id: "all", name: t("model.event.category.all") } as const, ...categories]}
              selected={filters.Category ? categories.find((x) => x.id === filters.Category) : undefined}
              onChange={(x) => onFilterUpdate("Category", x.id === "all" ? undefined : x.id)}
            />
          </div>
          <div className="self-center justify-self-end">
            <BorderlessButton onClick={eventsFiltersHandler.setFalse}>
              <X size={16} />
              <Capture2>{t("page.calendar.tabs.events.filter.close")}</Capture2>
            </BorderlessButton>
          </div>
        </div>
      ) : null}
    </div>
  );
}

interface EventsContentTabProps {
  search?: string;
  upcoming: CalendarData<EventDto>;
  past: CalendarData<EventDto>;
}

function EventsContentTab({ search, upcoming, past }: EventsContentTabProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4 pt-4">
      <Subtitle2>{t("page.calendar.events.section.upcoming")}</Subtitle2>
      {upcoming.isLoading ? (
        <div className="flex w-full justify-center">
          <LoadingIcon className="size-4" />
        </div>
      ) : upcoming.items.length === 0 ? (
        <Capture2>
          {search ? t("page.calendar.events.search.no-results") : t("page.calendar.events.no-upcoming-events")}
        </Capture2>
      ) : (
        <>
          <CardGrid>
            {upcoming.items.map((event) => (
              <EventCard key={event.id} event={event} />
            ))}
          </CardGrid>
          {upcoming.isLoadingMore ? (
            <div className="flex w-full justify-center">
              <LoadingIcon className="size-4" />
            </div>
          ) : upcoming.total! > upcoming.items.length ? (
            <BorderlessButton className="text-aop-basic-blue" onClick={upcoming.loadMore}>
              {t("page.calendar.events.section.show-remaining", { count: upcoming.total! - upcoming.items.length })}
            </BorderlessButton>
          ) : null}
        </>
      )}
      <Subtitle2>{t("page.calendar.events.section.past")}</Subtitle2>
      {past.isLoading ? (
        <div className="flex w-full justify-center">
          <LoadingIcon className="size-4" />
        </div>
      ) : past.items.length === 0 ? (
        <Capture2>
          {search ? t("page.calendar.events.search.no-results") : t("page.calendar.events.no-past-events")}
        </Capture2>
      ) : (
        <>
          <CardGrid>
            {past.items.map((event) => (
              <EventCard key={event.id} event={event} isPast />
            ))}
          </CardGrid>
          {past.isLoadingMore ? (
            <div className="flex w-full justify-center">
              <LoadingIcon className="size-4" />
            </div>
          ) : past.hasMore ? (
            <BorderlessButton className="text-aop-basic-blue" onClick={past.loadMore}>
              {t("page.calendar.events.section.show-more")}
            </BorderlessButton>
          ) : null}
        </>
      )}
    </div>
  );
}

interface ReservationsFilterTabProps {
  onSearch: (query: string) => void;
}

function ReservationsFilterTab({ onSearch }: ReservationsFilterTabProps): React.ReactNode {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const deferredSearch = useDeferredValue(useDebounce(search.toLowerCase().trim(), DEBOUNCE_WAIT));

  useEffect(() => {
    onSearch(deferredSearch);
  }, [deferredSearch, onSearch]);

  return (
    <div className="p-4">
      <SearchInput
        className="lg:w-96"
        placeholder={t("page.calendar.search-reservations.placeholder")}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
    </div>
  );
}

interface ReservationsContentTabProps {
  search?: string;
  upcoming: CalendarData<AssetBookingDto>;
  past: CalendarData<AssetBookingDto>;
}

function ReservationsContentTab({ upcoming, past, search }: ReservationsContentTabProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4 pt-4">
      <Subtitle2>{t("page.calendar.reservations.section.upcoming")}</Subtitle2>
      {upcoming.isLoading ? (
        <div className="flex w-full justify-center">
          <LoadingIcon className="size-4" />
        </div>
      ) : upcoming.items.length === 0 ? (
        <Capture2>
          {search
            ? t("page.calendar.reservations.search.no-results")
            : t("page.calendar.reservations.no-upcoming-reservations")}
        </Capture2>
      ) : (
        <>
          <CardGrid>
            {upcoming.items.map((reservation) => (
              <ReservationCard key={reservation.id} reservation={reservation} />
            ))}
          </CardGrid>
          {upcoming.isLoadingMore ? (
            <div className="flex w-full justify-center">
              <LoadingIcon className="size-4" />
            </div>
          ) : upcoming.hasMore ? (
            <BorderlessButton className="text-aop-basic-blue" onClick={upcoming.loadMore}>
              {t("page.calendar.reservations.section.show-more")}
            </BorderlessButton>
          ) : null}
        </>
      )}
      <Subtitle2>{t("page.calendar.reservations.section.past")}</Subtitle2>
      {past.isLoading ? (
        <div className="flex w-full justify-center">
          <LoadingIcon className="size-4" />
        </div>
      ) : past.items.length === 0 ? (
        <Capture2>
          {search
            ? t("page.calendar.reservations.search.no-results")
            : t("page.calendar.reservations.no-past-reservations")}
        </Capture2>
      ) : (
        <>
          <CardGrid>
            {past.items.map((reservation) => (
              <ReservationCard key={reservation.id} reservation={reservation} isPast />
            ))}
          </CardGrid>
          {past.isLoadingMore ? (
            <div className="flex w-full justify-center">
              <LoadingIcon className="size-4" />
            </div>
          ) : past.hasMore ? (
            <BorderlessButton className="text-aop-basic-blue" onClick={past.loadMore}>
              {t("page.calendar.events.section.show-more")}
            </BorderlessButton>
          ) : null}
        </>
      )}
    </div>
  );
}
