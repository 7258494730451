import type { EventDetailsDto } from "api/types";
import { List } from "components/List/List";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Capture2, Headline4 } from "components/Text/Text";
import { User } from "components/User/User";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import { useTranslation } from "react-i18next";

import { EventDetails } from "./components/EventDetails";

export interface LayoutProps {
  eventDetails: EventDetailsDto;
}
export function Layout(props: LayoutProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <DocumentPaper theme="minimal" title={t("page.event.details.title")}>
      <div className="flex flex-wrap gap-5">
        <div className="min-w-96 flex-1 bg-white px-5 py-4 xs:rounded-lg">
          <EventDetails eventDetails={props.eventDetails} />
        </div>

        <div className="min-w-96 flex-1 bg-white px-5 py-4 xs:rounded-lg" data-testid="event-participants">
          <Headline4 as="h2" className="block leading-relaxed tracking-[-0.008em]">
            {t("page.event.details.participants.title")}
          </Headline4>
          <Capture2 as="p" data-testid="event-participants-number" className="block leading-5 text-grey-darker">
            {t("page.event.details.participants.details", { count: props.eventDetails.participantCount })}
          </Capture2>
          <div className="mt-4" data-testid="event-participants-list">
            <List getKey={(item) => item.id} list={props.eventDetails.participants}>
              {(user) => (
                <UserNameLink key={user.id} user={user}>
                  <User
                    avatar={user.avatar}
                    deletedAt={user.deletedAt}
                    title={user.fullName}
                    subtitle={user.locatedAt}
                  />
                </UserNameLink>
              )}
            </List>
          </div>
        </div>
      </div>
    </DocumentPaper>
  );
}
