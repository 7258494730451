import type { ConnectedProjectDto } from "api/types";
import { SearchInput } from "components/SearchInput/SearchInput";
import { Capture2, Overline2, Subtitle2 } from "components/Text/Text";
import { sortBy } from "lodash-es";
import { useMemo, useState } from "react";
import { Briefcase as BriefcaseIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

import { ProjectItem } from "./ProjectItem";

export interface ProjectListProps {
  projects: ConnectedProjectDto[];
  recentProjects?: ConnectedProjectDto[];
  currentProjectId: string;
  showCurrentProject?: boolean;
  showRecentProjects?: boolean;
  showPortfolio?: boolean;
  isLoading?: boolean;
  onSelectProject: (projectId: string) => void;
  onClose?: () => void;
  noBottomPadding?: boolean;
  maxProjects?: number;
}

export function ProjectList({
  projects,
  recentProjects = [],
  currentProjectId,
  showCurrentProject,
  showRecentProjects,
  showPortfolio,
  isLoading,
  onSelectProject,
  onClose,
  noBottomPadding,
  maxProjects,
}: ProjectListProps): React.ReactNode {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");

  recentProjects = showRecentProjects ? recentProjects : [];

  const filteredList = useMemo(() => {
    const searchTerm = searchQuery?.trim().toLowerCase();
    const recentProjectIds = new Set(recentProjects.map((x) => x.id));
    const tempList = projects.filter(
      (p) =>
        (searchTerm || !recentProjectIds.has(p.id)) &&
        (showCurrentProject || p.id !== currentProjectId) &&
        (!searchTerm || p.name.toLowerCase().includes(searchTerm) || p.city?.toLowerCase().includes(searchTerm)),
    );

    const sortedList = sortBy(tempList, (x) => x.name.toUpperCase());
    if (maxProjects) {
      return sortedList.slice(0, maxProjects);
    }

    return sortedList;
  }, [currentProjectId, maxProjects, projects, recentProjects, searchQuery, showCurrentProject]);

  const showRecent = !searchQuery && !!recentProjects.length;
  const showOther = !!filteredList.length;
  const showNoResultsFound = !!searchQuery && !filteredList.length;

  return (
    <div className="flex flex-col gap-4">
      {projects.length > 3 && (
        <SearchInput
          className="w-full"
          value={searchQuery}
          placeholder={t("component.project-switcher.search.placeholder")}
          onChange={(e) => setSearchQuery(e.target.value || "")}
          onKeyDown={(e) => {
            if (e.key === "Enter" && filteredList.length === 1) {
              onSelectProject(filteredList[0].id);
            }
          }}
          autoFocus={!("ontouchstart" in document.documentElement && window.innerWidth < 1024)}
        />
      )}
      {isLoading ? (
        <div>{t("component.project-switcher.loading")}</div>
      ) : (
        <div className={twJoin("overflow-auto", noBottomPadding ? "" : "pb-7")}>
          {showPortfolio && !searchQuery && (
            <>
              <Link
                className="flex w-full items-center gap-4 rounded-md p-2 hover:bg-green-lightest focus:outline-none focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-grey-darkest"
                to={routes.portfolio.overview()}
                onClick={onClose}
              >
                <div className="flex size-14 items-center justify-center">
                  <BriefcaseIcon className="shrink-0 text-grey-darker" size={28} />
                </div>
                <div className="flex flex-1 flex-col items-start">
                  <Subtitle2 className="line-clamp-1 break-all text-left">
                    {t("component.project-switcher.portfolio")}
                  </Subtitle2>
                  <Overline2 className="text-grey-darker">
                    {t("component.project-switcher.portfolio.subtitle")}
                  </Overline2>
                </div>
              </Link>
              {showRecent || showOther ? <hr className="my-4 h-px border-0 bg-grey-lighter" /> : null}
            </>
          )}
          {showRecent && (
            <ul className="flex flex-col gap-2 pr-2">
              {recentProjects.map((p) => (
                <li key={p.id}>
                  <ProjectItem project={p} onClick={() => onSelectProject(p.id)} />
                </li>
              ))}
            </ul>
          )}
          {showRecent && showOther && <hr className="my-4 h-px border-0 bg-grey-lighter" />}
          {showOther && (
            <ul className="flex flex-col gap-2 pr-2">
              {filteredList.map((p) => (
                <li key={p.id}>
                  <ProjectItem project={p} onClick={() => onSelectProject(p.id)} />
                </li>
              ))}
            </ul>
          )}
          {showNoResultsFound && (
            <Capture2 className="mt-4 text-grey-dark" as="p">
              {t("component.project-switcher.search.no-results")}
            </Capture2>
          )}
        </div>
      )}
    </div>
  );
}
