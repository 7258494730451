import { Api } from "api/types";
import type { SecurityData } from "authentication/SecurityDataContext";
import { useSecurityData } from "authentication/SecurityDataContext";
import { getFromConfig } from "helpers/config";
import type { MutableRefObject } from "react";
import React, { useEffect, useMemo, useRef, useState } from "react";

export const ProjectHeaderName = "X-ProjectId";

export const ApiClientContext = React.createContext<{ api: Api<SecurityData>; isAuthenticated: boolean }>(null!);

interface Props {
  projectId?: string;
}

export function ApiClientProvider({ projectId, children }: React.PropsWithChildren<Props>): React.ReactNode {
  const securityData = useSecurityData();
  const [apiClient, setApiClient] = useState<Api<SecurityData>>();
  const dataRef = useRef({ projectId });
  dataRef.current.projectId = projectId;

  if (apiClient && securityData) {
    apiClient.setSecurityData(securityData);
  }

  const isAuthenticated = !!securityData;

  useEffect(() => {
    let cancelled = false;

    async function buildClient() {
      try {
        const client = await buildApiClient(dataRef);

        if (!cancelled) {
          setApiClient(client);
        }
      } catch (error) {
        // Rethrow so ErrorBoundary can catch the error
        setApiClient(() => {
          throw error;
        });
      }
    }

    void buildClient();

    return () => {
      cancelled = true;
    };
  }, []);

  const value = useMemo(
    () => (apiClient ? { api: apiClient, isAuthenticated } : undefined),
    [apiClient, isAuthenticated],
  );

  if (!value) {
    return null;
  }

  return <ApiClientContext.Provider value={value}>{children}</ApiClientContext.Provider>;
}

const buildApiClient = async function (dataRef: MutableRefObject<{ projectId?: string }>): Promise<Api<SecurityData>> {
  const baseUrl = await getFromConfig("newCoreApiRootUri");
  if (!baseUrl) {
    throw new Error(`No API base URL configured.
    If you're in development you have to fix your .env file (check .env.example).
    If you're in acceptance/production check whether ConfigCat has "newCoreApiRootUri" set to valid URL`);
  } else if (typeof baseUrl !== "string") {
    throw new Error(`BaseURL of configuration variable "newCoreApiRootUri" has wrong type configured`);
  } else {
    return new Api<SecurityData>({
      baseUrl: baseUrl,
      customFetch: (input, init) => {
        init = populateProjectIdHeader(init, dataRef.current.projectId);

        return fetch(input, init);
      },
      securityWorker: async (securityData: SecurityData | null) => {
        if (securityData?.getToken) {
          const token = await securityData.getToken();

          if (!token) {
            throw new Error("No token available");
          }

          return { headers: { Authorization: `Bearer ${token}` } };
        }
      },
    });
  }
};

/**
 * Populates (or removes) the projectId header in the request init object.
 */
function populateProjectIdHeader(init: RequestInit | undefined, projectId: string | undefined) {
  init = init || {};

  // If only we could decide on a single way to set headers...

  // Logic here:
  // If no headers are passed, we want to set the projectId header.
  // If an empty projectId is passed (e.g. null, undefined, ""), we don't want to send the header at all. Sometimes needed to get all data.
  if (!init.headers) {
    if (projectId) {
      init.headers = {
        [ProjectHeaderName]: projectId,
      };
    }
  } else if (init.headers instanceof Headers) {
    const hasHeader = init.headers.has(ProjectHeaderName);
    if (hasHeader) {
      const value = init.headers.get(ProjectHeaderName);
      if (!value) {
        init.headers.delete(ProjectHeaderName);
      }
    } else {
      if (projectId) {
        init.headers.append(ProjectHeaderName, projectId);
      }
    }
  } else if (Array.isArray(init.headers)) {
    const header = init.headers.find(([name]) => name === ProjectHeaderName);
    if (header) {
      if (!header[1]) {
        init.headers = init.headers.filter(([name]) => name !== ProjectHeaderName);
      }
    } else {
      if (projectId) {
        init.headers.push([ProjectHeaderName, projectId]);
      }
    }
  } else if (typeof init.headers === "object") {
    const hasHeader = ProjectHeaderName in init.headers;
    if (hasHeader) {
      const value = init.headers[ProjectHeaderName];
      if (!value) {
        delete init.headers[ProjectHeaderName];
      }
    } else {
      if (projectId) {
        init.headers[ProjectHeaderName] = projectId;
      }
    }
  }

  return init;
}
