import "./polyfills/crypto-randomuuid";

import * as Sentry from "@sentry/react";
import { ErrorPage } from "components/Error/ErrorPage";
import { initializeClarity } from "external/clarity";
import { initializeCookieBot } from "external/cookiebot";
import { initializeGoogleAnalytics } from "external/g-analytics";
import { initializeSentry } from "external/sentry";
import { GlobalLoadingSpinner } from "providers/GlobalLoadingSpinner";
import React from "react";
import { Async } from "react-async";
import * as ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import { router } from "./App";

async function initialize() {
  // Cookie bot must go first to potentially block cookies
  await initializeCookieBot(() => {
    initializeGoogleAnalytics();
    initializeClarity();
  });

  if (!("container" in document.documentElement.style)) {
    await import("container-query-polyfill");
  }

  if (import.meta.env.VITE_MOCK_API_IN_DEVELOPMENT === "true") {
    // imported dynamically to avoid being included in production
    console.warn("Mocks enabled");
    const { worker } = await import("./helpers/mocks/browser");
    await worker.start({ onUnhandledRequest: "bypass", quiet: true });
  }
}

initializeSentry();

const rootElement = document.getElementById("root")!;

const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={({ error }) => <ErrorPage error={error} />}>
      <GlobalLoadingSpinner>
        <Async promiseFn={initialize}>
          <Async.Fulfilled>
            <RouterProvider router={router} />
          </Async.Fulfilled>
        </Async>
      </GlobalLoadingSpinner>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
