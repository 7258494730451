declare global {
  interface Window {
    clarity: any;
  }
}

const CLARITY_ID = "hy4lkcecfo";

export function initializeClarity(): void {
  if (import.meta.env.DEV) {
    return;
  }

  window.clarity =
    window.clarity ||
    function () {
      // eslint-disable-next-line prefer-rest-params
      (window.clarity.q = window.clarity.q || []).push(arguments);
    };
  const script = document.createElement("script");
  script.async = true;
  script.src = "https://www.clarity.ms/tag/" + CLARITY_ID;

  const firstScript = document.getElementsByTagName("script")[0];
  firstScript.parentNode!.insertBefore(script, firstScript);

  if (window.Cookiebot?.consent.statistics) window.clarity("consent");
}

export function setClarityTag(key: string, value: string): void {
  if (window.clarity) {
    window.clarity("set", key, value);
  }
}
