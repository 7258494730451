import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { Button } from "components/Button/Button";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent } from "components/Form/FormContent";
import { GrowLabel } from "components/GrowLabel/GrowLabel";
import { LoadingIcon } from "components/Icons/Icons";
import { InfoIcon } from "components/InfoIcon/InfoIcon";
import { Capture1, Capture2, Subtitle2 } from "components/Text/Text";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routes } from "routes";

import type { WidgetTypes } from "../constants";

interface DataWidgetModalProps {
  activeWidgets: WidgetTypes[];
  onClose: () => void;
}

interface DataWidgetModalFormValues {
  ticketRating: boolean;
  ticketSolvingTime: boolean;
  engagement: boolean;
  contribution: boolean;
}

export function DataWidgetModal({ activeWidgets, onClose }: DataWidgetModalProps): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const query = useQueryClient();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();
  const form = useForm<DataWidgetModalFormValues>();

  const activateWidgets = useMutation({
    mutationFn: (widgetTypes: WidgetTypes[]) =>
      api.postWidgetsActiveV1({ widgetDataType: widgetTypes }).then((x) => x.data),
    onSuccess: async () => {
      showFlashToast({ type: "success", title: t("page.home.data-widgets.save.success") });
      await query.invalidateQueries({ queryKey: QUERY_KEYS.DATA_WIDGETS_LIST(projectId) });
    },
    onError() {
      showFlashToast({ type: "error", title: t("page.home.data-widgets.save.error") });
    },
  });

  const handleSave = async () => {
    const formValues = form.getValues();
    const widgetsToActivate = Object.entries(formValues)
      .filter(([, value]) => value === true)
      .map((x) => x[0] as WidgetTypes);

    await activateWidgets.mutateAsync(widgetsToActivate);

    onClose();
  };

  return (
    <div className="flex max-h-96 flex-col items-center gap-4 px-4 py-2 lg:max-h-fit">
      <div className="flex flex-col items-center gap-2">
        <Subtitle2>{t("components.data-widgets.modal.title")}</Subtitle2>
        <Capture2>{t("components.data-widgets.modal.subtitle")}</Capture2>
      </div>
      <div className="flex max-h-min flex-col gap-4 overflow-y-auto p-4">
        <Form className="grid grid-cols-1 gap-4 lg:grid-cols-2" formMethods={form} onSubmit={handleSave}>
          <FormContent className="items-center">
            <TicketRatingWidget display />
            <FormCheckbox<DataWidgetModalFormValues>
              name="ticketRating"
              label={t("components.data-widgets.ticket-rating.title")}
              defaultValue={activeWidgets.includes("ticketRating")}
            />
          </FormContent>
          <FormContent className="items-center">
            <TicketSolvingTimeWidget display />
            <FormCheckbox<DataWidgetModalFormValues>
              name="ticketSolvingTime"
              label={t("components.data-widgets.ticket-solving-time.title")}
              defaultValue={activeWidgets.includes("ticketSolvingTime")}
            />
          </FormContent>
          <FormContent className="items-center">
            <EngagementWidget display />
            <FormCheckbox<DataWidgetModalFormValues>
              name="engagement"
              label={t("components.data-widgets.engagement.title")}
              defaultValue={activeWidgets.includes("engagement")}
            />
          </FormContent>
          <FormContent className="items-center">
            <ContributionWidget display />
            <FormCheckbox<DataWidgetModalFormValues>
              name="contribution"
              label={t("components.data-widgets.contribution.title")}
              defaultValue={activeWidgets.includes("contribution")}
            />
          </FormContent>
        </Form>
      </div>
      <div className="flex gap-2 self-end">
        <Button styling="primaryFaded" onClick={onClose}>
          {t("common.action.cancel")}
        </Button>
        <Button styling="primary" onClick={handleSave} disabled={activateWidgets.isPending}>
          {t("common.action.save")}
        </Button>
      </div>
    </div>
  );
}

interface DataWidgetInternalProps {
  type: WidgetTypes;
  title: string;
  tooltip: string;
  neutralMessage: string;
  increaseMessage: string;
  decreaseMessage: string;
  display?: boolean;
}

function DataWidget({
  type,
  title,
  tooltip,
  neutralMessage,
  increaseMessage,
  decreaseMessage,
  display = false,
}: DataWidgetInternalProps): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { t } = useTranslation();
  const api = useApi();

  const {
    data: widgetDetails = { percentage: 0, isIncreasePositive: true },
    error,
    isLoading: isLoadingWidgetDetails,
  } = useQuery({
    queryKey: QUERY_KEYS.DATA_WIDGETS_DETAILS(projectId, type),
    queryFn: () => api.getWidgetsDataDetailsV1(type),
    select: (x) => x.data,
    enabled: !display,
  });

  return (
    <div className="relative flex w-full flex-col gap-2 overflow-hidden rounded-lg bg-white px-4 py-3 shadow-md">
      {isLoadingWidgetDetails ? (
        <div className="absolute inset-0 flex w-full items-center justify-center bg-white">
          <LoadingIcon className="w-8" />
        </div>
      ) : null}
      <div className="flex items-center gap-2">
        <Link
          to={
            type === "engagement"
              ? routes.analytics.engagementDetails({ slug })
              : type === "contribution"
                ? routes.analytics.contributionDetails({ slug })
                : routes.analytics.overview({ slug }) + "#tickets-section"
          }
        >
          <Capture1>{title}</Capture1>
        </Link>
        <InfoIcon tooltip={tooltip} />
      </div>
      <div className="flex items-center justify-between gap-2">
        {error ? (
          <Capture2>{t("components.data-widgets.error")}</Capture2>
        ) : (
          <>
            <Capture2>
              {display
                ? neutralMessage
                : widgetDetails.percentage === 0
                  ? neutralMessage
                  : widgetDetails.percentage > 0
                    ? increaseMessage
                    : decreaseMessage}
            </Capture2>
            <GrowLabel
              type="percentage"
              percentage={widgetDetails.percentage}
              increasingIsBad={!widgetDetails.isIncreasePositive}
            />
          </>
        )}
      </div>
    </div>
  );
}

interface DataWidgetProps {
  display?: boolean;
}

export function TicketRatingWidget({ display }: DataWidgetProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <DataWidget
      type="ticketRating"
      title={t("components.data-widgets.ticket-rating.title")}
      tooltip={t("components.data-widgets.ticket-rating.tooltip")}
      neutralMessage={t("components.data-widgets.ticket-rating.message.neutral")}
      increaseMessage={t("components.data-widgets.ticket-rating.message.increase")}
      decreaseMessage={t("components.data-widgets.ticket-rating.message.decrease")}
      display={display}
    />
  );
}
export function TicketSolvingTimeWidget({ display }: DataWidgetProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <DataWidget
      type="ticketSolvingTime"
      title={t("components.data-widgets.ticket-solving-time.title")}
      tooltip={t("components.data-widgets.ticket-solving-time.tooltip")}
      neutralMessage={t("components.data-widgets.ticket-solving-time.message.neutral")}
      increaseMessage={t("components.data-widgets.ticket-solving-time.message.increase")}
      decreaseMessage={t("components.data-widgets.ticket-solving-time.message.decrease")}
      display={display}
    />
  );
}

export function EngagementWidget({ display }: DataWidgetProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <DataWidget
      type="engagement"
      title={t("components.data-widgets.engagement.title")}
      tooltip={t("components.data-widgets.engagement.tooltip")}
      neutralMessage={t("components.data-widgets.engagement.message.neutral")}
      increaseMessage={t("components.data-widgets.engagement.message.increase")}
      decreaseMessage={t("components.data-widgets.engagement.message.decrease")}
      display={display}
    />
  );
}

export function ContributionWidget({ display }: DataWidgetProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <DataWidget
      type="contribution"
      title={t("components.data-widgets.contribution.title")}
      tooltip={t("components.data-widgets.contribution.tooltip")}
      neutralMessage={t("components.data-widgets.contribution.message.neutral")}
      increaseMessage={t("components.data-widgets.contribution.message.increase")}
      decreaseMessage={t("components.data-widgets.contribution.message.decrease")}
      display={display}
    />
  );
}
