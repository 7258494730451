import type { QuickReplyRepairRequestDto, QuickReplyRepairRequestStatusDto, UserDto } from "api/types";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { LoadingIcon } from "components/Icons/Icons";
import { Select } from "components/Select/Select";
import { Capture1, Capture2, Overline1 } from "components/Text/Text";
import { TicketStatus } from "components/Ticket/TicketStatus";
import { parseISO } from "date-fns";
import { daysBetween } from "helpers/date";
import { Calendar as CalendarIcon, User as UserIcon, Users as UsersIcon } from "react-feather";
import { Trans, useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

interface TicketInfoHeaderProps {
  ticket: QuickReplyRepairRequestDto;
  project: string;
  isChangingStatus: boolean;
  canChangeStatus: boolean;
  canChangeAssignee: boolean;
  onChangeStatus: (status: QuickReplyRepairRequestStatusDto) => void;
  onChangeAssignee: (assignee: UserDto) => void;
  changedAssignee?: UserDto;
  showProject: boolean;
}

export function TicketInfoHeader({
  ticket,
  project,
  canChangeStatus,
  canChangeAssignee,
  isChangingStatus,
  onChangeStatus,
  onChangeAssignee,
  changedAssignee,
  showProject,
}: TicketInfoHeaderProps): React.ReactNode {
  const { t, i18n } = useTranslation();

  return (
    <div className="flex flex-col gap-6 rounded-lg border-b border-grey-lightest bg-white p-4">
      <div className="flex flex-wrap gap-x-6 gap-y-2">
        {ticket.isCollective ? (
          <DetailInfo title={t("page.tickets.details.info.type")}>
            <div className="flex items-center gap-1 rounded bg-grey-lightest p-2">
              <UsersIcon className="shrink-0 text-blue" size={16} />
              <Capture1 className="text-blue-dark">{t("page.tickets.details.info.type.collective")}</Capture1>
            </div>
          </DetailInfo>
        ) : (
          <DetailInfo title={t("page.tickets.details.info.type")}>
            <div className="flex items-center gap-1 rounded bg-grey-lightest p-2">
              <UserIcon className="shrink-0 text-grey-dark" size={16} />
              <Capture1 className="text-grey-darker">{t("page.tickets.details.info.type.private")}</Capture1>
            </div>
          </DetailInfo>
        )}
        <DetailInfo title={t("page.tickets.details.info.id")}>
          <div className="gap-1 rounded bg-blue-lightest px-2 py-1.5">
            <Capture1>{ticket.requestId}</Capture1>
          </div>
        </DetailInfo>
        <DetailInfo title={t("page.tickets.details.info.open-since")}>
          <div className="gap-1 rounded bg-grey-lightest px-2 py-1.5">
            <Capture1>
              {t("page.tickets.details.info.open-since.content", {
                count: daysBetween(parseISO(ticket.postedAt), new Date()),
              })}
            </Capture1>
          </div>
        </DetailInfo>
        <DetailInfo title={t("page.tickets.details.info.status")} smallGap>
          {isChangingStatus ? (
            <LoadingIcon className="my-1.5 ml-3 w-5" />
          ) : (
            <Select
              disabled={ticket.possibleStatuses?.length === 0 || !canChangeStatus}
              selected={ticket.status}
              items={ticket.possibleStatuses || []}
              keySelector={(x) => x.id}
              renderOption={(x) => <TicketStatus description={x.description} labelColor={x.labelColor} />}
              onChange={onChangeStatus}
            />
          )}
        </DetailInfo>
        <DetailInfo title={t("page.tickets.details.info.assignee")} smallGap>
          <Select
            placeholder={t("page.tickets.details.info.assignee.unassigned")}
            disabled={!canChangeAssignee}
            selected={changedAssignee || ticket.assignee}
            items={ticket.possibleAssignees || []}
            keySelector={(x) => x.id}
            renderOption={(x) => x.fullName}
            onChange={onChangeAssignee}
          />
        </DetailInfo>
        {showProject && project ? (
          <DetailInfo title={t("page.tickets.details.info.project")}>
            <div className="gap-1 rounded bg-grey-lightest px-2 py-1.5">
              <Capture1>{project}</Capture1>
            </div>
          </DetailInfo>
        ) : null}
        <DetailInfo title={t("page.tickets.details.info.category")}>
          <div className="gap-1 rounded bg-grey-lightest px-2 py-1.5">
            <Capture1>{ticket.category.name}</Capture1>
          </div>
        </DetailInfo>
      </div>
      <div className="flex items-center gap-2">
        <CalendarIcon size={18} className="shrink-0" />
        <Capture2>
          <Trans
            i18nKey="page.tickets.details.created-at"
            values={{
              date: formatDate(i18n, "datetime", ticket.postedAt),
              name: ticket.author.fullName,
            }}
            components={{
              bold: <b />,
            }}
          />
        </Capture2>
      </div>
    </div>
  );
}

function DetailInfo({ title, children, smallGap }: { title: string; children: React.ReactNode; smallGap?: boolean }) {
  return (
    <div className={twJoin("flex flex-col", smallGap ? "gap-1" : "gap-1.5")}>
      <Overline1 className="text-grey-darker">{title}</Overline1>
      <div className="flex">{children}</div>
    </div>
  );
}
