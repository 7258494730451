import { useInfiniteQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { CreateGroupChatRequest, UserDto } from "api/types";
import backIcon from "assets/icons/chevron-left.svg";
import usersIcon from "assets/icons/users-plus.svg";
import { Button, IconButton } from "components/Button/Button";
import { Checkbox } from "components/Checkbox/Checkbox";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormImageInput } from "components/Form/FormImageInput";
import { FormInput } from "components/Form/FormInput";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import type { FormImage } from "components/ImageInput/useImageInput";
import { Modal } from "components/Modal/Modal";
import { SearchInput } from "components/SearchInput/SearchInput";
import { Capture1, Capture2, Headline4, Overline2 } from "components/Text/Text";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { AnimatePresence, motion, type Variants } from "framer-motion";
import { validateSize } from "helpers/file-size";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { createRequiredStringRule } from "helpers/rules";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useConfig } from "hooks/useConfig";
import { useDebounce } from "hooks/useDebounce";
import { useOnIntersection } from "hooks/useOnIntersection";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useDeferredValue, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "routes";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

interface FormValues {
  groupMembers: UserDto[];
  groupName: string;
  groupImage?: FormImage[];
}

const DEBOUNCE_WAIT = 200;
const AVAILABLE_USERS_PAGE = 10;
const MIN_SEARCH_CHARACTERS = 2;
const GROUP_NAME_MIN_LENGTH = 5;
const GROUP_NAME_MAX_LENGTH = 40;

const variants = {
  enter: (direction: "left" | "right") => {
    return {
      x: direction === "right" ? "100%" : "-100%",
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: "left" | "right") => {
    return {
      zIndex: 0,
      x: direction === "left" ? "100%" : "-100%",
      opacity: 0,
    };
  },
} satisfies Variants;

export function CreateChatModal({ isOpen, onClose }: Props): React.ReactNode {
  const { t } = useTranslation();
  const { setting: useGroupChats } = useConfig("useGroupChats");
  const slug = useSlug();
  const projectId = useProjectId();
  const sessionUser = useSessionUser();
  const api = useApi();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const showFlashToast = useFlashToast();
  const { uploadFormImage, isUploadingImage } = useUploadImage();

  const [step, setStep] = useState<number>(0);
  const [direction, setDirection] = useState<"left" | "right">("right");
  const [query, setQuery] = useState<string>("");

  const form = useForm<FormValues>({ defaultValues: { groupMembers: [] } });

  const selectedUsers = useWatch({ control: form.control, name: "groupMembers" });

  const debouncedQuery = useDeferredValue(
    useDebounce(query.trim().length < MIN_SEARCH_CHARACTERS ? "" : query, DEBOUNCE_WAIT),
  );

  const {
    data: availableUsersData,
    hasNextPage: hasMoreUsers,
    fetchNextPage: fetchMoreUsers,
    isLoading: isLoadingUsers,
    isFetchingNextPage: isLoadingMoreUsers,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.CHATS_AVAILABLE_USERS(projectId, debouncedQuery),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getChatsAvailableUsersV1({
          search: debouncedQuery,
          Offset: pageParam * AVAILABLE_USERS_PAGE,
          Limit: AVAILABLE_USERS_PAGE,
        })
        .then(commonAPIDataSelector),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
    enabled: isOpen,
    staleTime: 30 * 1000, // 30 seconds
  });

  const { mutateAsync: createGroupChat, isPending: isCreatingGroupChat } = useMutation({
    mutationFn: (payload: CreateGroupChatRequest) => api.postChatsGroupV2(payload).then(({ data }) => data),
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CHATS_LIST(projectId) });
    },
    onSuccess: (data) => {
      handleClose();
      navigate(routes.chats.details({ slug, id: data.id }));
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("page.chats.create-chat.group.error"),
      });
    },
  });

  const totalUsers = useMemo(() => availableUsersData?.pages[0].total ?? 0, [availableUsersData]);
  const availableUsers = useMemo(() => availableUsersData?.pages.flatMap((x) => x.items) ?? [], [availableUsersData]);

  const ref = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isLoadingUsers && hasMoreUsers) {
        void fetchMoreUsers();
      }
    }, [fetchMoreUsers, hasMoreUsers, isLoadingUsers]),
  });

  function handlePrevStep(step: number) {
    setDirection("left");
    // Timeout to prevent race condition with the direction animation
    setTimeout(() => {
      setStep(step);
    }, 10);
  }

  function handleNextStep(step: number) {
    setDirection("right");
    // Timeout to prevent race condition with the direction animation
    setTimeout(() => {
      setStep(step);
    }, 10);
  }

  function handleClose() {
    form.reset();
    setDirection("right");
    setStep(0);
    onClose();
  }

  async function handleSubmit(values: FormValues) {
    const image = await uploadFormImage(values.groupImage?.[0]);

    await createGroupChat({
      name: values.groupName,
      imageId: image?.id,
      userIds: values.groupMembers.map((x) => x.id),
    });
  }

  const isSubmitting = isCreatingGroupChat || isUploadingImage;

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose} shouldCloseOnEsc>
      <Form formMethods={form} onSubmit={handleSubmit}>
        <AnimatePresence mode="popLayout" initial={false}>
          <motion.div
            key={step}
            custom={direction}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.1 },
            }}
            variants={variants}
            className="flex max-w-md flex-col gap-3 px-5 pb-2 pt-5"
            data-testid="chats-user-search-modal"
          >
            {step === 0 ? (
              <>
                <Headline4 as="h2">{t("page.chats.modal.new-chat.start-title")}</Headline4>
                <SearchInput
                  data-testid="search-input"
                  onChange={(e) => setQuery(e.target.value.trim())}
                  placeholder={
                    sessionUser.project.type === "addressBased"
                      ? t("page.chats.modal.new-chat.input-placeholder.address-based")
                      : t("page.chats.modal.new-chat.input-placeholder.company-based")
                  }
                />
                {useGroupChats ? (
                  <Button styling="secondaryWhite" className="w-full" onClick={() => handleNextStep(1)}>
                    <div className="flex items-center gap-2">
                      <Icon name={usersIcon} />
                      <Capture1>{t("page.chats.modal.new-chat.group-chat.button")}</Capture1>
                    </div>
                  </Button>
                ) : null}
                {totalUsers > 0 && (
                  <Capture1>{t("page.chats.modal.new-chat.total-results", { count: totalUsers })}</Capture1>
                )}
                <div className="max-h-96 overflow-y-scroll rounded-md md:min-h-96 md:min-w-96 md:max-w-xl">
                  {isLoadingUsers ? (
                    <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
                  ) : availableUsers.length === 0 ? (
                    <Capture2 className="px-3 pb-5 pt-1 text-grey-dark" as="p">
                      {t("page.chats.modal.new-chat.no-results")}
                    </Capture2>
                  ) : (
                    <ul>
                      {availableUsers.map((availableUser) => (
                        <li key={availableUser.id} data-testid="available-user">
                          <Link to={routes.users.details({ slug, id: availableUser.id })}>
                            <div className="border-b border-b-grey-lightest p-3 hover:shadow-sm">
                              <div className="flex grow items-center gap-3">
                                <div className="size-10 shrink-0">
                                  <UserAvatar img={availableUser?.avatar} />
                                </div>
                                <div className="text-sm">
                                  <span className="font-semibold text-black">{availableUser.fullName}</span>
                                  <p className="text-grey">
                                    <span>{availableUser.locatedAt}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                  {!isLoadingUsers && hasMoreUsers && (
                    <div className="p-4" ref={ref}>
                      {isLoadingMoreUsers && <LoadingIcon className="inset-0 mx-auto my-4 w-6" />}
                    </div>
                  )}
                </div>
              </>
            ) : step === 1 ? (
              <>
                <div className="flex items-center gap-2">
                  <IconButton onClick={() => handlePrevStep(0)}>
                    <Icon name={backIcon} size={24} />
                  </IconButton>
                  <Headline4 as="h2">{t("page.chats.modal.new-chat.group-chat.select-member.title")}</Headline4>
                </div>
                <SearchInput
                  data-testid="search-input"
                  onChange={(e) => setQuery(e.target.value.trim())}
                  placeholder={
                    sessionUser.project.type === "addressBased"
                      ? t("page.chats.modal.new-chat.input-placeholder.address-based")
                      : t("page.chats.modal.new-chat.input-placeholder.company-based")
                  }
                />
                {selectedUsers.length > 0 ? (
                  <div className="flex max-w-sm gap-6 overflow-x-auto rounded-lg border border-grey-lighter bg-grey-lightest p-4">
                    {selectedUsers.map((user) => (
                      <div key={user.id} className="flex flex-col items-center gap-2 text-center">
                        <UserAvatar
                          key={user.id}
                          img={user.avatar}
                          showDeleteButton
                          onDelete={() =>
                            form.setValue(
                              "groupMembers",
                              selectedUsers.filter((x) => x.id !== user.id),
                            )
                          }
                          className="size-14"
                        />
                        <Overline2>{user.firstName}</Overline2>
                      </div>
                    ))}
                  </div>
                ) : null}
                {totalUsers > 0 && (
                  <Capture1>{t("page.chats.modal.new-chat.total-results", { count: totalUsers })}</Capture1>
                )}
                <div className="max-h-96 overflow-y-scroll rounded-md md:min-h-96 md:min-w-96 md:max-w-xl">
                  {isLoadingUsers ? (
                    <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
                  ) : availableUsers.length === 0 ? (
                    <Capture2 className="px-3 pb-5 pt-1 text-grey-dark" as="p">
                      {t("page.chats.modal.new-chat.no-results")}
                    </Capture2>
                  ) : (
                    <ul>
                      {availableUsers.map((availableUser) => (
                        <li key={availableUser.id} data-testid="available-user">
                          <label
                            htmlFor={`user-${availableUser.id}`}
                            className="flex cursor-pointer items-center justify-between border-b border-b-grey-lightest p-3 hover:shadow-sm"
                          >
                            <div className="flex grow items-center gap-3">
                              <div className="size-10 shrink-0">
                                <UserAvatar img={availableUser?.avatar} />
                              </div>
                              <div className="text-sm">
                                <span className="font-semibold text-black">{availableUser.fullName}</span>
                                <p className="text-grey">
                                  <span>{availableUser.locatedAt}</span>
                                </p>
                              </div>
                            </div>
                            <Checkbox
                              id={`user-${availableUser.id}`}
                              name="groupMembers"
                              checked={selectedUsers.map((x) => x.id).includes(availableUser.id)}
                              onChange={(e) => {
                                form.setValue(
                                  "groupMembers",
                                  e.target.checked
                                    ? [...selectedUsers, availableUser]
                                    : selectedUsers.filter((user) => user.id !== availableUser.id),
                                );
                              }}
                              shape="rounded"
                              color="green"
                            />
                          </label>
                        </li>
                      ))}
                    </ul>
                  )}
                  {!isLoadingUsers && hasMoreUsers && (
                    <div className="p-4" ref={ref}>
                      {isLoadingMoreUsers && <LoadingIcon className="inset-0 mx-auto my-4 w-6" />}
                    </div>
                  )}
                </div>
                {selectedUsers.length > 0 ? (
                  <div className="flex w-full justify-end">
                    <Button styling="primary" onClick={() => handleNextStep(2)}>
                      <Capture1>{t("common.action.next")}</Capture1>
                    </Button>
                  </div>
                ) : null}
              </>
            ) : (
              <>
                <div className="flex items-center gap-2">
                  <IconButton onClick={() => handlePrevStep(1)}>
                    <Icon name={backIcon} size={24} />
                  </IconButton>
                  <Headline4 as="h2">{t("page.chats.modal.new-chat.group-chat.group-details.title")}</Headline4>
                </div>
                <FormContent className="mb-2">
                  <FormField label={t("page.chats.modal.new-chat.group.group-name")} required>
                    <FormInput<FormValues, "groupName">
                      name="groupName"
                      placeholder={t("page.chats.modal.new-chat.group.group-name.placeholder")}
                      rules={{
                        validate: {
                          required: createRequiredStringRule(t, "page.chats.modal.new-chat.group.group-name"),
                        },
                        minLength: {
                          message: t("components.form.error.min-length", { length: GROUP_NAME_MIN_LENGTH }),
                          value: GROUP_NAME_MIN_LENGTH,
                        },
                        maxLength: {
                          message: t("components.form.error.max-length", { length: GROUP_NAME_MAX_LENGTH }),
                          value: GROUP_NAME_MAX_LENGTH,
                        },
                      }}
                      autoComplete="off"
                    />
                  </FormField>
                  <FormField
                    htmlFor="logo"
                    label={t("page.chats.modal.new-chat.group.group-image")}
                    description={t("page.chats.modal.new-chat.group.group-image.description")}
                  >
                    <FormImageInput<FormValues, "groupImage">
                      name="groupImage"
                      rules={{
                        validate: {
                          size(image) {
                            if (image) {
                              return validateSize(t, image);
                            }
                          },
                        },
                      }}
                    />
                  </FormField>
                </FormContent>
                <Capture1>
                  {t("page.chats.modal.new-chat.group.selected-members", { count: selectedUsers.length })}
                </Capture1>
                <div className="grid max-h-80 max-w-sm grid-cols-4 gap-6 overflow-y-auto rounded-lg border border-grey-lighter bg-grey-lightest p-4">
                  {selectedUsers.map((user) => (
                    <div key={user.id} className="flex flex-col items-center gap-2 text-center">
                      <UserAvatar
                        key={user.id}
                        img={user.avatar}
                        showDeleteButton
                        onDelete={() =>
                          form.setValue(
                            "groupMembers",
                            selectedUsers.filter((x) => x.id !== user.id),
                          )
                        }
                        className="size-14"
                      />
                      <Overline2>{user.firstName}</Overline2>
                    </div>
                  ))}
                </div>
                <div className="flex w-full justify-end">
                  <Button
                    type="submit"
                    styling="primary"
                    disabled={selectedUsers.length === 0}
                    isLoading={isSubmitting}
                  >
                    <Capture1>{t("common.action.create")}</Capture1>
                  </Button>
                </div>
              </>
            )}
          </motion.div>
        </AnimatePresence>
      </Form>
    </Modal>
  );
}
