import type { ScalingTextAreaProps } from "components/ScalingTextArea/ScalingTextArea";
import { ScalingTextArea } from "components/ScalingTextArea/ScalingTextArea";
import { twResolve } from "helpers/tw-resolve";
import { useBool } from "hooks/useBool";
import type { ChangeEvent } from "react";
import { AlertCircle as AlertCircleIcon } from "react-feather";
import type { FieldPath, FieldPathValue, FieldValues, RegisterOptions } from "react-hook-form";
import { useController } from "react-hook-form";

import { FormErrorWrapper } from "./FormErrorWrapper";

export interface FormTextAreaProps<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> extends ScalingTextAreaProps {
  name: TName;
  rules?: RegisterOptions<TFormValues, TName>;
  onChange?: (value: ChangeEvent<HTMLTextAreaElement>) => void;
  className?: string;
  "data-testid"?: string;
  compactHeight?: boolean;
  large?: boolean;
  scrollbar?: boolean;
  defaultValue?: FieldPathValue<TFormValues, TName>;
}

export function FormTextArea<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>({
  "data-testid": dataTestid,
  name,
  rules,
  defaultValue,
  className,
  compactHeight,
  large,
  scrollbar,
  ...props
}: FormTextAreaProps<TFormValues, TName>): React.ReactNode {
  const [isFocused, isFocusHandlers] = useBool(false);
  const {
    field,
    fieldState: { error },
  } = useController<TFormValues, TName>({ name, rules, defaultValue });
  const maxLengthValue = typeof rules?.maxLength === "object" ? rules.maxLength.value : undefined;
  const isError = error != null;
  const currentValueLength = `${field.value || ""}`.length || 0;

  return (
    <FormErrorWrapper
      name={name}
      className={twResolve("flex", className)}
      data-testid={dataTestid}
      subtext={
        maxLengthValue != null &&
        isFocused && (
          <>
            <span className={Number(maxLengthValue) < currentValueLength ? "text-red-dark" : undefined}>
              {currentValueLength}
            </span>
            /{maxLengthValue}
          </>
        )
      }
    >
      <ScalingTextArea
        {...props}
        className={twResolve(
          scrollbar ? "no-scrollbar" : undefined,
          "min-h-[6.75rem] w-full rounded-lg px-2 py-1.5 leading-6 ring-1 ring-grey-lighter placeholder:text-grey hover:ring-grey-darker focus-visible:outline-none focus-visible:ring-grey-darkest disabled:bg-grey-lightest disabled:text-grey-light",
          error != null ? "pr-7 ring-2 ring-red-dark" : undefined,
          props.icon && "pl-8",
          compactHeight && "min-h-20",
          large && "min-h-72",
          props.toolbar && "rounded-t-none",
        )}
        {...field}
        value={field.value as any}
        onFocus={(e) => {
          props.onFocus?.(e);
          isFocusHandlers.setTrue();
        }}
        onBlur={(e) => {
          field.onBlur();
          props.onBlur?.(e);
          isFocusHandlers.setFalse();
        }}
        onChange={(e) => {
          field.onChange(e);
          props.onChange?.(e);
        }}
        aria-invalid={error != null}
      />
      {isError && <AlertCircleIcon className="absolute right-2 top-2/4 -translate-y-2/4 text-red-dark" size={16} />}
    </FormErrorWrapper>
  );
}
