import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { LanguageDto } from "api/types";
import chevronDownIcon from "assets/icons/chevron-down.svg";
import logOutIcon from "assets/icons/log-out-01.svg";
import userEditIcon from "assets/icons/user-edit.svg";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { ToggleButton } from "components/ToggleButon/ToggleButton";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { getFlagIcon } from "helpers/flags";
import { useLanguages } from "helpers/languages";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUserOptional } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useClickOutside } from "hooks/useClickOutside";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { type ReactNode, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";
import type { ApiResponseType } from "types/api-types";

export function UserMenu({ isWide, isPortfolio }: { isWide: boolean; isPortfolio?: boolean }): React.ReactNode {
  const { t } = useTranslation();
  const [sessionUser] = useSessionUserOptional();
  const userMenuRef = useRef<HTMLDivElement | null>(null);
  const [userMenuOpen, userMenuOpenHandlers] = useBool(false);
  const slug = useSlug({ optional: true });

  useClickOutside(userMenuRef, userMenuOpenHandlers.setFalse, userMenuOpen);

  const languages = useLanguages();
  const changeLanguage = useChangeLanguage();

  const languageOptions =
    languages.data?.map((l) => ({
      label: (
        <span className="flex flex-row items-center gap-1">
          {getFlagIcon({ languageId: l.id, width: 16 })}
          <span className="block xl:block xs:hidden">{l.description}</span>
          <span className="hidden uppercase xl:hidden xs:block" aria-hidden>
            {l.id}
          </span>
        </span>
      ),
      value: l.id,
    })) || [];

  const selectedLanguage = languageOptions.find((l) => l.value === sessionUser?.language?.id) || languageOptions[0];

  return (
    <div className="relative mt-auto" ref={userMenuRef}>
      <div className="rounded-lg ring-1 ring-inset ring-grey-lightest">
        {userMenuOpen && (
          <div
            className={twJoin(
              "absolute bottom-0 z-20 flex flex-col gap-2 rounded-lg border border-grey-lightest bg-white pb-16",
              isWide ? "inset-x-0" : "left-0 w-[320px]",
            )}
          >
            {!isPortfolio && (
              <div className="flex flex-col gap-1">
                <span className="pl-4 pt-4 text-xs font-bold uppercase text-grey-darkest">
                  {t("component.user-menu.language")}
                </span>
                <div className="p-2">
                  <ToggleButton<LanguageDto["id"], ReactNode>
                    fillWidth
                    selected={selectedLanguage}
                    onChange={(option) => changeLanguage(option.value)}
                    options={languageOptions}
                  />
                </div>
              </div>
            )}
            {!isPortfolio && slug && sessionUser && sessionUser.role.type !== "resident" && (
              <Link
                className="flex w-full flex-row items-center gap-2 p-2 outline-none focus-visible:bg-aop-basic-blue-lightest hocus:bg-aop-basic-blue-lightest"
                to={routes.users.edit({ slug, id: sessionUser.id })}
                onClick={userMenuOpenHandlers.setFalse}
              >
                <span className="p-2">
                  <Icon name={userEditIcon} size={16} />
                </span>
                <span>{t("component.user-menu.user-settings")}</span>
              </Link>
            )}
            <Link
              className="flex w-full flex-row items-center gap-2 p-2 outline-none focus-visible:bg-aop-basic-blue-lightest hocus:bg-aop-basic-blue-lightest"
              to={routes.logout()}
              onClick={userMenuOpenHandlers.setFalse}
            >
              <span className="p-2">
                <Icon name={logOutIcon} size={16} />
              </span>
              <span>{t("component.user-menu.log-out")}</span>
            </Link>
          </div>
        )}
        <button
          className={twJoin(
            "relative z-20 flex w-full flex-row items-center justify-between gap-2 outline-none focus-visible:bg-aop-basic-blue-lightest hocus:bg-aop-basic-blue-lightest",
            isWide ? "p-2" : "p-1",
            userMenuOpen ? (isWide ? "rounded-b-lg" : "rounded-bl-lg") : "rounded-lg",
            userMenuOpen && !isWide
              ? "after:absolute after:left-12 after:line-clamp-1 after:h-full after:w-[260px] after:text-left after:leading-10 after:content-[attr(data-name)]"
              : "",
          )}
          data-name={sessionUser?.fullName || "..."}
          onClick={userMenuOpenHandlers.toggle}
        >
          <div className="flex flex-row items-center gap-2">
            <div className="size-8">
              <UserAvatar img={sessionUser?.avatar} />
            </div>
            {isWide && (
              <span className="line-clamp-1 text-left">
                {sessionUser?.fullName || <LoadingIcon className="w-5 animate-fade-in-delay opacity-0" />}
              </span>
            )}
          </div>
          {isWide && (
            <Icon
              name={chevronDownIcon}
              size={16}
              className={twJoin("transition-transform", userMenuOpen ? "" : "rotate-180")}
            />
          )}
        </button>
      </div>
    </div>
  );
}

function useChangeLanguage() {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const api = useApi();
  const showFlashToast = useFlashToast();
  const languages = useLanguages();

  const { mutateAsync: changeLanguage } = useMutation({
    mutationFn: (languageId: LanguageDto["id"]) => api.putSelfLanguageV1({ languageId }).then(({ data }) => data),
    onMutate: (variables) => {
      const foundLanguage = languages.data?.find((l) => l.id === variables);
      if (foundLanguage) {
        queryClient.setQueryData<ApiResponseType<"getSelfV2"> | undefined>(
          QUERY_KEYS.SELF(projectId),
          (oldResponse) => {
            if (oldResponse) {
              return {
                ...oldResponse,
                data: {
                  ...oldResponse.data,
                  language: foundLanguage,
                },
              };
            }
          },
        );
      }
    },
    onSuccess() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SELF(projectId) });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.self.action.change-language.notification.error"),
      });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SELF(projectId) });
    },
  });

  return changeLanguage;
}
