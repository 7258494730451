import { ErrorPage } from "components/Error/ErrorPage";
import { RequireAuth } from "components/Routing/RequireAuth";
import type { RouteObject } from "react-router-dom";

import { AssetDetailPage } from "./pages/AssetDetail";
import { BookAssetPage } from "./pages/BookAsset";
import { CreateOrEditBookableAssetPage } from "./pages/CreateOrEditAssets";
import { ListBookableAssetsPage } from "./pages/ListAssets";
import { canCreateBookableAssets, canViewSchedule } from "./permissions";
import { bookingsRoutes } from "./routes";

export const bookingsRoute = [
  {
    path: bookingsRoutes.list,
    element: <ListBookableAssetsPage />,
  },
  {
    path: bookingsRoutes.assetDetail,
    element: (
      <RequireAuth permission={canViewSchedule}>
        <AssetDetailPage />
      </RequireAuth>
    ),
  },
  {
    path: bookingsRoutes.createAsset,
    element: (
      <RequireAuth permission={canCreateBookableAssets}>
        <CreateOrEditBookableAssetPage />
      </RequireAuth>
    ),
  },
  {
    path: bookingsRoutes.editAsset,
    element: (
      <RequireAuth permission={canCreateBookableAssets}>
        <CreateOrEditBookableAssetPage />
      </RequireAuth>
    ),
  },
  {
    path: bookingsRoutes.bookAsset,
    element: <BookAssetPage />,
  },
  {
    path: bookingsRoutes.editBooking,
    element: <BookAssetPage />,
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
