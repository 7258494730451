import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { OnboardingScreenEditRequest } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { createFormTranslations, toTranslationsRequest, useLanguages } from "helpers/languages";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { isDefined } from "helpers/util";
import { useProjectId } from "hooks/Network/useProjectId";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "routes";

import type { LayoutProps } from "./Layout";

export function Loader(props: { children: (props: LayoutProps) => React.ReactNode }): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { id: onboardingScreenId } = useParams<{ id: string }>();
  const isEditMode = isDefined(onboardingScreenId);
  const api = useApi();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { uploadFormImage, isUploadingImage } = useUploadImage();
  const {
    data: onboardingScreenDetails,
    isLoading: isLoadingOnboardingScreenDetails,
    error: onboardingScreenDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.ONBOARDING_SCREENS_DETAILS(projectId, onboardingScreenId!),
    queryFn: () => api.getOnboardingScreensDetailsV1(onboardingScreenId!),
    select: commonAPIDataSelector,
    enabled: isEditMode,
  });
  const {
    data: projectDetails,
    isPending: isLoadingProjectDetails,
    error: projectDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.PROJECT_DETAILS(projectId),
    queryFn: () => api.getProjectDetailsV1(),
    select: commonAPIDataSelector,
  });
  const { data: languages = [], error: languagesError, isFetching: languagesIsLoading } = useLanguages();
  const createOnboardingPage = useMutation({
    mutationFn: api.postOnboardingScreensV1,
    onSuccess() {
      showFlashToast({ title: t("page.onboarding-screens.new.notification.success"), type: "success" });
      navigate(routes.onboardingScreens.list({ slug }));
    },
    onError() {
      showFlashToast({ title: t("page.onboarding-screens.new.notification.failed"), type: "error" });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.ONBOARDING_SCREENS_LIST(projectId) });
    },
  });
  const editOnboardingPage = useMutation({
    mutationFn: (data: OnboardingScreenEditRequest) => api.putOnboardingScreensV1(onboardingScreenId!, data),
    onSuccess() {
      showFlashToast({ title: t("page.onboarding-screens.edit.notification.success"), type: "success" });
      navigate(routes.onboardingScreens.list({ slug }));
    },
    onError() {
      showFlashToast({ title: t("page.onboarding-screens.edit.notification.failed"), type: "error" });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.ONBOARDING_SCREENS_LIST(projectId) });
    },
  });

  const error = onboardingScreenDetailsError || projectDetailsError || languagesError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const isLoading = isLoadingOnboardingScreenDetails || isLoadingProjectDetails || languagesIsLoading;
  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  return props.children({
    logoLight: projectDetails?.logoLight?.url,
    styling: {
      bgColor: projectDetails?.styling.navigationColor,
      textColor: projectDetails?.styling.textLightColor,
    },
    isEditMode: isEditMode,
    languages,
    defaultValues: isEditMode
      ? {
          titleTranslations: createFormTranslations(languages, onboardingScreenDetails!.titleTranslations),
          subtitleTranslations: createFormTranslations(languages, onboardingScreenDetails!.subtitleTranslations),
          image: [onboardingScreenDetails!.image],
        }
      : {
          titleTranslations: createFormTranslations(languages, undefined),
          subtitleTranslations: createFormTranslations(languages, undefined),
          image: [],
        },
    isSubmitting: isUploadingImage || editOnboardingPage.isPending || createOnboardingPage.isPending,
    async onSubmit(data) {
      const image = await uploadFormImage(data.image[0]);
      if (!image) {
        throw new Error("Failed to upload image");
      }

      if (isEditMode) {
        editOnboardingPage.mutate({
          titleTranslations: toTranslationsRequest(data.titleTranslations),
          subtitleTranslations: toTranslationsRequest(data.subtitleTranslations),
          imageId: image.id,
        });
      } else {
        createOnboardingPage.mutate({
          titleTranslations: toTranslationsRequest(data.titleTranslations),
          subtitleTranslations: toTranslationsRequest(data.subtitleTranslations),
          imageId: image.id,
        });
      }
    },
  });
}
