import { twResolve } from "helpers/tw-resolve";
import type { ForwardedRef } from "react";
import type React from "react";
import { forwardRef } from "react";

export interface InputProps
  extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "prefix"> {
  ref?: ForwardedRef<HTMLInputElement>;
  className?: string;
  prefix?: React.ReactNode;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { className, prefix, value, ...props },
  ref,
) {
  const isPrefix = prefix != null;

  const Component = isPrefix ? "label" : "div";

  return (
    <Component className="relative">
      {isPrefix && (
        <div className="pointer-events-none absolute left-0 top-0 flex h-full w-7 items-center justify-center text-grey-light">
          {prefix}
        </div>
      )}
      <input
        autoComplete="off"
        {...props}
        value={value}
        className={twResolve(
          "not-disabled:hover:ring-grey-darker h-9 w-full appearance-none rounded-lg px-2 py-1.5 text-base leading-6 ring-1 ring-grey-lighter placeholder:text-grey hover:ring-grey-darker focus-visible:outline-none focus-visible:ring-grey-darkest disabled:bg-grey-lightest disabled:text-grey-light",
          props["aria-invalid"] && "ring-2 ring-red-dark",
          props["readOnly"] && "bg-grey-lightest text-grey-dark",
          isPrefix && "pl-8",
          className,
        )}
        ref={ref}
      />
    </Component>
  );
});
