import { LoadingIcon } from "components/Icons/Icons";
import { ButtonStyle } from "components/Text/Text";
import { Tooltip } from "components/Tooltip/Tooltip";
import { twResolve } from "helpers/tw-resolve";
import type { ButtonHTMLAttributes, MouseEvent, MouseEventHandler, PropsWithChildren } from "react";
import React from "react";
import { Check as CheckIcon, MoreVertical as MoreVerticalIcon, Plus as PlusIcon } from "react-feather";
import type { LinkProps } from "react-router-dom";
import { Link } from "react-router-dom";
import { twJoin } from "tailwind-merge";

type ButtonStyling =
  | "primary"
  | "primaryFaded"
  | "primaryRed"
  | "secondary"
  | "secondaryGreen"
  | "secondaryRed"
  | "secondaryWhite";

type CardButtonStyling = "default" | "filled";

export interface ButtonProps<T extends React.ElementType = "button"> {
  as?: T;
  title?: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  styling?: ButtonStyling;
  onClick?: (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
  "data-testid"?: string;
  disabled?: boolean;
  pressed?: boolean;
  className?: string;
  isLoading?: boolean;
}

const baseButtonStyling =
  "relative rounded-lg px-6 py-2.5 self-start text-base text-center text-white truncate cursor-pointer focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-1 disabled:text-white disabled:bg-grey-lighter disabled:pointer-events-none";

const baseSecondaryButtonStyling =
  "py-2 px-5.5 bg-transparent border-2 disabled:text-grey-light disabled:border-grey-light disabled:ring-grey-light";

function getStyling(styling: ButtonStyling): string {
  switch (styling) {
    case "primary":
      return twResolve(
        baseButtonStyling,
        ButtonStyle,
        "bg-aop-basic-blue ring-aop-basic-blue hover:bg-aop-basic-blue-dark focus-visible:ring-aop-basic-blue-dark",
      );
    case "primaryFaded":
      return twResolve(
        baseButtonStyling,
        ButtonStyle,
        "bg-aop-basic-blue/20 text-aop-basic-blue ring-aop-basic-blue hover:bg-aop-basic-blue/30 focus-visible:ring-aop-basic-blue",
      );
    case "primaryRed":
      return twResolve(
        baseButtonStyling,
        ButtonStyle,
        "bg-red-dark ring-red hover:bg-red-darker focus-visible:ring-red-darker",
      );
    case "secondary":
      return twResolve(
        baseButtonStyling,
        baseSecondaryButtonStyling,
        ButtonStyle,
        "border-aop-basic-blue text-aop-basic-blue ring-aop-basic-blue hover:bg-aop-basic-blue-lightest focus-visible:ring-aop-basic-blue",
      );
    case "secondaryGreen":
      return twResolve(
        baseButtonStyling,
        baseSecondaryButtonStyling,
        ButtonStyle,
        "border-green-dark text-green-dark ring-green-dark hover:bg-green-lightest focus-visible:ring-green-darker",
      );
    case "secondaryRed":
      return twResolve(
        baseButtonStyling,
        baseSecondaryButtonStyling,
        ButtonStyle,
        "border-red-dark text-red-dark ring-red-dark hover:bg-red-lightest/50 focus-visible:ring-red-darker",
      );
    case "secondaryWhite":
      return twResolve(
        baseButtonStyling,
        ButtonStyle,
        "border border-grey-lightest bg-white text-black ring-grey-lightest hover:bg-aop-basic-blue/10 focus-visible:ring-aop-basic-blue",
      );
    default:
      throw new Error(`Unknown button style ${styling}`);
  }
}

export function Button<T extends React.ElementType = "button">({
  className,
  type = "button",
  styling = "primary",
  onClick,
  children,
  pressed,
  disabled,
  isLoading,
  title,
  as,
  ...props
}: React.PropsWithChildren<ButtonProps<T>> &
  Omit<React.ComponentPropsWithoutRef<T>, keyof ButtonProps<T>>): React.ReactNode {
  const Component = as || "button";

  return (
    <Tooltip tooltip={title}>
      <Component
        tabIndex={0}
        type={type}
        aria-pressed={pressed}
        onClick={isLoading ? undefined : onClick}
        disabled={disabled || isLoading}
        {...props}
        className={twResolve(getStyling(styling), isLoading && "!text-transparent", className)}
      >
        <LoadingIcon
          className={twJoin(
            "absolute left-1/2 top-1/2 w-5 -translate-x-1/2 -translate-y-1/2",
            !isLoading ? "hidden" : undefined,
          )}
        />
        <span>{children}</span>
      </Component>
    </Tooltip>
  );
}

export interface BorderlessButtonProps {
  title?: string;
  styling?: BorderlessButtonStyling;
  "data-testid"?: string;
  className?: string;
  pressed?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  "aria-label"?: string;
}

type BorderlessButtonStyling = "default" | "red";

const baseBorderlessButtonStyling =
  "p-1 rounded-2px focus-visible:outline-none focus-visible:ring-2 disabled:text-grey-lighter disabled:pointer-events-none";

function getBorderlessButtonStyling(styling: BorderlessButtonStyling) {
  switch (styling) {
    case "default":
      return twResolve(
        ButtonStyle,
        baseBorderlessButtonStyling,
        "text-grey-darker ring-grey-darkest hocus:text-aop-basic-blue-dark",
      );
    case "red":
      return twResolve(
        ButtonStyle,
        baseBorderlessButtonStyling,
        "text-red-dark ring-red-darkest hover:text-red-darkest",
      );
    default:
      throw new Error(`Unknown button style ${styling}`);
  }
}

export const BorderlessButton = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<
    BorderlessButtonProps & {
      type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
      onClick?: MouseEventHandler<HTMLButtonElement>;
    }
  >
>(function BorderlessButton(
  { className, styling = "default", title, type = "button", onClick, children, isLoading, ...props },
  ref,
) {
  return (
    <Tooltip tooltip={title}>
      <button
        ref={ref}
        type={type}
        onClick={onClick}
        {...props}
        className={twResolve(
          "relative flex items-center whitespace-nowrap focus-visible:outline-none",
          getBorderlessButtonStyling(styling),
          isLoading && "!text-transparent",
          className,
        )}
      >
        <LoadingIcon
          className={twJoin(
            "absolute left-1/2 top-1/2 w-5 -translate-x-1/2 -translate-y-1/2",
            !isLoading ? "hidden" : undefined,
          )}
        />
        {children}
      </button>
    </Tooltip>
  );
});

export const BorderlessExternalLinkButton = React.forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<BorderlessButtonProps & { href: string; onClick?: () => void }>
>(function BorderlessButton({ className, styling = "default", href, onClick, children, isLoading, ...props }, ref) {
  return (
    <a
      ref={ref}
      href={href}
      onClick={onClick}
      {...props}
      className={twResolve(
        "relative flex items-center whitespace-nowrap focus-visible:outline-none",
        getBorderlessButtonStyling(styling),
        isLoading && "!text-transparent",
        className,
      )}
    >
      <LoadingIcon
        className={twResolve(
          "absolute left-1/2 top-1/2 w-5 -translate-x-1/2 -translate-y-1/2",
          !isLoading ? "hidden" : undefined,
        )}
      />
      {children}
    </a>
  );
});

export const BorderlessLinkButton = React.forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<BorderlessButtonProps & LinkProps>
>(function BorderlessButton({ className, styling = "default", to, onClick, children, isLoading, ...props }, ref) {
  return (
    <Link
      ref={ref}
      to={to}
      onClick={onClick}
      {...props}
      className={twResolve(
        "relative flex items-center whitespace-nowrap focus-visible:outline-none",
        getBorderlessButtonStyling(styling),
        isLoading && "!text-transparent",
        className,
      )}
    >
      <LoadingIcon
        className={twResolve(
          "absolute left-1/2 top-1/2 w-5 -translate-x-1/2 -translate-y-1/2",
          !isLoading ? "hidden" : undefined,
        )}
      />
      {children}
    </Link>
  );
});

export interface IconButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  title?: string;
  pressed?: boolean;
  disabled?: boolean;
  className?: string;
  "data-testid"?: string;
}

export function IconButton({
  onClick,
  title,
  pressed,
  className,
  children,
  disabled,
  "data-testid": dataTestid,
}: PropsWithChildren<IconButtonProps>): React.ReactNode {
  return (
    <Tooltip tooltip={title}>
      <button
        aria-label={title}
        className={twResolve(
          "cursor-pointer overflow-hidden rounded-full border-2 border-grey-darkest/0 p-px leading-none text-grey-darker hover:bg-grey-lightest focus-visible:border-grey-darkest focus-visible:outline-none active:text-aop-basic-blue disabled:pointer-events-none disabled:text-grey-light",
          pressed ? "text-aop-basic-blue focus-visible:border-grey-darkest/0" : undefined,
          className,
        )}
        onClick={onClick}
        type="button"
        aria-pressed={pressed}
        data-testid={dataTestid}
        disabled={disabled}
      >
        {children}
      </button>
    </Tooltip>
  );
}

export function MoreButton({ ...props }: IconButtonProps): React.ReactNode {
  return (
    <IconButton {...props}>
      <MoreVerticalIcon size={24} />
    </IconButton>
  );
}

export interface LinkButtonProps {
  to: LinkProps["to"];
  state?: LinkProps["state"];
  onNavigate?: LinkProps["onClick"];
  target?: LinkProps["target"];
  styling?: ButtonStyling;
  className?: string;
  "data-testid"?: string;
}

export function LinkButton({
  className,
  children,
  styling = "primary",
  onNavigate,
  ...props
}: React.PropsWithChildren<LinkButtonProps>): React.ReactNode {
  return (
    <Link
      {...props}
      onClick={onNavigate}
      className={twResolve("inline-block align-middle", getStyling(styling), className)}
    >
      {children}
    </Link>
  );
}

export interface ExternalLinkButtonProps {
  href: string;
  onClick?: () => void;
  styling?: ButtonStyling;
  className?: string;
  "data-testid"?: string;
}

export function ExternalLinkButton({
  className,
  children,
  styling = "primary",
  ...props
}: React.PropsWithChildren<ExternalLinkButtonProps>): React.ReactNode {
  return (
    <a {...props} className={twResolve("inline-block", getStyling(styling), className)}>
      {children}
    </a>
  );
}

export function InvisibleExternalLinkButton({
  className,
  children,
  ...props
}: React.PropsWithChildren<ExternalLinkButtonProps>): React.ReactNode {
  return (
    <a {...props} className={className}>
      {children}
    </a>
  );
}

export function TableEditButton({
  className,
  children,
  ...props
}: React.PropsWithChildren<ButtonProps>): React.ReactNode {
  return (
    <button
      className={twResolve(
        "flex cursor-pointer items-center gap-1 rounded-lg bg-grey-lightest px-2 py-1 hover:ring hover:ring-aop-basic-blue disabled:cursor-default disabled:hover:ring-0",
        className,
      )}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {children}
    </button>
  );
}

export function ToolbarButton({
  className,
  onClick,
  isLoading,
  disabled,
  children,
  ...props
}: React.PropsWithChildren<ButtonProps>): React.ReactNode {
  return (
    <button
      tabIndex={0}
      type="button"
      onClick={isLoading ? undefined : onClick}
      disabled={disabled || isLoading}
      {...props}
      className={twResolve(
        "flex items-center justify-center whitespace-nowrap rounded-lg p-2 focus:outline-1 focus:outline-aop-basic-blue enabled:cursor-pointer enabled:hover:bg-grey-lighter disabled:text-grey",
        className,
      )}
    >
      <LoadingIcon className={twJoin("w-4", !isLoading ? "hidden" : undefined)} />
      <span>{children}</span>
    </button>
  );
}

export interface CardButtonProps<T extends React.ElementType = "button"> {
  as?: T;
  title?: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  styling?: CardButtonStyling;
  onClick?: (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
  "data-testid"?: string;
  disabled?: boolean;
  className?: string;
  isLoading?: boolean;
}

const baseCardButtonStyling =
  "relative rounded-full px-2 py-1 self-start text-base text-center text-white truncate cursor-pointer focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-1 disabled:text-grey disabled:bg-grey-lightest disabled:pointer-events-none flex items-center gap-0.5";

function getCardButtonStyling(styling: CardButtonStyling): string {
  switch (styling) {
    case "default":
      return twResolve(
        baseCardButtonStyling,
        ButtonStyle,
        "bg-aop-dark-blue-lighter text-aop-dark-blue ring-aop-basic-blue hover:bg-aop-dark-blue-light focus-visible:ring-aop-basic-blue-dark",
      );
    case "filled":
      return twResolve(
        baseCardButtonStyling,
        ButtonStyle,
        "bg-aop-dark-blue ring-aop-basic-blue hover:bg-aop-dark-blue-light hover:text-aop-dark-blue focus-visible:ring-aop-basic-blue",
      );
    default:
      throw new Error(`Unknown button style ${styling}`);
  }
}

export function CardButton<T extends React.ElementType = "button">({
  className,
  type = "button",
  styling = "default",
  onClick,
  children,
  pressed,
  disabled,
  isLoading,
  title,
  as,
  ...props
}: React.PropsWithChildren<CardButtonProps<T>> &
  Omit<React.ComponentPropsWithoutRef<T>, keyof CardButtonProps<T>>): React.ReactNode {
  const Component = as || "button";

  return (
    <Tooltip tooltip={title}>
      <Component
        tabIndex={0}
        type={type}
        aria-pressed={pressed}
        onClick={isLoading ? undefined : onClick}
        disabled={disabled || isLoading}
        {...props}
        className={twResolve(getCardButtonStyling(styling), isLoading && "!text-transparent", className)}
      >
        <LoadingIcon className={twJoin("w-5", !isLoading ? "hidden" : undefined)} />
        {styling === "filled" ? (
          <CheckIcon className={twJoin("w-5", isLoading ? "hidden" : undefined)} />
        ) : (
          <PlusIcon className={twJoin("w-5", isLoading ? "hidden" : undefined)} />
        )}
        <span>{children}</span>
      </Component>
    </Tooltip>
  );
}

export function StaticButton({ children }: React.PropsWithChildren<{}>): React.ReactNode {
  return (
    <div
      className={twResolve(
        baseButtonStyling,
        ButtonStyle,
        "inline-block cursor-default bg-grey-darker ring-grey-darkest",
      )}
    >
      {children}
    </div>
  );
}

export interface PillButtonProps<T extends React.ElementType = "button"> {
  as?: T;
  title?: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  onClick?: (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
  "data-testid"?: string;
  className?: string;
}

export function PillButton<T extends React.ElementType = "button">({
  className,
  type = "button",
  onClick,
  children,
  pressed,
  as,
  ...props
}: React.PropsWithChildren<PillButtonProps<T>> &
  Omit<React.ComponentPropsWithoutRef<T>, keyof PillButtonProps<T>>): React.ReactNode {
  const Component = as || "button";

  return (
    <Component
      tabIndex={0}
      type={type}
      aria-pressed={pressed}
      onClick={onClick}
      {...props}
      className={twResolve(
        "z-50 flex items-center gap-1 rounded-full bg-aop-basic-blue p-2 text-white shadow-lg hover:bg-aop-basic-blue-dark",
        className,
      )}
    >
      {children}
    </Component>
  );
}
