import { ErrorPage } from "components/Error/ErrorPage";
import { RequireAuth } from "components/Routing/RequireAuth";
import { RolePage } from "components/Routing/RolePage";
import type { RouteObject } from "react-router-dom";

import { CreateTicketPage } from "./pages/Create";
import { TicketDetailsPage } from "./pages/Details";
import { ListTicketsPage } from "./pages/List";
import { ResidentTicketDetailsPage } from "./pages/ResidentDetails";
import { ResidentListTicketsPage } from "./pages/ResidentList";
import { ticketsRoutes } from "./routes";

export const ticketsRoute = [
  {
    path: ticketsRoutes.list,
    element: (
      <RolePage getPageForRole={(role) => (role === "resident" ? <ResidentListTicketsPage /> : <ListTicketsPage />)} />
    ),
  },
  {
    path: ticketsRoutes.details,
    element: (
      <RolePage
        getPageForRole={(role) => (role === "resident" ? <ResidentTicketDetailsPage /> : <TicketDetailsPage />)}
      />
    ),
  },
  {
    path: ticketsRoutes.create,
    element: (
      <RequireAuth permission={(x) => x.isAdmin && x.userManagement.canListUsers && x.canCreateTicket}>
        <CreateTicketPage />
      </RequireAuth>
    ),
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
