import bg from "assets/images/bg.jpg";
import logo from "assets/images/logo-dark.svg";
import { Headline4 } from "components/Text/Text";

interface Props {
  header: string;
  body?: string;
}

// Coded to be an exact replica of the Azure B2C page
export function AzureB2CPage({ header, body, children }: React.PropsWithChildren<Props>): React.ReactNode {
  return (
    <div className="flex h-screen px-2.5 sm:items-center">
      <div className="fixed -z-10 size-full">
        <img className="fixed left-0 top-0 hidden size-full overflow-hidden object-cover sm:block" src={bg} alt="" />
      </div>
      <div className="mx-auto mt-[30px] w-full max-w-sm px-1">
        <div className="flex flex-col items-center rounded-lg px-2.5 py-[30px] sm:bg-white sm:px-[20px] sm:shadow-[0_5px_10px_rgba(0,0,30,0.05)]">
          <img className="max-h-[60px]" src={logo} alt="Area of People" />
          <div className="w-full text-center">
            <Headline4 className="mb-3.5 mt-10 text-[17px]" as="h1">
              {header}
            </Headline4>
            {body ? <p>{body}</p> : null}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export function AzureB2CSubmitButton({
  children,
  disabled,
}: React.PropsWithChildren<{ disabled?: boolean }>): React.ReactNode {
  return (
    <button
      className="w-full rounded-lg bg-aop-basic-blue px-4 py-2.5 text-white outline-none focus-visible:shadow-azure disabled:pointer-events-none disabled:opacity-50 hocus:bg-aop-basic-blue-dark"
      type="submit"
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export function AzureB2CLink({ children, href }: React.PropsWithChildren<{ href: string }>): React.ReactNode {
  return (
    <a className="font-semibold text-aop-basic-blue hover:underline" href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
}
