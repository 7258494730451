import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormBadgeMultiSelect } from "components/Form/FormBadgeMultiSelect";
import { Modal } from "components/Modal/Modal";
import { Capture2, Headline4, Subtitle2 } from "components/Text/Text";
import type { Specification } from "modules/bookings/constants";
import { specificationOptions } from "modules/bookings/constants";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface ExtraSpecificationsModalProps {
  isOpen: boolean;
  onClose: () => void;
  availableSpecifications: Specification[];
  onAddSpecifications: (specifications: Specification[]) => void;
}

interface FormValues {
  specifications: Specification[];
}

export function ExtraSpecificationsModal({
  isOpen,
  onClose,
  availableSpecifications,
  onAddSpecifications,
}: ExtraSpecificationsModalProps): React.ReactNode {
  const { t } = useTranslation();

  const form = useForm<FormValues>({
    defaultValues: {
      specifications: availableSpecifications,
    },
  });

  function onSubmit() {
    onAddSpecifications(form.getValues("specifications"));
    onClose();
  }

  useEffect(() => {
    form.reset({
      specifications: availableSpecifications,
    });
  }, [form, availableSpecifications]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} shouldCloseOnEsc isActionRequired>
      <Form formMethods={form} onSubmit={onSubmit}>
        <div className="flex flex-col gap-4 p-4">
          <Headline4>
            {t("page.bookings.create-or-edit.form.section.asset-details.extra-specifications.modal.title")}
          </Headline4>
          <Subtitle2 className="font-normal">
            {t("page.bookings.create-or-edit.form.section.asset-details.extra-specifications.modal.description")}
          </Subtitle2>
          <FormBadgeMultiSelect<FormValues, "specifications">
            name="specifications"
            items={specificationOptions}
            renderOption={(x) => {
              switch (x) {
                case "sizeSpecification":
                  return (
                    <Capture2>
                      {t(
                        "page.bookings.create-or-edit.form.section.asset-details.extra-specifications.modal.option.size",
                      )}
                    </Capture2>
                  );
                case "pricePerHourSpecification":
                  return (
                    <Capture2>
                      {t(
                        "page.bookings.create-or-edit.form.section.asset-details.extra-specifications.modal.option.price",
                      )}
                    </Capture2>
                  );
                case "capacitySpecification":
                  return (
                    <Capture2>
                      {t(
                        "page.bookings.create-or-edit.form.section.asset-details.extra-specifications.modal.option.capacity",
                      )}
                    </Capture2>
                  );
                case "locationSpecification":
                  return (
                    <Capture2>
                      {t(
                        "page.bookings.create-or-edit.form.section.asset-details.extra-specifications.modal.option.location",
                      )}
                    </Capture2>
                  );
              }
            }}
            keySelector={(x) => x}
            isGrid
            isBig
            centerText
          />
          <div className="grid grid-cols-2 gap-4">
            <Button className="w-full " styling="primaryFaded" onClick={onClose}>
              {t("common.action.cancel")}
            </Button>
            <Button data-testid="confirm-extra-specifications" className="w-full" styling="primary" type="submit">
              {t("common.action.add")}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
}
