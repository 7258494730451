import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { ProjectEditRequest } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import type { LayoutProps } from "./Layout";

export function Loader(props: {
  isEditMode: boolean;
  children: (props: LayoutProps) => React.ReactNode;
}): React.ReactNode {
  const projectId = useProjectId();
  const navigate = useNavigate();
  const sessionUser = useSessionUser();
  const api = useApi();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const {
    data: projectDetails,
    isFetching: isLoadingProjectDetails,
    error: projectDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.PROJECT_DETAILS(projectId),
    queryFn: () => api.getProjectDetailsV1(),
    select: commonAPIDataSelector,
    enabled: props.isEditMode,
  });
  const { data: projects = [], isPending: isLoadingProjects, error: projectsError } = useConnectedProjects();
  const {
    data: languages = [],
    isPending: isLoadingLanguages,
    error: languagesError,
  } = useQuery({
    queryKey: QUERY_KEYS.LANGUAGES_BASE,
    queryFn: () => api.getLanguagesBaseV1(),
    select: commonAPIDataSelector,
  });
  const createProject = useMutation({
    mutationFn: api.postProjectV1,
    async onSuccess(result) {
      showFlashToast({ title: t("page.projects.new.notification.success"), type: "success" });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CONNECTED_PROJECTS });
      navigate(`/@/${result.data.slug}`);
    },
    onError() {
      showFlashToast({ title: t("page.projects.new.notification.failed"), type: "error" });
    },
  });
  const editProject = useMutation({
    mutationFn: (data: ProjectEditRequest) => api.putProjectV1(projectId, data),
    onSuccess(result) {
      showFlashToast({ title: t("page.projects.edit.notification.success"), type: "success" });

      if (result.data.slug !== projectDetails?.slug) {
        window.location.href = `/@/${result.data.slug}`;
      } else {
        navigate(`/@/${result.data.slug}`);
        void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SELF(projectId) });
        void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CONNECTED_PROJECTS });
      }
    },
    onError() {
      showFlashToast({ title: t("page.projects.edit.notification.failed"), type: "error" });
    },
  });

  const error = projectDetailsError || languagesError || projectsError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const isLoading = isLoadingProjectDetails || isLoadingLanguages || isLoadingProjects;
  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  return props.children({
    isSuperAdmin: sessionUser.isSuperAdmin,
    isEditMode: props.isEditMode,
    defaultValues: props.isEditMode
      ? {
          city: projectDetails!.city,
          owner: projectDetails!.owner,
          maintenance: projectDetails!.maintenance,
          language: languages.find((x) => x.id === projectDetails!.languageId),
          name: projectDetails!.name,
          slug: projectDetails!.slug,
          type: projectDetails!.type,
        }
      : {
          language: languages[0],
          type: "addressBased",
        },
    languages,
    projects,
    isSubmitting: editProject.isPending || createProject.isPending,
    onSubmit(data) {
      if (props.isEditMode) {
        editProject.mutate({
          city: data.city,
          maintenance: data.maintenance,
          name: data.name,
          owner: data.owner,
          projectType: data.type,
          slug: data.slug,
        });
      } else {
        createProject.mutate({
          city: data.city,
          languageId: data.language.id,
          maintenance: data.maintenance,
          name: data.name,
          owner: data.owner,
          projectType: data.type,
          cloneProjectId: data.cloneProject?.id,
        });
      }
    },
  });
}
