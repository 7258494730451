export function Icon({
  name,
  size = 16,
  className,
}: {
  name: string;
  size?: number;
  className?: string;
}): React.ReactNode {
  return (
    <span className={className}>
      <svg width={size} height={size}>
        <use xlinkHref={`#${name}`} />
      </svg>
    </span>
  );
}
