import { LazyRichTextEditor } from "components/RichTextEditor/LazyRichTextEditor";
import type { RichTextEditorProps } from "components/RichTextEditor/RichTextEditor";
import { twResolve } from "helpers/tw-resolve";
import { debounce } from "lodash-es";
import { useMemo } from "react";
import type { FieldPath, FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";

import { FormErrorWrapper } from "./FormErrorWrapper";

export interface FormRichTextEditorProps<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> extends ExternalProps<TFormValues, TName> {
  "data-testid"?: string;
}
export function FormRichTextEditor<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  "data-testid": dataTestId,
  ...props
}: FormRichTextEditorProps<TFormValues, TName>): React.ReactNode {
  const {
    field,
    fieldState: { error },
  } = useController({
    name: name,
    control: control,
    rules: rules,
    shouldUnregister: shouldUnregister,
    defaultValue: defaultValue,
  });

  const isError = error !== undefined;

  const onChange = field.onChange;
  const debouncedOnChange = useMemo(
    () =>
      debounce((...args: Parameters<typeof onChange>) => {
        onChange(...args);
      }, 500),
    [onChange],
  );

  return (
    <FormErrorWrapper name={name} data-testid={dataTestId}>
      <LazyRichTextEditor
        {...props}
        value={field.value as any}
        className={twResolve(isError ? "ring-2 ring-red-dark" : undefined, props.className)}
        onChange={debouncedOnChange}
        onBlur={onChange}
      />
    </FormErrorWrapper>
  );
}

type ExternalProps<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> = UseControllerProps<TFormValues, TName> & Omit<RichTextEditorProps, "onChange">;
