import type { ConversationReplyDto } from "api/types";
import { BorderlessButton } from "components/Button/Button";
import { CommentFieldWithAvatar } from "components/CommentField/CommentField";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { LoadingIcon } from "components/Icons/Icons";
import type { FormImage } from "components/ImageInput/useImageInput";
import { useImageInput } from "components/ImageInput/useImageInput";
import { Capture1, Capture2 } from "components/Text/Text";
import { stripTime } from "helpers/date";
import { DEFAULT_MAXIMUM_FILE_SIZE_IN_MEGA_BYTES, megaBytesToBytes } from "helpers/file-size";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useOnIntersection } from "hooks/useOnIntersection";
import { groupBy } from "lodash-es";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ChatMessage } from "./ChatMessage";

interface ChatWindowProps {
  canChatWithUser: boolean;
  chatMessages: ConversationReplyDto[];
  hasMoreChatMessages: boolean | undefined;
  fetchMoreChatMessages: () => void;
  refreshChatMessages: () => void;
  isLoadingMoreChatMessages: boolean;
  sendChat: ({ message, files }: { message: string; files: FormImage[] }) => Promise<ConversationReplyDto>;
}

function groupChatMessagesByDate<T extends { time: string }>(chatMessages: T[]): [string, T[]][] {
  return Object.entries(groupBy(chatMessages, (x) => stripTime(x.time).valueOf()));
}

export function ChatWindow({
  canChatWithUser,
  chatMessages,
  hasMoreChatMessages,
  fetchMoreChatMessages,
  isLoadingMoreChatMessages,
  refreshChatMessages,
  sendChat,
}: ChatWindowProps): React.ReactNode {
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const showFlashToast = useFlashToast();
  const [images, setImages] = useState<FormImage[]>([]);
  const { addImages, removeImage, removeImages } = useImageInput({ onChange: setImages, maximumFiles: 1 });
  const { getValues, setValue, watch, register } = useForm<{ messageValue: string }>();
  const scrollRef = useRef<HTMLDivElement>(null);
  const loaderRef = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isLoadingMoreChatMessages && hasMoreChatMessages) {
        fetchMoreChatMessages();
      }
    }, [fetchMoreChatMessages, hasMoreChatMessages, isLoadingMoreChatMessages]),
  });

  const chatMessagesByDate = useMemo(() => groupChatMessagesByDate(chatMessages), [chatMessages]);

  function handleRefresh() {
    scrollRef.current?.scrollTo({ behavior: "smooth", top: scrollRef.current.scrollHeight });
    refreshChatMessages();
  }

  useEffect(() => {
    register("messageValue");
  }, [register]);

  const messageValue = watch("messageValue");

  const onChangeContent = useCallback((value: string) => setValue("messageValue", value), [setValue]);

  async function onSendChat() {
    const { messageValue } = getValues();
    await sendChat({
      message: messageValue,
      files: images,
    });

    removeImages();
    setValue("messageValue", "");
  }

  const onRemoveImage = useCallback(
    (imageToBeRemoved: FormImage) => {
      removeImage(imageToBeRemoved);
    },
    [removeImage],
  );

  return (
    <div className="h-[650px] grow rounded-lg bg-white p-5">
      <div className="flex h-full flex-col justify-between gap-3">
        <div className="flex justify-between">
          <Capture1>{t("page.user-detail.chat.title")}</Capture1>
          <BorderlessButton
            className="font-normal text-aop-basic-blue underline underline-offset-4"
            onClick={handleRefresh}
          >
            {t("page.user-detail.chat.refresh")}
          </BorderlessButton>
        </div>
        <div className="flex grow flex-col-reverse gap-4 overflow-y-auto pb-4" ref={scrollRef}>
          {chatMessagesByDate.map(([date, chatMessages]) => (
            <div key={date.toString()} data-testid="chat-message-date-group">
              <div className="relative mb-4 flex items-center justify-center whitespace-nowrap text-sm text-grey-lighter">
                <span className="absolute h-px w-full max-w-xs bg-grey-lighter" />
                <Capture2 className="relative bg-white px-4">
                  <FormattedDate format="date" date={new Date(Number(date)).toISOString()} />
                </Capture2>
              </div>
              <div className="flex flex-col-reverse gap-1">
                {chatMessages.map((chatMessage) => (
                  <ChatMessage
                    key={chatMessage.id}
                    isOwnMessage={chatMessage.author?.id === sessionUser.id}
                    hasPrevMessage={chatMessages[chatMessages.indexOf(chatMessage) - 1]?.author?.id === sessionUser.id}
                    isUnread={chatMessage.isUnread}
                    userAvatar={chatMessage.author?.avatar}
                    sentAt={chatMessage.time}
                    content={chatMessage.text}
                    image={chatMessage.image}
                  />
                ))}
              </div>
            </div>
          ))}
          {hasMoreChatMessages && (
            <div className="p-4" ref={loaderRef}>
              {isLoadingMoreChatMessages && <LoadingIcon className="inset-0 mx-auto my-4 w-6" />}
            </div>
          )}
        </div>
        <form>
          <CommentFieldWithAvatar
            user={sessionUser}
            value={messageValue}
            placeholder={t("page.user-detail.chat.input-placeholder")}
            onChange={onChangeContent}
            images={images}
            onSubmit={onSendChat}
            allowsImages
            onRemoveImage={onRemoveImage}
            onAddImages={(images) => {
              const maxMegaBytes = DEFAULT_MAXIMUM_FILE_SIZE_IN_MEGA_BYTES;
              const maxBytes = megaBytesToBytes(maxMegaBytes);
              if (Array.from(images).some((x) => x.size > maxBytes)) {
                showFlashToast({
                  title: t("components.form.error.file-too-big", {
                    sizeInMegaBytes: maxMegaBytes,
                  }),
                  type: "error",
                });

                return;
              }

              addImages(images);
            }}
            imageUploadTooltip={
              !sessionUser.chatEnabled ? t("page.user-detail.chat.disabled-input-tooltip") : undefined
            }
            sendTooltip={
              !sessionUser.chatEnabled
                ? t("page.user-detail.chat.disabled-input-tooltip")
                : t("page.user-detail.chat.input-tooltip")
            }
            disabled={!sessionUser.chatEnabled || !canChatWithUser}
          />
        </form>
      </div>
    </div>
  );
}
