import type { DragEndEvent, DragStartEvent } from "@dnd-kit/core";
import type {
  AutomatedSurveyDto,
  AutomatedSurveyEditRequest,
  AutomatedSurveyRequest,
  SimpleProjectDto,
} from "api/types";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { DynamicRepeater } from "components/DynamicRepeater/DynamicRepeater";
import { DynamicRepeaterItem } from "components/DynamicRepeater/DynamicRepeaterItem";
import { DynamicRepeaterItemOverlay } from "components/DynamicRepeater/DynamicRepeaterItemOverlay";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent, FormSplitContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { FormMultiSelect } from "components/Form/FormMultiSelect";
import { FormSelect } from "components/Form/FormSelect";
import { FormTextArea } from "components/Form/FormTextArea";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { createRequiredStringRule } from "helpers/rules";
import { parseAsNumber, sortAlphabetically } from "helpers/util";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useSystemSettingBreadcrumbs } from "modules/system-settings/util/useSystemSettingBreadcrumbs";
import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "routes";

import type {
  AutomatedSurveyQuestionCategory,
  AutomatedSurveyQuestionCategoryId,
  AutomatedSurveyType,
} from "../../constants";
import { automatedSurveyQuestionCategories, automatedSurveyTypes } from "../../constants";
import { FormQuestion } from "./components/FormQuestion";

interface Question {
  qId?: string;
  category: AutomatedSurveyQuestionCategory;
  explanation: string;
  ratingQuestion: string;
  openQuestion: string;
}

export interface FormValues {
  name: string;
  description: string;
  thanksMessage: string;
  interval: string;
  minSampleSize: string;
  type: AutomatedSurveyType;
  projects: SimpleProjectDto[];
  active: boolean;
  questions: Question[];
}

export interface LayoutProps {
  defaultFormValues: FormValues;
  onCreate: (payload: AutomatedSurveyRequest) => Promise<AutomatedSurveyDto>;
  onEdit: (payload: AutomatedSurveyEditRequest) => Promise<void>;
  isSubmitting: boolean;
}

const MAX_LENGTH_NAME = 255;
const MAX_LENGTH_DESCRIPTION = 5000;
const MAX_LENGTH_THANK_YOU_MESSAGE = 5000;
const MIN_INTERVAL = 1;
const MIN_SAMPLE_SIZE = 1;
const defaultQuestion = {
  category: automatedSurveyQuestionCategories[0],
  ratingQuestion: "",
  openQuestion: "",
  explanation: "",
};

export function Layout({ defaultFormValues, onCreate, onEdit, isSubmitting }: LayoutProps): React.ReactNode {
  const { id: surveyId } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: projects = [] } = useConnectedProjects();
  const sessionUser = useSessionUser();
  const [isSaveAndActivateModalOpen, saveAndActivateModalHandler] = useBool(false);
  const [isGoBackModalOpen, goBackModalHandler] = useBool(false);
  const [isMissingCatModalOpen, missingCatModalHandler] = useBool(false);
  const [missingCategories, setMissingCategories] = useState<string[]>([]);
  const [dragItemId, setDragItemId] = useState<number | null>(null);
  const form = useForm<FormValues>({ defaultValues: defaultFormValues });
  const { fields, update, append, remove, move } = useFieldArray<FormValues, "questions">({
    control: form.control,
    name: "questions",
  });

  const breadcrumbs = useSystemSettingBreadcrumbs({
    module: "automated-surveys",
    status: surveyId ? "edit" : "create",
  });

  function addAllProjects() {
    form.setValue("projects", projects);
  }

  function clearProjectSelection() {
    form.setValue("projects", []);
  }

  function getQuestionCategoryLabel(x: AutomatedSurveyQuestionCategoryId) {
    switch (x) {
      case "building":
        return t("page.automated-surveys.question-type.building");
      case "community":
        return t("page.automated-surveys.question-type.community");
      case "service":
        return t("page.automated-surveys.question-type.service");
      case "feelAtHome":
        return t("page.automated-surveys.question-type.feeling-at-home");
      case "other":
        return t("page.automated-surveys.types.other");
    }
  }

  function checkMandatoryQuestionCategories() {
    const questions = form.getValues("questions");
    const missingCategories = [];
    const mandatoryCategories = automatedSurveyQuestionCategories.filter((category) => category.mandatory);
    for (let i = 0; i < mandatoryCategories.length; i++) {
      let count = 0;
      for (let j = 0; j < questions.length; j++) {
        if (questions[j].category.id === mandatoryCategories[i].id) {
          count++;
        }
      }
      if (count === 0) {
        missingCategories.push(getQuestionCategoryLabel(mandatoryCategories[i].id));
      }
    }

    return { isValid: !missingCategories.length, missingCategories };
  }

  async function handleCreateAutomatedSurvey() {
    saveAndActivateModalHandler.setFalse();
    const values = form.getValues();

    const payload = {
      title: values.name,
      description: values.description,
      thankYouMessage: values.thanksMessage,
      weeklyInterval: parseAsNumber(values.interval) || 0,
      usersSampleSize: parseAsNumber(values.minSampleSize) || 0,
      category: values.type,
      questionGroups: values.questions.map((value, idx) => ({
        ratingQuestionTemplate: value.ratingQuestion,
        openQuestionTemplate: value.openQuestion,
        explanation: value.explanation,
        type: value.category.id,
        order: idx,
      })),
      enabledProjectsIds: values.projects.map((project) => project.id),
      isLocked: values.active,
    } satisfies AutomatedSurveyRequest;

    form.reset();
    await onCreate(payload);
  }

  async function handleEditAutomatedSurvey() {
    saveAndActivateModalHandler.setFalse();
    const values = form.getValues();

    const payload = {
      id: surveyId!,
      title: values.name,
      description: values.description,
      thankYouMessage: values.thanksMessage,
      weeklyInterval: parseAsNumber(values.interval) || 0,
      usersSampleSize: parseAsNumber(values.minSampleSize) || 0,
      category: values.type,
      questionGroups: values.questions.map((value, idx) => ({
        id: value.qId!,
        ratingQuestionTemplate: value.ratingQuestion,
        openQuestionTemplate: value.openQuestion,
        explanation: value.explanation,
        type: value.category.id,
        order: idx,
      })),
      enabledProjectsIds: values.projects.map((project) => project.id),
      isLocked: values.active,
    } satisfies AutomatedSurveyEditRequest;

    form.reset();
    await onEdit(payload);
  }

  async function handleSave() {
    const mandatoryCategoriesCheck = checkMandatoryQuestionCategories();
    if (!mandatoryCategoriesCheck.isValid) {
      setMissingCategories(mandatoryCategoriesCheck.missingCategories);
      missingCatModalHandler.setTrue();
    } else if (!defaultFormValues.active && form.getValues("active")) {
      saveAndActivateModalHandler.setTrue();
    } else {
      if (surveyId) {
        await handleEditAutomatedSurvey();
      } else {
        await handleCreateAutomatedSurvey();
      }
    }
  }

  function handleDragStart(event: DragStartEvent) {
    setDragItemId(event.active.data.current?.sortable.index);
  }

  function handleDragEnd(event: DragEndEvent) {
    setDragItemId(null);
    const { active, over } = event;

    if (over && active.id !== over.id) {
      move(active.data.current?.sortable.index, over?.data.current?.sortable.index);
    }
  }

  return (
    <DocumentPaper
      theme="constrained"
      title={surveyId ? t("page.automated-surveys.edit.title") : t("page.automated-surveys.new.title")}
      subTitle={<Breadcrumbs pages={breadcrumbs} />}
    >
      <Form formMethods={form} onSubmit={handleSave}>
        <FormContent maxWidth="2xl">
          <FormField label={t("page.automated-surveys.new.form.name.label")} required>
            <FormInput<FormValues>
              name="name"
              placeholder={t("page.automated-surveys.new.form.name.placeholder")}
              rules={{
                maxLength: {
                  message: t("components.form.error.max-length", { length: MAX_LENGTH_NAME }),
                  value: MAX_LENGTH_NAME,
                },
                validate: {
                  required: createRequiredStringRule(t, "page.automated-surveys.new.form.name.label"),
                },
              }}
            />
          </FormField>
          <FormSplitContent>
            <FormField label={t("page.automated-surveys.new.form.description.label")} required>
              <FormTextArea<FormValues>
                name="description"
                placeholder={t("page.automated-surveys.new.form.description.placeholder")}
                rules={{
                  maxLength: {
                    message: t("components.form.error.max-length", { length: MAX_LENGTH_DESCRIPTION }),
                    value: MAX_LENGTH_DESCRIPTION,
                  },
                  validate: {
                    required: createRequiredStringRule(t, "page.automated-surveys.new.form.description.label"),
                  },
                }}
              />
            </FormField>
            <FormField label={t("page.automated-surveys.new.form.thanks-message.label")} required>
              <FormTextArea<FormValues>
                name="thanksMessage"
                placeholder={t("page.automated-surveys.new.form.thanks-message.placeholder")}
                rules={{
                  maxLength: {
                    message: t("components.form.error.max-length", { length: MAX_LENGTH_THANK_YOU_MESSAGE }),
                    value: MAX_LENGTH_THANK_YOU_MESSAGE,
                  },
                  validate: {
                    required: createRequiredStringRule(t, "page.automated-surveys.new.form.thanks-message.label"),
                  },
                }}
              />
            </FormField>
          </FormSplitContent>
          <FormSplitContent>
            <FormField label={t("page.automated-surveys.new.form.interval.label")} required>
              <FormInput<FormValues>
                name="interval"
                placeholder={t("page.automated-surveys.new.form.interval.placeholder")}
                inputMode="numeric"
                type="number"
                rules={{
                  required: t("components.form.error.required", {
                    inputName: t("page.automated-surveys.new.form.interval.label"),
                  }),
                  min: {
                    message: t("page.automated-surveys.new.form.interval.rules.min", { count: MIN_INTERVAL }),
                    value: MIN_INTERVAL,
                  },
                }}
              />
            </FormField>
            <FormField label={t("page.automated-surveys.new.form.min-sample.label")} required>
              <FormInput<FormValues>
                name="minSampleSize"
                placeholder={t("page.automated-surveys.new.form.min-sample.placeholder")}
                inputMode="numeric"
                type="number"
                rules={{
                  required: t("components.form.error.required", {
                    inputName: t("page.automated-surveys.new.form.min-sample.label"),
                  }),
                  min: {
                    message: t("page.automated-surveys.new.form.min-sample.rules.min", { count: MIN_SAMPLE_SIZE }),
                    value: MIN_SAMPLE_SIZE,
                  },
                }}
              />
            </FormField>
            <FormField label={t("page.automated-surveys.new.form.type.label")} required>
              <FormSelect<FormValues, AutomatedSurveyType>
                name="type"
                placeholder={t("page.automated-surveys.new.form.type.placeholder")}
                items={automatedSurveyTypes}
                keySelector={(x) => x}
                renderOption={(x) => {
                  switch (x) {
                    case "other":
                      return t("page.automated-surveys.types.other");
                    case "feelAtHome":
                      return t("page.automated-surveys.types.feeling-at-home");
                  }
                }}
                rules={{
                  required: t("components.form.error.required", {
                    inputName: t("page.automated-surveys.new.form.type.label"),
                  }),
                }}
                disabled={defaultFormValues.active}
              />
            </FormField>
          </FormSplitContent>
          <div className="flex flex-col items-start gap-2 sm:flex-row sm:items-end">
            <FormField label={t("page.automated-surveys.new.form.projects.label")} required>
              <FormMultiSelect<FormValues, "projects">
                name="projects"
                placeholder={t("page.automated-surveys.new.form.projects.placeholder")}
                items={projects ? projects : []}
                sortSelectedItems={sortAlphabetically}
                keySelector={(x) => x.id}
                renderOption={(x) => x.name}
                rules={{
                  required: t("components.form.error.required", {
                    inputName: t("page.automated-surveys.new.form.projects.label"),
                  }),
                }}
              />
            </FormField>
            <div className="flex gap-2">
              <Button className="w-full" styling="primaryFaded" onClick={addAllProjects}>
                {t("component.create-message-multiple-projects.form.to.add-all")}
              </Button>
              <Button className="w-full" styling="secondaryRed" onClick={clearProjectSelection}>
                {t("component.create-message-multiple-projects.form.to.clear-all")}
              </Button>
            </div>
          </div>
          <div className="flex flex-col gap-4 rounded-lg bg-white p-4 ring-1 ring-grey-lighter">
            <DynamicRepeater
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              itemIds={fields.map((field) => field.id)}
            >
              {fields.map((question, idx) => (
                <DynamicRepeaterItem
                  key={question.id}
                  id={question.id}
                  title={t("page.automated-surveys.new.question.label", { count: idx + 1 })}
                  disabled={defaultFormValues.active}
                >
                  <FormQuestion
                    idx={idx}
                    question={question}
                    categories={
                      sessionUser.isSuperAdmin
                        ? automatedSurveyQuestionCategories
                        : automatedSurveyQuestionCategories.filter((cat) => cat.id !== "feelAtHome")
                    }
                    showDelete={
                      !defaultFormValues.active &&
                      (!question.category.mandatory
                        ? true
                        : fields.reduce(
                            (counter, field) => (question.category.id === field.category.id ? (counter += 1) : counter),
                            0,
                          ) > 1)
                    }
                    updateField={update}
                    removeField={remove}
                    disabled={defaultFormValues.active}
                  />
                </DynamicRepeaterItem>
              ))}
              <DynamicRepeaterItemOverlay
                show={dragItemId !== null}
                title={t("page.automated-surveys.new.question.label", { count: dragItemId! + 1 })}
              >
                <FormQuestion
                  idx={dragItemId!}
                  question={fields[dragItemId!]}
                  categories={automatedSurveyQuestionCategories}
                  showDelete={false}
                  updateField={() => null}
                  removeField={() => null}
                  disabled={defaultFormValues.active}
                />
              </DynamicRepeaterItemOverlay>
            </DynamicRepeater>
          </div>
          {!defaultFormValues.active && (
            <Button styling="primaryFaded" onClick={() => append(defaultQuestion)}>
              {t("page.automated-surveys.new.question.add")}
            </Button>
          )}
          <div className="flex justify-between gap-2">
            <FormCheckbox<FormValues>
              name="active"
              label={t("page.automated-surveys.new.form.active.label")}
              disabled={defaultFormValues.active}
            />
            <Button styling="primary" type="submit" isLoading={isSubmitting}>
              {t("page.automated-surveys.new.form.submit")}
            </Button>
          </div>
        </FormContent>
      </Form>
      <ConfirmModal
        id="automated-survey-save-active-modal"
        title={t("page.automated-surveys.new.save-active-modal.title")}
        description={t("page.automated-surveys.new.save-active-modal.description")}
        isLoading={false}
        onReject={saveAndActivateModalHandler.setFalse}
        rejectBtnProps={{
          "data-testid": "automated-survey-save-active-modal-cancel",
        }}
        onResolve={() => {
          if (surveyId) {
            void handleEditAutomatedSurvey();
          } else {
            void handleCreateAutomatedSurvey();
          }
          saveAndActivateModalHandler.setFalse();
        }}
        resolveBtnProps={{
          text: t("common.action.confirm"),
          "data-testid": "automated-survey-save-active-modal-confirm",
        }}
        isOpen={isSaveAndActivateModalOpen}
        shouldCloseOnEsc
        data-testid="automated-survey-save-active-modal"
      />
      <ConfirmModal
        id="automated-survey-go-back-modal"
        title={t("page.automated-surveys.new.go-back-modal.title")}
        description={t("page.automated-surveys.new.go-back-modal.description")}
        isLoading={false}
        theme="danger"
        isOpen={isGoBackModalOpen}
        shouldCloseOnEsc
        onReject={goBackModalHandler.setFalse}
        rejectBtnProps={{
          "data-testid": "automated-survey-go-back-modal-cancel",
        }}
        onResolve={() => navigate(routes.automatedSurveys.list())}
        resolveBtnProps={{
          text: t("common.action.confirm"),
          "data-testid": "automated-survey-go-back-modal-confirm",
        }}
        data-testid="automated-survey-go-back-modal"
      />
      <ConfirmModal
        id="automated-survey-missing-questions-modal"
        title={t("page.automated-surveys.new.missing-questions-modal.title")}
        description={t("page.automated-surveys.new.missing-questions-modal.description", {
          categories: missingCategories.join(", "),
        })}
        theme="danger"
        isLoading={false}
        onReject={missingCatModalHandler.setFalse}
        rejectBtnProps={{
          "data-testid": "automated-survey-missing-questions-modal-cancel",
        }}
        onResolve={missingCatModalHandler.setFalse}
        resolveBtnProps={{
          text: t("common.action.confirm"),
          "data-testid": "automated-survey-missing-questions-modal-confirm",
        }}
        isOpen={isMissingCatModalOpen}
        shouldCloseOnEsc
        data-testid="automated-survey-missing-questions-modal"
      />
    </DocumentPaper>
  );
}
