import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import type { AutomatedSurveyDto } from "api/types";
import { Anchor } from "components/Anchor/Anchor";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { LinkButton } from "components/Button/Button";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { ContextMenu } from "components/ContextMenu/ContextMenu";
import { Pagination } from "components/Pagination/Pagination";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Table } from "components/Table/Table";
import { Capture2 } from "components/Text/Text";
import { ProjectList } from "modules/system-settings/components/ProjectList";
import { useSystemSettingBreadcrumbs } from "modules/system-settings/util/useSystemSettingBreadcrumbs";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

export interface LayoutProps {
  automatedSurveys: AutomatedSurveyDto[];
  isLoadingAutomatedSurveys: boolean;
  page: number;
  onPageChange: (page: number) => void;
  totalPages: number;
}

export function Layout({
  automatedSurveys,
  isLoadingAutomatedSurveys,
  page,
  onPageChange,
  totalPages,
}: LayoutProps): React.ReactNode {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const columns = useMemo(() => {
    const helper = createColumnHelper<AutomatedSurveyDto>();

    return [
      helper.accessor("title", {
        header: t("page.automated-surveys.list.table.name"),
        cell: (cell) => (
          <Anchor to={routes.automatedSurveys.details({ id: cell.row.original.id })}>
            <span className="line-clamp-2">{cell.getValue()}</span>
          </Anchor>
        ),
      }),
      helper.accessor("category", {
        header: t("page.automated-surveys.list.table.type"),
        cell: (cell) => {
          switch (cell.getValue()) {
            case "other":
              return <span className="whitespace-nowrap">{t("page.automated-surveys.types.other")}</span>;
            case "feelAtHome":
              return <span className="whitespace-nowrap">{t("page.automated-surveys.types.feeling-at-home")}</span>;
          }
        },
      }),
      helper.accessor("enabledProjects", {
        header: t("page.automated-surveys.list.table.projects"),
        cell: (cell) => <ProjectList projects={cell.getValue()} limit={3} />,
      }),
      helper.accessor("questionGroups", {
        header: () => (
          <span className="whitespace-nowrap normal-case">
            {t("page.automated-surveys.list.table.n-of-questions.header")}
          </span>
        ),
        cell: (cell) => (
          <span className="whitespace-nowrap">
            {t("page.automated-surveys.list.table.n-of-questions.cell", { count: cell.getValue().length })}
          </span>
        ),
      }),
      helper.accessor("isLocked", {
        header: t("page.automated-surveys.list.table.locked"),
        cell: (cell) => (
          <span className="whitespace-nowrap">
            {cell.getValue()
              ? t("page.automated-surveys.list.table.locked.yes")
              : t("page.automated-surveys.list.table.locked.no")}
          </span>
        ),
      }),
      helper.accessor("id", {
        header: "",
        cell: (cell) => {
          const id = cell.getValue();

          const actions: ContextMenuAction[] = [
            {
              callback: () => navigate(routes.automatedSurveys.edit({ id })),
              text: t("page.automated-surveys.list.table.actions.edit"),
            },
            {
              callback: () => navigate(routes.automatedSurveys.details({ id })),
              text: t("page.automated-surveys.list.table.actions.view-queue"),
            },
          ];

          return (
            <div className="flex justify-end">
              <ContextMenu className="text-grey-darker" actions={actions} />
            </div>
          );
        },
      }),
    ];
  }, [navigate, t]);

  const tableInstance = useReactTable<AutomatedSurveyDto>({
    columns,
    data: automatedSurveys,
    getCoreRowModel: getCoreRowModel(),
  });

  const breadcrumbs = useSystemSettingBreadcrumbs({ module: "automated-surveys", status: "list" });

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.automated-surveys.title")}
      subTitle={<Breadcrumbs pages={breadcrumbs} />}
      actions={
        <LinkButton to={routes.automatedSurveys.create()}>{t("page.automated-surveys.list.create-new")}</LinkButton>
      }
    >
      {automatedSurveys.length ? (
        <Table table={tableInstance} isLoading={isLoadingAutomatedSurveys}>
          <Pagination count={totalPages} onChange={onPageChange} currentIndex={page} />
        </Table>
      ) : isLoadingAutomatedSurveys ? null : (
        <div className="rounded-lg bg-white p-5">
          <Capture2>{t("page.automated-surveys.list.no-data")}</Capture2>
        </div>
      )}
    </DocumentPaper>
  );
}
