import { IconButton } from "components/Button/Button";
import { MessageSquare, ThumbsUp } from "react-feather";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

export function LikeButton({ isActive, onClick }: { isActive: boolean; onClick: () => void }): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div className="whitespace-nowrap" data-testid="like-btn">
      <IconButton
        className="flex items-center gap-2 px-2 py-1.5 text-aop-basic-blue"
        title={t("component.community-post.like")}
        onClick={onClick}
      >
        <ThumbsUp className={isActive ? "fill-aop-basic-blue/80" : undefined} size={14} />
        <span className="align-top">{t("component.community-post.like")}</span>
      </IconButton>
    </div>
  );
}

interface LikeCountButtonProps {
  isActive: boolean;
  isGreyedOut: boolean;
  total: number;
  onClick: () => void;
}

export function LikeCountButton({ total, isActive, onClick, isGreyedOut }: LikeCountButtonProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div
      className={twJoin(
        "relative whitespace-nowrap",
        isActive &&
          "after:absolute after:-bottom-2 after:left-0 after:h-px after:w-full after:bg-aop-basic-blue after:content-['']",
      )}
      data-testid="like-count-btn"
    >
      <IconButton
        className={twJoin("px-2 py-1.5", isGreyedOut ? "text-grey" : "text-aop-basic-blue")}
        title={t("component.community-post.like-count", { count: total })}
        onClick={onClick}
      >
        {t("component.community-post.like-count", { count: total })}
      </IconButton>
    </div>
  );
}

interface CommentButtonProps {
  isActive: boolean;
  isGreyedOut: boolean;
  total: number;
  onClick: () => void;
}

export function CommentButton({ isActive, isGreyedOut, total, onClick }: CommentButtonProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div
      className={twJoin(
        "relative whitespace-nowrap",
        isActive &&
          "after:absolute after:-bottom-2 after:left-0 after:h-px after:w-full after:bg-aop-basic-blue after:content-['']",
      )}
    >
      <IconButton
        className={twJoin("flex items-center gap-2 px-2 py-1.5", isGreyedOut ? "text-grey" : "text-aop-basic-blue")}
        title={t("component.community-post.comments.title")}
        onClick={onClick}
      >
        <MessageSquare size={14} />
        <span>{t("component.community-post.comments.total", { count: total })}</span>
      </IconButton>
    </div>
  );
}
